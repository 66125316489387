import React from "react"
import PropTypes from "prop-types"

const LoadBar = (props) => {
  let { pathFill, stroke, className } = props

  return (
    <svg
      className={`animate-spin ${className}`}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        cx="12"
        cy="12"
        r="10"
        stroke={stroke}
        strokeWidth="4"
      />
      <path
        fill={pathFill}
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      />
    </svg>
  )
}

LoadBar.propTypes = {
  className: PropTypes.string,
  pathFill: PropTypes.string,
  stroke: PropTypes.string,
}

LoadBar.defaultProps = {
  className: "h-5 w-5",
  pathFill: "currentColor",
  stroke: "#F2F4F7",
}

export default LoadBar
