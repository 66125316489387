import { call, fork, put, takeLatest } from "redux-saga/effects"
import * as actions from "redux/actions/properties"

import { GET_PROPERTIES_REQUESTED } from "redux/types"
import { API_POST } from "helpers/api"

function* watchGetPropertiesList() {
  yield takeLatest(GET_PROPERTIES_REQUESTED, getPropertiesList)
}

function* getPropertiesList(action) {
  try {
    const res = yield call(apiGetPropertiesList, action.payload)
    yield put(actions.getPropertiesSucceed(res?.data))
  } catch (err) {
    yield put(actions.getPropertiesFailed())
  }
}

export const PropertiesType = {
  TALEP_RET_NEDENI: "Talep Ret Nedeni",
  SEYAHAT_NEDENI: "Seyahat Nedeni",
  DEPARTMAN: "Departman",
  DEFAULT: "",
}

/**
 * @description This function gets the properties list from the 'Tanımlar'.
 *
 * @param {typeof PropertiesType} PropertiesType
 * @returns
 */
const apiGetPropertiesList = (param) => {
  return API_POST(`${process.env.REACT_APP_PANEL_DOMAIN}/api/setting/getcorpsettings`, {
    SettingName: "Tanımlar",
    PropertyName: param,
  })
}

export default function* propertiesList() {
  yield fork(watchGetPropertiesList)
}
