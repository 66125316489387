import { useQuery, useMutation } from "@tanstack/react-query"
import { API_GET, API_POST } from "helpers/api"

/**
 * @typedef {Object} PropertiesType
 * @property {number} Page
 * @property {number} PageSize
 * @property {"Id" | "Name"} SortColumn
 * @property {string} Name
 * @property {6002 | 6003 | 6004 | 6005 | 6006} Type
 *
 * @param {PropertiesType} Obj
 * @returns UseQueryResult<AxiosResponse<any, any>, unknown>
 */
export const useRuleList = (
  props = {
    Page: 1,
    PageSize: 10,
    SortColumn: "Id",
    Name: undefined,
    Type: undefined,
    Status: undefined,
  },
) => {
  const { Type, Name, Status, ...filter } = props

  const query = useQuery({
    queryKey: ["rules", props],
    queryFn: () =>
      API_POST(`${process.env.REACT_APP_DISPLAY_DOMAIN}/api/rule/list`, {
        ...filter,
        Filter: { Name, Type, Status },
      }),
  })

  return query
}

export const useRuleTypeList = (ruleTypeId) => {
  const query = useQuery({
    queryKey: ["ruleTypes"],
    queryFn: () => API_GET(`${process.env.REACT_APP_DISPLAY_DOMAIN}/api/rule/ruletypes`),
  })
  // result came from the api structure. You can check {{DisplayUrl}}/api/rule/list endpoint response on Postman.
  const data = query?.data?.data?.result?.ruleTypes || []

  return {
    ...query,
    data: ruleTypeId ? data.find((item) => item.id === Number(ruleTypeId || "0")) : data,
  }
}

export const useRuleMetaDataByRuleTypeId = (ruleTypeId) => {
  const query = useQuery({
    queryKey: ["ruleTypes", ruleTypeId],
    queryFn: () => API_GET(`${process.env.REACT_APP_DISPLAY_DOMAIN}/api/rule/getmetadatabyruletypeid/${ruleTypeId}`),
    defaultQueryOptions: {
      enabled: ruleTypeId !== undefined,
    },
  })

  return {
    ...query,
    resultItems: query?.data?.data.Result?.ResultItem || undefined,
    ruleItems: query?.data?.data.Result?.RuleItem || undefined,
  }
}

export const useAddOrUpdateRuleMutation = () => {
  const mutation = useMutation({
    mutationFn: (data) =>
      API_POST(`${process.env.REACT_APP_DISPLAY_DOMAIN}/api/rule/${data.Rule.Id !== 0 ? "update" : "add"}`, data),
  })

  return mutation
}

export const useRemoveRuleMutation = () => {
  const mutation = useMutation({
    // @todo: Change this endpoint to delete.
    mutationFn: (ruleId) => API_GET(`${process.env.REACT_APP_DISPLAY_DOMAIN}/api/rule/delete/${ruleId}`),
  })

  return mutation
}

export const useGetRuleById = (ruleId) => {
  const query = useQuery({
    queryKey: ["rule", ruleId],
    queryFn: () => API_GET(`${process.env.REACT_APP_DISPLAY_DOMAIN}/api/rule/get/${ruleId}`),
    defaultQueryOptions: {
      enabled: ruleId !== undefined,
    },
  })

  return query
}

export const useDestinationParameterMutation = () => {
  const mutation = useMutation({
    mutationFn: ({ destinationTypes, term }) =>
      API_POST(
        `${
          process.env.REACT_APP_DISPLAY_DOMAIN
        }/DestinationPluginData/GetDestinationAutocomplete?term=${term}&destinationTypes=${destinationTypes.join(",")}`,
      ),
  })

  return mutation
}
