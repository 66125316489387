import { Row, Col } from "rsuite"
import { Icon } from "components"

const InputWrapper = ({ children, onRemove, ref }) => {
  return (
    <Row className="flex items-center border border-gray-200 bg-gray-25 rounded-lg py-3 px-1 my-2" ref={ref}>
      <Col xs={!!onRemove ? 23 : 24}>{children}</Col>
      <Col xs={!!onRemove ? 1 : 0}>
        {!!onRemove && (
          <Icon
            size={20}
            name="close"
            className={"!bg-gray-500 cursor-pointer hover:!bg-gray-400"}
            onClick={onRemove}
          />
        )}
      </Col>
    </Row>
  )
}

export default InputWrapper
