import React from "react"

import a from "components/MarketPlace/asset/a.png"
import b from "components/MarketPlace/asset/b.png"
import c from "components/MarketPlace/asset/c.png"

const Explorer = () => {
  return (
    <>
      <div className="mb-10 rounded-lg">
        <div className="w-full">
          <div>
            <img
              className="w-full"
              src={a}
              alt=""
            />
          </div>
          <div className="p-4 border border-gray-200 rounded-lg">
            <p className="text-gray-800 text-lg font-semibold">Kategori 1</p>
          </div>
        </div>
      </div>
      <div className="mb-10 rounded-lg">
        <div className="w-full">
          <div>
            <img
              className="w-full"
              src={b}
              alt=""
            />
          </div>
          <div className="p-4 border border-gray-200 rounded-lg">
            <p className="text-gray-800 text-lg font-semibold">Kategori 2</p>
          </div>
        </div>
      </div>
      <div className="mb-10 rounded-lg">
        <div className="w-full">
          <div>
            <img
              className="w-full"
              src={c}
              alt=""
            />
          </div>
          <div className="p-4 border border-gray-200 rounded-lg">
            <p className="text-gray-800 text-lg font-semibold">Kategori 3</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Explorer
