export const domainList  = [
	".platformeva", // ilk eleman olarak kalmalidir, yeri degisirse selectlerdeki default deger bozulur ve backende giden logic`te sorun cikar, sakin kaldirma. gerekli degilse bu datayi manipule ederek isini halletmelisin
	".com",
	".net",
	".org",
	".tr",
	".ac",
	".ad",
	".xxx",
	".ae",
	".aero",
	".af",
	".ag",
	".ai",
	".al",
	".am",
	".an",
	".ao",
	".aq",
	".ar",
	".arpa",
	".as",
	".asia",
	".at",
	".aw",
	".az",
	".ba",
	".bb",
	".bd",
	".be",
	".bf",
	".bg",
	".bh",
	".bi",
	".bj",
	".bm",
	".bn",
	".bo",
	".biz",
	".br",
	".bs",
	".bv",
	".bw",
	".by",
	".bz",
	".ca",
	".cc",
	".abk",
	".cd",
	".cf",
	".cg",
	".ch",
	".ci",
	".ck",
	".cl",
	".cm",
	".cn",
	".co",
	".coop",
	".cr",
	".cu",
	".cv",
	".cx",
	".cy",
	".cz",
	".de",
	".dj",
	".dk",
	".dm",
	".do",
	".dz",
	".ec",
	".edu",
	".ee",
	".eg",
	".er",
	".es",
	".et",
	".eu",
	".fi",
	".fk",
	".fm",
	".fo",
	".fr",
	".ga",
	".gb",
	".gd",
	".gov",
	".gp",
	".gq",
	".gr",
	".gs",
	".gt",
	".gtld",
	".gu",
	".gw",
	".gy",
	".hk",
	".hm",
	".hn",
	".hr",
	".ht",
	".hu",
	".id",
	".ie",
	".il",
	".im",
	".in",
	".io",
	".je",
	".jm",
	".jo",
	".jobs",
	".jp",
	".k12",
	".ke",
	".kg",
	".kh",
	".ki",
	".kw",
	".ky",
	".kz",
	".la",
	".lb",
	".lc",
	".li",
	".lk",
	".lr",
	".ls",
	".lt",
	".lu",
	".lv",
	".ly",
	".ma",
	".mc",
	".md",
	".mg",
	".mh",
	".mk",
	".ml",
	".mm",
	".mn",
	".mo",
	".mil",
	".mp",
	".mq",
	".mr",
	".ms",
	".mt",
	".mu",
	".museum",
	".mv",
	".mw",
	".mx",
	".my",
	".mz",
	".na",
	".name",
	".nc",
	".ne",
	".nf",
	".ng",
	".ni",
	".nl",
	".no",
	".np",
	".nr",
	".nu",
	".nz",
	".om",
	".info",
	".int",
	".pa",
	".pe",
	".pf",
	".pg",
	".ph",
	".pk",
	".pl",
	".pm",
	".pn",
	".pr",
	".pro",
	".ps",
	".pt",
	".pw",
	".py",
	".qa",
	".re",
	".ro",
	".root",
	".ru",
	".rw",
	".sa",
	".sb",
	".sc",
	".sd",
	".se",
	".sg",
	".sh",
	".si",
	".sj",
	".sk",
	".sl",
	".sm",
	".sn",
	".so",
	".sr",
	".st",
	".su",
	".sv",
	".sy",
	".sz",
	".tc",
	".td",
	".tf",
	".tg",
	".th",
	".tj",
	".tk",
	".tl",
	".tm",
	".tn",
	".to",
	".tp",
	".travel",
	".tt",
	".tv",
	".tw",
	".tz",
	".ua",
	".ug",
	".uk",
	".um",
	".us",
	".uy",
	".va",
	".vc",
	".ve",
	".vg",
]