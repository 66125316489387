import React, { useState } from "react"
import { BorderBox } from "components"
import { useDomainAddonSettingSave } from "services/wl-display/domainAddon/domainAddonSettingSave"
import { useDomainAddonSettingUpdate } from "services/wl-display/domainAddon/domainAddonSettingUpdate"

const GoogleAnalyticsPreview = ({ setIsEdit, domainAddonId, domainAddonSettingListData, addonSettingData, onSave, isSave }) => {
  const [state, setState] = useState({
    DomainAddonId: domainAddonId || 0,
    PropertyId: addonSettingData?.PropertyId || 0,
    PropertyType: addonSettingData?.Property?.Type || 0,
    Value: domainAddonSettingListData?.PropertyValue?.Value,
  })

  const { mutateAsync: saveAsync } = useDomainAddonSettingSave()
  const { mutateAsync: updateAsync } = useDomainAddonSettingUpdate()

  const handleChange = (e) => {
    setState((prevState) => ({
      ...prevState,
      Value: e.target.value,
    }))
  }

  const handleSave = async () => {
    try {
      if (isSave) {
        await saveAsync(state)
      } else {
        const updatePayload = {
          Id: domainAddonSettingListData?.Id || 0,
          PropertyValueId: domainAddonSettingListData?.PropertyValueId || 0,
          PropertyType: domainAddonSettingListData?.PropertyValue?.Property?.Type || 0,
          Value: state.Value,
        }
        await updateAsync(updatePayload)
      }
      setIsEdit(false)
      if (onSave) {
        onSave()
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div className="flex flex-col gap-4">
      <BorderBox className="p-6">
        <div className="flex flex-col gap-1">
          <span className="text-gray-800 text-lg font-semibold">Tracking ID</span>
          <input
            type="text"
            name="Value"
            className="w-1/2"
            placeholder="Tracking ID"
            value={state.Value}
            onChange={handleChange}
          />
        </div>
      </BorderBox>
      <div className="col mb-6 col-span-2 last:mb-0">
        <div className="button-area flex justify-end gap-4">
          <button
            onClick={() => setIsEdit(false)}
            className="btn outline"
          >
            Vazgeç
          </button>
          <button
            className="btn"
            disabled={!state.Value}
            onClick={handleSave}
          >
            Kaydet
          </button>
        </div>
      </div>
    </div>
  )
}

export default GoogleAnalyticsPreview
