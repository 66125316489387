import { useEffect, useState } from "react"
import { Swiper } from "swiper/react"
import { useDispatch, useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom"

import MainHeader from "components/Headers/MainHeader"
import AddonList from "components/MarketPlace/AddonList"
import AddonBox from "components/MarketPlace/AddonBox"
import MarketPlaceFilter from "layouts/Aside/MarketPlaceFilter"
import Explorer from "components/MarketPlace/Explorer"
import MarketPlaceHomePageSkeleton from "skeletons/MarketPlace/HomePage"

import { addonListRequested } from "redux/actions/addon"

const MarketPlace = (props) => {
  let { isLogged } = props
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()

  const [isFree, setIsFree] = useState(null)

  const addonListGet = useSelector((state) => state.Addon?.list)
  const addonListLoading = useSelector((state) => state.Addon?.loading)
  const addonListError = useSelector((state) => state.Addon?.error)

  const isFreeClick = () => {
    setIsFree(true)
  }

  const isPaidClick = () => {
    setIsFree(false)
  }

  useEffect(() => {
    dispatch(
      addonListRequested({
        IsFree: isFree,
        CategoryIds: [],
      }),
    )
  }, [dispatch, isFree])

  return (
    <>
      <MainHeader
        isLogged={isLogged}
        marketPlace
      />
      <MarketPlaceFilter
        addonListGetLength={addonListGet?.length}
        isFreeButton={isFreeClick}
        isPaidButton={isPaidClick}
      />
      {addonListLoading && <MarketPlaceHomePageSkeleton />}
      {!addonListLoading && !addonListError && (
        <main>
          <section className="p-10 my-0 mx-auto max-w-[1920px]">
            {searchParams.get("all") !== "true" ? (
              <div>
                <h2 className="text-3xl font-semibold  mb-8">EVA ürünleri ile işinizi bir adım daha ileri taşıyın</h2>
                <div className="grid grid-cols-3 gap-4">
                  <AddonBox />
                </div>
              </div>
            ) : (
              <div className="flex flex-col gap-2 mb-8">
                <h2 className="text-3xl font-semibold">Tümü</h2>
                <span>{addonListGet?.length} sonuç gösteriliyor</span>
              </div>
            )}
            <AddonList data={addonListGet} />
            {searchParams.get("all") !== "true" && (
              <div className="pb-10">
                <h2 className="mb-4 font-semibold text-lg">Keşfet</h2>
                <div className="">
                  <Swiper
                    slidesPerView={3}
                    spaceBetween={30}
                    className="mySwiper"
                    grabCursor={true}
                  >
                    <div className="grid grid-cols-3 gap-4">
                      <Explorer />
                    </div>
                  </Swiper>
                </div>
              </div>
            )}
          </section>
        </main>
      )}
    </>
  )
}

export default MarketPlace
