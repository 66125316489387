import { useMutation } from "@tanstack/react-query"
import { API_POST } from "helpers/api"

export const useDomainAddonSettingSave = () => {
  const mutation = useMutation({
    mutationFn: (data) => API_POST(`${process.env.REACT_APP_DISPLAY_DOMAIN}/api/domainaddonsetting/save`, data),
  })

  return mutation
}
