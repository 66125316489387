import React, { useEffect, useState } from "react"
import Icon from "components/Icon"
import Cookies from "universal-cookie"
import { Link } from "react-router-dom"

const Navigation = (props) => {
  let { StepsGoTo, showPrev, showNext, StepId, basePath } = props
  const [ShowPrev, setShowPrev] = useState(false)
  const [ShowNext, setShowNext] = useState(false)

  const cookies = new Cookies()
  const DomainType = cookies.get("associatedDomain")?.domainType

  useEffect(() => {
    showPrev ? setShowPrev(true) : setShowPrev(false)
    showNext ? setShowNext(true) : setShowNext(false)
  }, [showPrev, showNext])

  return (
    <div className="w-full py-6 flex justify-between">
      {ShowPrev && (
        <button
          onClick={() => {
            window.history.pushState(StepsGoTo - 2, `Step-${StepsGoTo - 2}`, `${basePath}/${showPrev}`)
            StepsGoTo(StepId - 1)
          }}
          className={`btn outline px-[33px]`}
        >
          <Icon
            name="arrow-left"
            size="20"
            color="#667085"
          />
          <span>Önceki</span> {/*showPrev*/}
        </button>
      )}
      {ShowNext && (
        <button
          onClick={() => {
            window.history.pushState(``, `Step-${StepsGoTo}`, `${basePath}/${showNext}`)
            StepsGoTo(StepId + 1)
          }}
          className={`btn px-[33px] ml-auto`}
        >
          <span>Sonraki</span> {/*showNext*/}
          <Icon
            name="arrow-right"
            size="20"
            color="white"
          />
        </button>
      )}

      {DomainType === "Corporate" && showNext === undefined && (
        <Link to={"/"}>
          <button
            onClick={() => {
              window.history.pushState(``, `Step-${StepsGoTo}`, `${basePath}/${showNext}`)
              StepsGoTo(StepId + 1)
            }}
            className={`btn px-[33px] ml-auto`}
          >
            <span>Tamamla</span>
          </button>
        </Link>
      )}
    </div>
  )
}

export default Navigation
