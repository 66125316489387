/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux";

//layouts
import PaginationAndNavigation from "./PaginationAndNavigation"
import Arrow from "./SliderArrows"
import AutoSlide from "./AutoSlide"

//components
import {ColorBox, SideMenuListItem} from "components"
import {UpdateStrategy} from "../../../../helpers/UpdateStrategy";


// saga-requests
import {getStrategySucceed} from "redux/actions/strategy";
import {setSettings} from "helpers/setSettings";
import {WidthAndHeightVisual} from "../../../../../../components/PageManager/WidthAndHeightVisual";

const Settings = (props) => {
  let { ThisComponent, setThisComponent} = props

  const dispatch = useDispatch()

  //selectors
  let components = useSelector(state => state.strategy.getStrategy.list?.result)

  //states
  const [ShowSubMenu, setShowSubMenu] = useState(null)
  const [VisualBlockSettings, setVisualBlockSettings] = useState(ThisComponent?.visual)

  //effects
  useEffect(() => {
    if (VisualBlockSettings){
      setThisComponent({
        ...ThisComponent,
        visual: {
          ...ThisComponent?.visual,
          ...VisualBlockSettings,
          style: {
            ...ThisComponent?.visual?.style,
            ...VisualBlockSettings?.style,
          },
        }
      })
    }
  }, [VisualBlockSettings])
  useEffect(() => {
    if (ThisComponent && VisualBlockSettings){
      const strategy = UpdateStrategy(components, ThisComponent)
      dispatch(getStrategySucceed(strategy))
    }
  }, [ThisComponent])


  return (
    <>
      <h6 className="text-gray-500 font-semibold">SETTINGS</h6>
      <div className="flex flex-col gap-4 mt-4">
        <WidthAndHeightVisual
          ThisComponent={ThisComponent}
          VisualSettings={VisualBlockSettings}
          setVisualSettings={setVisualBlockSettings}
        />

        <ColorBox
          title="Arka Plan Rengi"
          value={ThisComponent?.visual?.style?.backgroundColor}
          name="backgroundColor" // optional -  style property
          onChange={(e) =>
            setSettings(e, false, VisualBlockSettings).then((res) => setVisualBlockSettings(res))
          }
        />
        <div className="border-case flex flex-col border border-gray-200 rounded-lg">
          <SideMenuListItem
            className="border-t first:border-t-0"
            onClick={() =>
              setShowSubMenu({
                paginationAndNavigation: true,
              })
            }
            title="Sayfalandırma ve Gezinme"
          />
          {/*<SideMenuListItem
            className="border-t first:border-t-0"
            onClick={() =>
              setShowSubMenu({
                arrow: true,
              })
            }
            title="Arrow"
          />
          <SideMenuListItem
            className="border-t first:border-t-0"
            onClick={() =>
              setShowSubMenu({
                autoSlide: true,
              })
            }
            title="Auto Slide"
          />*/}
        </div>
      </div>

      <PaginationAndNavigation
        show={ShowSubMenu?.paginationAndNavigation}
        setShow={(e) => setShowSubMenu(e)}
        ThisComponent={ThisComponent}
        setThisComponent={setThisComponent}
      />
      {/*<Arrow
        show={ShowSubMenu?.arrow}
        setShow={(e) => setShowSubMenu(e)}
      />
      <AutoSlide
        show={ShowSubMenu?.autoSlide}
        setShow={(e) => setShowSubMenu(e)}
      />*/}
    </>
  )
}

export default Settings
