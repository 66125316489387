import React from "react"

import Icon from "components/Icon"

// constants
import { evaGlobalImageUrl } from "constants/index"

const MobileView = (props) => {
  let { logo, color, borderRadius, fontFamily, domainType } = props

  return (
    <>
      <div
        className="w-[180px] text-[6px] border-4 rounded-2xl bg-white"
        style={{ fontFamily: fontFamily }}
      >
        {domainType === "B2C" ? (
          <>
            <div className="main-header border-b border-gray-200 py-2 px-2">
              <div className="lp-container flex items-center justify-between">
                <div className="logo-wrapper">
                  <img
                    alt="logo"
                    className="object-cover max-w-[120px] max-h-[18px]"
                    src={logo}
                  />
                </div>
                <div className="navbar">
                  <ul className="flex items-center">
                    <li className="flex ml-3 p-1 first:ml-0">
                      <Icon
                        name="user"
                        size="8"
                        color="#1D2939"
                      />
                    </li>
                    <li className="flex ml-1 p-1 first:ml-0">
                      <Icon
                        name="menu"
                        size="8"
                        color="#1D2939"
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="sub-header bg-white text-[6px]">
              <div className="lp-container">
                <nav>
                  <ul className="flex items-center text-gray-500 font-medium whitespace-nowrap overflow-hidden">
                    {[
                      { text: "Otel", icon: "building" },
                      { text: "Uçak", icon: "plane" },
                      { text: "Tur", icon: "suitcase" },
                      { text: "Transfer", icon: "car" },
                      { text: "Paketle Kazan", icon: "special_days" },
                    ].map((item, key) => {
                      return (
                        <li
                          key={key}
                          className={`flex items-center justify-center text-center py-1 ml-1 first:ml-0 px-2 ${
                            key === 0 ? " font-semibold border-b-2 cursor-pointer" : "cursor-not-allowed"
                          }`}
                          style={key === 0 ? { borderBottomColor: color } : {}}
                        >
                          <Icon
                            className="mr-1"
                            name={item.icon}
                            size="8"
                            color="#667085"
                          />
                          <span className="">{item.text}</span>
                        </li>
                      )
                    })}
                  </ul>
                </nav>
              </div>
            </div>
            <div className="travel-planner">
              <div
                className="search-area relative overflow-hidden z-10 bg-cover bg-[center_70%]
            bg-gray-50"
              >
                <div className="lp-container relative z-20 py-2 px-2">
                  <div className="text-wrapper text-white w-11/12">
                    <div className="heading text-xs font-bold drop-shadow-sm text-gray-800">
                      Sıradaki <br /> konaklamanızı bulun.
                    </div>
                    <div className="text text-[6px] font-medium drop-shadow-sm mt-1 text-gray-500">
                      Oteller, evler ve çok daha fazlası için fırsatları kaçırmayın...
                    </div>
                  </div>
                  <div className="searchbar flex flex-col overflow-hidden !transition-all mt-1">
                    {[
                      { placeholder: "Nereye gidiyorsun?", icon: "search" },
                      { placeholder: "Giriş - Çıkış tarihi seçin", icon: "calendar" },
                      { placeholder: "2 misafir, 1 oda", icon: "person" },
                    ].map((v, k) => {
                      return (
                        <div
                          key={k}
                          className="input-wrapper w-full relative shadow-buttonSm border border-gray-200 overflow-hidden !transition-all mt-1 flex items-center justify-center"
                          style={{ borderRadius: borderRadius }}
                        >
                          <Icon
                            className="absolute top-1/2 left-2 transform -translate-y-1/2 pointer-events-none"
                            name={v.icon}
                            size="8"
                            color={`${k === 2 ? "#000" : "#667085"}`}
                          />
                          <input
                            type="text"
                            className={`w-full !h-4 placeholder:text-[6px] !pl-5 pb-[6px] border-none disabled:cursor-not-allowed disabled:!bg-white disabled:!pointer-events-auto ${
                              k === 2 ? "placeholder:text-gray-800" : "placeholder:text-gray-500"
                            }`}
                            placeholder={v.placeholder}
                            disabled
                          />
                        </div>
                      )
                    })}
                    <button
                      className="whitespace-nowrap text-white w-full px-7 border-0 cursor-not-allowed !transition-all mt-1 text-[6px] h-4 flex justify-center items-center"
                      style={{ backgroundColor: color, borderRadius: borderRadius }}
                    >
                      Otel ara
                    </button>
                  </div>
                </div>
              </div>
              <div className="features p-2">
                <div
                  className="lp-container flex flex-col border border-gray-200 rounded-lg items-center"
                  style={{ borderRadius: borderRadius }}
                >
                  {[
                    { text: "Fiyat farkı iade garantisi", icon: "rotate-ccw" },
                    { text: "Güvenli online alışveriş", icon: "shield" },
                    { text: "18 aya varan taksitler", icon: "credit-card" },
                  ].map((v, k) => {
                    return (
                      <div
                        key={k}
                        className="features-box w-full flex items-center justify-center text-center py-1"
                      >
                        <Icon
                          className="mr-1"
                          name={v.icon}
                          size="8"
                          color="#475467"
                        />
                        <span className="font-medium text-gray-500">{v.text}</span>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="w-full bg-white container py-2 flex flex-row justify-center rounded-2xl pt-1">
              <img
                className="h-3"
                alt="logo"
                src={logo}
              />
            </div>
            <div className="w-full flex justify-center items-center py-1 bg-[#FAFAFA] border-2 border-solid border-gray-200 text-[4px]">
              <div className="flex justify-center p-1">
                <div
                  className="bg-white flex gap-1 border p-1 border-solid border-gray-200"
                  style={{ borderRadius: borderRadius }}
                >
                  <p className="">25 Temmuz - 1 Ağustos</p>
                  <p className="">1 oda, 2 yetişkin</p>
                </div>
                <div
                  className="ml-2 flex justify-center items-center border px-1"
                  style={{ backgroundColor: color, borderRadius: borderRadius }}
                >
                  <Icon
                    name="search"
                    color="#FFF"
                    size="8"
                  />
                </div>
              </div>
            </div>
            <div className="p-2">
              <div className="flex justify-between items-center">
                <p className="text-gray-800 font-semibold mb-1">Kampanyalar</p>
                <div className="flex flex-row gap-1">
                  {Array.from(Array(4), (_, index) => (
                    <div
                      key={index}
                      className="w-[2px] h-[2px] bg-[#D3D4D7] rounded-full"
                    />
                  ))}
                </div>
              </div>
              <div className="flex flex-row justify-between bg-[#FAFAFA]">
                <p className="text-[#474F61]">₺ 1000 ve üzeri anında ₺200 indirim!</p>
                <Icon
                  name="chevron-right"
                  color="#474F61"
                  size="8"
                />
              </div>
            </div>
            <div className="w-full flex justify-between px-2 mb-1">
              <div className="flex justify-between w-full">
                <p className="font-bold">Oda Seçenekleri</p>
                <p className="text-[#474F61]">2 yetişkin</p>
              </div>
            </div>
            <div className="p-2 bg-[#FAFAFA] flex flex-col rounded-b-2xl">
              <p className="font-semibold mb-1">Deluxe Oda - Bahçe Manzaralı</p>
              <div className="flex">
                <img
                  alt="hotel"
                  className="w-2/5 mr-1 object-cover"
                  src={`${evaGlobalImageUrl}cms/media/hotel/room/d1bf02dd-7dc1-4bc5-95b7-69817b2d944c.jpg`}
                  style={borderRadius !== "24px" ? { borderRadius: borderRadius } : { borderRadius: "12px" }}
                />
                <div className="flex flex-col w-full">
                  <div className="flex gap-1 mb-1">
                    <Icon
                      name="size"
                      color="#01133D"
                      size="8"
                    />
                    <p className="">10 m2</p>
                    <Icon
                      name="image"
                      color="#01133D"
                      size="8"
                    />
                    <p className="">Bahçe manzarası</p>
                  </div>
                  <div className="flex gap-1 mb-1">
                    <Icon
                      name="no-smoking"
                      color="#01133D"
                      size="8"
                    />
                    <p className="">Sigara içilmez</p>
                    <Icon
                      name="wifi"
                      color="#01133D"
                      size="8"
                    />
                    <p className="">Wi-Fi</p>
                  </div>
                  <div className="flex items-center">
                    <p
                      className=" font-semibold"
                      style={{ color: color }}
                    >
                      Tüm özellikleri gör
                    </p>
                    <Icon
                      name="chevron-right"
                      color={color}
                      size="8"
                    />
                  </div>
                </div>
              </div>
              <div
                className="flex flex-row p-2 bg-white border border-solid border-gray-200 mt-2"
                style={{ borderRadius: borderRadius }}
              >
                <div className="w-1/2 flex flex-col gap-1 pt-1">
                  <div className="flex items-center">
                    <div className="flex items-center">
                      <Icon
                        className="mr-2"
                        name="check"
                        size="8"
                      />
                      <p className="border-b border-dashed border-gray-200 inline-block">Ücretsiz iptal</p>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <Icon
                      className="mr-2"
                      name="non_refundable_new"
                      size="8"
                    />
                    <p className="border-b border-dashed border-gray-200 inline-block">Geri Ödemesiz</p>
                  </div>
                  <div className="flex items-center">
                    <Icon
                      className="mr-2"
                      name="meal"
                      size="8"
                    />
                    <p className="border-b border-dashed border-gray-200 inline-block">Ultra her şey dahil</p>
                  </div>
                  <div className="flex items-center">
                    <Icon
                      className="mr-2"
                      name="discount"
                      size="8"
                    />
                    <p className="border-b border-dashed border-gray-200 inline-block">Erken rezervasyon</p>
                  </div>
                </div>
                <div className="w-1/2 flex flex-col gap-1">
                  <div className="flex justify-end">
                    <p
                      className="bg-[#FAFAFA] p-1 text-gray-800 font-medium"
                      style={{ borderRadius: borderRadius }}
                    >
                      %30 indirim
                    </p>
                  </div>
                  <p className="text-[#8A92A7] line-through flex justify-end">₺ 5.500</p>
                  <p className="text-gray-800 font-semibold flex justify-end">₺ 4.500</p>
                  <p className="text-[#474F61] flex justify-end">5 gece için</p>
                  <p
                    className="font-medium flex justify-end mb-2"
                    style={{ color: color }}
                  >
                    Fiyat Bilgileri
                  </p>
                  <div className="flex items-center justify-end">
                    <div
                      className="border border-solid px-1 py-[2px]"
                      style={{ borderColor: color, borderRadius: borderRadius }}
                    >
                      <p
                        className="font-medium"
                        style={{ color: color }}
                      >
                        Oda Seç
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default MobileView
