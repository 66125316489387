import ExcelJS from "exceljs"
import saveAs from "file-saver"
import moment from "moment"

export const exportToExcel = (data, headersArr) => {
  const workbook = new ExcelJS.Workbook()
  const worksheet = workbook.addWorksheet("Sheet1")

  const headers = headersArr
  worksheet.addRow(headers)

  data?.forEach((item) => {
    const row = Object.values(item).map((value, index) => {
      const header = headers[index]
      if (header?.includes("Check-In") || header?.includes("Check-Out") || header?.includes("Tarihi")) {
        if (value !== null && value !== undefined) {
          return moment(value).format("DD.MM.YYYY")
        } else {
          return "-"
        }
      }
      return value !== null && value !== undefined ? value : "-"
    })
    worksheet.addRow(row)
  })

  workbook.xlsx.writeBuffer().then((buffer) => {
    saveAs(new Blob([buffer]), "data.xlsx")
  })
}
