import React, { useState } from "react"
import { LineChart, Line } from "recharts"

import Icon from "components/Icon"
import { data } from "./data"

const Earning = () => {
  const [activeTab, setActiveTab] = useState(1)

  return (
    <div className="border border-gray-200 rounded-lg p-6 flex flex-col gap-6 bg-white">
      <div className="flex justify-between items-center">
        <h2 className="text-xl text-gray-700 font-semibold">Kazanç</h2>
        <div className="flex">
          <button
            className={`text-sm text-gray-500 px-2 pb-2 font-medium ${
              activeTab === 1 ? "border-b-blue-500 border-b-2 text-blue-500" : "border-b border-gray-200"
            }`}
            onClick={() => setActiveTab(1)}
          >
            30g
          </button>
          <button
            className={`text-sm text-gray-500 px-2 pb-2 font-medium ${
              activeTab === 2 ? "border-b-blue-500 border-b-2 text-blue-500" : "border-b border-gray-200"
            }`}
            onClick={() => setActiveTab(2)}
          >
            7g
          </button>
          <button
            className={`text-sm text-gray-500 px-2 pb-2 font-medium ${
              activeTab === 3 ? "border-b-blue-500 border-b-2 text-blue-500" : "border-b border-gray-200"
            }`}
            onClick={() => setActiveTab(3)}
          >
            1g
          </button>
        </div>
      </div>
      <div className="flex justify-between items-center">
        <div className="flex flex-col w-1/2">
          <span className="text-4xl text-gray-800">
            2.600 <span className="text-2xl text-gray-600">₺</span>
          </span>
          <span className="text-xs text-gray-600 flex items-center mt-10">
            {" "}
            <Icon
              name="arrow-up"
              size="16"
              color="#12B76A"
            />
            <span className="ml-2">%5 geçen aya göre</span>
          </span>
        </div>
        <div className="flex justify-end w-1/2 mr-4">
          <LineChart
            width={300}
            height={100}
            data={data}
          >
            <Line
              type="monotone"
              dataKey="pv"
              stroke="#2E90FA"
              strokeWidth={2}
              dot={false}
            />
          </LineChart>
        </div>
      </div>
    </div>
  )
}

export default Earning
