import { call, fork, put, takeLatest } from "redux-saga/effects"
import * as actions from "redux/actions/getPageList"

import { GET_PAGE_LIST_REQUESTED } from "redux/types"
import { API_POST } from "helpers/api"

function* watchGetPageList() {
  yield takeLatest(GET_PAGE_LIST_REQUESTED, getPageList)
}

function* getPageList(action) {
  try {
    const res = yield call(apiGetPageList, action.payload)
    yield put(actions.getPageListSucceed(res?.data))
  } catch (err) {
    yield put(actions.getPageListFailed())
  }
}

const apiGetPageList = (payload) => {
  return API_POST(`${process.env.REACT_APP_DISPLAY_DOMAIN}/api/page/list`, payload)
}

export default function* pageListGet() {
  yield fork(watchGetPageList)
}
