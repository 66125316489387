import React from "react"

// import Swiper core and required modules
import { Navigation, A11y, Parallax } from "swiper"

import { Swiper, SwiperSlide } from "swiper/react"

import TR_logo from "assets/img/flags/small/TR.png"
import tursabImg from "pages/B2cDemoPage/LivePreview/img/tursab_belge.png"
import maestro from "pages/B2cDemoPage/LivePreview/img/credit_cards/maestro.png"
import master from "pages/B2cDemoPage/LivePreview/img/credit_cards/master.png"
import stripe from "pages/B2cDemoPage/LivePreview/img/credit_cards/stripe.png"
import visa from "pages/B2cDemoPage/LivePreview/img/credit_cards/visa.png"
import banner1 from "pages/B2cDemoPage/LivePreview/img/banner-img-1.jpg"
import holiday1 from "pages/B2cDemoPage/LivePreview/img/holiday_themes/1.jpg"
import holiday2 from "pages/B2cDemoPage/LivePreview/img/holiday_themes/2.jpg"
import holiday3 from "pages/B2cDemoPage/LivePreview/img/holiday_themes/3.jpg"
import holiday4 from "pages/B2cDemoPage/LivePreview/img/holiday_themes/4.jpg"
import popular_area1 from "pages/B2cDemoPage/LivePreview/img/popular_areas/1.jpg"
import popular_area2 from "pages/B2cDemoPage/LivePreview/img/popular_areas/2.jpg"
import popular_area3 from "pages/B2cDemoPage/LivePreview/img/popular_areas/3.jpg"

import Icon from "components/Icon"

const B2CDesktop = (props) => {
  let { logo, color, borderRadius, fontFamily } = props

  return (
    <div style={{ fontFamily: fontFamily }}>
      <div className="lp-header relative z-20 bg-white">
        <div className="main-header border-b border-gray-200 py-5">
          <div className="lp-container flex items-center justify-between">
            <div className="logo-wrapper max-w-[140px]">
              <img
                alt="logo"
                className="object-cover max-h-[33px]"
                src={logo}
              />
            </div>
            <div className="navbar">
              <ul className="flex items-center">
                <li className="ml-8 first:ml-0">
                  <div className="language-selector flex items-center">
                    <img
                      className="mr-1"
                      src={TR_logo}
                      alt="Logo"
                    />
                    <span>Türkçe / TL</span>
                  </div>
                </li>
                <li className="ml-8 first:ml-0">Yardım</li>
                <li className="ml-8 first:ml-0">Giriş Yap</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="sub-header  bg-white">
        <div className="lp-container">
          <nav>
            <ul className="flex items-center text-gray-500 font-medium">
              {[
                { text: "Otel", icon: "building" },
                { text: "Uçak", icon: "plane" },
                { text: "Tur", icon: "suitcase" },
                { text: "Transfer", icon: "car" },
                { text: "Paketle Kazan", icon: "special_days" },
              ].map((item, key) => {
                return (
                  <li
                    key={key}
                    className={`flex items-center justify-center text-center py-4 px-2 ml-8 first:ml-0 ${
                      key === 0 ? " font-semibold border-b-2 min-w-[120px] cursor-pointer" : "cursor-not-allowed"
                    }`}
                    style={key === 0 ? { borderBottomColor: color } : {}}
                  >
                    <Icon
                      className="mr-2"
                      name={item.icon}
                      size="20"
                      color="#667085"
                    />
                    <span>{item.text}</span>
                  </li>
                )
              })}
            </ul>
          </nav>
        </div>
      </div>
      <div className="lp-main  bg-white">
        <div className="travel-planner">
          <div
            className="search-area relative overflow-hidden z-10 bg-cover bg-[center_70%]
            after:absolute after:inset-0 after:z-10 bg-[#FAFAFA]"
          >
            <div className="lp-container relative z-20 py-16">
              <div className="text-wrapper">
                <div className="heading text-4xl font-bold drop-shadow-sm text-gray-800 mb-2">Sonraki konaklamanızı bulun.</div>
                <div className="text text-xl font-medium drop-shadow-sm text-gray-500">
                  Oteller, evler ve çok daha fazlası için fırsatları kaçırmayın...
                </div>
              </div>
              <div className="searchbar flex items-center bg-white shadow-_2xl overflow-hidden !transition-all p-4 mt-6">
                {[
                  { placeholder: "Nereye gidiyorsun?", icon: "search" },
                  { placeholder: "Giriş - Çıkış tarihi seçin", icon: "calendar" },
                  { placeholder: "2 misafir, 1 oda", icon: "person" },
                ].map((v, k) => {
                  return (
                    <div
                      key={k}
                      className={`input-wrapper ${
                        k === 2 ? "w-7/12" : "w-full"
                      } relative shadow-buttonSm border border-gray-200 overflow-hidden !transition-all ml-4 first:ml-0`}
                      style={{ borderRadius: borderRadius }}
                    >
                      <Icon
                        className="absolute top-1/2 left-2 transform -translate-y-1/2 pointer-events-none"
                        name={v.icon}
                        size="20"
                        color={`${k === 2 ? "#000" : "#667085"}`}
                      />
                      <input
                        type="text"
                        className={`w-full border-none !pl-9 disabled:cursor-not-allowed disabled:!bg-white disabled:!pointer-events-auto ${
                          k === 2 ? "placeholder:text-gray-800" : "placeholder:text-gray-500"
                        }`}
                        placeholder={v.placeholder}
                        disabled
                      />
                    </div>
                  )
                })}
                <button
                  className="btn whitespace-nowrap px-7 border-0 cursor-not-allowed !transition-all ml-4"
                  style={{ backgroundColor: color, borderRadius: borderRadius }}
                >
                  Otel ara
                </button>
              </div>
            </div>
          </div>
          <div className="features py-6 mb-12">
            <div className="lp-container flex items-center">
              <div className="features-box w-full flex items-center justify-center text-center py-4">
                {[
                  { text: "Fiyat farkı iade garantisi", icon: "rotate-ccw" },
                  { text: "Güvenli online alışveriş", icon: "shield" },
                  { text: "18 aya varan taksitler", icon: "credit-card" },
                ].map((v, k) => {
                  return (
                    <div
                      key={k}
                      className="features-box w-full flex items-center justify-center text-center py-4"
                    >
                      <Icon
                        className="mr-2"
                        name={v.icon}
                        size="16"
                        color="#475467"
                      />
                      <span className="font-medium text-gray-500">{v.text}</span>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="banner-area">
          <div className="banner-navigtion">
            <ul className="flex items-center justify-center text-gray-500 font-medium text-base">
              {["Yaz Fırsatları", "Erken Rezervasyon", "Etkinlikler", "Ödeme Kolaylıkları"].map((v, k) => {
                return (
                  <li
                    key={k}
                    className=" border shadow-buttonSm cursor-pointercursor-not-allowed overflow-hidden !transition-all py-2.5 px-4 ml-8"
                    style={{ borderRadius: borderRadius }}
                  >
                    {v}
                  </li>
                )
              })}
            </ul>
          </div>

          <div className="banner-content mt-6">
            <div className="lp-container relative flex items-center z-10 !transition-all">
              <div className="icon-area absolute right-[calc(100%_+_28px)] flex border border-gray-300 rounded-lg p-[3px] shadow-buttonSm hover:shadow-buttonActive transition banner-swiper-prev">
                <Icon
                  name="arrow-left"
                  size={"24"}
                  color={"#667085"}
                />
              </div>
              <Swiper
                className="overflow-hidden transition-all"
                modules={[Navigation, A11y, Parallax]}
                spaceBetween={0}
                slidesPerView={1}
                parallax={true}
                speed={1200}
                pagination={{
                  el: ".swiper-pagination",
                  clickable: true,
                  dynamicBullets: true,
                }}
                navigation={{
                  prevEl: ".banner-swiper-prev",
                  nextEl: ".banner-swiper-next",
                  disabledClass: "gray-200 pointer-events-none opacity-40",
                }}
              >
                {[1, 2].map((v, k) => {
                  return (
                    <SwiperSlide
                      className={"overflow-hidden"}
                      key={k}
                    >
                      <img
                        className="absolute t-0 l-0 -z-10 h-full w-full object-cover"
                        data-swiper-parallax="35%"
                        data-swiper-parallax-opacity="1"
                        src={banner1}
                        alt=""
                        style={{ borderRadius: borderRadius }}
                      />
                      <div className="text-area w-9/12 py-20 px-10">
                        <p
                          className="text-5xl font-semibold text-white drop-shadow-sm"
                          data-swiper-parallax-x="15%"
                          data-swiper-parallax-opacity="0"
                        >
                          Yaz fırsatları devam ediyor! Kaçırmadan yakala
                        </p>
                        <button
                          className="btn border-none px-8 mt-6 cursor-not-allowed"
                          data-swiper-parallax-x="25%"
                          data-swiper-parallax-y="-10"
                          data-swiper-parallax-opacity="0"
                          style={{ backgroundColor: color }}
                        >
                          Fırsatları gör
                        </button>
                      </div>
                    </SwiperSlide>
                  )
                })}
              </Swiper>
              <div className="icon-area absolute left-[calc(100%_+_28px)] flex border border-gray-300 rounded-lg p-[3px] shadow-buttonSm hover:shadow-buttonActive transition banner-swiper-next">
                <Icon
                  name="arrow-right"
                  size={"24"}
                  color={"#667085"}
                />
              </div>
            </div>
          </div>
        </div>

        {/*Konaklama Seçenekleri*/}
        <div className="accommodation_options my-12">
          <div className="lp-container">
            <p className="text-gray-800 font-semibold text-2xl">Konaklama seçenekleri</p>

            <div className="grid grid-cols-6 gap-4 mt-6">
              {[
                { text: "Bungalovlar", icon: "bungalovs" },
                { text: "Villalar", icon: "villages" },
                { text: "Çiftlik evleri", icon: "farmhouses" },
                { text: "Kayak otelleri", icon: "ski-hotels" },
                { text: "Lüks oteller", icon: "lux-hotels" },
                { text: "Butik oteller", icon: "botique-hotels" },
              ].map((v, k) => {
                return (
                  <div
                    key={k}
                    className={`icon-box flex flex-col items-center border border-gray-200 text-sm font-medium text-color-800 
                      cursor-pointer !transition-all ${k === 0 ? "shadow-xl cursor-pointer" : "cursor-not-allowed"} px-2 py-4`}
                    style={{ borderRadius: borderRadius }}
                  >
                    <Icon
                      name={v.icon}
                      size="48"
                      color="#1D2939"
                    />
                    <span className="mt-1">{v.text}</span>
                  </div>
                )
              })}
            </div>
          </div>
        </div>

        {/*Tatil Temaları*/}
        <div className="holiday-themes mt-12">
          <div className="lp-container">
            <p className="text-gray-800 font-semibold text-2xl">Tatil Temaları</p>

            <div className="relative flex items-center">
              <div className="icon-area absolute right-[calc(100%_+_28px)] flex border border-gray-300 rounded-lg p-[3px] shadow-buttonSm hover:shadow-buttonActive transition holiday-themes-swiper-prev">
                <Icon
                  name="arrow-left"
                  size={"24"}
                  color={"#667085"}
                />
              </div>
              <Swiper
                // install Swiper modules
                className={"mt-6 pt-2 pb-12 px-3 -mx-3"}
                modules={[Navigation, A11y]}
                spaceBetween={15}
                slidesPerView={4}
                navigation={{
                  prevEl: ".holiday-themes-swiper-prev",
                  nextEl: ".holiday-themes-swiper-next",
                  disabledClass: "gray-200 pointer-events-none opacity-40",
                }}
              >
                {[
                  { text: "Balayı otelleri", src: holiday1 },
                  { text: "Her şey dahil oteller", src: holiday2 },
                  { text: "Yakın bölge otelleri", src: holiday3 },
                  { text: "Denize sıfır oteller", src: holiday4 },
                  { text: "Balayı otelleri", src: holiday1 },
                  { text: "Her şey dahil oteller", src: holiday2 },
                  { text: "Yakın bölge otelleri", src: holiday3 },
                  { text: "Denize sıfır oteller", src: holiday4 },
                ].map((v, k) => {
                  return (
                    <SwiperSlide key={k}>
                      <div
                        key={k}
                        className="image-box flex flex-col items-start border border-gray-200 bg-white overflow-hidden text-sm 
                          font-medium text-color-800 transition-all duration-500 hover:shadow-xl cursor-not-allowed"
                        style={{ borderRadius: borderRadius }}
                      >
                        <div className="image-wrapper overflow-hidden">
                          <img
                            className="w-full h-full object-cover transition-all duration-500 hover:scale-125"
                            src={v.src}
                            alt=""
                          />
                        </div>
                        <span className="p-4">{v.text}</span>
                      </div>
                    </SwiperSlide>
                  )
                })}
              </Swiper>
              <div className="icon-area absolute left-[calc(100%_+_28px)] flex border border-gray-300 rounded-lg p-[3px] shadow-buttonSm hover:shadow-buttonActive transition holiday-themes-swiper-next">
                <Icon
                  name="arrow-right"
                  size={"24"}
                  color={"#667085"}
                />
              </div>
            </div>
          </div>
        </div>

        {/*Popüler bölgeler*/}
        <div className="popular-places mt-12">
          <div className="lp-container">
            <p className="text-gray-800 font-semibold text-2xl">Popüler bölgeler</p>

            <div className="relative flex items-center">
              <div className="icon-area absolute right-[calc(100%_+_28px)] flex border border-gray-300 rounded-lg p-[3px] shadow-buttonSm hover:shadow-buttonActive transition popular-places-swiper-prev">
                <Icon
                  name="arrow-left"
                  size={"24"}
                  color={"#667085"}
                />
              </div>
              <Swiper
                // install Swiper modules
                className={"mt-6 pt-2 pb-12 px-3 -mx-3"}
                modules={[Navigation, A11y]}
                spaceBetween={15}
                slidesPerView={3}
                navigation={{
                  prevEl: ".popular-places-swiper-prev",
                  nextEl: ".popular-places-swiper-next",
                  disabledClass: "gray-200 pointer-events-none opacity-40",
                }}
              >
                {[
                  {
                    text: "Antalya otelleri",
                    desc: "Akdeniz’in incisi Antalya sizi bekliyor.",
                    src: popular_area1,
                  },
                  {
                    text: "Belek otelleri",
                    desc: "Antalya’nın gözdesinde yerinizi ayırın.",
                    src: popular_area2,
                  },
                  {
                    text: "Bodrum otelleri",
                    desc: "En güzel tatil Bodrum’da yapılır.",
                    src: popular_area3,
                  },
                  {
                    text: "Antalya otelleri",
                    desc: "Akdeniz’in incisi Antalya sizi bekliyor.",
                    src: popular_area1,
                  },
                  {
                    text: "Belek otelleri",
                    desc: "Antalya’nın gözdesinde yerinizi ayırın.",
                    src: popular_area2,
                  },
                  {
                    text: "Bodrum otelleri",
                    desc: "En güzel tatil Bodrum’da yapılır.",
                    src: popular_area3,
                  },
                ].map((v, k) => {
                  return (
                    <SwiperSlide key={k}>
                      <div
                        key={k}
                        className="image-box flex flex-col items-start border border-gray-200 bg-white overflow-hidden text-base 
                          font-semibold text-color-800 transition-all duration-500 hover:shadow-xl cursor-not-allowed"
                        style={{ borderRadius: borderRadius }}
                      >
                        <div className="imaga-wrapper overflow-hidden">
                          <img
                            className="w-full h-full object-cover transition-all duration-500 hover:scale-125"
                            src={v.src}
                            alt=""
                          />
                        </div>
                        <div className="p-4">
                          <p>{v.text}</p>
                          <p className="text-gray-500 text-sm font-medium mt-0.5">{v.desc}</p>
                        </div>
                      </div>
                    </SwiperSlide>
                  )
                })}
              </Swiper>
              <div className="icon-area absolute left-[calc(100%_+_28px)] flex border border-gray-300 rounded-lg p-[3px] shadow-buttonSm hover:shadow-buttonActive transition popular-places-swiper-next">
                <Icon
                  name="arrow-right"
                  size={"24"}
                  color={"#667085"}
                />
              </div>
            </div>
          </div>
        </div>

        {/*Popüler bölgeler*/}
        <div className="seo-text text-sm text-gray-500 my-12">
          <div className="lp-container">
            <p>
              Jolly ile unutulmaz bir tatil deneyimi sizleri bekliyor. Yurt içi ve yurt dışı yüzlerce farklı konaklama seçeneği ve
              geniş kapsamlı turlar ile uygun fiyatlı tatil paketlerini en ince ayrıntısına kadar sizi düşünerek hazırlayan Jolly,
              35 yıllık deneyimi ve birikimi ile hayalinizdeki tatili gerçeğe dönüştürüyor.
              <br />
              <br />
              Tatilcilerin yol arkadaşı ve gezginlerin daimi başlangıç noktası olan Jolly ile harika bir yaz geçirebilir, muhteşem
              bir balayı yapabilir; maviliklere yelken açtıran gemi turları,keşiflerle dolu yurt dışı turları, Anadolu'yu gezdiren
              kültür turları ile her bütçeye ve zevke uygun tur seçenekleri arasından seçim yaparak içinizdeki keşfetme tutkusunun
              peşine düşebilirsiniz.
              <br />
              <br />
              Tercih sizden, popüler tatil yerleri Jolly'den... Binlerce farklı destinasyonda dilediğiniz tatile çıkabilir, size
              uygun otel seçenekleri arasından tercih yapabilir ve seyahat planınızı sorunsuz bir şekilde gerçekleştirebilirsiniz.
              <br />
              <br />
              Yurt içi tatiliniz için yazın vazgeçilmez rotaları arasında yer alan Ege ve Akdeniz sahillerine uzanabilir; Çeşme,
              Bodrum, Fethiye ve Antalya’nın enfes kumsallarından berrak sularına doğru bir yolculuğa çıkabilirsiniz. Temiz havaya
              doymak ve yeşilin binbir tonuna şahit olmak için Karadeniz turları ile doğa tatili yapabilir, günübirlik veya hafta
              sonu turları ile hayata kısa bir mola verebilir, birbirinden lezzetli yöresel yemeklerin tadına GAP turları ile
              bakabilirsiniz.
              <br />
              <br />
              Gözde destinasyonlardan biri olan Akdeniz'in cennet adası Kıbrıs'ta ise eşsiz bir denizi, benzersiz koyları, tarihi
              ve kültürel öneme sahip durakları ziyaret edebilir, dört mevsim eğlenceyi bir arada bulabilir; muhteşem Kıbrıs
              otellerinde ihtiyacınıza uygun oda seçeneklerinde erken rezervasyon fırsatlarından yararlanarak unutulmaz bir tatil
              deneyimi yaşayabilirsiniz.
              <br />
              <br />
              Tatilinizi yurt dışında geçirmek isterseniz Jolly'nin zengin içerikli gezi programları, onlarca farklı yurt dışı
              tatil rotaları,uçaklı ya da otobüslü ulaşım imkanları, ekonomik ve vizesiz yurt dışı tur seçenekleri, deneyimli
              rehberleri, vize işlemleri, rezervasyon kolaylığı, transfer hizmetleri ve her bütçeye uygun yurt dışı otel
              seçenekleri ile kusursuz bir tatil yapabilirsiniz. Dünyayı keşfetmek için Avrupa'nın birbirinden güzel kentlerinden
              Asya'nın egzotik adalarına uzanabilir, Uzak Doğu'nun gizemli tapınaklarından Afrika'nın vahşi hayatına uzanan
              benzersiz bir maceraya atılabilirsiniz.
            </p>
          </div>
        </div>
      </div>
      <footer className="lp-footer text-xs text-gray-500 border-t border-gray-200  bg-white">
        <div className="lp-container grid grid-cols-4 gap-4 py-8">
          <div className="row">
            <div className="footer-logo-wrapper max-w-[140px]">
              <img
                alt="logo"
                className="object-cover max-h-[90px]"
                src={logo}
              />
            </div>
          </div>
          <div className="row">
            <ul>
              <li className="font-medium text-sm text-gray-800 pb-2">Kurumsal</li>
              {["Hakkımızda", "İletişim", "TÜRSAB Belge No: 286428"].map((v, k) => (
                <li
                  key={k}
                  className="mt-2"
                >
                  {v}
                </li>
              ))}
              <li className="mt-2">
                <img
                  src={tursabImg}
                  alt="Tursab"
                />
              </li>
            </ul>
          </div>
          <div className="row">
            <ul>
              <li className="font-medium text-sm text-gray-800 pb-2">Sözleşmeler</li>
              {[
                "Kullanım sözleşmesi",
                "Gizlilik sözleşmesi",
                "Bilgi güvenliği politikası",
                "KVKK aydınlatma metni",
                "KVKK işlenmesi politikası",
                "Çerez politikası",
              ].map((v, k) => (
                <li
                  key={k}
                  className="mt-2"
                >
                  {v}
                </li>
              ))}
            </ul>
          </div>
          <div className="row">
            <ul>
              <li className="font-medium text-sm text-gray-800 pb-2">Ödeme seçenekleri</li>
              <li className="flex items-center mt-2">
                {[visa, maestro, master, stripe].map((v, k) => {
                  return (
                    <span
                      key={k}
                      className="w-12 h-8 flex items-center justify-center bg-gray-100 rounded-lg ml-1 first:ml-0"
                    >
                      <img
                        src={v}
                        alt="alt"
                      />
                    </span>
                  )
                })}
              </li>
              <li className="mt-4">Sitemizde görmüş olduğunuz fiyatlara KDV dahildir.</li>
              <li className="mt-4">
                <button className="btn bg-gray-100 text-success-500 border-none text-xs py-2 px-3 h-auto font-normal hover:bg-gray-200">
                  <Icon
                    name="shield"
                    size="16"
                    color="#12B76A"
                  />
                  <span>Güvenli alışveriş</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div className="lp-container py-8 text-xs text-gray-400 text-center border-t border-gray-200">
          <p>Bu sitenin tüm seyahat hizmetleri Gordion Teknoloji A.Ş. tarafından verilmektedir. Her hakkı saklıdır. ©2022</p>
        </div>
      </footer>
    </div>
  )
}

export default B2CDesktop
