import React, { useState } from "react"
import { Modal, Button, Grid, Row, Col, Form, RadioGroup, Radio } from "rsuite"
import { Icon } from "components"
import xlsFileImage from "assets/img/fileImages/xlsFileImage.png"
import pdfFileImage from "assets/img/fileImages/pdfFileImage.png"
import jpegFileImage from "assets/img/fileImages/jpegFileImage.png"

import { exportToPDF } from "utils/exportToPdf"
import { exportToExcel } from "utils/exportToExcel"
import { printData } from "utils/printData"

/**
 * @param {Array} data
 * @param {Array} dataHeader
 * @param {boolean} open Visibility of the modal
 * @param {() => void} onClose on close callback
 * @returns
 */
const ExportModal = (props) => {
  const [formValue, setFormValue] = useState({
    selectedExportType: "xls",
  })

  const handleOnChangeForm = (formValue) => {
    setFormValue({ ...formValue })
  }

  const handleOnSubmitForm = (isAllData = false) => {
    let data = props.data
    if (!isAllData) {
      data = props.selectedRowData
      if (!data) {
        data = props.data
      }
    }

    if (formValue.selectedExportType === "xls") {
      exportToExcel(data, props.dataHeader)
    } else if (formValue.selectedExportType === "pdf") {
      exportToPDF(data)
    } else if (formValue.selectedExportType === "jpeg") {
      printData(data, props.dataHeader, true, 400)
    }
  }

  return (
    <>
      <Modal
        open={props.open}
        onClose={props.onClose}
        className="w-[392px] !mt-0 absolute top-1/2 left-1/2 transform !-translate-x-1/2 !-translate-y-1/2"
      >
        <Modal.Header
          closeButton={false}
          className="!px-1"
        >
          <div className="flex justify-between items-center">
            <p className="text-3xl font-semibold">Dışa Aktar</p>
            <span
              className="p-1 !w-8 !h-8 !border !border-solid !border-gray-400 flex items-center rounded-md hover:bg-gray-50 cursor-pointer"
              onClick={props.onClose}
            >
              <Icon
                name="close"
                className="!w-5 !h-5 mx-auto"
              />
            </span>
          </div>
        </Modal.Header>
        <Modal.Body className="text-left">
          <Grid fluid>
            <Row>
              <Col xs={24}>
                <p className="text-gray-600">Dışa aktarma yöntemini belirleyin.</p>
              </Col>
              <Col
                xs={24}
                className="my-6"
              >
                <Row className="flex items-center">
                  <Col xs={12}>
                    <Form
                      formValue={formValue}
                      onChange={handleOnChangeForm}
                    >
                      <Form.Group controlId="radioList">
                        <Form.ControlLabel className="font-semibold">Dosya Türü</Form.ControlLabel>
                        <Form.Control
                          name="selectedExportType"
                          accepter={RadioGroup}
                        >
                          <Radio value="xls">Excel</Radio>
                          <Radio value="pdf">PDF</Radio>
                          <Radio value="jpeg">JPEG</Radio>
                        </Form.Control>
                      </Form.Group>
                    </Form>
                  </Col>
                  <Col
                    xs={12}
                    className=""
                  >
                    <img
                      src={
                        formValue.selectedExportType === "xls"
                          ? xlsFileImage
                          : formValue.selectedExportType === "pdf"
                          ? pdfFileImage
                          : jpegFileImage
                      }
                      alt="info-success"
                      className="mx-auto"
                      height={200}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Grid>
        </Modal.Body>
        <Modal.Footer>
          <Grid fluid>
            <Row>
              <Col xs={24}>
                <Button
                  onClick={() => handleOnSubmitForm(true)}
                  className="btn w-full hover:!text-white"
                >
                  Tümünü Dışa Aktar
                </Button>
              </Col>
              <Col
                xs={24}
                className="mt-4"
              >
                <Button
                  onClick={() => handleOnSubmitForm()}
                  className="btn w-full bg-blue-50 text-blue-500 border border-solid border-blue-50 hover:!text-white hover:!bg-blue-500 hover:!border-blue-500"
                >
                  Seçilenleri Dışa Aktar
                </Button>
              </Col>
            </Row>
          </Grid>
        </Modal.Footer>
      </Modal>
    </>
  )
}

const useExportModal = () => {
  const [open, setOpen] = useState(false)
  const [data, setData] = useState(null)
  /**
   * @param {*} data
   */
  const handleOpen = (data) => {
    setData(data)
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  return {
    open,
    data,
    handleOpen,
    onClose,
  }
}

const ExportData = { useExportModal, ExportModal }
export default ExportData
