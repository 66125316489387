import React, { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"

// images
import Logo from "assets/img/logo.png"
import holiday2 from "./img/holiday_themes/2.jpg"
import holiday3 from "./img/holiday_themes/3.jpg"
import holiday4 from "./img/holiday_themes/4.jpg"

// Styles

// Components
import Icon from "components/Icon"

// utils
import { hexIsLight } from "utils/hexIsLight"
import { isInViewport } from "utils/isInViewport"

const CorpLivePreview = ({ config: { ThemeConfig } }) => {
  // Refs
  const searchAreaImg_ref = useRef()
  const header_ref = useRef()
  const lpBody = useRef()

  const year = new Date().getFullYear()

  // States
  const [ThemeLogo, setThemeLogo] = useState(Logo)
  const [Offset, setOffset] = useState(0)

  // Selectors
  const Device = useSelector((state) => state.DisplayDevice)
  const getConfiguration = useSelector((state) => state.GetConfiguration.result)

  // Func
  const handleScroll = (event) => {
    if (event) setOffset(event.currentTarget.scrollTop)
  }

  // Effects
  useEffect(() => {
    if (window.location.pathname === "/setup/preview") {
      setThemeLogo(ThemeConfig.displaySettings.logoValues.src)
    } else if (ThemeConfig?.logoValues?.src) {
      setThemeLogo(ThemeConfig.logoValues.src)
    } else {
      setThemeLogo(Logo)
    }
  }, [ThemeConfig])

  // Attach the scroll listener to the body
  useEffect(() => {
    const body = lpBody.current
    body.addEventListener("scroll", handleScroll)
  }, [])

  // Attach the scroll listener to the body
  useEffect(() => {
    if (getConfiguration && window.location.pathname !== "/setup/preview") setThemeLogo(getConfiguration.logoUrl)
  }, [getConfiguration])

  // animations
  useEffect(() => {
    if (Offset && searchAreaImg_ref && header_ref) {
      if (isInViewport(searchAreaImg_ref.current)) {
        searchAreaImg_ref.current.style.backgroundPosition = `50% ${70 - Offset / 30}%`
      }

      if (Offset > 380) {
        header_ref.current.classList.add("sticky")
      } else {
        header_ref.current.classList.remove("sticky")
      }
    }
  }, [Offset, searchAreaImg_ref, header_ref])

  return (
    <div
      ref={lpBody}
      className={`lp-body w-full h-full${Device.id === 0 ? " mobile" : ""} bg-white`}
      style={{
        fontFamily: ThemeConfig.displaySettings.fontFamily,
        overflow: "auto",
        height: Device.id === 0 ? "calc(100% - (56px + 84px))" : "",
        margin: "auto",
        width: Device.id === 0 ? "375px" : "",
      }}
      onScroll={handleScroll}
    >
      {Device.id === 1 && (
        <>
          <div
            ref={header_ref}
            className="lp-header relative z-20 bg-white"
          >
            <div className="main-header border-b border-gray-200 py-5">
              <div className="lp-container flex items-center justify-between">
                <div className="logo-wrapper max-w-[140px]">
                  <img
                    className="object-cover max-h-[33px]"
                    src={ThemeLogo}
                    alt={ThemeConfig?.logoValues?.name}
                  />
                </div>
                <div className="navbar">
                  <ul className="flex items-center">
                    <li className="ml-8 first:ml-0">
                      <div className="language-selector flex items-center">
                        <Icon
                          className="mr-2"
                          name={"globe"}
                          size="20"
                          color={"#667085"}
                        />
                        <span>Türkçe / TL</span>
                      </div>
                    </li>
                    <li className="ml-8 first:ml-0">
                      <div className="language-selector flex items-center">
                        <Icon
                          className="mr-2"
                          name={"help-circle"}
                          size="20"
                          color={"#667085"}
                        />
                        <span>Yardım</span>
                      </div>
                    </li>
                    <li className="ml-8 first:ml-0 border border-gray-200 px-4 py-[10px]">
                      <div className="language-selector flex items-center">
                        <Icon
                          className="mr-2"
                          name={"inbox"}
                          size="20"
                          color={"#667085"}
                        />
                        <span>Taleplerim</span>
                      </div>
                    </li>
                    <li className="ml-8 first:ml-0 border border-gray-200 px-4 py-[10px]">
                      <div className="language-selector flex items-center">
                        <Icon
                          className="mr-2"
                          name={"user"}
                          size="20"
                          color={"#667085"}
                        />
                        <span>Can Yılm...</span>
                        <Icon
                          className="mr-2"
                          name={"chevron-down"}
                          size="20"
                          color={"#667085"}
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="sub-header">
            <div className="lp-container">
              <nav>
                <ul className="flex items-center text-gray-500 font-medium">
                  {[
                    { text: "Otel", icon: "building" },
                    { text: "Uçak", icon: "plane" },
                    { text: "Transfer", icon: "car" },
                  ].map((item, key) => {
                    return (
                      <li
                        key={key}
                        className={`flex items-center justify-center text-center py-4 px-2 ml-8 first:ml-0 ${
                          key === 0 ? " font-semibold border-b-2 min-w-[120px] cursor-pointer" : "cursor-not-allowed"
                        }`}
                        style={
                          key === 0
                            ? {
                                color: ThemeConfig.displaySettings.linkColor,
                                borderColor: ThemeConfig.displaySettings.linkColor,
                                textDecoration: ThemeConfig.displaySettings.linkType,
                              }
                            : {
                                textDecoration: ThemeConfig.displaySettings.linkType,
                              }
                        }
                      >
                        <Icon
                          className="mr-2"
                          name={item.icon}
                          size="20"
                          color={`${key === 0 ? ThemeConfig.displaySettings.linkColor : "#667085"}`}
                        />
                        <span>{item.text}</span>
                      </li>
                    )
                  })}
                </ul>
              </nav>
            </div>
          </div>
          <div className="lp-main">
            <div className="travel-planner">
              <div
                ref={searchAreaImg_ref}
                className="search-area relative overflow-hidden z-10 bg-cover bg-[center_70%]
            after:absolute after:inset-0 after:z-10 after:bg-gray-50 after:opacity-30"
              >
                <div className="lp-container relative z-20 py-16">
                  <div className="text-wrapper">
                    <div className="heading text-3xl font-bold drop-shadow-sm mb-2">İş seyahatleri için konaklama planlayın</div>
                    <div className="text-gray-500 text-xl font-medium drop-shadow-sm">
                      Kendiniz veya iş arkadaşlarınız için konaklama seyahati planlayın
                    </div>
                  </div>
                  <div
                    className="grid grid-cols-3 gap-3 searchbar items-center bg-white shadow-_2xl overflow-hidden !transition-all p-4 mt-6"
                    style={{ borderRadius: ThemeConfig.displaySettings.borderRadius }}
                  >
                    {[
                      { placeholder: "Seyahat yeri?", icon: "search" },
                      { placeholder: "Giriş - Çıkış tarihi", icon: "calendar" },
                      { placeholder: "Kişi ve Oda Bilgisi", icon: "person" },
                      { placeholder: "Kişi Seçimi", icon: "person" },
                      { placeholder: "Seyahat Nedeni", icon: "person" },
                    ].map((v, k) => {
                      return (
                        <div
                          key={k}
                          className={`flex items-center input-wrapper ${"w-full"} relative h-[54px] shadow-buttonSm border border-gray-200 overflow-hidden !transition-all`}
                          style={{ borderRadius: ThemeConfig.displaySettings.borderRadius }}
                        >
                          <Icon
                            className="absolute top-1/2 left-2 transform -translate-y-1/2 pointer-events-none"
                            name={v.icon}
                            size="20"
                            color={`${k === 2 ? "#000" : "#667085"}`}
                          />
                          <input
                            type="text"
                            className={`w-full border-none !pl-9 disabled:cursor-not-allowed disabled:!bg-white disabled:!pointer-events-auto ${
                              k === 2 ? "placeholder:text-gray-800" : "placeholder:text-gray-500"
                            }`}
                            placeholder={v.placeholder}
                            disabled
                          />
                        </div>
                      )
                    })}
                    <button
                      className={`h-[54px] btn whitespace-nowrap px-7 border-0 cursor-not-allowed !transition-all`}
                      style={{
                        backgroundColor: ThemeConfig.displaySettings.buttonColor,
                        borderRadius: ThemeConfig.displaySettings.borderRadius,
                        color: ThemeConfig.displaySettings?.buttonColor
                          ? hexIsLight(ThemeConfig.displaySettings.buttonColor)
                            ? "black"
                            : "white"
                          : "",
                      }}
                    >
                      Otel ara
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* Konaklama Talepleri */}
            <div className="lp-container">
              <div className="flex items-center justify-between mb-1">
                <div className="text-2xl font-semibold text-gray-800">Konaklama Taleplerim</div>
                <div className="text-sm text-blue-500 p-2">Tümünü gör</div>
              </div>

              <div className="sub-header border-b border-b-gray-200">
                <div className="lp-container">
                  <nav>
                    <ul className="flex items-center text-gray-500 font-medium">
                      {[{ text: "Gelen Talepler" }, { text: "Oluşturduklarım" }].map((item, key) => {
                        return (
                          <li
                            key={key}
                            className={`flex items-center justify-center text-center text-sm py-4 px-2 ml-8 first:ml-0 ${
                              key === 0 ? " font-semibold border-b-2 min-w-[194px] cursor-pointer" : "cursor-not-allowed"
                            }`}
                            style={
                              key === 0
                                ? {
                                    color: ThemeConfig.displaySettings.linkColor,
                                    borderColor: ThemeConfig.displaySettings.linkColor,
                                    textDecoration: ThemeConfig.displaySettings.linkType,
                                  }
                                : {
                                    textDecoration: ThemeConfig.displaySettings.linkType,
                                  }
                            }
                          >
                            <span>{item.text}</span>
                          </li>
                        )
                      })}
                    </ul>
                  </nav>
                </div>
              </div>
              <div className="grid grid-cols-3 gap-4 mt-3 mb-16">
                <div
                  className="py-3 px-2 border border-gray-200 flex items-center justify-between"
                  style={{
                    borderRadius: ThemeConfig.displaySettings.borderRadius,
                  }}
                >
                  <div className="text-lg font-medium text-gray-600 ml-4">Onay Bekleyen</div>
                  <div className="text-center py-5 px-6 text-2xl font-semibold text-warning-500 w-[70px] h-[70px] bg-warning-50">
                    7
                  </div>
                </div>
                <div
                  className="py-3 px-2 border border-gray-200 flex items-center justify-between"
                  style={{
                    borderRadius: ThemeConfig.displaySettings.borderRadius,
                  }}
                >
                  <div className="text-lg font-medium text-gray-600 ml-4">Onayladıklarım</div>
                  <div className="text-center py-5 px-6 text-2xl font-semibold text-success-500 w-[70px] h-[70px] bg-success-50">
                    7
                  </div>
                </div>
                <div
                  className="py-3 px-2 border border-gray-200 flex items-center justify-between"
                  style={{
                    borderRadius: ThemeConfig.displaySettings.borderRadius,
                  }}
                >
                  <div className="text-lg font-medium text-gray-600 ml-4">Reddettiklerim</div>
                  <div className="text-center py-5 px-6 text-2xl font-semibold text-error-500 w-[70px] h-[70px] bg-error-50">
                    7
                  </div>
                </div>
              </div>
            </div>

            {/* Yaklaşan Otel Rezervasyonlarım */}

            <div className="lp-container mb-16">
              <div className="flex items-center justify-between mb-4">
                <div className="text-2xl font-semibold text-gray-800">Yaklaşan Otel Rezervasyonlarım</div>
                <div className="text-sm text-blue-500 p-2">Tümünü gör</div>
              </div>
              <div className="flex flex-col gap-4">
                <div className="flex gap-6 border border-gray-200">
                  <div>
                    <img
                      className="h-[146px]"
                      src={holiday4}
                      alt=""
                    />
                  </div>
                  <div className="flex flex-col gap-4 p-4 justify-between">
                    <div className="flex flex-col gap-1">
                      <div className="text-lg font-semibold text-gray-800">Bursa İş Seyahati</div>
                      <div className="text-sm text-gray-500">Bursa / Mudanya</div>
                    </div>
                    <div className="grid grid-cols-4 gap-3">
                      <div className="flex flex-col items-start justify-center w-[160px]">
                        <span className="text-sm text-gray-500 font-medium">Otel Adı</span>
                        <span className="text-gray-800">Trilye Resort</span>
                      </div>
                      <div className="flex flex-col items-start justify-center w-[160px]">
                        <span className="text-sm text-gray-500 font-medium">Giriş-Çıkış Tarihleri</span>
                        <span className="text-gray-800">12 Eyl- 18 Eyl</span>
                      </div>
                      <div className="flex flex-col items-start justify-center w-[160px]">
                        <span className="text-sm text-gray-500 font-medium">Kişi ve Oda Bilgisi</span>
                        <span className="text-gray-800">2 kişi, 1 oda</span>
                      </div>
                      <div className="flex flex-col items-start justify-center w-[160px]">
                        <span className="text-sm text-gray-500 font-medium">Kişiler</span>
                        <span className="text-gray-800 whitespace-nowrap">Can Yılmazer</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex gap-6 border border-gray-200">
                  <div>
                    <img
                      className="h-[146px]"
                      src={holiday2}
                      alt=""
                    />
                  </div>
                  <div className="flex flex-col gap-4 p-4 justify-between">
                    <div className="flex flex-col gap-1">
                      <div className="text-lg font-semibold text-gray-800">Antalya Eğitim Seyahati</div>
                      <div className="text-sm text-gray-500">Belek / Antalya</div>
                    </div>
                    <div className="grid grid-cols-4 gap-3">
                      <div className="flex flex-col items-start justify-center w-[160px]">
                        <span className="text-sm text-gray-500 font-medium">Otel Adı</span>
                        <span className="text-gray-800">Trilye Resort</span>
                      </div>
                      <div className="flex flex-col items-start justify-center w-[160px]">
                        <span className="text-sm text-gray-500 font-medium">Giriş-Çıkış Tarihleri</span>
                        <span className="text-gray-800">12 Eyl- 18 Eyl</span>
                      </div>
                      <div className="flex flex-col items-start justify-center w-[160px]">
                        <span className="text-sm text-gray-500 font-medium">Kişi ve Oda Bilgisi</span>
                        <span className="text-gray-800">2 kişi, 1 oda</span>
                      </div>
                      <div className="flex flex-col items-start justify-center w-[160px]">
                        <span className="text-sm text-gray-500 font-medium">Kişiler</span>
                        <span className="text-gray-800 whitespace-nowrap">Can Yılmazer</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex gap-6 border border-gray-200">
                  <div>
                    <img
                      className="h-[146px]"
                      src={holiday3}
                      alt=""
                    />
                  </div>
                  <div className="flex flex-col gap-4 p-4 justify-between">
                    <div className="flex flex-col gap-1">
                      <div className="text-lg font-semibold text-gray-800">Cunda Etkinlik Seyahati</div>
                      <div className="text-sm text-gray-500">Cunda / Balıkesir</div>
                    </div>
                    <div className="grid grid-cols-4 gap-3">
                      <div className="flex flex-col items-start justify-center w-[160px]">
                        <span className="text-sm text-gray-500 font-medium">Otel Adı</span>
                        <span className="text-gray-800">Trilye Resort</span>
                      </div>
                      <div className="flex flex-col items-start justify-center w-[160px]">
                        <span className="text-sm text-gray-500 font-medium">Giriş-Çıkış Tarihleri</span>
                        <span className="text-gray-800">12 Eyl- 18 Eyl</span>
                      </div>
                      <div className="flex flex-col items-start justify-center w-[160px]">
                        <span className="text-sm text-gray-500 font-medium">Kişi ve Oda Bilgisi</span>
                        <span className="text-gray-800">2 kişi, 1 oda</span>
                      </div>
                      <div className="flex flex-col items-start justify-center w-[160px]">
                        <span className="text-sm text-gray-500 font-medium">Kişiler</span>
                        <span className="text-gray-800 whitespace-nowrap">Can Yılmazer</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <footer className="lp-footer text-xs text-gray-500 border-t border-gray-200">
            <div className="lp-container">
              <div className="row flex justify-center py-8">
                <div className="footer-logo-wrapper max-w-[140px]">
                  <img
                    className="object-cover max-h-[90px]"
                    src={ThemeLogo}
                    alt={ThemeConfig?.logoValues?.name}
                  />
                </div>
              </div>
            </div>
            <div className="lp-container py-8 text-xs text-gray-400 text-center border-t border-gray-200">
              <p>
                Bu sitenin tüm seyahat hizmetleri Gordion Teknoloji A.Ş. tarafından verilmektedir. Her hakkı saklıdır. ©{year}
              </p>
            </div>
          </footer>
        </>
      )}
    </div>
  )
}

export default CorpLivePreview
