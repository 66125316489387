import { call, fork, put, takeLatest } from "redux-saga/effects"
import * as actions from "redux/actions/getAgreement"

import { GET_AGREEMENT_REQUESTED } from "redux/types"
import { API_GET } from "helpers/api"

function* watchGetAgreement() {
  yield takeLatest(GET_AGREEMENT_REQUESTED, getAgreement)
}

function* getAgreement(action) {
  try {
    const res = yield call(apiGetAgreement, action.payload)
    yield put(actions.agreementSucceed(res?.data?.result))
  } catch (err) {
    yield put(actions.agreementFailed())
  }
}

const apiGetAgreement = () => {
  return API_GET(`${process.env.REACT_APP_PANEL_DOMAIN}/api/panelagreement`)
}

export default function* agreementGet() {
  yield fork(watchGetAgreement)
}
