import React from "react";

//layouts
import PartialWrapper from "../../PartialWrapper";

//components
import {Subtitle, RangeInput} from "components";

const Margin = (props) => {
	let { showSubLayout, setShowSubLayout, visualValues, setVisualValues } = props

	const goBackHandleClick = () => {
		setShowSubLayout(null)
	}

	return (

		<PartialWrapper
			className={`margin-layout${showSubLayout?.Margin ? " active" : ""}`}
			goBackOnClick={goBackHandleClick}
		>
			<Subtitle>Kenar Boşluğu</Subtitle>

			<RangeInput
				className={"mt-3"}
				min={0}
				max={40}
				onChange={e => setVisualValues({...visualValues, componentMargin: e})}
				rangeLabel="Kenar boşluğu (px)"
				//defaultRangeValue={24}
				rangeValue={visualValues?.componentMargin}
			/>
		</PartialWrapper>
	)

}

export default Margin