import React from "react"

const MyHotelSkeleton = () => {
  return (
    <main>
      <section className="p-10">
        <div className="my-0 mx-auto flex flex-col page-container xl:w-9/12 w-full transition-[width] duration-500">
          <div className="animate-pulse space-y-3 h-2 bg-slate-200 rounded col-span-1 w-1/4 mt-10 mb-3" />
          <div className="animate-pulse space-y-3 h-2 bg-slate-200 rounded col-span-1 w-1/2 mb-10" />
          <div className="flex bg-white border border-solid border-gray-200 justify-between px-12 py-4 mb-10 gap-24 w-full h-[300px]">
            <div className="flex flex-col w-1/2 justify-center">
              <div className="animate-pulse space-y-3 my-7">
                <div className="h-2 bg-slate-200 rounded w-1/3" />
                <div className="h-2 bg-slate-200 rounded" />
              </div>
              <div className="animate-pulse space-y-3 my-7">
                <div className="h-2 bg-slate-200 rounded w-1/3" />
                <div className="h-2 bg-slate-200 rounded" />
              </div>
            </div>
            <div className="flex flex-col w-1/2 justify-center">
              <div className="animate-pulse space-y-3 my-7">
                <div className="h-2 bg-slate-200 rounded w-1/3" />
                <div className="h-2 bg-slate-200 rounded" />
              </div>
              <div className="animate-pulse space-y-3 my-7">
                <div className="h-2 bg-slate-200 rounded w-1/3" />
                <div className="h-2 bg-slate-200 rounded" />
              </div>
            </div>
          </div>
          <div className="flex bg-white border border-solid border-gray-200 justify-between px-12 py-4 mb-10 gap-24 w-full h-[300px]">
            <div className="flex flex-col w-1/2 justify-center">
              <div className="animate-pulse space-y-3 my-7">
                <div className="h-2 bg-slate-200 rounded w-1/3" />
                <div className="h-2 bg-slate-200 rounded" />
              </div>
              <div className="animate-pulse space-y-3 my-7">
                <div className="h-2 bg-slate-200 rounded w-1/3" />
                <div className="h-2 bg-slate-200 rounded" />
              </div>
            </div>
            <div className="flex flex-col w-1/2 justify-center">
              <div className="animate-pulse space-y-3 my-7 justify-end">
                <div className="h-10 bg-slate-200 rounded w-1/3 float-right" />
              </div>
              <div className="animate-pulse space-y-3 my-7">
                <div className="h-2 bg-slate-200 rounded w-1/3" />
                <div className="h-2 bg-slate-200 rounded" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default MyHotelSkeleton
