import { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useParams, useNavigate } from "react-router-dom"
import { Link } from "react-router-dom"

import { TitleH1 } from "components"
import Icon from "components/Icon"
import InfoBox from "components/Contracts/InfoBox"
import Header from "components/Headers/MainHeader"
import ContractsSkeleton from "skeletons/Conracts"

import { agreementRequested } from "redux/actions/getAgreement"

const View = ({ isLogged }) => {
  const dispatch = useDispatch()
  let { contractId } = useParams()
  let navigate = useNavigate()

  const agreementData = useSelector((state) => state.getAgreement.list?.agreements)
  const agreementLoading = useSelector((state) => state.getAgreement.loading)

  const [FilteredAgreementData, setfilteredgAgreementData] = useState()
  const [VisibleSuccess, setVisibleSuccess] = useState(false)
  const [FullScreen, setFullScreen] = useState(false)

  useEffect(() => {
    if (sessionStorage.getItem("saveAgreementSuccess") === "true") {
      setVisibleSuccess(true)
    } else {
      setVisibleSuccess(false)
    }
    dispatch(agreementRequested())
  }, [dispatch])

  useEffect(() => {
    if (agreementData) {
      const filteredAgreementData = agreementData?.find((item) => item.typeValue === contractId * 1)
      setfilteredgAgreementData(filteredAgreementData)
    }
  }, [agreementData, contractId])

  return (
    <>
      <Header isLogged={isLogged} />
      {agreementLoading && <ContractsSkeleton />}
      {!agreementLoading && (
        <main>
          <section className="p-10 my-0 mb-20 mx-auto max-w-[1440px]">
            <button
              className="flex items-center gap-2 cursor-pointer mb-6"
              onClick={() => {
                navigate(`/contracts`)
                sessionStorage.setItem("saveAgreementSuccess", false)
              }}
            >
              <Icon
                name="arrow-left"
                size="16"
                color="#667085"
              />
              <span className="text-gray-500">Sözleşmelere dön</span>
            </button>
            <div className="page-top-area flex justify-between items-center mb-6 text-right">
              <div className="page-title">
                <TitleH1 className="text-3xl font-bold">{FilteredAgreementData?.type}</TitleH1>
              </div>
              <button className="btn lg">
                <Link to={`/contracts/edit/${contractId}`}>Düzenle</Link>
              </button>
            </div>
            <InfoBox
              isVisible={VisibleSuccess}
              onClose={() => setVisibleSuccess(false)}
              icon="check-circle"
              bgColor={"#F6FEF9"}
              iconColor={"#039855"}
              iconBgColor={"#D1FADF"}
              title="Ayarlarınız başarıyla kaydedildi!"
              content="Yaptığınız değişikliklerinizi ‘’Ön İzleme’’ kısmından veya ürününüze ait ‘’Footer’’ alanından görüntüleyebilirsiniz."
            />
            {FullScreen ? (
              <div className="bg-[#344054b3] fixed inset-0 z-[60]">
                <div className="bg-gray-200 absolute inset-0 flex justify-center items-center m-auto h-5/6 w-5/6">
                  <div className="bg-white p-8 h-[calc(100%-4rem)] relative w-[90%]">
                    <div className="flex justify-between items-center py-4 mb-4 border-b sticky top-0 bg-white z-1">
                      <p className="text-lg font-semibold">{FilteredAgreementData?.type}</p>
                      <Icon
                        onClick={() => setFullScreen(!FullScreen)}
                        className={"cursor-pointer"}
                        name="minimize-2"
                        size="25"
                        color="#98A2B3"
                      />
                    </div>
                    <div
                      className="bg-white overflow-auto absolute pr-8"
                      style={{ height: "-webkit-fill-available" }}
                    >
                      <div
                        className="custom-reset-html"
                        dangerouslySetInnerHTML={{ __html: FilteredAgreementData?.body }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="bg-gray-200 h-4/6 px-[88px] pt-8 overflow-hidden">
                <div className="bg-white p-8 h-full relative">
                  <div className="flex justify-between items-center py-4 mb-4 border-b sticky top-0 bg-white z-1">
                    <p className="text-lg font-semibold">{FilteredAgreementData?.type}</p>
                    <Icon
                      onClick={() => setFullScreen(!FullScreen)}
                      className={"cursor-pointer"}
                      name="maximize-2"
                      size="20"
                      color="#98A2B3"
                    />
                  </div>
                  <div
                    className="bg-white overflow-auto absolute pr-8"
                    style={{ height: "-webkit-fill-available" }}
                  >
                    <div
                      className="custom-reset-html"
                      dangerouslySetInnerHTML={{ __html: FilteredAgreementData?.body }}
                    />
                  </div>
                </div>
              </div>
            )}
          </section>
        </main>
      )}
    </>
  )
}

export default View
