import Docs from "pages/Docs"
import SignUp from "pages/SignUp"
import Setup from "pages/Setup"
import Live from "pages/health/live"
import StartUp from "pages/health/startup"
import MyReservations from "pages/Reservations/MyReservations"
import MilesAndSmiles from "pages/Reservations/MilesAndSmiles"
import ReservationDetail from "pages/ReservationDetail"
import IntegrationSettings from "pages/IntegrationSettings"
import PublishingPage from "pages/IntegrationSettings/publishingPage"
import ForgotPassword from "pages/ForgetPassword"
import ViewSettings from "pages/ViewSettings"
import RezervationSettings from "pages/ReservationSettings"
import MyAccount from "pages/MyAccount"
import MyHotel from "pages/MyHotel"
import CorporateInformation from "pages/CorporateInformation"
import CorporateInformationEdit from "pages/CorporateInformation"
import DomainDNS from "pages/DomainDNS"
import AccountManagement from "pages/AccountManagement"
import AccountManagementDomains from "pages/AccountManagement/domains"
import AccountManagementProducts from "pages/AccountManagement/products"
import AccountManagementUserList from "pages/AccountManagement/userList"
import B2cDemoPage from "pages/B2cDemoPage"
import ThemeSettings from "pages/B2cDemoPage/themeSettings"
import MarketPlace from "pages/MarketPlace"
import AddonDetail from "pages/MarketPlace/AddonDetail"
import Plugins from "pages/MarketPlace/Plugins"
import PageManager from "./pages/PageManager"
import PageManagerEditPage from "./pages/PageManager/EditPage"
import Dashboard from "pages/Dashboard"
import Defines from "pages/Defines"
import DefineDetail from "pages/Defines/DefineDetail"
import DefineEdit from "pages/Defines/DefineEdit"
import Contracts from "pages/Contracts"
import View from "pages/Contracts/View"
import Editor from "pages/Contracts/Editor"
import Rules from "pages/Rules"
import NoRuleData from "pages/Rules/pages/NoRuleData"
import AddConfirmationRule from "pages/Rules/pages/AddConfirmationRule"
import AddBudgetRule from "pages/Rules/pages/AddBudgetRule"
import AddReservationRule from "pages/Rules/pages/AddReservationRule"
import AddSearchRule from "pages/Rules/pages/AddSearchRule"
import DetailRule from "pages/Rules/pages/DetailRule"
import EditRule from "pages/Rules/pages/EditRule"
import Settings from "pages/Settings"
import UserList from "pages/Settings/UserManagement/UserList"
import UserDetail from "pages/Settings/UserManagement/UserDetail"
import UserEdit from "pages/Settings/UserManagement/UserEdit"
import ReservationReports from "pages/Reports/pages/ReservationReports/ReservationReports"
import Reports from "pages/Reports"
import ReservationReportsDetail from "pages/Reports/pages/ReservationReportsDetail/ReservationReportsDetail"
import GoogleAnalytics from "pages/Settings/GoogleAnalytics/GoogleAnalytics"

const panelUser = "paneluser"
const b2ePanelUser = "panelb2euser"

const routes = [
  {
    path: "/signup",
    exact: true,
    roles: [panelUser, b2ePanelUser],
    component: (props) => <SignUp {...props} />,
  },
  {
    path: "/my-reservations",
    exact: true,
    roles: [panelUser, b2ePanelUser],
    component: (props) => <MyReservations {...props} />,
  },
  {
    path: "/my-reservations/:id",
    exact: true,
    roles: [panelUser, b2ePanelUser],
    component: (props) => <ReservationDetail {...props} />,
  },
  {
    path: "/miles-smiles",
    exact: true,
    roles: [panelUser, b2ePanelUser],
    component: (props) => <MilesAndSmiles {...props} />,
  },
  {
    path: "/miles-smiles/:id",
    exact: true,
    roles: [panelUser, b2ePanelUser],
    component: (props) => <ReservationDetail {...props} />,
  },
  {
    path: "/docs",
    exact: true,
    roles: [panelUser, b2ePanelUser],
    component: (props) => <Docs {...props} />,
  },
  {
    path: "/setup",
    roles: [panelUser, b2ePanelUser],
    exact: false,
    component: (props) => <Setup {...props} />,
  },
  {
    path: "/api/health/live",
    exact: true,
    roles: [panelUser, b2ePanelUser],
    component: (props) => <Live {...props} />,
  },
  {
    path: "/api/health/startup",
    exact: true,
    roles: [panelUser, b2ePanelUser],
    component: (props) => <StartUp {...props} />,
  },
  {
    path: "/integration-settings",
    exact: true,
    roles: [panelUser, b2ePanelUser],
    component: (props) => <IntegrationSettings {...props} />,
  },
  {
    path: "/integration-settings/publishing",
    exact: true,
    roles: [panelUser, b2ePanelUser],
    component: (props) => <PublishingPage {...props} />,
  },
  {
    path: "/forgot-password",
    exact: true,
    roles: [panelUser, b2ePanelUser],
    component: (props) => <ForgotPassword {...props} />,
  },
  {
    path: "/view-settings",
    exact: true,
    roles: [panelUser, b2ePanelUser],
    component: (props) => <ViewSettings {...props} />,
  },
  {
    path: "/reservation-settings",
    exact: true,
    roles: [panelUser, b2ePanelUser],
    component: (props) => <RezervationSettings {...props} />,
  },
  {
    path: "/my-account",
    exact: true,
    roles: [panelUser, b2ePanelUser],
    component: (props) => <MyAccount {...props} />,
  },
  {
    path: "/my-hotel",
    exact: true,
    roles: [panelUser, b2ePanelUser],
    component: (props) => <MyHotel {...props} />,
  },
  {
    path: "/corporate-information",
    exact: true,
    roles: [panelUser, b2ePanelUser],
    component: (props) => <CorporateInformation {...props} />,
  },
  {
    path: "/corporate-information/:edit",
    exact: true,
    roles: [panelUser, b2ePanelUser],
    component: (props) => <CorporateInformationEdit {...props} />,
  },
  //#region admin pages
  {
    path: "/domain-dns",
    exact: true,
    roles: ["paneladmin"],
    component: (props) => <DomainDNS {...props} />,
  },
  {
    path: "/account-management",
    exact: true,
    roles: ["paneladmin"],
    component: (props) => <AccountManagement {...props} />,
  },
  {
    path: "/account-management/domains/:id",
    exact: true,
    roles: ["paneladmin"],
    component: (props) => <AccountManagementDomains {...props} />,
  },
  {
    path: "/account-management/products/:id",
    exact: true,
    roles: ["paneladmin"],
    component: (props) => <AccountManagementProducts {...props} />,
  },
  {
    path: "/account-management/userlist/:id",
    exact: true,
    roles: ["paneladmin"],
    component: (props) => <AccountManagementUserList {...props} />,
  },
  //#endregion admin pages
  {
    path: "/theme-settings",
    exact: true,
    roles: [panelUser, b2ePanelUser],
    component: (props) => <B2cDemoPage {...props} />,
  },
  {
    path: "/theme-settings/configuration",
    exact: true,
    roles: [panelUser, b2ePanelUser],
    component: (props) => <ThemeSettings {...props} />,
  },
  {
    path: "/marketplace",
    exact: true,
    roles: [panelUser, b2ePanelUser],
    component: (props) => <MarketPlace {...props} />,
  },
  {
    path: "/marketplace/plugins",
    exact: true,
    roles: [panelUser, b2ePanelUser],
    component: (props) => <Plugins {...props} />,
  },
  {
    path: "/marketplace/plugins/:id",
    exact: true,
    roles: [panelUser, b2ePanelUser],
    component: (props) => <AddonDetail {...props} />,
  },
  {
    path: "/marketplace/:id",
    exact: true,
    roles: [panelUser, b2ePanelUser],
    component: (props) => <AddonDetail {...props} />,
  },
  {
    path: "/page-manager",
    exact: true,
    roles: [panelUser, b2ePanelUser],
    component: (props) => <PageManager {...props} />,
  },
  {
    path: "/page-manager/editPage/:pageId",
    exact: true,
    roles: [panelUser, b2ePanelUser],
    component: (props) => <PageManagerEditPage {...props} />,
  },
  {
    path: "/dashboard",
    exact: true,
    roles: [panelUser, b2ePanelUser],
    component: (props) => <Dashboard {...props} />,
  },
  {
    path: "/defines",
    exact: true,
    roles: [panelUser, b2ePanelUser],
    component: (props) => <Defines {...props} />,
  },
  {
    path: "/defines/:id",
    exact: true,
    roles: [panelUser, b2ePanelUser],
    component: (props) => <DefineDetail {...props} />,
  },
  {
    path: "/defines/:id/edit",
    exact: true,
    roles: [panelUser, b2ePanelUser],
    component: (props) => <DefineEdit {...props} />,
  },
  {
    path: "/contracts",
    exact: true,
    roles: [panelUser, b2ePanelUser],
    component: (props) => <Contracts {...props} />,
  },
  {
    path: "/contracts/view/:contractId",
    exact: true,
    roles: [panelUser, b2ePanelUser],
    component: (props) => <View {...props} />,
  },
  {
    path: "/contracts/edit/:contractId",
    exact: true,
    roles: [panelUser, b2ePanelUser],
    component: (props) => <Editor {...props} />,
  },
  {
    path: "/rules",
    exact: true,
    roles: [panelUser, b2ePanelUser],
    component: (props) => <Rules {...props} />,
  },
  {
    path: "/rules/notfound",
    exact: true,
    roles: [panelUser, b2ePanelUser],
    component: (props) => <NoRuleData {...props} />,
  },
  {
    path: "/rules/addNewRule/incomeRule",
    exact: true,
    roles: [panelUser, b2ePanelUser],
    component: (props) => <AddBudgetRule {...props} />,
  },
  {
    path: "/rules/addNewRule/reservationRule",
    exact: true,
    roles: [panelUser, b2ePanelUser],
    component: (props) => <AddReservationRule {...props} />,
  },
  {
    path: "/rules/addNewRule/searchRule",
    exact: true,
    roles: [panelUser, b2ePanelUser],
    component: (props) => <AddSearchRule {...props} />,
  },
  {
    path: "/rules/addNewRule/confirmationRule",
    exact: true,
    roles: [panelUser, b2ePanelUser],
    component: (props) => <AddConfirmationRule {...props} />,
  },
  {
    path: "/rules/:id",
    exact: true,
    roles: [panelUser, b2ePanelUser],
    component: (props) => <DetailRule {...props} />,
  },
  {
    path: "/rules/:id/edit",
    exact: true,
    roles: [panelUser, b2ePanelUser],
    component: (props) => <EditRule {...props} />,
  },
  {
    path: "/settings",
    exact: true,
    roles: [panelUser, b2ePanelUser],
    component: (props) => <Settings {...props} />,
  },
  {
    path: "/settings/user-management/list",
    exact: true,
    roles: [panelUser, b2ePanelUser],
    component: (props) => <UserList {...props} />,
  },
  {
    path: "/settings/user-management/:id",
    exact: true,
    roles: [panelUser, b2ePanelUser],
    component: (props) => <UserDetail {...props} />,
  },
  {
    path: "/settings/user-management/:id/edit",
    exact: true,
    roles: [panelUser, b2ePanelUser],
    component: (props) => <UserEdit {...props} />,
  },
  {
    path: "/settings/google-analytics",
    exact: true,
    roles: [panelUser, b2ePanelUser],
    component: (props) => <GoogleAnalytics {...props} />,
  },
  {
    path: "/reports",
    exact: true,
    roles: [panelUser, b2ePanelUser],
    component: (props) => <Reports {...props} />,
  },
  {
    path: "/reports/reservation-reports",
    exact: true,
    roles: [panelUser, b2ePanelUser],
    component: (props) => <ReservationReports {...props} />,
  },
  {
    path: "/reports/reservation-reports/:id",
    exact: true,
    roles: [panelUser, b2ePanelUser],
    component: (props) => <ReservationReportsDetail {...props} />,
  },
]
export default routes
