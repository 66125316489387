import React from "react"

const MyReservationSkeleton = ({className}) => {
  return (
    <main>
      <section className={`${className} p-10 my-5 mx-auto flex flex-col max-w-[1440px]`}>
        <div className="flex justify-between w-full">
          <div className="animate-pulse flex flex-col w-1/2 mb-10 gap-10">
            <div className="h-2 bg-slate-200 rounded w-1/2" />
            <div className="h-2 bg-slate-200 rounded w-full" />
          </div>
          <div className="animate-pulse flex justify-end w-1/3 gap-4">
            <div className="h-8 bg-slate-200 rounded w-1/12" />
            <div className="h-8 bg-slate-200 rounded w-1/12" />
          </div>
        </div>
        <div className="animate-pulse flex w-1/2 mb-10 gap-10">
          <div className="h-2 bg-slate-200 rounded w-1/3" />
          <div className="h-2 bg-slate-200 rounded w-1/3" />
          <div className="h-2 bg-slate-200 rounded w-1/3" />
          <div className="h-2 bg-slate-200 rounded w-1/3" />
        </div>
        <div className="flex flex-col bg-white border border-solid border-gray-200 justify-between mb-10 w-full h-[430px]">
          <div className="flex flex-col w-full justify-center border-b border-b-gray-200 p-6">
            <div className="animate-pulse flex gap-20">
              <div className="h-10 bg-slate-200 rounded w-2/3" />
              <div className="h-10 bg-slate-200 rounded w-1/2" />
              <div className="h-10 bg-slate-200 rounded w-1/4" />
            </div>
          </div>
          <div className="flex flex-col w-full justify-center border-b border-b-gray-200">
            <div className="animate-pulse flex gap-12 justify-between p-6 items-center">
              <div className="h-2 bg-slate-200 rounded w-1/4" />
              <div className="h-2 bg-slate-200 rounded w-1/4" />
              <div className="h-2 bg-slate-200 rounded w-1/4" />
              <div className="h-2 bg-slate-200 rounded w-1/4" />
              <div className="h-2 bg-slate-200 rounded w-1/4" />
              <div className="h-2 bg-slate-200 rounded w-1/4" />
              <div className="h-2 bg-slate-200 rounded w-1/4" />
              <div className="h-2 bg-slate-200 rounded w-1/4" />
            </div>
          </div>
          <div className="flex flex-col w-full justify-center border-b border-b-gray-200">
            <div className="animate-pulse flex gap-12 justify-between p-6 items-center">
              <div className="h-2 bg-slate-200 rounded w-1/4" />
              <div className="h-2 bg-slate-200 rounded w-1/4" />
              <div className="h-2 bg-slate-200 rounded w-1/4" />
              <div className="h-2 bg-slate-200 rounded w-1/4" />
              <div className="h-2 bg-slate-200 rounded w-1/4" />
              <div className="h-2 bg-slate-200 rounded w-1/4" />
              <div className="h-2 bg-slate-200 rounded w-1/4" />
              <div className="h-2 bg-slate-200 rounded w-1/4" />
            </div>
          </div>
          <div className="flex flex-col w-full justify-center border-b border-b-gray-200">
            <div className="animate-pulse flex gap-12 justify-between p-6 items-center">
              <div className="h-2 bg-slate-200 rounded w-1/4" />
              <div className="h-2 bg-slate-200 rounded w-1/4" />
              <div className="h-2 bg-slate-200 rounded w-1/4" />
              <div className="h-2 bg-slate-200 rounded w-1/4" />
              <div className="h-2 bg-slate-200 rounded w-1/4" />
              <div className="h-2 bg-slate-200 rounded w-1/4" />
              <div className="h-2 bg-slate-200 rounded w-1/4" />
              <div className="h-2 bg-slate-200 rounded w-1/4" />
            </div>
          </div>
          <div className="flex flex-col w-full justify-center border-b border-b-gray-200">
            <div className="animate-pulse flex gap-12 justify-between p-6 items-center">
              <div className="h-2 bg-slate-200 rounded w-1/4" />
              <div className="h-2 bg-slate-200 rounded w-1/4" />
              <div className="h-2 bg-slate-200 rounded w-1/4" />
              <div className="h-2 bg-slate-200 rounded w-1/4" />
              <div className="h-2 bg-slate-200 rounded w-1/4" />
              <div className="h-2 bg-slate-200 rounded w-1/4" />
              <div className="h-2 bg-slate-200 rounded w-1/4" />
              <div className="h-2 bg-slate-200 rounded w-1/4" />
            </div>
          </div>
          <div className="flex gap-5 p-6 items-center">
            <div className="animate-pulse flex w-1/3 gap-4">
              <div className="h-8 bg-slate-200 rounded w-1/12" />
              <div className="h-8 bg-slate-200 rounded w-1/12" />
              <div className="h-8 bg-slate-200 rounded w-1/12" />
            </div>
            <div className="animate-pulse w-1/3 flex justify-center">
              <div className="h-2 bg-slate-200 rounded w-2/3" />
            </div>
            <div className="animate-pulse w-1/3 flex gap-5 justify-end">
              <div className="h-8 bg-slate-200 rounded w-1/3" />
              <div className="h-8 bg-slate-200 rounded w-1/3" />
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default MyReservationSkeleton
