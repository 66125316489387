import React, { useEffect, useState } from "react"
import Header from "components/Headers/MainHeader"
import { Grid, Row, Col } from "rsuite"
import Card from "components/Settings/UserManagement/Card"
import { Icon } from "components"
import { useNavigate } from "react-router"
import { useDomainAddonList } from "services/wl-display/domainAddon/domainAddonList"
import Cookies from "universal-cookie"
const cookies = new Cookies()

const generalSettings = [
  {
    key: "userManagement",
    title: "Kullanıcı Yönetimi",
    description: "Sistemdeki kullanıcıların bilgilerini yönetin ve yeni kullanıcı ekleyin.",
    icon: "users",
    link: "/settings/user-management/list",
    role: ["Corporate"],
  },
  {
    key: "googleAnalytics",
    title: "Google Analytics",
    description: "Google Analytics ayarlarınızı ekleyin.",
    icon: "external-link",
    link: "/settings/google-analytics",
    role: ["B2C", "Otel"],
  },
]

export const useSettingsLinks = (settingsKey) => {
  const [links, setLinks] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    const link = generalSettings.find((item) => item.key === settingsKey)
    if (link) {
      setLinks(link)
    } else {
      setLinks([])
    }
  }, [settingsKey])

  return { links, onNavigate: () => navigate(Array.isArray(links) ? "" : links.link) }
}

const SettingListItem = ({ title, description, icon, link, role, userInfo }) => {
  const roleCheck = role.some((r) => userInfo?.domainType?.includes(r))
  return (
    <Col
      xs={24}
      sm={24}
      md={24}
      lg={8}
      xl={8}
      className={`py-[16px] px-[20px] transition-all ${
        roleCheck ? "cursor-pointer hover:!bg-gray-50 hover:shadow-sm rounded-lg" : "hidden"
      }`}
    >
      <a
        href={link}
        className="cursor-pointer"
      >
        <div className="flex justify-start items-start gap-[16px]">
          <div className="">
            <Icon
              name={icon}
              className="!bg-gray-500"
            />
          </div>
          <div className="">
            <p className="text-gray-800 font-medium pb-1">{title}</p>
            <p className="text-gray-500 text-sm">{description}</p>
          </div>
        </div>
      </a>
    </Col>
  )
}

function sliceGroups(arr, n) {
  let groups = []
  for (let i = 0; i < arr.length; i += n) {
    groups.push(arr.slice(i, i + n))
  }
  return groups
}

const Settings = (props) => {
  let { isLogged } = props
  const { data: domainAddonListData } = useDomainAddonList()

  const hasGaCode = domainAddonListData?.data?.some((item, index) =>
    item.Addon.AddonAddonProductTypes.some((type) => type.AddonProductType.Code === 1 && domainAddonListData?.data[index]?.Status === 1),
  )

  const [userInfo, setUserInfo] = useState(null)

  useEffect(() => {
    const user = cookies.get("associatedDomain")
    if (user) {
      setUserInfo(user)
    }
  }, [])

  return (
    <>
      <Header isLogged={isLogged} />
      <main>
        <section className="p-10 my-0 mx-auto max-w-[1440px]">
          <Grid className="w-full">
            <Row gutter={10}>
              <Col
                xs={24}
                className="my-3"
              >
                <h1 className="text-3xl text-gray-800 font-semibold mb-1">Ayarlar</h1>
                <p className="text-gray-500">Düzenlemek istediğiniz kategoriyi seçin ve ilerleyin.</p>
              </Col>
              {!hasGaCode && (userInfo?.domainType?.includes("B2C") || userInfo?.domainType?.includes("Otel")) ? (
                <Col
                  xs={24}
                  className="my-3"
                >
                  <p className="text-red-500">Aktif addon ayarınız bulunmamaktadır.</p>
                </Col>
              ) : (
                <Col
                  xs={24}
                  xl={24}
                >
                  <Card>
                    <p className="text-[#667085] font-semibold mb-[8px]">GENEL</p>
                    {sliceGroups(generalSettings, 3).map((group, index) => (
                      <Row
                        gutter={40}
                        key={index}
                      >
                        {group.map((item, index) => (
                          <SettingListItem
                            key={index}
                            userInfo={userInfo}
                            {...item}
                          />
                        ))}
                      </Row>
                    ))}
                  </Card>
                </Col>
              )}
            </Row>
          </Grid>
        </section>
      </main>
    </>
  )
}

export default Settings
