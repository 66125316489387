import { useState, useEffect } from "react"
import { Link } from "react-router-dom"

import { TitleH1, Modal, Icon } from "components"
import MainHeader from "components/Headers/MainHeader"

const PublishingPage = (props) => {
  let { isLogged } = props

  const [newDomainModal, setNewDomainModal] = useState(false)
  const [domainModalCheckbox, setDomainModalCheckbox] = useState(false)
  const [editDomainModal, setEditDomainModal] = useState(false)
  const [addDomainPage, setAddDomainPage] = useState(false)
  const [domainModalLoading, setDomainModalLoading] = useState(false)
  const [domainSuccessModal, setDomainSuccessModal] = useState(false)
  const [addDomainContent, setAddDomainContent] = useState(false)

  useEffect(() => {
    if (domainModalLoading) {
      setTimeout(() => {
        setDomainModalLoading(false)
        setDomainSuccessModal(true)
      }, 3000)
    }
  }, [domainModalLoading])

  return (
    <>
      <MainHeader isLogged={isLogged} />
      <main>
        <section className="p-10 my-0 mx-auto max-w-[1920px] overflow-auto">
          <div className="container">
            <Link
              to={"/integration-settings"}
              className="go-back inline-flex items-center cursor-pointer my-7"
            >
              <Icon
                className="mr-2"
                name="arrow-left"
                size="20"
                color="#667085"
              />
              <span className="text-base text-gray-500">Yayınlama</span>
            </Link>
            <div className="flex flex-row justify-between mb-7">
              <TitleH1 className=" justify-items-start">Bağlantı Ayarları</TitleH1>
              <div className="justify-items-end">
                <button
                  onClick={() => setNewDomainModal(true)}
                  className="btn lg"
                >
                  <p className="text-base font-medium text-white">Yeni Alan Adı Ekle</p>
                </button>
              </div>
            </div>
            <div className="flex flex-col  bg-white border border-solid border-gray-200 rounded-lg mb-6">
              <p className="text-lg font-semibold px-6 pt-6 mb-1">Bağlantılar</p>
              <p className="text-base font-normal px-6 text-gray-500 mb-6">
                Eva ile online rezervasyon almak için aşağıdaki bağlantıyı veya yeni bir alan adı ekleyerek kullanabilirsiniz.
              </p>
              <div className="flex flex-row bg-gray-50 border border-solid border-gray-200 py-3 px-6">
                <div className="w-1/12">
                  <p className="text-xs font-semibold text-gray-500">Durum</p>
                </div>
                <div className="w-9/12">
                  <p className="text-xs font-semibold text-gray-500">Link</p>
                </div>
                <div className="w-2/12" />
              </div>
              <div className="flex flex-row bg-white border border-solid border-gray-200 p-6">
                <div className="w-1/12">
                  <button className="bg-success-50 rounded-2xl py-[2px] px-2">
                    <p className="text-xs font-semibold text-success-500">Aktif</p>
                  </button>
                </div>
                <div className="w-9/12">
                  <div className="flex flex-col">
                    <p className="text-sm font-normal text-blue-600">hotellacasa.platform.eva.io</p>
                    <p className="text-sm font-normal text-gray-500">Eva Subdomain</p>
                  </div>
                </div>
                <div className="w-2/12 flex justify-end">
                  <button className="p-2">
                    <Icon
                      className=""
                      name="copy"
                      color="#667085"
                      size="20"
                    ></Icon>
                  </button>
                  <button
                    onClick={() => setEditDomainModal(true)}
                    className="p-2"
                  >
                    <Icon
                      className=""
                      name="edit-2"
                      color="#667085"
                      size="20"
                    ></Icon>
                  </button>
                </div>
              </div>
              <div className="flex flex-row bg-white border border-solid border-gray-200 p-6">
                <div className="w-1/12">
                  <button className="bg-warning-50 rounded-2xl py-[2px] px-2">
                    <p className="text-xs font-semibold text-warning-500">Bekliyor</p>
                  </button>
                </div>
                <div className="w-9/12">
                  <div className="flex flex-col">
                    <p className="text-sm font-normal text-blue-600">hotellacasa.com</p>
                    <p className="text-sm font-normal text-gray-500">Custom Domain</p>
                  </div>
                </div>
                <div className="w-2/12 flex justify-end">
                  <button className="p-2">
                    <Icon
                      className=""
                      name="refresh-cw"
                      color="#667085"
                      size="20"
                    ></Icon>
                  </button>
                  <button
                    onClick={() => setEditDomainModal(true)}
                    className="p-2"
                  >
                    <Icon
                      className=""
                      name="copy"
                      color="#667085"
                      size="20"
                    ></Icon>
                  </button>
                  <button
                    onClick={() => setEditDomainModal(true)}
                    className="p-2"
                  >
                    <Icon
                      className=""
                      name="trash-2"
                      color="#667085"
                      size="20"
                    ></Icon>
                  </button>
                </div>
              </div>
              <div className="flex flex-row bg-white border border-solid border-gray-200 p-6 rounded-b-lg">
                <div className="w-1/12">
                  <button className="bg-success-50 rounded-2xl py-[2px] px-2">
                    <p className="text-xs font-semibold text-success-500">Aktif</p>
                  </button>
                </div>
                <div className="w-9/12">
                  <div className="flex flex-col">
                    <p className="text-sm font-normal text-blue-600">hotellacasa.com/home</p>
                    <p className="text-sm font-normal text-gray-500">Custom Domain</p>
                  </div>
                </div>
                <div className="w-2/12 flex justify-end">
                  <button
                    onClick={() => setEditDomainModal(true)}
                    className="p-2"
                  >
                    <Icon
                      className=""
                      name="copy"
                      color="#667085"
                      size="20"
                    ></Icon>
                  </button>
                  <button
                    onClick={() => setEditDomainModal(true)}
                    className="p-2"
                  >
                    <Icon
                      className=""
                      name="trash-2"
                      color="#667085"
                      size="20"
                    ></Icon>
                  </button>
                </div>
              </div>
            </div>
            <div className="flex flex-col bg-white border border-solid border-gray-200 rounded-lg p-6">
              <p className="text-lg font-semibold mb-1">SSL/TLS sertifa</p>
              <p className="text-base text-gray-500 mb-6">Let’s Encrypt ile TLS sertifikalarını otomatik olarak oluşturduk. </p>
              <div className="h-[1px] bg-gray-200 mb-6"></div>
              <div className="flex flex-row mb-4">
                <p className="text-sm font-medium w-2/12">Sertifika:</p>
                <p className="text-sm text-gray-500 w-10/12">Let’s Encrypt</p>
              </div>
              <div className="flex flex-row mb-4">
                <p className="text-sm font-medium w-2/12">Domainler:</p>
                <p className="text-sm text-gray-500 w-10/12">hotellacasa.com</p>
              </div>
              <div className="flex flex-row mb-4">
                <p className="text-sm font-medium w-2/12">Oluşturma Tarihi:</p>
                <p className="text-sm text-gray-500 w-10/12">28.07.2022 - 14:00</p>
              </div>
              <div className="flex flex-row mb-4">
                <p className="text-sm font-medium w-2/12">Güncelleme:</p>
                <p className="text-sm text-gray-500 w-10/12">28.07.2022 - 14:00</p>
              </div>
              <div className="flex flex-row mb-4">
                <p className="text-sm font-medium w-2/12">Sonlanma:</p>
                <p className="text-sm text-gray-500 w-10/12">28 Ekim 2022 (3 aylık)</p>
              </div>
            </div>
          </div>

          <Modal
            onChange={() => {
              setNewDomainModal(false)
            }}
            open={newDomainModal}
            title={"Özel alan adı ekle"}
            width={600}
            closeButton={true}
          >
            <p className="text-base text-gray-500 mb-8">Marka tutarlılığı için kendi alan adınızı bağlayın.</p>
            <div className="flex flex-row bg-white mb-8 border-b border-solid border-gray-200">
              <p
                onClick={() => setAddDomainPage(false)}
                className={
                  !addDomainPage
                    ? "text-sm font-medium pb-4 border-b border-solid border-blue-500 text-blue-500 mr-2 cursor-pointer"
                    : "text-sm font-medium text-gray-500 cursor-pointer"
                }
              >
                Alan adı ekle
              </p>
              <p
                onClick={() => setAddDomainPage(true)}
                className={
                  addDomainPage
                    ? "text-sm font-medium pb-4 border-b border-solid border-blue-500 text-blue-500 ml-2 cursor-pointer"
                    : "text-sm font-medium text-gray-500 cursor-pointer"
                }
              >
                Nasıl yapılır?
              </p>
            </div>
            {addDomainPage ? (
              <>Nasıl Yapılır</>
            ) : addDomainContent ? (
              <>
                <div className="flex flex-row justify-between items-center bg-gray-50 border border-solid border-gray-200 rounded-lg py-2 px-4 mb-8">
                  <Icon
                    className=" mr-5"
                    name="info"
                    color="#667085"
                    size="25"
                  ></Icon>
                  <p className="text-sm text-gray-500">
                    booking.hotellacasa.com adresinin aktifleşebilmesi için, aşağıdaki adımları tamamlamanız gerekiyor.
                  </p>
                </div>
                <div className="flex flex-col mb-4">
                  <p className="text-lg mb-8 text-gray-900 font-medium">
                    1 - Domain’i yönettiğiniz hesabınıza giriş yapın. (Örneğin: godaddy.com)
                  </p>
                  <p className="text-lg text-gray-900 font-medium">
                    2 - Domain yönetim ekranından yeni bir CNAME oluşturun ve “Points to” alanına aşağıdaki linki kopyalayıp
                    yapıştırın.
                  </p>
                </div>
                <div className="flex flex-row justify-between bg-white border border-solid border-gray-200 py-2 px-3 rounded-lg shadow-sm mb-8">
                  <p className="text-base text-gray-500 justify-start">booking.hotellacasa.com</p>
                  <p className="flex items-center text-sm text-blue-500 font-medium justify-end">
                    <Icon
                      className="mr-3"
                      name="copy"
                      color="#2E90FA"
                      size="20"
                    ></Icon>
                    Kopyala
                  </p>
                </div>
                <p className="text-lg mb-8 text-gray-900 font-medium">
                  3 - Yukarıdaki işlemler bittikten sonra checkbox’ı işaretleyin ve tamamla butonuna basın.
                </p>
                <label
                  className="flex flex-row items-center cursor-pointer"
                  onChange={() => setDomainModalCheckbox(!domainModalCheckbox)}
                >
                  <input
                    defaultChecked={domainModalCheckbox}
                    className="mr-1"
                    name="checkbox"
                    type="checkbox"
                  />
                  <p className="text-base font-medium text-gray-800 ">Tamamdır, tüm adımları tamamladım.</p>
                </label>
                <div className="flex flex-row w-full justify-end mt-8">
                  <button
                    onClick={() => setNewDomainModal(false)}
                    className="btn lg outline mr-3"
                  >
                    Geri Dön
                  </button>
                  <button
                    onClick={() => {
                      setNewDomainModal(false)
                      setDomainModalLoading(true)
                    }}
                    className={!domainModalCheckbox ? "btn lg secondary disabled" : "btn lg"}
                  >
                    Tamamla
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="flex flex-row items-center">
                  <input
                    className=" border w-full"
                    type="text"
                    placeholder="booking"
                  />
                  <p className="text-base text-gray-800 font-medium mx-3">.</p>
                  <input
                    className="border w-full"
                    type="text"
                    placeholder="yourdomain.com"
                  />
                </div>
                <div className="flex flex-row w-full justify-end mt-8">
                  <button
                    onClick={() => setNewDomainModal(false)}
                    className="btn lg outline mr-3"
                  >
                    Vazgeç
                  </button>
                  <button
                    onClick={() => {
                      setAddDomainContent(true)
                    }}
                    className="btn lg"
                  >
                    İlerle
                  </button>
                </div>
              </>
            )}
          </Modal>

          <Modal
            onChange={() => {
              setEditDomainModal(false)
            }}
            open={editDomainModal}
            title={"Alan adını değiştirin"}
            width={600}
            closeButton={true}
          >
            <p className="text-base text-gray-500 mb-8">
              Site adı, siteniz için varsayılan URL'i belirler. Yalnızca alfanumerik karakterlere ve kısa çizgilere (-)(_) izin
              verilir.
            </p>
            <p className="text-sm text-gray-800 font-medium mb-1">Site Adı</p>
            <div className="flex flex-row items-center">
              <input
                className="w-full border"
                type="text"
              />
              <p className="text-base text-gray-800 font-medium ml-3">platform.eva.io</p>
            </div>
            <div className="flex flex-row w-full justify-end mt-8">
              <button
                onClick={() => setEditDomainModal(false)}
                className="btn lg outline mr-3"
              >
                Vazgeç
              </button>
              <button
                onClick={() => {
                  setEditDomainModal(false)
                }}
                className="btn lg"
              >
                Kaydet
              </button>
            </div>
          </Modal>

          <Modal
            open={domainModalLoading}
            width={392}
            closeButton={false}
          >
            <div className="flex flex-col items-center justify-center">
              <div className="flex items-center justify-center w-12 h-12 bg-blue-50 rounded-lg">
                <Icon
                  className=""
                  name="clock"
                  color="#2E90FA"
                  size="20"
                ></Icon>
              </div>
              <p className="text-lg font-medium text-black">Alan adı ekleniyor...</p>
            </div>
          </Modal>

          <Modal
            open={domainSuccessModal}
            width={600}
            closeButton={false}
          >
            <div className="flex flex-col items-center justify-center">
              <div className="flex items-center justify-center w-12 h-12 bg-success-50 rounded-lg">
                <Icon
                  className=""
                  name="check"
                  color="#12B76A"
                  size="20"
                ></Icon>
              </div>
              <p className="text-lg font-medium text-black">Alan adı eklendi</p>
              <p className="text-base text-gray-500 mb-8 text-center">
                CNAME değişiklikleri sisteme hemen yansımayabilir. Bir süre bekledikten sonra bağlantı ayarlarından tekrar
                bağlanmayı deneyebilirsiniz.
              </p>
              <button
                onClick={() => setDomainSuccessModal(false)}
                className="btn lg w-full"
              >
                Tamamla
              </button>
            </div>
          </Modal>
        </section>
      </main>
    </>
  )
}

export default PublishingPage
