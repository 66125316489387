import React, {useCallback, useEffect, useState} from "react"
import {useNavigate, useParams} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux";


// components
import {Grid, Row, Col, SelectPicker, Stack, Form, Placeholder} from "rsuite"
import Header from "components/Headers/MainHeader"
import {ErrorMessage, Icon, LoadBar, Alert} from "components"

// saga-requests
import {clientUserGetRequested} from "redux/actions/clientUserGet";
import {departmantListRequested} from "redux/actions/getDepartmants";
import {roleListRequested} from "redux/actions/getRoles";
import {clientUserUpdateRequested, clientUserUpdateSucceed} from "redux/actions/clientUserUpdate";
import {countryCodesRequested} from "redux/actions/getCountryCodes";
import {convertDataForSelectPicker} from "components/Settings/UserManagement/UserAddModal";

// locale
import localization from "../../../localization";

const UserEdit = (props) => {

  let { isLogged } = props
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { id } = useParams()
  const userUpdate = useSelector(state => state.clientUserUpdate.list)
  const userUpdateLoading = useSelector(state => state.clientUserUpdate.loading)
  const userInfo = useSelector(state => state.clientUserGet.list)
  const userInfoLoading = useSelector(state => state.clientUserGet.loading)
  const userInfoError = useSelector(state => state.clientUserGet.error)
  const handleOnNavigate = useCallback(() => navigate("/settings/user-management/list"), [navigate])


  //selectors
  const departmantList = useSelector(state => state.getDepartmants.list.result)
  const roleList = useSelector(state => state.getRoles.list.result)
  const countryCodeList = useSelector(state => state.getCountryCodes?.list?.result)

  //states
  const [formValue, setFormValue] = useState(null)
  const [buttonDisable, setButtonDisable] = useState(true)
  const [DepartmentListSelect, setDepartmentListSelect] = useState([])
  const [RoleListSelect, setRoleListSelect] = useState([])
  const [CountryCodeListSelect, setCountryCodeListSelect] = useState([])

  const checkAllFields = (formValue) => {
    for (var key in formValue) {
      if (formValue.hasOwnProperty(key)) {
        if (key === "surname") return true
        if (formValue[key] === "" || formValue[key] === null || formValue[key]?.length === 0){
          return false
        }
      }
    }
    return true
  }

  const handleOnChangeForm = (value) => {
    const fieldData = {
      ...formValue,
      ...value
    }
    setFormValue(fieldData)
    const isAllFieldsDone = checkAllFields(fieldData)
    setButtonDisable(!isAllFieldsDone)
  }


  const handleOnSubmit = () => {
    let formData = [formValue].reduce((acc, value) => {
      let departments = []
      let PhoneNumber = `${value?.countryCode} ${value?.phoneNumber}`
      let role
      if (typeof value.departments === "string"){
        departments = [value.departments* 1]
      } else {
        departments.push(...value.departments.map(dep => dep.departmentId *1))
      }
      if (typeof value.role === "object"){
        role = value.role[0].roleId
      } else {
        role = value.role* 1
      }
      if (value.phoneNumber.includes(" ")){
        PhoneNumber = `${value.countryCode} ${value.phoneNumber && value.phoneNumber.split(" ")[1]}`
      }

      let splittedNameSpaces = value.name && value.name.split(" ")
      acc.push({
        name: splittedNameSpaces.slice(0, -1).join(" ") !== "" ? splittedNameSpaces.slice(0, -1).join(" ") : value.name,
        surname: splittedNameSpaces.slice(0, -1).join(" ") !== "" ? splittedNameSpaces.pop() : value.surname,
        DepartmentIds: departments,
        RoleId: role,
        Email :value.email,
        userid: value.userId,
        PhoneNumber,
        status: value.status === 1 ? true : value.status === true,
      })
      return acc
    }, [])

    dispatch(clientUserUpdateRequested(...formData))
  }

  useEffect(() => {
    dispatch(clientUserGetRequested(id))
    if (!departmantList) dispatch(departmantListRequested())
    if (!roleList) dispatch(roleListRequested())
    if (!countryCodeList) dispatch(countryCodesRequested())
  }, [dispatch, departmantList, roleList, countryCodeList])

  useEffect(() => {
    if (userInfo){
      setFormValue({
        ...userInfo,
        countryCode: userInfo.phoneNumber && userInfo.phoneNumber?.split(" ")[0]
      })
    }
  }, [userInfo])

  useEffect(() => {
    if(departmantList && !DepartmentListSelect.length) setDepartmentListSelect(convertDataForSelectPicker(departmantList, "value", "text"))
    if(roleList && !RoleListSelect.length) setRoleListSelect(convertDataForSelectPicker(roleList, "value", "text"))
    if(countryCodeList && !CountryCodeListSelect.length) setCountryCodeListSelect(convertDataForSelectPicker(countryCodeList, "dialCode", "name"))
  }, [departmantList, roleList, countryCodeList])

  return (
    <>
      <Header isLogged={isLogged} />
      <main>
        <section className="p-10 my-0 mx-auto max-w-[778px]">
          <span
            className="!text-gray-600 flex justify-start items-center gap-2 mt-2 mb-8 cursor-pointer w-fit transition-all hover:drop-shadow"
            onClick={handleOnNavigate}
          >
            <Icon
              name="arrow-left"
              size="20"
              className="!bg-gray-500"
            />
            <h4>Kullanıcı Listesi</h4>
          </span>


          {userInfoLoading &&
              <Col
                  xs={24}
                  className="my-3"
              >
                {Array(5)
                    .fill()
                    .map((_, i) => (
                        <Placeholder.Paragraph
                            key={i}
                            style={{ marginTop: 30 }}
                        />
                    ))}
              </Col>}
          {userInfoError &&  <ErrorMessage className="mt-1">Kullanici yuklenirken bir hata ile karsilasildi.</ErrorMessage>}

          {userInfo && !userInfoError && !userInfoLoading &&
              <Grid fluid>
                <Form
                    fluid
                    //formValue={formValue}
                    onChange={handleOnChangeForm}
                >
                  <Row gutter={10}>
                    <Col
                        xs={24}
                        className="my-2"
                    >
                      <h1 className="text-3xl text-gray-800 font-semibold mb-1">Kullanıcı Detay</h1>
                    </Col>
                    <Col xs={24}>
                      <div className="w-full my-3 p-7 rounded-lg border border-gray-200 bg-white">
                        <Grid fluid>
                          <Row
                              gutter={10}
                              className="mb-[24px]"
                          >
                            <Col xs={12}>
                              <p className="mb-1">Ad Soyad</p>
                              <Form.Control
                                  name="name"
                                  placeholder="Kullanıcı adı giriniz"
                                  defaultValue={`${userInfo.name} ${userInfo.surname}`}
                              />
                            </Col>
                            <Col xs={12}>
                              <p className="mb-1">Statü*</p>
                              <Form.Control
                                  name="status"
                                  placeholder="Statü Seçiniz"
                                  defaultValue={userInfo.status ? 1 : 0}
                                  data={[
                                    {
                                      label: "Aktif",
                                      value: 1,
                                    },
                                    {
                                      label: "Pasif",
                                      value: 0,
                                    },
                                  ]}
                                  accepter={SelectPicker}
                                  searchable={false}
                                  cleanable={false}
                                  style={{ width: "100%" }}
                              />
                            </Col>
                          </Row>
                          <Row
                              gutter={10}
                              className="mb-[24px]"
                          >
                            <Col xs={12}>
                              <p className="mb-1">Departman Adı *</p>
                              <Form.Control
                                  name="departments"
                                  placeholder="Departman Seçiniz"
                                  defaultValue={`${userInfo?.departments && userInfo.departments[0]?.departmentId}`}
                                  data={DepartmentListSelect ? DepartmentListSelect : []}
                                  style={{ width: "100%" }}
                                  searchable={false}
                                  accepter={SelectPicker}
                              />
                            </Col>
                            <Col xs={12}>
                              <p className="mb-1">Rol</p>
                              <Form.Control
                                  name="role"
                                  placeholder="Rol Seçiniz"
                                  defaultValue={`${userInfo?.role.length && userInfo.role[0]?.roleId}`}
                                  data={RoleListSelect ? RoleListSelect : []}
                                  style={{ width: "100%" }}
                                  searchable={false}
                                  accepter={SelectPicker}
                              />
                            </Col>
                          </Row>
                          <Row
                              gutter={10}
                              className="mb-[24px]"
                          >
                            <Col xs={12}>
                              <p className="mb-1">Telefon Numarası</p>
                              <div className="flex">
                              <Form.Control
                                  name="countryCode"
                                  cleanable={false}
                                  renderValue={(value) => `+${value}`}
                                  renderMenuItem={(label, item) => `${item.label} +${item.value}`}
                                  loading={!CountryCodeListSelect}
                                  classPrefix=" w-auto"
                                  defaultValue={userInfo.phoneNumber?.split(" ")[0]}
                                  locale={localization?.TR?.PickerLocaleType}
                                  accepter={SelectPicker}
                                  data={CountryCodeListSelect} />
                              <Form.Control
                                  name="phoneNumber"
                                  placeholder="Telefon numarası giriniz"
                                  defaultValue={userInfo.phoneNumber?.split(" ")[1]}
                              />
                              </div>
                            </Col>
                            <Col xs={12}>
                              <p className="mb-1">Email</p>
                              <Form.Control
                                  type="email"
                                  name="email"
                                  readOnly
                                  placeholder="E-posta adresi giriniz"
                                  defaultValue={userInfo.email}
                              />
                            </Col>
                          </Row>
                        </Grid>
                      </div>
                    </Col>
                    <Col
                        xs={24}
                        className="flex justify-end items-center"
                    >
                      <Stack spacing={10}>
                        <div
                            className="btn border border-gray-300 bg-white text-gray-500 hover:bg-gray-500 hover:text-white hover:border-gray-500"
                            onClick={handleOnNavigate}
                        >
                          Vazgeç
                        </div>
                        <div
                            className="btn"
                            onClick={handleOnSubmit}
                            disabled={buttonDisable || userUpdateLoading}
                        >
                          {userUpdateLoading && <LoadBar />}
                          {!userUpdateLoading && "Kaydet"}

                        </div>
                      </Stack>
                    </Col>
                  </Row>
                </Form>


                {userUpdate && <Alert
                    title=""
                    description="Güncelleme işlemi başarılı bir şekilde sonuçlandı"
                    iconName="check-circle"
                    iconBgColor="#D1FADF"
                    hideApproveButton={true}
                    rejectButtonText={"Kapat"}
                    rejectButtonOnClick={() => dispatch(clientUserUpdateSucceed(null))}
                />}

              </Grid>
          }
        </section>
      </main>
    </>
  )
}

export default UserEdit
