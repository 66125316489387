/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useRef, useState} from "react"
import {useDispatch, useSelector} from "react-redux"

// constants
import {
  IMAGE,
  newImage,
  newSlider,
  newTabSlider,
} from "../../../Enums";
//layouts
import Content from "./Content"
import PartialWrapper from "../../PartialWrapper"
// components
import {ColorBox, Icon, SideMenuListItem, Subtitle, TabList} from "components"
// utils
import getSelectedIndex from "utils/getSelectedIndex"
//helpers
import {UpdateStrategy} from "../../../helpers/UpdateStrategy";
import {deleteSliderItem} from "../../../helpers/DeleteSliderItem";
import {setSettings} from "helpers/setSettings";

// saga-requests
import {getStrategySucceed} from "redux/actions/strategy";

const AddOrEditSlide = (props) => {
  let { activeLayout, editBlock, setEditBlock, ThisComponent, setThisComponent, ActiveCategory, subComponentIdList } = props

  const dispatch = useDispatch()
  let categoryInfo = editBlock?.categoryInfo

  const NewTabSlider = newTabSlider(ThisComponent?.code, activeLayout?.order)


  let [ThisSlider, setThisSlider] = useState(editBlock?.slider || newSlider(subComponentIdList?.id || editBlock?.slider?.code))
  const [ThisImage, setThisImage] = useState(editBlock?.slider?.children?.find(child => child?.name === IMAGE) || newImage(editBlock?.itemInfo?.imageId))

  const formRef = useRef()
  const UploadFileRef = useRef()

  // selectors
  let components = useSelector(state => state.strategy.getStrategy.list?.result)
  // states
  const [SelectedTab, setSelectedTab] = useState(0) // default
  const [UploadedImageInfo, setUploadedImageInfo] = useState(null)
  const [DefaultImageInfo, setDefaultImageInfo] = useState(null)
  const [BgColor, setBgColor] = useState(null)
  const [ShowContentLayout, setShowContentLayout] = useState(false)
  const [InfoCodeForStrategy, setInfoCodeForStrategy] = useState(activeLayout?.info?.code)
  const [SlideSettings, setSlideSettings] = useState(null)
  const [IsAddNewSlide, setIsAddNewSlide] = useState(editBlock?.categorySlide === "addNewSlide")

  // Func
  const editSliderTabChanged = (e) => {
    let selectedIndex = getSelectedIndex(e)
    setSelectedTab(selectedIndex)
  }
  const clearImageState = () => {
    setUploadedImageInfo(null)
    setDefaultImageInfo(null)
    setBgColor(null)
    setSlideSettings(null)
    setSelectedTab(0)
  }
  const updateImage = () => {
    UploadFileRef.current.click()
  }
  const updateBackgroundColor = (e) => {
    setBgColor(e)
  }
  const readImage = (file) => {
    const reader = new FileReader()
    reader.addEventListener("load", (event) => {
      setUploadedImageInfo({
        src: event.target.result,
        size: file.size,
        name: file.name,
        fileForBinary: file,
      })
    })
    reader.readAsDataURL(file)
    UploadFileRef.current.value = ""
  }
  const goBack = async () => {
    clearImageState()
    setEditBlock(null)
    setThisSlider(null)
  }
  const checkHasComponentVisual = () => {
    return ThisComponent?.visual
  }
  const getComponent = () => {
    let componentCode = ThisComponent?.code
    if (!componentCode && !checkHasComponentVisual()) {
      componentCode = InfoCodeForStrategy
    } else if (!componentCode && !InfoCodeForStrategy){
      componentCode = NewTabSlider?.code
    }
    let component = components?.children?.find(child => child?.code === componentCode)
    if (!component){
      setInfoCodeForStrategy(NewTabSlider.code)
      component = NewTabSlider
    }
    return component
  }

  const generateChildrenModels = (component) => {
    component.children = []
    return component
  }

  const createImageComponentData = (imageComponent) => {
    return {
      ...imageComponent,
      data: {
        ...UploadedImageInfo
      }
    }

  }

  const getSliderChilrenLength = () => (ThisSlider?.children && ThisSlider?.children?.length) || 0

  const addImageToSlider = (component, ThisSlider) => {
    const componentSlider = component.children.find(child => child?.code === ThisSlider?.code)
    const imageComponentIndex  = componentSlider?.children?.findIndex(child => child?.name === IMAGE)
    const imageComponent  = componentSlider?.children?.find(child => child?.name === IMAGE) || newImage(editBlock?.itemInfo?.imageId)
    const ImageComponentData = createImageComponentData(imageComponent)
    if (componentSlider){
      if (!componentSlider?.children){
        componentSlider.children = [ImageComponentData]
      } else {
        if (imageComponentIndex === -1){
          componentSlider.children[getSliderChilrenLength()] = ImageComponentData
        } else {
          componentSlider.children[imageComponentIndex] = ImageComponentData
        }
      }
    } else {
      ThisSlider.children = [ImageComponentData]
      component.children.push({
        ...ThisSlider
      })
    }

    setEditBlock({
      categorySlide: "updated",
      slider: ThisSlider,
      categoryInfo: ActiveCategory,
    })

  }

  const addVisualToSlider = (component, ThisSlider) => {
    const componentSlider = component.children.find(child => child?.code === ThisSlider?.code)
    const visualData = {
      ...component?.visual,
      style: {
        ...component?.visual?.style,
        backgroundColor: SlideSettings.backgroundColor
      }
    }
    const sliderVisualData = {
      ...ThisSlider,
      visual: {
        ...ThisSlider?.visual,
        ...visualData
      }
    }
    if (componentSlider){
      componentSlider.visual = visualData
    } else {
      component.children.push(sliderVisualData)
    }
    setThisSlider({
      ...ThisSlider,
      ...sliderVisualData
    })
    setEditBlock({
      categorySlide: "updated",
      slider: {
        ...ThisSlider,
        ...sliderVisualData
      },
      categoryInfo: ActiveCategory,
    })
  }
  const addCategoryToSlider = (ThisSlider) => {
    ThisSlider.data = {
      ...ThisSlider?.data,
      category: {
        ...ThisSlider?.data?.category,
        categoryId: categoryInfo?.Id,
        categoryName: categoryInfo?.Name,
        order: categoryInfo?.Order,
        categoryVisible: categoryInfo?.Visible,
      }
    }

    setThisSlider({
      ...ThisSlider
    })
  }

  //effects
  useEffect(() => {
    if (editBlock?.categorySlide === "addNewSlide"){
      setIsAddNewSlide(true)
    }
    if (editBlock?.slider) {
      setThisSlider({
        ...ThisSlider,
        ...editBlock?.slider
      })
      const thisImage = editBlock?.slider?.children?.find(child => child?.name === IMAGE)
      if (thisImage){
        setThisImage({
          ...thisImage
        })
      }
      setThisImage({
        ...editBlock?.slider?.children?.find(child => child?.name === IMAGE)
      })
      setDefaultImageInfo(editBlock?.slider?.media)
      const checkItemIncludesImage = (!!editBlock?.slider?.media?.src) || !editBlock?.slider?.visual?.style?.backgroundColor
      setSelectedTab(checkItemIncludesImage ? 0 : 1)
      setBgColor(editBlock?.slider?.visual?.style?.backgroundColor)
    }
  }, [editBlock?.slider, editBlock?.categorySlide])

  useEffect(() => {
    if (SlideSettings || UploadedImageInfo){
      const component = getComponent()
      if (!component?.children){
        generateChildrenModels(component)
      }
      if (IsAddNewSlide){
        ThisSlider = newSlider()
        setIsAddNewSlide(false)
      }
      if (!ThisSlider?.data?.category) addCategoryToSlider(ThisSlider)
      if (SlideSettings?.backgroundColor) addVisualToSlider(component, ThisSlider)
      if (UploadedImageInfo) addImageToSlider(component, ThisSlider)

      setThisSlider({
        ...ThisSlider
      })
      setThisComponent({
        ...component
      })
    }


  }, [SlideSettings, UploadedImageInfo])

  useEffect(() => {
    if (ThisComponent?.data || ThisComponent?.children){
      const strategy = UpdateStrategy(components, ThisComponent)
      dispatch(getStrategySucceed(strategy))
    }
  }, [ThisComponent])

  return (
    <PartialWrapper
      goBackOnClick={goBack}
      className={`edit-slide${editBlock?.categorySlide ? " active" : ""}`}
    >
      <Subtitle className="mb-4">{!IsAddNewSlide ? "Slide Düzenle" : "Slide Ekle"}</Subtitle>
      <div className="input-wrapper mb-8">
        <SideMenuListItem
          title={"İçerik"}
          iconName={"chevron-right"}
          disabled={!DefaultImageInfo?.src && !UploadedImageInfo?.src && !BgColor}
          onClick={() => {
            setShowContentLayout(true)
          }}
        />
      </div>

      <div className="sub-caption-box flex items-center mb-4">
        <h4 className="text-sm font-medium text-gray-800">Arka Plan</h4>
      </div>
      <TabList
        onChange={editSliderTabChanged}
        tabs={[{ label: "Görüntü" }, { label: "Renk" }, { label: "Video", disabled: true }]}
        tabClassName="w-full flex items-center justify-center text-center text-gray-600 text-xs p-2 border-r last:border-r-0 hover:bg-gray-50 transition duration-400"
        wrapperClassName="flex shrink-0 border rounded-lg border-gray-200 overflow-hidden mb-7"
        //itemActiveClass="!text-gray-800 !bg-blue-50"
        activeTab={SelectedTab}
      />

      <div className="w-full overflow-hidden">
        <div
          className="flex transition duration-500 -m-10"
          style={{
            transform: `translate3d(${SelectedTab === 1 ? "-100%" : "0%"} , 0px, 0px)`,
          }}
        >
          <div className="background-image-area text-sm w-full shrink-0 p-10">
            <div className="flex items-center">
              <p className="text-gray-800 font-medium">Arka plan görüntüsü</p>
              <div className="progress-area ml-auto">
                {!UploadedImageInfo?.src && !DefaultImageInfo?.src && (
                  <p
                    className="text-blue-500 cursor-pointer font-medium hover:text-blue-300 transition"
                    onClick={() => UploadFileRef.current.click()}
                  >
                    Ekle
                  </p>
                )}
                {(DefaultImageInfo?.src || UploadedImageInfo?.src) && (
                  <div className="icon-box flex items-center gap-4">
                    <Icon
                      className="cursor-pointer hover:opacity-50"
                      name="refresh-cw"
                      size={20}
                      color={"#1D2939"}
                      onClick={updateImage}
                    />
                    <Icon
                      className="cursor-pointer hover:opacity-50"
                      name="trash-2"
                      size={20}
                      color={"#1D2939"}
                      onClick={() => {
                        deleteSliderItem(ThisComponent, ThisSlider, true).then(response => {
                          if (response){
                            const strategy = UpdateStrategy(components, response)
                            dispatch(getStrategySucceed(strategy))
                            clearImageState()
                          }
                        })
                      }}
                    />
                  </div>
                )}
              </div>
              <form
                ref={formRef}
                className="hidden"
              >
                <input
                  hidden
                  type="file"
                  id="fileUploadInputForSlider"
                  ref={UploadFileRef}
                  onChange={(e) => readImage(UploadFileRef.current.files[0])}
                  accept=".jpg, .jpeg, .png, .svg, .gif"
                />
              </form>
            </div>

            {(DefaultImageInfo?.src || UploadedImageInfo?.src) && (
              <div className="uploaded-image-area flex items-center justify-center rounded-lg py-overflow-hidden h-[220px] mt-3">
                <img
                  className="max-w-full object-cover object-center bg-white/[.5] max-h-full"
                  src={UploadedImageInfo?.src || DefaultImageInfo?.src}
                  alt={UploadedImageInfo?.name || DefaultImageInfo?.name}
                />
              </div>
            )}

            <p className="mt-3">
              Yüksek çözünürlüklü fotoğraflar, kompanent’in yüklenme hızını etkileyebilir. Fotoğrafları yüklemeden önce optimize
              etmenizi tavsiye ederiz.
            </p>
          </div>

          <div className="w-full shrink-0 p-10">
            <div className="input-wrapper mb-8">
              <ColorBox
                title={"Arka Plan Rengi"}
                value={SlideSettings?.backgroundColor || editBlock?.slider?.visual?.style?.backgroundColor || "#98A2B3"}
                onChange={e => setSettings(e, true, SlideSettings).then((res) => {
                  setSlideSettings(res)
                  updateBackgroundColor(res?.backgroundColor)
                })}
              />
            </div>
          </div>
        </div>
      </div>
      <Content
        show={ShowContentLayout}
        setShow={setShowContentLayout}
        activeLayout={activeLayout}
        editBlock={editBlock}
        ThisSlider={ThisSlider}
        setThisSlider={setThisSlider}
        ThisComponent={ThisComponent}
        setThisComponent={setThisComponent}
        ActiveCategory={ActiveCategory}
      />
    </PartialWrapper>
  )
}

export default AddOrEditSlide
