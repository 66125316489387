import React from "react"
import Header from "components/Headers/MainHeader"
import { Grid, Row, Col, Placeholder } from "rsuite"
import ButtonActions from "components/Defines/ButtonActions"
import NoData from "components/Defines/NoData"
import DataList from "components/Defines/DataList"
import { useDefines } from "utils/useDefines"
import { useSelector } from "react-redux"

const Defines = (props) => {
  let { isLogged } = props
  const { data, filteredData: listData, isLoading, refetch } = useDefines()
  const { filter } = useSelector((state) => state.define)

  return (
    <>
      <Header isLogged={isLogged} />
      <main>
        <section className="p-10 my-0 mx-auto max-w-[1440px]">
          <Grid fluid>
            <Row gutter={10}>
              <Col
                xs={24}
                xl={16}
                className="my-3"
              >
                <h1 className="text-3xl text-gray-800 font-semibold mb-1">Tanımlar</h1>
                <p className="text-gray-500">Ürününüzde kullanılmak üzere departman, seyahat nedeni gibi kalıplar oluşturun.</p>
              </Col>
              <Col
                xs={24}
                xl={8}
                className="my-3"
              >
                {((data || []).length !== 0 || filter.type !== null) && <ButtonActions />}
              </Col>
              {isLoading ? (
                <Col
                  xs={24}
                  className="my-3"
                >
                  {Array(5)
                    .fill()
                    .map((_, i) => (
                      <Placeholder.Paragraph
                        key={i}
                        style={{ marginTop: 30 }}
                      />
                    ))}
                </Col>
              ) : (listData || []).length === 0 && filter.status === null && filter.type === null ? (
                <NoData />
              ) : (
                <DataList
                  data={listData}
                  refetch={refetch}
                />
              )}
            </Row>
          </Grid>
        </section>
      </main>
    </>
  )
}

export default Defines
