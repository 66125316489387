import React from "react"

import Icon from "components/Icon"

const InfoBox = ({ isVisible, onClose, icon, title, content, bgColor, iconColor, iconBgColor }) => {
  const iconBgStyle = { backgroundColor: iconBgColor }
  const boxStyle = { backgroundColor: bgColor }

  return (
    <>
      {isVisible ? (
        <div
          className={`flex justify-between items-center p-6 rounded-lg mb-6 gap-4 border border-gray-200`}
          style={boxStyle}
        >
          <div className="flex items-center gap-4">
            <div
              className={`flex justify-center items-center w-12 h-12 min-w-[48px] min-h-[48px] rounded-full`}
              style={iconBgStyle}
            >
              <Icon
                name={icon}
                size="20"
                color={iconColor}
              />
            </div>

            <div className="flex flex-col">
              <p className="text-sm text-gray-900">{title}</p>
              <p className="text-sm text-gray-500">{content}</p>
            </div>
          </div>
          <div
            className="cursor-pointer flex items-center"
            onClick={onClose}
          >
            <Icon
              name="close"
              size="20"
              color="#101828"
            />
          </div>
        </div>
      ) : null}
    </>
  )
}

export default InfoBox
