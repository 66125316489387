import React, { useState } from "react"

import Header from "components/Headers/MainHeader"
import Icon from "components/Icon"
import ConversionInformation from "components/Dashboard/ConversionInformation"
import NumberOfGuests from "components/Dashboard/NumberOfGuests"
import Earning from "components/Dashboard/Earning"
import TotalReservation from "components/Dashboard/TotalReservation"
import ReservationInformation from "components/Dashboard/ReservationInformation"

const Dashboard = (props) => {
  let { isLogged } = props

  const [visible, setVisible] = useState(true)

  return (
    <>
      <Header isLogged={isLogged} />
      <main>
        <section className="p-10 my-0 mx-auto max-w-[1440px]">
          <div>
            <h1 className="text-3xl text-gray-800 font-semibold mb-1">Gösterge Paneli</h1>
            <p className="text-gray-500">Performans ve finansal verilerinizi görüntüleyin.</p>
          </div>
          <div className="h-[1px] w-full bg-gray-200 my-6" />

          {visible && (
            <div className="flex justify-between items-center bg-[#B2DDFF] p-4 rounded-lg mb-6">
              <div className="flex items-center gap-2">
                <Icon
                  name="info"
                  size="20"
                  color="#101828"
                />
                <p className="text-gray-800 font-semibold">
                  Kazanç verileri gösterge amaçlıdır. Gerçek değerler için ödeme fişi bilgisi esas alınmalıdır.
                </p>
              </div>
              <div
                className="cursor-pointer flex items-center"
                onClick={() => setVisible(false)}
              >
                <Icon
                  name="close"
                  size="20"
                  color="#101828"
                />
              </div>
            </div>
          )}

          <div>
            <ConversionInformation />
            <div className="flex justify-between gap-6 mt-6">
              <div className="w-1/2">
                <NumberOfGuests />
              </div>
              <div className="flex flex-col gap-6 w-1/2">
                <Earning />
                <TotalReservation />
              </div>
            </div>
          </div>

          <div className="pb-20 mt-6">
            <ReservationInformation />
          </div>
        </section>
      </main>
    </>
  )
}

export default Dashboard
