import * as type from "redux/types"

export const agencyListForCorporateRequested = (data) => ({
  type: type.GET_AGENCY_LIST_FOR_CORPORATE_REQUESTED,
  payload: data,
})

export const agencyListForCorporateSucceed = (data) => ({
  type: type.GET_AGENCY_LIST_FOR_CORPORATE_SUCCEEDED,
  payload: data,
})

export const agencyListForCorporateFailed = () => ({
  type: type.GET_AGENCY_LIST_FOR_CORPORATE_FAILED,
})
