import { call, fork, put, takeLatest } from "redux-saga/effects"
import * as actions from "redux/actions/addonCategoryList"

import { ADDON_CATEGORY_REQUESTED } from "redux/types"
import { API_GET } from "helpers/api"

function* watchGetaddonCategory() {
  yield takeLatest(ADDON_CATEGORY_REQUESTED, getAddonCategory)
}

function* getAddonCategory(action) {
  try {
    const res = yield call(apiGetAddonCategory, action.payload)
    yield put(actions.addonCategorySucceed(res?.data))
  } catch (err) {
    yield put(actions.addonCategoryFailed())
  }
}

const apiGetAddonCategory = () => {
  return API_GET(`${process.env.REACT_APP_DISPLAY_DOMAIN}/api/category/list`)
}

export default function* addonCategory() {
  yield fork(watchGetaddonCategory)
}
