import { useEffect, useState } from "react"
import { NavLink } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"

import MarketPlaceFilterSkeleton from "skeletons/MarketPlace/MarketPlaceFilter"

import { addonCategoryRequested } from "redux/actions/addonCategoryList"

const MarketPlaceFilter = ({ allAddonListButton, isFreeButton, isPaidButton }) => {
  const dispatch = useDispatch()

  const [activeLink, setActiveLink] = useState()

  const addonListGet = useSelector((state) => state.Addon?.list)
  const addonCategoryList = useSelector((state) => state.AddonCategoryList?.list)
  const addonCategoryListLoading = useSelector((state) => state.AddonCategoryList?.loading)
  const addonCategoryListError = useSelector((state) => state.AddonCategoryList?.error)

  const handleClick = (link) => {
    setActiveLink(link)
  }

  useEffect(() => {
    dispatch(addonCategoryRequested())
  }, [dispatch])

  return (
    <>
      {addonCategoryListLoading && <MarketPlaceFilterSkeleton />}
      {!addonCategoryListLoading && !addonCategoryListError && (
        <aside className="aside border-r border-r-gray-200 p-6">
          <nav className="flex flex-col">
            <NavLink
              to="/marketplace"
              exact={true}
            >
              <span>Ana Sayfa</span>
            </NavLink>
            <NavLink
              to="/marketplace/plugins?my-plugins"
              exact={true}
            >
              <span>Eklentilerim</span>
            </NavLink>
            <div className="h-[1px] w-full bg-gray-200 mt-10 mb-8" />
          </nav>
          <div>
            <input
              type="text"
              placeholder="Eklenti arayın"
            />
            <div className="mt-8">
              <h3 className="text-gray-800 font-medium">Kategoriler</h3>
              <ul className="flex flex-col gap-4 mt-4 font-normal text-sm text-gray-500">
                <li className="flex justify-between">
                  <NavLink
                    to="/marketplace?all=true"
                    exact={true}
                    onClick={allAddonListButton}
                  >
                    <span>Tümü</span>
                  </NavLink>
                  <span>{addonListGet?.length}</span>
                </li>
                <nav className="flex flex-col gap-4">
                  {addonCategoryList?.map(
                    (value, index) =>
                      value.AddonCategories.length > 0 && (
                        <div
                          key={index}
                          className="flex justify-between w-full"
                        >
                          <NavLink
                            to={`/marketplace/plugins?categoryId=${value.Id}&categoryName=${value.Name}`}
                            exact={true}
                            onClick={() => handleClick(index)}
                            className={`flex justify-between w-full text-gray-500 ${
                              activeLink === index ? "text-blue-500" : ""
                            } `}
                          >
                            <span>{value.Name}</span>
                            <span>{value.AddonCategories.length}</span>
                          </NavLink>
                        </div>
                      ),
                  )}
                </nav>
              </ul>
            </div>
          </div>
          <div className="mt-8">
            <h3 className="text-gray-800 font-medium">Filtrele</h3>
            <div className="flex flex-col mt-4">
              <label className="flex flex-row items-center mb-2 cursor-pointer">
                <input
                  className="mr-2"
                  type="checkbox"
                  onChange={isFreeButton}
                />
                <p className="text-sm text-gray-500">Ücretsiz</p>
              </label>
              <label className="flex flex-row items-center cursor-pointer">
                <input
                  className="mr-2"
                  type="checkbox"
                  onChange={isPaidButton}
                />
                <p className="text-sm text-gray-500">Ücretli</p>
              </label>
            </div>
          </div>
        </aside>
      )}
    </>
  )
}

MarketPlaceFilter.propTypes = {
  allAddonListButton: PropTypes.func,
  isFreeButton: PropTypes.func,
  isPaidButton: PropTypes.func,
}

export default MarketPlaceFilter
