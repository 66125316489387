import moment from "moment"
export function getCityName(text) {
  let cityName = text.replace(/\([^)]*\)/g, "").trim()
  if (cityName.includes(",")) {
    cityName = cityName.split(",")[0].trim()
  }

  return cityName
}

export function getAirportCode(location) {
  const match = location.match(/\((.*?)\)/)
  return match ? match[1]?.split("-")[0] : "Bilinmeyen havalimanı"
}

export function dateToTime(date) {
  return moment(date).format("HH:mm")
}

export function getFormatedFlightTime(flightTime) {
  const splitedFlightTime = flightTime.split(":")
  const hour = splitedFlightTime[0]
  const minute = splitedFlightTime[1]

  const hourStr = hour === "00" ? "" : hour[0] === "0" ? hour[1] : hour
  const minuteStr = minute === "00" ? "" : minute[0] === "0" ? minute[1] : minute

  return `${hourStr}sa ${minuteStr}dk`
}
