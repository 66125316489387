let nameList = []
export const getEntiredComponents = {
	reset: () => {
		nameList = []
	},
	init: (obj, target) => {
		if(obj && target in obj){
			nameList.push({
				name: obj[target],
				code: obj?.code
			})
		}
		obj && Object.values(obj).reduce((acc, val) => {
			if (acc !== undefined){
				nameList.push({
					name: acc,
					code: val
				})
			}
			if (Array.isArray(val)){
				for(let i = 0; i < val.length; i++){
					getEntiredComponents.init(val[i], target)
				}
			}
		}, undefined);
		return nameList
	}
}