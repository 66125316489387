import React, { useCallback, useEffect } from "react"
import {useNavigate, useParams} from "react-router-dom"
import Header from "components/Headers/MainHeader"
import { Grid, Row, Col, Stack, Badge } from "rsuite"
import { Icon } from "components"
import UserDeleteModal, { useUserDeleteModal } from "components/Settings/UserManagement/UserDeleteModal"
import {useDispatch, useSelector} from "react-redux";
import {clientUserGetRequested} from "../../../redux/actions/clientUserGet";

const UserDetail = (props) => {
  let { isLogged } = props
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { id } = useParams()
  const { open, handleOpen, data: modalData, ...modalProps } = useUserDeleteModal()

  const userInfo = useSelector(state => state.clientUserGet.list)
  const handleOnNavigate = useCallback((path = "") => navigate("/settings/user-management" + path), [navigate])

  useEffect(() => {
    dispatch(clientUserGetRequested(id))
  }, [dispatch, id])
  return (
      <>
        {userInfo && (
            <>
              <Header isLogged={isLogged} />
              <main>
                <section className="p-10 my-0 mx-auto max-w-[778px]">
            <span
                className="!text-gray-600 flex justify-start items-center gap-2 mt-2 mb-8 cursor-pointer w-fit transition-all hover:drop-shadow"
                onClick={() => handleOnNavigate("/list")}
            >
              <Icon
                  name="arrow-left"
                  size="20"
                  className="!bg-gray-500"
              />
              <h4>Kullanıcı Listesi</h4>
            </span>
                  <Grid fluid>
                    <Row gutter={10}>
                      <Col
                          xs={24}
                          xl={16}
                          className="my-2"
                      >
                        <h1 className="text-3xl text-gray-800 font-semibold mb-1">Kullanıcı Detay</h1>
                      </Col>
                      <Col
                          xs={24}
                          xl={8}
                          className="my-2 flex justify-end items-center"
                      >
                        <Stack spacing={10}>
                          <div
                              className="btn"
                              onClick={() => handleOnNavigate("/" + userInfo.userId + "/edit")}
                          >
                            Düzenle
                          </div>
                        </Stack>
                      </Col>
                      <Col xs={24}>
                        <div className="w-full my-3 p-7 rounded-lg border border-gray-200 bg-white">
                          <Grid fluid>
                            <Row
                                gutter={10}
                                className="mb-[24px]"
                            >
                              <Col xs={12}>
                                <p className="font-medium text-sm">Ad Soyad</p>
                                <p className="text-lg text-gray-500">{`${userInfo.name} ${userInfo.surname}`}</p>
                              </Col>
                              <Col xs={12}>
                                <p className="font-medium text-sm">Statü</p>
                                <span className="text-lg text-gray-500">
                            {userInfo.status ? (
                                <Badge
                                    className="p-1 px-2 rounded-xl bg-green-100 text-green-500 font-semibold"
                                    content="Aktif"
                                />
                            ) : (
                                <Badge
                                    className="p-1 px-2 rounded-xl bg-red-100 text-red-500 font-semibold"
                                    content="Pasif"
                                />
                            )}
                          </span>
                              </Col>
                            </Row>
                            <Row
                                gutter={10}
                                className="mb-[24px]"
                            >
                              <Col xs={12}>
                                <p className="font-medium text-sm">Departman Adı</p>
                                <p className="text-lg text-gray-500">{userInfo && userInfo.departments.map(d => d.departmentName).join(", ")}</p>

                              </Col>
                              <Col xs={12}>
                                <p className="font-medium text-sm">Rol</p>
                                <p className="text-lg text-gray-500">{userInfo && userInfo.role.map(d => d.roleName).join(", ")}</p>
                              </Col>
                            </Row>
                            <Row
                                gutter={10}
                                className="mb-[24px]"
                            >
                              <Col xs={12}>
                                <p className="font-medium text-sm">Telefon Numarası</p>
                                <p className="text-lg text-gray-500">{userInfo.phoneNumber}</p>
                              </Col>
                              <Col xs={12}>
                                <p className="font-medium text-sm">Email</p>
                                <p className="text-lg text-gray-500">{userInfo.email}</p>
                              </Col>
                            </Row>
                            <Row gutter={10}>
                              <Col xs={24}>
                                <p>Şifre</p>
                                <p className="text-lg text-gray-500">**************</p>
                              </Col>
                            </Row>
                          </Grid>
                        </div>
                      </Col>
                    </Row>
                  </Grid>
                </section>
              </main>
              <UserDeleteModal
                  {...modalProps}
                  open={open}
                  data={modalData}
              />
            </>
        )}
      </>
  )
}

export default UserDetail
