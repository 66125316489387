import * as type from "redux/types"

export const getDesignFieldsValueRequested = (data) => ({
  type: type.DESIGN_FIELDS_VALUE_REQUESTED,
  payload: data,
})

export const getDesignFieldsValueSucceed = (data) => ({
  type: type.DESIGN_FIELDS_VALUE_SUCCEEDED,
  payload: data,
})

export const getDesignFieldsValueFailed = () => ({
  type: type.DESIGN_FIELDS_VALUE_FAILED,
})

export const saveDesignFieldsValueRequested = (data) => ({
  type: type.DESIGN_FIELDS_VALUE_SAVE_REQUESTED,
  payload: data,
})

export const saveDesignFieldsValueSucceed = (data) => ({
  type: type.DESIGN_FIELDS_VALUE_SAVE_SUCCEEDED,
  payload: data,
})

export const saveDesignFieldsValueFailed = () => ({
  type: type.DESIGN_FIELDS_VALUE_SAVE_FAILED,
})

export const getTemplatesRequested = (data) => ({
  type: type.GET_TEMPLATES_REQUESTED,
  payload: data,
})

export const getTemplatesSucceed = (data) => ({
  type: type.GET_TEMPLATES_SUCCEEDED,
  payload: data,
})

export const getTemplatesFailed = () => ({
  type: type.GET_TEMPLATES_FAILED,
})

export const deleteStrategyRequested = (data) => ({
  type: type.DELETE_STRATEGY_REQUESTED,
  payload: data,
})

export const deleteStrategySucceed = (data) => ({
  type: type.DELETE_STRATEGY_SUCCEEDED,
  payload: data,
})

export const deleteStrategyFailed = () => ({
  type: type.DELETE_STRATEGY_FAILED,
})
