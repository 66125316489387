export const transferTravelPlannerData = {
  "visual": {
    "style": {
      "width": undefined,
      "height": 0,
      "backgroundColor": undefined,
      "fontSize": undefined,
      "fontWeight": undefined,
      "color": undefined,
      "position": undefined,
      "fontFamily": undefined,
      "borderRadius": undefined,
      "lineHeight": undefined,
      "zIndex": undefined,
      "opacity": undefined,
      "paddingRight": undefined,
      "paddingTop": undefined,
      "paddingBottom": undefined,
      "paddingLeft": undefined,
      "marginRight": undefined,
      "marginTop": undefined,
      "marginBottom": undefined,
      "marginLeft": undefined,
      "left": undefined,
      "top": undefined,
      "right": undefined,
      "bottom": undefined,
      "cursor": undefined,
      "borderBottom": undefined,
      "borderTop": undefined,
      "borderRight": undefined,
      "borderLeft": undefined,
      "gap": undefined,
      "display": undefined,
      "alignItems": undefined,
      "padding": undefined,
      "border": undefined,
      "justifyContent": undefined,
      "margin": undefined,
      "flexDirection": undefined,
      "textAlign": undefined,
      "whiteSpace": undefined
    },
    "settings": undefined,
    "code": "transferTravelPlannerData",
    "outputData": undefined,
    "type": 1,
    "name": "Visual.Atom.TravelPlannerTransfer",
    "status": 1
  },
  "data": {
    "title": "",
    "titleLangKey": "titleLangKey",
    "subTitle": "",
    "subTitleLangKey": "subTitleLangKey",
    "backgroundImagePath": undefined,
    "button": "Ara",
    "titleStyle": {
      "textAlign": undefined,
      "fontSize": undefined,
      "fontFamily": undefined,
      "color": undefined,
      "fontWeight": undefined,
      "visible": undefined,
      "backgroundColor": undefined,
      "position": undefined,
      "borderRadius": undefined,
      "lineHeight": undefined,
      "zIndex": undefined,
      "opacity": undefined,
      "paddingRight": undefined,
      "paddingTop": undefined,
      "paddingBottom": undefined,
      "paddingLeft": undefined,
      "marginRight": undefined,
      "marginTop": undefined,
      "marginBottom": undefined,
      "marginLeft": undefined,
      "left": undefined,
      "top": undefined,
      "right": undefined,
      "bottom": undefined,
      "cursor": undefined,
      "borderBottom": undefined,
      "borderTop": undefined,
      "borderRight": undefined,
      "borderLeft": undefined,
      "gap": undefined,
      "display": undefined,
      "alignItems": undefined,
      "padding": undefined,
      "border": undefined,
      "justifyContent": undefined,
      "margin": undefined,
      "flexDirection": undefined,
      "width": undefined,
      "whiteSpace": undefined
    },
    "subTitleStyle": {
      "textAlign": undefined,
      "fontSize": undefined,
      "fontFamily": undefined,
      "color": undefined,
      "fontWeight": undefined,
      "visible": undefined,
      "backgroundColor": undefined,
      "position": undefined,
      "borderRadius": undefined,
      "lineHeight": undefined,
      "zIndex": undefined,
      "opacity": undefined,
      "paddingRight": undefined,
      "paddingTop": undefined,
      "paddingBottom": undefined,
      "paddingLeft": undefined,
      "marginRight": undefined,
      "marginTop": undefined,
      "marginBottom": undefined,
      "marginLeft": undefined,
      "left": undefined,
      "top": undefined,
      "right": undefined,
      "bottom": undefined,
      "cursor": undefined,
      "borderBottom": undefined,
      "borderTop": undefined,
      "borderRight": undefined,
      "borderLeft": undefined,
      "gap": undefined,
      "display": undefined,
      "alignItems": undefined,
      "padding": undefined,
      "border": undefined,
      "justifyContent": undefined,
      "margin": undefined,
      "flexDirection": undefined,
      "width": undefined,
      "whiteSpace": undefined
    },
    "code": "data code",
    "outputData": undefined,
    "type": 2,
    "name": "Data.Molecule.TravelPlannerTransfer",
    "status": 1
  },
  "event": undefined,
  "code": "component code",
  "outputData": undefined,
  "type": 5,
  "name": "Travel Planner Transfer",
  "status": 1
}

export const transferTravelPlannerTabButtons = [
  {label: "Gidiş Dönüş", value: "return"},
  {label: "Tek Yön", value: "one-way"},
]
