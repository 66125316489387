import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom"
import useQuery from "utils/useQuery";
import {useParams} from "react-router";
import QRCode from "react-qr-code";

// components
import {Dropdown, Whisper} from "rsuite"
import {Alert, Icon, LoadBar, Modal} from "components"
import {buildStyles, CircularProgressbar} from "react-circular-progressbar";

// layouts
import {ClonePathAlert, DeletePathAlert} from "pages/PageManager/layouts/Alerts";

// tooltips
import {
  DeviceBoxTooltip,
  LogoTooltip,
  PageLanguageBoxTooltip,
  pageNameIsCustomTooltip,
  PageSelectorBoxTooltip,
  PageStatusBoxTooltip,
  saveBtnTooltip,
} from "tooltips/PageManager/AddNewPage/index"

// images
import logo from "assets/svg/eva-single.svg"

// enums
import {
  ATOM_PAGE,
  definitionIdList,
  ICON_LIST,
  MEDIA_LIST,
  TAB_SLIDER,
  TEXT_HTML,
  TRAVEL_PLANNER_CAR_RENTAL_AGENCY,
  TRAVEL_PLANNER_CAR_RENTAL_CORPORATE,
  TRAVEL_PLANNER_FLIGHT,
  TRAVEL_PLANNER_HOTEL,
  TRAVEL_PLANNER_TOUR,
  TRAVEL_PLANNER_TRANSFER_AGENCY,
  TRAVEL_PLANNER_TRANSFER_CORPORATE
} from "pages/PageManager/Enums";

// cookies
import Cookies from "universal-cookie"

//helpers
import {API_POST} from "helpers/api";
import {getDesignFieldsValueRequestedAxios} from "pages/PageManager/helpers/GetDesignFieldsValueAxios";
import {createStrategyMultipleSavePattern} from "pages/PageManager/helpers/CreateStrategyMultipleSavePattern";
import {modifyTPByNewData} from "pages/PageManager/helpers/ModifyTPByNewData";
import {modifyTabSliderByNewData} from "pages/PageManager/helpers/ModifyTabSliderByNewData";
import {modifyMediaListByNewData} from "pages/PageManager/helpers/ModifyMediaListByNewData";
import {modifyTextHtmlByNewData} from "pages/PageManager/helpers/ModifyTextHtmlByNewData";
import {modifyIconListByNewData} from "pages/PageManager/helpers/ModifyIconListByNewData";
import {extractNameAndCode} from "pages/PageManager/helpers/extractNameAndCode";
import {getEntiredComponents} from "pages/PageManager/helpers/getEntiredComponents";
import {structureJson} from "pages/PageManager/helpers/structureJson";
import { getItemRelationObjectData, getItemRelationObjectIndex } from "pages/PageManager/helpers/getItemRelationObject";

//saga-requests
import {getStrategySucceed} from "redux/actions/strategy";
import {pageSaveSucceed} from "redux/actions/pageSave";
import {pageUpdateSucceed} from "redux/actions/pageUpdate";
import {pageStateRequested, pageStateSucceed} from "redux/actions/pageState";


const PageManagerHeader = (props) => {

  const {visualValues, setVisualValues, layoutPreview, setLayoutPreview, seoSettings } = props

  const navigate = useNavigate()
  const dispatch = useDispatch()
  let query = useQuery()
  let queryPageType = query.get("pageType")
  let queryPageState = query.get("pageState")

  //selectors
  let components = useSelector(state => state.strategy.getStrategy.list?.result)
  const pageSave = useSelector(state => state.pageSave.response)
  const pageUpdate = useSelector(state => state.pageUpdate.response)
  const statePage = useSelector((state) => state.pageState.response)
  const statePageLoading = useSelector((state) => state.pageState.loading)
  const statePageError = useSelector((state) => state.pageState.error)


  const cookies = new Cookies()
  const DomainName = cookies.get("associatedDomain")?.domainName

  //states
  //const [PageSelectorDdownOpen, setPageSelectorDdownOpen] = useState(false)
  const [PageSettingsDotsOpen, setPageSettingsDotsOpen] = useState(false)
  const [Languages, setLanguages] = useState([
    { name: "Türkçe", isMainLang: true, selected: true },
    { name: "İngilizce", isMainLang: false, selected: false },
    { name: "Almanca", isMainLang: false, selected: false },
    { name: "Arapça", isMainLang: false, selected: false },
  ])
  const [LanguageDdownOpen, setLanguageDdownOpen] = useState(false)
  const [ActiveLanguage, setActiveLanguage] = useState(Languages?.find((lang) => lang?.selected))
  const [PageStatusDdownOpen, setPageStatusDdownOpen] = useState(false)
  const [PageSituations, setPageSituations] = useState([
    { name: "Pasif", selected: Number(queryPageState) === 0, statusCode: 0 },
    { name: "Aktif", selected: Number(queryPageState) === 1, statusCode: 1 },
  ])
  const [ActivePageStatus, setActivePageStatus] = useState(PageSituations?.find((status) => status?.selected))
  const [CloneComponents, setCloneComponents] = useState(null)
  const [Loading, setLoading] = useState(false)
  const [SaveProgressPercentage, setSaveProgressPercentage] = useState(0)
  const [SaveProgressText, setSaveProgressText] = useState("")

  const [WillStateRow, setWillStateRow] = useState(null)
  const [WillDeleteRow, setWillDeleteRow] = useState(null)
  const [WillCloneRow, setWillCloneRow] = useState(null)
  const [queryName, setQueryName] = useState(query.get("name"))
  const [queryPath, setQueryPath] = useState(query.get("path"))
  const [isCustom, setIsCustom] = useState(query.get("isCustom") || "true")
  const [queryIsMainPage, setQueryIsMainPage] = useState(query.get("isMainPage"))
  const [Params, setParams] = useState(useParams())
  const [MobilePreviewModal, setMobilePreviewModal] = useState(false)


  /*const pageSelectorDropDownToggle = (show) => {
    setPageSelectorDdownOpen(show)
  }*/
  const pageSettingsDotsToggle = (show) => {
    setPageSettingsDotsOpen(show)
  }
  const LanguageDropDownToggle = (show) => {
    setLanguageDdownOpen(show)
  }
  const PageStatusDropDownToggle = (show) => {
    setPageStatusDdownOpen(show)
  }

  /*const renderPageSelectorDDownMenu = ({ onClose, left, top, className }, ref) => {
    const handleSelect = () => {
      onClose()
      //console.log(eventKey)
    }
    return (
      <Dropdown.Menu
        ref={ref}
        className={`${className} min-w-[180px] text-sm`}
        style={{ left, top }}
        onSelect={handleSelect}
      >
        <Dropdown.Item className="font-medium py-2.5 px-4 cursor-default !bg-white !text-gray-800 hover:bg-white hover:text-gray-800">
          Sayfalar
        </Dropdown.Item>
        <hr />
        <Dropdown.Item className="flex items-center justify-between py-2.5 px-4 cursor-default !bg-white !text-gray-800 hover:!bg-white hover:text-gray-800">
          <div className="flex items-center mr-10">
            <Icon
              name="corner-down-right"
              size="16"
              color="#2E90FA"
              className="mr-3"
            />
            <span className="page-name">{queryName}</span>
          </div>
          <span className="page-category text-gray-500 text-xs">{Object.keys(PageTypes)[queryPageType]}</span>
        </Dropdown.Item>
      </Dropdown.Menu>
    )
  }*/
  const renderPageSettingsDDownMenu = ({ onClose, left, top, className }, ref) => {
    const handleSelect = (eventkey) => {
      if (eventkey === "delete") {
        setWillDeleteRow({Id: Params?.pageId, Name: queryName, Path: queryPath, isCustom: isCustom})
      } else if (eventkey === "copy") {
        setWillCloneRow({Id: Params?.pageId, Name: queryName, Path: queryPath, isCustom: isCustom})
      } else if (eventkey === "mobilePreview"){
        setMobilePreviewModal(!MobilePreviewModal)
        onClose()
      } else {
        onClose()
      }
    }
    return (
      <Dropdown.Menu
        ref={ref}
        className={`${className} min-w-[180px] text-sm`}
        style={{ left, top }}
        onSelect={handleSelect}
      >
        <Dropdown.Item
          eventKey="disabled"
          className="font-medium py-2.5 px-4 cursor-default !bg-white !text-gray-800 hover:bg-white hover:text-gray-800"
        >
          Seçenekler
        </Dropdown.Item>
        <hr />
        <Dropdown.Item className="p-0">
          <Link
            to={`https://${DomainName}${queryPath && queryPath?.startsWith("/") ? queryPath : `/${queryPath}`}`}
            target="_blank"
            className="flex items-center gap-3 py-2.5 px-4"
          >
            <Icon
              name="eye"
              size="16"
              color="#1D2939"
            />
            <span className="page-setting-item">Siteyi görüntüle</span>
          </Link>
        </Dropdown.Item>
        <Dropdown.Item
          eventKey="mobilePreview"
          className="flex items-center gap-3 py-2.5 px-4"
        >
          <Icon
            name="eye"
            size="16"
            color="#1D2939"
          />
          <span className="page-setting-item">Mobil siteyi görüntüle</span>
        </Dropdown.Item>
        <Dropdown.Item
          eventKey="copy"
          className="flex items-center gap-3 py-2.5 px-4"
        >
          <Icon
            name="copy"
            size="16"
            color="#1D2939"
          />
          <span className="page-setting-item">Kopyala</span>
        </Dropdown.Item>
        {isCustom !== "false" && queryIsMainPage === "false" && (
          <Dropdown.Item
            eventKey="delete"
            className="flex items-center gap-3 py-2.5 px-4"
          >
            <Icon
              name="trash"
              size="16"
              color="#1D2939"
            />
            <span className="page-setting-item">Sil</span>
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    )
  }
  const renderLanguageDDownMenu = ({ onClose, left, top, className }, ref) => {
    const changeLanguage = (eventKey) => {
      if (eventKey) {
        eventKey.selected = true
        const langs = JSON.parse(JSON.stringify(Languages))
        Array.from(langs, (lang) => {
          if (lang.name === eventKey.name) {
            Array.from(langs, (lang) => (lang.selected ? (lang.selected = false) : null))
            lang.selected = true
          }
          return true
        })
        setLanguages([...langs])
        onClose()
      }
    }
    return (
      <Dropdown.Menu
        ref={ref}
        className={`${className} min-w-[180px] text-sm text-gray-800`}
        style={{ left, top }}
        onSelect={changeLanguage}
      >
        <Dropdown.Item className="font-medium py-2.5 px-4 cursor-default !bg-white !text-gray-800 hover:!bg-white hover:text-gray-800">
          Dil seçenekleri
        </Dropdown.Item>
        <hr />
        <Dropdown.Item
          eventKey={ActiveLanguage}
          className="flex items-center justify-between py-2.5 px-4 !bg-white !text-gray-800 hover:!bg-white hover:text-gray-800"
        >
          <div className="flex items-center">
            <Icon
              name="corner-down-right"
              size="16"
              color="#2E90FA"
              className="mr-3"
            />
            <span className="lang-name">{ActiveLanguage?.name}</span>
          </div>
          {ActiveLanguage?.isMainLang && <span className="lang-subtext text-gray-500 text-xs">Ana dil</span>}
        </Dropdown.Item>
        {Languages &&
          Languages.map((lang, index) => {
            return (
              <Dropdown.Item
                //disabled={!lang.isMainLang}
                key={index}
                //eventKey={lang}
                className={`${
                  !lang.isMainLang ? "!bg-white !text-gray-800 hover:!bg-white hover:text-gray-800 opacity-50" : ""
                } flex items-center justify-between py-2.5 px-4 cursor-not-allowed `}
                hidden={lang.selected}
              >
                <div className="flex items-center">
                  <span className="lang-name">{lang?.name}</span>
                </div>
                {lang?.isMainLang && <span className="lang-subtext text-gray-500 text-xs">Ana dil</span>}
              </Dropdown.Item>
            )
          })}
        <hr />
        <Dropdown.Item className="py-2.5 px-4 text-center cursor-not-allowed !bg-white !text-gray-800 hover:!bg-white hover:text-gray-800 opacity-50">
          Dilleri yönet
        </Dropdown.Item>
      </Dropdown.Menu>
    )
  }
  const renderPageStatusDDownMenu = ({ onClose, left, top, className }, ref) => {
    return (
      <Dropdown.Menu
        ref={ref}
        className={`${className} min-w-[180px] text-sm text-gray-800`}
        style={{ left, top }}
        onSelect={(e) => e ? setWillStateRow({Id: Params?.pageId, State: e?.statusCode, Name: queryName, Path: queryPath}) : undefined}
      >
        <Dropdown.Item className="font-medium py-2.5 px-4 cursor-default !bg-white !text-gray-800 hover:!bg-white hover:text-gray-800">
          Sayfa durumu
        </Dropdown.Item>
        <hr />
        <Dropdown.Item className="flex items-center justify-between py-2.5 px-4 cursor-default !bg-white !text-gray-800 hover:!bg-white hover:text-gray-800">
          <div className="flex items-center">
            <Icon
              name="corner-down-right"
              size="16"
              color="#2E90FA"
              className="mr-3"
            />
            <span className="page-status-text">{ActivePageStatus?.name}</span>
          </div>
        </Dropdown.Item>

        {PageSituations &&
          PageSituations.map((status, index) => {
            return (
              <Dropdown.Item
                key={index}
                eventKey={status}
                className="flex items-center justify-between py-2.5 px-4"
                hidden={status.selected}
              >
                <div className="flex items-center">
                  <span className="lang-name">{status?.name}</span>
                </div>
              </Dropdown.Item>
            )
          })}

        <hr />
        <Dropdown.Item className="py-2.5 px-4 text-blue-500 text-center">Yayın ayarları</Dropdown.Item>
      </Dropdown.Menu>
    )
  }

  const changePageStatus = (situations, state) => {
    Array.from(situations, (status) => {
      if (status.statusCode === state) {
        Array.from(situations, (status) => (status.selected ? (status.selected = false) : null))
        status.selected = true
      }
      return situations
    })
    return situations
  }

  const getComponentRequests = async (requestTypes) => {
    const sameRequests = await requestTypes
    var eliminateSameRequests = sameRequests.filter((payload, index, list) => {
      if (payload?.StrategyCode) return true
      return index === list.findIndex((t) => (t.DefinitionId === payload.DefinitionId))
    })

    const responses = Promise.all(eliminateSameRequests.map(async (req) => {
      return await getDesignFieldsValueRequestedAxios({
        ...req,
        LanguageCode: "tr",
      }).then(resp => {
        setSaveProgressText(`Bloklar hazirlandi`)
        setSaveProgressPercentage(2)
        const definitionId = getItemRelationObjectData(resp?.data.result.strategies)?.definitionId
        const componentIndex = Object.values(definitionIdList).findIndex((val) => val === definitionId)
        const componentName = Object.keys(definitionIdList)[componentIndex]
        const strategyIndex = getItemRelationObjectIndex(resp?.data.result.strategies)
        return {
          code: resp?.data.result.strategies[strategyIndex]?.code,
          name: componentName,
          ChildStrategies: resp?.data.result.strategies
        }
      })
    }));

    return await responses

  }
  const saveChanges = async () => {
    setLoading(true)
    // eklenmis olan componentlerin modellerini al
    let mainPattern = null
    setSaveProgressPercentage(1)

    const simplifiedComponents = extractNameAndCode(components);
    getEntiredComponents.reset()
    const entiredComponents = getEntiredComponents.init(simplifiedComponents, "name")

    const requestTypes = entiredComponents && Promise.all(entiredComponents.map(async (comp, index) => {
      let requestPayload
      if (comp?.code.includes("new")){
        // yeni bir comp
        requestPayload = {
          DefinitionId: definitionIdList[comp.name],
        }
      }
      else {
        // mevcut comp
        requestPayload = {
          StrategyCode: comp?.code,
        }
      }
      setSaveProgressText(`Bloklar hazirlaniyor`)
      return requestPayload
    }));


    if (await requestTypes){
      const requests = await getComponentRequests(await requestTypes)
      const atomPageModel = requests?.find(req => req?.name === ATOM_PAGE)?.ChildStrategies
      const structuredX = structureJson(requests, simplifiedComponents);
      createStrategyMultipleSavePattern("tr", Number(Params?.pageId !== "new" ? Params?.pageId : pageSave?.Result?.Id ? pageSave.Result.Id : pageUpdate?.Result?.Id), isCustom === "false" ? 0 : 1, atomPageModel, structuredX, [], visualValues, seoSettings).then(pattern => {
        setSaveProgressPercentage(4)
        mainPattern = pattern
        if (!components?.children || !components?.children.length){ // todo undefined da gelebiliyor
          saveDesignFieldsValueMultiple(mainPattern)
        } else {
          middleware(mainPattern).then((res) => {
            const componentIndex = getItemRelationObjectIndex(mainPattern.Strategies[0].ChildStrategies)
            res.map((r, i) => mainPattern.Strategies[0].ChildStrategies[componentIndex].Childs[i].ChildStrategies = r)
            console.log(mainPattern);
            saveDesignFieldsValueMultiple(mainPattern)
          })
        }
      })

    }
  }
  const middleware = async (mainPattern) => {
    const result = await components?.children && Promise.all(components.children.map(async (comp, index) => {
      const relationComponentIndex = getItemRelationObjectIndex(mainPattern?.Strategies[0]?.ChildStrategies)
      const strategies = mainPattern?.Strategies[0]?.ChildStrategies[relationComponentIndex]?.Childs[index]?.ChildStrategies
      if (
        comp?.name === TRAVEL_PLANNER_HOTEL ||
        comp?.name === TRAVEL_PLANNER_TOUR ||
        comp?.name === TRAVEL_PLANNER_FLIGHT ||
        comp?.name === TRAVEL_PLANNER_TRANSFER_CORPORATE ||
        comp?.name === TRAVEL_PLANNER_TRANSFER_AGENCY ||
        comp?.name === TRAVEL_PLANNER_CAR_RENTAL_CORPORATE ||
        comp?.name === TRAVEL_PLANNER_CAR_RENTAL_AGENCY
      ){
        return await modifyTPByNewData(comp, strategies, "backgroundImagePath")
      }
      else if (comp?.name === TAB_SLIDER){
        return await modifyTabSliderByNewData(comp, strategies, "src")
      }
      else if (comp?.name === MEDIA_LIST){
        return await modifyMediaListByNewData(comp, strategies, "src")
      }
      else if (comp?.name === TEXT_HTML){
        return await modifyTextHtmlByNewData(comp, strategies, "backgroundImagePath")
      }
      else if (comp?.name === ICON_LIST){
        return await modifyIconListByNewData(comp, strategies, "mediaPath")
      }
    }));
    return await result;
  }
  const DetectDeletedComponent = (oldValues, newValues, mainPattern) => {
    // Recursive fonksiyon çocukları da kontrol edecek şekilde
    function extractCodes(data) {
      const codes = new Set();
      function addCodes(items) {
        if (items){
          for (const item of items) {
            codes.add(item?.code);
            if (item?.children && item?.children.length > 0) {
              addCodes(item?.children);
            }
          }
        }
      }
      addCodes(data);
      return codes;
    }

    // İki JSON verisindeki tüm "code" değerlerini bir sete ekle
    const codes1 = extractCodes(oldValues);
    const codes2 = extractCodes(newValues);

    // İlk JSON'daki "code" değerlerinden ikinci JSON'da bulunmayanları bul
    return Array.from(codes1).filter(code => !codes2.has(code));
  }

  const saveDesignFieldsValueMultiple = (pattern) => {
    setSaveProgressPercentage(5)
    setSaveProgressText(`Sayfa kaydediliyor`)
    pattern["RemoveStrategyCodes"] = DetectDeletedComponent(CloneComponents?.children, components?.children) || []
    API_POST(`${process.env.REACT_APP_DISPLAY_DOMAIN}/api/v1/strategydesign/save-multiple-design-fields-value`, pattern).then(res => {
      let reqId, isCustom, pagePath, pageName;
      setSaveProgressPercentage(6)
      if (res?.data?.success){
        setSaveProgressText(`Sayfa kaydedildi`)
        reqId = res?.data?.result?.requestId
        isCustom = res?.data?.result?.requestType === 1
        pagePath = pageSave?.Result?.Path || pageUpdate?.Result?.Path || queryPath
        pageName = pageSave?.Result?.Name || pageUpdate?.Result?.Name || queryName
        setLoading(false)
      }
      setTimeout(() => {
        window.location.replace(`${reqId}?isCustom=${isCustom}&path=${pagePath}&name=${pageName}&pageType=${queryPageType}&pageState=${queryPageState || 1}&isMainPage=${queryIsMainPage || false}`)
      }, 300)
    })
  }
  //effects

  useEffect(() => {
    pageNameIsCustomTooltip()
    LogoTooltip("Kontrol paneline dön") // send text for globalization
    PageSelectorBoxTooltip()
    DeviceBoxTooltip()
    PageLanguageBoxTooltip()
    PageStatusBoxTooltip(
      '<div><span class="font-medium">Sayfa durumunu değiştir</span><span class="block mt-1">Sayfa statülerini görüntüleyin ve aralarından seçim yaparak değişikliklerinizi kaydedin.</span></div>',
    )
    //previewBtnTooltip()
    saveBtnTooltip()
  }, [])
  // clone
  useEffect(() => {
    if (components && !CloneComponents){
      setCloneComponents(JSON.parse(JSON.stringify(components)))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [components])

  useEffect(() => {
    if (Languages.length) {
      const activeLanguage = Languages?.find((lang) => lang?.selected)
      setActiveLanguage(activeLanguage)
    }
  }, [Languages])
  useEffect(() => {
    if (PageSituations.length) {
      const activeStatus = PageSituations?.find((status) => status?.selected)
      setActivePageStatus(activeStatus)
    }
  }, [PageSituations])
  useEffect(() => {
    if (statePage || statePageError){
      if (statePage?.Success) {
        setWillStateRow(null)
        // slug renew
        const {Id, Path, Name, PageType, State} = statePage.Result
        window.history.pushState('', '', `${Id}?isCustom=${!!isCustom}&path=${Path}&name=${Name}&pageType=${PageType}&pageState=${State}&isMainPage=${queryIsMainPage || false}`);
        const situations = JSON.parse(JSON.stringify(PageSituations))
        const newSituations = changePageStatus(situations, State)
        setPageSituations([...newSituations])
        dispatch(pageStateSucceed(null))
      } else if(!statePage?.Success){
        setWillStateRow({
          ...WillStateRow,
          errorMessage: statePage?.Errors && statePage?.Errors[0]?.Message
        })
      } else if (statePageError){
        setWillStateRow({
          ...WillStateRow,
          errorMessage: "güncelleme işlemi sırasında bir sorun oluştu, lütfen tekrar deneyiniz."
        })
      }
    }
    return () => {
      setWillStateRow(null)
      dispatch(pageStateSucceed(null))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statePage, statePageError])
  useEffect(() => {
    if (pageSave?.Success || pageUpdate?.Success){
      setQueryName(pageSave?.Result?.Name || pageUpdate?.Result?.Name)
      setQueryPath(pageSave?.Result?.Path || pageUpdate?.Result?.Path)
      setIsCustom(isCustom || "true")
      setQueryIsMainPage(queryIsMainPage || "false")
      setParams({
        ...Params,
        pageId: pageSave?.Result?.Id || pageUpdate?.Result?.Id
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSave, pageUpdate])

  return (
    <>
      <DeletePathAlert
        state={WillDeleteRow}
        setState={setWillDeleteRow}
        noListRequest
        onSuccess={() => {
          navigate("/page-manager")
        }}
      />

      <ClonePathAlert
        state={WillCloneRow}
        setState={setWillCloneRow}
        noListRequest
        onSuccess={() => {
          navigate("/page-manager")
        }}
      />
      {WillStateRow && (
        <Alert
          iconName={""}
          approveButtonText={`${!WillStateRow?.errorMessage ? WillStateRow?.State ? "Aktife Al" : "Pasife Al" : "Tekrar dene"}`}
          title={WillStateRow?.State ? "Aktife Al" : "Pasife Al"}
          rejectButtonOnClick={() => {
            dispatch(pageStateSucceed(null))
            setWillStateRow(null)
          }}
          approveButtonOnClick={() => dispatch(pageStateRequested({Id: WillStateRow.Id, State: WillStateRow?.State}))}
          approveButtonClass={"danger"}
          loading={statePageLoading}
        >
          <p className="text-sm text-gray-500">
            <code className="text-red-500"><b>{WillStateRow?.Name}</b></code>
            &nbsp;isimli ve
            &nbsp;<code className="text-red-500"><b>{WillStateRow?.Path}</b></code>
            &nbsp;url adresli {WillStateRow?.errorMessage ? WillStateRow?.errorMessage.toLowerCase() || `kayıt ${WillStateRow?.State ? "aktife" : "pasife"} alınırken bir hata oluştu` : `kaydı ${WillStateRow?.State ? "aktife" : "pasife"} almak istediğinize emin misiniz?`}</p>
        </Alert>
      )}

      <header className={`custom-header border-b border-gray-200 ${Loading ? "cursor-progress" : ""}`}>
        <div className="grid sm:grid-cols-3 py-[15.5px] px-10">
          <div className="col">
            <div className="flex w-full items-center gap-4">
              <div className="logo shrink-0">
                <Link
                  className="inline-block"
                  to={"/page-manager"}
                  onClick={() => {
                    setVisualValues(null)
                    dispatch(getStrategySucceed(null))
                    dispatch(pageSaveSucceed(null))
                    dispatch(pageUpdateSucceed(null))
                  }}
                >
                  <img
                    src={logo}
                    alt="logo"
                  />
                </Link>
              </div>
              <div className="page-selector-box">
                <button
                  className="flex items-center py-2 whitespace-nowrap max-w-40"
                  id="page-selector-button"
                  title={queryName}
                >
                  <p className="text-sm text-gray-500 font-medium truncate">{queryName}</p>
                  {/*<Icon
                    name="chevron-down"
                    size="20"
                    color="#667085"
                    className={`transition duration-300 ml-2 ease-linear${PageSelectorDdownOpen ? " rotate-180" : ""}`}
                  />*/}
                </button>
                {/*<Whisper
                  className={"relative"}
                  placement="bottomStart"
                  trigger="click"
                  speaker={renderPageSelectorDDownMenu}
                  onOpen={() => pageSelectorDropDownToggle(true)}
                  onClose={() => pageSelectorDropDownToggle(false)}
                >
                </Whisper>*/}
              </div>
              <div className="language-selector-box">
                <Whisper
                  className="relative"
                  placement="bottomStart"
                  trigger="click"
                  speaker={renderLanguageDDownMenu}
                  onOpen={() => LanguageDropDownToggle(true)}
                  onClose={() => LanguageDropDownToggle(false)}
                >
                  <button
                    className="flex items-center py-2 whitespace-nowrap"
                    id="page-selector-button"
                  >
                    <p className="text-sm text-gray-500 font-medium mr-2">{ActiveLanguage?.name}</p>
                    <Icon
                      name="chevron-down"
                      size="20"
                      color="#667085"
                      className={`transition duration-300 ease-linear${LanguageDdownOpen ? " rotate-180" : ""}`}
                    />
                  </button>
                </Whisper>
              </div>
              <div className={`page-status-box ${isCustom === "false" ? "!cursor-not-allowed is-custom-tooltip [&>*]:pointer-events-none" : ""}`}>
                <Whisper
                  className={"relative"}
                  placement="bottomStart"
                  trigger="click"
                  speaker={renderPageStatusDDownMenu}
                  onOpen={() => PageStatusDropDownToggle(true)}
                  onClose={() => PageStatusDropDownToggle(false)}
                >
                  <button
                    disabled={statePageLoading}
                    className={`flex items-center rounded-2xl py-2 px-2.5 whitespace-nowrap transition duration-1000 min-w-24 ${
                      ActivePageStatus?.statusCode ? "bg-success-50" : "bg-gray-100"
                    }`}
                    id="page-selector-button"
                  >
                    <Icon
                      name="dot"
                      size="8"
                      color={ActivePageStatus?.statusCode ? "#12B76A" : "#667085"}
                      className="mr-1.5 transition !duration-1000"
                    />
                    {statePageLoading && (
                      <LoadBar className="w-4 ml-auto" pathFill={"#2E90FA"} />
                    )}
                    {!statePageLoading && (
                      <>
                        <p
                          className={`text-sm font-medium mr-2 transition duration-1000 ${
                            ActivePageStatus?.statusCode ? "text-success-500" : "text-gray-500"
                          }`}
                        >
                          {ActivePageStatus?.name}
                        </p>
                        <Icon
                          name="chevron-down"
                          size="14"
                          color="#667085"
                          className={`transition duration-300 ease-linear${PageStatusDdownOpen ? " rotate-180" : ""}`}
                        />
                      </>
                    )}
                  </button>
                </Whisper>
              </div>
            </div>
          </div>
          <div className="col text-center">
            <div className="view-chooser inline-flex items-stretch text-sm text-gray-400 font-medium py-2 border border-gray-200 rounded-lg">
              {[
                { text: "Mobil", icon: "smartphone" },
                { text: "Masaüstü", icon: "monitor" },
              ].map((device, k) => {
                return (
                  <div
                    key={k}
                    onClick={() => setLayoutPreview({ id: k, name: device.text })}
                    className={`flex items-center cursor-pointer px-7 border-l border-gray-200 first:border-l-0 transition ${
                      layoutPreview.id === k ? " text-blue-500" : ""
                    }`}
                  >
                    <Icon
                      name={device.icon}
                      size="16"
                      color={layoutPreview.id === k ? "#2E90FA" : "#98A2B3"}
                    />{" "}
                    {/*2E90FA*/}
                    <p
                      className="ml-2"
                      hidden
                    >
                      {device.text}
                    </p>
                  </div>
                )
              })}
            </div>
          </div>
          <div className="col text-right">
            <div className="right-bar flex justify-end gap-4">
              {/*<button
                className="btn outline border-none sm"
                //disabled
              >
                Ön İzle
              </button>*/}
              <button
                className="btn sm save-btn"
                disabled={Loading} // todo: eger degisiklik yoksa da eklenecek
                onClick={saveChanges}
              >
                Kaydet
              </button>

              {Params?.pageId !== "new" && (
                <Whisper
                  className={"relative"}
                  placement="bottomEnd"
                  trigger="click"
                  speaker={renderPageSettingsDDownMenu}
                  onOpen={() => pageSettingsDotsToggle(true)}
                  onClose={() => pageSettingsDotsToggle(false)}
                >
                  <button
                    className={`btn sm text p-1.5 ${PageSettingsDotsOpen ? " !bg-blue-50" : ""}`}
                    //disabled
                  >
                    <Icon
                      name="more-vertical"
                      size="24"
                      color={PageSettingsDotsOpen ? "#2E90FA" : "#667085"}
                      className={`transition duration-150 ease-linear`}
                    />
                  </button>
                </Whisper>
              )}

            </div>
          </div>
        </div>
      </header>


      {/*mobile preview modal*/}
      <Modal
        open={MobilePreviewModal}
        width={512}
        closeButton={false}
        wrapperClass={"p-1"}
      >
        <div className="mobile-qr-box bg-white">
          <p className="text-lg font-medium mb-2">Yayında olan sayfanızı mobilde görün</p>
          <p className="text-sm text-gray-500">Yayınlanan sayfanızın mobil cihazlarda nasıl göründüğünü ve çalıştığını görmek için kodu cihazınızla tarayın.</p>
          <div className="flex flex-col justify-center items-center">
            <div className="w-36 py-9">
              <QRCode
                size={256}
                className="w-full h-auto"
                value={`https://${DomainName}${queryPath && queryPath?.startsWith("/") ? queryPath : `/${queryPath}`}`}
              />
            </div>
          </div>
          <button onClick={() => setMobilePreviewModal(!MobilePreviewModal)} className="btn w-full py-2">Tamam</button>
        </div>
      </Modal>


      <Modal
        open={Loading}
        width={600}
        closeButton={false}
      >
        <div className="login-box bg-white">
          <div className="flex flex-col justify-center items-center">
            <div className="w-36">
              <CircularProgressbar
                value={100/6 * SaveProgressPercentage}
                text={`${(100/6 * SaveProgressPercentage).toFixed(0)}%`}
                styles={buildStyles({ trailColor: "#F2F4F7", textColor: "#000", pathColor: "#2E90FA" })}
              />
            </div>
            <p className="text-lg font-medium my-6">{SaveProgressText}</p>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default PageManagerHeader
