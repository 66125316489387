import * as type from "redux/types"

export const getDefinePropertyTypeRequested = () => ({
  type: type.GET_DEFINE_PROPERTY_TYPE_REQUESTED,
})

export const getDefinePropertyTypeSucceed = (data) => ({
  type: type.GET_DEFINE_PROPERTY_TYPE_SUCCEEDED,
  payload: data,
})

export const getDefinePropertyTypeFailed = () => ({
  type: type.GET_DEFINE_PROPERTY_TYPE_FAILED,
})
