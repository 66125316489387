import * as type from "redux/types"

export const mediaLibraryListRequested = (data) => ({ type: type.MEDIA_LIBRARY_LIST_REQUESTED, payload: data })
export const mediaLibraryListSucceed = (data) => ({ type: type.MEDIA_LIBRARY_LIST_SUCCEEDED, payload: data })
export const mediaLibraryListFailed = () => ({ type: type.MEDIA_LIBRARY_LIST_FAILED })

export const mediaLibrarySaveRequested = (data) => ({ type: type.MEDIA_LIBRARY_SAVE_REQUESTED, payload: data })
export const mediaLibrarySaveSucceed = (data) => ({ type: type.MEDIA_LIBRARY_SAVE_SUCCEEDED, payload: data })
export const mediaLibrarySaveFailed = () => ({ type: type.MEDIA_LIBRARY_SAVE_FAILED })

export const mediaLibraryDeleteRequested = (data) => ({ type: type.MEDIA_LIBRARY_DELETE_REQUESTED, payload: data })
export const mediaLibraryDeleteSucceed = (data) => ({ type: type.MEDIA_LIBRARY_DELETE_SUCCEEDED, payload: data })
export const mediaLibraryDeleteFailed = () => ({ type: type.MEDIA_LIBRARY_DELETE_FAILED })
