import React, { useState } from "react"

//components
import { RangeInput, ToggleBox } from "components"

const HeaderSettings = () => {
  const [LogoSize, setLogoSize] = useState(24)

  return (
    <>
      <div className="flex flex-col gap-6">
        <RangeInput
          rangeLabel={"Logo Size(px)"}
          rangeValue={LogoSize}
          min={12}
          max={72}
          onChange={(value) => {
            console.log(value)
            setLogoSize(value)
          }}
        />

        <ToggleBox
          title={"Sticky Menu (While Scrolling)"}
          onToggle={(e) => console.log(e)}
        />
      </div>
    </>
  )
}

export default HeaderSettings
