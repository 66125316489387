import * as type from "redux/types"

export const addonCategoryRequested = (data) => ({
  type: type.ADDON_CATEGORY_REQUESTED,
  payload: data,
})

export const addonCategorySucceed = (data) => ({
  type: type.ADDON_CATEGORY_SUCCEEDED,
  payload: data,
})

export const addonCategoryFailed = () => ({
  type: type.ADDON_CATEGORY_FAILED,
})
