import React, { useState } from "react"
import DatePicker from "react-datepicker"

import tr from "date-fns/locale/tr"
import "assets/scss/datepicker.scss"

import Icon from "components/Icon"

const DatePickerWrap = () => {
  const [startDate, setStartDate] = useState(new Date())
  // const [endDate, setEndDate] = useState(new Date())
  const [visible, setVisible] = useState(false)

  const openDatePicker = (e) => {
    e.preventDefault()
    setVisible(!visible)
  }

  return (
    <div>
      <div onClick={openDatePicker}>
        <span
          className={`flex items-center gap-4 px-5 py-3 border rounded-lg cursor-pointer ${
            visible ? "border-[#B2DDFF]" : "border-gray-200"
          }`}
        >
          <span className="font-medium text-gray-500">06.01.2022 - 13.01.2022</span>
          <Icon
            name="calendar"
            size="16"
            color="#667085"
          />
        </span>
      </div>
      {visible && (
        <DatePicker
          selected={startDate}
          locale={tr}
          onChange={(date) => setStartDate(date)}
          useWeekdaysShort={true}
          dateFormat="dd/MM/yyyy"
          shouldCloseOnSelect={false}
          inline
        >
          <div className="react-datepicker__total-days">
            <span className="flex justify-between w-full gap-3">
              <button
                className="btn sm outline focus:outline-none w-1/2"
                onClick={() => setVisible(false)}
              >
                Kapat
              </button>
              <button
                className="btn sm focus:outline-none w-1/2"
                onClick={() => setVisible(false)}
              >
                Seç
              </button>
            </span>
          </div>
        </DatePicker>
      )}
    </div>
  )
}

export default DatePickerWrap
