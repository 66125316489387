import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux";

// layouts
import PartialWrapper from "../../../../PartialWrapper"

// components
import {
  BorderBox,
  ColorBox,
  FontSizeRangeBox,
  FontWeightBox,
  LoadBar,
  Subtitle,
  TextareaInput,
  ToggleBox
} from "components"


//helpers
import {setSettings} from "helpers/setSettings";

// saga-requests
import {newText, TEXT} from "pages/PageManager/Enums";

const TitleLayout = (props) => {
  let { showSubLayout, setShowSubLayout, editBlock, ThisComponent, setThisComponent } = props

  //states
  const [ThisSlider, setThisSlider] = useState(null)
  const [ThisText, setThisText] = useState(null)

  const [DefaultTextData] = useState({
    text: "",
  })
  const [DefaultTextVisual] = useState({
    fontSize: "48px",
    fontWeight: "normal",
    color: "#ffffff",
    display: "block",
  })
  const [ItemInfo, setItemInfo] = useState({
    data: DefaultTextData,
    visual: DefaultTextVisual
  })

  const goBackHandleClick = () => {
    setShowSubLayout(null)
    setItemInfo({
      data: DefaultTextData,
      visual: DefaultTextVisual
    })
  }

  const setItemInfoFromStrategy = () => {
    if (ThisText?.data || ThisText.visual){
      setItemInfo({
        data: ThisText.data || DefaultTextData,
        visual: ThisText.visual?.style || DefaultTextVisual
      })
    }
  }

  const getText = (ThisSlider) => {
    return ThisSlider?.children?.find(child => child?.name === TEXT)
  }

  const checkHasChildren = () => {
    return !!ThisSlider?.children
  }

  useEffect(() => {
    if (showSubLayout?.titleLayout){
      setItemInfoFromStrategy()
    }
  }, [showSubLayout?.titleLayout])

  useEffect(() => {
    if (editBlock?.slider){
      const thisSlider = ThisComponent?.children?.find(child => child?.code === editBlock?.slider?.code)
      setThisSlider({
        ...thisSlider,
      })
      setThisText(thisSlider?.children?.find(child => child.name === TEXT) || newText(editBlock?.itemInfo?.textId))
    }
  }, [editBlock, showSubLayout?.titleLayout])

  useEffect(() => {
    if ((JSON.stringify(ItemInfo?.data) !== JSON.stringify(DefaultTextData)) && showSubLayout?.titleLayout){
      const length = (ThisSlider?.children && ThisSlider?.children?.length) || 0
      let textComponent = getText(ThisSlider)
      if (!textComponent){
        textComponent = ThisText
        const hasChildren = checkHasChildren()
        if (!hasChildren) ThisSlider.children = []
        ThisSlider.children[length] = ThisText
        setThisSlider({
          ...ThisSlider
        })
      }
      textComponent.data = ItemInfo?.data
      if (!textComponent.visual) textComponent.visual = {}
      textComponent.visual.style = ItemInfo?.visual
      let updateSliderIndex = ThisComponent.children.findIndex(child => child?.code === ThisSlider?.code)
      ThisComponent.children[updateSliderIndex] = ThisSlider
      setThisComponent({
        ...ThisComponent
      })
    }
  }, [ItemInfo, showSubLayout?.titleLayout])

  if (!showSubLayout?.titleLayout){
    return false
  }

  return (
    <PartialWrapper
      className={`title-layout${showSubLayout?.titleLayout ? " active" : ""}`}
      goBackOnClick={goBackHandleClick}
    >

      <Subtitle>Başlık</Subtitle>

      {!ItemInfo && <div className="py-6"><LoadBar pathFill={"#2E90FA"} /></div>}

      {ItemInfo && (
        <div className="flex flex-col gap-4 mt-4">
          <div className="input-wrapper">
            <ToggleBox
              title={"Başlık Görünürlüğü"}
              checked={ItemInfo?.visual?.display !== "none"}
              onToggle={(e) => {
                setSettings({ target: {
                    name: "display",
                    value: e ? "block" : "none"
                  }}, false, ItemInfo, "visual").then((res) => setItemInfo(res))
              }}
            />
          </div>
          <div className="input-wrapper">
            <TextareaInput
              title="Metin"
              textareaClassName={"!border-gray-200 mt-2"}
              defaultValue={ItemInfo?.data?.text}
              placeholder="Bir metin giriniz..."
              resize={"none"}
              rows={3}
              name="text"
              onChange={(e) =>
                setSettings(e, true, ItemInfo, "data").then((res) => setItemInfo(res))
              }
            />
          </div>
          <div className="input-wrapper">
            <ColorBox
              name="color"
              title={"Renk"}
              defaultValue={ItemInfo?.visual?.color}
              onChange={(e) =>
                setSettings(e, false, ItemInfo, "visual").then((res) => setItemInfo(res))
              }
            />
          </div>
          <div className="input-wrapper">
            <BorderBox>
              <FontSizeRangeBox
                className={""}
                min={10}
                max={72}
                onChange={(size) =>
                  setSettings({ target: {
                      name: "fontSize",
                      value: `${size}px`
                    }}, false, ItemInfo, "visual").then((res) => setItemInfo(res))
                }
                rangeLabel={"Yazı Boyutu (px)"}
                rangeValue={ItemInfo?.visual?.fontSize ? ItemInfo?.visual?.fontSize.replace("px", "") : 48}
              />
            </BorderBox>
          </div>

          <div className="input-wrapper">
            <BorderBox>
              <FontWeightBox
                title="Yazı Kalınlığı"
                activeFontWeight={ItemInfo?.visual?.fontWeight || "normal"}
                onChange={(weight) =>
                  setSettings({ target: {
                      name: "fontWeight",
                      value: weight
                    }}, false, ItemInfo, "visual").then((res) => setItemInfo(res))
                }
              />
            </BorderBox>
          </div>
        </div>
      )}
    </PartialWrapper>
  )
}

export default TitleLayout
