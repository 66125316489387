import PropTypes from "prop-types"

const BorderBox = (props) => {
  const { className, children, ...args } = props

  return <div
    className={`flex flex-col bg-white border border-gray-200 rounded-lg ${className}`}
    {...args}
  >
    {children}
  </div>
}

export default BorderBox

BorderBox.prototypes = {
  className: PropTypes.string,
}

BorderBox.defaultProps = {
  className: "p-3",
}
