import { call, fork, put, takeLatest } from "redux-saga/effects"
import * as actions from "redux/actions/definePropertyType"

import { GET_PROPERTIES_REQUESTED } from "redux/types"
import { API_GET } from "helpers/api"

function* watchGetDefinePropertyList() {
  yield takeLatest(GET_PROPERTIES_REQUESTED, getDefinePropertyList)
}

function* getDefinePropertyList(action) {
  try {
    const res = yield call(apiGetDefinePropertyList, action.payload)
    yield put(actions.getDefinePropertyTypeSucceed(res?.data))
  } catch (err) {
    yield put(actions.getDefinePropertyTypeFailed())
  }
}

/**
 * @description This function gets the property type list from the 'Tanımlar'.
 *
 * @returns
 */
const apiGetDefinePropertyList = () => {
  /**
   * Last path is 'tanImlar' or 'tanımlar' because of the API accept it like this.
   * Browser doesn't send as 'tanımlar' it sends as 'tanImlar'
   */
  return API_GET(`${process.env.REACT_APP_PANEL_DOMAIN}/api/setting/getpropertybysetting/tanImlar`)
}

export default function* definePropertyTypeList() {
  yield fork(watchGetDefinePropertyList)
}
