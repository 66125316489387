/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Alert} from "components";
import getPageList from "../../helpers/GetPageList";
import {pageStateRequested, pageStateSucceed} from "redux/actions/pageState";

const StateRowAlert = (props) => {
	const {state, setState, TableSearchQuery, ActivePageTypeDropdown, ActivePageStatusDropdown, onSuccess, onError, noListRequest} = props
	const dispatch = useDispatch()

	const statePage = useSelector((state) => state.pageState.response)
	const statePageLoading = useSelector((state) => state.pageState.loading)
	const statePageError = useSelector((state) => state.pageState.error)

	const getChangePageState = () => {
		const {Id, State} = state
		dispatch(pageStateRequested({Id,State: State === 1 ? 0 : 1}))
	}

	useEffect(() => {
		if (statePage || statePageError){
			const staticErrorMessage = "güncelleme işlemi sırasında bir sorun oluştu, lütfen tekrar deneyiniz."
			if (statePage?.Success) {
				dispatch(pageStateSucceed(null))
				setState(null)
				if (!noListRequest) getPageList(dispatch, TableSearchQuery, ActivePageTypeDropdown, ActivePageStatusDropdown)
				if(onSuccess){
					onSuccess(true)
				}
			} else if(!statePage?.Success){
				setState({
					...state,
					errorMessage: (statePage?.Errors && statePage?.Errors[0]?.Message) || staticErrorMessage
				})
				if(onError){
					onError(true)
				}
			} else if (statePageError){
				setState({
					...state,
					errorMessage: staticErrorMessage
				})
				if(onError){
					onError(true)
				}
			}
		}
	}, [statePage, statePageError])

	return (
		<>
			{state && (
				<Alert
					iconName={""}
					approveButtonText={`${!state?.errorMessage ? state?.State ? "Pasife Al" : "Aktife Al" : "Tekrar dene"}`}
					title={state?.State ? "Pasife Al" : "Aktife Al"}
					rejectButtonOnClick={() => {
						dispatch(pageStateSucceed(null))
						setState(null)
					}}
					approveButtonOnClick={getChangePageState}
					approveButtonClass={state?.State ? "danger": ""}
					loading={statePageLoading}
				>
					<p className="text-sm text-gray-500">
						<code className="text-red-500"><b>{state?.Name}</b></code>
						&nbsp;isimli ve
						&nbsp;<code className="text-red-500"><b>{state?.Path}</b></code>
						&nbsp;url adresli {state?.errorMessage ? state?.errorMessage.toLowerCase() || `kayıt ${state?.State ? "pasife" : "aktife"} alınırken bir hata oluştu` : `kaydı ${state?.State ? "pasife" : "aktife"} almak istediğinize emin misiniz?`}</p>
				</Alert>
			)}
		</>
	)
}


export default StateRowAlert