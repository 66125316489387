export const setSettings = (e, outOfStyle, oldValues, key, valueConcatWithString) => {
	/*caret positioning hack*/
	if (e){
		const caret = e.target?.selectionStart
		const element = e.target
		if (element.selectionStart){
			window.requestAnimationFrame(() => {
				element.selectionStart = caret
				element.selectionEnd = caret
			})
		}
	}
	return new Promise( (resolve, reject) => {
		if (!e) reject("error")

		let val, obj
		val = {[e.target.name] : e.target.value}
		if (outOfStyle){
			if (valueConcatWithString){
				val = {[e.target.name]: `${e.target.value}${valueConcatWithString}`}
			}
			if (key){
				val = {
					[key]: {
						...oldValues?.[key],
						...val
					}
				}
			}
			obj = { ...val }
		}
		else {
			if (valueConcatWithString){
				val = {[e.target.name]: `${e.target.value}${valueConcatWithString}`}
			}
			if (!key){
				obj = {
					style: {
						...oldValues?.style,
						...val
					}
				}
			} else {
				obj = {
					[key]: {
						...oldValues?.[key],
						...val
					}
				}
			}
		}
		const res = {
			...oldValues,
			...obj
		}
		if (res){
			resolve(res);
		}
	});
}