import React from "react"

const ReservationPolicySkeleton = () => {
  return (
    <div className="skeleton">
      <div className="animate-pulse w-full space-y-3">
        <div className="grid grid-cols-4 gap-3">
          <div className="h-11 bg-slate-200 rounded col-span-1" />
          <div className="col-span-3 flex flex-col gap-4">
            <div className="h-32 bg-slate-200 rounded" />
            <div className="h-32 bg-slate-200 rounded" />
            <div className="h-32 bg-slate-200 rounded" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReservationPolicySkeleton
