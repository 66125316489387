import React, { useRef, useState } from "react"
import Icon from "components/Icon"
import uploadCircle from "assets/svg/upload-circle.svg"

// tooltip
import { ColorViewSettings, TitleH3 } from "components"

const SetLogoAndThemeColor = (props) => {
  let {
    config: { ThemeConfig, DefaultThemeButtonColor },
    setConfig,
  } = props

  // refs
  const formRef = useRef()
  const UploadFileRef = useRef()

  // states
  const [LTDraggableImageBoxHoverActive, setLTDraggableImageBoxHoverActive] = useState(false)

  // Func
  const calcImageSize = (size) => {
    let resultSize = size
    if (resultSize > 100000) {
      resultSize = resultSize / 1000000
      resultSize = resultSize.toFixed(2) + " MB"
    } else if (resultSize >= 1000) {
      resultSize = resultSize / 1000
      resultSize = (resultSize + "").split(".")[0] + " KB"
    } else if (resultSize < 1000) {
      resultSize = resultSize / 1000
      resultSize = resultSize + " bytes"
    }

    return resultSize
  }

  const readImage = (file) => {
    const reader = new FileReader()
    reader.addEventListener("load", (event) => {
      setConfig({
        logoValues: {
          src: event.target.result,
          size: file.size,
          name: file.name,
          fileForBinary: file,
        },
      })
    })
    reader.readAsDataURL(file)
    setLTDraggableImageBoxHoverActive(false)
    UploadFileRef.current.value = ""
  }

  const imageDragging = (event, show) => {
    event.stopPropagation()
    event.preventDefault()
    event.dataTransfer.dropEffect = "copy"
    show ? setLTDraggableImageBoxHoverActive(true) : setLTDraggableImageBoxHoverActive(false)
  }

  const imageDropped = (event) => {
    event.stopPropagation()
    event.preventDefault()
    const fileList = event.dataTransfer.files
    readImage(fileList[0])
  }

  return (
    <>
      <div className="w-5/12 mb-10 lg:mb-0">
        {/*Logo Selector*/}

        <div className="ds-box mb-8">
          <TitleH3 className="mb-2">Marka logosu</TitleH3>

          <div className="box-content relative">
            <div className="data-box">
              <form
                ref={formRef}
                className="hidden"
              >
                <input
                  type="file"
                  ref={UploadFileRef}
                  onChange={(e) => readImage(UploadFileRef.current.files[0])}
                  accept=".png, .svg, .gif"
                />
              </form>
              <div className="flex justify-center items-center w-full mb-8">
                {ThemeConfig?.displaySettings?.logoValues ? (
                  <div className="w-full">
                    <div
                      className={`${
                        LTDraggableImageBoxHoverActive ? "bg-white " : ""
                      }w-full flex items-center justify-center min-h-[100px] border border-gray-200 border-dashed rounded-lg mb-4`}
                      onDragOver={(e) => imageDragging(e, true)}
                      onDragLeave={(e) => imageDragging(e, false)}
                      onDrop={imageDropped}
                    >
                      <img
                        src={ThemeConfig.displaySettings?.logoValues?.src}
                        alt="Logo"
                        className={`${LTDraggableImageBoxHoverActive ? "opacity-0 " : ""}max-h-60`}
                      />
                    </div>

                    <div className="flex gap-2 items-start relative text-sm font-medium border border-blue-500 rounded-lg pl-3 py-4 pr-10 shadow-md">
                      <img
                        src={uploadCircle}
                        alt="Upload icon"
                      />

                      <div className="grid flex-col">
                        <strong className="truncate">{ThemeConfig.displaySettings?.logoValues?.name}</strong>
                        <span className="text-gray-500 text-sm">
                          {calcImageSize(ThemeConfig.displaySettings?.logoValues?.size)}
                        </span>
                      </div>

                      <div className="absolute h-5 w-5 right-4 top-4">
                        <button
                          onClick={() => {
                            setConfig({
                              ...ThemeConfig.displaySettings,
                              logoValues: null,
                            })
                          }}
                        >
                          <Icon
                            name="close"
                            color="black"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className={`${
                      LTDraggableImageBoxHoverActive ? "border-blue-300 " : "border-gray-300 "
                    }flex flex-col justify-center items-center w-full bg-white rounded-lg border border-dashed cursor-pointer transition hover:bg-gray-100`}
                  >
                    <div className="upload-area whitespace-normal overflow-hidden">
                      <button
                        onClick={() => UploadFileRef.current.click()}
                        onDragOver={(e) => imageDragging(e, true)}
                        onDragLeave={(e) => imageDragging(e, false)}
                        onDrop={imageDropped}
                      >
                        <div className="flex flex-col justify-center items-center py-4 px-6">
                          <img
                            src={uploadCircle}
                            alt="Upload icon"
                          />

                          <p
                            className={`${
                              LTDraggableImageBoxHoverActive ? "text-blue-500 " : "text-gray-500 "
                            }text-sm text-center mt-3`}
                          >
                            <span className="font-normal">
                              Yüklemek için <span className="font-medium text-blue-500">tıklayın</span> ya da sürükleyip bırakın.
                            </span>
                            <span className="text-xs">
                              <br />
                              <br />
                              (maks. 2 MB) SVG, PNG, ya da GIF formatında, zemini olmayan (transparan) bir logo yüklenmelidir.
                            </span>
                          </p>
                        </div>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/*Button Color*/}

        <ColorViewSettings
          setConfig={(config) => setConfig(config)}
          config={{
            ThemeConfig,
            DefaultThemeButtonColor,
          }}
        />

        {/*Gelişmiş ayarlar*/}
        {/* <div className="mt-5">
          <p className="inline text-blue-500 font-medium cursor-not-allowed select-none">Gelişmiş ayarlar</p>
        </div> */}
      </div>
    </>
  )
}

export default SetLogoAndThemeColor
