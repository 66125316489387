/* eslint-disable import/no-anonymous-default-export */
import { ApplicationInsights } from "@microsoft/applicationinsights-web"
import { ReactPlugin, withAITracking } from "@microsoft/applicationinsights-react-js"

// history
import { createBrowserHistory } from "history"
import { isProdEnv } from "./utils/environmentsCheck"
const browserHistory = createBrowserHistory({ basename: "" })

const reactPlugin = new ReactPlugin()
let ai
if (isProdEnv) {
  ai = new ApplicationInsights({
    config: {
      instrumentationKey: process.env.REACT_APP_APPINSIGHTS_KEY,
      extensions: [reactPlugin],
      enableAutoRouteTracking: true,
      autoTrackPageVisitTime: true,
      autoExceptionInstrumented: true,
      autoUnhandledPromiseInstrumented: true,
      enableRequestHeaderTracking: true,
      enableResponseHeaderTracking: true,
      extensionConfig: {
        [reactPlugin.identifier]: { history: browserHistory },
      },
    },
  })
  ai.loadAppInsights()
}

export default (Component) => withAITracking(reactPlugin, Component)
export const appInsights = ai?.appInsights
