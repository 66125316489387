import * as type from "redux/types"

export const milesAndSmilesRequested = (data) => ({
  type: type.MILES_AND_SMILES_REQUESTED,
  payload: data,
})

export const milesAndSmilesSucceed = (data) => ({
  type: type.MILES_AND_SMILES_SUCCEEDED,
  payload: data,
})

export const milesAndSmilesFailed = () => ({
  type: type.MILES_AND_SMILES_FAILED,
})
