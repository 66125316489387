import React from "react"

const MarketPlaceFilterSkeleton = () => {
  return (
    <aside className="aside border-r border-r-gray-200 px-8 py-16">
      <div className="flex flex-col gap-4 mb-20 animate-pulse space-y-3">
        <div className="h-2 w-1/2 bg-slate-200 rounded" />
        <div className="h-2 w-1/2 bg-slate-200 rounded" />
      </div>
      <div className=" animate-pulse space-y-3 gap-4 mb-20">
        <div className="h-8 bg-slate-200 rounded" />
      </div>
      <div className="flex flex-col animate-pulse space-y-3 gap-4 mb-20">
        <div className="h-2 w-1/2 bg-slate-200 rounded" />
        <div className="h-2 bg-slate-200 rounded" />
        <div className="h-2 bg-slate-200 rounded" />
        <div className="h-2 bg-slate-200 rounded" />
        <div className="h-2 bg-slate-200 rounded" />
      </div>
      <div className="flex flex-col animate-pulse space-y-3 gap-4 mt-10">
        <div className="h-2 w-1/3 bg-slate-200 rounded" />
        <div className="h-2 w-1/2 bg-slate-200 rounded" />
        <div className="h-2 w-1/2 bg-slate-200 rounded" />
      </div>
    </aside>
  )
}

export default MarketPlaceFilterSkeleton
