import React from "react";

//layouts
import PartialWrapper from "../../PartialWrapper";

//components
import { ColorBox, Subtitle } from "components";
import { ButtonGroupBox } from "components/PageManager";


const AnchorLinks = (props) => {
	let { showSubLayout, setShowSubLayout, visualValues, setVisualValues, links } = props

	const goBackHandleClick = () => {
		setShowSubLayout(null)
	}

	const linkColorOnChange = (value) => {
		setVisualValues({
			...visualValues,
			link: {
				...visualValues?.link,
				color: value
			}
		})
	}

	const linkStyleOnChange = (name) => {
		const linkStyle = links.find(link => link?.label === name)
		setVisualValues({
			...visualValues,
			link: {
				...visualValues?.link,
				name,
				style: linkStyle?.value
			}
		})
	}

	return (
		<PartialWrapper
			className={`margin-layout${showSubLayout?.AnchorLinks ? " active" : ""}`}
			goBackOnClick={goBackHandleClick}
		>
			<Subtitle>Linkler</Subtitle>

			<ColorBox
				className={"mt-4"}
				title={"Link Rengi"}
				value={visualValues?.link?.color}
				onChange={e => linkColorOnChange(e.target.value)}
			/>

			{links && (
				<ButtonGroupBox
					className={"mt-4"}
					title="Link Gösterimi"
					buttons={links.map(link => link.label)}
					ActiveButton={visualValues?.link?.name || links[0].label}
					onChange={name => linkStyleOnChange(name)}
				/>
			)}

		</PartialWrapper>
	)

}

export default AnchorLinks