import React from "react"
import PropTypes from "prop-types"

const ColorBox = (props) => {
  let { title, value, defaultValue, onChange, name, wrapperClass, labelClass, className } = props

  return (
    <div className={`${wrapperClass} ${className}`}>
      <span className="text-sm">{title}</span>
      <label className={labelClass}>
        <input
          type="color"
          name={name}
          value={value}
          defaultValue={!value ? defaultValue : undefined}
          className="absolute h-10 w-10 opacity-0"
          onChange={onChange}
        />

        <div
          style={{ backgroundColor: value || defaultValue }}
          className="w-full h-full rounded-full"
        />
      </label>
    </div>
  )
}

export default ColorBox

ColorBox.prototypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  wrapperClass: PropTypes.string,
  labelClass: PropTypes.string,
  className: PropTypes.string,
}

ColorBox.defaultProps = {
  title: "Arka Plan Rengi",
  value: undefined,
  defaultValue: "#000000",
  onChange: undefined,
  name: "backgroundColor",
  wrapperClass: "flex items-center justify-between gap-2 border border-gray-200 rounded-lg px-3 py-2",
  labelClass: "relative w-8 h-8 border p-0.5 rounded-full opacity-50 hover:opacity-100 border-gray-500",
  className: "",
}
