import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

// componenets
import tippy from "tippy.js"
import { TitleH3 } from "components"
import Icon from "../Icon"
import { hexIsLight } from "utils/hexIsLight"

const ColorViewSettings = (props) => {
  let {
    className,
    title,
    showTootip,
    tooltipContent,
    config: { ThemeConfig, DefaultThemeButtonColor },
    setConfig,
  } = props

  // refs

  // states
  const [LTShowColorPicker, setLTShowColorPicker] = useState(false)

  // Func

  // effects
  useEffect(() => {
    let isLight = hexIsLight(ThemeConfig.displaySettings.buttonColor)
    setConfig({
      buttonColorIsLight: isLight,
    })
    // eslint-disable-next-line
  }, [ThemeConfig.displaySettings.buttonColor])

  useEffect(() => {
    tippy("[data-tippy-content]", {
      placement: "top-start",
    })
  }, [])

  return (
    <>
      <div className={`ds-box ${className}`}>
        <TitleH3 className="mb-2">
          {title}
          {showTootip && (
            <Icon
              name="info"
              className="ml-2"
              size="16"
              color="#667085"
              data-tippy-content={tooltipContent}
            />
          )}
        </TitleH3>

        <div className="box-content relative">
          <div className="flex items-center w-full">
            <button
              onClick={() => {
                setConfig({
                  buttonColor: DefaultThemeButtonColor,
                  buttonColorIsLight: false,
                })
              }}
              className={`relative w-9 h-9 flex items-center justify-center rounded-lg bg-gray-900 cursor-pointer
                ${
                  ThemeConfig.displaySettings.buttonColor !== DefaultThemeButtonColor
                    ? ""
                    : " before:block before:absolute before:-inset-1 before:border before:border-gray-400 before:rounded-xl before:opacity-50"
                }
                `}
            >
              {ThemeConfig.displaySettings.buttonColor === DefaultThemeButtonColor && (
                <Icon
                  name={"check"}
                  size="24"
                  color="white"
                />
              )}
            </button>

            <div className="color-chooser-button relative flex items-center justify-center ml-4">
              <label htmlFor="color-chooser">
                <button
                  className={`w-9 h-9 flex items-center justify-center rounded-lg bg-gray-100 cursor-pointer
                ${
                  ThemeConfig.displaySettings.buttonColor === DefaultThemeButtonColor
                    ? ""
                    : " before:block before:absolute before:-inset-1 before:border before:border-blue-400 before:rounded-xl before:opacity-50"
                }
                `}
                  style={{
                    backgroundColor:
                      ThemeConfig.displaySettings.buttonColor !== DefaultThemeButtonColor
                        ? ThemeConfig.displaySettings.buttonColor
                        : "",
                  }}
                >
                  <Icon
                    style={LTShowColorPicker ? { transform: "rotate(45deg)" } : { transform: "" }}
                    className="shrink-0"
                    name="plus"
                    size="24"
                    color={`${
                      ThemeConfig.displaySettings.buttonColor === DefaultThemeButtonColor
                        ? "#2E90FA"
                        : !ThemeConfig.displaySettings?.buttonColorIsLight
                        ? "#fff"
                        : "#000"
                    }`}
                  />
                </button>
              </label>

              <input
                id="color-chooser"
                type="color"
                className="absolute h-10 w-10 opacity-0"
                value={ThemeConfig.displaySettings?.buttonColor}
                onFocus={() => setLTShowColorPicker(true)}
                onBlur={() => setLTShowColorPicker(false)}
                onChange={(event) => setConfig({ buttonColor: event.target.value })}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ColorViewSettings

ColorViewSettings.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  showTootip: PropTypes.bool,
  tooltipContent: PropTypes.string,
}

ColorViewSettings.defaultProps = {
  className: "mb-8",
  title: "Renk seçimi",
  showTootip: true,
  tooltipContent:
    "Seçeceğiniz renk sadece sitedeki butonlara uygulanır. Linkler’in renklerini değiştirebilmek için gelişmiş ayarlar menüsüne gidin.",
}
