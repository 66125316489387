// Get Configuration - GET
export const LIST_CONFIGURATON_REQUESTED = "LIST_CONFIGURATON_REQUESTED"
export const LIST_CONFIGURATON_SUCCEEDED = "LIST_CONFIGURATON_SUCCEEDED"
export const LIST_CONFIGURATON_FAILED = "LIST_CONFIGURATON_FAILED"

// Save Configuration - POST
export const SAVE_CONFIGURATON_REQUESTED = "SAVE_CONFIGURATON_REQUESTED"
export const SAVE_CONFIGURATON_SUCCEEDED = "SAVE_CONFIGURATON_SUCCEEDED"
export const SAVE_CONFIGURATON_FAILED = "SAVE_CONFIGURATON_FAILED"

// GET Configuration - GET
export const GET_CONFIGURATON_REQUESTED = "GET_CONFIGURATON_REQUESTED"
export const GET_CONFIGURATON_SUCCEEDED = "GET_CONFIGURATON_SUCCEEDED"
export const GET_CONFIGURATON_FAILED = "GET_CONFIGURATON_FAILED"

// Set Device - preview page configuration
export const SET_DEVICE = "SET_DEVICE"

// Set Viewport Full Size - preview page configuration
export const SET_VIEWPORT_FULL_SIZE = "SET_VIEWPORT_FULL_SIZE"

// Set ThemeConfig - preview page configuration
export const SET_THEME_CONFIG = "SET_THEME_CONFIG"
// #endregion
