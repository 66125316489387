import React from "react"
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Label } from "recharts"

import "react-datepicker/dist/react-datepicker.css"

import Icon from "components/Icon"
import DatePickerWrap from "./DatePicker"
import { data } from "./data"

const ConversionInformation = () => {
  return (
    <div className="border border-gray-200 rounded-lg p-6 flex flex-col gap-6 bg-white">
      <h2 className="text-xl text-gray-700 font-semibold">Dönüşüm Bilgileri</h2>

      <div className="flex justify-between">
        <section>
          <DatePickerWrap />
        </section>

        <div className="flex gap-3 items-center">
          <div className="flex gap-2 items-center">
            <Icon
              name="dot"
              size="10"
              color="#2E90FA"
            />
            <p className="text-gray-500 text-sm">Sayfa Görüntülenme</p>
          </div>
          <div className="flex gap-2 items-center">
            <Icon
              name="dot"
              size="10"
              color="#84CAFF"
            />
            <p className="text-gray-500 text-sm">Toplam Rezervasyon</p>
          </div>
          <div className="flex gap-2 items-center">
            <Icon
              name="dot"
              size="10"
              color="#175CD3"
            />
            <p className="text-gray-500 text-sm">Dönüşüm Oranı</p>
          </div>
        </div>
      </div>

      <div className="flex">
        <div className="w-7/12 mr-5">
          <ResponsiveContainer height={350}>
            <LineChart
              data={data}
              margin={{
                top: 5,
                right: 10,
                bottom: 20,
              }}
            >
              <CartesianGrid
                stroke="#F2F4F7"
                strokeDasharray="0"
                vertical={false}
              />
              <XAxis
                dataKey="name"
                axisLine={false}
                tickLine={false}
                padding={{ left: 15, right: 10 }}
                style={{
                  fontSize: "12px",
                  color: "#667085",
                }}
              >
                <Label
                  className="text-sm text-gray-500 font-medium"
                  value="Ay"
                  position="bottom"
                />
              </XAxis>
              <YAxis
                axisLine={false}
                tickLine={false}
                padding={{ left: 30, right: 30 }}
                style={{
                  fontSize: "12px",
                  color: "#667085",
                }}
              />
              <Tooltip />
              <Line
                type="monotone"
                dataKey="pv"
                stroke="#2E90FA"
                strokeWidth={2}
                activeDot={{ r: 8 }}
                dot={false}
              />
              <Line
                type="monotone"
                dataKey="uv"
                stroke="#84CAFF"
                strokeWidth={2}
                dot={false}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>

        <div className="flex flex-col gap-3 w-5/12">
          <div className="flex justify-between items-center border border-gray-200 rounded-xl py-8 px-6">
            <p className="text-gray-600 text-base font-medium">
              Sayfa Görüntülenme <br /> Sayısı
            </p>
            <span className="text-3xl text-gray-800 font-semibold">9K</span>
          </div>
          <div className="flex justify-between items-center border border-gray-200 rounded-xl py-8 px-6">
            <p className="text-gray-600 text-base font-medium">
              Toplam <br /> Rezervasyon
            </p>
            <span className="text-3xl text-gray-800 font-semibold">2.3K</span>
          </div>
          <div className="flex justify-between items-center border border-gray-200 rounded-xl py-8 px-6">
            <p className="text-gray-600 text-base font-medium">
              Dönüşüm <br /> Oranı
            </p>
            <span className="text-3xl text-gray-800 font-semibold">%40</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConversionInformation
