import React from "react"
import PropTypes from "prop-types"
import { Icon } from "../index"

const StatusNotificationBox = (props) => {
  let { show, error, title, desc, children } = props

  return (
    <div
      className={`${
        show ? "flex" : "hidden"
      } account-management-area items-center justify-center h-full overflow-auto lg:overflow-hidden`}
    >
      <div className="flex flex-col border border-gray-200 shadow-xl rounded-xl bg-white w-[392px] p-6">
        <form className="form-area w-full">
          {!error && (
            <div className="form-header">
              <div className={`inline-flex rounded-full p-3 ${error ? "bg-error-100" : "bg-success-100"}`}>
                <Icon
                  name={error ? "info" : "check-circle"}
                  size="20"
                  color="#101828"
                />
              </div>
            </div>
          )}
          <div className="form-body mt-5">
            <div className="form-wrapper items-start flex flex-col">
              <p className={`${error ? "text-error-500" : "text-gray-900"} text-lg font-semibold`}>{title}</p>
              <p
                className="text-sm text-gray-500 mt-2"
                dangerouslySetInnerHTML={{ __html: desc }}
              />
            </div>
          </div>

          {children}
        </form>
      </div>
    </div>
  )
}

StatusNotificationBox.propTypes = {
  title: PropTypes.string.isRequired,
  show: PropTypes.bool,
  error: PropTypes.bool,
}
StatusNotificationBox.defaultProps = {
  title: "",
  show: false,
  error: false,
}

export default StatusNotificationBox
