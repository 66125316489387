import React, { useState } from "react"
import { Link } from "react-router-dom"

import MainHeader from "components/Headers/MainHeader"
import Icon from "components/Icon"

export const HomePage = (props) => {
  let { isLogged, UserInfo } = props

  const [isAdmin] = useState(UserInfo?.role?.includes("paneladmin"))

  return (
    <>
      <MainHeader isLogged={isLogged} />
      <main>
        <section className="p-10 my-0 mx-auto max-w-[1440px] overflow-auto">
          <p className="text-gray-800 font-semibold text-3xl">Eva’ya hoş geldin {UserInfo?.name}!</p>

          <div className="welcome-wrapper flex flex-col lg:flex-row lg:items-center justify-between p-10 bg-white border border-gray-200 rounded-lg mt-6">
            <div className="text-box lg:w-5/12">
              <p className="text-lg text-gray-800 font-semibold">Sayfanız yayınlanmaya hazır.</p>
              <p className="text-lg mt-1 text-gray-500 font-normal">
                Kurulumu başarıyla tamamladın! Artık rezervasyon sayfanı müşterilerinle paylaşabilirsin.
              </p>
              <Link to="/integration-settings">
                <button
                  className="btn mt-4 whitespace-nowrap"
                  disabled={isAdmin}
                >
                  <span>Yayınlama Ayarlarına Git</span>
                  <Icon
                    name="arrow-right"
                    size="20"
                    color="#fff"
                  />
                </button>
              </Link>
            </div>
            <img
              className="mt-7 lg:mt-0 lg:w-7/12 max-w-[400px]"
              src="assets/img/dashboard-welcome.png"
              alt=""
            />
          </div>
        </section>
      </main>
    </>
  )
}

export default HomePage
