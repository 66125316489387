import { call, fork, put, takeLatest } from "redux-saga/effects"
import * as actions from "redux/actions/footerUptade"

import { FOOTER_UPTADE_REQUESTED } from "redux/types"
import { API_PATCH } from "helpers/api"

function* watchFooterUptade() {
  yield takeLatest(FOOTER_UPTADE_REQUESTED, FooterUptade)
}

function* FooterUptade(action) {
  try {
    const res = yield call(apiFooterUptade, action.payload)
    yield put(actions.footerUptadeSucceed(res.data.result))
  } catch (err) {
    yield put(actions.footerUptadeFailed())
  }
}

const apiFooterUptade = async (payload) => {
  return await API_PATCH(`${process.env.REACT_APP_PANEL_DOMAIN}/api/hotel/updatedetail`, payload)
}

export default function* GetFooterUptade() {
  yield fork(watchFooterUptade)
}
