import * as type from "redux/types"

export const productListRequested = (data) => ({
  type: type.GET_PRDUCT_LIST_REQUESTED,
  payload: data,
})

export const productListSucceed = (data) => ({
  type: type.GET_PRDUCT_LIST_SUCCEEDED,
  payload: data,
})

export const productListFailed = () => ({
  type: type.GET_PRDUCT_LIST_FAILED,
})
