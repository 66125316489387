import { call, fork, put, takeLatest } from "redux-saga/effects"
import * as actions from "redux/actions/getPropertiesWithUser"

import { PROPERTIES_REQUESTED } from "redux/types"
import { API_GET } from "helpers/api"

function* watchGetProperties() {
  yield takeLatest(PROPERTIES_REQUESTED, getProperties)
}

function* getProperties(action) {
  try {
    const response = yield call(apiGetProperties, action.payload)
    const {
      data: { result },
    } = response

    const groupByCategory = result.reduce((group, property) => {
      const { settingId } = property
      group[settingId] = group[settingId] ?? []
      group[settingId].push(property)
      return group
    }, {})

    yield put(actions.propertiesSucceed(groupByCategory))
  } catch (err) {
    yield put(actions.propertiesFailed())
  }
}

const apiGetProperties = (payload) => {
  return API_GET(`${process.env.REACT_APP_PANEL_DOMAIN}/api/setting/getuser/${payload}`)
}

export default function* getPropertiesWithUser() {
  yield fork(watchGetProperties)
}
