import React from "react"

import banner1 from "pages/B2cDemoPage/LivePreview/img/banner-img-1.jpg"
import Icon from "components/Icon"

// constants
import { evaGlobalImageUrl } from "constants/index"

const DesktopView = (props) => {
  let { logo, color, borderRadius, fontFamily, domainType } = props

  return (
    <>
      <div
        className="text-[6px] border-4 rounded-2xl"
        style={{ fontFamily: fontFamily }}
      >
        {domainType === "B2C" ? (
          <div className="">
            <div className="main-header border-b border-gray-200 py-2 px-10">
              <div className="max-w-[550px] mx-auto flex items-center justify-between">
                <div className="logo-wrapper max-w-[140px]">
                  <img
                    alt="logo"
                    className="object-cover max-h-[23px]"
                    src={logo}
                  />
                </div>
                <div className="navbar">
                  <ul className="flex items-center">
                    <li className="ml-8 first:ml-0">
                      <div className="language-selector flex items-center">
                        <span>Türkçe / TL</span>
                      </div>
                    </li>
                    <li className="ml-8 first:ml-0">Yardım</li>
                    <li className="ml-8 first:ml-0">Giriş Yap</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="max-w-[550px] mx-auto  bg-white">
              <div className="lp-container">
                <nav>
                  <ul className="flex items-center text-gray-500 font-medium gap-5 py-2">
                    {[
                      { text: "Otel", icon: "building" },
                      { text: "Uçak", icon: "plane" },
                      { text: "Tur", icon: "suitcase" },
                      { text: "Transfer", icon: "car" },
                      { text: "Paketle Kazan", icon: "special_days" },
                    ].map((item, key) => {
                      return (
                        <li
                          key={key}
                          className={`flex items-center justify-center text-center first:ml-0 pb-1 ${
                            key === 0 ? "font-semibold border-b-2 cursor-pointer w-16" : "cursor-not-allowed"
                          }`}
                          style={key === 0 ? { borderBottomColor: color } : {}}
                        >
                          <Icon
                            className="mr-2"
                            name={item.icon}
                            size="12"
                            color="#667085"
                          />
                          <span>{item.text}</span>
                        </li>
                      )
                    })}
                  </ul>
                </nav>
              </div>
            </div>
            <div className="max-w-[550px] mx-auto">
              <div className="travel-planner">
                <div
                  className="search-area relative overflow-hidden z-10 bg-cover bg-[center_70%]
            after:absolute after:inset-0 after:z-10 bg-[#FAFAFA]"
                >
                  <div className="lp-container relative z-20 pt-2">
                    <div className="text-wrapper">
                      <div className="heading font-bold drop-shadow-sm text-gray-800 mb-1 text-xs">
                        Sonraki konaklamanızı bulun.
                      </div>
                      <div className="text font-medium drop-shadow-sm text-gray-500">
                        Oteller, evler ve çok daha fazlası için fırsatları kaçırmayın...
                      </div>
                    </div>
                    <div className="searchbar flex items-center bg-white shadow-_2xl overflow-hidden !transition-all mt-2">
                      {[
                        { placeholder: "Nereye gidiyorsun?", icon: "search" },
                        { placeholder: "Giriş - Çıkış tarihi seçin", icon: "calendar" },
                        { placeholder: "2 misafir, 1 oda", icon: "person" },
                      ].map((v, k) => {
                        return (
                          <div
                            key={k}
                            className={`input-wrapper flex items-center justify-center w-2/3 ${
                              k === 2 ? "w-7/12" : "w-full"
                            } relative shadow-buttonSm border border-gray-200 overflow-hidden !transition-all ml-1 first:ml-0`}
                            style={{ borderRadius: borderRadius }}
                          >
                            <Icon
                              className="absolute top-1/2 left-2 transform -translate-y-1/2 pointer-events-none"
                              name={v.icon}
                              size="8"
                              color={`${k === 2 ? "#000" : "#667085"}`}
                            />
                            <input
                              type="text"
                              className={`w-full !h-4 placeholder:text-[6px] !pl-5 pb-[6px] border-none disabled:cursor-not-allowed disabled:!bg-white disabled:!pointer-events-auto${
                                k === 2 ? "placeholder:text-gray-800" : "placeholder:text-gray-500"
                              }`}
                              placeholder={v.placeholder}
                              disabled
                            />
                          </div>
                        )
                      })}
                      <button
                        className="whitespace-nowrap px-7 !py-2 border cursor-not-allowed !transition-all ml-2 text-[6px] h-4 flex justify-center items-center w-1/3"
                        style={{ backgroundColor: color, borderRadius: borderRadius }}
                      >
                        Otel ara
                      </button>
                    </div>
                  </div>
                </div>
                <div className="features pt-1 text-[6px]">
                  <div className="lp-container flex items-center">
                    <div className="features-box w-full flex items-center justify-center text-center py-4">
                      {[
                        { text: "Fiyat farkı iade garantisi", icon: "rotate-ccw" },
                        { text: "Güvenli online alışveriş", icon: "shield" },
                        { text: "18 aya varan taksitler", icon: "credit-card" },
                      ].map((v, k) => {
                        return (
                          <div
                            key={k}
                            className="features-box w-full flex items-center justify-center text-center py-4"
                          >
                            <Icon
                              className="mr-1"
                              name={v.icon}
                              size="8"
                              color="#475467"
                            />
                            <span className="font-medium text-gray-500">{v.text}</span>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>

              <div className="banner-navigtion text-[6px] pb-6">
                <ul className="flex items-center justify-center text-gray-500 font-medium">
                  {["Yaz Fırsatları", "Erken Rezervasyon", "Etkinlikler", "Ödeme Kolaylıkları"].map((v, k) => {
                    return (
                      <li
                        key={k}
                        className=" border shadow-buttonSm cursor-pointercursor-not-allowed overflow-hidden !transition-all py-1 px-2 ml-1"
                        style={{ borderRadius: borderRadius }}
                      >
                        {v}
                      </li>
                    )
                  })}
                </ul>
                <div className="w-full relative mt-2">
                  <img
                    className="absolute object-cover w-full h-full"
                    src={banner1}
                    alt="banner"
                    style={{ borderRadius: borderRadius }}
                  />
                  <div className="text-xs w-9/12 pt-6 pb-14 px-4">
                    <p className="text-white drop-shadow-sm">Yaz fırsatları devam ediyor! Kaçırmadan yakala</p>
                    <button
                      className="absolute border-none text-[6px] mt-2 cursor-not-allowed z-10 text-white px-2"
                      style={{ backgroundColor: color, borderRadius: borderRadius }}
                    >
                      Fırsatları gör
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="pb-7">
            <div className="w-full bg-white container mx-auto py-2 flex flex-row rounded-2xl">
              <img
                className="h-3"
                alt="logo"
                src={logo}
              />
            </div>
            <div className="w-full flex justify-center items-center py-2 bg-[#FAFAFA] border-2 border-solid border-gray-200 text-[6px]">
              <div className="flex container justify-center">
                <div className="flex gap-2 py-1 w-full">
                  <div
                    className="flex justify-start items-center border border-solid border-gray-200 pl-2 pr-8 py-1 w-1/3"
                    style={{ borderRadius: borderRadius }}
                  >
                    <Icon
                      className="mr-1"
                      name="calendar"
                      size="8"
                      color="#475467"
                    />
                    <p className="">Giriş tarihi seçin</p>
                  </div>
                  <div
                    className="flex justify-start items-center border border-solid border-gray-200 pl-2 pr-8 py-1 w-1/3"
                    style={{ borderRadius: borderRadius }}
                  >
                    <Icon
                      className="mr-1"
                      name="calendar"
                      size="8"
                      color="#475467"
                    />
                    <p className="">Çıkış tarihi seçin</p>
                  </div>
                  <div
                    className="flex justify-start items-center border border-solid border-gray-200 pl-2 pr-8 py-1 w-1/3"
                    style={{ borderRadius: borderRadius }}
                  >
                    <Icon
                      className="mr-1"
                      name="user"
                      size="8"
                      color="#475467"
                    />
                    <p className="">2 kişi, 1 oda</p>
                  </div>
                  <div
                    className="ml-2 flex justify-center items-center border w-1/5"
                    style={{ backgroundColor: color, borderRadius: borderRadius }}
                  >
                    <p className="text-white px-2">Oda Ara</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white container mx-auto pt-2 w-full flex flex-row justify-between mb-2">
              <div className="flex flex-col">
                <p className="text-xs font-bold">Oda Seçenekleri</p>
                <p className="text-[8px] text-[#474F61]">3 oda tipi mevcut</p>
              </div>
            </div>
            <div className="flex px-4">
              <div className="w-3/4 mr-2">
                <div
                  className="mb-4 bg-[#FAFAFA] py-2 px-3 border border-solid border-gray-200"
                  style={{ borderRadius: borderRadius }}
                >
                  <div className="flex flex-row">
                    <img
                      alt="hotel"
                      className="w-1/4 object-cover mr-4"
                      src={`${evaGlobalImageUrl}cms/media/hotel/room/d1bf02dd-7dc1-4bc5-95b7-69817b2d944c.jpg`}
                      style={{ borderRadius: borderRadius }}
                    />
                    <div className="flex flex-col w-full">
                      <p className="font-semibold mb-1">Deluxe Oda - Bahçe Manzaralı</p>
                      <div className="flex flex-row items-center gap-1 mb-2">
                        <Icon
                          name="size"
                          color="#01133D"
                          size="8"
                        />
                        <p className="font-semibold">10 m2</p>
                        <Icon
                          name="image"
                          color="#01133D"
                          size="8"
                        />
                        <p className="font-semibold">Bahçe manzarası</p>
                        <Icon
                          name="no-smoking"
                          color="#01133D"
                          size="8"
                        />
                        <p className="font-semibold">Sigara içilmez</p>
                        <Icon
                          name="wifi"
                          color="#01133D"
                          size="8"
                        />
                        <p className="font-semibold">Wi-Fi</p>
                      </div>
                      <div className="flex flex-row items-center">
                        <p
                          className=" font-semibold"
                          style={{ color: color }}
                        >
                          Tüm özellikleri gör
                        </p>
                        <Icon
                          name="chevron-right"
                          color={color}
                          size="8"
                        />
                      </div>
                      <div className="flex justify-end">
                        <div
                          className="p-1 w-1/3 flex justify-center items-center border mt-4 font-semibold"
                          style={{ borderColor: color, borderRadius: borderRadius, color: color }}
                        >
                          Rezervasyon Yap
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="mb-4 bg-[#FAFAFA] py-2 px-3 border border-solid border-gray-200"
                  style={{ borderRadius: borderRadius }}
                >
                  <div className="flex flex-row">
                    <img
                      alt="hotel"
                      className="w-1/4 object-cover mr-4"
                      src={`${evaGlobalImageUrl}cms/media/hotel/room/d1bf02dd-7dc1-4bc5-95b7-69817b2d944c.jpg`}
                      style={{ borderRadius: borderRadius }}
                    />
                    <div className="flex flex-col w-full">
                      <p className="font-semibold mb-1">Deluxe Oda - Bahçe Manzaralı</p>
                      <div className="flex flex-row items-center gap-1 mb-2">
                        <Icon
                          name="size"
                          color="#01133D"
                          size="8"
                        />
                        <p className="font-semibold">10 m2</p>
                        <Icon
                          name="image"
                          color="#01133D"
                          size="8"
                        />
                        <p className="font-semibold">Bahçe manzarası</p>
                        <Icon
                          name="no-smoking"
                          color="#01133D"
                          size="8"
                        />
                        <p className="font-semibold">Sigara içilmez</p>
                        <Icon
                          name="wifi"
                          color="#01133D"
                          size="8"
                        />
                        <p className="font-semibold">Wi-Fi</p>
                      </div>
                      <div className="flex flex-row items-center">
                        <p
                          className=" font-semibold"
                          style={{ color: color }}
                        >
                          Tüm özellikleri gör
                        </p>
                        <Icon
                          name="chevron-right"
                          color={color}
                          size="8"
                        />
                      </div>
                      <div className="flex justify-end">
                        <div
                          className="p-1 w-1/3 flex justify-center items-center border mt-4 font-semibold"
                          style={{ borderColor: color, borderRadius: borderRadius, color: color }}
                        >
                          Rezervasyon Yap
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-1/4">
                <div
                  className="mb-4 bg-[#FAFAFA] p-2 border border-solid border-gray-200"
                  style={{ borderRadius: borderRadius }}
                >
                  <div className="flex flex-row justify-between">
                    <p className="text-gray-800 font-medium">Rezervasyon Özeti</p>
                  </div>
                  <div
                    className="p-1 flex justify-center items-center borde mt-2"
                    style={{ backgroundColor: color, borderRadius: borderRadius }}
                  >
                    <p className="text-white font-medium">Rezervasyon Yap</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default DesktopView
