import React, { useRef, useEffect } from "react"
import PropTypes from "prop-types"

function useOutsideAlerter(ref, props) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (!event.target.id.includes("dx-dropdown-menu")) props.isOutsideClick()
    }
    document.addEventListener("mouseup", handleClickOutside)
    return () => {
      document.removeEventListener("mouseup", handleClickOutside)
    }
  }, [ref, props])
}

function OutsideAlerter(props) {
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef, props)
  const CustomTag = `${props.as}`

  return (
    <CustomTag
      onClick={() => props.handleClick()}
      id={`dx-dropdown-menu-${props.index}`}
      className={props.className}
      ref={wrapperRef}
    >
      {props.children}
    </CustomTag>
  )
}

OutsideAlerter.propTypes = {
  index: PropTypes.number.isRequired,
  isOutsideClick: PropTypes.func,
}
OutsideAlerter.defaultProps = {
  className: "",
}

export default OutsideAlerter
