export const createStrategyMultipleSavePattern = (LanguageCode, RequestId, RequestType, atomPageModel, children, removedItems = [], pageDesign, seoSettings) => {
	return new Promise(async (resolve, reject) => {
		const getRelationComponent = atomPageModel.find(strategy => strategy.relation === "Component")
		const getRelationVisual = atomPageModel.find(strategy => strategy.relation === "Visual")
		const getRelationData = atomPageModel.find(strategy => strategy.relation === "Data")
		const getRelationComponentIndex = atomPageModel.findIndex(strategy => strategy.relation === "Component")
		const getRelationVisualIndex = atomPageModel.findIndex(strategy => strategy.relation === "Visual")
		if (getRelationComponent){
			atomPageModel[getRelationComponentIndex] = {
				...atomPageModel[getRelationComponentIndex],
				Childs: children
			}

			if (getRelationData){
				getRelationData.input.index = seoSettings?.index
				getRelationData.input.canonicalUrl = seoSettings?.canonicalUrl
				getRelationData?.translateItems && getRelationData.translateItems.map(item => {
					if (item?.langKey === "PageSeoTitleLangKey"){
						item.value = seoSettings?.title || item.value
						item.name = seoSettings?.title || item.name
					} else if (item?.langKey === "PageSeoDescriptionLangKey"){
						item.value = seoSettings?.description || item.value
						item.name = seoSettings?.description || item.name
					} else if (item?.langKey === "PageSeoKeywordLangKey"){
						item.value = JSON.stringify(seoSettings?.keywords) || item.value
						item.name = JSON.stringify(seoSettings?.keywords) || item.name
					}
					return true
				})
			}

			if (getRelationVisual){
				atomPageModel[getRelationVisualIndex] = {
					...atomPageModel[getRelationVisualIndex],
					input: {
						...atomPageModel[getRelationVisualIndex]?.input,
						enableHeaderFooter: pageDesign?.enableHeaderFooter,
						link: {
							name: pageDesign?.link?.name,
							style: pageDesign?.link?.style,
							color: pageDesign?.link?.color,
						},
						style: {
							...atomPageModel[getRelationVisualIndex]?.input?.style,
							borderRadius: pageDesign?.borderRadius,
							fontFamily: pageDesign?.fontFamily,
							fontSize: pageDesign?.fontSize.toString(),
							backgroundColor: pageDesign?.backgroundColor,
							componentMargin: pageDesign?.componentMargin.toString(),
						},
					}
				}
			}

			resolve({
				LanguageCode,
				RequestId, // path id
				RequestType, // hep 1
				"Strategies": [
					{
						"ChildStrategies": atomPageModel
					}
				],
				"RemoveStrategyCodes":removedItems
			})
		}
	})
}