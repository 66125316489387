import React from "react"
import PropTypes from "prop-types"

//components
import { Icon } from "components"

const SideMenuListItem = (props) => {
  let { className, title, iconName, disabled, onClick } = props
  return (
    <div
      onClick={(e) => (disabled ? undefined : onClick(e))}
      disabled={disabled}
      className={`flex items-center justify-between ${className} px-3 py-4 transition hover:opacity-50${
        disabled ? " disabled cursor-not-allowed" : " cursor-pointer"
      }`}
    >
      <span className="text-sm">{title}</span>
      <Icon
        name={iconName}
        size="14"
        color="#1D2939"
      />
    </div>
  )
}

export default SideMenuListItem

SideMenuListItem.prototypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  iconName: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
}

SideMenuListItem.defaultProps = {
  className: "border border-gray-200 rounded-lg",
  title: "Başlık",
  iconName: "chevron-right",
  disabled: false,
  //onClick: (e) => console.log(e),
}
