import { useQuery } from "@tanstack/react-query"
import { API_GET } from "helpers/api"

export const useDefinePropertyType = () => {
  const {data, ...query} = useQuery({
    queryKey: ["definePropertyType"],
    queryFn: () => API_GET(`${process.env.REACT_APP_PANEL_DOMAIN}/api/setting/getpropertybysetting/tanImlar`),
  })

  return {
    query,
    data: !!data ? data?.data.Result : [],
  }
}
