// cookies
import Cookies from "universal-cookie"
const cookies = new Cookies()

export const logout = () => {
  cookies.remove("associatedDomain")
  cookies.remove("userInfo")
  cookies.remove("user_token")
  localStorage.removeItem("user_token")

  setTimeout(() => {
    window.location.href = `${process.env.REACT_APP_AUTH_DOMAIN}/connect/logout/?post_logout_redirect_uri=${encodeURIComponent(
      window.location.origin,
    )}`
  }, 100)
}
