import React, {useEffect, useRef, useState} from "react"
import {useDispatch, useSelector} from "react-redux";

//layouts
import PartialWrapper from "pages/PageManager/layouts/PartialWrapper"

//components
import { Subtitle } from "components"
import {UpdateStrategy} from "../../pages/PageManager/helpers/UpdateStrategy";
import {getStrategySucceed} from "../../redux/actions/strategy";

const EditBlockName = (props) => {
  let { activeLayout, editBlock, setEditBlock, ThisComponent, setThisComponent } = props


  // selectors
  let components = useSelector(state => state.strategy.getStrategy.list?.result)

  // refs
  const blockNameRef = useRef(null)

  // variables
  let blockName = activeLayout?.info?.name
  const customName = activeLayout?.info?.visual?.settings?.name

  const dispatch = useDispatch()

  const [BlockNameChanged, setBlockNameChanged] = useState(false)


  // funcs
  const goback = () => {
    const customBlockName = blockNameRef?.current?.value
    if (BlockNameChanged){
      const thisComponent = {
        ...ThisComponent,
        visual: {
          ...ThisComponent?.visual,
          settings: {
            ...ThisComponent?.visual?.settings,
            name: customBlockName
          },
        }
      }
      setThisComponent(thisComponent)
      const strategy = UpdateStrategy(components, thisComponent)
      dispatch(getStrategySucceed(strategy))

    }
    setEditBlock(null)
  }


  return (
    <PartialWrapper
      goBackOnClick={goback}
      className={`edit-block-name${editBlock?.rename_block_name ? " active" : ""}`}
    >
      <Subtitle className="mb-4">Bloğu Yeniden Adlandır</Subtitle>
      <div className="input-wrapper">
        <label>
          <span className="text-gray-800 font-medium">Blok adı</span>
          <input
            className="w-full mt-1.5"
            type="text"
            placeholder="Blok adı belirleyin"
            ref={blockNameRef}
            defaultValue={customName || blockName}
            onChange={(e) => setBlockNameChanged(true)}
          />
        </label>
      </div>
    </PartialWrapper>
  )
}

export default EditBlockName
