import * as type from "redux/types"

const initialState = {
  allTenants: {
    list: null,
    loading: false,
    error: false,
  },
  activeTenant: {
    list: null,
    loading: false,
    error: false,
  },
  setTenant: {
    list: null,
    loading: false,
    error: false,
  },
  updateTenant: {
    list: null,
    loading: false,
    error: false,
  },
  deleteTenant: {
    list: null,
    loading: false,
    error: false,
  },
  activeDomain: {
    list: null,
    loading: false,
    error: false,
  },
  setDomain: {
    list: null,
    loading: false,
    error: false,
  },
  deleteDomain: {
    list: null,
    loading: false,
    error: false,
  },
  userList: {
    list: null,
    loading: false,
    error: false,
  },
  setUserList: {
    list: null,
    loading: false,
    error: false,
  },
}

const accountManagement = (state = initialState, action) => {
  switch (action.type) {
    case type.GET_ALL_TENANTS_REQUESTED:
      return {
        ...state,
        allTenants: {
          list: null,
          loading: true,
          error: false,
        },
      }
    case type.GET_ALL_TENANTS_SUCCEEDED:
      return {
        ...state,
        allTenants: {
          list: action.payload,
          loading: false,
          error: false,
        },
      }
    case type.GET_ALL_TENANTS_FAILED:
      return {
        ...state,
        allTenants: {
          list: null,
          loading: false,
          error: true,
        },
      }
    case type.GET_TENANT_REQUESTED:
      return {
        ...state,
        activeTenant: {
          list: null,
          loading: true,
          error: false,
        },
      }
    case type.GET_TENANT_SUCCEEDED:
      return {
        ...state,
        activeTenant: {
          list: action.payload,
          loading: false,
          error: false,
        },
      }
    case type.GET_TENANT_FAILED:
      return {
        ...state,
        activeTenant: {
          list: null,
          loading: false,
          error: true,
        },
      }
    case type.SET_TENANT_REQUESTED:
      return {
        ...state,
        setTenant: {
          list: null,
          loading: true,
          error: false,
        },
      }
    case type.SET_TENANT_SUCCEEDED:
      return {
        ...state,
        setTenant: {
          list: action.payload,
          loading: false,
          error: false,
        },
      }
    case type.SET_TENANT_FAILED:
      return {
        ...state,
        setTenant: {
          list: null,
          loading: false,
          error: true,
        },
      }
    case type.UPDATE_TENANT_REQUESTED:
      return {
        ...state,
        updateTenant: {
          list: null,
          loading: true,
          error: false,
        },
      }
    case type.UPDATE_TENANT_SUCCEEDED:
      return {
        ...state,
        updateTenant: {
          list: action.payload,
          loading: false,
          error: false,
        },
      }
    case type.UPDATE_TENANT_FAILED:
      return {
        ...state,
        updateTenant: {
          ...state.updateTenant,
          loading: false,
          error: true,
        },
      }
    case type.DELETE_TENANT_REQUESTED:
      return {
        ...state,
        deleteTenant: {
          list: null,
          loading: true,
          error: false,
        },
      }
    case type.DELETE_TENANT_SUCCEEDED:
      return {
        ...state,
        deleteTenant: {
          list: action.payload,
          loading: false,
          error: false,
        },
      }
    case type.DELETE_TENANT_FAILED:
      return {
        ...state,
        deleteTenant: {
          list: null,
          loading: false,
          error: true,
        },
      }
    case type.GET_DOMAIN_REQUESTED:
      return {
        ...state,
        activeDomain: {
          list: null,
          loading: true,
          error: false,
        },
      }
    case type.GET_DOMAIN_SUCCEEDED:
      return {
        ...state,
        activeDomain: {
          list: action.payload,
          loading: false,
          error: false,
        },
      }
    case type.GET_DOMAIN_FAILED:
      return {
        ...state,
        activeDomain: {
          list: null,
          loading: false,
          error: true,
        },
      }
    case type.SET_DOMAIN_REQUESTED:
      return {
        ...state,
        setDomain: {
          list: null,
          loading: true,
          error: false,
        },
      }
    case type.SET_DOMAIN_SUCCEEDED:
      return {
        ...state,
        setDomain: {
          list: action.payload,
          loading: false,
          error: false,
        },
      }
    case type.SET_DOMAIN_FAILED:
      return {
        ...state,
        setDomain: {
          list: null,
          loading: false,
          error: true,
        },
      }
    case type.DELETE_DOMAIN_REQUESTED:
      return {
        ...state,
        deleteDomain: {
          list: null,
          loading: true,
          error: false,
        },
      }
    case type.DELETE_DOMAIN_SUCCEEDED:
      return {
        ...state,
        deleteDomain: {
          list: action.payload,
          loading: false,
          error: false,
        },
      }
    case type.DELETE_DOMAIN_FAILED:
      return {
        ...state,
        deleteDomain: {
          list: null,
          loading: false,
          error: true,
        },
      }
    case type.GET_USERLIST_REQUESTED:
      return {
        ...state,
        userList: {
          list: null,
          loading: true,
          error: false,
        },
      }
    case type.GET_USERLIST_SUCCEEDED:
      return {
        ...state,
        userList: {
          list: action.payload,
          loading: false,
          error: false,
        },
      }
    case type.GET_USERLIST_FAILED:
      return {
        ...state,
        userList: {
          list: null,
          loading: false,
          error: true,
        },
      }
    case type.SET_USERLIST_REQUESTED:
      return {
        ...state,
        setUserList: {
          list: null,
          loading: true,
          error: false,
        },
      }
    case type.SET_USERLIST_SUCCEEDED:
      return {
        ...state,
        setUserList: {
          list: action.payload,
          loading: false,
          error: false,
        },
      }
    case type.SET_USERLIST_FAILED:
      return {
        ...state,
        setUserList: {
          list: null,
          loading: false,
          error: true,
        },
      }
    default:
      return state
  }
}

export default accountManagement
