import { useMutation } from "@tanstack/react-query"
import { API_PATCH } from "helpers/api"

export const useDomainAddonSettingUpdate = () => {
  const mutation = useMutation({
    mutationFn: (data) => API_PATCH(`${process.env.REACT_APP_DISPLAY_DOMAIN}/api/domainaddonsetting/update`, data),
  })

  return mutation
}
