import React, { useContext, useEffect, useRef } from "react"
import { useFormContext, Controller } from "react-hook-form"
import { SelectPicker } from "rsuite"
import { RuleFormContext } from "./RuleForm"
import localization from "localization"
import { useRuleMetaDataByRuleTypeId } from "utils/useRules"

export const RuleConditionSelect = ({ mainIndex, index }) => {
  const formPath = `rule.children[${mainIndex}].children[${index}]`
  const wrapperRef = useRef(null)
  const {
    control,
    formState: { errors },
    unregister,
    register,
    getValues,
  } = useFormContext()
  const { ruleTypeId } = useContext(RuleFormContext)
  const { ruleItems: data } = useRuleMetaDataByRuleTypeId(ruleTypeId)

  const errorMessage = errors?.rule?.children?.[mainIndex]?.children?.[index]?.conditionSelect?.message

  useEffect(() => {
    register(`${formPath}.conditionSelect`)
    return () => {
      unregister(`${formPath}.conditionSelect`)
    }
  }, [register, unregister, formPath, getValues])

  return (
    <Controller
      name={`${formPath}.conditionSelect`}
      control={control}
      rules={{
        required: "Bu alan boş bırakılamaz",
      }}
      render={({ field }) => (
        <div ref={wrapperRef}>
          <SelectPicker
            container={wrapperRef.current}
            placeholder="Seçim yapın"
            name={field.name}
            menuStyle={{ width: 270 }}
            block
            searchable={false}
            cleanable={false}
            value={field.value}
            loading={!data}
            data={
              Object.entries(data?.Metadatas || {}).map((item, index) => ({
                key: item[1].Title + "-" + index,
                label: item[1].Title,
                value: item[0],
              })) || []
            }
            locale={localization.TR.PickerLocaleType.Picker}
            onChange={(values) => {
              field.onChange(values)
            }}
          />
          {errorMessage && <p className="text-red-500 text-xs py-1">{errorMessage}</p>}
        </div>
      )}
    />
  )
}
