import { call, fork, put, takeLatest } from "redux-saga/effects"
import * as actions from "redux/actions/pageState"

import { PAGE_STATE_REQUESTED } from "redux/types"
import { API_POST } from "helpers/api"

function* watchStatePage() {
  yield takeLatest(PAGE_STATE_REQUESTED, getPageState)
}

function* getPageState(action) {
  try {
    const res = yield call(apiGetPageState, action.payload)
    yield put(actions.pageStateSucceed(res?.data))
  } catch (err) {
    yield put(actions.pageStateFailed())
  }
}

const apiGetPageState = (payload) => {
  return API_POST(`${process.env.REACT_APP_DISPLAY_DOMAIN}/api/page/update-domain-page-state`, payload)
}

export default function* pageState() {
  yield fork(watchStatePage)
}
