import * as type from "redux/types"

export const contractsSetRequested = (data) => ({
  type: type.CONTRACTS_SET_REQUESTED,
  payload: data,
})

export const contractsSetSucceed = (data) => ({
  type: type.CONTRACTS_SET_SUCCEEDED,
  payload: data,
})

export const contractsSetFailed = () => ({
  type: type.CONTRACTS_SET_FAILED,
})
