import { call, fork, put, takeLatest } from "redux-saga/effects"
import * as actions from "redux/actions/addonPrice"

import { ADDON_PRICE_REQUESTED } from "redux/types"
import { API_GET } from "helpers/api"

function* watchGetaddonPrice() {
  yield takeLatest(ADDON_PRICE_REQUESTED, getAddonPrice)
}

function* getAddonPrice(action) {
  try {
    const res = yield call(apiGetAddonPrice, action.payload)
    yield put(actions.addonPriceSucceed(res?.data))
  } catch (err) {
    yield put(actions.addonPriceFailed())
  }
}

const apiGetAddonPrice = (addonPriceListId) => {
  return API_GET(`${process.env.REACT_APP_DISPLAY_DOMAIN}/api/addonprice/list/${addonPriceListId}`)
}

export default function* addonPrice() {
  yield fork(watchGetaddonPrice)
}
