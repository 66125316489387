import { TitleH3, Description } from "components"

const GenerateField = (props) => {
  let { properties, onChange } = props //textareaCharLength

  switch (properties?.propertyType) {
    case 1:
      return (
        <input
          type="number"
          disabled={true}
          name={properties?.propertyId}
          className="w-[72px] text-center"
          defaultValue={properties?.propertyValue ? JSON.parse(properties.propertyValue) : ""}
          onChange={(event) => onChange("number", event)}
          min={0}
          max={50}
        />
      )
    case 2:
      return (
        <textarea
          className="w-full"
          disabled={true}
          name={properties?.propertyId}
          defaultValue={properties?.propertyValue}
          onChange={(event) => onChange("textarea", event)}
          maxLength="400"
        />
      )
    case 3:
      return (
        <input
          type="checkbox"
          disabled={true}
          name={properties?.propertyId}
          defaultChecked={properties?.propertyValue ? JSON.parse(properties.propertyValue) : false}
          onChange={(event) => onChange("checkbox", { target: { name: event.target.name, value: event.target.checked } })}
        />
      )
    default:
      return <p className="text-xs">Türetilecek propertyType bulunamadı (propertyType = {properties?.propertyType})</p>
  }
}

const ProperyBox = (props) => {
  let { property, setProperties, textareaCharLength } = props
  return (
    <>
      {property.map((value, index) => {
        return (
          <div
            key={index}
            className={`box flex ${
              value?.propertyType === 3 ? "flex-row-reverse" : "flex-row flex-wrap"
            } justify-between gap-2 mb-4 last:mb-0`}
          >
            <div className={`${value?.propertyType !== 3 ? "w-[calc(100%-88px)]" : "grow"}`}>
              <TitleH3>{value?.propertyName}</TitleH3>
              {value?.propertyDescription && <Description>{value?.propertyDescription}</Description>}
            </div>

            <GenerateField
              properties={value}
              onChange={setProperties}
              textareaCharLength={textareaCharLength} // todo: yazım esnasında örn 16/400
            />
          </div>
        )
      })}
    </>
  )
}

export default ProperyBox
