import { call, fork, put, takeLatest } from "redux-saga/effects"
import * as actions from "redux/actions/getAllAccountList"

import { GET_ALL_ACCOUNT_LIST_REQUESTED } from "redux/types"
import { API_GET } from "helpers/api"

function* watchGetAllAccountList() {
  yield takeLatest(GET_ALL_ACCOUNT_LIST_REQUESTED, getAllAccountList)
}

function* getAllAccountList(action) {
  try {
    const res = yield call(apiGetAllAccountList, action.payload)
    yield put(actions.allAccountListSucceed(res?.data))
  } catch (err) {
    yield put(actions.allAccountListFailed())
  }
}

const apiGetAllAccountList = (payload) => {
  return API_GET(`${process.env.REACT_APP_AUTH_DOMAIN}/api/clientaccount/list`, payload, false)
}

export default function* allAccountListGet() {
  yield fork(watchGetAllAccountList)
}
