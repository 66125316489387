import { call, fork, put, takeLatest } from "redux-saga/effects"
import * as actions from "redux/actions/pageDelete"

import { PAGE_DELETE_REQUESTED } from "redux/types"
import {API_DELETE} from "helpers/api"

function* watchDeletePage() {
  yield takeLatest(PAGE_DELETE_REQUESTED, getPageDelete)
}

function* getPageDelete(action) {
  try {
    const res = yield call(apiGetPageDelete, action.payload)
    yield put(actions.pageDeleteSucceed(res?.data))
  } catch (err) {
    yield put(actions.pageDeleteFailed())
  }
}

const apiGetPageDelete = (payload) => {
  return API_DELETE(`${process.env.REACT_APP_DISPLAY_DOMAIN}/api/page/delete/${payload}`)
}

export default function* pageDelete() {
  yield fork(watchDeletePage)
}
