import React, { useState } from "react"

// wrappers
import PartialWrapper from "../../../../PartialWrapper"

//components
import { Icon, Subtitle, ToggleBox } from "components"
import { Dropdown, Whisper } from "rsuite"
import { NavigationListEdit } from "../index"

// utils

const Navigation = (props) => {
  let { editBlock, setEditBlock } = props

  //states
  const [NavListDdownOpen, setNavListDdownOpen] = useState(null)
  const [EditNavList, setEditNavList] = useState(null)

  const navigationList = ["Otel", "Uçak", "Tur"] /*, "Araç Kiralama", "Paketle Kazan"*/

  // Func

  const blockListDropDownToggle = (index, show) => {
    setNavListDdownOpen({
      index,
      show,
    })
  }

  const renderBlockListDropdown = ({ onClose, left, top, className }, ref, index, item) => {
    const handleSelect = (eventKey) => {
      if (eventKey) {
        onClose()
        switch (eventKey) {
          case "edit":
            setEditNavList({
              item,
            })
            break
          default:
            break
        }
      }
    }
    return (
      <Dropdown.Menu
        ref={ref}
        className={`${className} min-w-[190px] max-w-[240px]`}
        style={{ left, top }}
        onSelect={handleSelect}
      >
        <Dropdown.Item className="font-medium py-2.5 px-4 cursor-default !bg-white !text-gray-800 hover:!bg-white hover:text-gray-800">
          Seçenekler
        </Dropdown.Item>
        <hr />
        <Dropdown.Item
          className="flex gap-3 items-center py-2.5 px-4"
          eventKey="edit"
        >
          <Icon
            name="edit-2"
            size={16}
            color="#1D2939"
          />
          <p>Düzenle</p>
        </Dropdown.Item>
        <Dropdown.Item
          className="flex gap-3 items-center py-2.5 px-4"
          eventKey="delete"
        >
          <Icon
            name="trash"
            size={16}
            color="#1D2939"
          />
          <p>Sil</p>
        </Dropdown.Item>
      </Dropdown.Menu>
    )
  }

  return (
    <PartialWrapper
      className={`navigation-layout${editBlock?.showNavigationLayout ? " active" : ""}`}
      goBackOnClick={() => setEditBlock(null)}
    >
      <Subtitle>Navigation (Your Products)</Subtitle>

      <div className="input-wrapper mt-4">
        <ToggleBox
          title={"Display"}
          defaultChecked={true}
        />
      </div>

      <div className="border-box border rounded-lg border-gray-200 overflow-auto mt-6">
        {navigationList.map((item, index) => {
          //let lockedItem = block?.name !== "Header" && block?.name !== "Footer"
          return (
            <div
              key={index}
              className={`group relative flex items-center capitalize gap-2 py-4 px-3 border-b border-gray-200 select-none last:border-0`}
            >
              <Icon
                name="image"
                size={14}
                color="#1D2939"
                className="shrink-0"
              />
              <p className="mr-auto text-sm text-gray-800 truncate">{item}</p>

              <Whisper
                placement="bottomEnd"
                trigger="click"
                speaker={({ onClose, left, top, className }, ref) =>
                  renderBlockListDropdown({ onClose, left, top, className }, ref, index, item)
                }
                onOpen={() => blockListDropDownToggle(index, true)}
                onClose={() => blockListDropDownToggle(index, false)}
              >
                <div className="cursor-pointer">
                  <Icon
                    name="more-vertical"
                    size={14}
                    className="!duration-[100ms]"
                    color={NavListDdownOpen?.index === index && NavListDdownOpen?.show ? "#2E90FA" : "#1D2939"}
                  />
                </div>
              </Whisper>
            </div>
          )
        })}
      </div>

      <NavigationListEdit
        editNavList={EditNavList}
        setEditNavList={setEditNavList}
      />
    </PartialWrapper>
  )
}

export default Navigation
