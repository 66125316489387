import React, { useState } from "react"
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts"

import Icon from "components/Icon"

const TotalReservation = () => {
  const [activeTab, setActiveTab] = useState(1)

  const data = [
    { name: "Group A", value: 400 },
    { name: "Group B", value: 300 },
    { name: "Group C", value: 300 },
    { name: "Group D", value: 200 },
  ]

  const COLORS = ["#1570EF", "#2E90FA", "#84CAFF", "#B9E6FE"]

  return (
    <div className="border border-gray-200 rounded-lg p-6 flex flex-col gap-6 bg-white">
      <div className="flex justify-between items-center">
        <h2 className="text-xl text-gray-700 font-semibold">Toplam Rezervasyon</h2>
        <div className="flex">
          <button
            className={`text-sm text-gray-500 px-2 pb-2 font-medium ${
              activeTab === 1 ? "border-b-blue-500 border-b-2 text-blue-500" : "border-b border-gray-200"
            }`}
            onClick={() => setActiveTab(1)}
          >
            30g
          </button>
          <button
            className={`text-sm text-gray-500 px-2 pb-2 font-medium ${
              activeTab === 2 ? "border-b-blue-500 border-b-2 text-blue-500" : "border-b border-gray-200"
            }`}
            onClick={() => setActiveTab(2)}
          >
            7g
          </button>
          <button
            className={`text-sm text-gray-500 px-2 pb-2 font-medium ${
              activeTab === 3 ? "border-b-blue-500 border-b-2 text-blue-500" : "border-b border-gray-200"
            }`}
            onClick={() => setActiveTab(3)}
          >
            1g
          </button>
        </div>
      </div>

      <div className="flex items-center justify-between">
        <div>
          <ResponsiveContainer
            width={250}
            height={340}
          >
            <PieChart>
              <Pie
                data={data}
                labelLine={false}
                cx="50%"
                cy="50%"
                outerRadius={120}
                fill="#8884d8"
                dataKey="value"
              >
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>

        <div className="flex flex-col gap-10">
          <div className="flex gap-2 items-center">
            <Icon
              name="dot"
              size="10"
              color="#2E90FA"
            />
            <p className="text-gray-800 text-sm">Gerçekleşen</p>
          </div>
          <div className="flex gap-2 items-center">
            <Icon
              name="dot"
              size="10"
              color="#84CAFF"
            />
            <p className="text-gray-800 text-sm">İptal Edilen</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TotalReservation
