import * as type from "redux/types"

const initialState = {
  getDesignFieldsValue: {
    list: null,
    loading: false,
    error: false,
  },
  saveDesignFieldsValue: {
    response: null,
    loading: false,
    error: false,
  },
  getTemplates: {
    list: null,
    loading: false,
    error: false,
  },
  deleteStrategy: {
    response: null,
    loading: false,
    error: false,
  },
}

const strategyDesign = (state = initialState, action) => {
  switch (action.type) {
    case type.DESIGN_FIELDS_VALUE_REQUESTED:
      return {
        ...state,
        getDesignFieldsValue: {
          list: null,
          loading: true,
          error: false,
        },
      }
    case type.DESIGN_FIELDS_VALUE_SUCCEEDED:
      return {
        ...state,
        getDesignFieldsValue: {
          list: action.payload,
          loading: false,
          error: false,
        },
      }
    case type.DESIGN_FIELDS_VALUE_FAILED:
      return {
        ...state,
        getDesignFieldsValue: {
          list: null,
          loading: false,
          error: true,
        },
      }
    case type.DESIGN_FIELDS_VALUE_SAVE_REQUESTED:
      return {
        ...state,
        saveDesignFieldsValue: {
          response: null,
          loading: true,
          error: false,
        },
      }
    case type.DESIGN_FIELDS_VALUE_SAVE_SUCCEEDED:
      return {
        ...state,
        saveDesignFieldsValue: {
          response: action.payload,
          loading: false,
          error: false,
        },
      }
    case type.DESIGN_FIELDS_VALUE_SAVE_FAILED:
      return {
        ...state,
        saveDesignFieldsValue: {
          response: null,
          loading: false,
          error: true,
        },
      }
    case type.GET_TEMPLATES_REQUESTED:
      return {
        ...state,
        getTemplates: {
          list: null,
          loading: true,
          error: false,
        },
      }
    case type.GET_TEMPLATES_SUCCEEDED:
      return {
        ...state,
        getTemplates: {
          list: action.payload,
          loading: false,
          error: false,
        },
      }
    case type.GET_TEMPLATES_FAILED:
      return {
        ...state,
        getTemplates: {
          list: null,
          loading: false,
          error: true,
        },
      }
    case type.DELETE_STRATEGY_REQUESTED:
      return {
        ...state,
        deleteStrategy: {
          response: null,
          loading: true,
          error: false,
        },
      }
    case type.DELETE_STRATEGY_SUCCEEDED:
      return {
        ...state,
        deleteStrategy: {
          response: action.payload,
          loading: false,
          error: false,
        },
      }
    case type.DELETE_STRATEGY_FAILED:
      return {
        ...state,
        deleteStrategy: {
          response: null,
          loading: false,
          error: true,
        },
      }
    default:
      return state
  }
}

export default strategyDesign
