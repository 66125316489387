import * as type from "redux/types"

export const domainStateGetRequested = (data) => ({
  type: type.DOMAIN_GET_STATE_REQUESTED,
  payload: data,
})

export const domainStateGetSucceed = (data) => ({
  type: type.DOMAIN_GET_STATE_SUCCEEDED,
  payload: data,
})

export const domainStateGetFailed = () => ({
  type: type.DOMAIN_GET_STATE_FAILED,
})
