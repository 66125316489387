import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

// components
import MainHeader from "components/Headers/MainHeader"
import LivePreview from "./LivePreview"
import Icon from "components/Icon"
import { LogoUpload, TitleH3 } from "components"

// utils
import { hexIsLight } from "utils/hexIsLight"

// RSuite.JS
import { SelectPicker } from "rsuite"

// Constants
import { B2CDemoTypes } from "../../constants"

// Requests
import { listConfigurationRequested } from "./redux/actions/ListConfiguration"
import { getConfigurationRequested } from "./redux/actions/GetConfiguration"
import { ThemeConfigValue } from "./redux/actions/setThemeConfig"
import { setViewportFullSize } from "./redux/actions/ViewportFullSize"
import CorpLivePreview from "./CorpLivePreview"
import {DefaultVisualStyles} from "../PageManager/Enums";

const ThemeSettings = (props) => {
  let { AssociatedDomains } = props

  const dispatch = useDispatch()

  // refs
  const formRef = useRef()
  const UploadFileRef = useRef()
  const demoTypesWrapper = useRef()
  const textTypeWrapper = useRef()
  const linkTypeWrapper = useRef()

  const DomainId = AssociatedDomains?.domainId
  const DomainType = AssociatedDomains?.domainType
  // constants
  const ActiveDemoTypes = B2CDemoTypes.map((item, k) => ({ label: item.demoType.label, value: item.demoType.value }))

  // selectors
  const ListConfiguration = useSelector((state) => state.ListConfiguration.result)
  const ListConfigurationLoading = useSelector((state) => state.ListConfiguration.loading)
  const GetConfiguration = useSelector((state) => state.GetConfiguration.result)
  const GetConfigurationLoading = useSelector((state) => state.GetConfiguration.loading)
  const ViewportSize = useSelector((state) => state.ViewportFullSize.show)
  const DomainInfoState = useSelector((state) => state.associatedDomains.data)
  const saveConfigurationResponse = useSelector((state) => state.SaveConfiguration.data)

  // states
  const [AllImgsLoaded, setAllImgsLoaded] = useState(false)
  const [DefaultThemeButtonColor] = useState(DefaultVisualStyles.button.backgroundColor)
  const [DefaultThemeLinkColor] = useState(DefaultVisualStyles.button.backgroundColor)
  const [AllFonts, setAllFonts] = useState(null)
  const [AllLinks, setAllLinks] = useState(null)
  const [AllDisplays, setAllDisplays] = useState(null)
  const [ActiveDemoType, setActiveDemoType] = useState(ActiveDemoTypes[0])
  const [ActiveDisplays, setActiveDisplays] = useState(null)
  const [ActiveFontFamily, setActiveFontFamily] = useState(null)
  const [ActiveFontFamilyChanged, setActiveFontFamilyChanged] = useState(0)
  const [ActiveThemeButtonColor, setActiveThemeButtonColor] = useState(DefaultThemeButtonColor)
  const [ActiveThemeButtonColorIsLight, setActiveThemeButtonColorIsLight] = useState(false)
  const [ActiveThemeLinkColor, setActiveThemeLinkColor] = useState(DefaultThemeLinkColor)
  const [ActiveThemeLinkColorIsLight, setActiveThemeLinkColorIsLight] = useState(false)
  const [ActiveThemeLinkType, setActiveThemeLinkType] = useState(null)
  const [DraggableImageBoxHoverActive, setDraggableImageBoxHoverActive] = useState(false)
  const [ShowColorPicker, setShowColorPicker] = useState(false)
  const [ShowLinkColorPicker, setShowLinkColorPicker] = useState(false)
  const [ThemeConfigBeauty, setThemeConfigBeauty] = useState()
  const [GetThemeConfig, setGetThemeConfig] = useState(null)
  const [ThemeConfig, setThemeConfig] = useState({
    demoType: ActiveDemoType,
    displaySettings: {
      borderRadius: ActiveDisplays?.styles?.borderRadius,
      fontFamily: ActiveDisplays?.styles?.fontFamily,
      linkType: ActiveThemeLinkType,
    },
  })
  const [DisabledSaveButton, setDisabledSaveButton] = useState(true)
  const [CopyThemeConfig, setCopyThemeConfig] = useState(ThemeConfig)
  const [DeepCopyThemeConfig, setDeepCopyThemeConfig] = useState(null)

  // Func
  const setConfig = (config) => {
    if (config) {
      setThemeConfig({
        ...ThemeConfig,
        ...config,
      })
    }
  }

  const calcImageSize = (size) => {
    let resultSize = size
    if (resultSize > 100000) {
      resultSize = resultSize / 1000000
      resultSize = resultSize.toFixed(2) + " MB"
    } else if (resultSize >= 1000) {
      resultSize = resultSize / 1000
      resultSize = (resultSize + "").split(".")[0] + " KB"
    } else if (resultSize < 1000) {
      resultSize = resultSize / 1000
      resultSize = resultSize + " bytes"
    }

    return resultSize
  }

  const readImage = (file, config) => {
    const reader = new FileReader()
    reader.addEventListener("load", (event) => {
      setThemeConfig({
        ...ThemeConfig,
        logoValues: {
          src: event.target.result,
          size: file.size,
          name: file.name,
          fileForBinary: file,
        },
      })
      if (config) demoTypeChanged({ ...config, isUploadSuccess: true })
    })
    reader.readAsDataURL(file)
    setDraggableImageBoxHoverActive(false)
    UploadFileRef.current.value = ""
  }

  const imageDragging = (event, show) => {
    event.stopPropagation()
    event.preventDefault()
    event.dataTransfer.dropEffect = "copy"
    show ? setDraggableImageBoxHoverActive(true) : setDraggableImageBoxHoverActive(false)
  }

  const imageDropped = (event) => {
    event.stopPropagation()
    event.preventDefault()
    const fileList = event.dataTransfer.files
    readImage(fileList[0])
  }

  const demoTypeChanged = (config) => {
    if (config.isUploadSuccess) {
      setActiveDisplays({
        label: config.displayName,
        styles: {
          borderRadius: config.displaySettings.borderRadius,
          fontFamily: config.displaySettings.fontFamily,
        },
      })
      setActiveThemeLinkType(config.displaySettings.linkType)
      setActiveFontFamily(config.displaySettings.fontFamily)
      setActiveThemeButtonColor(config.displaySettings.buttonColor)
      setActiveThemeLinkColor(config.displaySettings.linkColor)
    } else {
      fetch(config.logoValues.src)
        .then((res) => res.blob())
        .then((file) => {
          readImage(file, config)
        })
    }
  }

  const isdemoTypeSelectClean = () => {
    setGetThemeConfig({
      ...GetConfiguration,
      cleared: true,
    })
  }

  const getPageRequestsSync = () => {
    dispatch(
      listConfigurationRequested({
        query: `domainType=${DomainType}`,
      }),
    )
    dispatch(
      getConfigurationRequested({
        domainType: DomainId,
      }),
    )
  }

  useEffect(() => {
    let fontFamily

    // if font doesn't changed and check theme configuration
    if (ActiveFontFamilyChanged === 0 && !GetThemeConfig) {
      fontFamily = ActiveDisplays?.styles?.fontFamily
    } else {
      fontFamily = ActiveFontFamily
    }
    setActiveFontFamily(fontFamily)

    let config = {
      ...ThemeConfig,
      demoType: ActiveDemoType,
      displaySettings: {
        ...ThemeConfig.displaySettings,
        borderRadius: ActiveDisplays?.styles?.borderRadius,
        fontFamily,
        buttonColor: ActiveThemeButtonColor,
        linkColor: ActiveThemeLinkColor,
        linkType: ActiveThemeLinkType,
      },
    }
    setThemeConfig(config)
    setCopyThemeConfig(config)
    // eslint-disable-next-line
  }, [
    ActiveDemoType,
    ActiveDisplays,
    ActiveFontFamilyChanged,
    ActiveThemeButtonColor,
    ActiveThemeLinkColor,
    ActiveThemeLinkType,
    GetThemeConfig,
  ])

  useEffect(() => {
    let result = JSON.stringify(ThemeConfig, null, 4)
    setThemeConfigBeauty(result)
    dispatch(ThemeConfigValue(result))
  }, [dispatch, ThemeConfig])

  // request
  useEffect(() => {
    if (!ListConfigurationLoading && !GetConfigurationLoading) {
      if (DomainId && DomainType) {
        getPageRequestsSync()
      } else {
        if (DomainInfoState && DomainInfoState.length) {
          getPageRequestsSync()
        }
      }
    }
    // eslint-disable-next-line
  }, [dispatch, DomainInfoState, DomainId, DomainType])

  // data manipulation
  useEffect(() => {
    if (ListConfiguration !== null) {
      // add background-color to response list
      let displays = ListConfiguration?.displays.reduce((acc, value) => {
        acc.push({
          ...value,
          backgroundColor: "#EFF8FF",
        })
        return acc
      }, [])

      // compare if GetThemeConfig is not empty
      let activeDisplay = displays ? displays[0] : {}
      let activeFontFamily = ListConfiguration?.fonts[0]?.value
      let activeThemeLinkType = ListConfiguration?.links[0]?.value
      let activeThemeButtonColor = DefaultThemeButtonColor
      let activeThemeLinkColor = DefaultThemeLinkColor
      if (GetThemeConfig?.configurations.length) {
        activeDisplay = [
          GetThemeConfig?.configurations.find(({ configurationMetadataCode }) => configurationMetadataCode === "theme"),
        ].reduce((acc, value) => {
          acc = {
            label: value.name,
            styles: value.properties,
          }
          return acc
        }, [])

        activeFontFamily = GetThemeConfig?.configurations.find(
          ({ configurationMetadataCode }) => configurationMetadataCode === "font",
        )?.name
        activeThemeLinkType = GetThemeConfig?.configurations.find(
          ({ configurationMetadataCode }) => configurationMetadataCode === "linktype",
        )?.properties?.textDecorationLine
        activeThemeButtonColor = GetThemeConfig?.configurations.find(
          ({ configurationMetadataCode }) => configurationMetadataCode === "color",
        )?.properties?.color
        activeThemeLinkColor = GetThemeConfig?.configurations.find(
          ({ configurationMetadataCode }) => configurationMetadataCode === "linkcolor",
        )?.properties?.linkcolor
        setThemeConfig({
          ...ThemeConfig,
          logoValues: {
            src: GetThemeConfig?.logoUrl,
            name: GetThemeConfig?.logoUrl,
          },
          favIconValues: {
            src: GetThemeConfig?.favIconUrl,
            name: GetThemeConfig?.favIconUrl,
          },
        })
        /*
        setActiveFontFamily(ListConfiguration.fonts[0].value)
        setActiveThemeLinkType(ListConfiguration.links[0].value)*/
      }
      setAllDisplays(displays)
      setActiveDisplays(activeDisplay)
      setAllFonts(ListConfiguration?.fonts)
      setActiveFontFamily(activeFontFamily)
      setAllLinks(ListConfiguration?.links)
      setActiveThemeLinkType(activeThemeLinkType ? activeThemeLinkType : ActiveThemeLinkType)
      setActiveThemeButtonColor(activeThemeButtonColor ? activeThemeButtonColor : DefaultThemeButtonColor)
      setActiveThemeLinkColor(activeThemeLinkColor ? activeThemeLinkColor : DefaultThemeLinkColor)
    }
    // eslint-disable-next-line
  }, [GetThemeConfig, ListConfiguration])

  // getConfiguration
  useEffect(() => {
    if (GetConfiguration) {
      setGetThemeConfig(GetConfiguration)
    }
  }, [GetConfiguration])

  useEffect(() => {
    if (AllImgsLoaded) {
      setDeepCopyThemeConfig(CopyThemeConfig)
    } else if (AllImgsLoaded && saveConfigurationResponse) {
      setDeepCopyThemeConfig(ThemeConfig)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AllImgsLoaded, saveConfigurationResponse])

  // detect changed ThemeSettings
  useEffect(() => {
    if (DeepCopyThemeConfig) {
      setDisabledSaveButton(JSON.stringify(DeepCopyThemeConfig) === JSON.stringify(ThemeConfig))
    }
  }, [ThemeConfig, DeepCopyThemeConfig])

  // activeDemoType
  useEffect(() => {
    if (ActiveDemoType && ActiveDemoType.value !== 0) demoTypeChanged(B2CDemoTypes[ActiveDemoType.value])
    // eslint-disable-next-line
  }, [ActiveDemoType])

  // when Viewport Size changed - fullscreen button
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        dispatch(setViewportFullSize(!ViewportSize))
        setShowColorPicker(false)
        setShowLinkColorPicker(false)
      }
    }
    window.addEventListener("keydown", handleEsc)

    return () => {
      window.removeEventListener("keydown", handleEsc)
    }
  }, [ViewportSize, dispatch])

  useEffect(() => {
    if (ListConfiguration) {
      Promise.all(
        Array.from(document.images).map((img) => {
          if (img.complete)
            if (img.naturalHeight !== 0) return Promise.resolve()
            else return Promise.reject(img)
          return new Promise((resolve, reject) => {
            img.addEventListener("load", resolve)
            img.addEventListener("error", () => reject(img))
          })
        }),
      ).then(
        () => {
          setTimeout(() => {
            setAllImgsLoaded(true)
            console.log(
              "%c all images loaded successfully! ",
              "background: green; color: white; padding: 10px 5px; font-size:17px",
            )
            // 500ms delay for bg images and some logics
          }, 500)
        },
        (badImg) => {
          console.log(
            "%c some image failed to load, others may still be loading ",
            "background: green; color: white; padding: 10px 5px; font-size:17px",
          )
          console.log("%c first broken image: " + badImg + " ", "background: green; color: white")
        },
      )
    }
  }, [ListConfiguration])

  return (
    <>
      <MainHeader
        isLogged={props.isLogged}
        isDemo={true}
        className={"isDemoPage"}
        lpLogoFile={ThemeConfig.logoValues?.fileForBinary}
        favIconFile={ThemeConfig.favIconValues?.fileForBinary}
        DisabledSaveButton={DisabledSaveButton}
      />
      <main className={`${AllImgsLoaded ? "initialized" : ""}${ViewportSize ? " full-size" : ""}`}>
        <aside className="aside w-[300px] overflow-auto border-r border-r-gray-200 whitespace-nowrap p-6">
          {/*Demo Type*/}

          <div
            ref={demoTypesWrapper}
            className="ds-box relative mb-8"
          >
            <TitleH3 className="mb-2">Hazır seçenekler</TitleH3>

            <div className="box-content relative">
              <div className="animate-pulse absolute left-0 w-full h-full bottom-0 space-y-3">
                <div className="grid grid-cols-4 gap-2">
                  <div className="h-11 bg-slate-200 rounded col-span-3" />
                  <div className="h-11 bg-slate-200 rounded col-span-1" />
                </div>
              </div>
              <SelectPicker
                block
                //placeholder={"Seçiniz"}
                defaultValue={0}
                data={ActiveDemoTypes ? ActiveDemoTypes : []}
                searchable={false}
                cleanable={false}
                container={demoTypesWrapper.current}
                onSelect={(value, item) => {
                  value === 0 ? isdemoTypeSelectClean() : setActiveDemoType({ ...item })
                }}
              />
            </div>
          </div>

          {/*Display*/}
          <div className="ds-box mb-8">
            <TitleH3 className="mb-2">Görünüm</TitleH3>

            <div className="box-content relative">
              <div className="animate-pulse absolute left-0 w-full h-full bottom-0 space-y-3">
                <div className="grid grid-cols-4 gap-2">
                  <div className="h-[50px] bg-slate-200 rounded col-span-3" />
                  <div className="h-[50px] bg-slate-200 rounded col-span-1" />
                </div>
                <div className="grid grid-cols-4 gap-2">
                  <div className="h-[50px] bg-slate-200 rounded col-span-3" />
                  <div className="h-[50px] bg-slate-200 rounded col-span-1" />
                </div>
                <div className="grid grid-cols-4 gap-2">
                  <div className="h-[50px] bg-slate-200 rounded col-span-3" />
                  <div className="h-[50px] bg-slate-200 rounded col-span-1" />
                </div>
              </div>
              <div className="data-box min-h-[150px]">
                {AllDisplays &&
                  ActiveDisplays &&
                  AllDisplays.map((display, index) => {
                    return (
                      <label
                        onClick={() => setActiveDisplays(display)}
                        htmlFor={`display-${index}`}
                        key={index}
                        className={`${
                          display.label === ActiveDisplays.label ? "border-blue-200 " : ""
                        }radio-group transition flex items-center border border-gray-200 shadow-buttonSm rounded-lg py-[5px] px-3 cursor-pointer mb-2 last:mb-0`}
                      >
                        <div className="radio-area inline-flex items-center mr-2">
                          <input
                            id={`display-${index}`}
                            type="radio"
                            name={"radios"}
                            onChange={() => false}
                            checked={display.label === ActiveDisplays.label}
                          />
                        </div>
                        <p className="text-gray-800 font-medium text-sm mr-4">{display.label}</p>
                        <div
                          className="font-preview-wrapper ml-auto w-[50px] h-10 flex items-center justify-center"
                          style={{ ...display.styles, backgroundColor: display.backgroundColor }}
                        >
                          <p className="text-gray-900 font-bold">Aa</p>
                        </div>
                      </label>
                    )
                  })}
              </div>
            </div>
          </div>

          {/*Text Type*/}
          <div
            ref={textTypeWrapper}
            className="ds-box relative mb-8"
          >
            <TitleH3 className="mb-2">Yazı Tipi</TitleH3>

            <div className="box-content relative">
              <div className="animate-pulse absolute left-0 w-full h-full bottom-0 space-y-3">
                <div className="grid grid-cols-4 gap-2">
                  <div className="h-11 bg-slate-200 rounded col-span-3" />
                  <div className="h-11 bg-slate-200 rounded col-span-1" />
                </div>
              </div>

              <SelectPicker
                block
                placeholder={""}
                value={ActiveFontFamily}
                menuMaxHeight={164}
                cleanable={false}
                data={AllFonts ? AllFonts : []}
                searchable={false}
                container={textTypeWrapper.current}
                onSelect={(value, item) => {
                  setActiveFontFamily(value)
                  setActiveFontFamilyChanged(ActiveFontFamilyChanged + 1)
                }}
              />
            </div>
          </div>

          {/*Logo Selector*/}
          <div className="ds-box mb-8">
            <TitleH3 className="mb-2">Marka logosu</TitleH3>

            <div className="box-content relative">
              <div className="animate-pulse absolute left-0 w-full h-full bottom-0 border border-blue-500 border-dashed rounded-lg overflow-hidden">
                <div className="grid grid-cols-4 h-full">
                  <div className="h-full bg-slate-200 rounded col-span-4" />
                </div>
              </div>
              <div className="data-box">
                <form
                  ref={formRef}
                  className="hidden"
                >
                  <input
                    type="file"
                    ref={UploadFileRef}
                    onChange={(e) => readImage(UploadFileRef.current.files[0])}
                    accept=".png, .svg, .gif"
                  />
                </form>
                <div className="flex justify-center items-center w-full mb-8">
                  {ThemeConfig?.logoValues ? (
                    <div className="w-full">
                      <div
                        className={`${
                          DraggableImageBoxHoverActive ? "bg-white " : ""
                        }w-full flex items-center justify-center min-h-[100px] border border-gray-200 border-dashed rounded-lg mb-4`}
                        onDragOver={(e) => imageDragging(e, true)}
                        onDragLeave={(e) => imageDragging(e, false)}
                        onDrop={imageDropped}
                      >
                        <img
                          src={ThemeConfig.logoValues?.src}
                          alt="Logo"
                          className={`${DraggableImageBoxHoverActive ? "opacity-0 " : ""}max-h-60`}
                        />
                      </div>

                      <div className="flex gap-2 items-start relative text-sm font-medium border border-blue-500 rounded-lg pl-3 py-4 pr-10 shadow-md">
                        <svg
                          className="shrink-0"
                          width="32"
                          height="32"
                          viewBox="0 0 46 46"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="3"
                            y="3"
                            width="40"
                            height="40"
                            rx="20"
                            fill="#D1E9FF"
                          />
                          <g clipPath="url(#clip0_164_9244)">
                            <path
                              d="M26.3333 26.3334L23 23M23 23L19.6666 26.3334M23 23V30.5M29.9916 28.325C30.8044 27.8819 31.4465 27.1808 31.8165 26.3322C32.1866 25.4837 32.2635 24.5361 32.0351 23.6389C31.8068 22.7418 31.2862 21.9463 30.5555 21.3779C29.8248 20.8095 28.9257 20.5006 28 20.5H26.95C26.6977 19.5244 26.2276 18.6186 25.5749 17.8509C24.9222 17.0831 24.104 16.4732 23.1817 16.0672C22.2594 15.6612 21.2571 15.4695 20.2501 15.5066C19.243 15.5437 18.2575 15.8086 17.3676 16.2814C16.4777 16.7542 15.7066 17.4226 15.1122 18.2363C14.5177 19.0501 14.1155 19.988 13.9358 20.9795C13.756 21.9711 13.8034 22.9905 14.0743 23.9611C14.3452 24.9317 14.8327 25.8282 15.5 26.5834"
                              stroke="#2E90FA"
                              strokeWidth="1.66667"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                          <rect
                            x="3"
                            y="3"
                            width="40"
                            height="40"
                            rx="20"
                            stroke="#EFF8FF"
                            strokeWidth="6"
                          />
                          <defs>
                            <clipPath id="clip0_164_9244">
                              <rect
                                width="20"
                                height="20"
                                fill="white"
                                transform="translate(13 13)"
                              />
                            </clipPath>
                          </defs>
                        </svg>

                        <div className="grid flex-col">
                          <strong className="truncate">{ThemeConfig.logoValues?.name}</strong>
                          <span className="text-gray-500 text-sm">{calcImageSize(ThemeConfig.logoValues?.size)}</span>
                        </div>

                        <div className="absolute h-5 w-5 right-4 top-4">
                          <button onClick={() => setThemeConfig({ ...ThemeConfig, logoValues: null })}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="21"
                              viewBox="0 0 20 21"
                              fill="none"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M5.28594 5.44361C5.61138 5.11817 6.13902 5.11817 6.46446 5.44361L9.99999 8.97914L13.5355 5.44361C13.861 5.11817 14.3886 5.11817 14.714 5.44361C15.0395 5.76905 15.0395 6.29668 14.714 6.62212L11.1785 10.1577L14.714 13.6932C15.0395 14.0186 15.0395 14.5463 14.714 14.8717C14.3886 15.1971 13.861 15.1971 13.5355 14.8717L9.99999 11.3362L6.46446 14.8717C6.13902 15.1971 5.61138 15.1971 5.28594 14.8717C4.96051 14.5463 4.96051 14.0186 5.28594 13.6932L8.82148 10.1577L5.28594 6.62212C4.96051 6.29668 4.96051 5.76905 5.28594 5.44361Z"
                                fill="black"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className={`${
                        DraggableImageBoxHoverActive ? "bg-gray-100 " : ""
                      }flex flex-col justify-center items-center w-full bg-white rounded-lg border border-blue-300 border-dashed cursor-pointer transition hover:bg-gray-100`}
                    >
                      <div className="upload-area whitespace-normal overflow-hidden">
                        <button
                          onClick={() => UploadFileRef.current.click()}
                          onDragOver={(e) => imageDragging(e, true)}
                          onDragLeave={(e) => imageDragging(e, false)}
                          onDrop={imageDropped}
                        >
                          <div className="flex flex-col justify-center items-center py-4 px-6">
                            <svg
                              width="46"
                              height="46"
                              viewBox="0 0 46 46"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                x="3"
                                y="3"
                                width="40"
                                height="40"
                                rx="20"
                                fill="#D1E9FF"
                              />
                              <g clipPath="url(#clip0_164_9244)">
                                <path
                                  d="M26.3333 26.3334L23 23M23 23L19.6666 26.3334M23 23V30.5M29.9916 28.325C30.8044 27.8819 31.4465 27.1808 31.8165 26.3322C32.1866 25.4837 32.2635 24.5361 32.0351 23.6389C31.8068 22.7418 31.2862 21.9463 30.5555 21.3779C29.8248 20.8095 28.9257 20.5006 28 20.5H26.95C26.6977 19.5244 26.2276 18.6186 25.5749 17.8509C24.9222 17.0831 24.104 16.4732 23.1817 16.0672C22.2594 15.6612 21.2571 15.4695 20.2501 15.5066C19.243 15.5437 18.2575 15.8086 17.3676 16.2814C16.4777 16.7542 15.7066 17.4226 15.1122 18.2363C14.5177 19.0501 14.1155 19.988 13.9358 20.9795C13.756 21.9711 13.8034 22.9905 14.0743 23.9611C14.3452 24.9317 14.8327 25.8282 15.5 26.5834"
                                  stroke="#2E90FA"
                                  strokeWidth="1.66667"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </g>
                              <rect
                                x="3"
                                y="3"
                                width="40"
                                height="40"
                                rx="20"
                                stroke="#EFF8FF"
                                strokeWidth="6"
                              />
                              <defs>
                                <clipPath id="clip0_164_9244">
                                  <rect
                                    width="20"
                                    height="20"
                                    fill="white"
                                    transform="translate(13 13)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>

                            <p className={`text-sm text-center text-blue-500 mt-3`}>
                              <span className="font-normal">
                                Yüklemek için <span className="font-medium text-blue-500">tıklayın</span> ya da sürükleyip
                                bırakın.
                              </span>
                            </p>
                          </div>
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Favicon Selector */}

          <LogoUpload
            favIcon={true}
            titleValue="Site İkonu"
            description="32x32 px ve PNG formatında"
            setConfig={(config) => setConfig(config)}
            config={{
              ThemeConfig,
            }}
          />

          {/*Button Color*/}
          <div className="ds-box mb-8">
            <TitleH3 className="mb-2">Buton renkleri</TitleH3>

            <div className="box-content relative">
              <div className="animate-pulse absolute left-0 inline-block">
                <div className="grid grid-rows-1 grid-flow-col gap-4">
                  <div className="row-span-1 bg-slate-200 rounded w-9 h-9" />
                  <div className="row-span-1 bg-slate-200 rounded w-9 h-9" />
                </div>
              </div>

              <div className="flex items-center w-full mb-8">
                <button
                  onClick={() => {
                    setActiveThemeButtonColor(DefaultThemeButtonColor)
                  }}
                  className={`relative w-9 h-9 flex items-center justify-center rounded-lg bg-gray-900 cursor-pointer
                ${
                  ActiveThemeButtonColor !== DefaultThemeButtonColor
                    ? ""
                    : " before:block before:absolute before:-inset-1 before:border before:border-gray-400 before:rounded-xl before:opacity-50"
                }
                `}
                >
                  {ActiveThemeButtonColor === DefaultThemeButtonColor && (
                    <Icon
                      name={"check"}
                      size="24"
                      color="white"
                    />
                  )}
                </button>

                <div className="color-chooser-button relative flex items-center justify-center ml-4">
                  <label htmlFor="color-chooser">
                    <button
                      className={`w-9 h-9 flex items-center justify-center rounded-lg bg-gray-100 cursor-pointer
                ${
                  ActiveThemeButtonColor === DefaultThemeButtonColor
                    ? ""
                    : " before:block before:absolute before:-inset-1 before:border before:border-blue-400 before:rounded-xl before:opacity-50"
                }
                `}
                      style={{
                        backgroundColor: ActiveThemeButtonColor !== DefaultThemeButtonColor ? ActiveThemeButtonColor : "",
                      }}
                    >
                      <Icon
                        style={ShowColorPicker ? { transform: "rotate(45deg)" } : { transform: "" }}
                        className="shrink-0"
                        name="plus"
                        size="24"
                        color={`${
                          ActiveThemeButtonColor === DefaultThemeButtonColor
                            ? "#2E90FA"
                            : !ActiveThemeButtonColorIsLight
                            ? "#fff"
                            : "#000"
                        }`}
                      />
                    </button>
                  </label>

                  <input
                    id="color-chooser"
                    type="color"
                    className="absolute h-10 w-10 opacity-0"
                    value={ThemeConfig.displaySettings?.buttonColor}
                    onClick={() => setShowLinkColorPicker(false)}
                    onFocus={() => setShowColorPicker(true)}
                    onBlur={() => setShowColorPicker(false)}
                    onChange={(e) => {
                      setActiveThemeButtonColor(e.target.value)
                      setActiveThemeButtonColorIsLight(!!hexIsLight(e.target.value))
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          {/*Link Selector*/}
          <div className="ds-box mb-8">
            <TitleH3 className="mb-2">Linkler</TitleH3>

            <div className="box-content relative">
              <div className="animate-pulse absolute left-0 inline-block">
                <div className="grid grid-rows-1 grid-flow-col gap-4">
                  <div className="row-span-1 bg-slate-200 rounded w-9 h-9" />
                  <div className="row-span-1 bg-slate-200 rounded w-9 h-9" />
                </div>
              </div>

              <div className="flex items-center w-full mb-8">
                <button
                  onClick={() => {
                    setActiveThemeLinkColor(DefaultThemeLinkColor)
                  }}
                  className={`relative w-9 h-9 flex items-center justify-center rounded-lg bg-gray-900 cursor-pointer
                ${
                  ActiveThemeLinkColor !== DefaultThemeLinkColor
                    ? ""
                    : " before:block before:absolute before:-inset-1 before:border before:border-gray-400 before:rounded-xl before:opacity-50"
                }
                `}
                >
                  {ActiveThemeLinkColor === DefaultThemeLinkColor && (
                    <Icon
                      name={"check"}
                      size="24"
                      color="white"
                    />
                  )}
                </button>

                <div className="color-chooser-button relative flex items-center justify-center ml-4">
                  <label htmlFor="link-chooser">
                    <button
                      className={`w-9 h-9 flex items-center justify-center rounded-lg bg-gray-100 cursor-pointer
                ${
                  ActiveThemeLinkColor === DefaultThemeLinkColor
                    ? ""
                    : " before:block before:absolute before:-inset-1 before:border before:border-blue-400 before:rounded-xl before:opacity-50"
                }
                `}
                      style={{ backgroundColor: ActiveThemeLinkColor !== DefaultThemeLinkColor ? ActiveThemeLinkColor : "" }}
                    >
                      <Icon
                        style={ShowLinkColorPicker ? { transform: "rotate(45deg)" } : { transform: "" }}
                        className="shrink-0"
                        name="plus"
                        size="24"
                        color={`${
                          ActiveThemeLinkColor === DefaultThemeLinkColor
                            ? "#2E90FA"
                            : !ActiveThemeLinkColorIsLight
                            ? "#fff"
                            : "#000"
                        }`}
                      />
                    </button>
                  </label>

                  <input
                    id="link-chooser"
                    type="color"
                    className="absolute h-10 w-10 opacity-0"
                    value={ThemeConfig.displaySettings?.linkColor}
                    onClick={() => setShowColorPicker(false)}
                    onFocus={() => setShowLinkColorPicker(true)}
                    onBlur={() => setShowLinkColorPicker(false)}
                    onChange={(e) => {
                      setActiveThemeLinkColor(e.target.value)
                      setActiveThemeLinkColorIsLight(!!hexIsLight(e.target.value))
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          {/*Link Type*/}
          <div
            ref={linkTypeWrapper}
            className="ds-box relative mb-8"
          >
            <TitleH3 className="mb-2">Link Tipi</TitleH3>

            <div className="box-content relative">
              <div className="animate-pulse absolute left-0 w-full h-full bottom-0 space-y-3">
                <div className="grid grid-cols-4 gap-2">
                  <div className="h-11 bg-slate-200 rounded col-span-3" />
                  <div className="h-11 bg-slate-200 rounded col-span-1" />
                </div>
              </div>
              <SelectPicker
                block
                placement="topStart"
                placeholder={""}
                value={ActiveThemeLinkType}
                menuMaxHeight={164}
                cleanable={false}
                data={AllLinks ? AllLinks : []}
                searchable={false}
                container={linkTypeWrapper.current}
                onSelect={(value, item) => {
                  setActiveThemeLinkType(value)
                }}
              />
            </div>
          </div>
        </aside>
        <section className="lp-section relative flex flex-col bg-gray-25 w-[calc(100%_-_300px)] h-[calc(100vh_-_72px)] overflow-hidden">
          <div className="loader-wrapper absolute left-0 top-0 w-full h-full overflow-hidden bg-gray-100 z-[101]">
            <div className="animate-pulse">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="569px"
                height="449px"
                viewBox="0 0 569 449"
                fill="none"
                className="w-full h-auto"
              >
                <ellipse
                  cx="292"
                  cy="204.496"
                  rx="207"
                  ry="204.496"
                  fill="#F2F4F7"
                />
                <g filter="url(#filter0_d_272_123668)">
                  <path
                    d="M37.2061 68.4313C36.8395 64.3054 39.8877 60.6706 44.0143 60.3128L356.814 33.1905C360.941 32.8327 364.583 35.8873 364.95 40.0132L381.235 223.317C381.602 227.443 378.554 231.078 374.427 231.436L61.6274 258.558C57.5007 258.916 53.8583 255.861 53.4917 251.735L37.2061 68.4313Z"
                    fill="white"
                  />
                  <path
                    d="M37.2061 68.4313C36.8395 64.3054 39.8877 60.6706 44.0143 60.3128L356.814 33.1905C360.941 32.8327 364.583 35.8873 364.95 40.0132L381.235 223.317C381.602 227.443 378.554 231.078 374.427 231.436L61.6274 258.558C57.5007 258.916 53.8583 255.861 53.4917 251.735L37.2061 68.4313Z"
                    stroke="#EAECF0"
                  />
                  <path
                    d="M37.2061 68.4313C36.8395 64.3054 39.8877 60.6706 44.0143 60.3128L356.814 33.1905C360.941 32.8327 364.583 35.8873 364.95 40.0132L366.322 55.4555L38.5781 83.8736L37.2061 68.4313Z"
                    fill="white"
                  />
                  <rect
                    x="0.325427"
                    y="0.272908"
                    width="5.4"
                    height="5.4"
                    rx="2.7"
                    transform="matrix(0.996262 -0.0863841 0.0884961 0.996077 44.7437 68.8102)"
                    fill="#98A2B3"
                    stroke="#98A2B3"
                    strokeWidth="0.6"
                  />
                  <rect
                    x="0.325427"
                    y="0.272908"
                    width="5.4"
                    height="5.4"
                    rx="2.7"
                    transform="matrix(0.996262 -0.0863841 0.0884961 0.996077 53.71 68.0328)"
                    fill="#98A2B3"
                    stroke="#98A2B3"
                    strokeWidth="0.6"
                  />
                  <rect
                    x="0.325427"
                    y="0.272908"
                    width="5.4"
                    height="5.4"
                    rx="2.7"
                    transform="matrix(0.996262 -0.0863841 0.0884961 0.996077 62.6763 67.2553)"
                    fill="#98A2B3"
                    stroke="#98A2B3"
                    strokeWidth="0.6"
                  />
                  <path
                    d="M37.2061 68.4313C36.8395 64.3054 39.8877 60.6706 44.0143 60.3128L356.814 33.1905C360.941 32.8327 364.583 35.8873 364.95 40.0132L366.322 55.4555L38.5781 83.8736L37.2061 68.4313Z"
                    stroke="#EAECF0"
                  />
                  <path
                    d="M60.647 101.235C58.4461 101.426 56.8204 103.364 57.0159 105.565L57.7239 113.533C57.9194 115.734 59.8621 117.363 62.0629 117.172L133.794 110.952C135.995 110.762 137.62 108.823 137.425 106.623L136.717 98.654C136.521 96.4535 134.579 94.8244 132.378 95.0152L60.647 101.235Z"
                    fill="#F9FAFB"
                  />
                  <path
                    d="M62.771 125.141C60.5701 125.331 58.9445 127.27 59.14 129.47L59.8479 137.439C60.0434 139.64 61.9861 141.269 64.187 141.078L175.768 131.403C177.969 131.212 179.595 129.273 179.399 127.073L178.691 119.104C178.496 116.904 176.553 115.275 174.352 115.466L62.771 125.141Z"
                    fill="#F9FAFB"
                  />
                  <path
                    d="M61.264 153.376C61.0685 151.176 62.6942 149.237 64.895 149.047L200.387 137.298C202.588 137.108 204.53 138.737 204.726 140.937L205.434 148.906C205.629 151.106 204.003 153.045 201.803 153.236L66.311 164.984C64.1101 165.175 62.1675 163.546 61.972 161.345L61.264 153.376Z"
                    fill="#F9FAFB"
                  />
                  <path
                    d="M67.0186 172.952C64.8177 173.143 63.192 175.082 63.3875 177.282L64.0955 185.251C64.291 187.451 66.2336 189.08 68.4345 188.89L156.106 181.288C158.306 181.097 159.932 179.158 159.737 176.958L159.029 168.989C158.833 166.789 156.891 165.16 154.69 165.351L67.0186 172.952Z"
                    fill="#F9FAFB"
                  />
                  <rect
                    width="111.341"
                    height="144.019"
                    rx="4"
                    transform="matrix(0.996262 -0.0863841 0.0884961 0.996077 241.221 82.8662)"
                    fill="#F2F4F7"
                  />
                  <rect
                    width="170.048"
                    height="40.0052"
                    rx="4"
                    transform="matrix(0.996262 -0.0863841 0.0884961 0.996077 64.8804 202.561)"
                    fill="#F2F4F7"
                  />
                  <path
                    d="M278.056 149.874L273.211 137.155L276.345 136.883L279.452 145.852L279.571 145.842L281.099 136.47L284.199 136.201L287.353 145.115L287.48 145.104L288.928 135.791L292.07 135.518L289.535 148.878L286.334 149.156L283.106 140.556L282.927 140.572L281.265 149.596L278.056 149.874Z"
                    fill="#D0D5DD"
                  />
                  <path
                    d="M298.067 148.138L293.222 135.418L296.355 135.146L299.463 144.116L299.582 144.106L301.11 134.734L304.209 134.465L307.363 143.379L307.491 143.368L308.939 134.054L312.08 133.782L309.546 147.142L306.345 147.42L303.116 138.82L302.938 138.835L301.276 147.859L298.067 148.138Z"
                    fill="#D0D5DD"
                  />
                  <path
                    d="M318.077 146.401L313.233 133.682L316.366 133.41L319.474 142.379L319.593 142.369L321.121 132.997L324.22 132.728L327.374 141.642L327.502 141.631L328.949 132.318L332.091 132.045L329.557 145.405L326.356 145.683L323.127 137.083L322.949 137.099L321.287 146.123L318.077 146.401Z"
                    fill="#D0D5DD"
                  />
                </g>
                <g filter="url(#filter1_d_272_123668)">
                  <path
                    d="M202.89 171.019C203.22 166.89 206.836 163.805 210.966 164.128L523.988 188.617C528.118 188.94 531.197 192.549 530.866 196.678L516.162 380.112C515.831 384.241 512.215 387.326 508.086 387.003L195.063 362.514C190.934 362.191 187.854 358.582 188.185 354.453L202.89 171.019Z"
                    fill="white"
                  />
                  <path
                    d="M202.89 171.019C203.22 166.89 206.836 163.805 210.966 164.128L523.988 188.617C528.118 188.94 531.197 192.549 530.866 196.678L516.162 380.112C515.831 384.241 512.215 387.326 508.086 387.003L195.063 362.514C190.934 362.191 187.854 358.582 188.185 354.453L202.89 171.019Z"
                    stroke="#EAECF0"
                  />
                  <path
                    d="M202.89 171.019C203.221 166.89 206.837 163.805 210.967 164.128L523.989 188.617C528.119 188.94 531.198 192.549 530.867 196.678L529.628 212.131L201.652 186.472L202.89 171.019Z"
                    fill="white"
                  />
                  <rect
                    x="0.275115"
                    y="0.322439"
                    width="5.4"
                    height="5.4"
                    rx="2.7"
                    transform="matrix(0.996954 0.0779948 -0.0799045 0.996803 210.314 172.582)"
                    fill="#98A2B3"
                    stroke="#98A2B3"
                    strokeWidth="0.6"
                  />
                  <rect
                    x="0.275115"
                    y="0.322439"
                    width="5.4"
                    height="5.4"
                    rx="2.7"
                    transform="matrix(0.996954 0.0779948 -0.0799045 0.996803 219.287 173.284)"
                    fill="#98A2B3"
                    stroke="#98A2B3"
                    strokeWidth="0.6"
                  />
                  <rect
                    x="0.275115"
                    y="0.322439"
                    width="5.4"
                    height="5.4"
                    rx="2.7"
                    transform="matrix(0.996954 0.0779948 -0.0799045 0.996803 228.26 173.986)"
                    fill="#98A2B3"
                    stroke="#98A2B3"
                    strokeWidth="0.6"
                  />
                  <path
                    d="M202.89 171.019C203.221 166.89 206.837 163.805 210.967 164.128L523.989 188.617C528.119 188.94 531.198 192.549 530.867 196.678L529.628 212.131L201.652 186.472L202.89 171.019Z"
                    stroke="#EAECF0"
                  />
                  <rect
                    width="296.576"
                    height="88.0099"
                    rx="8"
                    transform="matrix(0.996954 0.0779948 -0.0799045 0.996803 216.987 204.225)"
                    fill="#F9FAFB"
                    stroke="#D0D5DD"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeDasharray="4 4"
                  />
                  <path
                    d="M211.877 309.012C209.675 308.84 207.746 310.486 207.57 312.688L206.93 320.662C206.754 322.864 208.396 324.789 210.599 324.961L346.184 335.569C348.387 335.741 350.315 334.095 350.492 331.893L351.131 323.919C351.308 321.717 349.665 319.792 347.463 319.62L211.877 309.012Z"
                    fill="#F9FAFB"
                  />
                  <path
                    d="M209.96 332.936C207.757 332.763 205.829 334.409 205.652 336.611L205.013 344.585C204.836 346.787 206.479 348.712 208.681 348.885L296.413 355.748C298.616 355.92 300.544 354.275 300.721 352.073L301.36 344.098C301.536 341.896 299.894 339.972 297.692 339.799L209.96 332.936Z"
                    fill="#F9FAFB"
                  />
                  <rect
                    width="117.416"
                    height="40.0045"
                    rx="4"
                    transform="matrix(0.996954 0.0779948 -0.0799045 0.996803 387.29 321.877)"
                    fill="#F2F4F7"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M328.953 274.146C337.763 274.835 345.477 268.253 346.183 259.445C346.889 250.636 340.319 242.937 331.51 242.248C322.7 241.559 314.986 248.141 314.28 256.949C313.574 265.757 320.143 273.456 328.953 274.146ZM323.687 250.526L330.299 251.044C334.886 251.402 338.307 255.412 337.939 259.999L337.659 263.502C336.376 258.164 331.109 254.375 326.05 253.387C330.617 255.759 333.5 260.753 334.301 266.366L330.797 266.092C326.21 265.733 322.789 261.724 323.157 257.137L323.687 250.526Z"
                    fill="#2E90FA"
                  />
                  <path
                    d="M354.303 262.108L368.539 263.221C368.704 262.588 368.831 261.951 368.888 261.233C369.334 255.679 365.723 250.948 360.134 250.51C354.206 250.047 349.817 254.076 349.366 259.706C348.915 265.336 352.569 269.995 358.913 270.491C362.538 270.775 365.488 269.523 367.469 267.092L363.723 264.518C362.805 265.549 361.231 266.224 359.343 266.076C356.775 265.875 354.783 264.655 354.303 262.108ZM354.53 258.323C355.291 255.949 357.178 254.69 359.783 254.894C361.822 255.053 363.773 256.309 364.159 259.076L354.53 258.323Z"
                    fill="#1D2939"
                  />
                  <path
                    d="M383.442 252.866L377.775 265.731L374.266 252.148L368.904 251.729L374.564 271.183L380.115 271.618L388.804 253.286L383.442 252.866Z"
                    fill="#1D2939"
                  />
                  <path
                    d="M403.286 254.419L403.108 256.648C401.884 254.841 399.93 253.624 397.174 253.408C392.151 253.015 387.649 257.036 387.198 262.666C386.747 268.296 390.552 272.967 395.574 273.36C398.331 273.575 400.455 272.677 401.95 271.083L401.772 273.312L406.643 273.693L408.157 254.8L403.286 254.419ZM396.853 268.783C393.87 268.549 391.815 266.221 392.069 263.047C392.324 259.873 394.724 257.893 397.707 258.127C400.728 258.363 402.783 260.691 402.529 263.865C402.275 267.039 399.874 269.019 396.853 268.783Z"
                    fill="#1D2939"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_d_272_123668"
                    x="0"
                    y="20.0015"
                    width="418.441"
                    height="299.746"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                  >
                    <feFlood
                      floodOpacity="0"
                      result="BackgroundImageFix"
                    />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feMorphology
                      radius="12"
                      operator="erode"
                      in="SourceAlpha"
                      result="effect1_dropShadow_272_123668"
                    />
                    <feOffset dy="24" />
                    <feGaussianBlur stdDeviation="24" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0.120313 0 0 0 0 0.299471 0 0 0 0 0.583333 0 0 0 0.08 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_272_123668"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_272_123668"
                      result="shape"
                    />
                  </filter>
                  <filter
                    id="filter1_d_272_123668"
                    x="151.047"
                    y="151.006"
                    width="416.958"
                    height="297.12"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                  >
                    <feFlood
                      floodOpacity="0"
                      result="BackgroundImageFix"
                    />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feMorphology
                      radius="12"
                      operator="erode"
                      in="SourceAlpha"
                      result="effect1_dropShadow_272_123668"
                    />
                    <feOffset dy="24" />
                    <feGaussianBlur stdDeviation="24" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0.120313 0 0 0 0 0.299471 0 0 0 0 0.583333 0 0 0 0.08 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_272_123668"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_272_123668"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg>
            </div>
          </div>
          {AllImgsLoaded && (
            <>
              <button
                onClick={() => dispatch(setViewportFullSize(false))}
                className={`absolute top-4 right-4 z-50 btn sm outline !p-2 transition-all duration-500${
                  ViewportSize ? "" : " translate-x-16"
                }`}
              >
                <Icon
                  name="minimize-2"
                  size="24"
                  color="#667085"
                />
              </button>

              {DomainType === "Corporate" ? (
                <CorpLivePreview config={{ ThemeConfig, ThemeConfigBeauty }} />
              ) : (
                <LivePreview config={{ ThemeConfig, ThemeConfigBeauty }} />
              )}
            </>
          )}
        </section>
      </main>
    </>
  )
}

export default ThemeSettings
