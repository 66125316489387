import React, { useEffect, useState } from "react"
import { useRef } from "react"
import { CheckPicker } from "rsuite"
import localization from "localization"
import FormHiddenInput from "./FormHiddenInput"
import { useFormContext } from "react-hook-form"
import { useDestinationParameterMutation } from "utils/useRules"
import { useDebouncedCallback } from "use-debounce"
import { useUpdateEffect } from "usehooks-ts"

const FormDestinationPicker = ({ item, name, onChange, conditionName }) => {
  const ref = useRef(null)
  const { setValue, getValues, resetField } = useFormContext()
  const { mutateAsync, isPending } = useDestinationParameterMutation()
  const [selectedValues, setSelectedValues] = useState([])
  const [pickerData, setPickerData] = useState([])

  const destinationTypes =
    Object.entries(item?.DestinationTypes || "").map((item) => ({
      label: item[1],
      value: item[0],
    })) || []

  const debounceCallback = useDebouncedCallback((val) => {
    if (val === "") return
    if (val.length < 3) return

    mutateAsync({ destinationTypes: destinationTypes.map((item) => item.label), term: val })
      .then((res) => {
        if (!res?.data.length) return

        setPickerData([
          ...selectedValues,
          ...res?.data
            .map((item) => ({
              label: item.name,
              value: item.id.toString(),
            }))
            .filter((item) => !selectedValues.map((item) => item.value).includes(item.value)),
        ])
      })
      .catch((err) => console.log(err))
  }, 800)

  useEffect(() => {
    if (!getValues(`${name}`) && !getValues(`${name}_Text`)) return

    const newPickerData = getValues(`${name}`)
      ?.split(",")
      .map((item, index) => {
        const textVal = getValues(`${name}_Text`)?.split("__")
        return {
          label: textVal[index] || item,
          value: item,
        }
      })

    setSelectedValues(newPickerData)
    setPickerData(newPickerData)
  }, [getValues, name])

  useUpdateEffect(() => {
    resetField(name, { defaultValue: "" })
  }, [conditionName])

  return (
    <div ref={ref}>
      <CheckPicker
        placeholder={item?.Title || "Seçim Yapınız"}
        name={name}
        container={ref.current}
        menuStyle={{ width: ref.current?.clientWidth + 20 }}
        block
        disabled={Object.entries(item?.DestinationTypes || "").length === 0}
        searchable={true}
        loading={isPending}
        searchBy={() => true}
        onSearch={debounceCallback}
        cleanable={false}
        data={pickerData || []}
        value={getValues(`${name}`)?.split(",")}
        locale={localization.TR.PickerLocaleType.Picker}
        onChange={
          // @param val: string | number
          (val) => {
            const selectedValues = pickerData.filter((item) => val.includes(item.value))
            setSelectedValues(selectedValues)
            onChange(val.join(","))
            setValue(`${name}_Text`, selectedValues.map((item) => item.label).join("__"))
          }
        }
      />
      <FormHiddenInput
        type="hidden"
        name={`${name}_Text`}
      />
    </div>
  )
}

export default FormDestinationPicker
