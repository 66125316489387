import * as type from "redux/types"

export const pageCloneRequested = (data) => ({
  type: type.PAGE_CLONE_REQUESTED,
  payload: data,
})

export const pageCloneSucceed = (data) => ({
  type: type.PAGE_CLONE_SUCCEEDED,
  payload: data,
})

export const pageCloneFailed = () => ({
  type: type.PAGE_CLONE_FAILED,
})
