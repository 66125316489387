import {IMAGE} from "../Enums";

export const deleteSliderItem = function (ThisComponent, slider, softDelete) {
	return new Promise(function (resolve, reject) {
		let children = ThisComponent?.children
		if (!children){
			reject("error")
		}

		if (!softDelete){
			if(children){
				const s = children?.find((child) => child?.code === slider?.code)
				const index = children.indexOf(s);
				if (index > -1) {
					children.splice(index, 1);
				}
			}
		} else {
			slider?.children && slider.children?.map((child) => {
				let image = child?.name === IMAGE
				if (image){
					const index = slider.children?.indexOf(child);
					if (index > -1) {
						slider?.children?.splice(index, 1);
					}
				}
			})
		}
		resolve(ThisComponent);
	});
};