import CommonAddLayout from "pages/Rules/components/FormLayouts/CommonAddLayout"
import React from "react"
import RuleForm from "pages/Rules/components/FormLayouts/RuleForm"

const AddConfirmationRule = (props) => {
  return (
    <CommonAddLayout
      {...props}
      title="Onay Kuralı Ekle"
      description="Ürününüzde çalıştırmak üzere sisteme onay kuralı tanımlayın."
    >
      <RuleForm {...RuleForm.PageMeta.requestApprove} />
    </CommonAddLayout>
  )
}

export default AddConfirmationRule
