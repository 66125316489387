import { call, fork, put, takeLatest } from "redux-saga/effects"
import * as actions from "redux/actions/addonDetail"

import { ADDON_DETAIL_REQUESTED } from "redux/types"
import { API_GET } from "helpers/api"

function* watchGetaddonDetail() {
  yield takeLatest(ADDON_DETAIL_REQUESTED, getAddonDetail)
}

function* getAddonDetail(action) {
  try {
    const res = yield call(apiGetAddonDetail, action.payload)
    yield put(actions.addonDetailSucceed(res?.data))
  } catch (err) {
    yield put(actions.addonDetailFailed())
  }
}

const apiGetAddonDetail = (id) => {
  return API_GET(`${process.env.REACT_APP_DISPLAY_DOMAIN}/api/addon/get/${id}`)
}

export default function* addonDetail() {
  yield fork(watchGetaddonDetail)
}
