import {Icon} from "../index";
import React from "react";

const AddBlock = (props) => {
	let {AddNewBlockHandleClick, setAddNewBlockHandleClick, index, setBlockListTab, setEditLayouts, style} = props


	return (
		<div className="relative z-10">
			<div
				style={style}
				className={`flex items-center justify-center bg-primary-25 after:absolute after:border-t-2 after:border-dashed after:w-full 
                    ${AddNewBlockHandleClick === index ? "border-2 border-dashed border-primary-400 py-6 after:border-transparent" : "py-3 after:border-gray-300"}
                    
                    `}>
				{AddNewBlockHandleClick !== index && (
					<div
						onClick={() => {
							console.log(index)
							if (AddNewBlockHandleClick === index) index = null
							setEditLayouts(null)
							setBlockListTab(1)
							setTimeout(() => setAddNewBlockHandleClick(index), 0)

						}}
						className="relative flex items-center gap-1 text-primary-500 bg-white py-2 px-3 text-sm font-medium rounded-full border border-gray-100 cursor-pointer z-10 transition-all duration-500 hover:border-primary-300">
						<Icon name="plus-circle" color="#2E90FA" size={20} />
						<p>Blok Ekle</p>
					</div>
				)}

				{AddNewBlockHandleClick === index && (
					<div
						className="relative flex flex-col items-center py-2 px-3 text-sm font-medium z-10">
						<p>Hazır bloklardan seçin ve ekle butonuna basın.</p>
						<p
							onClick={() => {
								setAddNewBlockHandleClick(null)
								setEditLayouts(null)

							}}
							className="text-primary-500 cursor-pointer py-2 px-3">Vazgeç</p>
					</div>
				)}
			</div>
		</div>
	)
}


export default AddBlock