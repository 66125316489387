import * as type from "redux/types"

export const getCustomDomainsNearExpirationRequested = (data) => ({
  type: type.GET_CUSTOM_DOMAINS_NEAR_EXPIRATION_REQUESTED,
  payload: data,
})

export const getCustomDomainsNearExpirationSucceed = (data) => ({
  type: type.GET_CUSTOM_DOMAINS_NEAR_EXPIRATION_SUCCEEDED,
  payload: data,
})

export const getCustomDomainsNearExpirationFailed = () => ({
  type: type.GET_CUSTOM_DOMAINS_NEAR_EXPIRATION_FAILED,
})
