import { useQuery } from "@tanstack/react-query"
import { API_GET } from "helpers/api"

export const useAddonSettingList = (addonId, enabled) => {
  const query = useQuery({
    queryKey: ["clientAddonSettingList", addonId],
    queryFn: () => API_GET(`${process.env.REACT_APP_DISPLAY_DOMAIN}/api/addonsetting/list/${addonId}`),
    enabled: enabled,
  })

  return query
}
