import React, { useState } from "react"

// layouts
import PartialWrapper from "../../../../PartialWrapper"

// components
import { RangeInput, Subtitle } from "components"

const AdvancedPositionLayout = (props) => {
  const { showSubLayout, setShowSubLayout } = props

  const [HorizontalPos, setHorizontalPos] = useState(50)
  const [VerticalPos, setVerticalPos] = useState(50)

  return (
    <PartialWrapper
      className={`advanced-position-layout${showSubLayout?.advancedPositionLayout ? " active" : ""}`}
      goBackOnClick={() => setShowSubLayout(null)}
    >
      <Subtitle>Advanced Position</Subtitle>

      <div className="flex flex-col gap-3 mt-4">
        <div className="input-wrapper">
          <RangeInput
            rangeLabel={"Horizontal Position"}
            rangeValue={HorizontalPos}
            min={12}
            max={72}
            onChange={(value) => {
              console.log(value)
              setHorizontalPos(value)
            }}
          />
        </div>
        <div className="input-wrapper">
          <RangeInput
            rangeLabel={"Vertical Position"}
            rangeValue={VerticalPos}
            min={12}
            max={72}
            onChange={(value) => {
              console.log(value)
              setVerticalPos(value)
            }}
          />
        </div>
      </div>
    </PartialWrapper>
  )
}

export default AdvancedPositionLayout
