import React, { useRef } from "react"
import { SelectPicker } from "rsuite"
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Label, ResponsiveContainer } from "recharts"

import Icon from "components/Icon"
import { data, options } from "./data"

const NumberOfGuests = () => {
  const selectPickerRef = useRef()

  return (
    <div className="border border-gray-200 rounded-lg p-6 flex flex-col gap-6 bg-white">
      <h2 className="text-xl text-gray-700 font-semibold">Misafir Sayısı ve Rezervasyon </h2>
      <div
        ref={selectPickerRef}
        className="flex justify-end relative"
      >
        <SelectPicker
          block
          size="lg"
          placement="bottomStart"
          placeholder={"Aylık"}
          defaultValue={"Aylık"}
          menuMaxHeight={164}
          cleanable={false}
          className="w-[154px]"
          searchable={false}
          data={options}
          container={() => selectPickerRef.current}
        />
      </div>
      <div className="flex gap-3 items-center justify-end">
        <div className="flex gap-2 items-center">
          <Icon
            name="dot"
            size="10"
            color="#84CAFF"
          />
          <p className="text-gray-500 text-sm">Misafir Sayısı</p>
        </div>
        <div className="flex gap-2 items-center">
          <Icon
            name="dot"
            size="10"
            color="#2E90FA"
          />
          <p className="text-gray-500 text-sm">Rezervasyon Sayısı </p>
        </div>
      </div>

      <div className="mb-20">
        <ResponsiveContainer height={376}>
          <BarChart
            data={data}
            margin={{
              top: 5,
              right: 10,
              bottom: 20,
            }}
          >
            <CartesianGrid
              stroke="#F2F4F7"
              strokeDasharray="0"
              vertical={false}
            />
            <XAxis
              dataKey="name"
              axisLine={false}
              minTickGap={0}
              tickLine={false}
              padding={{ left: 20, right: 20 }}
              style={{
                fontSize: "12px",
                color: "#667085",
              }}
            >
              <Label
                className="text-sm text-gray-500 font-medium"
                value="Ay"
                position="bottom"
              />
            </XAxis>
            <YAxis
              axisLine={false}
              tickLine={false}
              padding={{ left: 30, right: 30 }}
              style={{
                fontSize: "12px",
                color: "#667085",
              }}
            />
            <Tooltip cursor={{ fill: "transparent" }} />
            <Bar
              dataKey="pv"
              stackId="b"
              fill="#2E90FA"
              barSize={16}
            />
            <Bar
              dataKey="uv"
              stackId="b"
              fill="#84CAFF"
              radius={[15, 15, 0, 0]}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

export default NumberOfGuests
