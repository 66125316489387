import * as type from "redux/types"

export const getStrategyRequested = (data) => ({
  type: type.STRATEGY_GET_REQUESTED,
  payload: data,
})

export const getStrategySucceed = (data) => ({
  type: type.STRATEGY_GET_SUCCEEDED,
  payload: data,
})

export const getStrategyFailed = () => ({
  type: type.STRATEGY_GET_FAILED,
})
export const saveStraregyRequested = (data) => ({
  type: type.STRATEGY_POST_REQUESTED,
  payload: data,
})

export const saveStraregySucceed = (data) => ({
  type: type.STRATEGY_POST_SUCCEEDED,
  payload: data,
})

export const saveStraregyFailed = () => ({
  type: type.STRATEGY_POST_FAILED,
})
