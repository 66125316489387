import * as type from "redux/types"

export const clientConfigRequested = (data) => ({
  type: type.CLIENT_CONFIG_REQUESTED,
  payload: data,
})

export const clientConfigSucceed = (data) => ({
  type: type.CLIENT_CONFIG_SUCCEEDED,
  payload: data,
})

export const clientConfigFailed = () => ({
  type: type.CLIENT_CONFIG_FAILED,
})
