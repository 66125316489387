import * as type from "redux/types"

export const pageSaveMainRequested = (data) => ({
  type: type.PAGE_SAVE_MAIN_REQUESTED,
  payload: data,
})

export const pageSaveMainSucceed = (data) => ({
  type: type.PAGE_SAVE_MAIN_SUCCEEDED,
  payload: data,
})

export const pageSaveMainFailed = () => ({
  type: type.PAGE_SAVE_MAIN_FAILED,
})
