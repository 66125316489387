import React, { useState, useEffect } from "react"
import { Toggle } from "rsuite"

import {Alert} from "components"
import { LogoUpload } from "components"

const DocumentAlert = ({
  ShowDocumentAlert,
  rejectButtonClick,
  approveButtonClick,
  documentNumberValue,
  setDocumentNumberValue,
  tursabValue,
  ToggleDigitalVerification,
  setToggleDigitalVerification,
  tursabLogo,
  setTursabLogo,
  config: { ThemeConfig },
  setConfig,
}) => {
  const isTursabValueNotEmpty = Boolean(tursabValue.value)
  const isToggleDisabled = !documentNumberValue && !isTursabValueNotEmpty
  const [isChecked, setIsChecked] = useState(ToggleDigitalVerification)

  const handleInputChange = (e) => {
    const inputValue = e.target.value
    const alphanumericValue = inputValue.replace(/[^a-zA-Z0-9]/g, "")
    e.target.value = alphanumericValue
    setDocumentNumberValue(alphanumericValue)
    if (!alphanumericValue) {
      setIsChecked(false)
      setToggleDigitalVerification(false)
    }
  }

  const handleToggleChange = () => {
    if (!isToggleDisabled && documentNumberValue) {
      setToggleDigitalVerification((prevState) => !prevState)
      setIsChecked((prevState) => !prevState)
    }
  }

  useEffect(() => {
    setDocumentNumberValue(tursabValue.value)
  }, [setDocumentNumberValue, tursabValue.value])

  useEffect(() => {
    setIsChecked(ToggleDigitalVerification)
  }, [ToggleDigitalVerification])

  return (
    <>
      {ShowDocumentAlert && (
        <Alert
          title="TÜRSAB Belge"
          description="Aşağıdaki bilgiler ürününüzün footer kısmında son kullanıcılarınız ile TÜRSAB belge bilgisi olarak paylaşılacaktır."
          rejectButtonText="Vazgeç"
          approveButtonText="Kaydet"
          rejectButtonOnClick={rejectButtonClick}
          approveButtonOnClick={approveButtonClick}
          iconName=""
          className="w-[600px]"
        >
          <div className="mt-6">
            <div className="col mb-6 last:mb-0">
              <p className="text-sm font-semibold pb-0.5">
                <span className="text-blue-500">*</span>Belge Numarası
              </p>
              <div className="text-gray-500">
                <div className="mt-1.5">
                  <input
                    type="tel"
                    name="phone"
                    className="w-full"
                    defaultValue={documentNumberValue || tursabValue.value}
                    onChange={handleInputChange}
                    placeholder="Belge numarası girin"
                  />
                </div>
              </div>
            </div>
            <div className="col mb-6 last:mb-0">
              <p className="text-sm font-semibold pb-0.5">Logo</p>
              <div className="text-gray-500">
                <div className="mt-1.5">
                  {/* <pre className="w-full overflow-hidden">{JSON.stringify(ThemeConfig, null, 4)}</pre> */}
                  <LogoUpload
                    accept={".jpg, .png"}
                    showText="false"
                    title={false}
                    description={"JPG, PNG formatlarında yüklenmelidir (maks. 2MB)"}
                    setConfig={(config) => setConfig(config)}
                    config={{
                      ThemeConfig,
                    }}
                    tursabLogo={tursabLogo}
                    setTursabLogo={setTursabLogo}
                  />
                </div>
              </div>
            </div>
            <div className="col mb-6 last:mb-0">
              <div className="text-gray-500">
                <div
                  className="flex items-start cursor-pointer select-none"
                  onClick={handleToggleChange}
                >
                  <div className="mr-2">
                    <Toggle
                      className="mr-2 pointer-events-none"
                      size="md"
                      checked={isChecked}
                    />
                  </div>

                  <div className="text-sm">
                    <p className="text-gray-800 font-medium">Dijital doğrulamam var</p>
                    <p>
                      Dijital doğrulama ile acentenizin TÜRSAB’a kayıtlı olup olmadığını doğrulayabilirsiniz ve dijital doğrulama
                      linki ile müşterilerinizi TÜRSAB’ın doğrulama sayfasına yönlendirebilirsiniz.
                    </p>
                  </div>
                </div>
                <p className="mt-8">
                  <span className="text-blue-500">*</span> ile işaretli alanlar zorunludur.
                </p>
              </div>
            </div>
          </div>
        </Alert>
      )}
    </>
  )
}

export default DocumentAlert
