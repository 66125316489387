/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

// utils

// components
import { Dropdown, Whisper } from "rsuite"
import { LoadBar, Icon, ErrorMessage } from "components"
import {CategoryList, IMAGE, TAB_SLIDER} from "pages/PageManager/Enums";
import {ReactSortable} from "react-sortablejs";

//helpers
import {array_move} from "helpers/ArrayMove";
import {deleteSliderItem} from "pages/PageManager/helpers/DeleteSliderItem";
import {UpdateStrategy} from "pages/PageManager/helpers/UpdateStrategy";
/*import {getSameCategoryItems} from "pages/PageManager/helpers/getSameCategoryItems";*/
import {groupItems} from "pages/PageManager/helpers/groupItems";

// saga requests
import {
  categoryDeleteSucceed,
  categoryListRequested,
  categorySaveSucceed,
  categoryUpdateSucceed
} from "redux/actions/pageManager"
import {getStrategySucceed} from "redux/actions/strategy";

const CategorySlides = (props) => {
  let { activeLayout, setEditBlock, ThisComponent, setThisComponent, ActiveCategory, setActiveCategory, categoryList, subComponentIdList } = props

  const dispatch = useDispatch()

  // selectors
  const categoryTypesLoading = useSelector((state) => state.pageManager.categoryList.loading)
  const categoryTypesError = useSelector((state) => state.pageManager.categoryList.error)
  const categorySaveResponse = useSelector((state) => state.pageManager.categorySave.response)
  const categoryDeleteResponse = useSelector((state) => state.pageManager.categoryDelete.response)
  const categoryUpdateResponse = useSelector((state) => state.pageManager.categoryUpdate.response)
  const mediaListLoading = useSelector((state) => state.mediaLibrary.mediaLibraryList.loading)
  const components = useSelector(state => state.strategy.getStrategy.list?.result)

  // states
  const [SlideListDdownOpen, setSlideListDdownOpen] = useState({})
  const [ShowCategoriesDropdown, setShowCategoriesDropdown] = useState(false)
  const [SliderCategoryTypes, setSliderCategoryTypes] = useState(null)
  const [ActiveCategorySliderList, setActiveCategorySliderList] = useState(null)
  const [ActiveCategorySliderListUpdated, setActiveCategorySliderListUpdated] = useState(false)

  // Func
  const blockListDropDownToggle = (index, show) => {
    setSlideListDdownOpen({
      index,
      show,
    })
  }
  const renderSlidesDropdown = ({ onClose, left, top, className }, ref, index, slider) => {
    const listLenght = ActiveCategorySliderList.length
    const handleSelect = (eventKey) => {
      if (eventKey) {
        onClose()
        switch (eventKey) {
          case "edit":
            setSlideListDdownOpen({})
            setEditBlock({
              categorySlide: index + 1,
              slider,
              categoryInfo: ActiveCategory,
            })
            break
          case "move-up":
            array_move(ActiveCategorySliderList, index, index-1)
            activeSliderListSortingChanged()
            break
          case "move-down":
            array_move(ActiveCategorySliderList, index, index+1)
            activeSliderListSortingChanged()
            break
          case "delete":
            setSlideListDdownOpen({})
            deleteSliderItem(ThisComponent, slider, false).then(response => {
              if (response){
                const strategy = UpdateStrategy(components, response)
                dispatch(getStrategySucceed(strategy))
                getMediaListByCategoryId()
              }
            })
            break
          default:
            break
        }
      }
    }
    return (
      <Dropdown.Menu
        ref={ref}
        className={`${className} min-w-[190px] max-w-[240px]`}
        style={{ left, top }}
        onSelect={handleSelect}
      >
        <Dropdown.Item className="font-medium py-2.5 px-4 cursor-default !bg-white !text-gray-800 hover:!bg-white hover:text-gray-800">
          Seçenekler
        </Dropdown.Item>
        <hr />
        <Dropdown.Item
          disabled={index === 0}
          className="flex gap-3 items-center py-2.5 px-4"
          eventKey="move-up"
        >
          <Icon
            name="arrow-up"
            size={16}
            color={index === 0 ? "#c5c6c7" : "#1D2939"}
          />
          <p>Yukarı Taşı</p>
        </Dropdown.Item>
        <Dropdown.Item
          disabled={listLenght - 1 === index}
          className="flex gap-3 items-center py-2.5 px-4"
          eventKey="move-down"
        >
          <Icon
            name="arrow-down"
            size={16}
            color={listLenght - 1 === index ? "#c5c6c7" : "#1D2939"}
          />
          <p>Aşağı Taşı</p>
        </Dropdown.Item>
        <Dropdown.Item
          className="flex gap-3 items-center py-2.5 px-4"
          eventKey="edit"
        >
          <Icon
            name="edit-2"
            size={16}
            color="#1D2939"
          />
          <p>Düzenle</p>
        </Dropdown.Item>
        {/*<Dropdown.Item
          className="flex gap-3 items-center py-2.5 px-4"
          eventKey={1}
        >
          <Icon
            name="copy"
            size={16}
            color="#1D2939"
          />
          <p>Kopyala</p>
        </Dropdown.Item>*/}
        <Dropdown.Item
          className="flex gap-3 items-center py-2.5 px-4"
          eventKey="delete"
        >
          <Icon
            name="trash"
            size={16}
            color="#1D2939"
          />
          <p>Sil</p>
        </Dropdown.Item>
      </Dropdown.Menu>
    )
  }
  const renderCategoriesDropdown = ({ onClose, left, top, className }, ref) => {
    const handleSelect = (eventKey) => {
      if (eventKey) {
        let activeCategory = SliderCategoryTypes.filter((cat) => cat?.Id === eventKey)
        setActiveCategory(...activeCategory)
        onClose()
      }
      switch (eventKey) {
        case "add_category":
          setEditBlock({
            category_settings: true,
            type: "category_add",
          })
          break
        default:
          return false
      }
    }
    return (
      <Dropdown.Menu
        ref={ref}
        className={`${className} w-[298px] mt-1`}
        style={{ left, top, maxHeight: `290px`, overflow: "auto", padding: 0 }}
        onSelect={handleSelect}
      >
        <Dropdown.Item className="sticky top-0 border-b font-medium py-3 px-4 cursor-default z-10 !bg-white !text-gray-800 hover:!bg-white hover:text-gray-800">
          Kategoriler
        </Dropdown.Item>

        {categoryList.map((categoryType, index) => {
          return (
            <Dropdown.Item
              key={index}
              eventKey={categoryType?.Id}
              className="flex items-center py-3 hover:bg-gray-50 hover:font-medium hover:text-gray-800"
            >
              <span className={categoryType?.Id === ActiveCategory?.Id ? "text-blue-500" : ""}>{categoryType.Name}</span>
              {index === 0 && <span className="text-gray-500 text-xs ml-3 mt-[0.1rem]">Birincil</span>}
              {categoryType.Status && (
                <Icon
                  onClick={() => {
                    setEditBlock({
                      category_settings: true,
                      type: "category_edit",
                      categoryInfo: categoryType,
                    })
                  }}
                  className="ml-auto"
                  name="settings"
                  size={16}
                  color="#475467"
                />
              )}
            </Dropdown.Item>
          )
        })}
        <Dropdown.Item
          eventKey={"add_category"}
          className="sticky bottom-0 border-t z-10 !bg-white !text-blue-500 text-center py-3"
        >
          Yeni kategori ekle
        </Dropdown.Item>
      </Dropdown.Menu>
    )
  }
  const categoriesDropdownToggle = (show) => {
    setShowCategoriesDropdown(show)
  }
  const getMediaListByCategoryId = () => {
    if (ThisComponent?.children) {
      let mediaData = []
      /*let mediaListByCategoryId = getSameCategoryItems(ThisComponent, ActiveCategory)*/
      const groupedByCategyId = groupItems(ThisComponent?.children, "categoryId")
      if (groupedByCategyId && groupedByCategyId.length){
        mediaData = []
        groupedByCategyId.map((item) => {
          const image = item?.children && item?.children?.find(child => child?.name === IMAGE)
          mediaData.push({
            ...item,
            media: image?.data,
          })
        })
      }
      if (mediaData && mediaData?.length){
        setActiveCategorySliderList(mediaData)
      } else {
        setActiveCategorySliderList(null)
      }
    }
  }
  const activeSliderListSortingChanged = () => {
    setActiveCategorySliderListUpdated(true)
  }
  // effects
  useEffect(() => {
    if (activeLayout?.[TAB_SLIDER] && (!categoryList || categorySaveResponse || categoryDeleteResponse || categoryUpdateResponse || (categoryList && categoryList[0]?.Type !== CategoryList.TAB_SLIDER))) {
      if (categorySaveResponse) dispatch(categorySaveSucceed(null))
      if (categoryDeleteResponse) dispatch(categoryDeleteSucceed(null))
      if (categoryUpdateResponse) dispatch(categoryUpdateSucceed(null))
      dispatch(categoryListRequested(CategoryList.TAB_SLIDER))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLayout])
  useEffect(() => {
    if (ThisComponent && categoryList && ActiveCategory) {
      getMediaListByCategoryId()
    }
  }, [ThisComponent, ActiveCategory, components])
  useEffect(() => {
    if (categoryList) {
      let orderedCategoryTypes = categoryList.sort((a, b) => (a.Order > b.Order ? 1 : -1))
      setSliderCategoryTypes(orderedCategoryTypes)
    }
  }, [categoryList])
  useEffect(() => {
    if (SliderCategoryTypes){
      if (!ActiveCategory){
        setActiveCategory(SliderCategoryTypes[0])
      }
    }
  }, [ActiveCategory, SliderCategoryTypes])
  // drag and drop
  useEffect(() => {
    if (ActiveCategorySliderList && ActiveCategorySliderListUpdated) {
      setActiveCategorySliderListUpdated(false)
      ThisComponent.children = ActiveCategorySliderList
      setThisComponent({
        ...ThisComponent
      })
    }
  }, [ActiveCategorySliderList, ActiveCategorySliderListUpdated])
  useEffect(() => {
    if (ThisComponent?.children && ActiveCategorySliderListUpdated){
      const strategy = UpdateStrategy(components, ThisComponent)
      dispatch(getStrategySucceed(strategy))
    }

  }, [ThisComponent, ActiveCategorySliderListUpdated])
  return (
    <div className={"flex flex-col gap-4"}>
      <h6 className="text-gray-500 font-semibold">SLIDES</h6>
      <div className="input-wrapper relative z-10">
        <span className="text-gray-800 font-medium">Kategoriler</span>

        {categoryTypesLoading && !ActiveCategorySliderList && (
          <div className="py-4"><LoadBar pathFill={"#2E90FA"} /></div>
        )}

        {categoryTypesError && !categoryTypesLoading && (
          <ErrorMessage className="mt-1">Kategori listesi yuklenirken bir hata ile karsilasildi.</ErrorMessage>
        )}

        {!categoryTypesError && !!categoryList?.length && (
          <Whisper
            className={"relative"}
            placement="bottomEnd"
            trigger="click"
            disabled={categoryTypesLoading}
            speaker={renderCategoriesDropdown}
            onOpen={() => categoriesDropdownToggle(true)}
            onClose={() => categoriesDropdownToggle(false)}
          >
            <div className="w-full flex items-center gap-2 cursor-pointer border border-gray-200 rounded-lg px-3.5 py-2.5 mt-1.5">
              {categoryTypesLoading && (
                <span className="mr-auto">
                  <LoadBar pathFill={"#2E90FA"} />
                </span>
              )}
              {!categoryTypesLoading && !categoryTypesError && (
                <span className="text-gray-800 pointer-events-none font-medium mr-auto">{ActiveCategory?.Name}</span>
              )}
              <div
                className={`icon-area flex items-center justify-center transition duration-200 ease-linear${
                  ShowCategoriesDropdown ? " rotate-180" : ""
                }`}
              >
                <Icon
                  name={"chevron-down"}
                  size="20"
                  color="#667085"
                />
              </div>
            </div>
          </Whisper>
        )}

        {!categoryList?.length && !categoryTypesLoading && !categoryTypesError && (
          <div className="mt-2">
            <p className="text-sm">
              Henüz sayfaya ait kategori listesi bulunmamaktadır, aşağıdaki butona tıklayarak kategori ekleyebilirsiniz.
            </p>
            <button
              onClick={(e) =>
                setEditBlock({
                  category_settings: true,
                  type: "category_add",
                })
              }
              className="btn secondary xs w-full py-4 mt-2 !h-auto rounded-none hover:bg-gray-100"
            >
              <Icon
                name={"plus"}
                size={20}
                color={"#2E90FA"}
              />
              <span>Kategori Ekle</span>
            </button>
          </div>
        )}
      </div>


      {!!categoryList?.length && !categoryTypesLoading && !categoryTypesError && (
        <>
          {!ActiveCategorySliderList && !mediaListLoading && (
            <ErrorMessage>
              <b>`{ActiveCategory?.Name}`</b> isimli kategoriye ait slider listesi yoktur
            </ErrorMessage>
          )}
          <div
            hidden={!ActiveCategorySliderList || mediaListLoading}
            className="border border-gray-200 rounded-lg overflow-auto max-h-[40vh]"
          >
            {ActiveCategorySliderList && !categoryTypesError && (
              <ReactSortable
                onUpdate={activeSliderListSortingChanged}
                tag="div" list={ActiveCategorySliderList} setList={setActiveCategorySliderList}>
                  {ActiveCategorySliderList.map((slider, index) => {
                    let visible = false
                    if (slider?.data?.category?.categoryId !== ActiveCategory?.Id){
                      visible = true
                    }
                    return (
                        <div
                          hidden={visible}
                          key={index}
                          className="group relative flex items-center gap-2 py-4 px-3 border-b border-gray-200 cursor-move transition hover:bg-gray-100 select-none last:border-0"
                        >
                          <div className="inline-flex shrink-0 items-center transition-all duration-200 w-0 overflow-hidden group-hover:w-[14px]">
                            <Icon
                              className="min-w-[14px]"
                              name="drag"
                              size={14}
                              color="#1D2939"
                            />
                          </div>
                          {(slider?.media?.src) && (
                            <img
                              className={"shrink-0 rounded"}
                              width={38}
                              height={28}
                              src={slider?.media?.src}
                              alt={"placeholder img"}
                            />
                          )}

                          {slider?.visual?.style?.backgroundColor && !(slider?.media?.src) && (
                            <div
                              style={{
                                width: 38,
                                height: 28,
                                backgroundColor: slider?.visual?.style?.backgroundColor
                              }}
                              className={"shrink-0 rounded"}
                            />
                          )}
                          <p
                            className="mr-auto text-sm text-gray-800 truncate"
                            title={slider?.media?.name}
                          >
                            {slider?.media?.name || slider?.media?.src?.split("/")?.pop() || slider?.visual?.style?.backgroundColor}
                          </p>

                          <Whisper
                            placement="bottomEnd"
                            trigger="click"
                            speaker={({ onClose, left, top, className }, ref) =>
                              renderSlidesDropdown({ onClose, left, top, className }, ref, index, slider)
                            }
                            onOpen={() => blockListDropDownToggle(index, true)}
                            onClose={() => blockListDropDownToggle(index, false)}
                          >
                            <div className="cursor-pointer">
                              <Icon
                                name="more-vertical"
                                size={14}
                                className="!duration-[100ms]"
                                color={SlideListDdownOpen?.index === index && SlideListDdownOpen?.show ? "#2E90FA" : "#1D2939"}
                              />
                            </div>
                          </Whisper>
                        </div>
                      )
                    })}
              </ReactSortable>
              )}
          </div>
          <button
            disabled={categoryTypesError}
            onClick={(e) =>
              setEditBlock({
                categorySlide: "addNewSlide",
                categoryInfo: ActiveCategory,
                itemInfo: subComponentIdList
              })
            }
            className="btn secondary xs w-full py-4 !h-auto rounded-none border-b hover:bg-gray-100 last:border-0"
          >
            <Icon
              name={"plus"}
              size={20}
              color={"#2E90FA"}
            />
            <span>Slide Ekle</span>
          </button>
        </>
      )}
    </div>
  )
}

export default CategorySlides
