import * as type from "redux/types"

export const profileImageRequested = (data) => ({
  type: type.PROFILE_IMAGE_REQUESTED,
  payload: data,
})

export const profileImageSucceed = (data) => ({
  type: type.PROFILE_IMAGE_SUCCEEDED,
  payload: data,
})

export const profileImageFailed = () => ({
  type: type.PROFILE_IMAGE_FAILED,
})
