import React from "react"
import { Icon } from "../index"
import PropTypes from "prop-types"

const Tab = (props) => {
  let { label, value, className, itemActiveClass, onClick, active, disabled, iconSize } = props
  return (
    <li
      key={value}
      onClick={!disabled ? onClick : undefined}
      disabled={disabled}
      className={`${className} [&>*]:pointer-events-none select-none cursor-pointer ${
        active === value ? itemActiveClass : "bg-white"
      }${disabled ? " opacity-50 !cursor-not-allowed" : ""}`}
    >
      {label.icon && (
        <Icon
          name={label.icon}
          size={iconSize}
          color="#475467"
        />
      )}
      <span>{label.label}</span>
    </li>
  )
}

export default Tab

Tab.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.object.isRequired,
  className: PropTypes.string,
  active: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  iconSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  itemActiveClass: PropTypes.string,
}

Tab.defaultProps = {
  className: "",
  label: [],
  active: 0, // default selected first item on the list
  itemActiveClass: "!text-gray-800 !bg-blue-50 font-medium", // default selected active class
}
