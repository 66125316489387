import React, { useState } from "react"
import PropTypes from "prop-types"

import { Slider } from "rsuite"
import {Icon} from "../index";
import {closestSize} from "../../helpers/closestSize";

const RadiusBox = (props) => {
  let { title, onChange, onRangeChange, ActiveButtonRadius, rangeLabel, showRangeArea, defaultRangeValue, rangeValue, min, max } = props

  const [RadiusList] = useState([0, 6, 24])
  const [ShowRangeArea, setShowRangeArea] = useState(showRangeArea)

  return (
    <div className="radius-box">
      <div className="border border-gray-200 p-3 rounded-lg">
        <div className="flex items-center justify-between">
          <span className="text-sm">{title}</span>
          <div className="flex justify-center text-center border border-gray-200 rounded-lg">
            {RadiusList.map((radius, key) => {
              return (
                <span
                  className={`group flex items-center justify-center w-full ${
                    closestSize(ActiveButtonRadius, RadiusList) === radius ? "bg-blue-100" : ""
                  } border-l border-gray-200 cursor-pointer capitalize first:border-l-0`}
                  key={key}
                  onClick={() => onChange(radius)}
                >
                  <span
                    className={`border-t-2 border-r-2 border-gray-400 p-2 m-2.5 transition group-hover:border-gray-600 ${closestSize(ActiveButtonRadius, RadiusList) === radius ? "border-gray-600" : ""}`}
                    style={{ borderTopRightRadius: radius }}
                  />
                </span>
              )
            })}


            <span
              className={`group flex items-center justify-center px-2.5 w-full border-l border-gray-200 cursor-pointer first:border-l-0 transition 
              ${ShowRangeArea ? "bg-blue-100 opacity-100" : "opacity-70"}
               hover:opacity-100`}
              onClick={() => setShowRangeArea(!ShowRangeArea)}
            >
              <Icon name="ellipsis" size={16} color="#575757" />
            </span>
          </div>
        </div>

        {ShowRangeArea && (
          <>
            <hr className="my-4" />
            <div className="range-input">
              <div className="range-title flex justify-between text-sm mb-2">
                <span>{rangeLabel}</span>
                <span className="text-blue-500 font-medium">{rangeValue}</span>
              </div>

              <Slider
                progress
                defaultValue={defaultRangeValue}
                value={rangeValue}
                tooltip={false}
                min={min}
                max={max}
                onChange={(value) => {
                  onRangeChange(value)
                }}
              />

              <div className="range-indicators flex justify-between text-sm font-medium text-gray-500 mt-1">
                <span className="min">{min}</span>
                <span className="max">{max}</span>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default RadiusBox

RadiusBox.prototypes = {
  title: PropTypes.string,
  rangeLabel: PropTypes.string,
  ActiveButtonRadius: PropTypes.number,
  defaultRangeValue: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func,
  showRangeArea: PropTypes.bool,
}

RadiusBox.defaultProps = {
  title: "Köşeler",
  rangeLabel: "Köşeler (px)",
  ActiveButtonRadius: 0,
  defaultRangeValue: undefined,
  rangeValue: undefined,
  showRangeArea: false,
  min: 0,
  max: 100,
  //onChange: (e) => console.log(e),
}
