export const AddTPPattern = (model, thisComponent, mediaId) => {
  console.log({
    model,
    thisComponent,
    mediaId,
  })
  if (!model || !Object.values(model)?.length) {
      return false
  }

  //translateItems
  // designField listesi icinde bulunan itemlar`in relation key`i "Data" olani getirir
  const isRelationData = model?.find((item) => item?.relation === "Data")
  const isRelationVisual = model?.find((item) => item?.relation === "Visual")
  const translateItems = isRelationData?.translateItems
  const dataInput = isRelationData?.input
  const visualInput = isRelationVisual?.input

  let title = thisComponent?.data?.title
  let subTitle = thisComponent?.data?.subTitle


  if (isRelationVisual && thisComponent?.visual){
      visualInput.style = {
        ...visualInput.style,
        ...thisComponent?.visual?.style
      }
      visualInput.settings = {
        ...visualInput.settings,
        ...thisComponent?.visual?.settings
      }
    }


    if (isRelationData) {
       translateItems.map(item => {
            if (item?.langKey === "TitleLangKey"){
                item.value = title || item.value
                item.name = title || item.name
            } else if (item?.langKey === "SubTitleLangKey"){
                item.value = subTitle || item.value
                item.name = subTitle || item.name
            }
        })

      if (thisComponent?.data?.backgroundImagePath === 0){
        delete dataInput.backgroundMediaId
      } else {
        dataInput.backgroundMediaId = mediaId || dataInput.backgroundMediaId
      }

        dataInput.titleStyle = {
         ...dataInput.titleStyle,
          ...thisComponent?.data?.titleStyle
        }
        dataInput.subTitleStyle = {
         ...dataInput.subTitleStyle,
         ...thisComponent?.data?.subTitleStyle
        }

        return model
    }
}

export default AddTPPattern
