
export const deleteIconListItem = function (ThisComponent, id, deleteOnlyImage) { // delete only image or Completely item delete

	console.log(id);
	return new Promise(function (resolve, reject) {
		let children = ThisComponent?.children
		if (!children){
			reject("error")
		}

		if (!deleteOnlyImage){ // completely delete
			const filteredChilds = children.filter(item => {
				let itemId = item?.data?.id || item?.data?.mediaPath // || slider?.itemInfo?.id
				return itemId !== id
			})
			if (JSON.stringify(children) !== JSON.stringify(filteredChilds)){
				ThisComponent.children = filteredChilds
			}
		} else {
			let itemIndex = null
			let item = children?.find((item, i) => {
				console.log(item);
				let itemId = item?.data?.id || item?.mediaPath
				console.log(itemId);
				if (itemId === id){
					itemIndex = i
					return itemId === id
				}
			})
			item = {
				...item,
				data: {
					...item?.data,
					mediaPath: undefined,
					mediaDetails: undefined
				}
			}
			if(children) {
				console.log(children);
				children[itemIndex] = item
			}
		}
		resolve(ThisComponent);
	});
};