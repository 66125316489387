const getInfoFromChildren = (jsonData) => {
  const result = jsonData
  if (result?.children) {
    const info = []
    const children = result.children
    for (let i = 0; i < children.length; i++) {
      info.push(children[i])
    }
    return info
  } else {
    return [result]
  }
}

export default getInfoFromChildren
