import * as type from "redux/types"

const initialState = {
  categoryList: {
    list: null,
    loading: false,
    error: false,
  },
  categoryGet: {
    list: null,
    loading: false,
    error: false,
  },
  categorySave: {
    list: null,
    loading: false,
    error: false,
  },
  categoryUpdate: {
    list: null,
    loading: false,
    error: false,
  },
  categoryDelete: {
    list: null,
    loading: false,
    error: false,
  },
  componentsList: {
    list: null,
    loading: false,
    error: false,
  },
  addAtomPage: {
    result: null,
    loading: false,
    error: false,
  },
}

const pageManager = (state = initialState, action) => {
  switch (action.type) {
    case type.CATEGORY_LIST_REQUESTED:
      return {
        ...state,
        categoryList: {
          list: null,
          loading: true,
          error: false,
        },
      }
    case type.CATEGORY_LIST_SUCCEEDED:
      return {
        ...state,
        categoryList: {
          list: action.payload,
          loading: false,
          error: false,
        },
      }
    case type.CATEGORY_LIST_FAILED:
      return {
        ...state,
        categoryList: {
          list: null,
          loading: false,
          error: true,
        },
      }
    case type.CATEGORY_GET_REQUESTED:
      return {
        ...state,
        categoryGet: {
          response: null,
          loading: true,
          error: false,
        },
      }
    case type.CATEGORY_GET_SUCCEEDED:
      return {
        ...state,
        categoryGet: {
          response: action.payload,
          loading: false,
          error: false,
        },
      }
    case type.CATEGORY_GET_FAILED:
      return {
        ...state,
        categoryGet: {
          response: null,
          loading: false,
          error: true,
        },
      }
    case type.CATEGORY_SAVE_REQUESTED:
      return {
        ...state,
        categorySave: {
          response: null,
          loading: true,
          error: false,
        },
      }
    case type.CATEGORY_SAVE_SUCCEEDED:
      return {
        ...state,
        categorySave: {
          response: action.payload,
          loading: false,
          error: false,
        },
      }
    case type.CATEGORY_SAVE_FAILED:
      return {
        ...state,
        categorySave: {
          response: null,
          loading: false,
          error: true,
        },
      }
    case type.CATEGORY_UPDATE_REQUESTED:
      return {
        ...state,
        categoryUpdate: {
          response: null,
          loading: true,
          error: false,
        },
      }
    case type.CATEGORY_UPDATE_SUCCEEDED:
      return {
        ...state,
        categoryUpdate: {
          response: action.payload,
          loading: false,
          error: false,
        },
      }
    case type.CATEGORY_UPDATE_FAILED:
      return {
        ...state,
        categoryUpdate: {
          response: null,
          loading: false,
          error: true,
        },
      }
    case type.CATEGORY_DELETE_REQUESTED:
      return {
        ...state,
        categoryDelete: {
          response: null,
          loading: true,
          error: false,
        },
      }
    case type.CATEGORY_DELETE_SUCCEEDED:
      return {
        ...state,
        categoryDelete: {
          response: action.payload,
          loading: false,
          error: false,
        },
      }
    case type.CATEGORY_DELETE_FAILED:
      return {
        ...state,
        categoryDelete: {
          response: null,
          loading: false,
          error: true,
        },
      }
    case type.COMPONENTS_LIST_REQUESTED:
      return {
        ...state,
        componentsList: {
          list: null,
          loading: true,
          error: false,
        },
      }
    case type.COMPONENTS_LIST_SUCCEEDED:
      return {
        ...state,
        componentsList: {
          list: action.payload,
          loading: false,
          error: false,
        },
      }
    case type.COMPONENTS_LIST_FAILED:
      return {
        ...state,
        componentsList: {
          list: null,
          loading: false,
          error: true,
        },
      }
    case type.ADD_ATOM_PAGE_REQUESTED:
      return {
        ...state,
        addAtomPage: {
          result: null,
          loading: true,
          error: false,
        },
      }
    case type.ADD_ATOM_PAGE_SUCCEEDED:
      return {
        ...state,
        addAtomPage: {
          result: action.payload,
          loading: false,
          error: false,
        },
      }
    case type.ADD_ATOM_PAGE_FAILED:
      return {
        ...state,
        addAtomPage: {
          result: null,
          loading: false,
          error: true,
        },
      }
    default:
      return state
  }
}

export default pageManager
