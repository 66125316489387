import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getPropertiesRequested } from "redux/actions/properties"
import { PropertiesType } from "redux/sagas/properties"
import { API_POST } from "helpers/api"
import { useMutation } from "@tanstack/react-query"
import { useQuery } from "@tanstack/react-query"

/**
 *
 * @param {typeof PropertiesType} type
 * @returns
 */
export const useDefines = (type = PropertiesType.DEFAULT) => {
  const { filter } = useSelector((state) => state.define)

  const { data, ...query } = useQuery({
    queryKey: ["defines", type || filter.type],
    queryFn: () =>
      API_POST(`${process.env.REACT_APP_PANEL_DOMAIN}/api/setting/getcorpsettings`, {
        SettingName: "Tanımlar",
        PropertyName: type || filter.type,
      }),
  })

  return {
    ...query,
    data: data?.data?.Result || [],
    filteredData:
      (filter?.status === null
        ? data?.data?.Result
        : data?.data?.Result?.filter((item) => (item.PropertyValueStatus === 1) === filter?.status)) || [],
  }
}

/**
 *
 * @param {typeof PropertiesType} type
 * @returns
 */
export const usePropertyList = (type = PropertiesType.DEFAULT) => {
  const data = useSelector((state) => state.properties.list)
  const isLoading = useSelector((state) => state.properties.loading)
  const isError = useSelector((state) => state.properties.error)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getPropertiesRequested(type))
  }, [dispatch, type])

  return {
    data,
    isLoading,
    isError,
  }
}

export const useDefineById = (propertyId) => {
  const { data, ...query } = useDefines()

  return {
    query,
    data: data?.find((item) => item.PropertyValueId?.toString() === propertyId?.toString()) || null,
  }
}

export const useDefineCrud = () => {
  /**
   * @typedef {Object} DefineUpdateData
   * @property {PropertyId} type
   * @property {PropertyValueId} id
   * @property {PropertyValuenAME} name
   * @property {PropertyValueDescription} description
   * @property {"active" | "passive" | -1} status
   *
   * @description Save, update or delete define.
   * If you send status parameter as -1, define will be deleted also you have to send id parameter.
   * If you send status parameter as active or passive, define will be updated.
   *
   * @description If you don't send id parameter, define will be saved.
   *
   * @param {DefineUpdateData} data
   * @returns
   */
  const defineCrud = async (data) => {
    return API_POST(
      `${process.env.REACT_APP_PANEL_DOMAIN}/api/setting/${!!data?.PropertyValueId ? "updatecorpsettings" : "savecorpsettings"}`,
      {
        Properties: [
          {
            ...data,
            PropertyValueId: data?.PropertyValueId || null,
            Status: data.Status === "active" || data.Status === 1 ? 1 : data.Status === "passive" || data.Status === 0 ? 0 : -1,
          },
        ],
      },
    )
  }

  const checkAllFields = (formValue, searchData = []) => {
    let responseData = {
      disable: false,
      message: "",
    }
    if (
      searchData.find(
        (item) =>
          item.PropertyValue?.toLowerCase() === formValue.PropertyValue?.toLowerCase() &&
          item.PropertyId === formValue.PropertyId,
      )
    ) {
      responseData.disable = true
      responseData.message = "Bu isimle kayıtlı başka bir tanım zaten var, lütfen başka bir isim kullanın."
      return responseData
    }

    if (!formValue.PropertyId || formValue.PropertyValue.split(" ").join("").length === 0) {
      responseData.disable = true
      responseData.message = ""
      return responseData
    }

    return responseData
  }

  return {
    defineCrud,
    checkAllFields,
  }
}

export const useImportDefine = () => {
  const mutation = useMutation({
    mutationFn: (form) => API_POST(`${process.env.REACT_APP_PANEL_DOMAIN}/api/setting/uploadcorpsettings`, form),
  })

  return mutation
}
