import InformationImages from "./InformationImages"

const Information = (props) => {
  let { description } = props
  return (
    <>
      <div className="bg-white p-6 rounded-lg flex max-h-[381px]">
        <div className="w-1/2 flex flex-col gap-6 overflow-auto p-2">
          <p className="text-gray-500 text-sm">{description}</p>
        </div>
        <div className="w-1/2 relative">
          <InformationImages />
        </div>
      </div>
    </>
  )
}

export default Information
