import {getEntiredImages} from "./GetEntiredImages";
import {dataUrlToFile} from "helpers/DataUrlToFile";
import {apiPostMediaLibrarySave} from "redux/sagas/mediaLibrary";
import AddTPPattern from "../Patterns/AddTPPattern";

export const modifyTPByNewData = async (thisComponent, model, imageKey) => {
	const hasImage = thisComponent?.data && await getEntiredImages(thisComponent?.data, imageKey)/*.then(r => console.log(r))*/
	const alreadyUploadedImage = !!hasImage && hasImage?.startsWith("https://")
	if (hasImage && !alreadyUploadedImage){
		// resmi upload et ve sonrasinda modeli manipule et
		return await dataUrlToFile(hasImage,`no_name_${(new Date().toJSON().slice(0,10))}.png`, { type: "image/png" }).then(file => {
			let formData = new FormData()
			formData.append("File", file)
			return apiPostMediaLibrarySave(formData).then(async response => {
				const uploadedMediaId = response?.data?.result?.id
				return AddTPPattern(model, thisComponent, uploadedMediaId);
			})
		})
	} else {
		return AddTPPattern(model, thisComponent, null)
	}

}