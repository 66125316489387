import tippy from "tippy.js"

// global configuration
const delay = [300, 100]
const allowHTML = true

export function LogoTooltip(text) {
  let selector = ".logo a"
  const instance = tippy(selector, {
    placement: "bottom-start",
    content: `<span class="text-blue-500 font-medium">${text}</span>`,
    allowHTML,
    delay,
  })

  document.querySelector(selector).addEventListener("click", () => {
    instance[0]?.disable()
  })
  document.querySelector(selector).addEventListener("mouseenter", () => {
    instance[0]?.enable()
  })
}

export const PageSelectorBoxTooltip = () => {
  let selector = ".page-selector-box button"
  const instance = tippy(selector, {
    placement: "bottom",
    content: `<span class="font-medium">Sayfa adı</span><span class="block mt-1">Düzenleme modunda açılan sayfa adı.</span>`,
    allowHTML,
    delay,
  })

  document.querySelector(selector).addEventListener("click", () => {
    instance[0]?.disable()
  })
  document.querySelector(selector).addEventListener("mouseenter", () => {
    instance[0]?.enable()
  })
}

export const PageLanguageBoxTooltip = () => {
  let selector = ".language-selector-box button"
  const instance = tippy(selector, {
    placement: "bottom",
    content: `<span class="font-medium">Dil değiştir</span><span class="block mt-1">Sitenizde kullandığınız tüm dilleri görün ve aralarından seçim yapın.</span>`,
    allowHTML,
    delay,
  })

  document.querySelector(selector).addEventListener("click", () => {
    instance[0]?.disable()
  })
  document.querySelector(selector).addEventListener("mouseenter", () => {
    instance[0]?.enable()
  })
}

export const PageStatusBoxTooltip = (htmlElement) => {
  let selector = ".page-status-box button"
  const instance = tippy(selector, {
    placement: "bottom",
    content: htmlElement,
    allowHTML,
    delay,
  })

  document.querySelector(selector).addEventListener("click", () => {
    instance[0]?.disable()
  })
  document.querySelector(selector).addEventListener("mouseenter", () => {
    instance[0]?.enable()
  })
}

export const DeviceBoxTooltip = () => {
  let mobilePhoneSelector = ".view-chooser .icon-smartphone"
  let desktopSelector = ".view-chooser .icon-monitor"
  const instanceMobile = tippy(mobilePhoneSelector, {
    placement: "bottom",
    content: `<span class="font-medium">Mobil görünüme geç</span><span class="block mt-1">Sayfanızı mobil cihazlar için düzenleyin.</span>`,
    allowHTML,
    delay,
  })

  const instanceDesktop = tippy(desktopSelector, {
    placement: "bottom",
    content: `<span class="font-medium">Masaüstü görünüme geç</span><span class="block mt-1">Sayfanızı masaüstü cihazlar için düzenleyin.</span>`,
    allowHTML,
    delay,
  })

  document.querySelector(mobilePhoneSelector).addEventListener("click", () => {
    instanceMobile[0]?.disable()
  })
  document.querySelector(mobilePhoneSelector).addEventListener("mouseenter", () => {
    instanceMobile[0]?.enable()
  })

  document.querySelector(desktopSelector).addEventListener("click", () => {
    instanceDesktop[0]?.disable()
  })
  document.querySelector(desktopSelector).addEventListener("mouseenter", () => {
    instanceDesktop[0]?.enable()
  })
}

export const previewBtnTooltip = () => {
  let selector = ".right-bar button.outline"
  const instance = tippy(selector, {
    placement: "bottom",
    content: `<span class="font-medium">Ön izle</span><span class="block mt-1">Sayfanızı kaydetmeden önce, masaüstü ve mobil görünümünü gözden geçirin. </span>`,
    allowHTML,
    delay,
  })

  document.querySelector(selector).addEventListener("click", () => {
    instance[0]?.disable()
  })
  document.querySelector(selector).addEventListener("mouseenter", () => {
    instance[0]?.enable()
  })
}

export const saveBtnTooltip = () => {
  let selector = ".right-bar .save-btn"
  const instance = tippy(selector, {
    placement: "bottom",
    content: `<span class="font-medium">Kaydet</span><span class="block mt-1">Sayfanızda yaptığınız son değişiklikleri kaydedin. </span>`,
    allowHTML,
    delay,
  })

  document.querySelector(selector).addEventListener("click", () => {
    instance[0]?.disable()
  })
  document.querySelector(selector).addEventListener("mouseenter", () => {
    instance[0]?.enable()
  })
}

export const pageNameIsCustomTooltip = () => {
  let selector = ".is-custom-tooltip"
  tippy(selector, {
    placement: "bottom",
    content: `Hazır temalarda değişiklik yapılamaz.`,
    allowHTML,
    delay,
  })
}
