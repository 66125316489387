import * as type from "redux/types"
import { PropertiesType } from "redux/sagas/properties"

/**
 *
 * @param {typeof PropertiesType} param
 * @returns
 */
export const getPropertiesRequested = (param = PropertiesType.DEFAULT) => ({
  type: type.GET_PROPERTIES_REQUESTED,
  payload: param,
})

export const getPropertiesSucceed = (data) => ({
  type: type.GET_PROPERTIES_SUCCEEDED,
  payload: data,
})

export const getPropertiesFailed = () => ({
  type: type.GET_PROPERTIES_FAILED,
})
