/* eslint-disable react-hooks/exhaustive-deps,array-callback-return */
import React, {useEffect, useRef, useState} from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { useDebouncedEffect } from "helpers/useDebouncedEffect"

// Components
import MainHeader from "components/Headers/MainHeader"
import { TitleH1, Description, Icon, LoadBar, StatusNotificationBox } from "components"
import { SelectPicker, MultiCascader } from "rsuite"

// constants
import { DomainTypes } from "../../constants"

// moment JS
import moment from "moment"

// dxTable
import { LoadPanel } from "devextreme-react/load-panel"
import DataGrid, {
  Column,
  FilterRow,
  Item,
  Pager,
  Paging,
  SearchPanel,
  Selection,
  Sorting,
  Toolbar,
} from "devextreme-react/data-grid"
import SelectBox from "devextreme-react/select-box"
import Button from "devextreme-react/button"
import OutsideAlerter from "pages/Reservations/OutsideAlerter"
import localization from "../../localization"

// img
import evaPreview from "../../assets/svg/eva-preview.svg"

// Requests
import {
  getDomainRequested,
  getTenantRequested,
  setDomainRequested,
  deleteDomainRequested,
} from "../../redux/actions/accountManagement"
import { productListRequested } from "redux/actions/getProductList"
import { agencyListRequested } from "redux/actions/getAgencyList"
import { agencyListForCorporateRequested } from "redux/actions/getAgencyListForCorporate"
import { domainList } from "constants/domainList";

export const MyDomains = (props) => {
  let { isLogged } = props

  const dispatch = useDispatch()
  const params = useParams()

  //refs
  const domainUriWrapper = useRef()

  let { id } = params

  // selectors
  const activeDomainLoading = useSelector((state) => state.accountManagement.activeDomain.loading)
  const activeDomainList = useSelector((state) => state.accountManagement.activeDomain.list)
  const activeDomainError = useSelector((state) => state.accountManagement.activeDomain.error)

  const setDomainLoading = useSelector((state) => state.accountManagement.setDomain.loading)
  const setDomainList = useSelector((state) => state.accountManagement.setDomain.list)
  const setDomainError = useSelector((state) => state.accountManagement.setDomain.error)

  const removeDomainLoading = useSelector((state) => state.accountManagement.deleteDomain.loading)
  const removeDomainList = useSelector((state) => state.accountManagement.deleteDomain.list)
  const removeDomainError = useSelector((state) => state.accountManagement.deleteDomain.error)

  //const activeTenantLoading = useSelector((state) => state.accountManagement.activeTenant.loading)
  const activeTenantList = useSelector((state) => state.accountManagement.activeTenant.list)
  //const activeTenantError = useSelector((state) => state.accountManagement.activeTenant.error)

  const getProductListLoading = useSelector((state) => state.getProductList.loading)
  const getProductList = useSelector((state) => state.getProductList.list)
  //const getProductListError = useSelector((state) => state.getProductList.error)

  const getAgencyListLoading = useSelector((state) => state.getAgencyList.loading)
  const getAgencyList = useSelector((state) => state.getAgencyList.list)
  //const getAgencyListError = useSelector((state) => state.getAgencyList.error)

  const getAgencyListForCorporateLoading = useSelector((state) => state.getAgencyListForCorporate.loading)
  const getAgencyListForCorporate = useSelector((state) => state.getAgencyListForCorporate.list)
  //const getAgencyListForCorporateError = useSelector((state) => state.getAgencyListForCorporate.error)

  // states
  const [TabList] = useState([
    { text: "Domainler", isActive: true, url: "account-management/domains" },
    { text: "Kullanıcı Listesi", isActive: false, url: "account-management/userlist" },
  ])
  const [DomainErrorClass, setDomainErrorClass] = useState(false)
  const [IsOpenTableDdown, setIsOpenTableDdown] = useState(null)
  const [DomainName, setDomainName] = useState("") // dikkat ! add domain ve delete domain için ortak state kullanılır -> DomainName
  const [DomainURI, setDomainURI] = useState(domainList[0]) // dikkat ! add domain ve delete domain için ortak state kullanılır -> DomainName+URI
  const [DomainId, setDomainId] = useState("")
  const [ShowAddDomain, setShowAddDomain] = useState(false)
  const [ShowRemoveDomain, setShowRemoveDomain] = useState(false)
  const [DomainAddButtonDisabled, setDomainAddButtonDisabled] = useState(true)
  const [DomainRemoveButtonDisabled, setDomainRemoveButtonDisabled] = useState(true)
  const [ShowStatusNotificationBox, setShowStatusNotificationBox] = useState(false)
  const [MultiCascaderSelectedList, setMultiCascaderSelectedList] = useState([])
  const [MultiCascaderSearchTerm, setMultiCascaderSearchTerm] = useState("")
  const [ActiveDomainType, setActiveDomainType] = useState(0)
  const [RemoveDomainMessage, setRemoveDomainMessage] = useState({})
  const [AddDomainMessage, setAddDomainMessage] = useState({})
  const [TableStates, setTableStates] = useState({
    cellAlignments: "left",
    rowWidth: 162,
    dataGrid: {
      id: "gridContainer",
      allowColumnReordering: true,
      allowColumnResizing: true,
      columnAutoWidth: true,
      showBorders: true,
      showScrollbar: "always",
      keyExpr: "Id",
      dataSource: [],
      noDataText: "",
    },
    paging: {
      defaultPageSize: 5,
    },
    pager: {
      visible: true,
      allowedPageSizes: [5, 10, 20],
      displayMode: "full",
      showPageSizeSelector: true,
      showInfo: true,
      showNavigationButtons: true,
    },
  })
  const [DynamicSelectTag, setDynamicSelectTag] = useState(MultiCascader)
  const [MultiCascaderTypes, setMultiCascaderTypes] = useState({
    Otel: [],
    B2C: [],
    Corporate: [],
  })

  const domainTypeItems = [
    {
      value: "B2C",
      text: "B2C",
    },
    {
      value: "Otel",
      text: "Otel Widget",
    },
    {
      value: "Corporate",
      text: "Corporate",
    },
  ]

  // Func

  const setForm = (e) => {
    setDomainName(e.target.value)
  }

  const onValueChanged = (e) => {
    setActiveDomainType(e.value)

    const filteredData = activeDomainList.filter((item) => item.DomainType === e.value)

    const { dataGrid, ...tableStates } = TableStates
    dataGrid.dataSource = filteredData
    setTableStates({ ...tableStates, dataGrid })
  }

  const domainTypeChanged = (e) => {
    setActiveDomainType(+e)
    setMultiCascaderSelectedList([])
    setMultiCascaderSearchTerm("")
    setDynamicSelectTag(+e === 2 ? SelectPicker : MultiCascader)
  }

  const addDomainRequest = () => {
    let defaultSaveData = {
      Name: `${DomainName}${DomainURI}`,
      IsCustomDomain: true,
      DomainType: ActiveDomainType,
      TenantId: id,
      Products: [],
    }

    // control for platformeva.com
    if (DomainURI === domainList[0]){
      defaultSaveData = {
        ...defaultSaveData,
        IsCustomDomain: false,
        Name: DomainName,
      }
    }

    setDomainAddButtonDisabled(true)
    let saveData = defaultSaveData

    if (typeof MultiCascaderSelectedList === "object") {
      MultiCascaderSelectedList.map((product) => {
        saveData.Products.push({
          ProductId: product,
          ProductType: 1,
        })
      })
    } else if (typeof MultiCascaderSelectedList === "number") {
      saveData.Products = [
        {
          ProductId: MultiCascaderSelectedList,
          ProductType: 0,
        },
      ]
    }
    dispatch(setDomainRequested(saveData))
  }

  const showAddDomain = () => {
    setShowAddDomain(!ShowAddDomain)
    setDomainName("")
    setShowStatusNotificationBox(false)
    setActiveDomainType(0)
    setDomainAddButtonDisabled(true)
  }

  const removeDomainRequest = () => {
    setDomainRemoveButtonDisabled(true)
    dispatch(deleteDomainRequested(DomainId))
  }

  const showRemoveDomain = (rowData) => {
    setShowRemoveDomain(!ShowRemoveDomain)
    setRemoveDomainMessage({})
    setDomainName(DomainName ? "" : rowData.Name)
    setDomainId(DomainId ? "" : rowData.Id)
  }

  const showStatusNotificationBox = () => {
    setShowStatusNotificationBox(!ShowStatusNotificationBox)
    setDomainAddButtonDisabled(true)
  }

  useDebouncedEffect(
    () => {
      if (MultiCascaderSearchTerm.length >= 3 && ActiveDomainType) {
        let reqData = { ProductName: MultiCascaderSearchTerm }
        if (ActiveDomainType === 1) {
          dispatch(productListRequested(reqData))
        } else if (ActiveDomainType === 2) {
          dispatch(agencyListRequested(reqData))
        } else if (ActiveDomainType === 3) {
          dispatch(agencyListForCorporateRequested(reqData))
        }
      }
    },
    [MultiCascaderSearchTerm, ActiveDomainType],
    300,
  )

  useEffect(() => {
    const re = /^(?=.{1,253}$)[a-zA-Z0-9]+(-[a-zA-Z0-9]+)*(\.[a-zA-Z0-9]+)*\.?$/
    if (DomainName === "" || re.test(DomainName)) {
      setDomainErrorClass(false)
    } else {
      setDomainErrorClass(true)
    }
  }, [DomainName])

  // effects
  useEffect(() => {
    if (activeDomainError) {
      alert("Müşteriye ait domain bilgilerine ulaşılamadı. Lütfen daha sonra tekrar deneyiniz.")
      // todo buraya bakmalısın - static code (route değişebilir)
      window.location.pathname = "/account-management"
    }
    if (!activeDomainError && !activeDomainLoading) {
      dispatch(getTenantRequested(id))
      dispatch(getDomainRequested(id))
    }
  }, [dispatch, id, activeDomainError])

  useEffect(() => {
    if (activeDomainList) {
      setTableStates({
        ...TableStates,
        dataGrid: {
          ...TableStates.dataGrid,
          dataSource: activeDomainList,
        },
      })
    }
  }, [activeDomainList])

  // validation add domain button disable state
  useEffect(() => {
    !!DomainName &&
    !DomainErrorClass &&
    ((ActiveDomainType !== 0 && ActiveDomainType !== 1) || MultiCascaderSelectedList.length > 0)
      ? setDomainAddButtonDisabled(false)
      : setDomainAddButtonDisabled(true)
  }, [DomainName, ActiveDomainType, MultiCascaderSelectedList, DomainErrorClass])

  // get product list
  useEffect(() => {
    if (getProductList) {
      let multiCascaderTypes = getProductList.reduce((acc, value) => {
        acc.push({
          label: value.name,
          value: value.id,
        })
        return acc
      }, [])

      setMultiCascaderTypes({
        ...MultiCascaderTypes,
        Otel: multiCascaderTypes,
      })
    }
  }, [getProductList])

  // get agency list
  useEffect(() => {
    if (getAgencyListForCorporate) {
      let multiCascaderTypes = getAgencyListForCorporate.reduce((acc, value) => {
        acc.push({
          label: value.name,
          value: value.id,
        })
        return acc
      }, [])

      setMultiCascaderTypes({
        ...MultiCascaderTypes,
        Corporate: multiCascaderTypes,
      })
    }
  }, [getAgencyListForCorporate])

  useEffect(() => {
    if (getAgencyList) {
      let multiCascaderTypes = getAgencyList.reduce((acc, value) => {
        acc.push({
          label: value.name,
          value: value.id,
        })
        return acc
      }, [])

      setMultiCascaderTypes({
        ...MultiCascaderTypes,
        B2C: multiCascaderTypes,
      })
    }
  }, [getAgencyList])

  useEffect(() => {
    if (setDomainList) {
      showStatusNotificationBox()
      if (!setDomainList?.success) {
        setAddDomainMessage({ success: "Başarısız ekleme işlemi" })
      } else {
        setAddDomainMessage({ success: setDomainList?.message })
      }
      dispatch(getDomainRequested(id))
    } else if (setDomainError) {
      alert("Domain ekleme işlemi sırasında bir hata oluştu, lütfen sayfayı yenileyiniz")
    }
  }, [setDomainList])

  useEffect(() => {
    if (removeDomainList) {
      if (!removeDomainList?.success) {
        setRemoveDomainMessage({ success: "Başarısız silme işlemi" })
      } else {
        setRemoveDomainMessage({ success: removeDomainList?.message })
      }
      dispatch(getDomainRequested(id))
    } else if (removeDomainError) {
      alert("Silme işlemi sırasında bir hata oluştu, lütfen sayfayı yenileyiniz")
    }
  }, [removeDomainList])

  useEffect(() => {
    if (removeDomainError || setDomainError) {
      let errorMessage = `işlem sırasında bir hata oluştu, bu genellikle internet bağlantısı sorunlarında veya sunucu ile haberleşme kaynaklı sorunlardan gerçekleşmektedir. Lütfen internet bağlantını kontrol et, herşeyden eminsen sayfayı yenile.`

      if (removeDomainError) setRemoveDomainMessage({ error: errorMessage })
      if (setDomainError) {
        showStatusNotificationBox()
        setAddDomainMessage({ error: errorMessage })
      }
    }
  }, [setDomainError, removeDomainError])

  return (
    <>
      <MainHeader isLogged={isLogged} />
      <main className="bg-gray-25 z-10">
        <section className="wrapper relative flex flex-col bg-white lg:bg-transparent overflow-auto">
          {activeDomainLoading && (
            <div className="account-management-area flex items-center justify-center h-full overflow-auto lg:overflow-hidden">
              <div className="flex items-center justify-center border border-gray-200 shadow-xl rounded-xl bg-white w-full h-full p-10">
                <LoadBar pathFill={"#2E90FA"} />
              </div>
            </div>
          )}

          {/*showAddDomain*/}
          {ShowAddDomain && !activeDomainLoading && (
            <>
              {!ShowStatusNotificationBox && (
                <div className="account-management-area flex items-center justify-center h-full overflow-auto lg:overflow-hidden">
                  <div className="flex flex-col border border-gray-200 shadow-xl rounded-xl bg-white w-[450px] p-6">
                    <form className="form-area w-full">
                      <div className="form-header">
                        <p className="text-lg text-gray-900 font-semibold">[{activeTenantList?.Name}] için Domain Ekle</p>
                        <p className="text-sm text-gray-500 mt-2">Seçmiş olduğunuz müşterinize yeni domain tanımlayın.</p>
                      </div>
                      <div className="form-body mt-8">
                        <div className="form-wrapper flex flex-col mt-6 first:mt-0">
                          <label
                            className="text-gray-800 text-sm font-medium mb-1"
                            htmlFor="domain_name"
                          >
                            Domain Adı
                          </label>
                          <div className="input-wrapper relative flex items-center w-full">
                            <input
                              className={`w-[60%] !rounded-r-none focus:outline-0	!shadow-none ${
                                DomainErrorClass ? "border !border-error-500" : "!border-gray-200"
                              }`}
                              id="domain_name"
                              placeholder="abc"
                              type="text"
                              value={DomainName}
                              onChange={(e) => {
                                setForm(e)
                              }}
                            />
                            <div
                              className="relative w-[40%]"
                               ref={domainUriWrapper}
                            >
                              <SelectPicker
                                block
                                menuMaxHeight={164}
                                defaultValue={".platformeva"}
                                cleanable={false}
                                searchable={true}
                                locale={localization.TR.PickerLocaleType.Picker}
                                data={domainList.map(list => ({"label": list, "value": list}))}
                                onSelect={(value) => {
                                  setDomainURI(value)
                                }}
                                container={domainUriWrapper.current}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-wrapper flex flex-col mt-6 first:mt-0">
                          <label
                            className="text-gray-800 text-sm font-medium mb-1"
                            htmlFor="domain_name"
                          >
                            Domain Tipi
                          </label>
                          <SelectPicker
                            block
                            placeholder={"Domain tipi seçin"}
                            cleanable={false}
                            data={[...DomainTypes]}
                            searchable={false}
                            /*container={textTypeWrapper.current}*/
                            onSelect={domainTypeChanged}
                          />
                        </div>
                        {ActiveDomainType !== 0 && (
                          <div className="form-wrapper flex flex-col mt-6 first:mt-0">
                            <label
                              className="text-gray-800 text-sm font-medium mb-1"
                              htmlFor="domain_name"
                            >
                              {DomainTypes[ActiveDomainType - 1]?.label === "B2C"
                                ? "Acente"
                                : DomainTypes[ActiveDomainType - 1]?.label}
                            </label>

                            <DynamicSelectTag
                              block
                              appearance="default"
                              menuHeight="auto"
                              menuWidth={300}
                              placeholder={"Lütfen Seçiniz"}
                              cleanable={true}
                              data={MultiCascaderTypes[DomainTypes[ActiveDomainType - 1]?.label]}
                              searchable={true}
                              locale={{
                                ...localization?.TR?.PickerLocaleType,
                                searchPlaceholder: "Ara",
                                noResultsText:
                                  getProductListLoading || getAgencyListLoading || getAgencyListForCorporateLoading
                                    ? "Yükleniyor..."
                                    : MultiCascaderSearchTerm.length >= 3
                                    ? "Sonuç bulunamadı"
                                    : "Minimum 3 karakter giriniz",
                              }}
                              onSearch={(e) => setMultiCascaderSearchTerm(e)}
                              onChange={(e) => {
                                setMultiCascaderSelectedList(e)
                              }}
                            />
                          </div>
                        )}
                      </div>

                      <div className="form-footer mt-8">
                        <div className="btn-group flex gap-6">
                          <button
                            type="button"
                            className="btn outline w-full"
                            onClick={showAddDomain}
                          >
                            Vazgeç
                          </button>
                          <button
                            type="button"
                            className="btn w-full"
                            onClick={addDomainRequest}
                            disabled={DomainAddButtonDisabled}
                          >
                            {DomainAddButtonDisabled && setDomainLoading ? <LoadBar pathFill={"#2E90FA"} /> : "Domain Ekle"}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              )}

              <StatusNotificationBox
                show={ShowStatusNotificationBox && Object.values(AddDomainMessage)?.length}
                {...(AddDomainMessage?.error ? { error: true } : "")}
                desc={AddDomainMessage?.success ? AddDomainMessage?.success : AddDomainMessage?.error}
              >
                <div className="form-footer mt-8">
                  <div className="btn-group flex gap-6">
                    <Link
                      className="block w-full"
                      to={"/account-management"}
                    >
                      <button
                        type="button"
                        className="btn outline w-full px-2"
                      >
                        Müşteri Yönetimi
                      </button>
                    </Link>
                    <button
                      type="button"
                      className="btn w-full px-2"
                      onClick={showAddDomain}
                    >
                      Domain Listesi
                    </button>
                  </div>
                </div>
              </StatusNotificationBox>
            </>
          )}

          <StatusNotificationBox
            show={ShowRemoveDomain && !Object.values(RemoveDomainMessage)?.length && !activeDomainLoading}
            error={true}
            title="Uyarı!"
            desc={`<span class="text-error-500">${DomainName}</span> adlı domain kaydını silmek istediğinize emin misiniz?`}
          >
            <div className="form-footer mt-8">
              <div className="btn-group flex gap-6">
                <button
                  type="button"
                  className="btn outline w-full px-2"
                  onClick={showRemoveDomain}
                >
                  Vazgeç
                </button>
                <button
                  type="button"
                  className="btn danger w-full px-2"
                  onClick={removeDomainRequest}
                >
                  {DomainRemoveButtonDisabled && removeDomainLoading ? <LoadBar pathFill={"#f04438"} /> : "Sil"}
                </button>
              </div>
            </div>
          </StatusNotificationBox>

          <StatusNotificationBox
            show={ShowRemoveDomain && (!!RemoveDomainMessage.success || RemoveDomainMessage.error) && !activeDomainLoading}
            title=""
            {...(RemoveDomainMessage?.error ? { error: true } : "")}
            desc={RemoveDomainMessage?.success ? RemoveDomainMessage?.success : RemoveDomainMessage?.error}
          >
            <div className="form-footer mt-8">
              <div className="btn-group flex gap-6">
                <Link
                  className="block w-full"
                  to={"/account-management"}
                >
                  <button
                    type="button"
                    className="btn outline w-full px-2"
                  >
                    Müşteri Yönetimi
                  </button>
                </Link>
                <button
                  type="button"
                  className="btn w-full px-2"
                  onClick={showRemoveDomain}
                >
                  Domain Listesi
                </button>
              </div>
            </div>
          </StatusNotificationBox>

          {!activeDomainLoading &&
            !ShowAddDomain &&
            activeDomainList &&
            !ShowRemoveDomain &&
            !Object.values(RemoveDomainMessage)?.length && (
              <>
                <div className="account-management-container max-w-[1032px] px-4 py-8 w-full mx-auto">
                  <Link
                    to="/account-management"
                    className="inline-flex items-center"
                  >
                    <Icon
                      name="arrow-left"
                      size="20"
                      color="#667085"
                    />
                    <p className="font-medium text-gray-500 ml-2">Müşteri yönetimine dön</p>
                  </Link>
                  <div className="page-info flex items-center flex-wrap mt-6">
                    <div className="page-info-text mr-4">
                      <TitleH1>{activeTenantList?.Name}</TitleH1>
                      <Description className="mt-2">
                        Oluşturulma tarihi: {moment(activeTenantList?.CreateDate).format("DD.MM.YYYY")}
                      </Description>
                    </div>
                  </div>
                  <div className="filter-wrapper border-b border-gray-200">
                    <ul className="relative flex items-start text-gray-500 font-medium text-sm pt-6 select-none">
                      {TabList.map((v, k) => {
                        return (
                          <li
                            key={k}
                            className={`transition delay-200 ${v.isActive ? "text-blue-500 border-b border-blue-500" : ""}`}
                          >
                            <Link
                              to={`../${v.url}/${id}`}
                              className="block px-3 pb-4 xl:py-4"
                            >
                              {v.text}
                            </Link>
                          </li>
                        )
                      })}
                    </ul>
                  </div>

                  {!activeDomainList.length && (
                    <div className="empty-box flex flex-col lg:items-center gap-10 lg:flex-row lg:overflow-hidden py-5">
                      <div className="lg:w-7/12 flex flex-col items-start py-5">
                        <TitleH1 className="mb-4">Henüz hiç domain yok!</TitleH1>
                        <p className="text-gray-500 text-sm lg:text-lg">
                          Bu sayfadan domain ekleyebilir, yönetebilir ve domainlerini görüntüleyebilirsin.
                        </p>

                        <button
                          onClick={showAddDomain}
                          className="btn sm lg:md mt-4 lg:mt-8"
                        >
                          <span>Domain Ekle</span>
                        </button>
                      </div>

                      <figure className="hidden lg:flex lg:w-5/12 items-center justify-center">
                        <img
                          src={evaPreview}
                          alt="HomePage Preview"
                        />
                      </figure>
                    </div>
                  )}

                  {/*table list*/}
                  {!!activeDomainList.length && (
                    <>
                      <LoadPanel
                        position={{ of: `#${TableStates.dataGrid.id}` }}
                        shadingColor="rgba(0,0,0,0.4)"
                        loadPanelVisible={false}
                        showIndicator={true}
                        shading={true}
                        showPane={true}
                        hideOnOutsideClick={true}
                        visible={false}
                        text={"Yükleniyor..."}
                        //onHidden={() => false}
                      />
                      <DataGrid
                        className={`mt-6`}
                        {...TableStates.dataGrid}
                      >
                        {/*TABLE OPTIONS*/}
                        <Selection {...TableStates.selection} />
                        <Sorting mode="multiple" />
                        <SearchPanel
                          placeholder={"Domain arayın"}
                          visible={true}
                          width="288"
                        />
                        <FilterRow {...TableStates.filterRow} />
                        <Paging {...TableStates.paging} />
                        <Pager {...TableStates.pager} />
                        {/*TABLE OPTIONS*/}

                        <Toolbar>
                          <Item
                            location="before"
                            name="searchPanel"
                          />
                          <Item location="before">
                            <SelectBox
                              width="184"
                              items={domainTypeItems}
                              displayExpr="text"
                              valueExpr="value"
                              placeholder="Domain Tipi"
                              onValueChanged={onValueChanged}
                            />
                          </Item>
                          <Item location="after">
                            <Button
                              text="Domain Ekle"
                              width="auto"
                              className="!bg-blue-500 !text-white"
                              onClick={showAddDomain}
                            />
                          </Item>
                        </Toolbar>

                        {/*TABLE BODY*/}
                        <Column
                          caption="Domain Adı"
                          dataField="Name"
                          allowFiltering={true}
                          allowSearch={true}
                          allowHeaderFiltering={false}
                          alignment={"center"}
                          width={TableStates.rowWidth}
                        />
                        <Column
                          caption="Domain Tipi"
                          dataField="DomainType"
                          alignment={TableStates.cellAlignments}
                          allowFiltering={false}
                          allowHeaderFiltering={true}
                          width={TableStates.rowWidth}
                        />
                        <Column
                          caption="Kullanıcı Sayısı"
                          dataField="TenantDomainUsers"
                          cellRender={(row) => {
                            let users = row.data?.TenantDomainUsers.filter((user) => user.UserId > 0)
                            return users.length ? users.length : "-"
                          }}
                          allowFiltering={true}
                          allowHeaderFiltering={false}
                          width={TableStates.rowWidth}
                        />
                        <Column
                          caption="Oluşturulma Tarihi"
                          dataType="date"
                          dataField="CreateDate"
                          allowFiltering={false}
                          allowHeaderFiltering={true}
                          width={TableStates.rowWidth}
                        />
                        <Column
                          caption="Son İşlem Tarihi"
                          dataType="date"
                          dataField="UpdateDate"
                          allowFiltering={true}
                          allowHeaderFiltering={false}
                          width={TableStates.rowWidth}
                        />
                        <Column
                          caption="İşlemler"
                          width={TableStates.rowWidth}
                          fixed={true}
                          fixedPosition={"right"}
                          cellRender={(row) => {
                            /*let { ReservationNo, isNotificationSent } = row.row.data*/
                            return (
                              <div className="dropdown relative">
                                <OutsideAlerter
                                  as="button"
                                  className="w-full"
                                  index={row.rowIndex}
                                  isOutsideClick={() => setIsOpenTableDdown(null)}
                                  handleClick={() =>
                                    IsOpenTableDdown !== row.rowIndex
                                      ? setIsOpenTableDdown(row.rowIndex)
                                      : setIsOpenTableDdown(null)
                                  }
                                >
                                  <Icon
                                    className="pointer-events-none"
                                    name="more-vertical"
                                    size="20"
                                    color="#1D2939"
                                  />
                                </OutsideAlerter>
                                {IsOpenTableDdown === row.rowIndex && (
                                  <ul
                                    className={`dropdown-body absolute right-[12px] bg-white text-base border border-solid border-gray-100 shadow-lg mt-1 rounded-lg overflow-hidden text-sm text-gray-800 z-10`}
                                  >
                                    {/*
                                    todo BE tamamlandıktan sonra bunlar da eklenecek (MARKETPLACE ADDONS)
                                    <li className="dropdown-item">
                                    <Link
                                      to={`products/id=${row.data.Id}&add=true`}
                                      className="block"
                                    >
                                      <button className="w-full py-2.5 px-4 hover:bg-gray-50 hover:font-medium text-left">
                                        Ürün ekle
                                      </button>
                                    </Link>
                                  </li>
                                  <li className="dropdown-item">
                                    <Link
                                      to={`products/id=${row.data.Id}`}
                                      className="block"
                                    >
                                      <button className="w-full py-2.5 px-4 hover:bg-gray-50 hover:font-medium text-left">
                                        Ürünleri göster
                                      </button>
                                    </Link>
                                  </li>*/}
                                    <li className="dropdown-item">
                                      <button
                                        onClick={() => showRemoveDomain(row.data)}
                                        className="w-full py-2.5 px-4 hover:bg-gray-50 hover:font-medium text-left"
                                      >
                                        Domaini kaldır
                                      </button>
                                    </li>
                                  </ul>
                                )}
                              </div>
                            )
                          }}
                          alignment={"center"}
                        />
                        {/*TABLE BODY*/}
                      </DataGrid>
                    </>
                  )}
                </div>
              </>
            )}
        </section>
      </main>
    </>
  )
}

export default MyDomains
