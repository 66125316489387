import React, { useState } from "react"
import PropTypes from "prop-types"

//helpers
import {closestSize} from "helpers/closestSize";

const FontSizeBox = (props) => {
  let { title, onChange, activeFontSize, letters, sizes, styles, className } = props

  return (
    <div className="font-size-box">
      <label className="text-sm">{title}</label>
      <div className={`flex justify-center text-center border border-gray-200 rounded-lg overflow-hidden ${className}`}>
        {sizes.map((size, key) => {
          return (
            <span
              className={`flex items-center justify-center min-w-9 min-h-9 w-full h-full py-0.5 text-${styles[key]} ${
                closestSize(activeFontSize, sizes) === size ? "bg-blue-100 text-black" : ""
              } border-l border-gray-200 cursor-pointer first:border-l-0 transition hover:text-black`}
              key={key}
              onClick={() => onChange(size)}
            >
              {typeof letters === "object" ? letters[key] : letters }
            </span>
          )
        })}
      </div>
    </div>
  )
}

export default FontSizeBox

FontSizeBox.prototypes = {
  title: PropTypes.string,
  letters: PropTypes.string || PropTypes.array,
  className: PropTypes.string,
  activeFontSize: PropTypes.string,
  sizes: PropTypes.array,
  styles: PropTypes.array,
  onChange: PropTypes.func,
}

FontSizeBox.defaultProps = {
  title: "Yazı Boyutu",
  letters: "AA",
  className: "mt-2",
  activeFontSize: "base",
  sizes: [12, 16, 20],
  styles: ["xs", "base", "xl"],
  //onChange: (e) => console.log(e),
}
