import React from "react"
import { Controller, useFormContext } from "react-hook-form"
import { Input } from "rsuite"

const FormNameInput = () => {
  const {
    formState: { errors },
  } = useFormContext()

  const errorMessage = errors["rule"]?.Name?.message || null
  
  return (
    <>
      <div className="text-sm ">Kural Adı</div>
      <Controller
        name="rule.Name"
        rules={{
          required: "Kural adı zorunludur",
        }}
        render={({ field }) => (
          <>
            <Input
              name={field.name}
              placeholder="Kural adı yazınız"
              onChange={(e) => field.onChange(e)}
              value={field.value}
            />
            {errorMessage && <p className="text-red-500 text-xs py-1">{errorMessage}</p>}
          </>
        )}
      />
    </>
  )
}

export default FormNameInput
