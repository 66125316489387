import * as type from "redux/types"

export const pageStateRequested = (data) => ({
  type: type.PAGE_STATE_REQUESTED,
  payload: data,
})

export const pageStateSucceed = (data) => ({
  type: type.PAGE_STATE_SUCCEEDED,
  payload: data,
})

export const pageStateFailed = () => ({
  type: type.PAGE_STATE_FAILED,
})
