import { call, fork, put, takeLatest } from "redux-saga/effects"
import * as actions from "redux/actions/strategyDesign"

import {
  DESIGN_FIELDS_VALUE_REQUESTED,
  DESIGN_FIELDS_VALUE_SAVE_REQUESTED,
  GET_TEMPLATES_REQUESTED,
  DELETE_STRATEGY_REQUESTED,
} from "redux/types"
import { API_GET, API_POST } from "helpers/api"

function* watchStrategyDesign() {
  yield takeLatest(DESIGN_FIELDS_VALUE_REQUESTED, getDesignFieldsList)
  yield takeLatest(DESIGN_FIELDS_VALUE_SAVE_REQUESTED, saveDesignFieldsList)
  yield takeLatest(GET_TEMPLATES_REQUESTED, getTemplates)
  yield takeLatest(DELETE_STRATEGY_REQUESTED, deleteStrategy)
}

function* getDesignFieldsList(action) {
  try {
    const res = yield call(apiGetDesignFieldsList, action.payload)
    yield put(actions.getDesignFieldsValueSucceed(res?.data))
  } catch (err) {
    yield put(actions.getDesignFieldsValueFailed())
  }
}
function* saveDesignFieldsList(action) {
  try {
    const res = yield call(apiSaveDesignFieldsList, action.payload)
    yield put(actions.saveDesignFieldsValueSucceed(res?.data))
  } catch (err) {
    yield put(actions.saveDesignFieldsValueFailed())
  }
}
function* getTemplates(action) {
  try {
    const res = yield call(apiGetTemplates, action.payload)
    yield put(actions.getTemplatesSucceed(res?.data))
  } catch (err) {
    yield put(actions.getTemplatesFailed())
  }
}
function* deleteStrategy(action) {
  try {
    const res = yield call(apiDeleteStrategy, action.payload)
    yield put(actions.deleteStrategySucceed(res?.data))
  } catch (err) {
    yield put(actions.deleteStrategyFailed())
  }
}

const apiGetDesignFieldsList = (payload) => {
  return API_GET(`${process.env.REACT_APP_DISPLAY_DOMAIN}/api/v1/strategydesign/get-design-fields-value`, payload)
}
const apiSaveDesignFieldsList = (payload) => {
  return API_POST(`${process.env.REACT_APP_DISPLAY_DOMAIN}/api/v1/strategydesign/save-design-fields-value`, payload)
}
const apiGetTemplates = (payload) => {
  return API_GET(`${process.env.REACT_APP_DISPLAY_DOMAIN}/api/v1/strategydesign/get-templates`, payload)
}
const apiDeleteStrategy = (payload) => {
  return API_POST(`${process.env.REACT_APP_DISPLAY_DOMAIN}/api/v1/strategydesign/delete-strategy`, payload)
}

export default function* strategyDesign() {
  yield fork(watchStrategyDesign)
}
