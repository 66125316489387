import React from "react"
import { Controller, useFormContext } from "react-hook-form"
import { InputNumber } from "rsuite"

const FormPrioritySelectBox = ({ methods }) => {
  const {
    formState: { errors },
  } = useFormContext()

  const errorMessage = errors["rule"]?.Priority?.message

  return (
    <>
      <div className="text-sm">Öncelik Sırası</div>
      <Controller
        control={methods.control}
        name={"rule.Priority"}
        rules={{
          required: "Öncelik sırası seçiniz",
        }}
        render={({ field }) => (
          <>
            <InputNumber
              name={field.name}
              defaultValue={1}
              max={1000}
              min={1}
              placeholder="Öncelik belirleyiniz"
              className="pr-1"
              value={field.value}
              onChange={(e) => field.onChange(e)}
            />
            {errorMessage && <p className="text-red-500 text-xs py-1">{errorMessage}</p>}
          </>
        )}
      />
    </>
  )
}

export default FormPrioritySelectBox
