import { useState } from "react"

import { Modal, Icon } from "components"
import MainHeader from "components/Headers/MainHeader"
import Settings from "components/IntegrationSettings/Settings"

const IntegraionSettings = (props) => {
  let { isLogged, AssociatedDomains } = props

  const [accessLinkModal, setAccessLinkModal] = useState(false)
  const [accessLinkModalPage, setAccessLinkModalPage] = useState(false)
  const [reservationModal, setRezervationModal] = useState(false)
  const [reservationModalPage, setRezervationModalPage] = useState(false)
  const [accessLinkModalRadio, setAccessLinkModalRadio] = useState("domain-1")
  const [targetBlank, setTargetBlank] = useState(true)
  const [buttonRemoveModal, setButtonRemoveModal] = useState(false)

  const handleRadio = (event) => {
    setAccessLinkModalRadio(event.target.value)
  }

  return (
    <>
      <MainHeader isLogged={isLogged} />
      <main>
        <section className="p-10 my-0 mx-auto max-w-[1440px]">
          <Settings {...AssociatedDomains} />
        </section>

        <Modal
          onChange={() => {
            setAccessLinkModal(false)
          }}
          open={accessLinkModal}
          title={"Erişim Linki"}
          width={600}
          closeButton={true}
        >
          <p className="text-base mb-8">Link oluşturun ve istediğiniz yerden online rezervasyon almaya başlayın.</p>
          <div className="flex flex-row bg-white mb-8 border-b border-solid border-gray-200">
            <p
              onClick={() => setAccessLinkModalPage(false)}
              className={
                !accessLinkModalPage
                  ? "text-sm font-medium pb-4 border-b border-solid border-blue-500 text-blue-500 mr-2 cursor-pointer"
                  : "text-sm font-medium text-gray-500 cursor-pointer"
              }
            >
              Link Al
            </p>
            <p
              onClick={() => setAccessLinkModalPage(true)}
              className={
                accessLinkModalPage
                  ? "text-sm font-medium pb-4 border-b border-solid border-blue-500 text-blue-500 ml-2 cursor-pointer"
                  : "text-sm font-medium text-gray-500 cursor-pointer"
              }
            >
              Nasıl yapılır?
            </p>
          </div>
          {!accessLinkModalPage ? (
            <>
              <p className="text-lg text-gray-900 font-medium mb-4">1 - Bağlantı türünü seçin</p>
              <div className="flex flex-col mb-8">
                <label className="flex flex-row items-center mb-2 cursor-pointer">
                  <input
                    className="mr-2"
                    defaultChecked={accessLinkModalRadio === "domain-1"}
                    onChange={(e) => handleRadio(e)}
                    type="radio"
                    value="domain-1"
                    name="Domain"
                  />
                  <p className={accessLinkModalRadio === "domain-1" ? "text-base text-gray-800" : "text-base text-gray-400"}>
                    https://hotellacasa.eva.io
                  </p>
                </label>
                <label className="flex flex-row items-center cursor-pointer">
                  <input
                    className="mr-2"
                    defaultChecked={accessLinkModalRadio === "domain-2"}
                    onChange={(e) => handleRadio(e)}
                    type="radio"
                    value="domain-2"
                    name="Domain"
                  />
                  <p className={accessLinkModalRadio === "domain-2" ? "text-base text-gray-800" : "text-base text-gray-400"}>
                    https://yourdomain.com
                  </p>
                </label>
              </div>
              <p className="text-lg text-gray-900 font-medium mb-4">
                2 - Linki kopyalayın ve rezervasyon almak istediğiniz yerlere ekleyin.
              </p>

              <div className="flex flex-row bg-white border border-solid border-gray-200 rounded-lg mb-4">
                <p className="text-base text-gray-500 px-3 py-2 border-r-2 border-solid border-gray-200">http://</p>
                <input
                  className="w-full !border-none text-blue-500"
                  value="hotellacasa.eva.io"
                  type="text"
                />
                <p className="flex items-center text-sm text-blue-500 px-3 py-2">
                  <Icon
                    className="mr-3"
                    name="copy"
                    color="#2E90FA"
                    size="20"
                  />
                  Kopyala
                </p>
              </div>
              <p className="text-base text-gray-500">
                Websitenizdeki rezervasyon butonlarına, sosyal medyada kullandığınız paylaşımlara vb. her alanda bu linki
                ekleyerek online rezervasyon alabilirsin.
              </p>
              <div className="flex flex-row w-full justify-end mt-8">
                <button
                  onClick={() => setAccessLinkModal(false)}
                  className="btn lg outline mr-3"
                >
                  Vazgeç
                </button>
                <button
                  onClick={() => {
                    setAccessLinkModal(false)
                  }}
                  className="btn lg"
                >
                  Oluştur
                </button>
              </div>
            </>
          ) : (
            <>Nasıl yapılır</>
          )}
        </Modal>

        <Modal
          onChange={() => {
            setRezervationModal(false)
          }}
          open={reservationModal}
          title={"Rezervasyon butonu"}
          width={600}
          closeButton={true}
        >
          <div className="flex flex-row bg-white mb-8 border-b border-solid border-gray-200">
            <p
              onClick={() => setRezervationModalPage(false)}
              className={
                !reservationModalPage
                  ? "text-sm font-medium pb-4 border-b border-solid border-blue-500 text-blue-500 mr-2 cursor-pointer"
                  : "text-sm font-medium text-gray-500 cursor-pointer"
              }
            >
              Buton ekle
            </p>
            <p
              onClick={() => setRezervationModalPage(true)}
              className={
                reservationModalPage
                  ? "text-sm font-medium pb-4 border-b border-solid border-blue-500 text-blue-500 ml-2 cursor-pointer"
                  : "text-sm font-medium text-gray-500 cursor-pointer"
              }
            >
              Nasıl yapılır?
            </p>
          </div>
          {!reservationModalPage ? (
            <>
              <div className="flex flex-row  p-6 bg-gray-50 border border-solid border-gray-200 rounded-lg mb-8">
                <button className="bg-[#F97C1C] py-2 px-4 border border-solid border-[#F97C1C] rounded-lg text-white mr-6">
                  Rezervasyon Yap
                </button>
                <p className="text-base text-gray-500 w-1/2">4 basit adımda butonunuzu websitenize ekleyin. </p>
              </div>
              <p className="text-lg text-gray-900 font-medium mb-4">1 - Bağlantı türünü seçin</p>
              <div className="flex flex-col mb-8">
                <label className="flex flex-row items-center mb-2 cursor-pointer">
                  <input
                    className="mr-2"
                    defaultChecked={accessLinkModalRadio === "domain-1"}
                    onChange={(e) => handleRadio(e)}
                    type="radio"
                    value="domain-1"
                    name="Domain"
                  />
                  <p className={accessLinkModalRadio === "domain-1" ? "text-base text-gray-800" : "text-base text-gray-400"}>
                    https://hotellacasa.eva.io
                  </p>
                </label>
                <label className="flex flex-row items-center cursor-pointer">
                  <input
                    className="mr-2"
                    defaultChecked={accessLinkModalRadio === "domain-2"}
                    onChange={(e) => handleRadio(e)}
                    type="radio"
                    value="domain-2"
                    name="Domain"
                  />
                  <p className={accessLinkModalRadio === "domain-2" ? "text-base text-gray-800" : "text-base text-gray-400"}>
                    https://yourdomain.com
                  </p>
                </label>
              </div>
              <div className="bg-white w-1/2 flex flex-row justify-between rounded-lg border border-solid border-gray-200 py-2 px-4 mb-8">
                <p className="text-sm">Yeni sekmede aç</p>

                <label class="inline-flex relative items-center  cursor-pointer">
                  <input
                    type="checkbox"
                    className="sr-only peer"
                    checked={targetBlank}
                    readOnly
                  />
                  <div
                    onClick={() => {
                      setTargetBlank(!targetBlank)
                    }}
                    className="w-11 h-6 bg-gray-200 rounded-full peer  peer-focus:ring-blue-500  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-500"
                  />
                </label>
              </div>
              <p className="text-lg text-gray-900 font-medium mb-4">2 - Buton metni girin</p>
              <input
                value="Rezervasyon Yap"
                className="border mb-8"
                type="text"
              />
              <p className="text-lg text-gray-900 font-medium mb-4">3 - Buton rengini seçin</p>
              <div className="flex flex-row bg-white border border-solid border-gray-200 rounded-lg mb-8 w-1/2">
                <input
                  value="#F97C1C"
                  className="w-10/12 !border-none"
                  type="text"
                />
                <p className=" w-7 h-7 bg-[#F97C1C] rounded my-2" />
              </div>
              <p className="text-lg text-gray-900 font-medium mb-4">4- Kodu kopyala ve websitenin istediğin yerine yapıştır.</p>
              <div className="flex flex-row items-center bg-white border border-solid border-gray-200 rounded-lg shadow-sm py-2 px-3">
                <p className="text-base text-gray-500 break-words w-10/12">{`<script src="https://my.eva.io/webapp/js/src/others/eva_iframe.js"></script> <div class="eva-app-c1cd0f02-6d01"></div>`}</p>
                <p className="flex items-center text-sm text-blue-500 w-2/12 ml-2">
                  <Icon
                    className="mr-3"
                    name="copy"
                    color="#2E90FA"
                    size="20"
                  />
                  Kopyala
                </p>
              </div>
              <div className="flex flex-row w-full justify-end mt-8">
                <button
                  onClick={() => setRezervationModal(false)}
                  className="btn lg outline mr-3"
                >
                  Vazgeç
                </button>
                <button
                  onClick={() => {
                    setRezervationModal(false)
                  }}
                  className="btn lg"
                >
                  Oluştur
                </button>
              </div>
            </>
          ) : (
            <>Nasıl yapılır?</>
          )}
        </Modal>

        <Modal
          onChange={() => {
            setButtonRemoveModal(false)
          }}
          open={buttonRemoveModal}
          title={"Butonu kaldırmak istiyor musun?"}
          width={600}
          closeButton={true}
        >
          <p className="text-base text-gray-500">
            Entegrasyonları buradan kaldırsan bile websitesinden kaldırmış olmazsınız. Bu butonu websitesindeki sayfalardan da
            kaldırman gerekiyor.
          </p>
          <div className="flex flex-row w-full justify-end mt-8">
            <button
              onClick={() => setButtonRemoveModal(false)}
              className="btn lg outline mr-3"
            >
              Vazgeç
            </button>
            <button
              onClick={() => {
                setButtonRemoveModal(false)
              }}
              className="border border-solid border-[#D92D20] text-white rounded-lg shadow-sm py-2 px-4 bg-error-600 lg"
            >
              Evet Kaldır
            </button>
          </div>
        </Modal>
      </main>
    </>
  )
}

export default IntegraionSettings
