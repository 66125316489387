import { call, fork, put, takeLatest } from "redux-saga/effects"
import * as actions from "redux/actions/myReservationsDetail"

import { MY_RESERVATIONS_DETAIL_REQUESTED } from "redux/types"
import { API_POST } from "helpers/api"

function* watchGetMyReservationsDetail() {
  yield takeLatest(MY_RESERVATIONS_DETAIL_REQUESTED, getMyReservationsDetail)
}

function* getMyReservationsDetail(action) {
  try {
    const res = yield call(apiGetMyReservationsDetail, action.payload)
    let result = res.data?.result
    yield put(actions.myReservationsDetailSucceed(result))
  } catch (err) {
    yield put(actions.myReservationsDetailFailed())
  }
}

const apiGetMyReservationsDetail = (payload) => {
  return API_POST(`${process.env.REACT_APP_PANEL_DOMAIN}/api/reservation/get`, payload)
}

export default function* myReservationsDetail() {
  yield fork(watchGetMyReservationsDetail)
}
