/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import {useSelector} from "react-redux"

// layouts
import CategorySlides from "./CategorySlides"
import BlockWrapper from "../../BlockWrapper"
import AddOrEditSlide from "./AddOrEditSlide"
import Settings from "../Slider/Settings"

// components
import { TabList, EditBlockName } from "components"

// utils
import getKeyByValue from "utils/getKeyByValue"
import getSelectedIndex from "utils/getSelectedIndex";
import {
  CategoryList,
  newTabSlider,
  TAB_SLIDER,
  TabListTabLabels,
  NEW_REGISTER_CODE,
} from "../../../Enums";
import AddOrEditCategory from "./CategorySlides/AddOrEditCategory";

// saga-requests

const Slider = (props) => {
  let { show, activeLayout, setEditLayouts, EditBlock, setEditBlock, ActiveCategory, setActiveCategory } = props

  let infoCodeForStrategy = activeLayout?.info?.code
  let code = infoCodeForStrategy || activeLayout?.code
  // selectors
  const categoryList = useSelector((state) => state.pageManager.categoryList.list)
  const components = useSelector(state => state.strategy.getStrategy.list?.result)

  // states
  const [EditSliderTab, setEditSliderTab] = useState(0)
  const [ThisComponent, setThisComponent] = useState((components && components?.children && components?.children.find(child => child?.code === infoCodeForStrategy)) || newTabSlider(code, activeLayout?.order))

  const [subComponentIdList] =  useState({
    id: NEW_REGISTER_CODE(),
    imageId: NEW_REGISTER_CODE(),
    textId: NEW_REGISTER_CODE(),
    buttonId: NEW_REGISTER_CODE(),
    hyperLinkId: NEW_REGISTER_CODE(),
  })

  // Func
  const editSliderTabChanged = (e) => {
    let selectedIndex = getSelectedIndex(e)
    setEditSliderTab(selectedIndex)
  }

  useEffect(() => {
    if (ActiveCategory && (!ThisComponent?.data && !ThisComponent?.children && !ThisComponent?.visual)){
      const mockLayout = {
        [TAB_SLIDER]: true,
        info: activeLayout?.info,
        code: activeLayout?.code,
        virtualBlock: activeLayout?.virtualBlock,
        order: activeLayout?.order,
        mock: {
          category: {
            name : ActiveCategory?.Name
          }
        }
      }
      setEditLayouts(mockLayout)
    }

  }, [ActiveCategory])

  return (
    <BlockWrapper
      show={show}
      activeLayout={activeLayout}
      setEditLayouts={setEditLayouts}
      editBlock={EditBlock}
      setEditBlock={setEditBlock}
      ThisComponent={ThisComponent}
      setThisComponent={setThisComponent}
      doneBtnOnClick={() => {
        setEditLayouts(null)
        setActiveCategory(null)
      }}
    >
      {(!EditBlock || !EditBlock[getKeyByValue(EditBlock, true)]) && (
        <>
          <TabList
            onChange={editSliderTabChanged}
            tabs={[
              { label: TabListTabLabels.content, icon: "list", disabled: false },
              { label: TabListTabLabels.design, icon: "palette-solid", disabled: false },
              { label: TabListTabLabels.publish, icon: "share", disabled: true },
            ]}
            tabClassName="w-full flex items-center gap-2.5 text-center text-gray-600 text-xs p-2 border-r last:border-r-0 hover:bg-gray-50 transition duration-400"
            wrapperClassName="flex shrink-0 border rounded-lg border-gray-200 overflow-hidden mb-7"
            //itemActiveClass="!text-gray-800 !bg-blue-50"
            activeTab={EditSliderTab}
          />

          {show && (
            <>
              {EditSliderTab === 0 && (
                <CategorySlides
                  activeLayout={activeLayout}
                  EditBlock={EditBlock}
                  setEditBlock={setEditBlock}
                  ThisComponent={ThisComponent}
                  setThisComponent={setThisComponent}
                  ActiveCategory={ActiveCategory}
                  setActiveCategory={setActiveCategory}
                  categoryList={categoryList}
                  subComponentIdList={subComponentIdList}
                />
              )}
              {EditSliderTab === 1 && (
                <Settings
                  ThisComponent={ThisComponent}
                  setThisComponent={setThisComponent}/>
              )}
              {EditSliderTab === 2 && <h1>Publish</h1>}
            </>
          )}
        </>
      )}

      {activeLayout && (
        <>
          <EditBlockName
            editBlock={EditBlock}
            setEditBlock={setEditBlock}
          />
          <AddOrEditSlide
            activeLayout={activeLayout}
            editBlock={EditBlock}
            setEditBlock={setEditBlock}
            ThisComponent={ThisComponent}
            setThisComponent={setThisComponent}
            ActiveCategory={ActiveCategory}
            subComponentIdList={subComponentIdList}
          />
          <AddOrEditCategory
            editBlock={EditBlock}
            setEditBlock={setEditBlock}
            ThisComponent={ThisComponent}
            setThisComponent={setThisComponent}
            categoryList={categoryList}
            categoryType={CategoryList.TAB_SLIDER}
          />
        </>
      )}
    </BlockWrapper>
  )
}

export default Slider
