import { call, fork, put, takeLatest } from "redux-saga/effects"
import * as actions from "redux/actions/getDepartmants"

import { GET_DEPARTMANTS_REQUESTED } from "redux/types"
import { API_GET } from "helpers/api"

function* watchGetDepartmantList() {
  yield takeLatest(GET_DEPARTMANTS_REQUESTED, getDepartmantList)
}

function* getDepartmantList(action) {
  try {
    const res = yield call(apiGetDepartmantList, action.payload)
    yield put(actions.departmantListSucceed(res?.data))
  } catch (err) {
    yield put(actions.departmantListFailed())
  }
}

const apiGetDepartmantList = (payload) => {
  return API_GET(`${process.env.REACT_APP_PANEL_DOMAIN}/api/staticdata/departmanlist/?term=`, payload)
}

export default function* departmantListGet() {
  yield fork(watchGetDepartmantList)
}
