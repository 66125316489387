import React from "react"
import moment from "moment"
import { BorderBox, Icon } from "components"
import ReservationSummaryRoutes from "components/Reservation/ReservationSummaryRoutes"

const ReservationReportsDetailTransferModule = ({ reservationData }) => {
  let transferSegmentGroupItems = reservationData.DetailResponse.TransferSegmentGroupItem.filter((x) =>
    reservationData.ProductKeys.includes(x.Key),
  )

  transferSegmentGroupItems = transferSegmentGroupItems.sort(
    (a, b) => moment(a.Items[0]?.DepartureTime).valueOf() - moment(b.Items[0]?.DepartureTime).valueOf(),
  )

  const originSegmentGroup = transferSegmentGroupItems[0]?.Items[0]
  const destinationSegmentGroup = transferSegmentGroupItems.length === 1 ? null : transferSegmentGroupItems[1]?.Items[0]
  return (
    <div className="space-y-4">
      {[originSegmentGroup, destinationSegmentGroup]
        .filter((x) => !!x)
        .map((segment, segmentIndex) => (
          <BorderBox
            className="flex w-full gap-4 bg-gray-50 p-4"
            key={segment?.Key}
          >
            <div className="flex w-full flex-col items-center justify-between gap-2 md:flex-row">
              <div className="flex flex-col">
                {!!destinationSegmentGroup && (
                  <p className="mb-1 text-sm font-medium">{segmentIndex === 0 ? "Gidiş" : "Dönüş"}</p>
                )}
                <div className="flex items-center gap-2 whitespace-nowrap">
                  <img
                    className="h-[46px] w-[78px] object-cover"
                    src={segment?.PictureUrl}
                    alt=""
                  />
                  <div className="flex flex-col gap-2">
                    <p className="line-clamp-3 w-48 whitespace-normal text-sm font-semibold">{segment?.Name}</p>

                    <div className="flex items-center gap-1">
                      <Icon
                        name="calendar"
                        size="14"
                        color="#667085"
                      />
                      <p className="text-xs text-gray-500">{moment(segment?.DepartureTime).format("DD MMM YYYY dddd")}</p>
                    </div>
                    <div className="flex items-center gap-4">
                      <div className="flex items-center gap-1">
                        <Icon
                          name="users"
                          size="14"
                          color="#667085"
                        />
                        <p className="text-xs text-gray-500">Maks {segment?.Quota}</p>
                      </div>
                      <div className="flex items-center gap-1">
                        <Icon
                          name="clock"
                          size="14"
                          color="#667085"
                        />
                        <p className="text-xs text-gray-500">{`${moment.utc(segment?.DepartureTime).format("HH:mm")}/${moment
                          .utc(segment?.ArrivalTime)
                          .format("HH:mm")}`}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {(segmentIndex === 0
                ? !!reservationData.RoundTripInformations?.[0]?.TransferProductFlightDetails?.FlightAddressDeparture
                : !!reservationData.RoundTripInformations?.[0]?.TransferProductFlightDetails?.FlightAddressArrival) && (
                <div className="mt-2 w-full grow md:mt-0 md:w-auto">
                  <div className="mx-auto h-[100px] w-full max-w-[300px] rounded-primary border p-2">
                    <div className="text-xs text-gray-500">Adresiniz</div>
                    <p className="mt-1 w-[90%] text-sm font-semibold">
                      {segmentIndex === 0
                        ? reservationData.RoundTripInformations[0]?.TransferProductFlightDetails?.FlightAddressDeparture
                        : reservationData.RoundTripInformations[0]?.TransferProductFlightDetails?.FlightAddressArrival}
                    </p>
                  </div>
                </div>
              )}
              <ReservationSummaryRoutes
                origin={segment?.Name.split(" - ")[segmentIndex === 0 ? 1 : 2]}
                destination={segment?.Name.split(" - ")[segmentIndex === 0 ? 2 : 1]}
              />
            </div>
          </BorderBox>
        ))}
    </div>
  )
}

export default ReservationReportsDetailTransferModule
