import * as type from "redux/types"

// DETAIL
export const addonDetailRequested = (data) => ({
  type: type.ADDON_DETAIL_REQUESTED,
  payload: data,
})

export const addonDetailSucceed = (data) => ({
  type: type.ADDON_DETAIL_SUCCEEDED,
  payload: data,
})

export const addonDetailFailed = () => ({
  type: type.ADDON_DETAIL_FAILED,
})
