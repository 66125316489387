import * as type from "redux/types"

export const pageDeleteRequested = (data) => ({
  type: type.PAGE_DELETE_REQUESTED,
  payload: data,
})

export const pageDeleteSucceed = (data) => ({
  type: type.PAGE_DELETE_SUCCEEDED,
  payload: data,
})

export const pageDeleteFailed = () => ({
  type: type.PAGE_DELETE_FAILED,
})
