import Icon from "components/Icon"
import React, { useState, useEffect } from "react"
import { Modal, Button, SelectPicker, Form, InputGroup, Input } from "rsuite"
import { useDefinePropertyType } from "utils/useDefinePropertyType"
import { useDefineCrud, useDefines } from "utils/useDefines"

/**
 *
 * @param {boolean} open Visibility of the modal
 * @param {(data) => void} onSuccess on success callback
 * @param {() => void} onFailed on Failed callback
 * @param {() => void} onClose on close callback
 * @returns
 */

const formInitialValue = {
  Status: "active",
  PropertyId: undefined,
  PropertyValue: "",
  Description: "",
}

const DefineAddModal = (props) => {
  const [formValue, setFormValue] = useState(formInitialValue)
  const [buttonDisable, setButtonDisable] = useState(true)
  const [nameWarning, setNameWarning] = useState("")
  const { data: propertyTypeList } = useDefinePropertyType()
  const { checkAllFields, defineCrud } = useDefineCrud()
  const { data, refetch } = useDefines()


  const handleOnChangeForm = (value) => {
    setFormValue(value)
    const checkResult = checkAllFields(value, data || [])
    setButtonDisable(checkResult.disable)
    setNameWarning(checkResult.message)
  }

  const handleOnClose = () => {
    setFormValue(formInitialValue)
    props.onClose()
  }

  const handleOnSubmit = () => {
    setButtonDisable(true)
    /**
     * @description Save new define
     */
    defineCrud(formValue)
      .then((response) => {
        if (response.status === 200) {
          props.onSuccess(formValue)
          refetch();
        } else {
          console.error("Yeni tanım eklenirken bir hata oluştu. Lütfen tekrar deneyiniz.")
        }
      })
      .catch((error) => {
        console.error("Yeni tanım eklenirken bir hata oluştu. Lütfen tekrar deneyiniz. : " + error.message)
      })
  }

  const NameInput = (props) => {
    return (
      <InputGroup inside className="flex items-center border !border-red-500 hover:!border-red-500 active:!border-red-500 focus:!border-red-500">
        <Input
          className="!border-[#f04438] hover:!border-[#f04438] active:!border-red-500 focus:!border-red-500 focus:!outline-red-100 !outline-6 active:!outline-red-100 focus-within:!border-red-500 focus-within:!outline-red-100"
          {...props}
        />
        <InputGroup.Addon>
          <Icon
            name="alert-circle"
            className="!bg-[#f04438] !w-4 !h-4 mt-[0.3rem]"
          />
        </InputGroup.Addon>
      </InputGroup>
    )
  }
  useEffect(() => {
    if (props.open) {
      setFormValue({ ...formInitialValue })
    }

    return () => {
      setButtonDisable(true)
      setNameWarning("")
    }
  }, [props.open])

  return (
    <>
      <Modal
        open={props.open}
        onClose={handleOnClose}
        className="w-[382px] !mt-0 absolute top-1/2 left-1/2 transform !-translate-x-1/2 !-translate-y-1/2"
      >
        <Form
          fluid
          formValue={formValue}
          onChange={handleOnChangeForm}
        >
          <Modal.Header
            closeButton={false}
            className="px-1"
          >
            <p className="font-semibold text-xl">Yeni tanım ekleyin</p>
            <p className="text-gray-600 text-sm">Formu doldurarak sisteme yeni tanım ekleyin.</p>
          </Modal.Header>
          <Modal.Body className="px-1">
            <Form.Group controlId="PropertyId">
              <Form.ControlLabel className="text-sm font-medium">Tanım Türü* </Form.ControlLabel>
              <Form.Control
                name="PropertyId"
                placeholder="Tanım türü seçin"
                data={
                  propertyTypeList?.map((item) => ({
                    value: item.Id,
                    label: item.Name,
                  })) || []
                }
                accepter={SelectPicker}
                searchable={false}
                cleanable={false}
                style={{ width: "100%" }}
              />
            </Form.Group>
            <Form.Group controlId="PropertyValue">
              <Form.ControlLabel className="text-sm font-medium">Tanım Adı* </Form.ControlLabel>
              <Form.Control
              autoFocus
                name="PropertyValue"
                placeholder="Tanım adı girin"
                accepter={!!nameWarning ? NameInput : undefined}
                className={`${
                  nameWarning
                    ? "!border-[#f04438] !border-2 focus:!outline-red-100 hover:!border-[#f04438] active:!outline-red-100"
                    : "!outline-none focus:!outline-none !active:!outline-none"
                }}`}
              />
              <p className="text-[#f04438] text-sm mt-2">{nameWarning}</p>
            </Form.Group>
            <Form.Group controlId="Status">
              <Form.ControlLabel className="text-sm font-medium">Statü </Form.ControlLabel>
              <Form.Control
                name="Status"
                placeholder="Statü Seçiniz"
                data={[
                  {
                    value: "active",
                    label: "Aktif",
                  },
                  {
                    value: "passive",
                    label: "Pasif",
                  },
                ]}
                accepter={SelectPicker}
                searchable={false}
                cleanable={false}
                style={{ width: "100%" }}
              />
            </Form.Group>
            <Form.Group controlId="Description">
              <Form.ControlLabel className="text-sm font-medium">Tanım Açıklaması </Form.ControlLabel>
              <Form.Control
                name="Description"
                placeholder="Opsiyonel"
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="flex px-1">
            <Button
              onClick={handleOnClose}
              className="btn w-full bg-white text-gray-600 border border-solid border-gray-300 mr-2 hover:!text-white hover:!bg-gray-600 hover:!border-gray-600"
            >
              Vazgeç
            </Button>
            <Button
              onClick={handleOnSubmit}
              className="btn w-full hover:!text-white focus:!text-white"
              disabled={buttonDisable}
            >
              Kaydet
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}

const useDefineAddModal = () => {
  const [open, setOpen] = useState(false)

  /**
   * @param {*} data
   */
  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return {
    open,
    handleOpen,
    handleClose,
  }
}

export { useDefineAddModal }
export default DefineAddModal
