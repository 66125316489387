import * as type from "redux/types"

const initialState = {
  getStrategy: {
    list: null,
    loading: false,
    error: false,
  },
  saveStrategy: {
    response: null,
    loading: false,
    error: false,
  },
}

const strategy = (state = initialState, action) => {
  switch (action.type) {
    case type.STRATEGY_GET_REQUESTED:
      return {
        ...state,
        getStrategy: {
          list: null,
          loading: true,
          error: false,
        },
      }
    case type.STRATEGY_GET_SUCCEEDED:
      return {
        ...state,
        getStrategy: {
          list: action.payload,
          loading: false,
          error: false,
        },
      }
    case type.STRATEGY_GET_FAILED:
      return {
        ...state,
        getStrategy: {
          list: null,
          loading: false,
          error: true,
        },
      }
    case type.STRATEGY_POST_REQUESTED:
      return {
        ...state,
        saveStrategy: {
          response: null,
          loading: true,
          error: false,
        },
      }
    case type.STRATEGY_POST_SUCCEEDED:
      return {
        ...state,
        saveStrategy: {
          response: action.payload,
          loading: false,
          error: false,
        },
      }
    case type.STRATEGY_POST_FAILED:
      return {
        ...state,
        saveStrategy: {
          response: null,
          loading: false,
          error: true,
        },
      }
    default:
      return state
  }
}

export default strategy
