import { call, fork, put, takeLatest } from "redux-saga/effects"
import * as actions from "redux/actions/getAgencyListForCorporate"

import { GET_AGENCY_LIST_FOR_CORPORATE_REQUESTED } from "redux/types"
import { API_POST } from "helpers/api"

function* watchGetAgencyListForCorporate() {
  yield takeLatest(GET_AGENCY_LIST_FOR_CORPORATE_REQUESTED, getAgencyListForCorporate)
}

function* getAgencyListForCorporate(action) {
  try {
    const res = yield call(apiGetAgencyListForCorporate, action.payload)
    yield put(actions.agencyListForCorporateSucceed(res?.data?.result?.data))
  } catch (err) {
    yield put(actions.agencyListForCorporateFailed())
  }
}

const apiGetAgencyListForCorporate = (payload) => {
  return API_POST(`${process.env.REACT_APP_PANEL_DOMAIN}/api/products/agencylistforcorporate`, payload)
}

export default function* AgencyList() {
  yield fork(watchGetAgencyListForCorporate)
}
