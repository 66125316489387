/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"

// components
import MainHeader from "components/Headers/MainHeader"
import {TitleH1, Description, Icon, ErrorMessage} from "components"
import uploadFile from "../../assets/svg/upload-file.svg"
import { LoadPanel } from "devextreme-react/load-panel"
import MyReservationSkeleton from "../../skeletons/MyReservation"
import DataGrid, {
  Column,
  FilterRow,
  Item,
  Pager,
  Paging,
  SearchPanel,
  Selection,
  Sorting,
  Toolbar,
} from "devextreme-react/data-grid"
import SelectBox from "devextreme-react/select-box";
import OutsideAlerter from "pages/Reservations/OutsideAlerter"
import {ClonePathAlert, DeletePathAlert, MainPageRowAlert, StateRowAlert} from "./layouts/Alerts";

// utils
import getKeyByValue from "utils/getKeyByValue"
import getPageList from "./helpers/GetPageList";

// constants
import {PageState, PageTypes} from "constants/index"

//saga-requests
import {pageDeleteSucceed} from "redux/actions/pageDelete";
import {pageStateSucceed} from "redux/actions/pageState";
import {pageCloneSucceed} from "redux/actions/pageClone";
import {pageSaveMainSucceed} from "redux/actions/pageSaveMain";

const PageManager = (props) => {
  let { isLogged, AssociatedDomains } = props

  const dispatch = useDispatch()

  const PageTypeDropdownList = Object.keys(PageTypes).map((type, index) => {
    return ({
      text: type,
      value: Object.values(PageTypes)[index]
    })
  })

  const PageStatusDropdownList = Object.keys(PageState).map((type, index) => {
    return ({
      text: type,
      value: Object.values(PageTypes)[index]
    })
  })



  //selectors

  const pageList = useSelector((state) => state.getPageList.list.Data)
  const pageListLoading = useSelector((state) => state.getPageList.loading)
  const pageListError = useSelector((state) => state.getPageList.error)

  //states
  const [IsOpenTableDdown, setIsOpenTableDdown] = useState(null)
  const [WillDeleteRow, setWillDeleteRow] = useState(null)
  const [WillStateRow, setWillStateRow] = useState(null)
  const [WillCloneRow, setWillCloneRow] = useState(null)
  const [WillMainPageRow, setWillMainPageRow] = useState(null)
  const [TableSearchQuery, setTableSearchQuery] = useState("")
  const [ActivePageTypeDropdown, setActivePageTypeDropdown] = useState(null)
  const [ActivePageStatusDropdown, setActivePageStatusDropdown] = useState(null)
  const [TableStates, setTableStates] = useState({
    cellAlignments: "center",
    cellCssClass: "!align-middle",
    rowWidth: "20%",
    dataGrid: {
      id: "gridContainer",
      allowColumnReordering: true,
      allowColumnResizing: true,
      columnAutoWidth: true,
      showBorders: true,
      showScrollbar: "always",
      keyExpr: "Id",
      //columnHidingEnabled: true,
      dataSource: pageList,
      noDataText: "Aranılan kriterde bir sayfa bulunamadı.",
    },
    paging: {
      defaultPageSize: 5,
    },
    pager: {
      visible: true,
      allowedPageSizes: [5, 10, 20],
      displayMode: "full",
      showPageSizeSelector: true,
      showInfo: true,
      showNavigationButtons: true,
    },
  })

  useEffect(() => {
    getPageList(dispatch)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      setWillMainPageRow(null)
      dispatch(pageSaveMainSucceed(null))
      setWillDeleteRow(null)
      dispatch(pageDeleteSucceed(null))
      setWillStateRow(null)
      dispatch(pageStateSucceed(null))
      setWillCloneRow(null)
      dispatch(pageCloneSucceed(null))
    }
  }, [])

  useEffect(() => {
    if (pageList) {
      setTableStates({
        ...TableStates,
        dataGrid: {
          ...TableStates.dataGrid,
          dataSource: pageList,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageList])

  return (
    <>

      <DeletePathAlert
        state={WillDeleteRow}
        setState={setWillDeleteRow}
        TableSearchQuery={TableSearchQuery}
        ActivePageTypeDropdown={ActivePageTypeDropdown}
        ActivePageStatusDropdown={ActivePageStatusDropdown}
        /*onSuccess={() =>
          getPageList(dispatch, TableSearchQuery, ActivePageTypeDropdown, ActivePageStatusDropdown)
        }*/
      />

      <ClonePathAlert
        state={WillCloneRow}
        setState={setWillCloneRow}
        TableSearchQuery={TableSearchQuery}
        ActivePageTypeDropdown={ActivePageTypeDropdown}
        ActivePageStatusDropdown={ActivePageStatusDropdown}
      />

      <MainPageRowAlert
        state={WillMainPageRow}
        setState={setWillMainPageRow}
        TableSearchQuery={TableSearchQuery}
        ActivePageTypeDropdown={ActivePageTypeDropdown}
        ActivePageStatusDropdown={ActivePageStatusDropdown}
      />

      <StateRowAlert
        state={WillStateRow}
        setState={setWillStateRow}
        TableSearchQuery={TableSearchQuery}
        ActivePageTypeDropdown={ActivePageTypeDropdown}
        ActivePageStatusDropdown={ActivePageStatusDropdown}
      />

      <MainHeader isLogged={isLogged} />
      <main>
        <section className="p-10 my-0 mx-auto max-w-[1920px] overflow-auto">
          <div className="page-container">
            <div className="flex flex-col items-center">
              <div className="xl:w-9/12 w-full transition-[width] duration-500">
                <div className="page-top-area flex justify-between items-center mb-6">
                  <div className="page-title">
                    <TitleH1>Sayfa Yönetimi</TitleH1>
                    <Description>Websitenizin sayfalarını özelleştirin.</Description>
                  </div>

                  <Link to={`editPage/new?pageState=1&pageType=1`}>
                    <button className="btn">Sayfa Ekle</button>
                  </Link>
                </div>
                {!pageListLoading && pageListError && (
                  <ErrorMessage className="mt-1">Sayfa listesi yuklenirken bir hata ile karsilasildi.</ErrorMessage>
                )}
                {pageListLoading && !pageListError && <MyReservationSkeleton className="!p-0" />}

                {!pageList && !pageListLoading && !pageListError && (
                  <div className="bg-white border border-gray-200 shadow-sm rounded-lg pt-10 pb-12 px-8">
                    <div className="flex flex-col justify-center items-center text-center">
                      <img
                        src={uploadFile}
                        alt="Upload icon"
                        width={48}
                      />

                      <p className="font-medium text-gray-800 mt-4">Henüz hiç sayfa yok!</p>
                      <p className="text-sm text-gray-500 mt-1">Lütfen tekrar deneyin veya yeni bir sayfa oluşturun.</p>
                      <Link to="addNewPage">
                        <button
                          disabled
                          className="btn outline sm mt-6 md:!px-32 w-full lg:w-auto"
                        >
                          Yeni sayfa ekle
                        </button>
                      </Link>
                    </div>
                  </div>
                )}

                {pageList && !pageListLoading && (
                  <div className="page-manager-container w-full">
                    <LoadPanel
                      position={{ of: `#${TableStates.dataGrid.id}` }}
                      shadingColor="rgba(0,0,0,0.4)"
                      loadPanelVisible={true}
                      showIndicator={true}
                      shading={true}
                      showPane={true}
                      hideOnOutsideClick={true}
                      visible={!pageList && !pageList.length}
                      text={"Yükleniyor..."}
                      //onHidden={() => false}
                    />
                    <DataGrid
                      className={"dx-overflow-visible-table !max-w-full"}
                      {...TableStates.dataGrid}
                    >
                      {/*TABLE OPTIONS*/}
                      <Selection {...TableStates.selection} />

                      <Sorting mode="multiple" />
                      <SearchPanel
                        placeholder={"Sayfa ara"}
                        visible={true}
                        width="250"
                        text={TableSearchQuery}
                        onTextChange={(text) => {
                          setTableSearchQuery(text)
                          getPageList(dispatch, text, ActivePageTypeDropdown, ActivePageStatusDropdown)
                        }}
                      />
                      <FilterRow {...TableStates.filterRow} />
                      <Paging {...TableStates.paging} />
                      <Pager {...TableStates.pager} />
                      {/*TABLE OPTIONS*/}

                      <Toolbar>
                        <Item
                          location="before"
                          name="searchPanel"
                        />
                        <Item location="after">
                          <div className="flex gap-4">
                            <SelectBox
                              width="200"
                              items={PageTypeDropdownList}
                              displayExpr="text"
                              valueExpr="value"
                              placeholder="Sayfa tipi"
                              showClearButton
                              value={ActivePageTypeDropdown}
                              onValueChanged={e => {
                                setActivePageTypeDropdown(e.value)
                                getPageList(dispatch, TableSearchQuery, e.value, ActivePageStatusDropdown)
                              }}
                            />
                            <SelectBox
                              width="160"
                              items={PageStatusDropdownList}
                              displayExpr="text"
                              valueExpr="value"
                              placeholder="Statü"
                              showClearButton
                              value={ActivePageStatusDropdown}
                              onValueChanged={e => {
                                setActivePageStatusDropdown(e.value)
                                getPageList(dispatch, TableSearchQuery, ActivePageTypeDropdown, e.value)
                              }}
                            />
                          </div>
                        </Item>
                        {/*<Item location="before">

                      </Item>*/}
                        {/*<Item location="after">
                          <Link to="addNewPage">
                            <Button
                              text="Sayfa Ekle"
                              width="auto"
                              className="!bg-blue-500 !text-white"
                            />
                          </Link>
                        </Item>*/}
                      </Toolbar>

                      {/*TABLE BODY*/}
                      <Column
                        caption="Sayfalar"
                        dataField="Name"
                        allowFiltering={true}
                        allowSearch={true}
                        allowHeaderFiltering={false}
                        width={"28%"}
                        cellRender={(row) => {
                          return (
                            <div className="flex items-center gap-3">
                              <div className="bg-gray-100 shrink-0 rounded-full p-2.5">
                                <Icon
                                  className="inline-flex"
                                  name={row?.data?.isMainPage ? "home" : "file"}
                                  size="20"
                                  color="#98A2B3"
                                />
                              </div>
                              <span
                                className="text-gray-800 font-medium truncate"
                                title={row.text}
                              >
                                {row.text}
                              </span>
                            </div>
                          )
                        }}
                        alignment={"left"}
                        cssClass={TableStates.cellCssClass}
                      />
                      <Column
                        caption="Sayfa tipi"
                        dataField="PageType"
                        cellRender={(row) => (row.text ? getKeyByValue(PageTypes, row.text) : "-")}
                        allowFiltering={false}
                        allowHeaderFiltering={true}
                        width={TableStates.rowWidth}
                        alignment={TableStates.cellAlignments}
                        cssClass={TableStates.cellCssClass}
                      />
                      <Column
                        caption="Url"
                        dataField="Path"
                        allowFiltering={false}
                        allowHeaderFiltering={true}
                        width={TableStates.rowWidth}
                        alignment={"left"}
                        cssClass={TableStates.cellCssClass}
                        cellRender={(row) => (
                          <span title={row.displayValue}
                            className="block truncate"
                          >
                        {row.displayValue}
                      </span>
                        )}
                      />
                      <Column
                        caption="Durum"
                        dataField="State"
                        sortOrder={"desc"}
                        cellRender={(row) => (
                          <span
                            className={`inline-flex gap-1 items-center ${
                              row.value === 1
                                ? "bg-success-50 text-success-500"
                                : row.value === 0
                                ? "bg-gray-100 text-gray-500"
                                : "bg-warning-50 text-warning-500"
                            } rounded-xl py-0.5 px-2`}
                          >
                            <Icon
                              name="dot"
                              color={row.value === 1 ? "#12B76A" : row.value === 0 ? "#667085" : "#F79009"}
                              size="8"
                            />
                            <span>{row.value === 1 ? "Aktif" : row.value === 0 ? "Pasif" : "Planlandı"}</span>
                          </span>
                        )}
                        allowFiltering={true}
                        allowHeaderFiltering={false}
                        width={TableStates.rowWidth}
                        alignment={TableStates.cellAlignments}
                        cssClass={TableStates.cellCssClass}
                      />
                      <Column
                        caption="Değişiklik tarihi"
                        dataField="UpdateDate"
                        dataType="date"
                        cellRender={(row) => (
                          <div className="flex flex-col">
                            <span>{row.data.Status ? "Yayınlanmış" : "Son değişiklik"}</span>
                            <span>{row.text}</span>
                          </div>
                        )}
                        allowFiltering={false}
                        allowHeaderFiltering={true}
                        width={120}
                        //alignment={TableStates.cellAlignments}
                        cssClass={TableStates.cellCssClass}
                      />
                      <Column
                        caption="Oluşturma tarihi"
                        dataField="CreateDate"
                        dataType="date"
                        cellRender={(row) => (row.text ? row.text : "-")}
                        allowFiltering={true}
                        allowHeaderFiltering={false}
                        width={TableStates.rowWidth}
                        alignment={TableStates.cellAlignments}
                        cssClass={TableStates.cellCssClass}
                      />
                      <Column
                        caption="İşlemler"
                        width={108}
                        /*fixed={true}
                        fixedPosition={"right"}*/
                        cellRender={(row) => {
                          const keyIndex = `${row.key}${row.rowIndex}`
                          return (
                            <div className="dropdown relative">
                              <OutsideAlerter
                                as="button"
                                className="w-full"
                                index={keyIndex * 1}
                                isOutsideClick={() => setIsOpenTableDdown(null)}
                                handleClick={() =>
                                  IsOpenTableDdown !== keyIndex ? setIsOpenTableDdown(keyIndex) : setIsOpenTableDdown(null)
                                }
                              >
                                <Icon
                                  className="pointer-events-none"
                                  name="more-vertical"
                                  size="20"
                                  color="#1D2939"
                                />
                              </OutsideAlerter>
                              {IsOpenTableDdown === keyIndex && (
                                <ul
                                  className={`dropdown-body min-w-[180px] absolute right-3 bg-white border border-solid border-gray-100 shadow-lg mt-1 rounded-lg overflow-hidden text-sm text-gray-800 z-10`}
                                >
                                  <li className="dropdown-item">
                                    <Link
                                      to={`https://${AssociatedDomains?.domainName}${row.row.data.Path}`}
                                      target="_blank"
                                      className="flex gap-3 items-center w-full py-2.5 px-4 hover:bg-gray-50 hover:font-medium text-left"
                                    >
                                      <Icon
                                        name="external-link"
                                        size="16"
                                        color="#1D2939"
                                      />
                                      <span>Görüntüle</span>
                                    </Link>
                                  </li>
                                  <li className="dropdown-item">
                                    <Link
                                      to={`editPage/${row?.data?.Id}?isCustom=${row.row.data?.isCustom}&path=${row.row.data?.Path}&name=${row.row.data?.Name}&pageType=${row.row.data?.PageType}&pageState=${row.row.data?.State}&isMainPage=${row.row.data?.isMainPage}`}
                                      className="flex gap-3 items-center w-full py-2.5 px-4 hover:bg-gray-50 hover:font-medium text-left"
                                    >
                                      <Icon
                                        name="edit-2"
                                        size="16"
                                        color="#1D2939"
                                      />
                                      <span>Düzenle</span>
                                    </Link>
                                  </li>
                                  <li className="dropdown-item">
                                    <button
                                      onClick={() => setWillCloneRow(row.row.data)}
                                      className="flex gap-3 items-center w-full py-2.5 px-4 hover:bg-gray-50 hover:font-medium text-left">
                                      <Icon
                                        name="copy"
                                        size="16"
                                        color="#1D2939"
                                      />
                                      <span>Kopyala</span>
                                    </button>
                                  </li>

                                  {row.row.data?.isCustom && (
                                    <li className="dropdown-item">
                                      <button
                                        onClick={() => setWillStateRow(row.row.data)}
                                        className="flex gap-3 items-center w-full py-2.5 px-4 hover:bg-gray-50 hover:font-medium text-left">
                                        <Icon
                                          name={row.row.data.State ? "toggle-left" : "toggle-right"}
                                          size="16"
                                          color="#1D2939"
                                        />
                                        <span>{row.row.data.State ? "Pasife Al" : "Aktife Al"}</span>
                                      </button>
                                    </li>
                                  )}

                                  <li className="dropdown-item">
                                    <button
                                      onClick={() => setWillMainPageRow(row.row.data)}
                                      className={`${row.row.data.isMainPage ? "opacity-50 pointer-events-none" : ""} flex gap-3 items-center w-full py-2.5 px-4 hover:bg-gray-50 hover:font-medium text-left`}>
                                      <Icon
                                        name="home"
                                        size="16"
                                        color="#1D2939"
                                      />
                                      <span>Açılış sayfası olarak ayarla</span>
                                    </button>
                                  </li>

                                  {row.row.data?.isCustom && !row.row.data?.isMainPage && (
                                    <li className="dropdown-item">
                                      <button
                                        onClick={() => setWillDeleteRow(row.row.data)}
                                        className="flex gap-3 items-center w-full py-2.5 px-4 hover:bg-gray-50 hover:font-medium text-left">
                                        <Icon
                                          name="trash"
                                          size="16"
                                          color="#1D2939"
                                        />
                                        <span>Sil</span>
                                      </button>
                                    </li>
                                  )}

                                </ul>
                              )}
                            </div>
                          )
                        }}
                        alignment={TableStates.cellAlignments}
                        cssClass={`${TableStates.cellCssClass} !py-[23px]`}
                      />
                      {/*TABLE BODY*/}
                    </DataGrid>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}

export default PageManager
