import { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"

import { TitleH1 } from "components"
import Header from "components/Headers/MainHeader"
import Content from "components/Contracts/Content"
import ContractsSkeleton from "skeletons/Conracts"

import { agreementRequested } from "redux/actions/getAgreement"

const Contracts = ({ isLogged }) => {
  const dispatch = useDispatch()

  const agreements = useSelector((state) => state.getAgreement.list?.agreements)
  const agreementLoading = useSelector((state) => state.getAgreement.loading)
  const agreementError = useSelector((state) => state.getAgreement.error)

  const [ActiveDropDownValue, setActiveDropDownValue] = useState()
  const [ActiveContracts, setActiveContracts] = useState({})
  const [ActiveDropdownIndex, setActiveDropdownIndex] = useState(-1)

  const toggleDropdown = (index) => {
    setActiveDropdownIndex(index === ActiveDropdownIndex ? -1 : index)
  }

  useEffect(() => {
    dispatch(agreementRequested())
  }, [dispatch])

  return (
    <>
      <Header isLogged={isLogged} />
      {agreementLoading && <ContractsSkeleton />}
      {!agreementLoading && !agreementError && (
        <main>
          <section className="p-10 my-0 mb-20 mx-auto max-w-[1440px]">
            {!ActiveDropDownValue && (
              <div className="page-top-area flex items-center mb-6">
                <div className="page-title">
                  <TitleH1 className="text-3xl font-bold">Sözleşmeler</TitleH1>
                  <p className="font-normal mt-1">
                    İşletmeniz ve müşterilerinizin yasal haklarını koruyan ilgili metinleri düzenleyin.
                  </p>
                </div>
              </div>
            )}
            {ActiveDropDownValue && (
              <div className="page-top-area flex justify-between items-center mb-6 text-right">
                <div className="page-title">
                  <TitleH1 className="text-3xl font-bold">{ActiveContracts.title}</TitleH1>
                </div>
                {ActiveDropDownValue === "view" && (
                  <button
                    className="btn lg"
                    onClick={() => setActiveDropDownValue("edit")}
                  >
                    Düzenle
                  </button>
                )}
              </div>
            )}
            <div className="flex flex-col gap-8 pb-24">
              {!ActiveDropDownValue &&
                  agreements && agreements.map(({ type, typeValue, updateDate }, index) => (
                  <Content
                    key={index}
                    title={type}
                    typeValue={typeValue}
                    updateDate={updateDate}
                    isOpen={index === ActiveDropdownIndex}
                    toggleDropdown={() => toggleDropdown(index)}
                    setActiveDropDownValue={setActiveDropDownValue}
                    setActiveContracts={setActiveContracts}
                  />
                ))}
            </div>
          </section>
        </main>
      )}
    </>
  )
}

export default Contracts
