import React, { useState } from "react"
import { Modal, Button } from "rsuite"
import { ReactComponent as InfoSuccess } from "assets/svg/info-success.svg"
/**
 * @param {*} data data of the modal
 * @param {boolean} open Visibility of the modal
 * @param {() => void} onClose on close callback
 * @param {() => void} onOk on ok callback
 * @returns
 */
const SuccessModal = (props) => {
  return (
    <>
      <Modal
        open={props.open}
        onClose={props.onClose}
        className="w-[382px] !mt-0 absolute top-1/2 left-1/2 transform !-translate-x-1/2 !-translate-y-1/2"
      >
        <Modal.Header
          closeButton={false}
          className="text-center flex justify-center"
        >
          <span>
            <InfoSuccess className="w-14 h-14 mx-auto" />
          </span>
        </Modal.Header>
        <Modal.Body className="text-center">
          <p className="font-medium text-gray-900 text-lg">Kullanıcı başarıyla oluşturuldu.</p>
          <p className="font-medium text-sm text-gray-500">Yeni kullanıcı ekleyebilir veya listeye dönebilirsiniz.</p>
        </Modal.Body>
        <Modal.Footer className="flex">
          <Button
            onClick={props.onClose}
            className="btn w-full bg-white text-gray-600 border border-solid border-gray-300 mr-2 hover:!text-white hover:!bg-gray-600 hover:!border-gray-600"
          >
            Listeye Dön
          </Button>
          <Button
            onClick={props.onOk}
            className="btn w-full hover:!text-white"
          >
            Kullanıcı Ekle
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

const useSuccessModal = () => {
  const [open, setOpen] = useState(false)
  const [data, setData] = useState(null)
  /**
   * @param {*} data
   */
  const handleOpen = (data) => {
    setData(data)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return {
    open,
    data,
    handleOpen,
    handleClose,
  }
}

export { useSuccessModal }
export default SuccessModal
