import React, { useCallback, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import Header from "components/Headers/MainHeader"
import { Grid, Row, Col, Divider, Input, SelectPicker, Stack, Form, useToaster, Message } from "rsuite"
import { Icon } from "components"
import DefineDeleteModal, { useDefineDeleteModal } from "components/Defines/DefineDeleteModal"
import { useDefineById, useDefineCrud } from "utils/useDefines"

const Textarea = React.forwardRef((props, ref) => (
  <Input
    {...props}
    as="textarea"
    ref={ref}
  />
))

const DefineEdit = (props) => {
  let { isLogged } = props
  const navigate = useNavigate()
  const { id } = useParams()
  const { data, isLoading } = useDefineById(id || 0)
  const {
    open,
    handleOpen,
    data: modalData,
    ...modalProps
  } = useDefineDeleteModal({
    onSuccess: () => {
      navigate("/defines")
    },
  })
  const handleOnNavigate = useCallback(() => navigate("/defines"), [navigate])
  const [formValue, setFormValue] = useState({
    ...data,
    Status: data?.PropertyValueStatus === 1 ? "active" : "passive",
    Description: data?.PropertyValueDescription,
  })
  const { defineCrud, checkAllFields } = useDefineCrud()
  const [buttonDisable, setButtonDisable] = useState(true)

  const toaster = useToaster()

  const message = (message, type = "info") =>
    toaster.push(
      <Message
        showIcon
        type={type}
        closable
        className="mt-16"
      >
        {message}
      </Message>,
      { duration: 5000, placement: "topEnd" },
    )

  const handleOnDelete = useCallback(() => {
    handleOpen(data)
  }, [data, handleOpen])

  const handeleOnClickCancel = () => navigate("/defines/" + data?.PropertyId)
  const handleOnChangeForm = (value) => {
    setFormValue(value)
    setButtonDisable(checkAllFields(value).disable)
  }

  const handleOnSubmit = () => {
    setButtonDisable(true)

    defineCrud(formValue)
      .then(() => {
        message("Başarılı bir şekilde güncellendi", "success")
      })
      .catch(() => {
        message("Tanım güncellenirken bir hata oluştu. Lütfen tekrar deneyiniz.", "error")
        console.error("Tanım güncellenirken bir hata oluştu. Lütfen tekrar deneyiniz.")
      })
  }

  useEffect(() => {
    if (!data && !isLoading) {
      handleOnNavigate()
    }
  }, [data, handleOnNavigate, isLoading])

  return (
    <>
      <Header isLogged={isLogged} />
      <main>
        <section className="p-10 my-0 mx-auto max-w-[778px]">
          <span
            className="!text-gray-600 flex justify-start items-center gap-2 mt-2 mb-8 cursor-pointer w-fit transition-all hover:drop-shadow"
            onClick={handleOnNavigate}
          >
            <Icon
              name="arrow-left"
              size="20"
              className="!bg-gray-500"
            />
            <h4>Tanımlar</h4>
          </span>
          <Grid fluid>
            <Form
              fluid
              formValue={formValue}
              onChange={handleOnChangeForm}
            >
              <Row gutter={10}>
                <Col
                  xs={24}
                  className="my-2"
                >
                  <h1 className="text-3xl text-gray-800 font-semibold mb-1">Tanım Detayı</h1>
                </Col>
                <Col xs={24}>
                  <div className="w-full my-3 p-7 rounded-lg border border-gray-200 bg-white">
                    <Grid fluid>
                      <Row gutter={10}>
                        <Col
                          xs={24}
                          className="mb-10"
                        >
                          <p>Tanım Türü</p>
                          <Input
                            className="my-2"
                            disabled
                            style={{ width: "100%" }}
                            value={data?.PropertyName}
                          />
                        </Col>
                        <Col xs={12}>
                          <p>Tanım Adı *</p>
                          <Form.Control
                            name="PropertyValue"
                            placeholder="Tanım adı"
                          />
                        </Col>
                        <Col xs={12}>
                          <p>Statü</p>
                          <Form.Control
                            name="Status"
                            placeholder="Statü Seçiniz"
                            data={[
                              {
                                label: "Aktif",
                                value: "active",
                              },
                              {
                                label: "Pasif",
                                value: "passive",
                              },
                            ]}
                            accepter={SelectPicker}
                            searchable={false}
                            cleanable={false}
                            style={{ width: "100%" }}
                          />
                        </Col>
                        <Col
                          xs={24}
                          className="mb-2 mt-5"
                        >
                          <Divider />
                        </Col>
                        <Col xs={24}>
                          <p>Açıklama</p>
                          <Form.Control
                            className="my-2"
                            accepter={Textarea}
                            rows={3}
                            placeholder="Açıklama giriniz"
                            name="Description"
                          />
                        </Col>
                      </Row>
                    </Grid>
                  </div>
                </Col>
                <Col
                  xs={24}
                  className="flex justify-end items-center"
                >
                  <Stack spacing={10}>
                    <div
                      className="btn border border-red-300 bg-white text-red-500 hover:bg-red-500 hover:text-white hover:border-red-500"
                      onClick={handleOnDelete}
                    >
                      Sil
                    </div>
                    <div
                      className="btn border border-gray-300 bg-white text-gray-500 hover:bg-gray-500 hover:text-white hover:border-gray-500"
                      onClick={handeleOnClickCancel}
                    >
                      Vazgeç
                    </div>
                    <div
                      className="btn"
                      onClick={handleOnSubmit}
                      disabled={buttonDisable}
                    >
                      Kaydet
                    </div>
                  </Stack>
                </Col>
              </Row>
            </Form>
          </Grid>
        </section>
      </main>
      <DefineDeleteModal
        {...modalProps}
        open={open}
        data={modalData}
      />
    </>
  )
}

export default DefineEdit
