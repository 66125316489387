import React from "react"
import moment from "moment"
import { BorderBox } from "components"

const ReservationReportsDetailInfo = ({ data, reservationData, searchRequest, displayInformations }) => {
  return (
    <BorderBox className="p-8">
      <div className="flex flex-col">
        <div className="flex gap-4 justify-between">
          <div className="flex flex-col gap-1">
            <span className="text-gray-500">Seyahat Yeri</span>
            <span className="text-gray-800">{searchRequest?.DestinationName}</span>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-gray-500">Otel Adı</span>
            <span className="text-gray-800">{reservationData?.DetailResponse?.Name} </span>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-gray-500">Giriş-Çıkış Tarihleri</span>
            <span className="text-gray-800">
              {`${moment(displayInformations?.CheckInDate).format("DD MMM")}
                -
                ${moment(displayInformations?.CheckOutDate).format("DD MMM")}`}
            </span>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-gray-500">Seyahat Nedeni</span>
            <span className="text-gray-800">{data?.travelReasonName ? data?.travelReasonName : "-"}</span>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-gray-500">Toplam Tutar</span>
            <span className="text-gray-800">
              {Intl.NumberFormat("tr-TR", {
                style: "currency",
                currency: "TRY",
              }).format(data.totalPrice)}
            </span>
          </div>
        </div>
      </div>
    </BorderBox>
  )
}

export default ReservationReportsDetailInfo
