const PickerLocaleType = {
  noResultsText: "Sonuç bulunamadı",
  placeholder: "Lütfen seçiniz",
  searchPlaceholder: "",
  loading: "Lütfen bekleyiniz",
  emptyMessage: "",

  // for CheckTreePicker, MultiCascader
  checkAll: "",

  // for InputPicker
  newItem: "",
  createOption: "",
  Picker: {
    noResultsText: 'Sonuç bulunamadı',
    placeholder: 'Seç',
    searchPlaceholder: 'Ara',
    checkAll: 'Tümü'
  },
}

const index = {
  PickerLocaleType,
}

export default index
