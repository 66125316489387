import React from "react"
import { Icon } from "components"

const BlockListLoadingSkeleton = () => {
  return (
    <div className="cursor-progress border-b border-gray-200 last:border-0">
      <div
        className={`group relative flex items-center capitalize gap-2 py-4 px-3 border-b border-gray-200 select-none last:border-0`}
      >
        <Icon
          name="image"
          size={14}
          color="#1D2939"
          className="shrink-0"
        />
        <div className="flex w-full justify-between items-center my-1.5">
          <div className={`animate-pulse space-y-3 h-2 bg-slate-200 rounded col-span-1 w-5/12`} />
        </div>

        <Icon
          name="more-vertical"
          size={14}
          className="!duration-[100ms]"
          color="#1D2939"
        />
      </div>
      <div
        className={`group relative flex items-center capitalize gap-2 py-4 px-3 border-b border-gray-200 select-none last:border-0`}
      >
        <Icon
          name="image"
          size={14}
          color="#1D2939"
          className="shrink-0"
        />
        <div className="flex w-full justify-between items-center my-1.5">
          <div className={`animate-pulse space-y-3 h-2 bg-slate-200 rounded col-span-1 w-3/12`} />
        </div>

        <Icon
          name="more-vertical"
          size={14}
          className="!duration-[100ms]"
          color="#1D2939"
        />
      </div>
      <div
        className={`group relative flex items-center capitalize gap-2 py-4 px-3 border-b border-gray-200 select-none last:border-0`}
      >
        <Icon
          name="image"
          size={14}
          color="#1D2939"
          className="shrink-0"
        />
        <div className="flex w-full justify-between items-center my-1.5">
          <div className={`animate-pulse space-y-3 h-2 bg-slate-200 rounded col-span-1 w-4/12`} />
        </div>

        <Icon
          name="more-vertical"
          size={14}
          className="!duration-[100ms]"
          color="#1D2939"
        />
      </div>
      <div
        className={`group relative flex items-center capitalize gap-2 py-4 px-3 border-b border-gray-200 select-none last:border-0`}
      >
        <Icon
          name="image"
          size={14}
          color="#1D2939"
          className="shrink-0"
        />
        <div className="flex w-full justify-between items-center my-1.5">
          <div className={`animate-pulse space-y-3 h-2 bg-slate-200 rounded col-span-1 w-2/12`} />
        </div>

        <Icon
          name="more-vertical"
          size={14}
          className="!duration-[100ms]"
          color="#1D2939"
        />
      </div>
    </div>
  )
}
const ReadyBlocksLoadingSkeleton = () => {
  return (
    <div
      className={"list-item"}
    >
      {[1,2,3,4,5].map(n => {
        return (
          <div key={n} className="mb-7 last:mb-0 cursor-progress">
            <div className="flex w-full justify-between items-center my-1.5">
              <div className={`animate-pulse space-y-3 h-2 bg-slate-200 rounded col-span-1 w-3/12`} />
            </div>
            <div className={"group relative border border-gray-200 rounded-lg mt-2"}>
              <div className="hover-overlay flex items-center justify-center h-full w-full absolute bg-gray-300/[.06] opacity-0 rounded-lg index-10 transition group-hover:opacity-100">

              </div>
              <div className={"image-area p-2"}>
                <div className="w-full justify-between items-center my-1.5">
                  <div className={`animate-pulse space-y-3 h-24 bg-slate-200 rounded col-span-1 w-full`} />
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

const StrategyLoadingSkeleton = () => {
  return (
    <div className="loader-wrapper absolute left-0 top-0 w-full h-full overflow-hidden bg-gray-100 z-[101]">
      <div className="animate-pulse">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="569px"
          height="449px"
          viewBox="0 0 569 449"
          fill="none"
          className="w-full h-auto"
        >
          <ellipse
            cx="292"
            cy="204.496"
            rx="207"
            ry="204.496"
            fill="#F2F4F7"
          />
          <g filter="url(#filter0_d_272_123668)">
            <path
              d="M37.2061 68.4313C36.8395 64.3054 39.8877 60.6706 44.0143 60.3128L356.814 33.1905C360.941 32.8327 364.583 35.8873 364.95 40.0132L381.235 223.317C381.602 227.443 378.554 231.078 374.427 231.436L61.6274 258.558C57.5007 258.916 53.8583 255.861 53.4917 251.735L37.2061 68.4313Z"
              fill="white"
            />
            <path
              d="M37.2061 68.4313C36.8395 64.3054 39.8877 60.6706 44.0143 60.3128L356.814 33.1905C360.941 32.8327 364.583 35.8873 364.95 40.0132L381.235 223.317C381.602 227.443 378.554 231.078 374.427 231.436L61.6274 258.558C57.5007 258.916 53.8583 255.861 53.4917 251.735L37.2061 68.4313Z"
              stroke="#EAECF0"
            />
            <path
              d="M37.2061 68.4313C36.8395 64.3054 39.8877 60.6706 44.0143 60.3128L356.814 33.1905C360.941 32.8327 364.583 35.8873 364.95 40.0132L366.322 55.4555L38.5781 83.8736L37.2061 68.4313Z"
              fill="white"
            />
            <rect
              x="0.325427"
              y="0.272908"
              width="5.4"
              height="5.4"
              rx="2.7"
              transform="matrix(0.996262 -0.0863841 0.0884961 0.996077 44.7437 68.8102)"
              fill="#98A2B3"
              stroke="#98A2B3"
              strokeWidth="0.6"
            />
            <rect
              x="0.325427"
              y="0.272908"
              width="5.4"
              height="5.4"
              rx="2.7"
              transform="matrix(0.996262 -0.0863841 0.0884961 0.996077 53.71 68.0328)"
              fill="#98A2B3"
              stroke="#98A2B3"
              strokeWidth="0.6"
            />
            <rect
              x="0.325427"
              y="0.272908"
              width="5.4"
              height="5.4"
              rx="2.7"
              transform="matrix(0.996262 -0.0863841 0.0884961 0.996077 62.6763 67.2553)"
              fill="#98A2B3"
              stroke="#98A2B3"
              strokeWidth="0.6"
            />
            <path
              d="M37.2061 68.4313C36.8395 64.3054 39.8877 60.6706 44.0143 60.3128L356.814 33.1905C360.941 32.8327 364.583 35.8873 364.95 40.0132L366.322 55.4555L38.5781 83.8736L37.2061 68.4313Z"
              stroke="#EAECF0"
            />
            <path
              d="M60.647 101.235C58.4461 101.426 56.8204 103.364 57.0159 105.565L57.7239 113.533C57.9194 115.734 59.8621 117.363 62.0629 117.172L133.794 110.952C135.995 110.762 137.62 108.823 137.425 106.623L136.717 98.654C136.521 96.4535 134.579 94.8244 132.378 95.0152L60.647 101.235Z"
              fill="#F9FAFB"
            />
            <path
              d="M62.771 125.141C60.5701 125.331 58.9445 127.27 59.14 129.47L59.8479 137.439C60.0434 139.64 61.9861 141.269 64.187 141.078L175.768 131.403C177.969 131.212 179.595 129.273 179.399 127.073L178.691 119.104C178.496 116.904 176.553 115.275 174.352 115.466L62.771 125.141Z"
              fill="#F9FAFB"
            />
            <path
              d="M61.264 153.376C61.0685 151.176 62.6942 149.237 64.895 149.047L200.387 137.298C202.588 137.108 204.53 138.737 204.726 140.937L205.434 148.906C205.629 151.106 204.003 153.045 201.803 153.236L66.311 164.984C64.1101 165.175 62.1675 163.546 61.972 161.345L61.264 153.376Z"
              fill="#F9FAFB"
            />
            <path
              d="M67.0186 172.952C64.8177 173.143 63.192 175.082 63.3875 177.282L64.0955 185.251C64.291 187.451 66.2336 189.08 68.4345 188.89L156.106 181.288C158.306 181.097 159.932 179.158 159.737 176.958L159.029 168.989C158.833 166.789 156.891 165.16 154.69 165.351L67.0186 172.952Z"
              fill="#F9FAFB"
            />
            <rect
              width="111.341"
              height="144.019"
              rx="4"
              transform="matrix(0.996262 -0.0863841 0.0884961 0.996077 241.221 82.8662)"
              fill="#F2F4F7"
            />
            <rect
              width="170.048"
              height="40.0052"
              rx="4"
              transform="matrix(0.996262 -0.0863841 0.0884961 0.996077 64.8804 202.561)"
              fill="#F2F4F7"
            />
            <path
              d="M278.056 149.874L273.211 137.155L276.345 136.883L279.452 145.852L279.571 145.842L281.099 136.47L284.199 136.201L287.353 145.115L287.48 145.104L288.928 135.791L292.07 135.518L289.535 148.878L286.334 149.156L283.106 140.556L282.927 140.572L281.265 149.596L278.056 149.874Z"
              fill="#D0D5DD"
            />
            <path
              d="M298.067 148.138L293.222 135.418L296.355 135.146L299.463 144.116L299.582 144.106L301.11 134.734L304.209 134.465L307.363 143.379L307.491 143.368L308.939 134.054L312.08 133.782L309.546 147.142L306.345 147.42L303.116 138.82L302.938 138.835L301.276 147.859L298.067 148.138Z"
              fill="#D0D5DD"
            />
            <path
              d="M318.077 146.401L313.233 133.682L316.366 133.41L319.474 142.379L319.593 142.369L321.121 132.997L324.22 132.728L327.374 141.642L327.502 141.631L328.949 132.318L332.091 132.045L329.557 145.405L326.356 145.683L323.127 137.083L322.949 137.099L321.287 146.123L318.077 146.401Z"
              fill="#D0D5DD"
            />
          </g>
          <g filter="url(#filter1_d_272_123668)">
            <path
              d="M202.89 171.019C203.22 166.89 206.836 163.805 210.966 164.128L523.988 188.617C528.118 188.94 531.197 192.549 530.866 196.678L516.162 380.112C515.831 384.241 512.215 387.326 508.086 387.003L195.063 362.514C190.934 362.191 187.854 358.582 188.185 354.453L202.89 171.019Z"
              fill="white"
            />
            <path
              d="M202.89 171.019C203.22 166.89 206.836 163.805 210.966 164.128L523.988 188.617C528.118 188.94 531.197 192.549 530.866 196.678L516.162 380.112C515.831 384.241 512.215 387.326 508.086 387.003L195.063 362.514C190.934 362.191 187.854 358.582 188.185 354.453L202.89 171.019Z"
              stroke="#EAECF0"
            />
            <path
              d="M202.89 171.019C203.221 166.89 206.837 163.805 210.967 164.128L523.989 188.617C528.119 188.94 531.198 192.549 530.867 196.678L529.628 212.131L201.652 186.472L202.89 171.019Z"
              fill="white"
            />
            <rect
              x="0.275115"
              y="0.322439"
              width="5.4"
              height="5.4"
              rx="2.7"
              transform="matrix(0.996954 0.0779948 -0.0799045 0.996803 210.314 172.582)"
              fill="#98A2B3"
              stroke="#98A2B3"
              strokeWidth="0.6"
            />
            <rect
              x="0.275115"
              y="0.322439"
              width="5.4"
              height="5.4"
              rx="2.7"
              transform="matrix(0.996954 0.0779948 -0.0799045 0.996803 219.287 173.284)"
              fill="#98A2B3"
              stroke="#98A2B3"
              strokeWidth="0.6"
            />
            <rect
              x="0.275115"
              y="0.322439"
              width="5.4"
              height="5.4"
              rx="2.7"
              transform="matrix(0.996954 0.0779948 -0.0799045 0.996803 228.26 173.986)"
              fill="#98A2B3"
              stroke="#98A2B3"
              strokeWidth="0.6"
            />
            <path
              d="M202.89 171.019C203.221 166.89 206.837 163.805 210.967 164.128L523.989 188.617C528.119 188.94 531.198 192.549 530.867 196.678L529.628 212.131L201.652 186.472L202.89 171.019Z"
              stroke="#EAECF0"
            />
            <rect
              width="296.576"
              height="88.0099"
              rx="8"
              transform="matrix(0.996954 0.0779948 -0.0799045 0.996803 216.987 204.225)"
              fill="#F9FAFB"
              stroke="#D0D5DD"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeDasharray="4 4"
            />
            <path
              d="M211.877 309.012C209.675 308.84 207.746 310.486 207.57 312.688L206.93 320.662C206.754 322.864 208.396 324.789 210.599 324.961L346.184 335.569C348.387 335.741 350.315 334.095 350.492 331.893L351.131 323.919C351.308 321.717 349.665 319.792 347.463 319.62L211.877 309.012Z"
              fill="#F9FAFB"
            />
            <path
              d="M209.96 332.936C207.757 332.763 205.829 334.409 205.652 336.611L205.013 344.585C204.836 346.787 206.479 348.712 208.681 348.885L296.413 355.748C298.616 355.92 300.544 354.275 300.721 352.073L301.36 344.098C301.536 341.896 299.894 339.972 297.692 339.799L209.96 332.936Z"
              fill="#F9FAFB"
            />
            <rect
              width="117.416"
              height="40.0045"
              rx="4"
              transform="matrix(0.996954 0.0779948 -0.0799045 0.996803 387.29 321.877)"
              fill="#F2F4F7"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M328.953 274.146C337.763 274.835 345.477 268.253 346.183 259.445C346.889 250.636 340.319 242.937 331.51 242.248C322.7 241.559 314.986 248.141 314.28 256.949C313.574 265.757 320.143 273.456 328.953 274.146ZM323.687 250.526L330.299 251.044C334.886 251.402 338.307 255.412 337.939 259.999L337.659 263.502C336.376 258.164 331.109 254.375 326.05 253.387C330.617 255.759 333.5 260.753 334.301 266.366L330.797 266.092C326.21 265.733 322.789 261.724 323.157 257.137L323.687 250.526Z"
              fill="#2E90FA"
            />
            <path
              d="M354.303 262.108L368.539 263.221C368.704 262.588 368.831 261.951 368.888 261.233C369.334 255.679 365.723 250.948 360.134 250.51C354.206 250.047 349.817 254.076 349.366 259.706C348.915 265.336 352.569 269.995 358.913 270.491C362.538 270.775 365.488 269.523 367.469 267.092L363.723 264.518C362.805 265.549 361.231 266.224 359.343 266.076C356.775 265.875 354.783 264.655 354.303 262.108ZM354.53 258.323C355.291 255.949 357.178 254.69 359.783 254.894C361.822 255.053 363.773 256.309 364.159 259.076L354.53 258.323Z"
              fill="#1D2939"
            />
            <path
              d="M383.442 252.866L377.775 265.731L374.266 252.148L368.904 251.729L374.564 271.183L380.115 271.618L388.804 253.286L383.442 252.866Z"
              fill="#1D2939"
            />
            <path
              d="M403.286 254.419L403.108 256.648C401.884 254.841 399.93 253.624 397.174 253.408C392.151 253.015 387.649 257.036 387.198 262.666C386.747 268.296 390.552 272.967 395.574 273.36C398.331 273.575 400.455 272.677 401.95 271.083L401.772 273.312L406.643 273.693L408.157 254.8L403.286 254.419ZM396.853 268.783C393.87 268.549 391.815 266.221 392.069 263.047C392.324 259.873 394.724 257.893 397.707 258.127C400.728 258.363 402.783 260.691 402.529 263.865C402.275 267.039 399.874 269.019 396.853 268.783Z"
              fill="#1D2939"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_272_123668"
              x="0"
              y="20.0015"
              width="418.441"
              height="299.746"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood
                floodOpacity="0"
                result="BackgroundImageFix"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feMorphology
                radius="12"
                operator="erode"
                in="SourceAlpha"
                result="effect1_dropShadow_272_123668"
              />
              <feOffset dy="24" />
              <feGaussianBlur stdDeviation="24" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.120313 0 0 0 0 0.299471 0 0 0 0 0.583333 0 0 0 0.08 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_272_123668"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_272_123668"
                result="shape"
              />
            </filter>
            <filter
              id="filter1_d_272_123668"
              x="151.047"
              y="151.006"
              width="416.958"
              height="297.12"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood
                floodOpacity="0"
                result="BackgroundImageFix"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feMorphology
                radius="12"
                operator="erode"
                in="SourceAlpha"
                result="effect1_dropShadow_272_123668"
              />
              <feOffset dy="24" />
              <feGaussianBlur stdDeviation="24" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.120313 0 0 0 0 0.299471 0 0 0 0 0.583333 0 0 0 0.08 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_272_123668"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_272_123668"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </div>
    </div>
  )
}

export { BlockListLoadingSkeleton, ReadyBlocksLoadingSkeleton, StrategyLoadingSkeleton }
