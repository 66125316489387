/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useRef, useState} from "react";

// helpers
import {setSettings} from "helpers/setSettings";

//localization
import localization from "../../localization";

//components
import {SelectPicker} from "rsuite";
import {BorderBox} from "../index";

export const WidthAndHeightVisual = (props) => {
	let {ThisComponent, VisualSettings, setVisualSettings} = props

	const widthUnitTypeWrapperRef= useRef()
	const heighthUnitTypeWrapperRef= useRef()
	const widthInputRef = useRef(null);
	const heightInputRef = useRef(null);

	// const
	const units = ["auto", "px", "%"]
	const unitRegexp = /[^%pxauto]+/gi

	const unitSelectpickerData = units.map((unit) => {
		return {
			label: unit,
			value: unit,
		}
	})

	//states
	const [DefaultUnit] = useState(units[0]) // pattern1+'|'+pattern2, 'gi'
	const [WidthActiveUnit, setWidthActiveUnit] = useState((ThisComponent?.visual?.style?.width && ThisComponent?.visual?.style?.width?.replace(unitRegexp,"")) || DefaultUnit)
	const [HeightActiveUnit, setHeightActiveUnit] = useState((ThisComponent?.visual?.style?.height && ThisComponent?.visual?.style?.height?.replace(unitRegexp,"")) || DefaultUnit)

	//funcs
	const getUnit = (whichProperty, whichUnit) => {
		if (ThisComponent?.visual?.style?.[whichProperty] === DefaultUnit) return DefaultUnit
		return ThisComponent?.visual?.style?.[whichProperty] && ThisComponent?.visual?.style?.[whichProperty].slice(Number(whichUnit ? whichUnit.length : DefaultUnit) * -1);
	}
	const resetFields = (value, ref, key, activeUnit) => {
		if (activeUnit !== value){
			setVisualSettings({
				...ThisComponent?.visual,
				...VisualSettings,
				style: {
					...ThisComponent?.visual?.style,
					...VisualSettings?.style,
					[key]: "auto"
				}
			})
			setTimeout(() => {
				if (ref?.current){
					ref.current.value = undefined
				}
			}, 0)
		}
	}


	useEffect(() => {
		if (ThisComponent?.visual?.style?.width){
			const activeUnit = getUnit("width", WidthActiveUnit)
			setWidthActiveUnit(activeUnit)
		}
		if (ThisComponent?.visual?.style?.height){
			const activeUnit = getUnit("height", HeightActiveUnit)
			setHeightActiveUnit(activeUnit)
		}

	}, [ThisComponent?.visual?.style?.width || ThisComponent?.visual?.style?.height])


	return (
		<BorderBox className="gap-3 p-3">
			<div className="relative input-wrapper flex items-center border-t pt-3 first:p-0 first:border-t-0">
				<label
					htmlFor="widthInput"
					className="grow"
				>
					Genişlik (px, %)
				</label>

				{units.map(unit => {
					if (unit === DefaultUnit){
						return false
					}
					return <input
						hidden={WidthActiveUnit !== unit}
						key={unit}
						name="width"
						ref={widthInputRef}
						placeholder={WidthActiveUnit === "px" ? "1040" : "100"}
						style={{width: "90px"}}
						className={`!h-[26px] !py-0 !pl-0 !text-blue-500 !text-sm !border-0 bg-gray-100 text-right !pr-[52px]`}
						type="number"
						value={ThisComponent?.visual?.style?.width && ThisComponent?.visual?.style?.width?.replace(WidthActiveUnit, "")}
						onChange={(e) =>
							setSettings(e, false, VisualSettings, undefined, WidthActiveUnit).then((res) => setVisualSettings(res))
						}
					/>
				})}
				<div
					ref={widthUnitTypeWrapperRef}
					className="inline-selectpicker-wrapper absolute w-[50px] right-0 top-1">
					<SelectPicker
						block
						className="!border-none"
						placement="bottomStart"
						value={WidthActiveUnit || DefaultUnit}
						menuMaxHeight={164}
						cleanable={false}
						searchable={false}
						container={widthUnitTypeWrapperRef.current}
						onChange={(value) => {
							if (value === DefaultUnit){
								resetFields(value, widthInputRef, "width", WidthActiveUnit)
							}
							setWidthActiveUnit(value)
						}}
						size="xs"
						data={unitSelectpickerData}
						locale={localization.TR.PickerLocaleType.Picker}
						//onChange={(value) => console.log(value)}
					/>
				</div>
			</div>
			<div className="relative input-wrapper flex items-center border-t pt-3 first:p-0 first:border-t-0">
				<label
					htmlFor="heightInput"
					className="grow"
				>
					Yükseklik (px)
				</label>

				{units.map(unit => {
					if (unit === DefaultUnit || unit === "%"){
						return false
					}
					return <input
						hidden={HeightActiveUnit !== unit}
						key={unit}
						name="height"
						ref={heightInputRef}
						style={{width: "90px"}}
						placeholder={HeightActiveUnit === "px" ? "" : "100"}
						className={`!h-[26px] !py-0 !pl-0 !text-blue-500 !text-sm !border-0 bg-gray-100 text-right !pr-[52px]`}
						type="number"
						value={ThisComponent?.visual?.style?.height && ThisComponent?.visual?.style?.height?.replace(HeightActiveUnit, "")}
						onChange={(e) =>
							setSettings(e, false, VisualSettings, undefined, HeightActiveUnit).then((res) => setVisualSettings(res))
						}
					/>
				})}
				<div
					ref={heighthUnitTypeWrapperRef}
					className="inline-selectpicker-wrapper absolute w-[50px] right-0 top-4">
					<SelectPicker
						block
						className="!border-none"
						placement="bottomStart"
						value={HeightActiveUnit || DefaultUnit}
						menuMaxHeight={164}
						cleanable={false}
						searchable={false}
						container={heighthUnitTypeWrapperRef.current}
						onChange={(value) => {
							if (value === DefaultUnit){
								resetFields(value, heightInputRef, "height", HeightActiveUnit)
							}
							setHeightActiveUnit(value)
						}}
						size="xs"
						data={unitSelectpickerData.slice(0, 2)}
						locale={localization.TR.PickerLocaleType.Picker}
					/>
				</div>
			</div>
		</BorderBox>
	)
}