import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { CircularProgressbar, buildStyles } from "react-circular-progressbar"
import Cookies from "universal-cookie"
// import { Link } from "react-router-dom"

import IntegrationSettinsSkeleton from "skeletons/integrationSettings"

import { TitleH1, Modal, Icon } from "components"
import Desktop404 from "components/IntegrationSettings/PreviewDesktop/Desktop404"
import Mobile404 from "components/IntegrationSettings/PreviewMobile/Mobile404"
import DesktopView from "components/IntegrationSettings/PreviewDesktop/DesktopView"
import MobileView from "components/IntegrationSettings/PreviewMobile/MobileView"

import data from "./data.json"

import { domainStateGetRequested } from "redux/actions/domainStateGet"
import { domainStateUptadeRequested } from "redux/actions/domainStateUptade"
import { clientConfigGetRequested } from "redux/actions/clientConfigurationGet"

const cookies = new Cookies()

const B2CIntegrationSettings = (props) => {
  let { domainName, domainType } = props

  const dispatch = useDispatch()
  const DomainValue = cookies.get("domainValue")

  const domainStateGetError = useSelector((state) => state.domainStateUptade?.error)
  const domainStateGetLoading = useSelector((state) => state.domainStateGet?.loading)
  const domainStateUptadeLoading = useSelector((state) => state.domainStateUptade?.loading)
  const clientConfigurationGet = useSelector((state) => state.clientConfigurationGet?.data)
  const clientConfigurationGetLoading = useSelector((state) => state.clientConfigurationGet?.loading)

  const [title, setTitle] = useState("")
  const [desc, setDesc] = useState("")
  const [actionText, setActionText] = useState("")
  const [status, setStatus] = useState(false)
  const [value, setValue] = useState()
  const [percentage, setPercentage] = useState(10)
  const [loadingText, setLoadingText] = useState("")
  const [openLoadingPopup, setOpenLoadingPopup] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [skeleton, setSkeleton] = useState(false)

  const publishClick = () => {
    let obj = {
      domainName,
      domainState: value,
    }
    cookies.set("domainValue", value)
    dispatch(domainStateUptadeRequested(obj))
  }

  const openLoadingModal = () => {
    setOpenModal(false)
    setOpenLoadingPopup(true)
    if (openLoadingModal) {
      !domainStateUptadeLoading && setStatus(!status)
    }
  }

  const logo = clientConfigurationGet?.logoUrl
  const color = clientConfigurationGet?.configurations.find(
    ({ configurationMetadataCode }) => configurationMetadataCode === "color",
  )?.properties?.color
  const borderRadius = clientConfigurationGet?.configurations.find(
    ({ configurationMetadataCode }) => configurationMetadataCode === "theme",
  )?.properties?.borderRadius
  const fontFamily = clientConfigurationGet?.configurations.find(
    ({ configurationMetadataCode }) => configurationMetadataCode === "theme",
  )?.properties?.fontFamily

  useEffect(() => {
    setTimeout(() => {
      if (percentage < 100) {
        setPercentage(percentage + 10)
      } else if (percentage === 100) {
        setOpenLoadingPopup(false)
        setPercentage(0)
        setSkeleton(false)
      }
    }, 300)
  }, [percentage])

  useEffect(() => {
    dispatch(clientConfigGetRequested())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let obj = {
      domainName,
    }
    dispatch(domainStateGetRequested(obj))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domainName])

  useEffect(() => {
    DomainValue === "1" ? setStatus(true) : setStatus(false)
  }, [DomainValue, value])

  useEffect(() => {
    domainStateGetLoading && clientConfigurationGetLoading ? setSkeleton(true) : setSkeleton(false)
  }, [clientConfigurationGetLoading, domainStateGetLoading])

  return (
    <>
      {skeleton && <IntegrationSettinsSkeleton />}
      {!domainStateGetLoading && !skeleton && !domainStateGetError && (
        <div>
          <TitleH1 className="my-6">Yayınlama Ayarları</TitleH1>
          <div className="grid grid-cols-2 bg-white border border-solid border-gray-200 p-12 mb-10 gap-24">
            <div className="">
              <div className="flex flex-row justify-between mb-1">
                <p className="text-xl justify-start font-semibold">
                  {DomainValue === "2" ? "Lütfen Bekleyiniz" : "Kanalın hazır!"}
                </p>
                <div className="justify-end" />
              </div>
              <p className="text-base  text-gray-500 mb-8">
                {DomainValue !== "2" &&
                  (status
                    ? "Rezervasyon almaya ara vermek için yayını durdurabilirsin."
                    : "Rezervasyon almaya başlamak için kanalınızı yayına almalısınız.")}
                {DomainValue === "2" && "Kanalınız yayınlanıyor..."}
              </p>
              <div className="mb-8">
                <p className="text-base justify-start font-semibold mb-2">Yayın Durumu</p>
                {status ? (
                  <div className="border rounded-lg flex justify-between items-center p-4">
                    <div className=" text-success-500 bg-success-50 rounded-2xl flex items-center justify-between px-[10px] py-1">
                      <Icon
                        className="mr-2"
                        name="dot"
                        color="#12B76A"
                        size="14"
                      />
                      <span className="text-sm">Yayında</span>
                    </div>
                    <button
                      className="btn outline"
                      onClick={() => {
                        setTitle(data.offlineTitle)
                        setDesc(data.offlineDesc)
                        setLoadingText("Sayfanızın yayını durduruluyor...")
                        setActionText("Evet, durdur")
                        setOpenModal(true)
                        setValue(0)
                      }}
                    >
                      Yayını Durdur
                    </button>
                  </div>
                ) : (
                  <div className="border rounded-lg flex justify-between items-center p-4">
                    <div className=" text-warning-500 bg-warning-50 rounded-2xl flex items-center justify-between px-[10px] py-1">
                      <Icon
                        className="mr-2"
                        name="dot"
                        color=" #F79009"
                        size="14"
                      />
                      Yayında değil
                    </div>
                    <button
                      className="btn sm w-24 focus:outline-none"
                      onClick={() => {
                        setTitle(data.onlineTitle)
                        setDesc(data.onlineDesc)
                        setLoadingText("Sayfanız yayınlanıyor...")
                        setActionText("Evet, yayınla")
                        setOpenModal(true)
                        setValue(1)
                      }}
                    >
                      Yayınla
                    </button>
                  </div>
                )}
              </div>
              <div className="relative">
                <p className="text-base justify-start font-semibold mb-2">Bağlantı linki</p>

                <a
                  href={`https://${domainName}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <input
                    className="border w-full text-blue-500 !pr-16"
                    type="text"
                    readOnly
                    defaultValue={domainName}
                  />
                  <Icon
                    className="mr-2 absolute right-4 top-[45px]"
                    name="external-link"
                    color=" #2E90FA"
                    size="16"
                  />
                </a>
              </div>
              {/* {domainType !== "B2C" && (
                <div className="bg-white pt-8">
                  <Link
                    to="/integration-settings/publishing"
                    className="flex justify-center px-4 py-2 hover:shadow-md hover:border-gray-100 items-center text-sm text-gray-500 border rounded-lg border-gray-200 w-1/3 "
                  >
                    <Icon
                      className="mr-2"
                      name="button"
                      color="#667085"
                      size="16"
                    />
                    <span>Bağlantı Ayarları</span>
                  </Link>
                </div>
              )} */}
            </div>
            <div className="flex flex-col gap-3">
              <p className="text-base justify-start font-semibold">Ön İzleme</p>
              {clientConfigurationGet && (
                <div className="relative">
                  <div>
                    {status ? (
                      <DesktopView
                        logo={logo}
                        color={color}
                        borderRadius={borderRadius}
                        fontFamily={fontFamily}
                        domainType={domainType}
                      />
                    ) : (
                      <Desktop404
                        logo={logo}
                        color={color}
                        borderRadius={borderRadius}
                        fontFamily={fontFamily}
                        data={clientConfigurationGet}
                      />
                    )}
                  </div>
                  <div className="absolute top-[23%] right-[-5%] z-30">
                    {status ? (
                      <MobileView
                        logo={logo}
                        color={color}
                        borderRadius={borderRadius}
                        fontFamily={fontFamily}
                        domainType={domainType}
                      />
                    ) : (
                      <Mobile404
                        logo={logo}
                        color={color}
                        borderRadius={borderRadius}
                        fontFamily={fontFamily}
                        data={clientConfigurationGet}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      <Modal
        onChange={() => {
          setOpenModal(false)
        }}
        open={openModal}
        title={title}
        width={600}
        closeButton={true}
      >
        <p className="text-base ">{desc}</p>
        <div className="flex flex-row w-full justify-end mt-8">
          <button
            onClick={() => setOpenModal(false)}
            className="btn lg outline mr-3"
          >
            Vazgeç
          </button>
          <button
            onClick={() => {
              openLoadingModal()
              publishClick()
              setSkeleton(true)
            }}
            className="btn lg"
          >
            {actionText}
          </button>
        </div>
      </Modal>

      <Modal
        onChange={() => {
          setOpenLoadingPopup(false)
        }}
        open={openLoadingPopup}
        width={600}
      >
        <div className="login-box bg-white">
          <div className="flex flex-col justify-center items-center">
            <div className="w-36">
              <CircularProgressbar
                value={percentage}
                text={`${percentage}%`}
                styles={buildStyles({ textColor: "#000", pathColor: "#2E90FA" })}
              />
            </div>
            <p className="text-lg font-medium my-6">{loadingText}</p>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default B2CIntegrationSettings
