import React, { useState } from "react"
import PropTypes from "prop-types"

//components
import { Icon } from "components"

const AlignmentBlock = (props) => {
  let { onClick, position, selected } = props

  const [Lines] = useState([`w-full`, `w-full`, `w-1/2`])

  return (
    <div className="alignment-block group flex flex-col w-full">
      <div
        onClick={onClick}
        className={`relative w-full flex flex-col items-${position} border ${
          selected ? "!border-blue-500" : "cursor-pointer border-gray-200"
        } gap-2 rounded-lg py-3 px-2 transition hover:border-blue-300`}
      >
        <div
          className={`absolute inline-flex -right-2 -top-2 p-0.5 rounded-full bg-blue-500 opacity-0${
            selected ? " !opacity-100" : ""
          } transition group-hover:opacity-50`}
        >
          <Icon
            name={"check"}
            size={12}
            color={"white"}
          />
        </div>
        {Lines.map((className, key) => {
          return (
            <div
              key={key}
              className={`${className} h-1.5 rounded-lg ${key === 2 ? ` bg-blue-500` : "bg-gray-200"}`}
            />
          )
        })}
      </div>

      <p className={"text-xs text-center text-gray-800 capitalize mt-2"}>{`${position === "start" ? "Sola" : position === "center" ? "Ortaya" : "Sağa"} Hizalı`}</p>
    </div>
  )
}

export default AlignmentBlock

AlignmentBlock.propTypes = {
  position: PropTypes.string,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
}
AlignmentBlock.defaultProps = {
  position: "start", // center , end
  selected: false,
}
