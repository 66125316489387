export const IconListDummy = {
  "name": "Icon List",
  "order": null,
  "children": [
    {
      "name": "Icon",
      "data": {
        "text": "null",
        "mediaPath": "https://assets-v2.lottiefiles.com/a/490bc92a-1170-11ee-9e35-0f6eacef4099/DBwCBrM6eQ.gif",
        "url": "",
        "target": "_blank"
      }
    }
  ]
}