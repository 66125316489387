import React from "react"
import PropTypes from "prop-types"

const Subtitle = (props) => {
  let { className, children } = props
  return <p className={`text-lg font-semibold text-gray-800 ${className}`}>{children}</p>
}

export default Subtitle

Subtitle.propTypes = {
  className: PropTypes.string,
}

Subtitle.defaultProps = {
  className: "",
}
