import { call, fork, put, takeLatest } from "redux-saga/effects"
import * as actions from "redux/actions/myReservationsApprove"

import { MY_RESERVATIONS_APPROVE_REQUESTED } from "redux/types"
import { API_POST } from "helpers/api"

function* watchMyReservationsApprove() {
  yield takeLatest(MY_RESERVATIONS_APPROVE_REQUESTED, getMyReservationsApprove)
}

function* getMyReservationsApprove(action) {
  try {
    const res = yield call(apiMyReservationsApprove, action.payload)
    yield put(actions.myReservationsApproveSucceed(res?.data))
  } catch (err) {
    yield put(actions.myReservationsApproveFailed())
  }
}

const apiMyReservationsApprove = (payload) => {
  return API_POST(`${process.env.REACT_APP_PANEL_DOMAIN}/api/reservation/approve`, payload)
}

export default function* myReservationsApprove() {
  yield fork(watchMyReservationsApprove)
}
