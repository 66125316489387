/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react"
import {useSelector} from "react-redux"

// layouts
import BlockWrapper from "../../BlockWrapper"
import {TitleLayout, SubTitleLayout, ButtonLayout} from "../../Design"
import Settings from "../../Design/Settings"

// components
import { TabList, SideMenuListItem } from "components"

// utils
import { isProdEnv } from "utils/environmentsCheck"
import getSelectedIndex from "utils/getSelectedIndex"

//helpers
import {
  newTPComponentHotel,
  newTPComponentTour,
  newTPComponentTransferAgency,
  newTPComponentTransferCorporate,
  newTPComponentFlight,
  newTPComponentCarRentalAgency,
  newTPComponentCarRentalCorporate,
  TRAVEL_PLANNER_HOTEL,
  TRAVEL_PLANNER_TOUR,
  TRAVEL_PLANNER_TRANSFER_AGENCY,
  TRAVEL_PLANNER_TRANSFER_CORPORATE,
  TRAVEL_PLANNER_FLIGHT,
  TRAVEL_PLANNER_CAR_RENTAL_AGENCY,
  TRAVEL_PLANNER_CAR_RENTAL_CORPORATE,
  TabListTabLabels,
} from "../../../Enums";

const TravelPlanner = (props) => {
  let { show, activeLayout, setEditLayouts, travelPlannerType } = props

  let infoCodeForStrategy = activeLayout?.info?.code
  let code = infoCodeForStrategy || activeLayout?.code

  let TpType
  if (travelPlannerType === TRAVEL_PLANNER_HOTEL){
    TpType = newTPComponentHotel(code, activeLayout?.order)
  } else if (travelPlannerType === TRAVEL_PLANNER_TOUR){
    TpType = newTPComponentTour(code, activeLayout?.order)
  } else if (travelPlannerType === TRAVEL_PLANNER_TRANSFER_AGENCY){
    TpType = newTPComponentTransferAgency(code, activeLayout?.order)
  } else if (travelPlannerType === TRAVEL_PLANNER_TRANSFER_CORPORATE){
    TpType = newTPComponentTransferCorporate(code, activeLayout?.order)
  } else if (travelPlannerType === TRAVEL_PLANNER_FLIGHT){
    TpType = newTPComponentFlight(code, activeLayout?.order)
  } else if (travelPlannerType === TRAVEL_PLANNER_CAR_RENTAL_AGENCY){
    TpType = newTPComponentCarRentalAgency(code, activeLayout?.order)
  } else if (travelPlannerType === TRAVEL_PLANNER_CAR_RENTAL_CORPORATE){
    TpType = newTPComponentCarRentalCorporate(code, activeLayout?.order)
  }

  // selectors
  const components = useSelector(state => state.strategy.getStrategy.list?.result)

  // states
  const [EditBlock, setEditBlock] = useState(null)
  const [ComponentDisplayTab, setComponentDisplayTab] = useState(0)
  const [TPType, setTPType] = useState(TpType)
  const [ThisComponent, setThisComponent] = useState((components && components?.children && components?.children.find(child => child?.code === infoCodeForStrategy)) || TPType)
  const [UploadedImageInfo, setUploadedImageInfo] = useState(null)
  const [DefaultImageInfo, setDefaultImageInfo] = useState(ThisComponent?.data?.backgroundImagePath)


  // Func
  const editComponentDisplayTabChanged = (e) => {
    let selectedIndex = getSelectedIndex(e)
    setComponentDisplayTab(selectedIndex)
  }

  const saveChangesOrGoBack = async () => {
    setEditLayouts(null)
  }

  useEffect(() => {
    if (!TPType){
      setTPType(TPType)
    }
  }, [TPType, TpType])

  useEffect(() => {
    if (activeLayout?.order !== undefined && typeof activeLayout?.order === "number"){
      setThisComponent({
        ...ThisComponent,
        order: activeLayout?.order
      })
    }
  }, [activeLayout])

  return (
    <BlockWrapper
      show={show}
      activeLayout={activeLayout}
      setEditLayouts={setEditLayouts}
      editBlock={EditBlock}
      setEditBlock={setEditBlock}
      ThisComponent={ThisComponent}
      setThisComponent={setThisComponent}
      doneBtnOnClick={saveChangesOrGoBack}
    >
      <>
        <TabList
          onChange={editComponentDisplayTabChanged}
          tabs={[
            { label: TabListTabLabels.content, icon: "list", disabled: false },
            { label: TabListTabLabels.design, icon: "palette-solid", disabled: false },
            { label: TabListTabLabels.publish, icon: "share", disabled: true },
          ]}
          tabClassName="w-full flex items-center gap-2.5 text-center text-gray-600 text-xs p-2 border-r last:border-r-0 hover:bg-gray-50 transition duration-400"
          wrapperClassName="flex shrink-0 border rounded-lg border-gray-200 overflow-hidden mb-7"
          //itemActiveClass="!text-gray-800 !bg-blue-50"
          activeTab={ComponentDisplayTab}
        />

        {ComponentDisplayTab === 0 && (
          <>
            <div className="input-wrapper border border-gray-200 rounded-lg mb-4">
              <SideMenuListItem
                title="Başlık"
                className="border-t border-gray-200 first:border-t-0"
                onClick={() =>
                  setEditBlock({
                    showTitleLayout: true,
                  })
                }
              />
              <SideMenuListItem
                title="Alt Başlık"
                className="border-t border-gray-200 first:border-t-0"
                onClick={() =>
                  setEditBlock({
                    showSubTitleLayout: true,
                  })
                }
              />
              <SideMenuListItem
                title="Buton"
                className="border-t border-gray-200 first:border-t-0"
                disabled={isProdEnv}
                onClick={() => {
                  setEditBlock({
                    showButtonLayout: true,
                  })
                }}
              />
            </div>
          </>
        )}

        {ComponentDisplayTab === 1 && (
          <Settings
            editBlock={EditBlock}
            setEditBlock={setEditBlock}
            setEditLayouts={setEditLayouts}
            UploadedImageInfo={UploadedImageInfo}
            setUploadedImageInfo={setUploadedImageInfo}
            ThisComponent={ThisComponent}
            setThisComponent={setThisComponent}
            DefaultImageInfo={DefaultImageInfo}
            setDefaultImageInfo={setDefaultImageInfo}
            TPType={TPType}
          />
        )}
      </>

      {/* region sub menus*/}
      <TitleLayout
        editBlock={EditBlock}
        setEditBlock={setEditBlock}
        ThisComponent={ThisComponent}
        setThisComponent={setThisComponent}
      />
      <SubTitleLayout
        editBlock={EditBlock}
        setEditBlock={setEditBlock}
        ThisComponent={ThisComponent}
        setThisComponent={setThisComponent}
      />
      <ButtonLayout
        editBlock={EditBlock}
        setEditBlock={setEditBlock}
        ThisComponent={ThisComponent}
        setThisComponent={setThisComponent}
      />
      {/* endregion sub menus*/}
    </BlockWrapper>
  )
}

export default TravelPlanner
