import * as type from "redux/types"

export const clientConfigGetRequested = (data) => ({
  type: type.CLIENT_CONFIG_GET_REQUESTED,
  payload: data,
})

export const clientConfigGetSucceed = (data) => ({
  type: type.CLIENT_CONFIG_GET_SUCCEEDED,
  payload: data,
})

export const clientConfigGetFailed = () => ({
  type: type.CLIENT_CONFIG_GET_FAILED,
})
