import { Card } from "components"
import CommonAddLayout from "pages/Rules/components/FormLayouts/CommonAddLayout"
import React, { useState, useRef, useEffect, Fragment } from "react"
import { useParams } from "react-router-dom"
import { Button, Grid, Row, Col, Badge, Modal, Whisper, Popover } from "rsuite"
import { ReactComponent as ErrorInfoIcon } from "assets/svg/error-info.svg"
import { useRemoveRuleMutation, useGetRuleById, useRuleTypeList } from "utils/useRules"
import { useNavigate } from "react-router-dom"

const ButtonContent = ({ ruleId }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const { mutateAsync, isPending } = useRemoveRuleMutation()
  const navigate = useNavigate()

  const handleClick = (ruleId) => {
    mutateAsync(ruleId)
      .then(() => {
        setModalOpen(false)
        window.location.href = "/rules"
      })
      .catch(() => {
        setModalOpen(false)
      })
  }

  return (
    <>
      <span className="flex items-center gap-2">
        <Button
          color="red"
          appearance="ghost"
          onClick={() => setModalOpen(true)}
        >
          Sil
        </Button>
        <Button
          className="bg-blue-500 hover:!text-white hover:!bg-blue-400"
          appearance="primary"
          onClick={() => navigate(`/rules/${ruleId}/edit`)}
        >
          Düzenle
        </Button>
      </span>
      <Modal
        open={modalOpen}
        backdrop="static"
        size="xs"
        className="w-[382px] !mt-0 absolute top-1/2 left-1/2 transform !-translate-x-1/2 !-translate-y-1/2"
      >
        <Modal.Body className="text-center">
          <div className="w-full flex justify-center">
            <ErrorInfoIcon />
          </div>
          <p className="text-gray-600 text-lg font-semibold mt-5">Kuralı Sil</p>
          <p className="text-gray-600 mt-2">Kuralı silerseniz ürünlerdeki kurala bağlı sistemler çalışmayacaktır.</p>
        </Modal.Body>
        <Modal.Footer className="flex justify-between">
          <Button
            onClick={() => setModalOpen(false)}
            className="w-full !border !border-solid !border-gray-200 !bg-white !text-gray-500 hover:!bg-gray-100 "
          >
            Vazgeç
          </Button>
          <Button
            onClick={() => handleClick(ruleId)}
            className="w-full !bg-red-600 text-white hover:!bg-red-400 hover:!text-white"
            loading={isPending}
          >
            Sil
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

const DetailRule = (props) => {
  const { id } = useParams()
  const ref = useRef(null)
  const { data, isLoading } = useGetRuleById(id)
  const { data: typeData } = useRuleTypeList()
  const navigate = useNavigate()
  const ruleParameters = JSON.parse(data?.data?.result?.rule?.parameters || "{}")?.Rule || undefined
  const resultParameters = JSON.parse(data?.data?.result?.rule?.parameters || "{}")?.Result || undefined

  useEffect(() => {
    if (!isLoading && !data?.data?.result?.rule) {
      navigate("/rules")
    }
  }, [data?.data?.result?.rule, isLoading, navigate])

  return (
    <CommonAddLayout
      title="Kural Detayı"
      description="Koşullarınıza uygun kural tanımlayın."
      buttonContent={<ButtonContent ruleId={id} />}
      isLoading={isLoading}
      {...props}
    >
      <Grid
        fluid
        className="!px-0"
      >
        <Row>
          <Col xs={24}>
            {/* Genereal Info */}
            <Card
              size="sm"
              title="Genel Bilgiler"
            >
              <Row>
                <Col
                  xs={24}
                  md={12}
                  lg={6}
                  className="text-left"
                >
                  <p className="font-semibold">Kural Tipi</p>
                  <p className="text-gray-500">
                    {(typeData || []).find((item) => item?.id === data?.data?.result?.rule?.type)?.name || "Tanımsız kural tipi"}
                  </p>
                </Col>
                <Col
                  xs={24}
                  md={12}
                  lg={6}
                  className="text-left"
                >
                  <p className="font-semibold">Kural Adı</p>
                  <p className="text-gray-500">{data?.data?.result?.rule?.name}</p>
                </Col>
                <Col
                  xs={24}
                  md={12}
                  lg={6}
                  className="text-left"
                >
                  <p className="font-semibold">Statü</p>
                  {data?.data?.result?.rule?.status ? (
                    <Badge
                      className="p-1 px-2 rounded-xl bg-green-100 text-green-500 font-semibold"
                      content="Aktif"
                    />
                  ) : (
                    <Badge
                      className="p-1 px-2 rounded-xl bg-red-100 text-red-500 font-semibold"
                      content="Pasif"
                    />
                  )}
                </Col>
                <Col
                  xs={24}
                  md={12}
                  lg={6}
                  className="text-left"
                >
                  <p className="font-semibold">Öncelik Sırası</p>
                  <p className="text-gray-500">{data?.data?.result?.rule?.priority}</p>
                </Col>
              </Row>
            </Card>
            {/* Conditions */}
            <Card
              size="sm"
              title="Koşullar"
            >
              <Row>
                {!!ruleParameters &&
                  !!ruleParameters?.children &&
                  ruleParameters?.children.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        {/* Condition Operators */}
                        {index !== 0 && (
                          <Col
                            xs={24}
                            className="flex flex-col items-center justify-center py-2"
                          >
                            <div className="w-full h-[15px] border-x border-b border-gray-200" />
                            <div className="flex items-center justify-center my-[20px]">
                              <p className="text-gray-400 px-2 py-1 border border-gray-200 rounded-xl bg-white z-10 ">
                                {/* We know this index is andor operator info. This parameter same for each sub item */}
                                {item?.relatedCondition === "and" ? "Ve" : "Veya"}
                              </p>
                              <span
                                style={{
                                  height: "74px",
                                  border: "1px solid #e2e2e2",
                                  position: "absolute",
                                  left: "calc(50%)",
                                }}
                              ></span>
                            </div>
                            <div className="w-full h-[15px] border-x border-t border-gray-200" />
                          </Col>
                        )}
                        <Col
                          xs={24}
                          className="text-left mb-5"
                        >
                          <p className="font-semibold">{index + 1}. Kural </p>
                        </Col>
                        <Col xs={24}>
                          <Row className="flex items-center">
                            {/* Condition Items And and OR operator field */}
                            {(item?.children || []).length > 1 && (
                              <Col
                                xs={3}
                                className="flex items-center justify-start multiple-condition"
                              >
                                <p className="text-gray-400 px-2 py-1 border border-gray-200 rounded-xl bg-white z-10 ">
                                  {/* We know this index is andor operator info. This parameter same for each sub item */}
                                  {item?.multipleCondition === "and" ? "Ve" : "Veya"}
                                </p>
                                <span
                                  style={{
                                    width: "35px",
                                    border: "1px solid #e2e2e2",
                                    position: "absolute",
                                    left: "calc(50% - 8px)",
                                  }}
                                ></span>
                              </Col>
                            )}
                            {/* Childrens Field */}
                            <Col xs={(item?.children || []).length > 1 ? 21 : 24}>
                              <Row ref={ref}>
                                {(item?.children || []).length > 0 &&
                                  (item?.children || [])?.map((subItem, subItemIndex) => (
                                    <RuleItem
                                      key={subItemIndex}
                                      connectLineVisible={(item?.children?.length || 0) > 1}
                                      conditionItem={subItem}
                                      conditionItemIndex={subItemIndex}
                                      metadaTypeId={data?.data?.result?.rule?.type}
                                      treeElementVisible={item?.children.length > 1}
                                      isMiddleItem={subItemIndex !== 0 && subItemIndex !== item?.children.length - 1}
                                    />
                                  ))}
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </React.Fragment>
                    )
                  })}
              </Row>
            </Card>
            {/* Conditions */}
            {!!resultParameters &&
              !!resultParameters?.children &&
              !!resultParameters?.children[0]?.children?.[0]?.conditionSelect && (
                <Card
                  size="sm"
                  title="Sonuçlar"
                >
                  <Row>
                    {resultParameters?.children.map((item, index) => {
                      return (
                        <React.Fragment key={index}>
                          <Col xs={24}>
                            <Row className="flex items-center">
                              {/* Childrens Field */}
                              <Col xs={24}>
                                <Row ref={ref}>
                                  {(item?.children || []).length > 0 &&
                                    (item?.children || [])?.map((subItem, subItemIndex) => (
                                      <ResultItem
                                        key={subItemIndex}
                                        connectLineVisible={(item?.children?.length || 0) > 1}
                                        conditionItem={subItem}
                                        conditionItemIndex={subItemIndex}
                                        metadaTypeId={data?.data?.result?.rule?.type}
                                        treeElementVisible={false}
                                        isMiddleItem={subItemIndex !== 0 && subItemIndex !== item?.children.length - 1}
                                      />
                                    ))}
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                        </React.Fragment>
                      )
                    })}
                  </Row>
                </Card>
              )}
          </Col>
        </Row>
      </Grid>
    </CommonAddLayout>
  )
}

const CellWrapper = ({ children, className, xs }) => (
  <Col
    xs={xs || 6}
    className={`flex justify-start items-center  border-gray-100 py-[15px] px-3 ${className}`}
  >
    <div className="line-clamp-1 text-ellipsis">{children}</div>
  </Col>
)

const RuleItem = ({ connectLineVisible = true, conditionItem, conditionItemIndex, treeElementVisible, isMiddleItem }) => {
  const conditionItemValues = Object.entries(conditionItem || {}).filter(([key]) => key.includes("_Text"))
  return (
    <>
      <Col
        xs={24}
        className={`mb-3 ${connectLineVisible ? "condition-card-input-content" : ""}`}
      >
        {/* Cell Row */}
        {conditionItem && (
          <Row className="flex items-stretch border border-gray-100 rounded-xl text-gray-600">
            {/* Cell Item */}
            <CellWrapper>{conditionItem.conditionSelect || "Koşul Adı"}</CellWrapper>
            {conditionItemValues.map(([_, value], index) => {
              const isArray = value?.includes("__")
              if (isArray) {
                const [firstValue, ...values] = value.split("__")
                return (
                  <CellWrapper
                    key={index}
                    className="border-l"
                    xs={12}
                  >
                    {firstValue || "Koşul Adı"}
                    <Whisper
                      trigger="hover"
                      placement={"bottom"}
                      controlId={`control-id-${index}-${conditionItem.conditionSelect}`}
                      speaker={
                        <Popover>
                          {values.map((item, index) => (
                            <p
                              key={index}
                              className="line-clamp-1 text-ellipsis"
                            >
                              &bull; {item}
                            </p>
                          ))}
                        </Popover>
                      }
                    >
                      <span className="text-blue-500 cursor-pointer">{values.length > 0 ? ` +${values.length}` : ""}</span>
                    </Whisper>
                  </CellWrapper>
                )
              }
              return (
                <CellWrapper
                  key={index}
                  className="border-l"
                >
                  {value || "Koşul Adı"}
                </CellWrapper>
              )
            })}
          </Row>
        )}
        {!conditionItem && (
          <Row className="flex items-center border border-gray-100 rounded-xl text-gray-600">
            {/* Cell Item */}
            <CellWrapper>Hatalı Kural Satırı</CellWrapper>
          </Row>
        )}

        {/* Tree Lines */}
        <span
          className="vertical-line"
          style={{
            display: treeElementVisible ? "block" : "none",
            width: " 25px",
            height: " 1px",
            position: " absolute",
            top: " 50%",
            border: " 1px solid #e2e2e2",
            left: " -25px",
          }}
        ></span>
        <span
          className="horizontal-line"
          style={{
            height: conditionItemIndex === 0 ? "calc(50% + 20px)" : isMiddleItem ? "calc(100% + 20px)" : "calc(50% - 0px)",
            width: "1px",
            display: treeElementVisible ? "block" : "none",
            position: "absolute",
            border: "1px solid #e2e2e2",
            top: conditionItemIndex === 0 ? "calc(50% - 0px)" : isMiddleItem ? "0" : "0px",
            left: "-25px",
          }}
        ></span>
      </Col>
    </>
  )
}

const ResultItem = ({ connectLineVisible = true, conditionItem, conditionItemIndex, treeElementVisible, isMiddleItem }) => {
  const conditionItemValues = Object.entries(conditionItem || {}).filter(([key]) => key.includes("_Text"))

  return (
    <>
      <Col
        xs={24}
        className={`${connectLineVisible ? "condition-card-input-content" : ""}`}
      >
        {/* Cell Row */}
        {conditionItem && (
          <>
            {/* Cell Item */}
            {conditionItemValues.map(([key, value], index) => {
              const isArray = value?.includes("__")
              if (isArray) {
                const [firstValue, ...values] = value.split("__")
                return (
                  <Row
                    key={index}
                    className="flex items-stretch border border-gray-100 rounded-xl text-gray-600"
                  >
                    <CellWrapper>{key.split("_", 1)[0] || "-"}</CellWrapper>
                    <CellWrapper
                      key={index}
                      className="border-l"
                      xs={12}
                    >
                      {firstValue || "-"}
                      <Whisper
                        trigger="hover"
                        placement={"bottom"}
                        controlId={`control-id-${index}-${conditionItem.conditionSelect}`}
                        speaker={
                          <Popover>
                            {values.map((item, index) => (
                              <p
                                key={index}
                                className="line-clamp-1 text-ellipsis"
                              >
                                &bull; {item}
                              </p>
                            ))}
                          </Popover>
                        }
                      >
                        <span className="text-blue-500 cursor-pointer">{values.length > 0 ? ` +${values.length}` : ""}</span>
                      </Whisper>
                    </CellWrapper>
                  </Row>
                )
              }
              return (
                <>
                  {/* This criteria comes from when saving rule form's numericinput as empty.  */}
                  {value !== "0" && (
                    <Row
                      key={index}
                      className="flex items-stretch border border-gray-100 rounded-xl text-gray-600 mb-2"
                    >
                      <CellWrapper>{key.split("_", 1)[0] || "-"}</CellWrapper>
                      <CellWrapper className="border-l">{value || "-"}</CellWrapper>
                    </Row>
                  )}
                </>
              )
            })}
          </>
        )}
        {!conditionItem && conditionItem !== null && (
          <Row className="flex items-center border border-gray-100 rounded-xl text-gray-600">
            {/* Cell Item */}
            <CellWrapper>Hatalı Kural Satırı</CellWrapper>
          </Row>
        )}

        {/* Tree Lines */}
        <span
          className="vertical-line"
          style={{
            display: treeElementVisible ? "block" : "none",
            width: " 25px",
            height: " 1px",
            position: " absolute",
            top: " 50%",
            border: " 1px solid #e2e2e2",
            left: " -25px",
          }}
        ></span>
        <span
          className="horizontal-line"
          style={{
            height: conditionItemIndex === 0 ? "calc(50% + 20px)" : isMiddleItem ? "calc(100% + 20px)" : "calc(50% - 0px)",
            width: "1px",
            display: treeElementVisible ? "block" : "none",
            position: "absolute",
            border: "1px solid #e2e2e2",
            top: conditionItemIndex === 0 ? "calc(50% - 0px)" : isMiddleItem ? "0" : "0px",
            left: "-25px",
          }}
        ></span>
      </Col>
    </>
  )
}

export default DetailRule
