import { v4 as uuidv4 } from "uuid"
import AddIconPattern from "./AddIconPattern";

const AddIconListPattern = (model, thisComponent, mediaIdList) => {
  console.log({
    model,
    thisComponent,
    mediaIdList,
  })
  if (!model || !Object.values(model)?.length) {
    return false
  }

  const copyModel = JSON.parse(JSON.stringify(model))
  const isRelationData = copyModel?.find((item) => item?.relation === "Data")
  const isRelationVisual = copyModel?.find((item) => item?.relation === "Visual")
  let dataInput = isRelationData?.input
  let visualInput = isRelationVisual?.input
  const translateItems = isRelationData?.translateItems

  const getIndexByRelation = (model, relation) => {
    return model?.findIndex((item) => item?.relation === relation)
  }

  dataInput = {
    ...dataInput,
    maxItemSize: 6,
    titleStyle: {
      ...dataInput.titleStyle,
      ...thisComponent?.data?.titleStyle
    },
  }
  isRelationData.input = dataInput

  visualInput = {
    ...visualInput,
    style: {
      ...visualInput?.style,
      ...thisComponent?.visual?.style
    },
    settings: {
      ...visualInput?.settings,
      ...thisComponent?.visual?.settings
    },
    paginationStyle: {
      ...visualInput?.paginationStyle,
      ...thisComponent?.visual?.paginationStyle,
    }
  }
  isRelationVisual.input = visualInput

  const iconList = AddIconPattern(copyModel, thisComponent, mediaIdList)
  const componentsIndex = getIndexByRelation(copyModel, "Component")

  iconList.items.map((icon, index) => {
    const dataIndex = getIndexByRelation(copyModel[componentsIndex].Childs[index].ChildStrategies, "Data")
    copyModel[componentsIndex].Childs[index].ChildStrategies[dataIndex].input = icon
  })
  iconList.translateItems.map((item, index) => {
    const dataIndex = getIndexByRelation(copyModel[componentsIndex].Childs[index].ChildStrategies, "Data")
    copyModel[componentsIndex].Childs[index].ChildStrategies[dataIndex].translateItems = item
  })

  let title = thisComponent?.data?.title
  translateItems.map(item => {
    if (item?.langKey === "TitleLangKey"){
      item.value = title || item.value
      item.name = "icon list title"
    }
  })

  return copyModel
}

export default AddIconListPattern
