import React, { useState } from "react"

//layouts
import PartialWrapper from "../PartialWrapper"

//components
import { ColorBox, Subtitle, TextInput, ButtonStyleBox, RadiusBox, FontSizeTabBox } from "components"

// utils
import getSelectedIndex from "utils/getSelectedIndex"
import {travelPlannerData} from "../../dummyData/hoteltravelplanner";

const ButtonLayout = (props) => {
  let { editBlock, setEditBlock } = props

  const [ButtonBgColor, setButtonBgColor] = useState("#2E90FA")
  const [ButtonStyle, setButtonStyle] = useState("Dolu")
  const [ButtonRadius, setButtonRadius] = useState(0)
  const [FontSizeTab, setFontSizeTab] = useState(0)

  const changeButtonBgColor = (event) => {
    setButtonBgColor(event.target.value)
  }

  const fontSizeTabChanged = (e) => {
    let selectedIndex = getSelectedIndex(e)
    setFontSizeTab(selectedIndex)
  }

  return (
    <PartialWrapper
      className={`button-layout${editBlock?.showButtonLayout ? " active" : ""}`}
      goBackOnClick={() => setEditBlock(null)}
    >
      <Subtitle>Buton</Subtitle>

      <div className="input-wrapper mt-4">
        <TextInput
          title={"Metin"}
          placeholder={travelPlannerData.buttonText}
          defaultValue={travelPlannerData.buttonText || "Ara"}
        />
      </div>

      <div className="input-wrapper mt-4">
        <ColorBox
          title={"Renk"}
          defaultValue={ButtonBgColor}
          onChange={changeButtonBgColor}
        />
      </div>
      <div className="input-wrapper mt-4">
        <ButtonStyleBox
          title="Stil"
          ActiveButtonStyle={ButtonStyle}
          onChange={(style) => {
            setButtonStyle(style)
          }}
        />
      </div>

      <div className="input-wrapper mt-4">
        <RadiusBox
          title="Köşe Stili"
          min={0}
          max={40}
          ActiveButtonRadius={ButtonRadius}
          onChange={(radius) => {
            setButtonRadius(radius)
          }}
          rangeLabel={"Köşe Yuvarlaklığı (px)"}
          rangeValue={ButtonRadius}
          onRangeChange={(radius) => {
            setButtonRadius(radius)
          }}
        />
      </div>

      <div className="input-wrapper mt-4">
        <FontSizeTabBox
          activeTab={FontSizeTab}
          onTabChange={(e) => fontSizeTabChanged(e)}
          onChange={(e) => console.log(e)}
        />
      </div>
    </PartialWrapper>
  )
}

export default ButtonLayout
