/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"

// layouts
import { PageSettings, Blocks } from "pages/PageManager/layouts/OuterSideBars"
import PageDesign from "pages/PageManager/layouts/OuterSideBars/PageDesign";

// components
import { TabList } from "components"

// utils
import getSelectedIndex from "utils/getSelectedIndex"
import useQuery from "utils/useQuery";

const PageManagerAside = (props) => {
  let {
    templates,
    strategies,
    EditLayouts,
    setEditLayouts,
    EditBlock,
    setEditBlock,
    BlockListTab,
    ActiveCategory,
    setActiveCategory,
    setBlockListTab,
    AddNewBlockHandleClick,
    setAddNewBlockHandleClick,
    AssociatedDomains,
    visualValues,
    setVisualValues,
    activeMenuTab,
    setActiveMenuTab,
    allFonts,
    allLinks,
    setPageLock,
    seoSettings,
    setSeoSettings,
  } = props


  let query = useQuery()
  let pagePath = query.get("path")

  // states
  const [BlockListDdownOpen, setBlockListDdownOpen] = useState({})
  const [MenuItems, setMenuItems] = useState([
    { label: "Bloklar", icon: "layout", disabled: !seoSettings.path && !strategies?.list?.result },
    { label: "Tasarım", icon: "palette-solid", disabled: !strategies?.list?.result  },
    { label: "Yayınla", icon: "share", disabled: true  },
    { label: "Ayarlar", icon: "settings", disabled: false  },
  ])

  const menuChanged = (e) => {
    let selectedIndex;
    selectedIndex = getSelectedIndex(e)
    setEditLayouts(null)
    setActiveMenuTab(selectedIndex)
    setBlockListDdownOpen({})
  }

  // when blockListTab item dropdown clicked
  useEffect(() => {
    setAddNewBlockHandleClick(null)
    setBlockListDdownOpen({})
  }, [EditLayouts])

  // when blockListTab item dropdown clicked
  useEffect(() => {
    const designMenu = MenuItems.find(item => item?.icon === "palette-solid")
    const blocksMenu = MenuItems.find(item => item?.icon === "layout")
    const blocked = true
    if (strategies?.list?.result){
      if (strategies?.list?.result?.children || strategies?.list?.result?.code){
        designMenu.disabled = !blocked
      }
    } else {
      designMenu.disabled = blocked
      if (!pagePath) {
        blocksMenu.disabled = blocked
      }
    }
    setMenuItems([
      ...MenuItems
    ])
  }, [strategies?.list?.result])


  return (
    <aside className="flex gap-[1px] bg-gray-50 w-auto z-10">
      <TabList
        onChange={menuChanged}
        tabs={MenuItems}
        tabClassName="flex flex-col items-center text-center justify-center w-[66px] gap-2.5 text-xs text-gray-600 py-2 px-3.5 border-y border-r border-gray-200 hover:bg-gray-50 transition duration-400 first:border-t-0"
        aria-label="tabs example"
        iconSize="18"
        //wrapperClassName="bg-white"
        //itemActiveClass="!text-gray-800 !bg-blue-50"
        activeTab={activeMenuTab}
      />
      <div
        className={`sidebar-inner${
          EditLayouts ? ` active` : ``
        } relative flex flex-col gap-8 bg-white w-[340px] border-x border-gray-200 text-gray-600 text-sm grow px-5 pt-8`}
      >
        <>
          {activeMenuTab === 0 && (
            <Blocks
              templates={templates}
              strategies={strategies}
              blockListTab={BlockListTab}
              setBlockListTab={setBlockListTab}
              blockListDdownOpen={BlockListDdownOpen}
              setBlockListDdownOpen={setBlockListDdownOpen}
              ActiveCategory={ActiveCategory}
              setActiveCategory={setActiveCategory}
              editLayouts={EditLayouts}
              setEditLayouts={setEditLayouts}
              EditBlock={EditBlock}
              setEditBlock={setEditBlock}
              AddNewBlockHandleClick={AddNewBlockHandleClick}
            />
          )}
          {activeMenuTab === 1 && (
            <PageDesign
              strategies={strategies}
              visualValues={visualValues}
              setVisualValues={setVisualValues}
              AssociatedDomains={AssociatedDomains}
              allFonts={allFonts}
              allLinks={allLinks}
            />
          )}

          {activeMenuTab === 3 && (
            <PageSettings
              menuItems={MenuItems}
              setMenuItems={setMenuItems}
              setActiveMenuTab={setActiveMenuTab}
              setPageLock={setPageLock}
              seoSettings={seoSettings}
              setSeoSettings={setSeoSettings}
            />
          )}
        </>
      </div>
    </aside>
  )
}

export default PageManagerAside
