import * as type from "redux/types"

export const myReservationsDetailRequested = (data) => ({
  type: type.MY_RESERVATIONS_DETAIL_REQUESTED,
  payload: data,
})

export const myReservationsDetailSucceed = (data) => ({
  type: type.MY_RESERVATIONS_DETAIL_SUCCEEDED,
  payload: data,
})

export const myReservationsDetailFailed = () => ({
  type: type.MY_RESERVATIONS_DETAIL_FAILED,
})
