import React, { useState } from "react"

// layouts
import PartialWrapper from "../../../../PartialWrapper"

// components
import { BorderBox, ColorBox, FontSizeRangeBox, Subtitle, TextareaInput, ToggleBox } from "components"

const TextLayout = (props) => {
  const { showSubLayout, setShowSubLayout } = props

  const [TitleBgColor, setTitleBgColor] = useState("#000000")
  const [FontSize, setFontSize] = useState(24)

  const changeTitleBgColor = (event) => {
    setTitleBgColor(event.target.value)
  }

  return (
    <PartialWrapper
      className={`text-layout${showSubLayout?.textLayout ? " active" : ""}`}
      goBackOnClick={() => setShowSubLayout(null)}
    >
      <Subtitle>Text</Subtitle>

      <div className="flex flex-col gap-4 mt-4">
        <div className="input-wrapper">
          <ToggleBox
            title={"Display Text"}
            defaultChecked={true}
            onToggle={(e) => console.log(e)}
            //wrapperClassName={""} optional
            //toggleClassName={""} optional
            //textClassName={""} optional
          />
        </div>
        <div className="input-wrapper">
          <TextareaInput
            title="Metin"
            textareaClassName={"!border-gray-200 mt-2"}
            defaultValue="Lorem ipsum..."
            resize={"none"}
            rows={2}
          />
        </div>
        <div className="input-wrapper">
          <ColorBox
            title={"Renk"}
            value={TitleBgColor}
            onChange={changeTitleBgColor}
          />
        </div>
        <div className="input-wrapper">
          <BorderBox>
            <FontSizeRangeBox
              className={""}
              min={10}
              max={72}
              onChange={(size) => {
                setFontSize(size)
              }}
              rangeLabel={"Yazı Boyutu (px)"}
              rangeValue={FontSize}
            />
          </BorderBox>
        </div>
      </div>
    </PartialWrapper>
  )
}

export default TextLayout
