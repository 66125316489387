import React from "react"
import { Toggle } from "rsuite"
import PropTypes from "prop-types"

const ToggleBox = (props) => {
  let { title, name, defaultChecked, checked, toggleClassName, textClassName, wrapperClassName, onToggle, disabled, size, ...args } = props
  return (
    <label
      className={`flex items-center justify-between border border-gray-200 rounded-lg text-gray-800 p-3 ${wrapperClassName}`}
    >
      <span className={textClassName}>{title}</span>
      <Toggle
        name={name}
        checked={checked}
        defaultChecked={defaultChecked}
        className={toggleClassName}
        disabled={disabled}
        onChange={onToggle}
        size={size}
        {...args}
      />
    </label>
  )
}

export default ToggleBox

ToggleBox.prototypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string,
  toggleClassName: PropTypes.string,
  textClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  size: PropTypes.string,
}

ToggleBox.defaultProps = {
  title: "Label",
  name: undefined,
  toggleClassName: "ml-auto",
  textClassName: "select-none",
  wrapperClassName: "",
  size: "md",
  disabled: false,
  checked: undefined,
  defaultChecked: undefined,
}
