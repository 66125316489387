import { useRef } from "react"
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import Icon from "components/Icon"

import addonImg from "assets/img/addondetail.png"

const InformationImages = () => {
  const navigationNextRef = useRef(null)
  const navigationPrevRef = useRef(null)

  // const [openModal, setOpenModal] = useState(false)

  return (
    <>
      <Swiper
        style={{
          "--swiper-navigation-color": "rgba(102, 112, 133, 1)",
          "--swiper-navigation-bullet-inactive-opacity": 1,
          "--swiper-navigation-bullet-inactive-color": "#FFF",
        }}
        onInit={(swiper) => {
          swiper.params.navigation.prevEl = navigationPrevRef.current
          swiper.params.navigation.nextEl = navigationNextRef.current
          swiper.navigation.init()
          swiper.navigation.update()
        }}
        loop={true}
        pagination={true}
        mousewheel={true}
        spaceBetween={100}
        modules={[Navigation, Pagination, Mousewheel, Keyboard]}
      >
        {Array.from(Array(3), (_, index) => (
          <SwiperSlide key={index}>
            <div className="flex justify-center">
              <img
                // onClick={() => setOpenModal(true)}
                src={addonImg}
                alt="addon"
              />
            </div>
          </SwiperSlide>
        ))}
        <div
          className="z-10 w-8 h-8 flex items-center justify-center absolute top-1/2 -translate-y-1/2 cursor-pointer left-8 right-auto"
          ref={navigationPrevRef}
        >
          <Icon
            name="arrow-left"
            color="rgba(102, 112, 133, 1)"
            size={20}
          />
        </div>
        <div
          className="z-10 w-8 h-8 flex items-center justify-center absolute top-1/2 -translate-y-1/2 cursor-pointer right-8 left-auto"
          ref={navigationNextRef}
        >
          <Icon
            name="arrow-right"
            color="rgba(102, 112, 133, 1)"
            size={20}
          />
        </div>
      </Swiper>

      {/* <Modal
        open={openModal}
        closeButton={false}
        size={"1080"}
      >
        <div className="">
          <Swiper
            style={{
              "--swiper-navigation-color": "rgba(102, 112, 133, 1)",
              "--swiper-navigation-bullet-inactive-opacity": 1,
              "--swiper-navigation-bullet-inactive-color": "#FFF",
            }}
            onInit={(swiper) => {
              swiper.params.navigation.prevEl = navigationPrevRef.current
              swiper.params.navigation.nextEl = navigationNextRef.current
              swiper.navigation.init()
              swiper.navigation.update()
            }}
            loop={true}
            pagination={true}
            mousewheel={true}
            spaceBetween={100}
            modules={[Navigation, Pagination, Mousewheel, Keyboard]}
          >
            {Array.from(Array(3), (_, index) => (
              <SwiperSlide
                key={index}
                onClick={() => setOpenModal(true)}
              >
                <div className="flex justify-center">
                  <img
                    src={addonImg}
                    alt="addon"
                  />
                </div>
              </SwiperSlide>
            ))}
            <div
              className="z-10 w-8 h-8 flex items-center justify-center absolute top-1/2 -translate-y-1/2 cursor-pointer left-8 right-auto"
              ref={navigationPrevRef}
            >
              <Icon
                name="arrow-left"
                color="rgba(102, 112, 133, 1)"
                size={20}
              />
            </div>
            <div
              className="z-10 w-8 h-8 flex items-center justify-center absolute top-1/2 -translate-y-1/2 cursor-pointer right-8 left-auto"
              ref={navigationNextRef}
            >
              <Icon
                name="arrow-right"
                color="rgba(102, 112, 133, 1)"
                size={20}
              />
            </div>
          </Swiper>
        </div>
      </Modal> */}
    </>
  )
}

export default InformationImages
