import * as type from "redux/types"

const initialState = {
  list: null,
  loading: false,
  error: false,
  errorMessages: null,
}

const clientUserRegister = (state = initialState, action) => {
  switch (action.type) {
    case type.CLIENT_USER_REGISTER_REQUESTED:
      return {
        ...state,
        loading: true,
        error: false,
      }
    case type.CLIENT_USER_REGISTER_SUCCEEDED:
      return {
        ...state,
        list: action.payload,
        loading: false,
        error: false,
        errorMessages: null,
      }
    case type.CLIENT_USER_REGISTER_FAILED:
      return {
        ...state,
        list: null,
        loading: false,
        error: true,
      }
    case type.CLIENT_USER_REGISTER_FAILED_WITH_ERROR_MESSAGE:
      return {
        ...state,
        list: null,
        loading: false,
        error: true,
        errorMessages: action.payload,
      }
    default:
      return state
  }
}

export default clientUserRegister
