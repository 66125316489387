import React from "react"

// components
import { GoBackBtn } from "components"

const PartialWrapper = (props) => {
  let { className, goBackOnClick, goBackBtnDisabled, children } = props

  return (
    <div className={`inner-sidebar-wrapper flex flex-col overflow-auto ${className}`}>
      <GoBackBtn
        disabled={goBackBtnDisabled}
        onClick={goBackOnClick}
      />
      <div className="inside-container grow overflow-auto text-sm mt-4 pb-2">{children}</div>
    </div>
  )
}

export default PartialWrapper
