import { v4 as uuidv4 } from "uuid"

export const COMPONENTS_NAMING = {
	ATOM_PAGE: "Atom Page",
	HEADER: "Header",
	FOOTER: "Footer",
	TRAVEL_PLANNER_HOTEL: "Travel Planner Hotel",
	TRAVEL_PLANNER_TOUR: "Travel Planner Tour",
	TRAVEL_PLANNER_TRANSFER_AGENCY: "Travel Planner Transfer",
	TRAVEL_PLANNER_TRANSFER_CORPORATE: "Travel Planner Transfer Corporate",
	TRAVEL_PLANNER_FLIGHT: "Travel Planner Flight",
	TRAVEL_PLANNER_CAR_RENTAL_AGENCY: "Travel Planner Car Rental",
	TRAVEL_PLANNER_CAR_RENTAL_CORPORATE: "Travel Planner Car Rental Corporate",
	TAB_SLIDER: "Tab Slider",
	ICON_LIST: "Icon List",
	MEDIA_LIST: "Media List",
	IMAGE_LIST: "Image List",
	TEXT_HTML: "HTML",
	ICON: "Atom.Icon",
	SLIDER: "Slider",
	MEDIA: "Media",
	IMAGE: "Image",
	BUTTON: "Button",
	TEXT: "Text",
	HYPERLINK: "Components.Atom.Hyperlink",
}

export const ATOM_PAGE = COMPONENTS_NAMING.ATOM_PAGE
export const TRAVEL_PLANNER_HOTEL = COMPONENTS_NAMING.TRAVEL_PLANNER_HOTEL
export const TRAVEL_PLANNER_TOUR = COMPONENTS_NAMING.TRAVEL_PLANNER_TOUR
export const TRAVEL_PLANNER_TRANSFER_AGENCY = COMPONENTS_NAMING.TRAVEL_PLANNER_TRANSFER_AGENCY
export const TRAVEL_PLANNER_TRANSFER_CORPORATE = COMPONENTS_NAMING.TRAVEL_PLANNER_TRANSFER_CORPORATE
export const TRAVEL_PLANNER_FLIGHT = COMPONENTS_NAMING.TRAVEL_PLANNER_FLIGHT
export const TRAVEL_PLANNER_CAR_RENTAL_AGENCY = COMPONENTS_NAMING.TRAVEL_PLANNER_CAR_RENTAL_AGENCY
export const TRAVEL_PLANNER_CAR_RENTAL_CORPORATE = COMPONENTS_NAMING.TRAVEL_PLANNER_CAR_RENTAL_CORPORATE
export const TAB_SLIDER = COMPONENTS_NAMING.TAB_SLIDER
export const HEADER = COMPONENTS_NAMING.HEADER
export const TEXT_HTML = COMPONENTS_NAMING.TEXT_HTML
export const ICON_LIST = COMPONENTS_NAMING.ICON_LIST
export const MEDIA_LIST = COMPONENTS_NAMING.MEDIA_LIST
export const IMAGE_LIST = COMPONENTS_NAMING.IMAGE_LIST
export const FOOTER = COMPONENTS_NAMING.FOOTER
export const ICON = COMPONENTS_NAMING.ICON
export const SLIDER = COMPONENTS_NAMING.SLIDER
export const MEDIA = COMPONENTS_NAMING.MEDIA
export const IMAGE = COMPONENTS_NAMING.IMAGE
export const BUTTON = COMPONENTS_NAMING.BUTTON
export const TEXT = COMPONENTS_NAMING.TEXT
export const HYPERLINK = COMPONENTS_NAMING.HYPERLINK

export const definitionIdList = {
	[COMPONENTS_NAMING.ATOM_PAGE] : 1,
	[COMPONENTS_NAMING.HEADER] : null,
	[COMPONENTS_NAMING.FOOTER] : null,
	[COMPONENTS_NAMING.TRAVEL_PLANNER_HOTEL] : 111,
	[COMPONENTS_NAMING.TRAVEL_PLANNER_TOUR] : 209,
	[COMPONENTS_NAMING.TRAVEL_PLANNER_TRANSFER_AGENCY] : 223,
	[COMPONENTS_NAMING.TRAVEL_PLANNER_TRANSFER_CORPORATE] : null,
	[COMPONENTS_NAMING.TRAVEL_PLANNER_FLIGHT] : 220,
	[COMPONENTS_NAMING.TRAVEL_PLANNER_CAR_RENTAL_AGENCY] : 226,
	[COMPONENTS_NAMING.TRAVEL_PLANNER_CAR_RENTAL_CORPORATE] : null,
	[COMPONENTS_NAMING.TAB_SLIDER] : 96,
	[COMPONENTS_NAMING.ICON_LIST] : 99,
	[COMPONENTS_NAMING.MEDIA_LIST] : 31,
	[COMPONENTS_NAMING.IMAGE_LIST] : null,
	[COMPONENTS_NAMING.TEXT_HTML] : 108,
	[COMPONENTS_NAMING.ICON] : 165,
	[COMPONENTS_NAMING.SLIDER] : 6,
	[COMPONENTS_NAMING.MEDIA] : 232,
	[COMPONENTS_NAMING.IMAGE] : 3,
	[COMPONENTS_NAMING.BUTTON] : 20,
	[COMPONENTS_NAMING.TEXT] : 5,
	[COMPONENTS_NAMING.HYPERLINK] : 4,
}

export const CategoryList = {
	ALL: 1,
	MEDIA_LIST: 2,
	TAB_SLIDER: 3,
}

export const DefaultVisualStyles = {
	button: {
		backgroundColor: "#101828",
		borderColor: "#101828",
		//color: null,
	},
	enableHeaderFooter: true,
	fontSize: 16,
	backgroundColor: "#fff",
	componentMargin: 0,
}

export const TabListTabLabels = {
	content: "İçerik",
	design: "Tasarım",
	publish: "Yayınlama",
}

export const DefaultPageSettings = {
	pageTitle: "Yeni sayfa"
}

export const NEW_REGISTER_CODE = () => {
	const id = uuidv4()
	return `new-${id}`
}
export const newAtomPage = (code) => {
	return {
		name: ATOM_PAGE,
		code: code || NEW_REGISTER_CODE(),
	}
}
export const newTPComponentTour = (code, order) => {
	return {
		name: TRAVEL_PLANNER_TOUR,
		code: code || NEW_REGISTER_CODE(),
		order
	}
}
export const newTPComponentHotel = (code, order) => {
	return {
		name: TRAVEL_PLANNER_HOTEL,
		code: code || NEW_REGISTER_CODE(),
		order
	}
}
export const newTPComponentTransferAgency = (code, order) => {
	return {
		name: TRAVEL_PLANNER_TRANSFER_AGENCY,
		code: code || NEW_REGISTER_CODE(),
		order
	}
}
export const newTPComponentTransferCorporate = (code, order) => {
	return {
		name: TRAVEL_PLANNER_TRANSFER_CORPORATE,
		code: code || NEW_REGISTER_CODE(),
		order
	}
}
export const newTPComponentFlight = (code, order) => {
	return {
		name: TRAVEL_PLANNER_FLIGHT,
		code: code || NEW_REGISTER_CODE(),
		order
	}
}
export const newTPComponentCarRentalAgency = (code, order) => {
	return {
		name: TRAVEL_PLANNER_CAR_RENTAL_AGENCY,
		code: code || NEW_REGISTER_CODE(),
		order
	}
}
export const newTPComponentCarRentalCorporate = (code, order) => {
	return {
		name: TRAVEL_PLANNER_CAR_RENTAL_CORPORATE,
		code: code || NEW_REGISTER_CODE(),
		order
	}
}
export const newTabSlider = (code, order) => {
	return {
		name: TAB_SLIDER,
		code: code || NEW_REGISTER_CODE(),
		order
	}
}
export const newMediaList = (code, order) => {
	return {
		name: MEDIA_LIST,
		code: code || NEW_REGISTER_CODE(),
		order
	}
}
export const newIconList = (code, order) => {
	return {
		name: ICON_LIST,
		code: code || NEW_REGISTER_CODE(),
		order
	}
}
export const newTextHtml = (code, order) => {
	return {
		name: TEXT_HTML,
		code: code || NEW_REGISTER_CODE(),
		order
	}
}
export const newIcon = (code, order) => {
	return {
		name: ICON,
		code: code || NEW_REGISTER_CODE(),
		order
	}
}
export const newSlider = (code, order) => {
	return {
		name: SLIDER,
		code: code || NEW_REGISTER_CODE(),
		order
	}
}
export const newMedia = (code, order) => {
	return {
		name: MEDIA,
		code: code || NEW_REGISTER_CODE(),
		order
	}
}
export const newImage = (code, order) => {
	return {
		name: IMAGE,
		code: code || NEW_REGISTER_CODE(),
		order
	}
}
export const newButton = (code, order) => {
	return {
		name: BUTTON,
		code: code || NEW_REGISTER_CODE(),
		order
	}
}
export const newText = (code, order, className) => {
	return {
		name: TEXT,
		code: code || NEW_REGISTER_CODE(),
		order,
		visual: {
			settings: {
				className
			}
		}
	}
}
export const newHyperLink = (code, order) => {
	return {
		name: HYPERLINK,
		code: code || NEW_REGISTER_CODE(),
		order
	}
}









