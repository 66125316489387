import React from "react"
import PropTypes from "prop-types"

const ErrorMessage = (props) => {
  let { className, children } = props

  return (
    <div className={`bg-error-50 text-error-500 pointer-events-none font-medium p-2 rounded-lg ${className}`}>{children}</div>
  )
}

export default ErrorMessage

ErrorMessage.prototypes = {
  className: PropTypes.string,
}

ErrorMessage.defaultProps = {
  className: "",
}
