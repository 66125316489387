import React, { useState } from "react"
import { Icon } from "components"
import MainHeader from "components/Headers/MainHeader"
import BackgroundIcons from "components/BackgroundIcons"

const SignUp = (props) => {
  let { isLogged } = props

  const [ShowPwRequirements, setshowPwRequirements] = useState(false)
  const [PasswordList, setPasswordList] = useState({
    pw1: {
      type: "password",
      isShow: false,
      placeholder: "Şifre",
    },
    pw2: {
      type: "password",
      isShow: false,
      placeholder: "Şifre tekrar",
    },
  })

  // change password type and icon when i click eye icon on inputs
  const togglePassword = (pw) => {
    let isText = PasswordList[pw].type === "text"
    setPasswordList({
      ...PasswordList,
      [pw]: {
        ...PasswordList[pw],
        type: isText ? "password" : "text",
        isShow: !isText,
      },
    })
  }

  const setPassword = (e, pw) => {
    let pwValue = e.target.value

    pwValue.length > 0 && pw.includes("1") ? setshowPwRequirements(true) : setshowPwRequirements(false)
    checkPasswordRequiretments(pw, pwValue)
  }

  const checkPasswordRequiretments = (pw, pwValue) => {
    let minLength = pwValue.length >= 8
    let specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(pwValue) // eslint-disable-line
    let isContainsNumber = /\d/.test(pwValue) // eslint-disable-line
    let isContainsUpperCase = /[A-Z]/.test(pwValue) // eslint-disable-line
    let isContainsLowerCase = /[a-z]/.test(pwValue) // eslint-disable-line
    let isContainsLowerAndUpperCase = isContainsUpperCase && isContainsLowerCase

    setPasswordList({
      ...PasswordList,
      [pw]: {
        ...PasswordList[pw],
        value: pwValue,
        minLength,
        specialChars,
        isContainsNumber,
        isContainsLowerAndUpperCase,
      },
    })
  }

  return (
    <>
      <MainHeader
        isLogged={isLogged}
        className={"!absolute"}
      />

      <section className="register-wrapper flex items-center justify-center  relative overflow-hidden min-h-screen">
        {/*background blur icons*/}
        <BackgroundIcons />

        <div className="container">
          <div className="flex flex-col lg:flex-row items-center justify-between gap-4">
            <div className="left-box w-full md:w-[48%] hidden lg:block">
              <p className="text-gray-800 text-5xl font-bold">
                Websitenizden online rezervasyon almaya&nbsp;
                <span className="inline-block text-primary-500">hemen&nbsp;</span>başlayın.
              </p>
              <p className="text-2xl font-normal text-gray-500 mt-6">
                Eva ile otelinizin websitesine rezervasyon alma yeteneği kazandırarak satış oranlarınızı artırın.
              </p>
            </div>

            {/*Login box*/}
            <div className="login-box border border-gray-200 rounded-lg bg-white p-8">
              {/*login box title*/}
              <div className="title-area">
                <h2 className="text-3xl text-gray-800 font-bold">Kaydınızı tamamlayın!</h2>
                <p className="text-base text-gray-500 mt-2">Başlamak için formu doldurun.</p>
              </div>

              {/*login box form*/}
              <form className="form-area mt-8 w-full lg:w-[424px]">
                <div className="form-wrapper mb-4">
                  <div className="flex flex-col lg:flex-row lg:items-center text-sm font-normal text-gray-500 -mx-4">
                    <div className="lg:w-7/12 px-4">
                      <p>Otel:</p>
                      <p
                        className="whitespace-normal lg:whitespace-nowrap lg:truncate font-medium text-gray-400 mt-1"
                        title={"Hotel LaCasa Uzun isimli Bir Otel Anatolia Resort Ultra"}
                      >
                        Hotel LaCasa Uzun isimli Bir Otel Anatolia Resort Ultra
                      </p>
                    </div>
                    <div className="lg:w-5/12 px-4 mt-3 lg:mt-0">
                      <p>E-Posta:</p>
                      <p
                        className="whitespace-normal break-all lg:whitespace-nowrap lg:truncate font-medium text-gray-400 mt-1"
                        title={"ensargordiontech@hotelanatolia.com"}
                      >
                        ensargordiontech@hotelanatolia.com
                      </p>
                    </div>
                  </div>
                </div>
                <div className="form-wrapper mb-4">
                  <input
                    className="w-full placeholder:text-slate-500"
                    type="text"
                    placeholder="İsim"
                    required
                    autoComplete="none"
                  />
                </div>
                <div className="form-wrapper mb-4">
                  <input
                    className="w-full placeholder:text-slate-500"
                    type="text"
                    placeholder="Soyisim"
                    required
                    autoComplete="none"
                  />
                </div>
                {Object.values(PasswordList).map((pw, k) => {
                  return (
                    <div
                      key={k}
                      className=" mb-4"
                    >
                      <div className="form-wrapper">
                        <input
                          onChange={(e) => setPassword(e, Object.keys(PasswordList)[k])}
                          className="w-full placeholder:text-slate-500"
                          type={pw.type}
                          placeholder={pw.placeholder}
                          required
                          autoComplete="none"
                        />
                        <div
                          className="absolute flex items-center px-3.5 inset-y-0 right-0 cursor-pointer"
                          onClick={() => togglePassword(Object.keys(PasswordList)[k])}
                        >
                          <Icon
                            name={`eye${!pw.isShow ? "-off" : ""}`}
                            size="16"
                            color={!pw.isShow ? "#98A2B3" : "#2E90FA"}
                          />
                        </div>
                      </div>

                      {k === 0 && ShowPwRequirements && (
                        <div className="text-gray-400 text-xs mt-1.5">
                          <p className={`mb-1 transition ${pw.minLength ? "text-success-400" : ""}`}>*Minimum 8 karakter</p>
                          <p className={`mb-1 transition ${pw.isContainsNumber ? "text-success-400" : ""}`}>
                            *Minimum bir adet rakam
                          </p>
                          <p className={`mb-1 transition ${pw.specialChars ? "text-success-400" : ""}`}>
                            *Minimum bir adet sembol
                          </p>
                          <p className={`mb-1 transition ${pw.isContainsLowerAndUpperCase ? "text-success-400" : ""}`}>
                            *Büyük ve küçük harf
                          </p>
                        </div>
                      )}
                    </div>
                  )
                })}
                <div className="form-wrapper pt-4">
                  <button
                    type="submit"
                    className="btn w-full"
                  >
                    Hemen Başla
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default SignUp
