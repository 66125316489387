import React, {useEffect, useRef, useState} from "react"
import { useDispatch, useSelector } from "react-redux"

//layouts
import PartialWrapper from "../../../PartialWrapper"

// components
import { Icon, LoadBar, ErrorMessage, Subtitle, ToggleBox } from "components"

// saga-requests
import {
  categoryUpdateRequested,
  categoryDeleteRequested,
  categoryDeleteFailed, categorySaveRequested,
} from "redux/actions/pageManager"
import {UpdateStrategy} from "../../../../helpers/UpdateStrategy";
import {getStrategySucceed} from "redux/actions/strategy";
import {setSettings} from "helpers/setSettings";

const AddOrEditCategory = (props) => {
  let { editBlock, setEditBlock, ThisComponent, setThisComponent, categoryList, categoryType } = props

  const dispatch = useDispatch()
  const categoryNameInputRef = useRef("")

  let categoryInfo = editBlock?.categoryInfo
  const isVisible = editBlock?.category_settings
  const isAddNewCategory = editBlock?.type === "category_add"
  const defaultCategoryInfo = {
    Name: "",
    Order: categoryInfo?.Order || 0,
    Visible: true,
  }

  // selectors
  const categoryUpdate = useSelector((state) => state.pageManager.categoryUpdate.response)
  const categoryUpdateLoading = useSelector((state) => state.pageManager.categoryUpdate.loading)
  const categoryUpdateError = useSelector((state) => state.pageManager.categoryUpdate.error)
  const categoryDelete = useSelector((state) => state.pageManager.categoryDelete.response)
  const categoryDeleteLoading = useSelector((state) => state.pageManager.categoryDelete.loading)
  const categoryDeleteError = useSelector((state) => state.pageManager.categoryDelete.error)
  const categorySave = useSelector((state) => state.pageManager.categorySave.response)
  const categorySaveLoading = useSelector((state) => state.pageManager.categorySave.loading)
  const categorySaveError = useSelector((state) => state.pageManager.categorySave.error)
  const components = useSelector(state => state.strategy.getStrategy.list?.result)

  // states
  const [CategoryDeleteErrorMessage, setCategoryDeleteErrorMessage] = useState(null)
  const [CategoryInfo, setCategoryInfo] = useState(defaultCategoryInfo)
  const [ShowOrderMatchErrorMessage, setShowOrderMatchErrorMessage] = useState(false)

  const newCategoryInfo = (arr, value) => {
    if (arr){
      let length = arr.length,
        max = 0;
      while (length--) {
        if (Number(arr[length][value]) > max) {
          max = Number(arr[length][value]);
        }
      }
      return {
        Name: "",
        Order: max + 1 || 0,
        Visible: true,
      };
    }
  };
  const resetFields = (refs) => {
    setCategoryInfo(newCategoryInfo(categoryList, "Order"))
    setTimeout(() => {
      if (refs) refs.map(ref => {
        if (ref.current) ref.current.value = undefined
        return true
      })
    }, 0)
  }
  const clearState = () => {
    setCategoryDeleteErrorMessage(null)
    resetFields([categoryNameInputRef])
  }
  const ActionOrGoBack = () => {
    let dynamicRequestData = {}
    let requestType = () => null
    if (isAddNewCategory){
      if (!CategoryInfo?.Name) {
        clearState()
        setEditBlock(null)
        return false
      }
      requestType = categorySaveRequested
      dynamicRequestData = {
        Type: categoryType,
        Visible: CategoryInfo?.Visible,
      }
    } else {
      if (JSON.stringify(categoryInfo) === JSON.stringify(CategoryInfo)) {
        clearState()
        setEditBlock(null)
        return false
      }
      requestType = categoryUpdateRequested
      dynamicRequestData = {
        Id: categoryInfo?.Id,
        Visible: CategoryInfo?.Visible,
      }
    }
    dispatch(
      requestType({
        Name: CategoryInfo?.Name,
        Order: CategoryInfo?.Order,
        ...dynamicRequestData,
      }),
    )
  }
  const deleteCategory = () => {
    dispatch(categoryDeleteRequested({
      categoryId: CategoryInfo?.Id,
      type: CategoryInfo?.Type,
    }))
  }
  const closeBlockByTimer = () => {
    setTimeout(() => {
      clearState()
      setEditBlock(null)
    }, 300)
  }
  const updateCategoryInfoStrategy = () => {
    return new Promise((resolve, reject) => {
      ThisComponent.children.map(slider => {
        if (slider?.data?.category?.categoryId === CategoryInfo?.Id){
          slider.data.category = {
            ...slider.data.category,
            categoryName: CategoryInfo?.Name,
            order: Number(CategoryInfo?.Order),
            categoryVisible: CategoryInfo?.Visible,
          }
        }
        return null
      })
      resolve(ThisComponent)
    })
  }
  const deleteDataIncludesCategoryId = () => {
    return new Promise((resolve) => {
      ThisComponent.children.map((slider, itemIndex) => {
        if (slider?.data?.category?.categoryId === CategoryInfo?.Id) ThisComponent.children.splice(itemIndex, 1)
        return null
      })
      resolve(ThisComponent)
    })
  }

  const isOrderingMatch = (Category) => {
    if (!categoryList) return false
    const categoryListItem = categoryList.find(category => category?.Id === Category?.Id)
    const hasDifference = categoryListItem?.Order !== Category?.Order
    if (hasDifference){
      const item = categoryList.find(category => category?.Order === Category?.Order)
      return item ? item : false;
    }
    return false
  }

  //effects
  useEffect(() => {
    if (ThisComponent?.children){
      updateCategoryInfoStrategy().then(thisComponent => {
        const strategy = UpdateStrategy(components, thisComponent)
        dispatch(getStrategySucceed(strategy))
      })
    }
    const match = isOrderingMatch(CategoryInfo)
    if (match && ((isAddNewCategory && CategoryInfo?.Name) || !isAddNewCategory)){
      setShowOrderMatchErrorMessage(match)
    } else {
      if (ShowOrderMatchErrorMessage) setShowOrderMatchErrorMessage(false)
    }
  }, [CategoryInfo])
  useEffect(() => {
    if (categoryList && !categoryInfo){
      setCategoryInfo(newCategoryInfo(categoryList, "Order"))
    } else if (categoryInfo){
      setCategoryInfo(categoryInfo)
    }
  }, [categoryList, categoryInfo])
  useEffect(() => {
    if (categoryUpdate || categorySave || categoryDelete) {
      if (categoryDelete?.success) {
        deleteDataIncludesCategoryId().then(() => closeBlockByTimer())
      } else if (categoryDelete){
        setCategoryDeleteErrorMessage(categoryDelete?.errors && categoryDelete?.errors[0]?.message)
        dispatch(categoryDeleteFailed())
      } else {
        closeBlockByTimer()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryUpdate, categorySave, categoryDelete])

  if (!isVisible) {
    return false
  }

  return (
    <PartialWrapper
      goBackOnClick={ActionOrGoBack}
      goBackBtnDisabled={(categoryUpdateLoading || categoryDeleteLoading) || ShowOrderMatchErrorMessage}
      className={`category-settings${isVisible ? " active" : ""}`}
    >
      <Subtitle className="mb-4">Kategori {isAddNewCategory ? "Ekle" : "Düzenle"}</Subtitle>

      {!isAddNewCategory && categoryType !== 2 && (
        <div className="input-wrapper">
        <ToggleBox
          title="Sekmeyi Göster"
          wrapperClassName="mt-4"
          disabled={categoryUpdateLoading || categoryDeleteLoading}
          checked={CategoryInfo?.Visible}
          onToggle={(e) => {
            setSettings({target: {name: "Visible", value: e}}, true, CategoryInfo).then((res) => setCategoryInfo(res))
          }}
        />
      </div>)}
      <div className={`input-wrapper${!isAddNewCategory ? " mt-4" : ""}`}>
        <label>
          <span className="text-gray-800 font-medium">Kategori adı</span>

          {!categorySaveLoading && categorySaveError && (
            <ErrorMessage className="mt-1">Kategori kaydedilirken bir hata ile karsilasildi.</ErrorMessage>
          )}

          {!categoryUpdateLoading && categoryUpdateError && (
            <ErrorMessage className="mt-1">Kategori guncellenirken bir hata ile karsilasildi.</ErrorMessage>
          )}

          <div className="input-wrapper relative mt-1.5">
            <input
              ref={categoryNameInputRef}
              className="w-full"
              type="text"
              name="Name"
              placeholder="Kategori adı girin"
              value={CategoryInfo?.Name}
              disabled={categoryUpdateLoading || categoryDeleteLoading}
              onChange={(e) => {
                setSettings(e, true, CategoryInfo).then((res) => setCategoryInfo(res))
              }}
            />
            {categoryUpdateLoading && (
              <LoadBar
                className="absolute right-3.5 top-3 h-5 w-5"
                pathFill={"#2E90FA"}
              />
            )}
            {categoryUpdate && !categoryUpdateLoading && (
              <div className="absolute right-3 top-1/2 -translate-y-1/2 inline-flex bg-success-50 rounded-full p-1">
                <Icon
                  name="check"
                  color={"#039855"}
                />
              </div>
            )}
          </div>
        </label>
      </div>
      <div className="input-wrapper mt-4">
        <label>
          <div className="flex items-center border border-gray-200 rounded-lg text-sm text-gray-800 p-3">
            <span>Sıralamadaki yeri</span>
            <input
              className="w-[46px] ml-auto bg-gray-100 rounded-lg !h-[26px] text-center !color-blue-500 !text-sm !px-1"
              type="number"
              name="Order"
              min="0"
              value={Number(CategoryInfo?.Order)}
              onChange={(e) => {
                setSettings({target: {name: e.target.name, value: Number(e.target.value)}}, true, CategoryInfo).then((res) => setCategoryInfo(res))
              }}
              disabled={categoryUpdateLoading || categoryDeleteLoading}
            />
          </div>
        </label>

        {ShowOrderMatchErrorMessage && (
          <ErrorMessage className="mt-1">
            Bu sıralama başka bir kategoride bulunmaktadır.
            <br/>
            <small>bulunan kategori: <b><code>{ShowOrderMatchErrorMessage?.Name}</code></b></small>
            {isAddNewCategory && (
              <>
                <br/>
                <small>Önerilen sıralama: <b><code>{newCategoryInfo(categoryList, "Order")?.Order}</code></b></small>
              </>
            )}
          </ErrorMessage>
        )}

        {!isAddNewCategory && (
          <div className="input-wrapper mt-4">

            {!categoryDeleteLoading && categoryDeleteError && (
              <ErrorMessage className="mb-1">
                {CategoryDeleteErrorMessage || "Kategori silinirken bir hata ile karsilasildi."}
              </ErrorMessage>
            )}
            <button
              className="btn w-full sm danger-text"
              onClick={deleteCategory}
              disabled={categoryUpdateLoading || categoryDeleteLoading}
            >
              {!categoryDeleteLoading && <span>Kategoriyi sil</span>}

              {categoryDeleteLoading && (
                <LoadBar
                  className="h-5 w-5"
                  pathFill={"#F04438"}
                />
              )}
            </button>
          </div>
        )}
      </div>
    </PartialWrapper>
  )
}

export default AddOrEditCategory
