import { call, fork, put, takeLatest } from "redux-saga/effects"
import * as actions from "redux/actions/milesAndSmiles"

import { MILES_AND_SMILES_REQUESTED } from "redux/types"
import { API_POST } from "helpers/api"

function* watchGetMilesAndSmilesList() {
  yield takeLatest(MILES_AND_SMILES_REQUESTED, getMilesAndSmilesList)
}

function* getMilesAndSmilesList(action) {
  try {
    const res = yield call(apiGetMilesAndSmilesList, action.payload)
    yield put(actions.milesAndSmilesSucceed(res.data?.result?.data))
  } catch (err) {
    yield put(actions.milesAndSmilesFailed())
  }
}

const apiGetMilesAndSmilesList = (payload) => {
  return API_POST(`${process.env.REACT_APP_PANEL_DOMAIN}/api/reservation/loyaltyreport`, payload)
}

export default function* milesAndSmilesListGet() {
  yield fork(watchGetMilesAndSmilesList)
}
