import * as type from "redux/types"

export const agencyListRequested = (data) => ({
  type: type.GET_AGENCY_LIST_REQUESTED,
  payload: data,
})

export const agencyListSucceed = (data) => ({
  type: type.GET_AGENCY_LIST_SUCCEEDED,
  payload: data,
})

export const agencyListFailed = () => ({
  type: type.GET_AGENCY_LIST_FAILED,
})
