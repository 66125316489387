/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useRef, useState} from "react"
import {useDispatch, useSelector} from "react-redux"

// constants
import {NEW_REGISTER_CODE, newIcon, newIconList} from "../../../Enums";

//layouts
import PartialWrapper from "../../PartialWrapper"

// components
import {
  /*AlignmentBlock,*/
  ErrorMessage,
  Icon,
  Subtitle,
  TextInput,
  ToggleBox
} from "components"

//helpers
import {UpdateStrategy} from "../../../helpers/UpdateStrategy";
import {deleteIconListItem} from "../../../helpers/DeleteIconListItem";
import {setSettings} from "helpers/setSettings";

//saga-requests
import {getStrategySucceed} from "redux/actions/strategy";

const AddOrEdit = (props) => {
  let { activeLayout, editBlock, setEditBlock, ThisComponent, setThisComponent } = props

  const dispatch = useDispatch()
  const formRef = useRef()
  const UploadFileRef = useRef()
  const NewIconList = newIconList(ThisComponent?.code, activeLayout?.order)

  const ThisIcon = newIcon(editBlock?.code || editBlock?.itemInfo?.id)

  // selectors
  const saveDesignFieldsValueErrorMessage = useSelector((state) => state.strategyDesign?.saveDesignFieldsValue?.response?.errors)
  const saveDesignFieldsValueLoading = useSelector((state) => state.strategyDesign?.saveDesignFieldsValue?.loading)
  //const saveDesignFieldsValueError = useSelector((state) => state.strategyDesign?.saveDesignFieldsValue?.error)
  let components = useSelector(state => state.strategy.getStrategy.list?.result)

  // states
  const DefaultIconInfo = {
    id: NEW_REGISTER_CODE(),
    text: "",
    mediaPath: null,
    url: "",
    target: "_blank",
  }
  const [InfoCodeForStrategy, setInfoCodeForStrategy] = useState(activeLayout?.info?.code)
  const [IsAddNewItem, setIsAddNewItem] = useState(editBlock?.categoryItem === "addNewItem")
  const [IconInfo, setIconInfo] = useState(DefaultIconInfo)
  const [ItemHasChanged, setItemHasChanged] = useState(false)

  // Func
  const deleteItemImage = () => {
    setItemHasChanged(true)
    deleteIconListItem(ThisComponent, IconInfo?.id, true).then(response => {
      if (response){
        const strategy = UpdateStrategy(components, response)
        dispatch(getStrategySucceed(strategy))
        const ItemIndex = getItemIndex(IconInfo?.id) || 0
        setIconInfo(response?.children[ItemIndex].data)
      }
    })
  }
  const clearState = () => {
    setIconInfo(null)
    setItemHasChanged(false)
  }
  const readImage = (file) => {
    const reader = new FileReader()
    reader.addEventListener("load", (event) => {
      const obj = {
        mediaPath: event.target.result,
        mediaDetails: {
          size: file.size,
          name: file.name,
          fileForBinary: file,
        }
      }
      setIconInfo({
        ...IconInfo,
        ...obj
      })
    })
    reader.readAsDataURL(file)
    UploadFileRef.current.value = ""
    setItemHasChanged(true)
  }
  const saveChangesOrGoBack = async () => {
    clearState()
    setEditBlock(null)
  }
  const checkHasComponentVisual = () => {
    return ThisComponent?.visual
  }
  const getComponent = () => {
    let componentCode = ThisComponent?.code
    if (!componentCode && !checkHasComponentVisual()) {
      componentCode = InfoCodeForStrategy
    } else if (!componentCode && !InfoCodeForStrategy){
      componentCode = NewIconList?.code
    }
    let component = components?.children?.find(child => child?.code === componentCode)
    if (!component){
      setInfoCodeForStrategy(NewIconList.code)
      component = NewIconList
    }
    return component
  }
  const getItemId = () => editBlock?.itemInfo?.id || editBlock?.media?.id || editBlock?.media?.imagePath
  /*const addItemToStrategyData = (component) => {
    return {...component, data: {...component?.data, items: []}}
  }*/
  const addChildToStrategyData = (component) => {
    return {...component, children: []}
  }
  const checkItemsLength = () => {
    return (ThisComponent?.children && ThisComponent?.children.length) || 0
  }
  const getItemIndex = (id) => {
    const index = ThisComponent?.children?.findIndex(item => item.data.id === id)
    if (index > -1){
      return index
    } else {
      return null
    }
  }

  //effects
  useEffect(() => {
    if (editBlock?.itemInfo && Object.keys(editBlock.itemInfo).length > 1){
      setIconInfo(editBlock.itemInfo)
    } else {setIconInfo(DefaultIconInfo)}
    setIsAddNewItem(editBlock?.categoryItem === "addNewItem")

  }, [editBlock?.itemInfo])
  useEffect(() => {
    const itemId = getItemId()
    if (IconInfo && itemId && ItemHasChanged){
      setIsAddNewItem(false)
      let component = getComponent()
      const iconIndex = getItemIndex(IconInfo?.id)
      const relatedIconIndex = iconIndex !== null ? iconIndex : checkItemsLength()
      if (!ThisComponent?.children) {component = addChildToStrategyData(ThisComponent)}
      const Icon = ThisIcon
      Icon.data = IconInfo
      component.children[relatedIconIndex] = Icon
      setThisComponent({...component})
    }
  }, [IconInfo])
  useEffect(() => {
    if ((ThisComponent?.data || ThisComponent?.children) && ItemHasChanged){
      setItemHasChanged(false)
      const strategy = UpdateStrategy(components, ThisComponent)
      dispatch(getStrategySucceed(strategy))
    }
  }, [ThisComponent])

  return (
    <PartialWrapper
      goBackOnClick={saveChangesOrGoBack}
      className={`add-or-edit-icon${editBlock?.showAddOrEditIconLayout ? " active" : ""}${
        saveDesignFieldsValueLoading ? " cursor-progress [&>*]:opacity-50 [&>*]:pointer-events-none [&>*]:select-none" : ""
      }`}
    >
      {saveDesignFieldsValueErrorMessage && (
        <ErrorMessage className="my-2">
          <code>Hata: {saveDesignFieldsValueErrorMessage[0]?.message}</code>
        </ErrorMessage>
      )}

      {/*<pre>{JSON.stringify(IconInfo, null, 2)}</pre>*/}

      <Subtitle className="mb-4">{!IsAddNewItem ? "Öğe Düzenle" : "Öğe Ekle"}</Subtitle>
      <div className="flex flex-col gap-4">
        <div className="input-wrapper">
          <TextInput
            title="Metin"
            name="text"
            placeholder={"icon ismi girin"}
            value={IconInfo?.text || ""}
            onChange={e => setSettings(e, true, IconInfo, null).then((res) => {
              setIconInfo(res)
              setItemHasChanged(true)
            })}
          />
        </div>
        <div className="input-wrapper">
          <div className="flex flex-col">
            <div className="background-image-area text-sm w-full shrink-0">
              <div className="flex items-center">
                <span className="text-gray-800 font-medium">Görüntü</span>
                <div className="progress-area ml-auto">
                  {!IconInfo?.mediaPath && (
                    <p
                      className="text-blue-500 cursor-pointer font-medium hover:text-blue-300 transition"
                      onClick={() => UploadFileRef.current.click()}
                    >
                      Ekle
                    </p>
                  )}
                  {IconInfo?.mediaPath && (
                    <div className="icon-box flex items-center gap-4">
                      <Icon
                        className="cursor-pointer hover:opacity-50"
                        name="refresh-cw"
                        size={20}
                        color={"#1D2939"}
                        onClick={() => UploadFileRef.current.click()}
                      />
                      <Icon
                        className="cursor-pointer hover:opacity-50"
                        name="trash-2"
                        size={20}
                        color={"#1D2939"}
                        onClick={deleteItemImage}
                      />
                    </div>
                  )}
                </div>
                <form
                  ref={formRef}
                  className="hidden"
                >
                  <input
                    hidden
                    type="file"
                    id="fileUploadInputForSlider"
                    ref={UploadFileRef}
                    onChange={(e) => readImage(UploadFileRef.current.files[0])}
                    accept=".jpg, .jpeg, .png, .svg, .gif"
                  />
                </form>
              </div>

              {IconInfo?.mediaPath && (
                <div className="uploaded-image-area flex items-center justify-center rounded-lg py-overflow-hidden h-[220px] mt-3">
                  <img
                    className="max-w-full object-cover min-w-[50%] object-center bg-white/[.8] max-h-full"
                    src={IconInfo.mediaPath}
                    alt={IconInfo?.mediaDetails?.name}
                  />
                </div>
              )}

              {!IconInfo?.mediaPath && (
                <p className="mt-3">
                  Yüksek çözünürlüklü fotoğraflar, kompanent’in yüklenme hızını etkileyebilir. Fotoğrafları yüklemeden önce
                  optimize etmenizi tavsiye ederiz.
                </p>
              )}
            </div>

            {/*<div className="input-wrapper mt-7">
              <span className="text-gray-800 font-medium">Link type</span>
              <SelectPicker
                block
                className={"mt-2"}
                placement="bottomStart"
                menuMaxHeight={164}
                defaultValue={"1"}
                placeholder={"Link tipi seçin"}
                cleanable={false}
                data={[
                  {
                    label: "Web adresi",
                    value: "1",
                  },
                ]}
                searchable={false}
                //container={() => selectPickerRef.current}
              />
            </div>*/}
            <div className="input-wrapper mt-7">
              <TextInput
                title="Bağlantı adresi"
                name="url"
                value={IconInfo?.url || ""}
                placeholder="URL..."
                onChange={e => setSettings(e, true, IconInfo, null).then((res) => {
                  setIconInfo(res)
                  setItemHasChanged(true)
                })}
              />
            </div>

            <div className="input-wrapper mt-4">
              <ToggleBox
                title={"Yeni Sekmede Aç"}
                checked={IconInfo?.target === "_blank"}
                onToggle={e => {
                  setSettings({ target: {
                      name: "target",
                      value: e ? "_blank" : "_self"
                    }}, true, IconInfo).then((res) => {
                    setIconInfo(res)
                    setItemHasChanged(true)
                  })
                }}
                //wrapperClassName={""}
              />
            </div>

            {/*<div className="border border-gray-200 rounded-lg p-3 mt-4">
              <span className="text-gray-800 font-medium">Hizalama</span>
              <div className="flex items-center gap-6 mt-3">
                {["start", "center", "end"].map((pos, key) => {
                  return (
                    <AlignmentBlock
                      onClick={() => setSelectedAlignment(pos)}
                      selected={SelectedAlignment === pos}
                      key={key}
                      position={pos}
                    />
                  )
                })}
              </div>
            </div>*/}
          </div>
        </div>
      </div>
    </PartialWrapper>
  )
}

export default AddOrEdit
