import React from "react"
import { BorderBox } from "components"
import { JsonParserHelper } from "utils/jsonParserHelper"

const ReservationReportsDetailResNote = (props) => {
  let [data] = [props.data]
  const reservationData = JsonParserHelper.jsonParseOrUndefined(data?.reservationData)
  return (
    <BorderBox className="p-8">
      <div className="flex flex-col">
        <h3 className="text-gray-500 font-semibold">Rezervasyon Notu</h3>
        <p className="text-gray-500 mt-4">{reservationData?.ReservationNote}</p>
      </div>
    </BorderBox>
  )
}

export default ReservationReportsDetailResNote
