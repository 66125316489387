import React from "react"

import Icon from "components/Icon"
import banner404 from "assets/img/404-banner.png"

const Desktop404 = (props) => {
  let { data, logo, fontFamily } = props
  let { mail = data?.domainProducts[0]?.email, phone = data?.domainProducts[0]?.phone } = data

  return (
    <div
      className="bg-white border-4 rounded h-[360px] mb-5"
      style={{ fontFamily: fontFamily }}
    >
      <div className="grid grid-cols-2 h-full gap-4 rounded-2xl">
        <div className="p-5 m-auto">
          <div className="w-full bg-white pb-4 flex items-center justify-center">
            <img
              className="h-6"
              alt="logo"
              src={logo}
            />
          </div>
          <div className="flex flex-col justify-center w-full">
            <h3 className="text-gray-900 text-xl font-semibold">Hemen dönüyoruz!</h3>
            <p className="text-[10px] text-center">şu anda sitemiz bakım aşamasında</p>
            {/* <div className="mt-6 flex flex-col">
              <p className="text-[8px]">Döndüğümüzde ilk senin haberin olsun!</p>
              <div className="flex gap-2 mt-2">
                <p className="border border-gray-200 text-[8px] py-1 pl-2 pr-12 rounded">Email adresi girin</p>
                <button
                  className="text-white text-[8px] py-1 px-2 rounded"
                  style={{ backgroundColor: color }}
                >
                  Bana Bildir
                </button>
              </div>
            </div> */}
          </div>
          <div className="flex items-end justify-center gap-2 pt-20">
            <div className="flex items-center gap-1">
              <Icon
                className=""
                name="headphones"
                size="8"
                color="#667085"
              />
              <p className="text-[6px]">{phone}</p>
            </div>
            <div className="flex items-center gap-1">
              <Icon
                className=""
                name="mail"
                size="8"
                color="#667085"
              />
              <p className="text-[6px]">{mail}</p>
            </div>
            {/* <div className="flex items-center gap-1">
              <Icon
                className=""
                name="instagram"
                size="8"
                color="#667085"
              />
              <p className="text-[6px]">instagram</p>
            </div> */}
          </div>
        </div>
        <div className="">
          <img
            className="h-full object-cover"
            alt="logo"
            src={banner404}
          />
        </div>
      </div>
    </div>
  )
}

export default Desktop404
