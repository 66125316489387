import { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import Cookies from "universal-cookie"

import { Icon, PreviewMobile, PreviewDesktop } from "components"
import B2CDesktop from "./B2CDesktop"
import B2CMobile from "./B2CMobile"
import MobilePreviewSkeleton from "skeletons/Preview/Mobile"
import DesktopPreviewSkeleton from "skeletons/Preview/Desktop"

import { clientConfigGetRequested } from "redux/actions/clientConfigurationGet"

const cookies = new Cookies()

const View = ({ setPreview }) => {
  const dispatch = useDispatch()
  const DomainType = cookies.get("associatedDomain")?.domainType

  const clientConfigurationGet = useSelector((state) => state.clientConfigurationGet?.data)
  const clientConfigurationGetLoading = useSelector((state) => state.clientConfigurationGet?.loading)

  const [mobile, setMobile] = useState(true)

  const logo = clientConfigurationGet?.logoUrl
  const color = clientConfigurationGet?.configurations.find(
    ({ configurationMetadataCode }) => configurationMetadataCode === "color",
  )?.properties?.color
  const borderRadius = clientConfigurationGet?.configurations.find(
    ({ configurationMetadataCode }) => configurationMetadataCode === "theme",
  )?.properties?.borderRadius
  const fontFamily = clientConfigurationGet?.configurations.find(
    ({ configurationMetadataCode }) => configurationMetadataCode === "font",
  )?.properties?.fontFamily

  useEffect(() => {
    dispatch(clientConfigGetRequested())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className="relative z-[51]">
        <div className="fixed inset-0 !bg-gray-500/[0.6]">
          <div className="absolute w-full">
            <header
              className={`header relative w-full top-0 left-0 h-[72px] border-b border-b-gray-200 bg-white py-[14px] flex items-center px-4 lg:px-0 z-10`}
            >
              <div className={"w-full px-10"}>
                <div className="flex items-center justify-between">
                  <div className="logo-area">
                    <Link to="/">
                      <h1 className="text-3xl font-semibold">Ön İzleme</h1>
                    </Link>
                  </div>

                  <div className="flex flex-row relative border border-solid border-gray-200 rounded-lg p-2">
                    <div
                      onClick={() => setMobile(true)}
                      className="flex flex-row items-center px-2 cursor-pointer"
                    >
                      <Icon
                        className="mr-1"
                        name="smartphone"
                        color={mobile ? "#2E90FA" : "#98A2B3"}
                        size="18"
                      />
                      <p className={`text-sm font-medium ${mobile ? "text-blue-500" : "text-gray-400"} `}>Mobil</p>
                    </div>
                    <div className="w-[2px] absolute h-3/5 left-[43%] bg-gray-200" />
                    <div
                      onClick={() => setMobile(false)}
                      className="flex flex-row items-center px-2 cursor-pointer"
                    >
                      <Icon
                        className="mr-1"
                        name="monitor"
                        color={!mobile ? "#2E90FA" : "#98A2B3"}
                        size="18"
                      />
                      <p className={`text-sm font-medium ${!mobile ? "text-blue-500" : "text-gray-400"} `}>Masaüstü</p>
                    </div>
                  </div>

                  <button
                    onClick={() => setPreview(false)}
                    className="btn"
                  >
                    <span className="text-base pointer-events-none px-[37px]">Kapat</span>
                  </button>
                </div>
              </div>
            </header>
            {mobile ? (
              <div className="h-screen pt-[40px] pb-[130px] flex justify-center items-center bg-[#F5F5F5]">
                <div className="w-[400px] h-full overflow-auto border-8 rounded-2xl">
                  <div className="flex h-screen flex-col sticky rounded-lg">
                    <div className="flex flex-row pb-1 pt-4 px-4 bg-white border-none">
                      <span className="font-semibold">9:41</span>
                    </div>
                    {clientConfigurationGetLoading && <MobilePreviewSkeleton />}
                    {!clientConfigurationGetLoading &&
                      (DomainType === "B2C" ? (
                        <B2CMobile
                          logo={logo}
                          color={color}
                          borderRadius={borderRadius}
                          fontFamily={fontFamily}
                        />
                      ) : (
                        <PreviewMobile
                          logo={logo}
                          color={color}
                          borderRadius={borderRadius}
                          fontFamily={fontFamily}
                        />
                      ))}
                  </div>
                </div>
              </div>
            ) : (
              <div className="h-screen pt-[40px] pb-[110px] flex justify-center items-center bg-[#F5F5F5]">
                <div className="w-4/5 h-full overflow-auto border-8 rounded-2xl">
                  <div className="h-screen flex flex-col rounded-lg">
                    <div className="flex flex-row pb-1 pt-4 px-4 bg-white border-none">
                      <span className="font-semibold">9:41</span>
                    </div>
                    {clientConfigurationGetLoading && <DesktopPreviewSkeleton />}
                    {!clientConfigurationGetLoading &&
                      (DomainType === "B2C" ? (
                        <B2CDesktop
                          logo={logo}
                          color={color}
                          borderRadius={borderRadius}
                          fontFamily={fontFamily}
                        />
                      ) : (
                        <PreviewDesktop
                          logo={logo}
                          color={color}
                          borderRadius={borderRadius}
                          fontFamily={fontFamily}
                        />
                      ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default View
