/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react"
import { useDispatch, useSelector } from "react-redux"

// layouts
import BlockWrapper from "../../BlockWrapper"
import { TitleLayout } from "../../Design"
import AddOrEdit from "./AddOrEdit"
import Settings from "./Settings";

// components
import { Icon, TabList, SideMenuListItem } from "components"
import { Dropdown, Whisper } from "rsuite"
import {ReactSortable} from "react-sortablejs";

// utils
import getSelectedIndex from "utils/getSelectedIndex"

// helpers
import {deleteIconListItem} from "../../../helpers/DeleteIconListItem";
import {UpdateStrategy} from "../../../helpers/UpdateStrategy";
import {array_move} from "helpers/ArrayMove";

//constants
import {NEW_REGISTER_CODE, newIconList, TabListTabLabels} from "../../../Enums";

// saga-requests
import {getStrategySucceed} from "redux/actions/strategy";

const IconList = (props) => {
  let { show, activeLayout, setEditLayouts } = props

  const dispatch = useDispatch()


  let infoCodeForStrategy = activeLayout?.info?.code
  let code = infoCodeForStrategy || activeLayout?.code

  // selectors
  const components = useSelector(state => state.strategy.getStrategy.list?.result)

  // states
  const [EditBlock, setEditBlock] = useState(null)
  const [IconListTab, setIconListTab] = useState(0)
  const [ThisComponent, setThisComponent] = useState((components && components?.children && components?.children.find(child => child?.code === infoCodeForStrategy)) || newIconList(code, activeLayout?.order))
  const [BlockListDdownOpen, setBlockListDdownOpen] = useState({})
  const [SortingChanged, setSortingChanged] = useState(false)

  // Func
  const editIconListTabChanged = (e) => {
    let selectedIndex = getSelectedIndex(e)
    setIconListTab(selectedIndex)
  }
  const blockListDropDownToggle = (index, show) => {
    setBlockListDdownOpen({
      index,
      show,
    })
  }
  const renderBlockListDropdown = ({ onClose, left, top, className }, ref, index, child) => {
    const listLenght = ThisComponent?.data?.items && ThisComponent?.data?.items?.length
    const handleSelect = (eventKey) => {
      if (eventKey) {
        onClose()
        switch (eventKey) {
          case "edit":
            setEditBlock({
              showAddOrEditIconLayout: true,
              itemInfo: child?.data,
              code: child?.code
            })
            break
          case "move-up":
            array_move(ThisComponent?.data?.items, index, index-1)
            setSortingChanged(true)
            break
          case "move-down":
            array_move(ThisComponent?.data?.items, index, index+1)
            setSortingChanged(true)
            break
          case "delete":
            deleteIconListItem(ThisComponent, child?.data?.id, false).then(response => {
              if (response){
                const strategy = UpdateStrategy(components, response)
                dispatch(getStrategySucceed(strategy))
              }
            })
            break
          default:
            break
        }
      }
    }
    return (
      <Dropdown.Menu
        ref={ref}
        className={`${className} min-w-[190px] max-w-[240px]`}
        style={{ left, top }}
        onSelect={handleSelect}
      >
        <Dropdown.Item className="font-medium py-2.5 px-4 cursor-default !bg-white !text-gray-800 hover:!bg-white hover:text-gray-800">
          Seçenekler
        </Dropdown.Item>
        <hr />
        <Dropdown.Item
          disabled={index === 0}
          className="flex gap-3 items-center py-2.5 px-4"
          eventKey={"move-up"}
        >
          <Icon
            name="arrow-up"
            size={16}
            color={index === 0 ? "#c5c6c7" : "#1D2939"}
          />
          <p>Yukarı Taşı</p>
        </Dropdown.Item>
        <Dropdown.Item
          disabled={listLenght - 1 === index}
          className="flex gap-3 items-center py-2.5 px-4"
          eventKey={"move-down"}
        >
          <Icon
            name="arrow-down"
            size={16}
            color={listLenght - 1 === index ? "#c5c6c7" : "#1D2939"}
          />
          <p>Aşağı Taşı</p>
        </Dropdown.Item>
        <Dropdown.Item
          className="flex gap-3 items-center py-2.5 px-4"
          eventKey="edit"
        >
          <Icon
            name="edit-2"
            size={16}
            color="#1D2939"
          />
          <p>Düzenle</p>
        </Dropdown.Item>
        {/*<Dropdown.Item
          className="flex gap-3 items-center py-2.5 px-4"
          eventKey={1}
        >
          <Icon
            name="copy"
            size={16}
            color="#1D2939"
          />
          <p>Kopyala</p>
        </Dropdown.Item>*/}
        <Dropdown.Item
          className="flex gap-3 items-center py-2.5 px-4"
          eventKey="delete"
        >
          <Icon
            name="trash"
            size={16}
            color="#1D2939"
          />
          <p>Sil</p>
        </Dropdown.Item>
      </Dropdown.Menu>
    )
  }

  //effects
  useEffect(() => {
    if (SortingChanged){
      const strategy = UpdateStrategy(components, ThisComponent)
      dispatch(getStrategySucceed(strategy))
      setSortingChanged(false)
    }
  }, [SortingChanged])

  useEffect(() => {
    if (activeLayout?.order !== undefined && typeof activeLayout?.order === "number"){
      setThisComponent({
        ...ThisComponent,
        order: activeLayout?.order
      })
    }
  }, [activeLayout])

  return (
    <BlockWrapper
      show={show}
      activeLayout={activeLayout}
      editBlock={EditBlock}
      setEditBlock={setEditBlock}
      ThisComponent={ThisComponent}
      setThisComponent={setThisComponent}
      doneBtnOnClick={() => {
        setEditLayouts(null)
      }}
    >
      <>
        <TabList
          onChange={editIconListTabChanged}
          tabs={[
            { label: TabListTabLabels.content, icon: "list", disabled: false },
            { label: TabListTabLabels.design, icon: "palette-solid", disabled: false },
            { label: TabListTabLabels.publish, icon: "share", disabled: true },
          ]}
          tabClassName="w-full flex items-center gap-2.5 text-center text-gray-600 text-xs p-2 border-r last:border-r-0 hover:bg-gray-50 transition duration-400"
          wrapperClassName="flex shrink-0 border rounded-lg border-gray-200 overflow-hidden mb-7"
          //itemActiveClass="!text-gray-800 !bg-blue-50"
          activeTab={IconListTab}
        />

        {IconListTab === 0 && (
          <>
            <div className="input-wrapper mb-4">
              <SideMenuListItem
                title={"Başlık"}
                iconName={"chevron-right"}
                disabled={!ThisComponent?.children && !ThisComponent?.children?.length}
                onClick={() => {
                  setEditBlock({
                    showTitleLayout: true,
                  })
                }}
              />
            </div>

            <div className="border rounded-lg border-gray-200 overflow-auto">
              {!ThisComponent?.children && (
                <p className="text-sm p-2 my-2">
                  Mevcut bir liste bulunmamaktadır, aşağıdaki butona tıklayarak ekleme yapabilirsiniz.
                </p>
              )}

              {ThisComponent?.children && (
                <ReactSortable
                  onUpdate={() => setSortingChanged(true)}
                  tag="div" list={ThisComponent?.children} setList={(newItemList) => {
                  setThisComponent({
                    ...ThisComponent,
                    children: newItemList
                  })
                }}>
                  {ThisComponent?.children.map((child, index) => {
                    return (
                      <div
                        key={index}
                        //onDrag={(e) => console.log(e)}
                        draggable={true}
                        className={`group relative flex items-center gap-2 py-4 px-3 border-b border-gray-200 cursor-move select-none last:border-0`}
                      >
                        <div className={`inline-flex items-center w-10 h-7 p-1 bg-gray-200 rounded`}>
                          <img
                            className={"shrink-0 rounded"}
                            width={38}
                            height={28}
                            src={child?.data?.mediaPath || "https://cdn-icons-png.flaticon.com/512/6570/6570337.png"}
                            alt={"placeholder img"}
                          />
                        </div>
                        <p className="mr-auto text-sm text-gray-800 truncate">{child?.data?.text}</p>

                        <Whisper
                          placement="bottomEnd"
                          trigger="click"
                          speaker={({ onClose, left, top, className }, ref) =>
                            renderBlockListDropdown({ onClose, left, top, className }, ref, index, child)
                          }
                          onOpen={() => blockListDropDownToggle(index, true)}
                          onClose={() => blockListDropDownToggle(index, false)}
                        >
                          <div className="cursor-pointer">
                            <Icon
                              name="more-vertical"
                              size={14}
                              className="!duration-[100ms]"
                              color={BlockListDdownOpen?.index === index && BlockListDdownOpen?.show ? "#2E90FA" : "#1D2939"}
                            />
                          </div>
                        </Whisper>
                      </div>
                    )
                  })}
                </ReactSortable>
              )}

              <button
                onClick={() => {
                  setEditBlock({
                    showAddOrEditIconLayout: true,
                    categoryItem: "addNewItem",
                    itemInfo: {
                      id: NEW_REGISTER_CODE()
                    }
                  })
                }}
                className="btn secondary xs w-full py-4 !h-auto rounded-none border-b hover:bg-gray-100 last:border-0"
              >
                <Icon
                  name={"plus"}
                  size={20}
                  color={"#2E90FA"}
                />
                <span>Öğe Ekle</span>
              </button>
            </div>

          </>
        )}

        {IconListTab === 1 && (
          <Settings
            ThisComponent={ThisComponent}
            setThisComponent={setThisComponent}/>
        )}
      </>

      {/*add or edit item */}
      <AddOrEdit
        activeLayout={activeLayout}
        editBlock={EditBlock}
        setEditBlock={setEditBlock}
        ThisComponent={ThisComponent}
        setThisComponent={setThisComponent}
      />

      {/*title layout*/}
      <TitleLayout
        editBlock={EditBlock}
        setEditBlock={setEditBlock}
        ThisComponent={ThisComponent}
        setThisComponent={setThisComponent}
      />
    </BlockWrapper>
  )
}

export default IconList
