import {getUrl} from "./helpers/remoteHost";

let config = {
  domain: `${process.env.REACT_APP_AUTH_DOMAIN}/connect`,
  clientId: process.env.REACT_APP_Cid,
  audience: "YOUR_API_IDENTIFIER",
}

export function getConfig() {
  const audience = config.audience && config.audience !== "YOUR_API_IDENTIFIER" ? config.audience : null

  return {
    domain: config.domain,
    clientId: config.clientId,
    ...(audience ? { audience } : null),
  }
}
