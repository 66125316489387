import React from "react"

import d from "components/MarketPlace/asset/d.png"
import e from "components/MarketPlace/asset/e.png"
import f from "components/MarketPlace/asset/f.png"

const AddonBox = () => {
  return (
    <>
      <div className="mb-10 rounded-lg">
        <div className="w-full">
          <div>
            <img
              className="w-full"
              src={d}
              alt=""
            />
          </div>
          <div className="p-4 border border-gray-200 rounded-lg">
            <p className="text-gray-800 text-lg font-semibold">CMS Integration</p>
          </div>
        </div>
      </div>
      <div className="mb-10 rounded-lg">
        <div className="w-full">
          <div>
            <img
              className="w-full"
              src={e}
              alt=""
            />
          </div>
          <div className="p-4 border border-gray-200 rounded-lg">
            <p className="text-gray-800 text-lg font-semibold">IT Service Management</p>
          </div>
        </div>
      </div>
      <div className="mb-10 rounded-lg">
        <div className="w-full">
          <div>
            <img
              className="w-full"
              src={f}
              alt=""
            />
          </div>
          <div className="p-4 border border-gray-200 rounded-lg">
            <p className="text-gray-800 text-lg font-semibold">Themes and Styles</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddonBox
