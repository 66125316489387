import { call, fork, put, takeLatest } from "redux-saga/effects"
import * as actions from "redux/actions/addon"

import { ADDON_LIST_REQUESTED } from "redux/types"
import { API_POST } from "helpers/api"

function* watchGetAddonList() {
  yield takeLatest(ADDON_LIST_REQUESTED, getAddonList)
}

function* getAddonList(action) {
  try {
    const res = yield call(apiGetAddonList, action.payload)
    yield put(actions.addonListSucceed(res?.data))
  } catch (err) {
    yield put(actions.addonListFailed())
  }
}

const apiGetAddonList = (payload) => {
  return API_POST(`${process.env.REACT_APP_DISPLAY_DOMAIN}/api/addon/list`, payload)
}

export default function* addonListGet() {
  yield fork(watchGetAddonList)
}
