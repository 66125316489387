import { call, fork, put, takeLatest } from "redux-saga/effects"
import * as actions from "redux/actions/userInfo"

import { USER_INFO_STATE_REQUESTED } from "redux/types"
import { API_GET } from "helpers/api"

function* watchGetUserInfo() {
  yield takeLatest(USER_INFO_STATE_REQUESTED, getUserInfo)
}

function* getUserInfo() {
  try {
    const res = yield call(apiGetUserInfo)
    yield put(actions.userInfoSucceed(res?.data))
  } catch (err) {
    yield put(actions.userInfoFailed())
  }
}

const apiGetUserInfo = () => {
  return API_GET(`${process.env.REACT_APP_AUTH_DOMAIN}/connect/userinfo`, {}, false)
}

export default function* userInfo() {
  yield fork(watchGetUserInfo)
}
