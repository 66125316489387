import * as type from "redux/types"

export const myReservationsListRequested = (data) => ({
  type: type.MY_RESERVATIONS_LIST_REQUESTED,
  payload: data,
})

export const myReservationsListSucceed = (data) => ({
  type: type.MY_RESERVATIONS_LIST_SUCCEEDED,
  payload: data,
})

export const myReservationsListFailed = () => ({
  type: type.MY_RESERVATIONS_LIST_FAILED,
})
