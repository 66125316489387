export const CarouselDummy = {
  "name": "Tab Slider",
  "code": "new-aaa15fb4-d6fc-431e-bab1-8baf18cc7c56",
  "order": undefined,
  "children": [
    {
      "name": "Slider",
      "code": undefined,
      "data": {
        "category": {
          "categoryId": undefined,
          "order": 0,
          "categoryName": "",
          "categoryVisible": true
        },
      },
      "children": [
        {
          "name": "Image",
          "code": undefined,
          "data": {
            "src": "https://assets-v2.lottiefiles.com/a/490bc92a-1170-11ee-9e35-0f6eacef4099/DBwCBrM6eQ.gif",
            "size": 1840,
            "name": "",
            "fileForBinary": {}
          }
        },
        {
          "name": "Text",
          "code": undefined,
          "data": {
            "title": "",
          }
        }
      ]
    }
  ]
}