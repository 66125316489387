import Icon from "components/Icon"
import React from "react"

const SocialMediaBox = ({ icon, title, link }) => {
  return (
    <div className="flex gap-3 items-center border border-gray-200 p-4">
      <Icon
        name={icon}
        size="16"
        color="#98A2B3"
      />
      <div>
        <h3 className="text-sm text-gray-800 font-medium">{title}</h3>
        <a
          className="text-xs text-gray-500"
          href={link}
        >
          {link}
        </a>
      </div>
    </div>
  )
}

export default SocialMediaBox
