import * as type from "redux/types"

const initialState = {
  list: [],
  loading: false,
  error: false,
}

const addonList = (state = initialState, action) => {
  switch (action.type) {
    case type.ADDON_LIST_REQUESTED:
      return {
        ...state,
        loading: true,
        error: false,
      }
    case type.ADDON_LIST_SUCCEEDED:
      return {
        ...state,
        list: action.payload,
        loading: false,
        error: false,
      }
    case type.ADDON_LIST_FAILED:
      return {
        ...state,
        list: [],
        loading: false,
        error: true,
      }
    default:
      return state
  }
}

export default addonList
