import * as type from "redux/types"

const initialState = {
  data: null,
  loading: true,
  error: false,
}

const clientConfig = (state = initialState, action) => {
  switch (action.type) {
    case type.CLIENT_CONFIG_REQUESTED:
      return {
        ...state,
        loading: true,
        error: false,
      }
    case type.CLIENT_CONFIG_SUCCEEDED:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
      }
    case type.CLIENT_CONFIG_FAILED:
      return {
        ...state,
        data: null,
        loading: false,
        error: true,
      }
    default:
      return state
  }
}

export default clientConfig
