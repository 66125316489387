import { call, fork, put, takeLatest } from "redux-saga/effects"
import * as actions from "redux/actions/addonSave"

import { ADDON_SAVE_REQUESTED } from "redux/types"
import { API_POST } from "helpers/api"

function* watchGetDomainState() {
  yield takeLatest(ADDON_SAVE_REQUESTED, addonSave)
}

function* addonSave(action) {
  try {
    const data = action.payload
    const res = yield call(apiAddonSave, data)
    yield put(actions.addonSaveSucceed(res?.data))
  } catch (err) {
    yield put(actions.addonSaveFailed())
  }
}

const apiAddonSave = async (data) => {
  return await API_POST(`${process.env.REACT_APP_DISPLAY_DOMAIN}/api/domainaddon/save`, data)
}

export default function* saveAddon() {
  yield fork(watchGetDomainState)
}
