import { call, fork, put, takeLatest } from "redux-saga/effects"
import * as actions from "redux/actions/getRoles"

import { GET_ROLES_REQUESTED } from "redux/types"
import { API_GET } from "helpers/api"

function* watchGetRoleList() {
  yield takeLatest(GET_ROLES_REQUESTED, getRoleList)
}

function* getRoleList(action) {
  try {
    const res = yield call(apiGetRoleList, action.payload)
    yield put(actions.roleListSucceed(res?.data))
  } catch (err) {
    yield put(actions.roleListFailed())
  }
}

const apiGetRoleList = (payload) => {
  return API_GET(`${process.env.REACT_APP_PANEL_DOMAIN}/api/staticdata/rolelist`, payload)
}

export default function* roleListGet() {
  yield fork(watchGetRoleList)
}
