/* eslint-disable react-hooks/exhaustive-deps */
//import { useUserDataById } from "constants/mocks/userManagementMock"
import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

// components
import { Modal, Button, SelectPicker, Form } from "rsuite"
import { ErrorMessage } from "components"

// redux-saga
import { departmantListRequested } from "redux/actions/getDepartmants"
import { roleListRequested } from "redux/actions/getRoles"
import { countryCodesRequested } from "redux/actions/getCountryCodes"
import { clientUserRegisterSucceed } from "redux/actions/clientUserRegister"

//locale
import localization from "../../../localization"

/**
 *
 * @param {boolean} open Visibility of the modal
 * @param {(data) => void} onSuccess on success callback
 * @param {() => void} onFailed on Failed callback
 * @param {() => void} onClose on close callback
 * @returns
 */

const formInitialValue = {
  //status: "active",
  //id: undefined,
  name: "",
  email: "",
  password: "",
  PhoneNumber: "",
  //isAddedPanelUser: true,
  DepartmentIds: "",
  countryCode: 90, // TR
  RoleId: "",
}

export const convertDataForSelectPicker = (arr, value, label) => {
  return arr.reduce((acc, val) => {
    acc.push({
      value: val[value],
      label: val[label],
    })
    return acc
  }, [])
}

const UserAddModal = (props) => {
  const dispatch = useDispatch()
  //const { data: posts, error, isLoading } = useQuery("postsData", getDepartmants);

  // selectors
  const departmantList = useSelector((state) => state.getDepartmants.list.result)
  const roleList = useSelector((state) => state.getRoles.list.result)
  const countryCodeList = useSelector((state) => state.getCountryCodes?.list?.result)
  const clientUserRegisterLoading = useSelector((state) => state.clientUserRegister.loading)
  const clientUserRegisterErrorMessage = useSelector((state) => state.clientUserRegister?.errorMessages)

  // states
  /*const [ShowPwRequirements, setshowPwRequirements] = useState(false)*/
  /*const [PasswordList, setPasswordList] = useState({
    pw1: {
      type: "password",
      isShow: false,
      placeholder: "şifre",
    },
  })*/
  const [formValue, setFormValue] = useState(formInitialValue)
  const [buttonDisable, setButtonDisable] = useState(true)
  const [DepartmentListSelect, setDepartmentListSelect] = useState([])
  const [RoleListSelect, setRoleListSelect] = useState([])
  const [CountryCodeListSelect, setCountryCodeListSelect] = useState([])

  // Func
  // change password type and icon when i click eye icon on inputs
  /*const togglePassword = (pw) => {
    let isText = PasswordList[pw].type === "text"
    setPasswordList({
      ...PasswordList,
      [pw]: {
        ...PasswordList[pw],
        type: isText ? "password" : "text",
        isShow: !isText,
      },
    })
  }*/

  // const setPassword = (e, pw) => {
  //   let pwValue = e

  //   pwValue.length > 0 && pw.includes("1") ? setshowPwRequirements(true) : setshowPwRequirements(false)
  //   checkPasswordRequiretments(pw, pwValue)
  // }

  // const checkPasswordRequiretments = (pw, pwValue) => {
  //   let minLength = pwValue.length >= 8
  //   let specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(pwValue) // eslint-disable-line
  //   let isContainsNumber = /\d/.test(pwValue) // eslint-disable-line
  //   let isContainsUpperCase = /[A-Z]/.test(pwValue) // eslint-disable-line
  //   let isContainsLowerCase = /[a-z]/.test(pwValue) // eslint-disable-line
  //   let isContainsLowerAndUpperCase = isContainsUpperCase && isContainsLowerCase

  //   if (!pw) {
  //     return minLength && specialChars && isContainsNumber && isContainsLowerAndUpperCase
  //   }

  //   setPasswordList({
  //     ...PasswordList,
  //     [pw]: {
  //       ...PasswordList[pw],
  //       value: pwValue,
  //       minLength,
  //       specialChars,
  //       isContainsNumber,
  //       isContainsLowerAndUpperCase,
  //     },
  //   })
  // }

  const checkAllFields = (formValue) => {
    for (var key in formValue) {
      if (formValue.hasOwnProperty(key) && key !== "password") {
        if (formValue[key] === "") {
          return false
        }
      }
    }
    return true
  }

  const handleOnChangeForm = (value) => {
    setFormValue(value)
    const isAllFieldsDone = checkAllFields(value)
    // const pwDone = checkPasswordRequiretments(null, value.password)
    const buttonState = isAllFieldsDone // && pwDone
    setButtonDisable(!buttonState)
  }

  const handleOnClose = () => {
    dispatch(clientUserRegisterSucceed(null))
    setFormValue(formInitialValue)
    // checkPasswordRequiretments(Object.keys(PasswordList)[0], "")
    props.onClose()
  }

  const handleOnSubmit = () => {
    let formData = [formValue].reduce((acc, value) => {
      let splittedNameSpaces = value.name && value.name.split(" ")
      const departments = []
      departments.push(value.DepartmentIds * 1)
      acc.push({
        ...value,
        name: splittedNameSpaces.slice(0, -1).join(" "),
        surname: splittedNameSpaces.pop(),
        PhoneNumber: `${value.countryCode} ${value.PhoneNumber}`,
        DepartmentIds: departments,
        RoleId: value.RoleId * 1,
      })
      return acc
    }, [])
    props.onSuccess(...formData)
  }

  // effects
  useEffect(() => {
    if (props.open) {
      setFormValue({ ...formInitialValue })
    }

    return () => {
      setButtonDisable(true)
    }
  }, [props.open])

  useEffect(() => {
    if (props.open) {
      if (!departmantList) dispatch(departmantListRequested())
      if (!roleList) dispatch(roleListRequested())
      if (!countryCodeList) dispatch(countryCodesRequested())
    }
  }, [dispatch, props.open])

  useEffect(() => {
    if (departmantList && !DepartmentListSelect.length)
      setDepartmentListSelect(convertDataForSelectPicker(departmantList, "value", "text"))
    if (roleList && !RoleListSelect.length) setRoleListSelect(convertDataForSelectPicker(roleList, "value", "text"))
    if (countryCodeList && !CountryCodeListSelect.length)
      setCountryCodeListSelect(convertDataForSelectPicker(countryCodeList, "dialCode", "name"))
  }, [departmantList, roleList, countryCodeList])

  return (
    <>
      <Modal
        open={props.open}
        onClose={handleOnClose}
        className="w-[382px] !mt-0 absolute top-1/2 left-1/2 transform !-translate-x-1/2 !-translate-y-1/2"
      >
        <Form
          fluid
          formValue={formValue}
          onChange={handleOnChangeForm}
        >
          <Modal.Header
            closeButton={false}
            className="px-1"
          >
            <p className="font-semibold text-xl">Yeni kullanıcı ekleyin</p>
            <p className="text-gray-600 text-sm my-1">Formu doldurarak sisteme yeni kullanıcı ekleyin.</p>
          </Modal.Header>
          <Modal.Body className="px-1">
            {/* @todo This area will be done when update the api service about for this situation */}
            {/* <Form.Group controlId="PropertyId">
              <Form.ControlLabel className="text-sm font-medium">Üyelik Tipi*</Form.ControlLabel>
              <Form.Control
                name="PropertyId"
                placeholder="Üyelik tipi seçim"
                data={
                  []?.map((item) => ({
                    value: item.Id,
                    label: item.Name,
                  })) || []
                }
                accepter={SelectPicker}
                searchable={false}
                cleanable={false}
                style={{ width: "100%" }}
              />
            </Form.Group> */}
            <Form.Group controlId="name">
              <Form.ControlLabel className="text-sm font-medium">Ad Soyad* </Form.ControlLabel>
              <Form.Control
                name="name"
                placeholder="Ad soyad girin"
                className={"!outline-none focus:!outline-none !active:!outline-none"}
              />
            </Form.Group>
            <Form.Group controlId="email">
              <Form.ControlLabel className="text-sm font-medium">Email* </Form.ControlLabel>
              <Form.Control
                name="email"
                type="email"
                placeholder="Email adresi girin"
                className={"!outline-none focus:!outline-none !active:!outline-none"}
              />
              {clientUserRegisterErrorMessage && !clientUserRegisterLoading && (
                <ErrorMessage>{clientUserRegisterErrorMessage}</ErrorMessage>
              )}
            </Form.Group>
            <Form.Group controlId="PhoneNumber">
              <Form.ControlLabel className="text-sm font-medium">Telefon* </Form.ControlLabel>
              <div className="flex">
                <Form.Control
                  name="countryCode"
                  cleanable={false}
                  renderValue={(value) => `+${value}`}
                  renderMenuItem={(label, item) => `${item.label} +${item.value}`}
                  loading={!CountryCodeListSelect}
                  classPrefix=" w-auto"
                  locale={localization?.TR?.PickerLocaleType}
                  accepter={SelectPicker}
                  data={CountryCodeListSelect}
                />
                <Form.Control
                  name="PhoneNumber"
                  type="tel"
                  inputMode="numeric"
                  autoComplete="tel"
                  maxLength="19"
                  placeholder="xxxx xxxx xxxx xxxx"
                  required
                  className={"!outline-none focus:!outline-none !active:!outline-none w-full"}
                />
              </div>
            </Form.Group>
            {/* 
            <Form.Group controlId="password">
              <Form.ControlLabel className="text-sm font-medium">Geçici şifre</Form.ControlLabel>
              {Object.values(PasswordList).map((pw, k) => {
                return (
                  <div
                    key={k}
                    className=" mb-4"
                  >
                    <div className="form-wrapper">
                      <Form.Control
                        onChange={(e) => setPassword(e, Object.keys(PasswordList)[k])}
                        className="w-full placeholder:text-slate-500"
                        type={pw.type}
                        name="password"
                        placeholder={pw.placeholder}
                        autoComplete="none"
                      />
                      <div
                        className="absolute flex items-center px-3.5 inset-y-0 right-0 cursor-pointer"
                        onClick={() => togglePassword(Object.keys(PasswordList)[k])}
                      >
                        <Icon
                          name={`eye${!pw.isShow ? "-off" : ""}`}
                          size="16"
                          color={!pw.isShow ? "#98A2B3" : "#2E90FA"}
                        />
                      </div>
                    </div>

                    {k === 0 && ShowPwRequirements && (
                      <div className="text-gray-400 text-xs mt-1.5">
                        <p className={`mb-1 transition ${pw.minLength ? "text-success-400" : ""}`}>*Minimum 8 karakter</p>
                        <p className={`mb-1 transition ${pw.isContainsNumber ? "text-success-400" : ""}`}>
                          *Minimum bir adet rakam
                        </p>
                        <p className={`mb-1 transition ${pw.specialChars ? "text-success-400" : ""}`}>*Minimum bir adet sembol</p>
                        <p className={`mb-1 transition ${pw.isContainsLowerAndUpperCase ? "text-success-400" : ""}`}>
                          *Büyük ve küçük harf
                        </p>
                      </div>
                    )}
                  </div>
                )
              })}
            </Form.Group> */}
            {/* @todo This area will be done when update the api service about for this situation */}
            {/* <Form.Group controlId="password">
              <Form.ControlLabel className="text-sm font-medium">Geçici şifre* </Form.ControlLabel>
              <Form.Control
                name="password"
                placeholder="Departman seçin"
                accepter={InputPassword}
                style={{ width: "100%" }}
              />
            </Form.Group> */}
            <Form.Group controlId="department">
              <Form.ControlLabel className="text-sm font-medium">Departman* </Form.ControlLabel>
              <Form.Control
                name="DepartmentIds"
                placeholder="Departman seçin"
                data={DepartmentListSelect}
                accepter={SelectPicker}
                loading={!DepartmentListSelect}
                searchable={false}
                onLoad={(e) => console.log(e)}
                cleanable={false}
                style={{ width: "100%" }}
              />
            </Form.Group>
            <Form.Group controlId="role">
              <Form.ControlLabel className="text-sm font-medium">Rol* </Form.ControlLabel>
              <Form.Control
                name="RoleId"
                placeholder="Rol seçin"
                data={RoleListSelect}
                accepter={SelectPicker}
                loading={!RoleListSelect}
                searchable={false}
                cleanable={false}
                style={{ width: "100%" }}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="flex px-1">
            <Button
              onClick={handleOnClose}
              className="btn w-full bg-white text-gray-600 border border-solid border-gray-300 mr-2 hover:!text-white hover:!bg-gray-600 hover:!border-gray-600"
            >
              Vazgeç
            </Button>
            <Button
              onClick={handleOnSubmit}
              className="btn w-full hover:!text-white focus:!text-white"
              disabled={buttonDisable}
            >
              Kaydet
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}

const useUserAddModal = () => {
  const [open, setOpen] = useState(false)

  /**
   * @param {*} data
   */
  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return {
    open,
    handleOpen,
    handleClose,
  }
}

export { useUserAddModal }
export default UserAddModal
