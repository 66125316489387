import { call, fork, put, takeLatest } from "redux-saga/effects"
import * as actions from "redux/actions/pageUpdate"

import { PAGE_UPDATE_REQUESTED } from "redux/types"
import { API_POST } from "helpers/api"

function* watchUpdatePage() {
  yield takeLatest(PAGE_UPDATE_REQUESTED, getPageUpdate)
}

function* getPageUpdate(action) {
  try {
    const res = yield call(apiGetPageUpdate, action.payload)
    yield put(actions.pageUpdateSucceed(res?.data))
  } catch (err) {
    yield put(actions.pageUpdateFailed())
  }
}

const apiGetPageUpdate = (payload) => {
  return API_POST(`${process.env.REACT_APP_DISPLAY_DOMAIN}/api/page/update-domain-page`, payload)
}

export default function* pageUpdate() {
  yield fork(watchUpdatePage)
}
