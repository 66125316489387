import React from "react"

const ViewSettingsSkeleton = () => {
  return (
    <main>
      <section className="p-10 my-0 mx-auto flex flex-col container">
        <div className="flex justify-between items-center">
          <div className="animate-pulse space-y-3 my-3 w-1/3">
            <div className="h-2 bg-slate-200 rounded w-1/3" />
            <div className="h-2 bg-slate-200 rounded" />
          </div>
          <div className="animate-pulse space-y-3 h-10 bg-slate-200 rounded w-1/12 my-10" />
        </div>
        <div className="flex bg-white border border-solid border-gray-200 justify-between p-16 mb-10 gap-12 w-full h-[500px]">
          <div className="flex flex-col w-1/2 justify-center">
            <div className="flex flex-col justify-center">
              <div className="animate-pulse space-y-3 my-3">
                <div className="h-2 bg-slate-200 rounded w-1/3" />
                <div className="bg-slate-200 rounded h-[113px]" />
              </div>
            </div>
            <div className="animate-pulse space-y-3 my-3">
              <div className="h-2 bg-slate-200 rounded w-1/4" />
              <div className="h-12 bg-slate-200 rounded" />
            </div>
            <div className="animate-pulse space-y-3 my-3">
              <div className="h-2 bg-slate-200 rounded w-1/4" />
              <div className="h-12 bg-slate-200 rounded" />
            </div>
            <div className="animate-pulse space-y-3 my-7">
              <div className="h-2 bg-slate-200 rounded w-1/4" />
              <div className="h-10 bg-slate-200 rounded w-1/12" />
            </div>
          </div>
          <div className="flex flex-col justify-center w-1/2">
            <div className="animate-pulse space-y-3 my-12">
              <div className="h-2 bg-slate-200 rounded w-1/4" />
              <div className="bg-slate-200 rounded h-[420px]" />
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default ViewSettingsSkeleton
