import React from "react"
import MainHeader from "components/Headers/MainHeader"
import NoDataScreen from "../../components/NoDataScreen"

const NoRuleData = (props) => {
  let { isLogged } = props
  return (
    <>
      <MainHeader isLogged={isLogged} />
      <main>
        <section className="p-10 my-0 mx-auto max-w-[1920px] overflow-auto">
          <NoDataScreen />
        </section>
      </main>
    </>
  )
}

NoRuleData.propTypes = {}

NoRuleData.defaultProps = {}

export default NoRuleData
