import * as type from "redux/types"

export const propertiesRequested = (data) => ({
  type: type.PROPERTIES_REQUESTED,
  payload: data,
})

export const propertiesSucceed = (data) => ({
  type: type.PROPERTIES_SUCCEEDED,
  payload: data,
})

export const propertiesFailed = () => ({
  type: type.PROPERTIES_FAILED,
})
