import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import Icon from "components/Icon"
import TextEditor from "components/TextEditor"

import { saveUserSettingsRequested } from "redux/actions/saveUserSettings"
import { getSettingsRequested } from "redux/actions/getSettings"

const About = ({ setSelectedContent, getSettingsData, setLoading }) => {
  const dispatch = useDispatch()

  const [aboutData, setAboutData] = useState(null)
  const [Fullscreen, setFullscreen] = useState(false)

  const about = getSettingsData?.Result.find((i) => i.PropertyName === "Hakkımızda Text")

  const saveAndPublish = () => {
    dispatch(
      saveUserSettingsRequested({
        properties: [
          {
            PropertyId: about.PropertyId,
            PropertyValue: aboutData || "",
            Status: aboutData ? 1 : 0,
          },
        ],
      }),
    )
    setSelectedContent(false)
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
      dispatch(getSettingsRequested())
    }, 2000)
  }

  useEffect(() => {
    if (getSettingsData) {
      setAboutData(about.PropertyValue)
    }
  }, [about, getSettingsData])

  return (
    <div className="editor-area">
      {Fullscreen ? (
        <div className="bg-[#344054b3] fixed inset-0 z-[60] flex justify-center items-center">
          <div className="bg-white h-5/6 relative w-5/6 p-4 rounded-lg">
            <div className="flex justify-end absolute right-10 top-[50px] z-10">
              <Icon
                onClick={() => setFullscreen(!Fullscreen)}
                className={"cursor-pointer"}
                name="minimize-2"
                size="25"
                color="#98A2B3"
              />
            </div>

            <TextEditor
              data={aboutData}
              setData={setAboutData}
            />
            <div className="absolute bottom-4 right-4 flex justify-between">
              <div className="flex items-center gap-2">
                <button
                  onClick={() => setSelectedContent(false)}
                  className={`btn outline`}
                >
                  Vazgeç
                </button>
                <button
                  onClick={saveAndPublish}
                  className="btn"
                >
                  Kaydet ve Yayınla
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="relative">
          <div className="flex justify-end absolute right-8 top-8 z-10">
            <Icon
              onClick={() => setFullscreen(!Fullscreen)}
              className={"cursor-pointer"}
              name="maximize-2"
              size="25"
              color="#98A2B3"
            />
          </div>
          <TextEditor
            data={aboutData}
            setData={setAboutData}
          />
          <div className="button-group flex flex-row justify-end gap-4 mt-6">
            <button
              onClick={() => setSelectedContent(false)}
              className={`btn outline`}
            >
              Vazgeç
            </button>
            <button
              onClick={saveAndPublish}
              className="btn"
            >
              Kaydet ve Yayınla
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default About
