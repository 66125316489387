import ReactModal from "react-modal"

import { TitleH1 } from "components"

const Modal = ({ title = "", open = false, width, headerClass = "mb-8", wrapperClass = "p-3", closeButton, onChange, children }) => {
  const customStyles = {
    content: {
      height: "auto",
      bottom: "auto",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: !!width ? width + "px" : "950px",
    },
  }

  return (
    <ReactModal
      style={customStyles}
      isOpen={open}
      ariaHideApp={false}
    >
      <section className={`h-[calc(100%-40px)] ${wrapperClass}`}>
        {(title !== "" || closeButton) && (
          <div className={`modal-header flex items-center justify-between ${headerClass}`}>
            {<TitleH1>{title}</TitleH1>}
            {closeButton && (
              <button
                className="btn sm outline square"
                onClick={() => onChange()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.28607 5.28595C5.6115 4.96052 6.13914 4.96052 6.46458 5.28595L10.0001 8.82149L13.5356 5.28595C13.8611 4.96052 14.3887 4.96052 14.7142 5.28595C15.0396 5.61139 15.0396 6.13903 14.7142 6.46447L11.1786 10L14.7142 13.5355C15.0396 13.861 15.0396 14.3886 14.7142 14.714C14.3887 15.0395 13.8611 15.0395 13.5356 14.714L10.0001 11.1785L6.46458 14.714C6.13914 15.0395 5.6115 15.0395 5.28607 14.714C4.96063 14.3886 4.96063 13.861 5.28607 13.5355L8.8216 10L5.28607 6.46447C4.96063 6.13903 4.96063 5.61139 5.28607 5.28595Z"
                    fill="black"
                  />
                </svg>
              </button>
            )}
          </div>
        )}

        <div className="modal-body">{children}</div>
      </section>
    </ReactModal>
  )
}

export default Modal
