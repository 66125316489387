import React, { useState } from "react"
import PropTypes from "prop-types"

const ButtonGroupBox = (props) => {
  let { title, buttons, onChange, ActiveButton, className } = props

  return (
    <div className={`flex flex-col border border-gray-200 px-3 pt-3.5 pb-5 rounded-lg ${className}`}>
      <label className="text-sm">{title}</label>
      <div className="flex justify-center text-center border border-gray-200 rounded-lg mt-4">
        {buttons.map((style, key) => {
          return (
            <span
              className={`flex items-center justify-center w-full py-2 text-xs font-medium ${
                ActiveButton === style ? "bg-blue-50" : ""
              } border-l border-gray-200 cursor-pointer first:border-l-0`}
              key={key}
              onClick={() => onChange(style)}
            >
              {style}
            </span>
          )
        })}
      </div>
    </div>
  )
}

export default ButtonGroupBox

ButtonGroupBox.prototypes = {
  title: PropTypes.string,
  buttons: PropTypes.array,
  ActiveButton: PropTypes.string,
  onChange: PropTypes.func,
}

ButtonGroupBox.defaultProps = {
  title: "Style",
  buttons: ["A Button", "B Button"],
  ActiveButton: "A Button",
  //onChange: (e) => console.log(e),
}
