import * as type from "redux/types"

export const clientUserUpdateRequested = (data) => ({
  type: type.CLIENT_USER_UPDATE_REQUESTED,
  payload: data,
})

export const clientUserUpdateSucceed = (data) => ({
  type: type.CLIENT_USER_UPDATE_SUCCEEDED,
  payload: data,
})

export const clientUserUpdateFailed = () => ({
  type: type.CLIENT_USER_UPDATE_FAILED,
})
