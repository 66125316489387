import React from "react"
import { Controller, useFormContext } from "react-hook-form"
import { SelectPicker } from "rsuite"

const statusItems = [
  {
    key: "active",
    label: "Aktif",
    value: "active",
  },
  {
    key: "passive",
    label: "Pasif",
    value: "passive",
  },
]

const StatusSelectBox = ({ methods }) => {
  const {
    formState: { errors },
  } = useFormContext()

  const errorMessage = errors["rule"]?.Status?.message

  return (
    <>
      <div className="text-sm">Statü</div>
      <Controller
        control={methods.control}
        name="rule.Status"
        rules={{
          required: "Statü seçiniz",
        }}
        render={({ field }) => (
          <>
            <SelectPicker
              name={field.name}
              menuStyle={{ width: 270 }}
              block
              searchable={false}
              cleanable={false}
              placeholder="Statü seçiniz"
              data={statusItems}
              onChange={(e) => field.onChange(e)}
              value={field.value}
            />
            {errorMessage && <p className="text-red-500 text-xs py-1">{errorMessage}</p>}
          </>
        )}
      />
    </>
  )
}

export default StatusSelectBox
