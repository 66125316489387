import * as type from "../types"

export const saveConfigurationRequested = (data) => ({
  type: type.SAVE_CONFIGURATON_REQUESTED,
  payload: data,
})

export const saveConfigurationSucceed = (data) => ({
  type: type.SAVE_CONFIGURATON_SUCCEEDED,
  payload: data,
})

export const saveConfigurationFailed = () => ({
  type: type.SAVE_CONFIGURATON_FAILED,
})
