import * as type from "redux/types"

export const departmantListRequested = (data) => ({
  type: type.GET_DEPARTMANTS_REQUESTED,
  payload: data,
})

export const departmantListSucceed = (data) => ({
  type: type.GET_DEPARTMANTS_SUCCEEDED,
  payload: data,
})

export const departmantListFailed = () => ({
  type: type.GET_DEPARTMANTS_FAILED,
})
