import { Icon } from "components"
import React, { useEffect } from "react"
import { useFormContext, Controller } from "react-hook-form"
import { Radio, RadioGroup } from "rsuite"

export const generalSettingsList = [
  { name: "siyah-beyaz", title: "Siyah-Beyaz", icon: "black-white", group: "İkon Rengi" },
  { name: "renkli", title: "Renkli", icon: "colored", group: "İkon Rengi" },
  { name: "ikon", title: "İkon", group: "Görünüm" },
  { name: "ikon-sosyal-medya-adi", title: "İkon ve Sosyal Medya Adı", group: "Görünüm" },
]

const groupBy = (list, key) => {
  return list.reduce((acc, item) => {
    const groupKey = item[key]
    if (!acc[groupKey]) acc[groupKey] = []
    acc[groupKey].push(item)
    return acc
  }, {})
}

const groupedSettings = groupBy(generalSettingsList, "group")

const SocialMediaGeneralSettings = ({ displayPreference, iconPreference }) => {
  const { control, setValue } = useFormContext()

  useEffect(() => {
    if (displayPreference) {
      const displayValue = displayPreference === "IconWithName" ? "ikon-sosyal-medya-adi" : "ikon"
      setValue("generalSettings.Görünüm", displayValue)
    } else {
      setValue("generalSettings.Görünüm", "ikon")
    }

    if (iconPreference) {
      const iconValue = iconPreference === "Colored" ? "renkli" : "siyah-beyaz"
      setValue("generalSettings.İkon Rengi", iconValue)
    } else {
      setValue("generalSettings.İkon Rengi", "siyah-beyaz")
    }
  }, [displayPreference, iconPreference, setValue])

  return (
    <div className="bg-white border border-gray-200 rounded-lg p-8 mt-6">
      <div className="flex items-center justify-between">
        <h3 className="text-gray-800 text-lg font-semibold">Genel Ayarlar</h3>
      </div>
      <div className="flex gap-3">
        {Object.entries(groupedSettings).map(([group, settings]) => (
          <div
            key={group}
            className="flex flex-col gap-3 w-1/2 mt-4"
          >
            <h4 className="text-gray-800 font-medium">{group}</h4>
            <Controller
              name={`generalSettings.${group}`}
              control={control}
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  className="flex flex-row gap-3"
                >
                  {settings.map(({ name, icon, title }) => (
                    <Radio
                      key={name}
                      value={name}
                      className={`w-1/2 border rounded-lg text-sm text-gray-800 font-medium p-2 pr-2 ${
                        field.value === name ? "border-blue-200" : "border-gray-200"
                      }`}
                    >
                      <div className="flex item-center justify-between">
                        <span>{title}</span>
                        {group === "İkon Rengi" &&
                          (icon === "black-white" ? (
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 22 22"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M22 11.415C22 12.7283 21.7413 14.0286 21.2388 15.2419C20.7362 16.4551 19.9997 17.5575 19.0711 18.4861L16.2426 15.6577C16.7998 15.1005 17.2417 14.4391 17.5433 13.7111C17.8448 12.9832 18 12.203 18 11.415H22Z"
                                fill="#101828"
                              />
                              <path
                                d="M19.0711 18.4861C17.1957 20.3615 14.6522 21.415 12 21.415L12 17.415C13.5913 17.415 15.1174 16.7829 16.2426 15.6577L19.0711 18.4861Z"
                                fill="#F2F4F7"
                              />
                              <path
                                d="M12 21.415C9.34784 21.415 6.8043 20.3615 4.92894 18.4861L7.75736 15.6577C8.88258 16.7829 10.4087 17.415 12 17.415V21.415Z"
                                fill="#EAECF0"
                              />
                              <path
                                d="M4.92893 18.4861C3.05357 16.6107 2 14.0672 2 11.415L6 11.415C6 13.0063 6.63214 14.5325 7.75736 15.6577L4.92893 18.4861Z"
                                fill="#D0D5DD"
                              />
                              <path
                                d="M2 11.415C2 8.76288 3.05357 6.21934 4.92893 4.34398L7.75736 7.1724C6.63214 8.29762 6 9.82374 6 11.415H2Z"
                                fill="#475467"
                              />
                              <path
                                d="M4.92893 4.34397C6.80429 2.46861 9.34783 1.41504 12 1.41504L12 5.41504C10.4087 5.41504 8.88258 6.04718 7.75736 7.1724L4.92893 4.34397Z"
                                fill="#667085"
                              />
                              <path
                                d="M12 1.41504C14.6522 1.41504 17.1957 2.4686 19.0711 4.34396L16.2426 7.17239C15.1174 6.04718 13.5913 5.41504 12 5.41504V1.41504Z"
                                fill="#98A2B3"
                              />
                              <path
                                d="M19.0711 4.34397C20.9464 6.21933 22 8.76287 22 11.415L18 11.415C18 9.82374 17.3679 8.29761 16.2426 7.1724L19.0711 4.34397Z"
                                fill="#344054"
                              />
                            </svg>
                          ) : (
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 20 21"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M20 10.415C20 11.7283 19.7413 13.0286 19.2388 14.2419C18.7362 15.4551 17.9997 16.5575 17.0711 17.4861L14.2426 14.6577C14.7998 14.1005 15.2417 13.4391 15.5433 12.7111C15.8448 11.9832 16 11.203 16 10.415H20Z"
                                fill="#FFE73E"
                              />
                              <path
                                d="M17.0711 17.4861C15.1957 19.3615 12.6522 20.415 10 20.415L10 16.415C11.5913 16.415 13.1174 15.7829 14.2426 14.6577L17.0711 17.4861Z"
                                fill="#51FF51"
                              />
                              <path
                                d="M10 20.415C7.34784 20.415 4.8043 19.3615 2.92894 17.4861L5.75736 14.6577C6.88258 15.7829 8.4087 16.415 10 16.415V20.415Z"
                                fill="#70C1FF"
                              />
                              <path
                                d="M2.92893 17.4861C1.05357 15.6107 1.03279e-06 13.0672 0 10.415L4 10.415C4 12.0063 4.63214 13.5325 5.75736 14.6577L2.92893 17.4861Z"
                                fill="#A878FF"
                              />
                              <path
                                d="M0 10.415C2.31859e-07 7.76288 1.05357 5.21934 2.92893 3.34398L5.75736 6.1724C4.63214 7.29762 4 8.82374 4 10.415H0Z"
                                fill="#FF6FFA"
                              />
                              <path
                                d="M2.92893 3.34397C4.80429 1.46861 7.34783 0.415042 9.99999 0.415039L9.99999 4.41504C8.4087 4.41504 6.88258 5.04718 5.75736 6.1724L2.92893 3.34397Z"
                                fill="#FF4D96"
                              />
                              <path
                                d="M10 0.415039C12.6522 0.415039 15.1957 1.4686 17.0711 3.34396L14.2426 6.17239C13.1174 5.04718 11.5913 4.41504 10 4.41504V0.415039Z"
                                fill="#FF4545"
                              />
                              <path
                                d="M17.0711 3.34397C18.9464 5.21933 20 7.76287 20 10.415L16 10.415C16 8.82374 15.3679 7.29761 14.2426 6.1724L17.0711 3.34397Z"
                                fill="#FF7243"
                              />
                            </svg>
                          ))}
                      </div>
                    </Radio>
                  ))}
                </RadioGroup>
              )}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default SocialMediaGeneralSettings
