const getItemRelationObjectIndex = (model, relation = "Component") => {
	return model?.findIndex(child => child?.relation === relation) || 0
}
const getItemRelationObjectData = (model, relation = "Component") => {
	return model?.find(child => child?.relation === relation)
}

export {
	getItemRelationObjectIndex,
	getItemRelationObjectData,
}