/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import {useSelector} from "react-redux"

// layouts
import Categories from "./Categories"
import BlockWrapper from "../../BlockWrapper"
import AddOrEditMedia from "./AddOrEditMedia"
import Settings from "../Slider/Settings"
import { TitleLayout } from "../../Design"

// components
import { TabList, SideMenuListItem, EditBlockName } from "components"

// utils
import getKeyByValue from "utils/getKeyByValue"
import getSelectedIndex from "utils/getSelectedIndex";
import {
  CategoryList,
  newMediaList,
  MEDIA_LIST,
  TabListTabLabels,
  NEW_REGISTER_CODE
} from "../../../Enums";
import AddOrEditCategory from "../Slider/CategorySlides/AddOrEditCategory";

// saga-requests

const MediaList = (props) => {
  let { show, activeLayout, setEditLayouts, EditBlock, setEditBlock } = props

  let infoCodeForStrategy = activeLayout?.info?.code
  let code = infoCodeForStrategy || activeLayout?.code
  // selectors
  const categoryList = useSelector((state) => state.pageManager.categoryList.list)
  const components = useSelector(state => state.strategy.getStrategy.list?.result)

  // states
  const [EditMediaTab, setEditMediaTab] = useState(0)
  const [ThisComponent, setThisComponent] = useState((components && components?.children && components?.children.find(child => child?.code === infoCodeForStrategy)) || newMediaList(code, activeLayout?.order))
  const [SelectedCategory, setSelectedCategory] = useState(null)

  const [subComponentIdList] =  useState({
    id: NEW_REGISTER_CODE(),
    imageId: NEW_REGISTER_CODE(),
    textId: NEW_REGISTER_CODE(),
    subTextId: NEW_REGISTER_CODE(),
    buttonId: NEW_REGISTER_CODE(),
    hyperLinkId: NEW_REGISTER_CODE(),
  })

  // Func
  const editMediaTabChanged = (e) => {
    let selectedIndex = getSelectedIndex(e)
    setEditMediaTab(selectedIndex)
  }

  useEffect(() => {
    if (SelectedCategory && (!ThisComponent?.data && !ThisComponent?.visual)){
      const mockLayout = {
        [MEDIA_LIST]: true,
        info: activeLayout?.info,
        code: activeLayout?.code,
        virtualBlock: activeLayout?.virtualBlock,
        order: activeLayout?.order,
        mock: {
          category: {
            name : SelectedCategory?.Name
          }
        }
      }
      setEditLayouts(mockLayout)
    }

  }, [SelectedCategory])


  return (
    <BlockWrapper
      show={show}
      activeLayout={activeLayout}
      setEditLayouts={setEditLayouts}
      editBlock={EditBlock}
      setEditBlock={setEditBlock}
      ThisComponent={ThisComponent}
      setThisComponent={setThisComponent}
      doneBtnOnClick={() => {
        setEditLayouts(null)
      }}
    >
      {(!EditBlock || !EditBlock[getKeyByValue(EditBlock, true)]) && (
        <>
          <TabList
            onChange={editMediaTabChanged}
            tabs={[
              { label: TabListTabLabels.content, icon: "list", disabled: false },
              { label: TabListTabLabels.design, icon: "palette-solid", disabled: false },
              { label: TabListTabLabels.publish, icon: "share", disabled: true },
            ]}
            tabClassName="w-full flex items-center gap-2.5 text-center text-gray-600 text-xs p-2 border-r last:border-r-0 hover:bg-gray-50 transition duration-400"
            wrapperClassName="flex shrink-0 border rounded-lg border-gray-200 overflow-hidden mb-7"
            //itemActiveClass="!text-gray-800 !bg-blue-50"
            activeTab={EditMediaTab}
          />

          {show && (
            <>
              {EditMediaTab === 0 && (
                <>
                  <div className="input-wrapper mb-7">
                    <SideMenuListItem
                      title={"Başlık"}
                      iconName={"chevron-right"}
                      onClick={() => {
                        setEditBlock({
                          showTitleLayout: true,
                        })
                      }}
                    />
                  </div>
                  <Categories
                    activeLayout={activeLayout}
                    EditBlock={EditBlock}
                    setEditBlock={setEditBlock}
                    ThisComponent={ThisComponent}
                    setThisComponent={setThisComponent}
                    SelectedCategory={SelectedCategory}
                    setSelectedCategory={setSelectedCategory}
                    categoryList={categoryList}
                    subComponentIdList={subComponentIdList}
                  />
                </>
              )}
              {EditMediaTab === 1 && (
                <Settings
                  ThisComponent={ThisComponent}
                  setThisComponent={setThisComponent}/>
              )}
              {EditMediaTab === 2 && <h1>Publish</h1>}
            </>
          )}
        </>
      )}

      {activeLayout && (
        <>
          <EditBlockName
            editBlock={EditBlock}
            setEditBlock={setEditBlock}
          />
          <AddOrEditMedia
            activeLayout={activeLayout}
            editBlock={EditBlock}
            setEditBlock={setEditBlock}
            ThisComponent={ThisComponent}
            setThisComponent={setThisComponent}
            SelectedCategory={SelectedCategory}
            subComponentIdList={subComponentIdList}
          />
          <AddOrEditCategory
            editBlock={EditBlock}
            setEditBlock={setEditBlock}
            ThisComponent={ThisComponent}
            setThisComponent={setThisComponent}
            categoryList={categoryList}
            categoryType={CategoryList.MEDIA_LIST}
          />
        </>
      )}

      {/*title layout*/}
      <TitleLayout
        editBlock={EditBlock}
        setEditBlock={setEditBlock}
        ThisComponent={ThisComponent}
        setThisComponent={setThisComponent}
      />
    </BlockWrapper>
  )
}

export default MediaList
