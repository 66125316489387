import React, { useEffect } from "react"
import moment from "moment"
import { BorderBox } from "components"

const ReservationReportsDetailPersonInfo = (props) => {
  let [data] = [props.data]
  const [personData, setPersonData] = React.useState()

  useEffect(() => {
    data && setPersonData(JSON.parse(data))
  }, [data])

  return (
    <BorderBox className="p-8">
      <div className="flex flex-col">
        <h3 className="text-gray-800 font-semibold mb-4">Kişi Bilgileri</h3>
        <div className="flex flex-col">
          <div className="flex gap-4 justify-between">
            <div className="flex flex-col gap-1">
              <span className="text-gray-500">Ad Soyad</span>
              <span className="text-gray-800">{`${personData?.[0]?.Name} ${personData?.[0]?.Surname}`}</span>
            </div>
            <div className="flex flex-col gap-1">
              <span className="text-gray-500">Kimlik Numarası</span>
              <span className="text-gray-800">{personData?.[0]?.TcKimlikNo} </span>
            </div>
            <div className="flex flex-col gap-1">
              <span className="text-gray-500">Doğum Tarihi</span>
              <span className="text-gray-800">{moment(personData?.[0]?.Birthday).format("DD.MM.YYYY")}</span>
            </div>
            <div className="flex flex-col gap-1">
              <span className="text-gray-500">Telefon Numarası</span>
              <span className="text-gray-800">{`${personData?.[0]?.PhoneNumbers[0].AreaCode} ${personData?.[0]?.PhoneNumbers[0].Number}`}</span>
            </div>
            <div className="flex flex-col gap-1">
              <span className="text-gray-500">E-posta</span>
              <span className="text-gray-800">{personData?.[0]?.Email}</span>
            </div>
          </div>
        </div>
      </div>
    </BorderBox>
  )
}

export default ReservationReportsDetailPersonInfo
