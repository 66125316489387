import React from "react"
import PropTypes from "prop-types"

const PreviewAreaSettings = (props) => {
  let {
    className,
    config: { ThemeConfig },
    type,
  } = props

  return (
    <>
      {type === "B2C" && (
        <div
          className="relative w-7/12 flex flex-col overflow-hidden"
          style={{
            fontFamily: `"${ThemeConfig?.displaySettings.fontFamily}"`,
          }}
        >
          <div className="preview-area flex w-full">
            <div className="preview-body flex flex-col w-full rounded-lg overflow-hidden border border-gray-200 shadow-lg">
              <div className="window-indicators flex items-center h-6 px-2 border-b border-gray-200">
                {[1, 2, 3].map((v) => {
                  return (
                    <div
                      key={v}
                      className="rounded-xl bg-gray-400 p-[3px] ml-[3px] first:ml-0"
                    />
                  )
                })}
              </div>
              <div className="preview-header flex items-center px-8 py-2">
                {ThemeConfig && ThemeConfig?.displaySettings?.logoValues?.src ? (
                  <img
                    className="max-w-[120px] max-h-12"
                    src={ThemeConfig?.displaySettings?.logoValues?.src}
                    alt="logo"
                  />
                ) : (
                  <div className="bg-gray-200 rounded-full p-2.5" />
                )}
                <ul className="flex items-center text-[8px] text-gray-500 font-medium ml-auto">
                  {["Dil Seçimi", "Yardım", "Giriş Yap"].map((v) => {
                    return (
                      <li
                        key={v}
                        className="ml-5 first:ml-0"
                      >
                        {v}
                      </li>
                    )
                  })}
                </ul>
              </div>
              <div className="preview-main grow flex flex-col pb-4">
                <div className="travel-planner bg-gray-100">
                  <div className="px-7 pt-6 pb-10">
                    <p className="text-sm text-gray-800 font-semibold">Sıradaki konaklamanızı bulun.</p>
                    <ul
                      className="flex items-center bg-white px-2 py-1 mt-4 transition-all-all duration-300"
                      style={{
                        borderRadius: ThemeConfig?.displaySettings.borderRadius,
                      }}
                    >
                      {[1, 2, 3, 4].map((v) => {
                        return (
                          <li
                            key={v}
                            className={`relative w-full p-2 ml-4 first:ml-0 transition-all-all duration-300 bg-gray-100`}
                            style={
                              v === 4
                                ? {
                                    borderRadius: ThemeConfig?.displaySettings?.borderRadius,
                                    backgroundColor: ThemeConfig?.displaySettings?.buttonColor,
                                  }
                                : {
                                    borderRadius: ThemeConfig?.displaySettings?.borderRadius,
                                  }
                            }
                          >
                            {v === 4 ? (
                              <span
                                className="absolute grid items-center inset-0 text-[8px] text-center font-bold"
                                style={{
                                  color: ThemeConfig?.displaySettings?.buttonColorIsLight ? "#000" : "#fff",
                                }}
                              >
                                Button
                              </span>
                            ) : (
                              ""
                            )}
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </div>
                <div className="slider-box bg-white mt-7 grow">
                  <div className="px-7 flex flex-col h-full">
                    <ul className="flex items-center mb-3">
                      {[1, 2, 3].map((v) => {
                        return (
                          <li
                            key={v}
                            className={`w-full flex justify-center text-[8px] text-center font-semibold p-[1px] ml-4 first:ml-0 transition-all-all duration-300 ${
                              v === 1 ? "bg-gray-100 text-gray-900" : "text-gray-500"
                            }`}
                            style={{
                              borderRadius: ThemeConfig?.displaySettings.borderRadius,
                            }}
                          >
                            Link {v}
                          </li>
                        )
                      })}
                    </ul>

                    <div
                      className="bg-gray-100 grow min-h-[120px] transition-all-all duration-300"
                      style={{
                        borderRadius: ThemeConfig?.displaySettings.borderRadius,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {type === "Corporate" && (
        <div
          className="relative w-7/12 flex flex-col overflow-hidden"
          style={{
            fontFamily: `"${ThemeConfig?.displaySettings.fontFamily}"`,
          }}
        >
          <div className="preview-area flex w-full">
            <div className="preview-body flex flex-col w-full rounded-lg overflow-hidden border border-gray-200 shadow-lg">
              <div className="window-indicators flex items-center h-6 px-2 border-b border-gray-200">
                {[1, 2, 3].map((v) => {
                  return (
                    <div
                      key={v}
                      className="rounded-xl bg-gray-400 p-[3px] ml-[3px] first:ml-0"
                    />
                  )
                })}
              </div>
              <div className="preview-header flex items-center px-8 py-5">
                {ThemeConfig && ThemeConfig?.displaySettings?.logoValues?.src ? (
                  <img
                    className="max-w-[120px] max-h-12"
                    src={ThemeConfig?.displaySettings?.logoValues?.src}
                    alt="logo"
                  />
                ) : (
                  <div className="bg-gray-200 rounded-full p-2.5" />
                )}
                <ul className="flex items-center text-[8px] text-gray-500 font-medium ml-auto">
                  {["Dil Seçimi", "Yardım", "Taleplerim", "Giriş Yap"].map((v) => {
                    return (
                      <li
                        key={v}
                        className="ml-5 first:ml-0"
                      >
                        {v}
                      </li>
                    )
                  })}
                </ul>
              </div>
              <div className="preview-main grow flex flex-col pb-4">
                <div className="travel-planner bg-gray-50">
                  <div className="px-20 pt-6 pb-10">
                    <p className="text-xl text-gray-800 font-bold">İş seyahatları için konaklama planlayın</p>
                    <p className="text-xs mt-1 text-gray-500">Kendiniz ve iş arkadaşlarınız için konaklama seyahatı planlayın.</p>
                    <ul
                      className="grid grid-cols-3 gap-3 searchbar items-center bg-white shadow-_2xl overflow-hidden !transition-all p-4 mt-6"
                      style={{
                        borderRadius: ThemeConfig?.displaySettings.borderRadius,
                      }}
                    >
                      {[1, 2, 3, 4, 5, 6].map((v) => {
                        return (
                          <li
                            key={v}
                            className={`h-[34px] relative w-full p-2 transition-all-all duration-300 bg-gray-100`}
                            style={
                              v === 6
                                ? {
                                    borderRadius: ThemeConfig?.displaySettings?.borderRadius,
                                    backgroundColor: ThemeConfig?.displaySettings?.buttonColor,
                                  }
                                : {
                                    borderRadius: ThemeConfig?.displaySettings?.borderRadius,
                                  }
                            }
                          >
                            {v === 6 ? (
                              <span
                                className="absolute grid items-center inset-0 text-[8px] text-center font-bold"
                                style={{
                                  color: ThemeConfig?.displaySettings?.buttonColorIsLight ? "#000" : "#fff",
                                }}
                              >
                                Button
                              </span>
                            ) : (
                              ""
                            )}
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </div>
                <div className="slider-box bg-white mt-7 grow">
                  <div className="px-20 flex flex-col h-full">
                    <p className="text-sm text-gray-800 font-semibold mb-2">Konaklama Taleplerim</p>
                    <div
                      className="bg-gray-100 grow min-h-[120px] transition-all-all duration-300"
                      style={{
                        borderRadius: ThemeConfig?.displaySettings.borderRadius,
                      }}
                    />
                  </div>
                </div>
                <div className="slider-box bg-white mt-7 grow">
                  <div className="px-20 flex flex-col h-full">
                    <p className="text-sm text-gray-800 font-semibold mb-2">Yaklaşan Otel Rezervasyonlarım</p>
                    <div
                      className="bg-gray-100 grow min-h-[120px] transition-all-all duration-300"
                      style={{
                        borderRadius: ThemeConfig?.displaySettings.borderRadius,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {type === "Otel" && (
        <div
          className={`${className} box p-0 lg:min-h-[248px] shadow-_2xl`}
          style={{
            fontFamily: `"${ThemeConfig?.displaySettings.fontFamily}"`,
          }}
        >
          <div className="w-full h-6 px-2 inline-flex items-center gap-[3px] border-b border-gray-200 rounded">
            <span className="h-[6px] w-[6px] bg-gray-400 rounded-full" />
            <span className="h-[6px] w-[6px] bg-gray-400 rounded-full" />
            <span className="h-[6px] w-[6px] bg-gray-400 rounded-full" />
          </div>

          <div className="p-6">
            {ThemeConfig?.displaySettings?.logoValues?.src && (
              <div className="box p-4 mb-4">
                <img
                  src={ThemeConfig?.displaySettings?.logoValues?.src}
                  alt="Logo"
                  className="max-h-20"
                />
              </div>
            )}

            <strong className={`block mb-4 text-gray-500 font-bold transition-all`}>Başlık yazı tipi</strong>

            <div
              className={`bg-gray-200 p-4 flex gap-4 flex-col lg:flex-row transition-all`}
              style={{
                borderRadius: ThemeConfig?.displaySettings.borderRadius,
              }}
            >
              <div
                className={`h-[104px] w-40 bg-white transition-all`}
                style={{
                  borderRadius: ThemeConfig?.displaySettings.borderRadius,
                }}
              />

              <div>
                <div
                  className={`h-4 w-24 bg-gray-100 mb-2 transition-all`}
                  style={{
                    borderRadius: ThemeConfig?.displaySettings.borderRadius,
                  }}
                />
                <div
                  className={`h-4 w-40 bg-gray-100 mb-2 transition-all`}
                  style={{
                    borderRadius: ThemeConfig?.displaySettings.borderRadius,
                  }}
                />
                <div
                  className={`h-4 w-[120px] bg-gray-100 mb-4 transition-all`}
                  style={{
                    borderRadius: ThemeConfig?.displaySettings.borderRadius,
                  }}
                />

                <button
                  className={`h-6 w-[111px] text-xs transition-all bg-gray-500 pointer-events-none`}
                  style={{
                    borderRadius: ThemeConfig?.displaySettings.borderRadius,
                    backgroundColor: ThemeConfig?.displaySettings.buttonColor,
                    color: ThemeConfig?.displaySettings.buttonColorIsLight ? "black" : "white",
                  }}
                >
                  Buton
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default PreviewAreaSettings

PreviewAreaSettings.propTypes = {
  className: PropTypes.string,
}
PreviewAreaSettings.defaultProps = {
  className: "w-full",
}
