import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

// components
import ProperyBox from "components/ProperyBox"

// saga requests
import { contractsSetRequested } from "redux/actions/contractsSet"
import { propertiesRequested } from "redux/actions/getPropertiesWithUser"
import ReservationPolicySkeleton from "../../skeletons/ReservationPolicy"

const ReservationPolicy = (props) => {
  let { AssociatedDomains } = props

  const dispatch = useDispatch()

  const TenantId = AssociatedDomains?.tenantId

  // selectors
  const propertiesList = useSelector((state) => state.getPropertiesWithUser.list)
  const propertiesLoading = useSelector((state) => state.getPropertiesWithUser.loading)
  const propertiesError = useSelector((state) => state.getPropertiesWithUser.error)
  //const contractsSetResponse = useSelector((state) => state.contractsSet.result)

  // states
  let [EditProperyList, setEditProperyList] = useState({})

  // Func
  const setPropertiesCallBack = (fieldName, event) => {
    let { name, value } = event.target
    setEditProperyList({
      ...EditProperyList,
      [name]: typeof value === "boolean" ? JSON.stringify(value) : value,
    })
  }

  const saveSettings = () => {
    // wrap every object inside array
    const newEditProperyList = Object.entries(EditProperyList).map((value) => [{ PropertyId: value[0], PropertyValue: value[1] }])

    dispatch(
      contractsSetRequested({
        tenantId: TenantId,
        properties: newEditProperyList.flat(1),
      }),
    )
  }

  useEffect(() => {
    dispatch(propertiesRequested(TenantId))
  }, [dispatch, TenantId])

  return (
    <>
      {propertiesError && !propertiesList && !propertiesLoading && (
        <code className="bg-error-50 text-error-500 text-sm">
          Yükleme sırasında bir sorun ile karşılaşıldı, lütfen daha sonra tekrar deneyiniz.
        </code>
      )}

      {propertiesLoading && <ReservationPolicySkeleton />}

      {!propertiesLoading && propertiesList && (
        <>
          <p className="text-base text-gray-500 my-8">Otel politikanıza uygun müşteri deneyimi için ayarlarınızı güncel tutun.</p>

          {[propertiesList].map((properties, index) => {
            return (
              <div
                key={index}
                className="flex flex-col"
              >
                {Object.values(properties).map((property, key) => {
                  return (
                    <div
                      key={key}
                      className="flex flex-col lg:flex-row mb-8 lg:gap-3"
                    >
                      {/*<pre className="text-xs">{JSON.stringify(property, null, 2)}</pre>*/}
                      <div className="lg:w-3/12 mb-3 lg:mb-0">
                        <p className="text-sm text-gray-800 font-medium">{property[0]?.settingName}</p>
                      </div>
                      <div className="lg:w-9/12">
                        <ProperyBox
                          property={property}
                          setProperties={setPropertiesCallBack}
                        />
                      </div>
                    </div>
                  )
                })}
              </div>
            )
          })}

          <div className="flex w-full justify-end">
            <button
              className="btn lg"
              disabled={true}
              onClick={saveSettings}
            >
              Kaydet
            </button>
          </div>
        </>
      )}
    </>
  )
}

export default ReservationPolicy
