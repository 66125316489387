import React from "react"

const FontViewSettings = (props) => {
  let {
    row,
    config: { ThemeConfig, AllDisplays },
    setConfig,
  } = props

  return (
    <>
      <div className={!row ? "flex flex-col gap-3" : "grid grid-cols-1 lg:grid-cols-3 gap-6 mt-6 row-start-1 row-end-4"}>
        {AllDisplays &&
          ThemeConfig.displaySettings?.borderRadius &&
          AllDisplays.map((display, index) => {
            return (
              <label
                onClick={() => {
                  setConfig({
                    borderRadius: display.styles.borderRadius,
                    fontFamily: display.styles.fontFamily,
                    activeDisplay: display.label,
                  })
                }}
                htmlFor={`display-${index}`}
                key={index}
                className={`${
                  display.styles.borderRadius === ThemeConfig.displaySettings.borderRadius ? "border-blue-200 " : ""
                }radio-group bg-white flex items-center border border-gray-200 shadow-buttonSm rounded-lg py-[5px] px-3 transition cursor-pointer mb-2 last:mb-0`}
              >
                <div className="radio-area inline-flex items-center mr-2">
                  <input
                    id={`display-${index}`}
                    type="radio"
                    name={"radios"}
                    onChange={() => false}
                    checked={display.styles.borderRadius === ThemeConfig.displaySettings.borderRadius}
                  />
                </div>
                <p className="text-gray-800 font-medium text-sm mr-4">{display.label}</p>
                <div
                  className="font-preview-wrapper ml-auto w-[50px] h-10 flex items-center justify-center"
                  style={{ ...display.styles, backgroundColor: display.backgroundColor }}
                >
                  <p className="text-gray-900 font-bold">Aa</p>
                </div>
              </label>
            )
          })}
      </div>
    </>
  )
}

export default FontViewSettings
