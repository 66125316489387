import React from "react"
import { Button, Col, Row } from "rsuite"
import { useFormContext } from "react-hook-form"

/**
 * @typedef {Object} FormButtonAreaProps
 * @property {Function} onClick
 * @property {Function} onCancel
 * @property {boolean} isLoading
 * @property {boolean} isMainButtonDisabled
 *
 * @param {FormButtonAreaProps} props
 *
 * @returns
 */
const FormFooter = ({ onClick, onCancel, isMainButtonDisabled, isLoading }) => {
  const { watch } = useFormContext()
  const ruleId = watch("rule.Id")

  return (
    <Row className="py-10">
      <Col xs={24}>
        <div className="flex justify-end gap-2">
          <Button
            onClick={onCancel}
            className="!border border-solid !border-gray-500 bg-white !text-gray-500"
          >
            Vazgeç
          </Button>
          <Button
            appearance="primary"
            type="submit"
            className="btn"
            onClick={onClick}
            loading={isLoading}
            disabled={isMainButtonDisabled}
          >
            {ruleId ? "Güncelle" : "Oluştur"}
          </Button>
        </div>
      </Col>
    </Row>
  )
}

export default FormFooter
