import CommonAddLayout from "pages/Rules/components/FormLayouts/CommonAddLayout"
import React, { useEffect } from "react"
import RuleForm from "pages/Rules/components/FormLayouts/RuleForm"
import { useNavigate, useParams } from "react-router-dom"
import { useGetRuleById, useRuleTypeList } from "utils/useRules"
import { Placeholder } from "rsuite"

const EditRule = (props) => {
  const { id } = useParams()
  const { data, isLoading } = useGetRuleById(id)
  const { data: typeDataList, isLoading: typeDataIsLoading } = useRuleTypeList()
  const typeDataId = data?.data?.result?.rule?.type
  const typeData = typeDataList?.find((item) => item.id === typeDataId)
  const navigate = useNavigate()

  let ruleParameters = JSON.parse(data?.data?.result?.rule?.parameters || "{}")?.Rule || undefined
  const resultParameters = JSON.parse(data?.data?.result?.rule?.parameters || "{}")?.Result || undefined

  useEffect(() => {
    if (!isLoading && !data?.data?.result?.rule) {
      navigate("/rules")
    }
  }, [data?.data?.result?.rule, isLoading, navigate])

  return (
    <CommonAddLayout
      {...props}
      title={`${typeData?.name} Kuralı Düzenleyin` || ""}
      description="Ürününüzde çalıştırmak üzere sisteme rezervasyon kuralını düzenleyin."
      backButtonText="Önceki Sayfa"
      isLoading={isLoading}
    >
      {isLoading && (
        <Placeholder.Graph
          height="70px"
          className="rounded-lg"
        />
      )}

      {!isLoading && !typeDataIsLoading && (
        <RuleForm
          ruleTypeId={typeData?.id}
          successMessage={`${typeData?.name} kuralı başarıyla güncellendi.`}
          successDescription={`${typeData?.name} kuralı başarıyla güncellendi.`}
          editInitialValue={{
            defaultValues: {
              rule: { Id: id, ...ruleParameters },
              result: resultParameters,
            },
            shouldUnregister: true,
            mode: "onSubmit",
          }}
          id={id}
        />
      )}
    </CommonAddLayout>
  )
}

export default EditRule
