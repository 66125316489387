import { call, fork, put, takeLatest } from "redux-saga/effects"
import * as actions from "redux/actions/domainStateUptade"

import { DOMAIN_UPTADE_STATE_REQUESTED } from "redux/types"
import { API_POST } from "helpers/api"

function* watchUptadeDomainState() {
  yield takeLatest(DOMAIN_UPTADE_STATE_REQUESTED, UptadeDomainState)
}

function* UptadeDomainState(action) {
  try {
    const data = action.payload
    const res = yield call(apiUptadeDomainState, data)
    yield put(actions.domainStateUptadeSucceed(res?.data?.result))
  } catch (err) {
    yield put(actions.domainStateUptadeFailed())
  }
}

const apiUptadeDomainState = (data) => {
  if (Object.keys(data)?.length !== 0) {
    var formBody = []
    for (var property in data) {
      var encodedKey = encodeURIComponent(property)
      var encodedValue = encodeURIComponent(data[property])
      formBody.push(encodedKey + "=" + encodedValue)
    }
    formBody = formBody.join("&")

    return API_POST(
      `${process.env.REACT_APP_DISPLAY_DOMAIN}/api/domain/setdomainstate`,
      formBody,
      "application/x-www-form-urlencoded",
    )
  }

  return API_POST(`${process.env.REACT_APP_DISPLAY_DOMAIN}/api/domain/setdomainstate`, data)
}

export default function* domainStateUptade() {
  yield fork(watchUptadeDomainState)
}
