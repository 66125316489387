import { call, fork, put, takeLatest } from "redux-saga/effects"
import * as actions from "redux/actions/strategy"

import { STRATEGY_GET_REQUESTED, STRATEGY_POST_REQUESTED } from "redux/types"
import { API_GET, API_POST } from "helpers/api"

function* watchStrategy() {
  yield takeLatest(STRATEGY_GET_REQUESTED, getStrategy)
  yield takeLatest(STRATEGY_POST_REQUESTED, saveStrategy)
}

function* getStrategy(action) {
  try {
    const res = yield call(apiGetStrategy, action.payload)
    yield put(actions.getStrategySucceed(res?.data))
  } catch (err) {
    yield put(actions.getStrategyFailed())
  }
}
function* saveStrategy(action) {
  try {
    const res = yield call(apiSaveStrategy, action.payload)
    yield put(actions.saveStraregySucceed(res?.data))
  } catch (err) {
    yield put(actions.saveStraregyFailed())
  }
}

const apiGetStrategy = (payload) => {
  return API_GET(`${process.env.REACT_APP_DISPLAY_DOMAIN}/api/v1/strategydesign/get-page-components`, payload)
}
const apiSaveStrategy = (payload) => {
  return API_POST(`${process.env.REACT_APP_DISPLAY_DOMAIN}/api/v1/strategy/run`, payload)
}

export default function* strategy() {
  yield fork(watchStrategy)
}
