import { combineReducers } from "redux"
import contractsSet from "redux/reducers/contractsSet"
import getAllAccountList from "redux/reducers/getAllAccountList"
import getDepartmants from "redux/reducers/getDepartmants"
import getRoles from "redux/reducers/getRoles"
import profileImage from "redux/reducers/profileImage"
import associatedDomains from "redux/reducers/associatedDomains"
import getPropertiesWithUser from "redux/reducers/getPropertiesWithUser"
import clientConfiguration from "redux/reducers/clientConfiguration"
import clientConfigurationGet from "redux/reducers/clientConfigurationGet"
import myReservationsList from "redux/reducers/myReservationsList"
import myReservationsDetail from "redux/reducers/myReservationsDetail"
import myReservationsApprove from "redux/reducers/myReservationsApprove"
import myWorkAreaInfo from "redux/reducers/myWorkAreaInfo"
import destinations from "redux/reducers/destinations"
import accountManagement from "redux/reducers/accountManagement"
import getCustomDomainsNearExpiration from "redux/reducers/getCustomDomainsNearExpiration"
import refreshCustomDomainValidationToken from "redux/reducers/refreshCustomDomainValidationToken"
import getAgencyList from "redux/reducers/getAgencyList"
import getAgencyListForCorporate from "redux/reducers/getAgencyListForCorporate"
import getProductList from "redux/reducers/getProductList"
import getPageList from "redux/reducers/getPageList"
import pageManager from "redux/reducers/pageManager"
import mediaLibrary from "redux/reducers/mediaLibrary"
import clientUserRegister from "redux/reducers/clientUserRegister"
import clientUserUpdate from "redux/reducers/clientUserUpdate"
import clientUserGet from "redux/reducers/clientUserGet"
import strategy from "redux/reducers/strategy"
import strategyDesign from "redux/reducers/strategyDesign"
import domainStateGet from "redux/reducers/domainStateGet"
import domainStateUptade from "redux/reducers/domainStateUptade"
import footerUptade from "redux/reducers/footerUptade"
import getCountryCodes from "redux/reducers/getCountryCodes"
import pageSave from "redux/reducers/pageSave"
import pageUpdate from "redux/reducers/pageUpdate"
import pageDelete from "redux/reducers/pageDelete"
import pageState from "redux/reducers/pageState"
import pageClone from "redux/reducers/pageClone"
import pageSaveMain from "redux/reducers/pageSaveMain"
// B2C
import ListConfiguration from "pages/B2cDemoPage/redux/reducers/ListConfiguration"
import DisplayDevice from "pages/B2cDemoPage/redux/reducers/DisplayDevice"
import SaveConfiguration from "pages/B2cDemoPage/redux/reducers/SaveConfiguration"
import GetConfiguration from "pages/B2cDemoPage/redux/reducers/GetConfiguration"
import getThemeConfig from "pages/B2cDemoPage/redux/reducers/setThemeConfig"
import ViewportFullSize from "pages/B2cDemoPage/redux/reducers/ViewportFullSize"
import Addon from "redux/reducers/addon"
import AddonDetail from "redux/reducers/addonDetail"
import AddonPrice from "redux/reducers/addonPrice"
import AddonCategoryList from "redux/reducers/addonCategoryList"
import UserInfo from "redux/reducers/userInfo"
import AddonSave from "redux/reducers/addonSave"
import milesAndSmilesList from "redux/reducers/milesAndSmiles"
import saveUserSettings from "redux/reducers/saveUserSettings"
import getSettings from "redux/reducers/getSettings"
import properties from "redux/reducers/properties"
import definePropertyType from "./definePropertyType"
import define from "./define"
import getAgreement from "redux/reducers/getAgreement"
import saveAgreement from "redux/reducers/saveAgreement"


const rootReducer = combineReducers({
  strategy,
  contractsSet,
  getAllAccountList,
  getDepartmants,
  getRoles,
  profileImage,
  associatedDomains,
  getPropertiesWithUser,
  clientConfiguration,
  clientConfigurationGet,
  myReservationsList,
  myReservationsDetail,
  myReservationsApprove,
  myWorkAreaInfo,
  destinations,
  accountManagement,
  getCustomDomainsNearExpiration,
  refreshCustomDomainValidationToken,
  getProductList,
  getPageList,
  pageManager,
  getAgencyList,
  getAgencyListForCorporate,
  mediaLibrary,
  clientUserRegister,
  clientUserUpdate,
  clientUserGet,
  strategyDesign,
  domainStateGet,
  domainStateUptade,
  footerUptade,
  getCountryCodes,
  pageSave,
  pageUpdate,
  pageDelete,
  pageState,
  pageClone,
  pageSaveMain,
  // B2C
  ListConfiguration,
  DisplayDevice,
  SaveConfiguration,
  GetConfiguration,
  getThemeConfig,
  ViewportFullSize,
  Addon,
  AddonDetail,
  AddonPrice,
  AddonCategoryList,
  UserInfo,
  AddonSave,
  milesAndSmilesList,
  saveUserSettings,
  getSettings,
  properties,
  definePropertyType,
  define,
  getAgreement,
  saveAgreement,
})

export default rootReducer
