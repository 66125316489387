import { call, fork, put, takeLatest } from "redux-saga/effects"
import * as actions from "redux/actions/mediaLibrary"

import { MEDIA_LIBRARY_LIST_REQUESTED, MEDIA_LIBRARY_SAVE_REQUESTED, MEDIA_LIBRARY_DELETE_REQUESTED } from "redux/types"
import { API_GET, API_POST } from "helpers/api"

function* watchMediaList() {
  yield takeLatest(MEDIA_LIBRARY_LIST_REQUESTED, getMediaList)
  yield takeLatest(MEDIA_LIBRARY_SAVE_REQUESTED, mediaListSave)
  yield takeLatest(MEDIA_LIBRARY_DELETE_REQUESTED, mediaListDelete)
}

function* getMediaList(action) {
  try {
    const res = yield call(apiGetMediaLibraryList, action.payload)
    yield put(actions.mediaLibraryListSucceed(res?.data))
  } catch (err) {
    yield put(actions.mediaLibraryListFailed())
  }
}
function* mediaListSave(action) {
  try {
    const res = yield call(apiPostMediaLibrarySave, action.payload)
    yield put(actions.mediaLibrarySaveSucceed(res?.data))
  } catch (err) {
    yield put(actions.mediaLibrarySaveFailed())
  }
}
function* mediaListDelete(action) {
  try {
    const res = yield call(apiGetMediaLibraryDelete, action.payload)
    yield put(actions.mediaLibraryDeleteSucceed(res?.data))
  } catch (err) {
    yield put(actions.mediaLibraryDeleteFailed())
  }
}

const apiGetMediaLibraryList = (payload) => {
  return API_POST(`${process.env.REACT_APP_DISPLAY_DOMAIN}/api/v1/medialibrary/list`, payload)
}
export const apiPostMediaLibrarySave = (payload) => {
  return API_POST(`${process.env.REACT_APP_DISPLAY_DOMAIN}/api/medialibrary/save`, payload)
}
const apiGetMediaLibraryDelete = (payload) => {
  return API_GET(`${process.env.REACT_APP_DISPLAY_DOMAIN}/api/medialibrary/delete/${payload}`)
}

export default function* pageManager() {
  yield fork(watchMediaList)
}
