import { call, fork, put, takeLatest } from "redux-saga/effects"
import * as actions from "redux/actions/clientUserUpdate"

import { CLIENT_USER_UPDATE_REQUESTED } from "redux/types"
import { API_POST } from "helpers/api"

function* watchGetClientUserUpdate() {
  yield takeLatest(CLIENT_USER_UPDATE_REQUESTED, getClientUserUpdate)
}

function* getClientUserUpdate(action) {
  try {
    const res = yield call(apiGetClientUserUpdate, action.payload)
    yield put(actions.clientUserUpdateSucceed(res?.data))
  } catch (err) {
    yield put(actions.clientUserUpdateFailed())
  }
}

const apiGetClientUserUpdate = (payload) => {
  return API_POST(`${process.env.REACT_APP_AUTH_DOMAIN}/api/clientaccount/update`, payload, "application/json", false)
}

export default function* clientUserUpdateGet() {
  yield fork(watchGetClientUserUpdate)
}
