import React from "react"
import { Modal, Grid, Row, Col, Button } from "rsuite"
import { ReactComponent as SuccessIcon } from "assets/svg/success.svg"
import { RuleListButtons } from "pages/Rules/components/NoDataScreen"

/**
 * @typedef {object} SuccessModalProps
 * @property {boolean} nofiticationModalOpen
 * @property {function} setNofiticationModalOpen
 * @property {string} title
 * @property {string} description
 * @property {"stretch" | "wide"} size
 *
 * @param {SuccessModalProps} props
 * @returns
 */
const SuccessModal = ({ open, setOpen, title, description, size }) => {
  return (
    <Modal
      size={"lg"}
      open={open}
      onClose={() => setOpen(false)}
      keyboard={false}
      backdrop="static"
      className="!mt-0 absolute top-1/2 left-1/2 transform !-translate-x-1/2 !-translate-y-1/2 max-w-[740px]"
    >
      <Modal.Body>
        <SuccessIcon />
        <Grid fluid>
          <Row>
            <Col xs={24}>
              <p className="text-left text-xl font-semibold mt-4">{title}</p>
            </Col>
            <Col xs={24}>
              <p className="text-left text-gray-600 mt-2">{description}</p>
            </Col>
            <Col xs={24}>
              <RuleListButtons size={size} />
            </Col>
          </Row>
        </Grid>
      </Modal.Body>
      <Modal.Footer>
        <Grid fluid>
          <Row>
            <Col xs={12}>
              <Button
                className="w-full border border-solid border-gray-300 bg-white btn !text-gray-500"
                appearance="subtle"
                onClick={() => (window.location.href = "/")}
              >
                Ana Sayfaya Dön
              </Button>
            </Col>
            <Col xs={12}>
              <Button
                className="w-full btn"
                appearance="primary"
                onClick={() => (window.location.href = "/rules")}
              >
                Kural Listesine Dön
              </Button>
            </Col>
          </Row>
        </Grid>
      </Modal.Footer>
    </Modal>
  )
}

export default SuccessModal
