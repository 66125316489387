import React from "react"

// wrappers
import PartialWrapper from "../../../PartialWrapper"

// components
import { Subtitle, ToggleBox } from "components"

// utils

const MainMenu = (props) => {
  let { editBlock, setEditBlock } = props


  const ToggleTitles = [
    /*{text: "Display All", value: true},*/
    {text: "Hesabım", value: true},
    {text: "Yardım", value: true},
    {text: "Taleplerim", value: true},
    {text: "Para Birimi & Dil", value: true}
  ]

  return (
    <PartialWrapper
      className={`main-menu-layout${editBlock?.showMainMenuLayout ? " active" : ""}`}
      goBackOnClick={() => setEditBlock(null)}
    >
      <Subtitle>Main Menu</Subtitle>

      <div className="input-wrapper mt-4">
        <div className="flex flex-col gap-2">
          {ToggleTitles.map((item, key) => {
            return (
              <ToggleBox
                key={key}
                title={item?.text}
                checked={item.value}
                onToggle={(e) => {
                  console.log(item?.text)
                  }
                }
              />
            )
          })}
        </div>
      </div>
    </PartialWrapper>
  )
}

export default MainMenu
