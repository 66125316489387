import React, { useState } from "react"

// layouts
import PartialWrapper from "../../../../PartialWrapper"

// components
import { ColorBox, Subtitle, ToggleBox } from "components"

const ContentOverlayLayout = (props) => {
  const { showSubLayout, setShowSubLayout } = props

  const [ContentOverlayColor, setContentOverlayColor] = useState("#000000")

  const changeContentOverlayColor = (event) => {
    setContentOverlayColor(event.target.value)
  }

  return (
    <PartialWrapper
      className={`content-overlay-layout${showSubLayout?.contentOverlayLayout ? " active" : ""}`}
      goBackOnClick={() => setShowSubLayout(null)}
    >
      <Subtitle>İçerik Kaplaması</Subtitle>

      <div className="flex flex-col gap-4 mt-4">
        <div className="input-wrapper">
          <ToggleBox
            title={"Display Overlay"}
            defaultChecked={true}
            onToggle={(e) => console.log(e)}
            //wrapperClassName={""} optional
            //toggleClassName={""} optional
            //textClassName={""} optional
          />
        </div>
        <div className="input-wrapper">
          <ColorBox
            title={"Renk"}
            value={ContentOverlayColor}
            onChange={changeContentOverlayColor}
          />
        </div>
      </div>
    </PartialWrapper>
  )
}

export default ContentOverlayLayout
