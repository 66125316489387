import React, { useEffect, useState } from "react"

import { getPropertyValueByName } from "utils/propertyUtils"

const ContactContent = ({ setSelectedContent, getSettingsData }) => {
  const [contactValues, setContactValues] = useState([])

  const hasContactInfo = [
    "Telefon",
    "Fax",
    "Çağrı Merkezi",
    "Çağrı Merkezi Bilgilendirme",
    "Adres",
    "İlçe",
    "Şehir",
    "Ülke",
  ].some((same) => getPropertyValueByName(contactValues, same))

  const phone = getPropertyValueByName(contactValues, "Telefon")
  const fax = getPropertyValueByName(contactValues, "Fax")
  const email = getPropertyValueByName(contactValues, "Email")
  const call_center = getPropertyValueByName(contactValues, "Çağrı Merkezi")
  const call_center_info = getPropertyValueByName(contactValues, "Çağrı Merkezi Bilgilendirme")
  const address = getPropertyValueByName(contactValues, "Adres")
  const district = getPropertyValueByName(contactValues, "İlçe")
  const city = getPropertyValueByName(contactValues, "Şehir")
  const country = getPropertyValueByName(contactValues, "Ülke")

  const combinedValues = [district, city, country].filter((value) => value !== "")

  useEffect(() => {
    if (getSettingsData) {
      const filteredData = getSettingsData.Result.filter((item) => item.SettingName === "İletişim Bilgileri")
      setContactValues(filteredData)
    }
  }, [getSettingsData])

  return (
    <div className="bg-white border border-gray-200 rounded-lg p-8 mt-6">
      <div className="flex items-center justify-between">
        <h3 className="text-gray-800 text-lg font-semibold">
          <span className="text-blue-500">*</span>İletişim Bilgileri
        </h3>
        <button
          className="text-blue-500 font-medium"
          onClick={() => setSelectedContent("İletişim Bilgileri")}
        >
          {hasContactInfo ? "Düzenle" : "İletişim Bilgilerini Ekle"}
        </button>
      </div>
      {hasContactInfo ? (
        <div className="mt-4 flex gap-6">
          <img
            className="h-[292px]"
            src="assets/img/map.png"
            alt=""
          />
          <div className="flex flex-col gap-3 w-full">
            <div className="flex w-full gap-4">
              <div className="flex flex-col w-1/2">
                <span className="text-sm font-medium">Telefon</span>
                <span className="text-gray-500 line-clamp-2">{phone ? phone : "Telefon alanı doldurulmadı"}</span>
              </div>
              <div className="flex flex-col w-1/2">
                <span className="text-sm font-medium">Faks</span>
                <span className="text-gray-500 line-clamp-2">{fax ? fax : "Faks alanı doldurulmadı"}</span>
              </div>
            </div>
            <div className="flex w-full gap-4">
              <div className="flex flex-col w-1/2">
                <span className="text-sm font-medium">Çağrı Merkezi</span>
                <span className="text-gray-500 line-clamp-2">
                  {call_center ? call_center : "Çağrı Merkezi alanı henüz doldurulmadı"}
                </span>
              </div>
              <div className="flex flex-col w-1/2">
                <span className="text-sm font-medium">E-posta</span>
                <span className="text-gray-500 line-clamp-2">{email ? email : "E-posta alanı doldurulmadı"}</span>
              </div>
            </div>
            <div className="flex flex-col">
              <span className="text-sm font-medium">Çağrı Merkezi Bilgilendirme</span>
              <span className="text-gray-500 line-clamp-2">
                {call_center_info ? call_center_info : "Çağrı Merkezi Bilgilendirme alanı henüz doldurulmadı"}
              </span>
            </div>
            <div className="flex flex-col">
              <span className="text-sm font-medium">Adres</span>
              <span className="text-gray-500 line-clamp-2">
                <span className="text-gray-500 line-clamp-2">
                  {address || combinedValues.length !== 0
                    ? `${address}${address && combinedValues.length ? ", " : ""}${combinedValues.join(" / ")}`
                    : "Adres alanı henüz doldurulmadı"}
                </span>
              </span>
            </div>
          </div>
        </div>
      ) : (
        <p className={"text-gray-500 mt-4"}>İşletmenize ait iletişim bilgileri ekleyin.</p>
      )}
    </div>
  )
}

export default ContactContent
