import React from "react"
import PropTypes from "prop-types"

const TextInput = (props) => {
  let { title, placeholder, defaultValue, value, name, onChange, inputClassName, textClassName, wrapperClassName, disabled } =
    props
  return (
    <label className={wrapperClassName}>
      <span className={textClassName}>{title}</span>
      <input
        className={inputClassName}
        type="text"
        disabled={disabled}
        name={name}
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
      />
    </label>
  )
}

export default TextInput

TextInput.prototypes = {
  title: PropTypes.string.isRequired,
  inputClassName: PropTypes.string,
  textClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
}

TextInput.defaultProps = {
  title: "Label",
  inputClassName: "w-full mt-2",
  textClassName: "text-gray-800 font-medium",
  wrapperClassName: "",
  name: "text",
  placeholder: "",
  defaultValue: undefined,
  value: undefined,
  disabled: false,
  //onChange: (e) => console.log(e),
}
