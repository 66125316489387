export const getUrl = (data) => {
  if (typeof window !== "undefined") {
    // todo: openID sorunu cozulene kadar gecici cozum
    if (data.includes("wl-auth")){
      return data
    }

    let host = window.location.host
    let url = data
    if (host && host.split(".")[0].endsWith("-stg")) {
      let array = url.split(".")
      array[0] = array[0] + "-stg"

      var resultUrl = array.join(".")
      var variable = "-stg-stg"
      if(resultUrl.includes(variable)){
        return resultUrl.replace(variable, "-stg")
      }
      return resultUrl
    }
    return url
  }
}
