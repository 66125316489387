import { Col, Row, Placeholder, Checkbox } from "rsuite"
import { Card } from "components"
import { Fragment, useContext, useState } from "react"
import InputWrapper from "../FormItems/InputWrapper"
import RuleForm, { RuleFormContext } from "./RuleForm"
import { useRuleMetaDataByRuleTypeId } from "utils/useRules"
import { Controller, useFormContext } from "react-hook-form"
import DynamicFormItemsManager from "../DynamicFormItems/DynamicFormItemsManager"
import ConditionItemSerializer from "../SerializerFunctions/ConditionItemSerializer"
import ParameterSerializer from "../SerializerFunctions/ParameterSerializer"
import NewAreaButton from "../NewAreaButton"
import { FormHiddenInput } from "../FormItems"

/**
 * @typedef {object} props
 * @property {boolean} isEditing
 * @property {boolean} defaultOpen
 *
 * @param {props} props
 * @returns
 */
const ResultFormItemsCard = ({ isEditing = false, defaultOpen = false }) => {
  const { ruleTypeId } = useContext(RuleFormContext)
  const { resultItems, isLoading } = useRuleMetaDataByRuleTypeId(ruleTypeId)
  const conditionSelectItems = ConditionItemSerializer(resultItems?.Metadatas)
  const [limitVisible, setLimitVisible] = useState(defaultOpen || false)

  const {
    register,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useFormContext()

  return (
    <>
      <Card
        size="sm"
        className={`${limitVisible ? "block" : "hidden"}`}
      >
        <Row>
          <Col
            xs={24}
            className="mb-2"
          >
            <div className="text-gray-800 font-medium text-lg flex justify-between">
              <span>
                Sonuç&nbsp;
                {RuleForm.PageMeta.budgetApprove.ruleTypeId !== ruleTypeId && (
                  <span className="text-gray-400 font-normal text-sm">OPSİYONEL</span>
                )}
              </span>
              {/* 
              This filter is a spesific field. If you want to learn more information, you can contact with PM's.
              This parameter couldn't be support by backend.
            */}
              {ruleTypeId === RuleForm.PageMeta.reservationRule.ruleTypeId && (
                <span>
                  <Controller
                    name={`result.reservationWithoutApproval`}
                    control={control}
                    value
                    render={({ field }) => (
                      <Checkbox
                        name={field.name}
                        checked={field.value}
                        onChange={(_, checked) => {
                          field.onChange(checked)
                        }}
                        className="text-xs"
                      >
                        Onay Gerektirmeden Rezervasyon Yapılabilsin
                      </Checkbox>
                    )}
                  />
                </span>
              )}
            </div>
          </Col>
        </Row>

        {isLoading && (
          <Placeholder.Graph
            height="70px"
            className="rounded-lg"
          />
        )}

        {!isLoading && (
          <InputWrapper onRemove={RuleForm.PageMeta.budgetApprove.ruleTypeId !== ruleTypeId && (() => setLimitVisible(false))}>
            {resultItems?.Metadatas &&
              Object.entries(resultItems?.Metadatas).map(([key], index) => {
                const parameters = ParameterSerializer(conditionSelectItems.find((item) => item[0] === key))
                return (
                  <Fragment key={`${key}_${index}`}>
                    {(parameters?.length || 0) > 0 &&
                      parameters
                        .filter((item) => item.Title !== RuleForm.PageMeta.reservationRule.spesificCondition_1)
                        .map((item, subIndex) => {
                          const formPath = `result.children[${index}].children[${index}]`
                          let name = `${formPath}.${item?.Type || "dynamicInput-0"}__${index}_${subIndex}`
                          let hiddenName = ""

                          if (getValues(`${formPath}.conditionSelect`) !== key) {
                            register(`${formPath}.conditionSelect`, {
                              value: key,
                            })
                          }

                          if (!isEditing) {
                            if (item?.Type === "ReadonlyParameter") {
                              register(`${formPath}.ReadonlyParameter__${index}_${subIndex}`, {
                                value: item?.ReadonlyValue,
                              })
                            } else {
                              register(name, {
                                value:
                                  getValues(name) ||
                                  // Spesiific field comparison. If you want to learn more information, you can contact with Author.
                                  item.Type === "BooleanListParameter"
                                    ? (item?.Title || item?.Description).includes(
                                        RuleForm.PageMeta.reservationRule.spesificCondition_2,
                                      )
                                      ? "false"
                                      : ""
                                    : "",
                              })
                            }
                          }

                          if (item?.Type !== "ReadonlyParameter") {
                            hiddenName = `${formPath}.${item?.Title || item?.Description}_${item?.Type}_Text`
                            register(hiddenName, {
                              value:
                                getValues(hiddenName) ||
                                getValues(name) ||
                                // Spesiific field comparison. If you want to learn more information, you can contact with Author.
                                item.Type === "BooleanListParameter"
                                  ? (item?.Title || item?.Description).includes(
                                      RuleForm.PageMeta.reservationRule.spesificCondition_2,
                                    )
                                    ? "Hayır"
                                    : ""
                                  : "",
                            })
                          }

                          const watchedValue = watch(`${formPath}`)

                          const isRequired =
                            !Object.entries(watchedValue || {})
                              ?.map(([key, value]) => {
                                return key.includes("NumericParameter") && !!value
                              })
                              .find((item) => item) && RuleForm.PageMeta.budgetApprove.ruleTypeId === ruleTypeId

                          return (
                            <div key={`${subIndex}-${index}-${key}-${item?.Type}`}>
                              {item?.Type !== "ReadonlyParameter" ? (
                                <>
                                  <Col
                                    xs={8}
                                    className="flex items-center justify-start"
                                  >
                                    {item?.Title || item?.Description}
                                  </Col>
                                  <Col
                                    xs={16}
                                    className="mb-2"
                                  >
                                    <DynamicFormItemsManager
                                      conditionName={key}
                                      item={item}
                                      formPath={formPath}
                                      errorMessage={!!errors?.result?.children ? "Lütfen en az bir alanı doldurunuz" : null}
                                      name={name}
                                      hiddenName={hiddenName}
                                      required={isRequired}
                                    />
                                  </Col>
                                </>
                              ) : (
                                <FormHiddenInput name={`${formPath}.ReadonlyParameter__${index}_${subIndex}`} />
                              )}
                            </div>
                          )
                        })}
                  </Fragment>
                )
              })}
          </InputWrapper>
        )}
      </Card>
      <Row className={`${limitVisible ? "hidden" : "block"}`}>
        <Col xs={24}>
          <NewAreaButton
            text="Limit Ekle"
            onClick={() => setLimitVisible(true)}
          />
        </Col>
      </Row>
    </>
  )
}

export default ResultFormItemsCard
