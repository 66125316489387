import { all, call, fork, put, takeLatest } from "redux-saga/effects"
import * as actions from "redux/actions/destinations"

import { CITIES_REQUESTED, COUNTRIES_REQUESTED, DISTRICTS_REQUESTED } from "redux/types"
import { API_GET } from "helpers/api"

function* watchGetCountries() {
  yield takeLatest(COUNTRIES_REQUESTED, getCountries)
}
function* watchGetCities() {
  yield takeLatest(CITIES_REQUESTED, getCities)
}
function* watchGetDistricts() {
  yield takeLatest(DISTRICTS_REQUESTED, getDistricts)
}

function* getCountries() {
  try {
    const res = yield call(apiGetCountries)
    let countries = res?.data?.result.reduce((acc, value) => {
      acc.push({
        label: value.name,
        value: value.id,
      })
      return acc
    }, [])

    yield put(actions.countriesSucceed(JSON.stringify(countries)))
  } catch (err) {
    yield put(actions.countriesFailed())
  }
}
function* getCities(action) {
  try {
    const res = yield call(apiGetCities, action.payload)
    let countries = res?.data?.result.reduce((acc, value) => {
      acc.push({
        label: value.name,
        value: value.id,
      })
      return acc
    }, [])
    yield put(actions.citiesSucceed(JSON.stringify(countries)))
  } catch (err) {
    yield put(actions.citiesFailed())
  }
}

function* getDistricts(action) {
  try {
    const res = yield call(apiGetDistricts, action.payload)
    let districts = res?.data?.result.reduce((acc, value) => {
      acc.push({
        label: value.name,
        value: value.id,
      })
      return acc
    }, [])
    yield put(actions.districtsSucceed(JSON.stringify(districts)))
  } catch (err) {
    yield put(actions.districtsFailed())
  }
}

const apiGetCountries = () => API_GET(`${process.env.REACT_APP_BOOK_DOMAIN}/api/paneldestination/countries`)

const apiGetCities = (countryId) => API_GET(`${process.env.REACT_APP_BOOK_DOMAIN}/api/paneldestination/cities/${countryId}`)

const apiGetDistricts = (cityId) => API_GET(`${process.env.REACT_APP_BOOK_DOMAIN}/api/paneldestination/districts/${cityId}`)

export default function* destinations() {
  yield all([fork(watchGetCountries), fork(watchGetCities), fork(watchGetDistricts)])
}
