import React, { useEffect, useState } from "react"
import { BorderBox } from "components"
import { useNavigate } from "react-router"
import GoogleAnalyticsEdit from "./GoogleAnalyticsEdit"
import Icon from "components/Icon"
import Header from "components/Headers/MainHeader"
import { useDomainAddonList } from "services/wl-display/domainAddon/domainAddonList"
import { useDomainAddonSettingList } from "services/wl-display/domainAddon/domainAddonSettingList"
import { useAddonSettingList } from "services/wl-display/domainAddon/addonSettingList"

const GoogleAnalytics = (props) => {
  let { isLogged } = props
  const navigate = useNavigate()

  const [isEdit, setIsEdit] = useState(false)
  const [addonId, setAddonId] = useState(null)
  const [domainAddonId, setDomainAddonId] = useState(null)
  const [manualLoading, setManualLoading] = useState(false)

  const { data: domainAddonListData, isLoading: domainAddonSettingListLoading } = useDomainAddonList()
  const {
    data: domainAddonSettingListData,
    isLoading: domainAddonSettingLoading,
    refetch,
  } = useDomainAddonSettingList(addonId, !!addonId)
  const { data: addonSettingData, isLoading: addonSettingLoading } = useAddonSettingList(addonId, !!addonId)

  const findAddonIdByProductTypeCode = (data, code) => {
    const item = data.find(
      (d) => d.Addon && d.Addon.AddonAddonProductTypes.some((apt) => apt.AddonProductType && apt.AddonProductType.Code === code),
    )
    setDomainAddonId(item ? item.Id : null)
    return item ? item.AddonId : null
  }

  useEffect(() => {
    if (!domainAddonSettingListLoading) {
      setAddonId(findAddonIdByProductTypeCode(domainAddonListData?.data, 1))
    }
  }, [domainAddonListData, domainAddonSettingListLoading])

  useEffect(() => {
    if (domainAddonSettingListData) {
      setManualLoading(false)
    }
  }, [domainAddonSettingListData])

  const handleOnNavigate = (path = "") => navigate("/settings/" + path)

  const handleSaveCallback = () => {
    setManualLoading(true)
    refetch()
  }

  const isLoadingAddonData = domainAddonSettingListLoading || manualLoading || addonSettingLoading || domainAddonSettingLoading

  return (
    <>
      <Header isLogged={isLogged} />
      <main>
        <section className="p-10 my-0 mx-auto max-w-[1080px]">
          <span
            className="!text-gray-600 flex justify-start items-center gap-2 mt-2 mb-8 cursor-pointer w-fit transition-all hover:drop-shadow"
            onClick={() => handleOnNavigate()}
          >
            <Icon
              name="arrow-left"
              size="20"
              className="!bg-gray-500"
            />
            <h4>Ayarlara Dön</h4>
          </span>
          <h1 className="text-gray-800 text-3xl font-semibold mb-6">Google Analytics</h1>
          {isEdit ? (
            <GoogleAnalyticsEdit
              setIsEdit={setIsEdit}
              domainAddonId={domainAddonId}
              domainAddonSettingListData={domainAddonSettingListData?.data?.[0]}
              domainAddonListData={domainAddonListData?.data}
              addonSettingData={addonSettingData?.data?.[0]}
              onSave={handleSaveCallback}
              isSave={!domainAddonSettingListData?.data?.[0]?.PropertyValue?.Value}
            />
          ) : (
            <BorderBox className="p-6">
              <div className="flex justify-between items-center">
                <div className="flex flex-col gap-2">
                  <span className="text-gray-800 text-lg font-semibold">Tracking ID</span>
                  {isLoadingAddonData ? (
                    <span className="text-sm text-gray-500">Yükleniyor...</span>
                  ) : (
                    <span>{`${domainAddonSettingListData?.data?.[0]?.PropertyValue?.Value ?? "-"}`}</span>
                  )}
                </div>

                <button
                  className="text-blue-500 font-medium ml-auto"
                  onClick={() => setIsEdit(true)}
                >
                  Düzenle
                </button>
              </div>
            </BorderBox>
          )}
        </section>
      </main>
    </>
  )
}

export default GoogleAnalytics
