import * as type from "../types"

const initialState = {
  value: {},
}

const getThemeConfig = (state = initialState, action) => {
  switch (action.type) {
    case type.SET_THEME_CONFIG:
      return {
        ...state,
        value: action.payload,
      }
    default:
      return state
  }
}

export default getThemeConfig
