import { call, fork, put, takeLatest } from "redux-saga/effects"
import * as actions from "redux/actions/saveUserSettings"

import { SAVE_USER_SETTINGS_REQUESTED } from "redux/types"
import { API_POST } from "helpers/api"

function* watchGetSaveUserSettings() {
  yield takeLatest(SAVE_USER_SETTINGS_REQUESTED, getSaveUserSettings)
}

function* getSaveUserSettings(action) {
  try {
    const res = yield call(apiGetSaveUserSettings, action.payload)
    yield put(actions.saveUserSettingsSucceed(res?.data))
  } catch (err) {
    yield put(actions.saveUserSettingsFailed())
  }
}

const apiGetSaveUserSettings = (payload) => {
  return API_POST(`${process.env.REACT_APP_PANEL_DOMAIN}/api/setting/saveusersettings`, payload)
}

export default function* saveUserSettings() {
  yield fork(watchGetSaveUserSettings)
}
