import React from "react"
import { Link } from "react-router-dom"

// components
import MainHeader from "components/Headers/MainHeader"
import Icon from "components/Icon"
import BackgroundIcons from "components/BackgroundIcons"

// images
import preview from "assets/img/b2c/setup/preview.png"

const B2cDemo = (props) => {
  let { isLogged } = props
  return (
    <>
      <MainHeader
        isLogged={isLogged}
        className={"!absolute"}
      />

      <section className="register-wrapper flex items-center justify-center relative overflow-hidden min-h-screen">
        {/*background blur icons*/}
        <BackgroundIcons />

        <div className="container">
          <div className="flex flex-col lg:flex-row items-center justify-between gap-4">
            <div className="left-box w-full md:w-[48%] hidden lg:block">
              <p className="text-gray-800 text-5xl font-bold">Hemen online rezervasyon almaya başlayın.</p>
              <p className="text-2xl font-normal text-gray-500 mt-6">
                Eva ile otelinizin websitesine rezervasyon alma yeteneği kazandırarak satış oranlarınızı artırın.
              </p>
              <Link to="/theme-settings/configuration">
                <button className="btn px-[42px] mt-8">
                  <span>Hemen Dene</span>
                  <Icon
                    name={"arrow-right"}
                    size="20"
                    color="#fff"
                  />
                </button>
              </Link>
            </div>

            {/*Login box*/}
            <div className="login-box w-full lg:w-[52%] hidden lg:block">
              <img
                src={preview}
                alt="preview"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default B2cDemo
