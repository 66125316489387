import React, { useState } from "react"
import { NavLink } from "react-router-dom"

//layouts
import BlockWrapper from "../../BlockWrapper"
import HeaderSettings from "./Settings"
import { MainMenu, Navigation } from "./SubMenus"

//components
import { Icon, TabList, SideMenuListItem } from "components"

// utils
import { isProdEnv } from "utils/environmentsCheck"
import getSelectedIndex from "utils/getSelectedIndex"
import {TabListTabLabels} from "../../../Enums";

const Header = (props) => {
  let { show, activeLayout, setEditLayouts } = props

  const [EditBlock, setEditBlock] = useState(null)
  const [ComponentDisplayTab, setComponentDisplayTab] = useState(0)

  // Func
  const editComponentDisplayTabChanged = (e) => {
    let selectedIndex = getSelectedIndex(e)
    setComponentDisplayTab(selectedIndex)
  }
  return (
    <BlockWrapper
      show={show}
      activeLayout={activeLayout}
      setEditLayouts={setEditLayouts}
      editBlock={EditBlock}
      setEditBlock={setEditBlock}
      doneBtnOnClick={() => {
        setEditLayouts(null)
      }}
    >
      <TabList
        onChange={editComponentDisplayTabChanged}
        tabs={[
          { label: TabListTabLabels.content, icon: "list", disabled: false },
          { label: TabListTabLabels.design, icon: "palette-solid", disabled: isProdEnv },
          { label: TabListTabLabels.publish, icon: "share", disabled: true },
        ]}
        tabClassName="w-full flex items-center gap-2.5 text-center text-gray-600 text-xs p-2 border-r last:border-r-0 hover:bg-gray-50 transition duration-400"
        wrapperClassName="flex shrink-0 border rounded-lg border-gray-200 overflow-hidden mb-7"
        //itemActiveClass="!text-gray-800 !bg-blue-50"
        activeTab={ComponentDisplayTab}
      />

      {ComponentDisplayTab === 0 && (
        <>
          <div className="logo-box mb-7">
            <p className="text-sm font-medium mb-4">Logo</p>
            <div className="flex items-center text-blue-500 text-xs font-medium py-0.5 px-2 bg-blue-50 rounded-lg border border-gray-100 gap-1">
              <Icon
                name="info"
                color="#2E90FA"
                size={14}
              />
              <p>Logo yüklemek için tema ayarlarını kullanabilirsiniz.</p>
            </div>

            <NavLink
              as={"button"}
              className="btn secondary xs w-full py-2 !h-auto border-b hover:bg-gray-100 mt-4"
              to={`/theme-settings/configuration`}
            >
              Go to Theme Settings
            </NavLink>
          </div>

          <div className="border-case flex flex-col border border-gray-200 rounded-lg">
            <SideMenuListItem
              className="border-t first:border-t-0"
              onClick={() =>
                setEditBlock({
                  showMainMenuLayout: true,
                })
              }
              title="Main Menu"
            />
            <SideMenuListItem
              className="border-t first:border-t-0"
              onClick={() =>
                setEditBlock({
                  showNavigationLayout: true,
                })
              }
              title="Navigation (Your Products)"
            />
          </div>
        </>
      )}
      {ComponentDisplayTab === 1 && <HeaderSettings />}

      {/* region sub menus*/}
      <MainMenu
        editBlock={EditBlock}
        setEditBlock={setEditBlock}
      />

      <Navigation
        editBlock={EditBlock}
        setEditBlock={setEditBlock}
      />

      {/* endregion sub menus*/}
    </BlockWrapper>
  )
}

export default Header
