import React, { Fragment, useContext, useEffect, useMemo, useState } from "react"
import { useFormContext } from "react-hook-form"
import { Col, Row, Placeholder } from "rsuite"
import { RuleFormContext } from "./RuleForm"
import { useRuleMetaDataByRuleTypeId } from "utils/useRules"
import InputWrapper from "../FormItems/InputWrapper"
import { RuleConditionSelect } from "./RuleConditionSelect"
import DynamicFormItemsManager from "../DynamicFormItems/DynamicFormItemsManager"
import ConditionItemSerializer from "../SerializerFunctions/ConditionItemSerializer"
import ParameterSerializer from "../SerializerFunctions/ParameterSerializer"

const RulesCardInputs = ({ onRemove, treeElementVisible, index, mainIndex, isMiddleItem }) => {
  const formPath = `rule.children[${mainIndex}].children[${index}]`
  const { ruleTypeId } = useContext(RuleFormContext)
  const { ruleItems, isLoading } = useRuleMetaDataByRuleTypeId(ruleTypeId)
  const conditionSelectItems = useMemo(() => ConditionItemSerializer(ruleItems?.Metadatas), [ruleItems?.Metadatas])
  const [parameters, setParameters] = useState([])
  const {
    watch,
    formState: { errors },
    getValues,
    unregister,
    setValue,
  } = useFormContext()

  const conditionSelectValue = watch(`${formPath}.conditionSelect`, null)
  // Get parameters by selected condition.

  useEffect(() => {
    setParameters(ParameterSerializer(conditionSelectItems.find((item) => item[0] === conditionSelectValue)) || [])
  }, [conditionSelectValue, conditionSelectItems, unregister, formPath, index])

  return (
    <Col
      xs={24}
      className={treeElementVisible ? "condition-card-input-content" : ""}
    >
      {isLoading && (
        <Placeholder.Graph
          height="70px"
          className="rounded-lg"
        />
      )}
      {!isLoading && (
        <InputWrapper onRemove={onRemove}>
          <Row>
            <Col xs={8}>
              <RuleConditionSelect
                mainIndex={mainIndex}
                index={index}
              />
            </Col>
            {(parameters?.length || 0) > 0 &&
              parameters
                ?.map((item, subIndex) => {
                  // Calculate col size is calculated by dividing 24-8(8 is previous container grids value) to parameter length.
                  const calculatedColSize = Math.floor(16 / parameters.filter((item) => item.Type !== "ReadonlyParameter").length)
                  const errorMessage =
                    errors?.rule?.children?.[mainIndex]?.children?.[index]?.[item?.Type || "dynamicInput-" + index]?.errorMessage
                  if (item.Type === "ReadonlyParameter") {
                    setValue(`${formPath}.ReadonlyParameter__${index}__${subIndex}`, item.ReadonlyValue)
                  }
                  return (
                    <Fragment key={subIndex}>
                      {item.Type !== "ReadonlyParameter" && (
                        <Col xs={calculatedColSize}>
                          <DynamicFormItemsManager
                            item={item}
                            errorMessage={errorMessage}
                            name={`${formPath}.${item?.Type || "dynamicInput-" + index}`}
                            formPath={formPath}
                            conditionName={getValues(`${formPath}.conditionSelect`)}
                          />
                        </Col>
                      )}
                    </Fragment>
                  )
                })
                .reverse()}
          </Row>
        </InputWrapper>
      )}
      <span
        className={`${formPath}-conditionSelect-verticalLine`}
        style={{
          height: index === 0 ? "calc(50% + 8px)" : isMiddleItem ? "calc(100% + 8px)" : "calc(50% - 8px)",
          width: "1px",
          display: treeElementVisible ? "block" : "none",
          position: "absolute",
          border: "1px solid #e2e2e2",
          top: index === 0 ? "calc(50% - 8px)" : isMiddleItem ? "0" : "0px",
          margin: "8px 0px",
          left: "-24px",
        }}
      ></span>
    </Col>
  )
}

export default RulesCardInputs
