/**
 * Verilen bir dizideki nesneyi özellik ismine göre bulur.
 * @param {Array} data - Arama yapılacak nesne dizisi.
 * @param {string} propertyName - Eşleştirilecek özellik ismi.
 * @returns {Object|null} - İlk eşleşen nesne, bulunamazsa null döner.
 */

const findObjectByPropertyName = (data, propertyName) => {
  if (!Array.isArray(data)) {
    throw new Error("data bir dizi olmalıdır!")
  }

  return data?.find((obj) => obj.PropertyName === propertyName) || null
}

const findObjectBySettingName = (data, propertyName) => {
  if (!Array.isArray(data)) {
    throw new Error("data bir dizi olmalıdır!")
  }

  return data?.find((obj) => obj.SettingName === propertyName) || null
}

/**
 * İsim özelliğine göre bir özelliğin değerini döndürür.
 * @param {Array} data - Arama yapılacak nesne dizisi.
 * @param {string} propertyName - Değeri alınacak özelliğin ismi.
 * @returns {*} - Özelliğin değeri, bulunamazsa null döner.
 */

export const getPropertyValueByName = (data, propertyName) => {
  const obj = findObjectByPropertyName(data, propertyName)
  return obj ? obj.PropertyValue : null
}

export const getPropertyById = (data, propertyName) => {
  const obj = findObjectByPropertyName(data, propertyName)
  return obj ? obj.PropertyId : null
}

export const getSettingById = (data, settingName) => {
  const obj = findObjectBySettingName(data, settingName)
  return obj ? obj.PropertyId : null
}
