import React from "react"
import PropTypes from "prop-types"

// components
import { Slider } from "rsuite"

const RangeInput = (props) => {
  let { className, title, onChange, rangeLabel, defaultRangeValue, rangeValue, min, max } = props

  return (
    <div className={`border border-gray-200 p-3 rounded-lg ${className}`}>
      {title && (
        <>
          <span className="text-sm">{title}</span>
          <hr className="my-4" />
        </>
      )}
      <div className="range-input">
        <div className="range-title flex justify-between text-sm mb-2">
          <span>{rangeLabel}</span>
          <span className="text-blue-500 font-medium">{rangeValue}</span>
        </div>

        <Slider
          progress
          defaultValue={defaultRangeValue}
          value={rangeValue}
          tooltip={false}
          min={min}
          max={max}
          onChange={(value) => {
            onChange(value)
          }}
        />

        <div className="range-indicators flex justify-between text-sm font-medium text-gray-500 mt-1">
          <span className="min">{min}</span>
          <span className="max">{max}</span>
        </div>
      </div>
    </div>
  )
}

export default RangeInput

RangeInput.prototypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  rangeLabel: PropTypes.string,
  defaultRangeValue: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func,
}

RangeInput.defaultProps = {
  //title: "Font",
  className: "",
  rangeLabel: "Size (px)",
  defaultRangeValue: undefined,
  rangeValue: undefined,
  min: 0,
  max: 100,
  //onChange: (e) => console.log(e),
}
