import { Icon } from "components"
import { evaGlobalImageUrl } from "constants/index"

const Mobile = (props) => {
  let { logo, color, borderRadius, fontFamily } = props

  return (
    <>
      <div
        className="flex flex-col bg-white relative"
        style={{ fontFamily: fontFamily }}
      >
        <div className="w-full bg-white container mx-auto py-4 flex flex-row justify-center">
          <img
            className="h-6"
            alt="logo"
            src={logo}
          />
        </div>
        <div className="w-full flex justify-center items-center py-6 bg-[#FAFAFA] border-2 border-solid border-gray-200">
          <div className="flex flex-row container mx-auto justify-center">
            <div
              className="bg-white flex flex-row border px-6 py-1 border-solid border-gray-200"
              style={{ borderRadius: borderRadius }}
            >
              <p className="text-sm border-r border-solid border-gray-200 px-4">25 Temmuz - 1 Ağustos</p>
              <p className="text-sm px-4">1 oda, 2 yetişkin</p>
            </div>
            <div
              className="w-[32px] h-[52px] ml-2 flex justify-center items-center"
              style={{ backgroundColor: color, borderRadius: borderRadius }}
            >
              <Icon
                name="search"
                color="#FFF"
                size="15"
              />
            </div>
          </div>
        </div>

        <div className="bg-white container mx-auto pt-6 mb-4 w-full flex flex-row justify-between">
          <div
            className="bg-[#FAFAFA] w-full flex flex-row justify-between p-2 h-[34px] items-center border border-solid border-gray-200"
            style={{ borderRadius: borderRadius }}
          >
            <p className="text-sm text-[#01133D]">Türkçe - ₺</p>
            <Icon
              name="chevron-down"
              size="15"
            />
          </div>
        </div>
        <div className="mb-6 container mx-auto">
          <div className="flex flex-row justify-between items-center">
            <p className="text-base text-gray-800 font-semibold mb-4">Kampanyalar</p>
            <div className="flex flex-row gap-1">
              {Array.from(Array(4), (_, index) => (
                <div
                  key={index}
                  className="w-[4px] h-[4px] bg-[#D3D4D7] rounded-full"
                />
              ))}
            </div>
          </div>
          <div className="flex flex-row justify-between p-4 bg-[#FAFAFA]">
            <p className="text-sm text-[#474F61]">₺ 1000 ve üzeri anında ₺200 indirim!</p>
            <Icon
              name="chevron-right"
              color="#474F61"
              size="15"
            />
          </div>
        </div>
        <div className="container mx-auto mb-4 flex flex-row justify-between items-center">
          <p className="text-lg text-gray-800 font-semibold">Oda Seçenekleri</p>
          <p className="text-sm text-[#474F61]">2 yetişkin</p>
        </div>
        <div className="bg-white container mb-20 mx-auto">
          <div className="flex flex-col">
            <div
              className="mb-4 bg-[#FAFAFA] p-2 border border-solid border-gray-200"
              style={{ borderRadius: borderRadius }}
            >
              <div className="flex flex-col mb-4">
                <p className="text-base font-semibold mb-4">Deluxe Oda - Bahçe Manzaralı</p>
                <div className="flex flex-row">
                  <img
                    alt="hotel"
                    className="w-2/5 mr-3"
                    src={`${evaGlobalImageUrl}cms/media/hotel/room/d1bf02dd-7dc1-4bc5-95b7-69817b2d944c.jpg`}
                    style={{ borderRadius: borderRadius }}
                  />
                  <div className="flex flex-col w-full">
                    <div className="flex flex-row gap-1 mb-1">
                      <Icon
                        name="size"
                        color="#01133D"
                        size="15"
                      />
                      <p className="text-sm">10 m2</p>
                      <Icon
                        name="image"
                        color="#01133D"
                        size="15"
                      />
                      <p className="text-sm">Bahçe manzarası</p>
                    </div>
                    <div className="flex flex-row gap-1 mb-5">
                      <Icon
                        name="no-smoking"
                        color="#01133D"
                        size="15"
                      />
                      <p className="text-sm">Sigara içilmez</p>
                      <Icon
                        name="wifi"
                        color="#01133D"
                        size="15"
                      />
                      <p className="text-sm">Wi-Fi</p>
                    </div>
                    <div className="flex flex-row items-center">
                      <p
                        className="text-sm font-semibold"
                        style={{ color: color }}
                      >
                        Tüm özellikleri gör
                      </p>
                      <Icon
                        name="chevron-right"
                        color={color}
                        size="15"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="flex flex-row p-3 mb-3 bg-white border border-solid"
                style={{ borderColor: color, borderRadius: borderRadius }}
              >
                <div className="w-1/2 flex flex-col gap-2">
                  <div className="flex items-center">
                    <div
                      className="py-1 px-2 flex flex-row border text-xs text-gray-800"
                      style={{ borderColor: color, borderRadius: borderRadius }}
                    >
                      <Icon
                        className="mr-1"
                        name="best-price"
                        size="15"
                      />
                      <p>En düşük fiyat</p>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <Icon
                      className="mr-2"
                      name="non_refundable_new"
                      size="15"
                    />
                    <p className="border-b border-dashed border-gray-200 inline-block text-sm">Geri Ödemesiz</p>
                  </div>
                  <div className="flex items-center">
                    <Icon
                      className="mr-2"
                      name="meal"
                      size="15"
                    />
                    <p className="border-b border-dashed border-gray-200 inline-block text-sm">Ultra her şey dahil</p>
                  </div>
                  <div className="flex items-center">
                    <Icon
                      className="mr-2"
                      name="discount"
                      size="15"
                    />
                    <p className="border-b border-dashed border-gray-200 inline-block text-sm">Erken rezervasyon indirimi</p>
                  </div>
                  <div className="flex items-center">
                    <Icon
                      className="mr-2"
                      name="baby"
                      size="15"
                    />
                    <p className="border-b border-dashed border-gray-200 inline-block text-sm">2 çocuk ücretsiz</p>
                  </div>
                </div>
                <div className="w-1/2 flex flex-col gap-1">
                  <div className="flex justify-end">
                    <p className="bg-[#FAFAFA] inline-block rounded p-1 text-xs text-gray-800 font-medium mr-2">%30 indirim</p>
                  </div>
                  <p className="text-xs  text-[#8A92A7] line-through flex justify-end">₺ 5.500</p>
                  <p className="text-xl text-gray-800 font-semibold flex justify-end">₺ 4.500</p>
                  <p className="text-xs  text-[#474F61] flex justify-end">5 gece için</p>
                  <p
                    className="text-sm font-medium flex justify-end mb-5"
                    style={{ color: color }}
                  >
                    Fiyat Bilgileri
                  </p>
                  <div className="flex items-center justify-end">
                    <div
                      className="border-2 border-solid px-8 py-3 flex flex-row items-center"
                      style={{ borderColor: color, borderRadius: borderRadius }}
                    >
                      <Icon
                        className="mr-2"
                        name="check"
                        color={color}
                        size="15"
                      />
                      <p
                        className="text-sm font-medium"
                        style={{ color: color }}
                      >
                        Seçildi
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="flex flex-row p-4 mb-4 bg-white border border-solid border-border-gray"
                style={{ borderRadius: borderRadius }}
              >
                <div className="w-1/2 flex flex-col gap-2">
                  <div className="flex items-center">
                    <div
                      className="py-1 px-2 flex flex-row text-xs border text-gray-800"
                      style={{ borderColor: color, borderRadius: borderRadius }}
                    >
                      <Icon
                        className="mr-1"
                        name="best-price"
                        size="15"
                      />
                      <p>En düşük fiyat</p>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <Icon
                      className="mr-2"
                      name="non_refundable_new"
                      size="15"
                    />
                    <p className="border-b border-dashed border-gray-200 inline-block text-sm">Geri Ödemesiz</p>
                  </div>
                  <div className="flex items-center">
                    <Icon
                      className="mr-2"
                      name="meal"
                      size="15"
                    />
                    <p className="border-b border-dashed border-gray-200 inline-block text-sm">Ultra her şey dahil</p>
                  </div>
                  <div className="flex items-center">
                    <Icon
                      className="mr-2"
                      name="discount"
                      size="15"
                    />
                    <p className="border-b border-dashed border-gray-200 inline-block text-sm">Erken rezervasyon indirimi</p>
                  </div>
                  <div className="flex items-center">
                    <Icon
                      className="mr-2"
                      name="baby"
                      size="15"
                    />
                    <p className="border-b border-dashed border-gray-200 inline-block text-sm">2 çocuk ücretsiz</p>
                  </div>
                </div>
                <div className="w-1/2 flex flex-col gap-1">
                  <p className="text-xl text-gray-800 font-semibold flex justify-end">₺ 6.500</p>
                  <p className="text-xs  text-[#474F61] flex justify-end">5 gece için</p>
                  <p
                    className="text-sm font-medium flex justify-end mb-5"
                    style={{ color: color }}
                  >
                    Fiyat Bilgileri
                  </p>
                  <div className="flex items-center justify-end">
                    <div
                      className="border-2 border-solid px-8 py-3"
                      style={{ borderColor: color, borderRadius: borderRadius }}
                    >
                      <p
                        className="text-sm font-medium"
                        style={{ color: color }}
                      >
                        Oda Seç
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-center items-center">
                <p
                  className="text-sm font-medium"
                  style={{ color: color }}
                >
                  Bu oda için diğer fiyat seçeneklerini gör (2)
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <div
              className="mb-4 bg-[#FAFAFA] p-2 border border-solid border-gray-200"
              style={{ borderRadius: borderRadius }}
            >
              <div className="flex flex-col mb-4">
                <p className="text-base font-semibold mb-4">Deluxe Oda - Deniz Manzaralı</p>
                <div className="flex flex-row">
                  <img
                    alt="hotel"
                    className="w-2/5 mr-3"
                    src={`${evaGlobalImageUrl}cms/media/hotel/room/d1bf02dd-7dc1-4bc5-95b7-69817b2d944c.jpg`}
                    style={{ borderRadius: borderRadius }}
                  />
                  <div className="flex flex-col w-full">
                    <div className="flex flex-row gap-1 mb-1">
                      <Icon
                        name="size"
                        color="#01133D"
                        size="15"
                      />
                      <p className="text-sm">10 m2</p>
                      <Icon
                        name="image"
                        color="#01133D"
                        size="15"
                      />
                      <p className="text-sm">Bahçe manzarası</p>
                    </div>
                    <div className="flex flex-row gap-1 mb-5">
                      <Icon
                        name="no-smoking"
                        color="#01133D"
                        size="15"
                      />
                      <p className="text-sm">Sigara içilmez</p>
                      <Icon
                        name="wifi"
                        color="#01133D"
                        size="15"
                      />
                      <p className="text-sm">Wi-Fi</p>
                    </div>
                    <div className="flex flex-row items-center">
                      <p
                        className="text-sm  font-semibold"
                        style={{ color: color }}
                      >
                        Tüm özellikleri gör
                      </p>
                      <Icon
                        name="chevron-right"
                        color={color}
                        size="15"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="flex flex-row p-3 mb-3 bg-white border border-solid border-gray-200"
                style={{ borderRadius: borderRadius }}
              >
                <div className="w-1/2 flex flex-col gap-2">
                  <div className="flex items-center">
                    <div
                      className="py-1 px-2 rounded flex flex-row border text-xs text-gray-800"
                      style={{ borderColor: color }}
                    >
                      <Icon
                        className="mr-1"
                        name="best-price"
                        size="15"
                      />
                      <p>En düşük fiyat</p>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <Icon
                      className="mr-2"
                      name="non_refundable_new"
                      size="15"
                    />
                    <p className="border-b border-dashed border-gray-200 inline-block text-sm">Geri Ödemesiz</p>
                  </div>
                  <div className="flex items-center">
                    <Icon
                      className="mr-2"
                      name="meal"
                      size="15"
                    />
                    <p className="border-b border-dashed border-gray-200 inline-block text-sm">Ultra her şey dahil</p>
                  </div>
                  <div className="flex items-center">
                    <Icon
                      className="mr-2"
                      name="discount"
                      size="15"
                    />
                    <p className="border-b border-dashed border-gray-200 inline-block text-sm">Erken rezervasyon indirimi</p>
                  </div>
                  <div className="flex items-center">
                    <Icon
                      className="mr-2"
                      name="baby"
                      size="15"
                    />
                    <p className="border-b border-dashed border-gray-200 inline-block text-sm">2 çocuk ücretsiz</p>
                  </div>
                </div>
                <div className="w-1/2 flex flex-col gap-1">
                  <div className="flex justify-end">
                    <p
                      className="bg-[#FAFAFA] inline-block p-1 text-xs text-gray-800 font-medium mr-2"
                      style={{ borderRadius: borderRadius }}
                    >
                      %30 indirim
                    </p>
                  </div>
                  <p className="text-xs  text-[#8A92A7] line-through flex justify-end">₺ 5.500</p>
                  <p className="text-xl text-gray-800 font-semibold flex justify-end">₺ 4.500</p>
                  <p className="text-xs  text-[#474F61] flex justify-end">5 gece için</p>
                  <p
                    className="text-sm font-medium flex justify-end mb-5"
                    style={{ color: color }}
                  >
                    Fiyat Bilgileri
                  </p>
                  <div className="flex items-center justify-end">
                    <div
                      className="border-2 border-solid px-8 py-3"
                      style={{ borderColor: color, borderRadius: borderRadius }}
                    >
                      <p
                        className="text-sm font-medium"
                        style={{ color: color }}
                      >
                        Oda Seç
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="flex flex-row p-4 mb-4 bg-white border border-solid border-border-gray"
                style={{ borderRadius: borderRadius }}
              >
                <div className="w-1/2 flex flex-col gap-2">
                  <div className="flex items-center">
                    <div
                      className="py-1 px-2 flex flex-row text-xs border text-gray-800"
                      style={{ borderColor: color, borderRadius: borderRadius }}
                    >
                      <Icon
                        className="mr-1"
                        name="best-price"
                        size="15"
                      />
                      <p>En düşük fiyat</p>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <Icon
                      className="mr-2"
                      name="non_refundable_new"
                      size="15"
                    />
                    <p className="border-b border-dashed border-gray-200 inline-block text-sm">Geri Ödemesiz</p>
                  </div>
                  <div className="flex items-center">
                    <Icon
                      className="mr-2"
                      name="meal"
                      size="15"
                    />
                    <p className="border-b border-dashed border-gray-200 inline-block text-sm">Ultra her şey dahil</p>
                  </div>
                  <div className="flex items-center">
                    <Icon
                      className="mr-2"
                      name="discount"
                      size="15"
                    />
                    <p className="border-b border-dashed border-gray-200 inline-block text-sm">Erken rezervasyon indirimi</p>
                  </div>
                  <div className="flex items-center">
                    <Icon
                      className="mr-2"
                      name="baby"
                      size="15"
                    />
                    <p className="border-b border-dashed border-gray-200 inline-block text-sm">2 çocuk ücretsiz</p>
                  </div>
                </div>
                <div className="w-1/2 flex flex-col gap-1">
                  <p className="text-xl text-gray-800 font-semibold flex justify-end">₺ 6.500</p>
                  <p className="text-xs  text-[#474F61] flex justify-end">5 gece için</p>
                  <p
                    className="text-sm font-medium flex justify-end mb-5"
                    style={{ color: color }}
                  >
                    Fiyat Bilgileri
                  </p>
                  <div className="flex items-center justify-end">
                    <div
                      className="border-2 border-solid px-8 py-3"
                      style={{ borderColor: color, borderRadius: borderRadius }}
                    >
                      <p
                        className="text-sm font-medium"
                        style={{ color: color }}
                      >
                        Oda Seç
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-center items-center ">
                <p
                  className="text-sm font-medium"
                  style={{ color: color }}
                >
                  Bu oda için diğer fiyat seçeneklerini gör (2)
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full flex flex-col justify-center items-center">
          <p className="text-xs text-[#8A92A7] mb-6">Powered by Gordion</p>
          <div className="bg-[#D3D4D7] h-[1px] w-full" />
        </div>
        <div className="bg-white flex flex-col gap-6 justify-between container my-12 mx-auto">
          <div>
            <img
              className="h-6 mb-2"
              alt="logo"
              src={logo}
            />
            <p className="text-xs text-[#8A92A7]">©2022, Gordion Teknoloji. Tüm hakları saklıdır.</p>
          </div>
          <div className="w-[60%]">
            <p className="text-sm text-[#474F61]">Adres:</p>
            <p className="text-sm text-[#8A92A7]">Yavansu Mh. Süleyman Demirel Bulv. No:156 Kuşadası / AYDIN – Türkiye</p>
          </div>
          <div className="">
            <p className="text-sm text-[#474F61]">İletişim:</p>
            <p className="text-sm text-[#8A92A7]">0(256) 999 16 56</p>
            <p className="text-sm text-[#8A92A7]">info@fantasia.com.tr</p>
          </div>
        </div>
        <div className="bg-[#FAFAFA] border border-solid border-gray-200 py-2 px-4 sticky bottom-0 left-0 flex flex-row justify-between">
          <div className="flex flex-row items-center">
            <div
              className="border border-solid rounded inline-flex mr-2"
              style={{ borderColor: color }}
            >
              <Icon
                name="chevron-up"
                color={color}
                size="16"
              />
            </div>
            <div className="flex-col">
              <p className="text-xs text-[#474F61]">Toplam</p>
              <p className="text-xl text-gray-800 font-semibold">₺ 4.500</p>
            </div>
          </div>
          <div
            className="py-2 px-8 flex justify-center border items-center"
            style={{ backgroundColor: color, borderRadius: borderRadius }}
          >
            <p className="text-sm text-white font-medium">Rezervasyon Yap</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Mobile
