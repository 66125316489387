import * as type from "redux/types"

export const pageSaveRequested = (data) => ({
  type: type.PAGE_SAVE_REQUESTED,
  payload: data,
})

export const pageSaveSucceed = (data) => ({
  type: type.PAGE_SAVE_SUCCEEDED,
  payload: data,
})

export const pageSaveFailed = () => ({
  type: type.PAGE_SAVE_FAILED,
})
