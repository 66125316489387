/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import tippy from "tippy.js"

import MainHeader from "components/Headers/MainHeader"
import { TitleH1 } from "components"
import Icon from "components/Icon"
import DocumentAlert from "./Edit/DocumentAlert"
import CorporateInformationSkeleton from "skeletons/CorporateInformation"
import ContactContent from "./ContactContent"
import TursabContent from "./TursabContent"
import About from "./Edit/About"
import Contact from "./Edit/Contact"

import { saveUserSettingsRequested } from "redux/actions/saveUserSettings"
import { getSettingsRequested } from "redux/actions/getSettings"
import { mediaLibrarySaveRequested, mediaLibraryListRequested } from "redux/actions/mediaLibrary"
import SocialMedia from "./Edit/SocialMedia"
import SocialMediaContent from "./SocialMediaContent"

let flag = true
const CorporateInformation = (props) => {
  let { isLogged } = props
  const dispatch = useDispatch()

  //refs
  const tippyContent = useRef(null)
  // states
  const [ThemeConfig, setThemeConfig] = useState({})
  const [ShowDocumentAlert, setShowDocumentAlert] = useState(false)
  const [documentNumberValue, setDocumentNumberValue] = useState("")
  const [aboutData, setAboutData] = useState(null)
  const [tursabValue, setTursabValue] = useState({})
  const [tursabVerify, setTursabVerify] = useState({})
  const [tursabLogo, setTursabLogo] = useState({})
  const [ToggleDigitalVerification, setToggleDigitalVerification] = useState(false)
  const [selectedContent, setSelectedContent] = useState(false)
  const [loading, setLoading] = useState(false)

  const AddStateData = {
    Hakkımızda: {
      title: "Hakkımızda",
      description: "İşletmeniz hakkında kurumsal bilgilerinizi verin. Yaptığınız değişiklikler sitenize yansıyacaktır.",
    },
    "İletişim Bilgileri": {
      title: "İletişim Bilgileri",
      description:
        "Aşağıdaki bilgiler ürününüzün footer kısmında son kullanıcılarınız ile iletişim bilgisi olarak paylaşılacaktır. ",
    },
    "Sosyal Medya": {
      title: "Sosyal Medya Ayarları",
      description: "İşletmenizin sosyal medya hesaplarını yönetin ve kurumsal kimliğini destekleyin.",
    },
  }

  // selectors
  const mediaSaveData = useSelector((state) => state.mediaLibrary.mediaLibrarySave?.response)
  const mediaList = useSelector((state) => state.mediaLibrary.mediaLibraryList.list)
  const getSettingsData = useSelector((state) => state.getSettings.list)
  const getSettingsLoading = useSelector((state) => state.getSettings.loading)
  // Func
  const setConfig = (config) => {
    if (config) {
      setThemeConfig({
        ...ThemeConfig,
        displaySettings: {
          ...ThemeConfig.displaySettings,
          ...config,
        },
      })
      if (flag) {
        var formData = new FormData()
        const logoFile = config?.logoValues?.fileForBinary

        formData.append("File", logoFile)
        dispatch(mediaLibrarySaveRequested(formData))
      }
    }
  }

  const addDocument = () => {
    setShowDocumentAlert(!ShowDocumentAlert)
  }

  const saveAndPublish = () => {
    dispatch(
      saveUserSettingsRequested({
        properties: [
          {
            PropertyId: tursabValue.id,
            PropertyValue: documentNumberValue || "",
            Status: documentNumberValue ? 1 : 0
          },
          {
            PropertyId: tursabVerify.id,
            PropertyValue: ToggleDigitalVerification ? "True" : "False",
            Status: 1,
          },
          {
            PropertyId: tursabLogo.id,
            PropertyValue: ThemeConfig?.displaySettings?.logoValues && mediaList && mediaList[0]?.path ? mediaList[0]?.path : "",
            Status: ThemeConfig?.displaySettings?.logoValues && mediaList && mediaList[0]?.path ? 1 : 0
          },
        ],
      }),
    )
    setShowDocumentAlert(false)
    setLoading(true)

    setTimeout(() => {
      setLoading(false)
      dispatch(getSettingsRequested())
    }, 2000)
  }

  useEffect(() => {
    if (mediaSaveData?.result) {
      let Ids = [mediaSaveData?.result?.id]
      dispatch(mediaLibraryListRequested({ Ids }))
    }
  }, [mediaSaveData?.result])

  useEffect(() => {
    dispatch(getSettingsRequested())
  }, [dispatch])

  useEffect(() => {
    if (getSettingsData) {
      setLoading(false)

      const about = getSettingsData?.Result.find((i) => i.PropertyName === "Hakkımızda Text")
      const tursab = getSettingsData?.Result.find((i) => i.PropertyName === "Tursab Belge No")
      const tursabVerify = getSettingsData?.Result.find((i) => i.PropertyName === "Dijital Doğrulama")
      const tursabLogo = getSettingsData?.Result.find((i) => i.PropertyName === "Tursab Logo")

      setAboutData(about)
      setTursabVerify({
        id: tursabVerify.PropertyId,
        value: tursabVerify.PropertyValue,
      })
      setTursabValue({
        id: tursab.PropertyId,
        value: tursab.PropertyValue,
      })
      setTursabLogo({
        id: tursabLogo.PropertyId,
        value: tursabLogo.PropertyValue,
      })

      const tursabLogoValue = tursabLogo.PropertyValue === "" ? null : tursabLogo.PropertyValue
      setTursabLogo({
        id: tursabLogo.PropertyId,
        value: tursabLogoValue,
      })
    }
  }, [getSettingsData])

  useEffect(() => {
    if (tippyContent) {
      tippy(".tippy-content", {
        content: tippyContent?.current?.innerHTML,
        allowHTML: true,
      })
    }
  }, [tippyContent])

  useEffect(() => {
    if (tursabVerify.value === "True") {
      setToggleDigitalVerification(true)
    } else {
      setToggleDigitalVerification(false)
    }
  }, [tursabVerify.value])

  return (
    <>
      <MainHeader isLogged={isLogged} />
      {loading && <CorporateInformationSkeleton />}
      {!getSettingsLoading && !loading && (
        <main>
          {!selectedContent ? (
            <section className="p-10 my-0 mx-auto max-w-[1440px]">
              <div className="page-container pb-14">
                <div className="flex flex-col w-full">
                  <div className="page-top-area flex items-center mb-6">
                    <div className="page-title">
                      <TitleH1 className="text-3xl font-bold">Kurumsal Bilgiler</TitleH1>
                      <p className="font-normal mt-1">
                        Ürününüzün ‘’Footer’’ alanında, işletmeniz ile ilgili kurumsal bilgilerinizi verin.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="bg-white border border-gray-200 rounded-lg p-8">
                    <div className="flex items-center justify-between">
                      <h3 className="text-gray-800 text-lg font-semibold">Hakkımızda</h3>
                      <button
                        className="text-blue-500 font-medium"
                        onClick={() => setSelectedContent("Hakkımızda")}
                      >
                        {aboutData ? "Düzenle" : "Hakkımızda Bilgilerini Ekle"}
                      </button>
                    </div>
                    {aboutData ? (
                      <div
                        className="line-clamp-5 mt-4 text-gray-500 custom-reset-html"
                        dangerouslySetInnerHTML={{ __html: aboutData.PropertyValue }}
                      ></div>
                    ) : (
                      <p className={"text-gray-500 mt-4"}>İşletmeniz hakkında bilgiler verin.</p>
                    )}
                  </div>

                  <ContactContent
                    getSettingsData={getSettingsData}
                    setSelectedContent={setSelectedContent}
                  />

                  <TursabContent
                    tursabLogo={tursabLogo}
                    tursabValue={tursabValue}
                    tippyContent={tippyContent}
                    addDocument={addDocument}
                  />

                  <SocialMediaContent
                    getSettingsData={getSettingsData}
                    setSelectedContent={setSelectedContent}
                  />

                  <p className="note text-gray-500 mt-6">
                    <span className="text-blue-500">*</span>
                    Müşterilerinize daha iyi bir deneyim sunabilmek ve kurulumunuzu tamamlayabilmek için zorunlu olarak
                    işaretlenmiş alanları doldurun.
                  </p>
                </div>
              </div>

              <DocumentAlert
                rejectButtonClick={() => {
                  if (tursabVerify.value === "True") {
                    setToggleDigitalVerification(true)
                  } else {
                    setToggleDigitalVerification(false)
                  }
                  flag = false
                  setConfig({
                    ...ThemeConfig.displaySettings,
                    logoValues: null,
                  })
                  flag = true
                  setLoading(true)

                  setTimeout(() => {
                    setLoading(false)
                    dispatch(getSettingsRequested())
                  }, 500)
                  setDocumentNumberValue(tursabValue.value)
                  setShowDocumentAlert(false)
                }}
                approveButtonClick={() => saveAndPublish()}
                setConfig={(config) => setConfig(config)}
                config={{
                  ThemeConfig,
                }}
                ShowDocumentAlert={ShowDocumentAlert}
                documentNumberValue={documentNumberValue}
                setDocumentNumberValue={setDocumentNumberValue}
                tursabValue={tursabValue}
                setToggleDigitalVerification={setToggleDigitalVerification}
                ToggleDigitalVerification={ToggleDigitalVerification}
                setLoading={setLoading}
                tursabLogo={tursabLogo}
                setTursabLogo={setTursabLogo}
              />
            </section>
          ) : (
            <section className="p-10 my-0 mx-auto max-w-[1440px]">
              <button
                onClick={() => setSelectedContent(false)}
                className="go-back inline-flex items-center cursor-pointer"
              >
                <Icon
                  className="mr-2"
                  name="arrow-left"
                  size="20"
                  color="#667085"
                />
                <span className="text-base text-gray-500">Kurumsal Bilgilere Dön</span>
              </button>
              <div className="page-container mt-6">
                <div className="flex flex-col w-full">
                  <div className="page-top-area flex items-center mb-6">
                    <div className="page-title">
                      <TitleH1 className="text-3xl font-bold">{AddStateData[selectedContent]?.title}</TitleH1>
                      <p className="font-normal mt-1">{AddStateData[selectedContent]?.description}</p>
                    </div>
                  </div>
                  {selectedContent === "Hakkımızda" && (
                    <About
                      setSelectedContent={setSelectedContent}
                      getSettingsData={getSettingsData}
                      setLoading={setLoading}
                    />
                  )}
                  {selectedContent === "İletişim Bilgileri" && (
                    <div className="bg-white border border-gray-200 rounded-lg p-8">
                      <Contact
                        setSelectedContent={setSelectedContent}
                        getSettingsData={getSettingsData}
                        setLoading={setLoading}
                      />
                    </div>
                  )}
                  {selectedContent === "Sosyal Medya" && (
                    <div>
                      <SocialMedia
                        rejectButtonClick={() => {
                          setTimeout(() => {
                            setLoading(false)
                            dispatch(getSettingsRequested())
                          }, 500)
                        }}
                        setSelectedContent={setSelectedContent}
                        getSettingsData={getSettingsData}
                        setLoading={setLoading}
                      />
                    </div>
                  )}
                </div>
              </div>
            </section>
          )}
        </main>
      )}
    </>
  )
}

export default CorporateInformation
