import { CurrencyInput } from "components"
import FormHiddenInput from "./FormHiddenInput"
import { useFormContext } from "react-hook-form"
import { useUpdateEffect } from "usehooks-ts"

const FormNumericInput = ({ placeholder, name, onChange, conditionName, hiddenName }) => {
  const { setValue, getValues, resetField } = useFormContext()

  useUpdateEffect(() => {
    resetField(name, { defaultValue: "" })
  }, [conditionName])

  return (
    <>
      <CurrencyInput
        placeholder={placeholder} // this was "Tutar girin"
        name={name} // this was "selectBudgetPrice"
        value={getValues(`${name}`)}
        onChange={(val) => {
          onChange(val)
          setValue(hiddenName || `${name}_Text`, val.currentTarget.value)
        }}
      />
      <FormHiddenInput
        type="hidden"
        name={hiddenName || `${name}_Text`}
      />
    </>
  )
}

export default FormNumericInput
