import React from "react"
import moment from "moment"
import { BorderBox } from "components"
import lcfc from "utils/lowerCasedFirstCharacter"

const ReservationReportsDetailCarRentalInfo = ({ data, reservationData }) => {
  const car = lcfc(reservationData?.DetailResponse?.Car)
  return (
    <BorderBox className="p-8">
      <div className="grid grid-cols-1 gap-x-2 gap-y-3 md:grid-cols-2 lg:grid-cols-4">
        <div>
          <p className="font-medium text-gray-500">Alış Tarihi ve Saati</p>
          <p className="mt-1">{moment(car?.carDetail.pickupDate).format("DD MMM ddd, HH:mm")}</p>
        </div>
        <div>
          <p className="font-medium text-gray-500">Teslim Tarihi ve Saati</p>
          <p className="mt-1">{moment(car?.carDetail.returnDate).format("DD MMM ddd, HH:mm")}</p>
        </div>
        <div>
          <p className="font-medium text-gray-500">Seyahat Nedeni</p>
          <p className="mt-1">{data?.travelReasonName || "Bilinmiyor"}</p>
        </div>
        <div>
          <p className="font-medium text-gray-500">Toplam Tutar</p>
          <p className="mt-1">
            {Intl.NumberFormat("tr-TR", {
              style: "currency",
              currency: "TRY",
            }).format(data.totalPrice)}
          </p>
        </div>
      </div>
    </BorderBox>
  )
}

export default ReservationReportsDetailCarRentalInfo
