import React, { useEffect } from "react"
import { Stack, Button } from "rsuite"
import { ExportData, Icon } from "components"
import UserAddModal, { useUserAddModal } from "./UserAddModal"
import SuccessModal, { useSuccessModal } from "./SuccessModal"
import ImportModal, { useImportModal } from "./ImportModal"
import { useDispatch, useSelector } from "react-redux"
import { clientUserRegisterRequested, clientUserRegisterSucceed } from "redux/actions/clientUserRegister"
import { allAccountListRequested } from "redux/actions/getAllAccountList"

const { useExportModal, ExportModal } = ExportData

const getExportData = (data) => {
  return data.map((user) => ({
    name: `${user.name} ${user.surname}`,
    email: user.email,
    departments: (user?.departments || []).map((items) => items.departmentName).join(", "),
    status: user.status ? "Aktif" : "Pasif",
    role: (user?.role || []).map((items) => items.roleName).join(", "),
    //phone: user.phone,
  }))
}

const ButtonActions = ({ data, selectedRowsData }) => {
  const dispatch = useDispatch()

  const addModal = useUserAddModal()
  const successModal = useSuccessModal()
  const exportModal = useExportModal(selectedRowsData)
  const importModal = useImportModal()

  const clientUserRegisterState = useSelector((state) => state.clientUserRegister.list)

  let exportmodalData = selectedRowsData && getExportData(selectedRowsData)

  if (!selectedRowsData) {
    exportmodalData = getExportData(data)
  }

  console.log("data", data)
  console.log("selectedRowsData", selectedRowsData)

  useEffect(() => {
    if (clientUserRegisterState?.success) {
      dispatch(clientUserRegisterSucceed(null))
      addModal.handleClose()
      successModal.handleOpen(data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientUserRegisterState])

  useEffect(() => {
    if (successModal.data && !successModal.open) {
      dispatch(allAccountListRequested())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successModal])

  return (
    <>
      <Stack
        direction="column"
        spacing={20}
        alignItems="flex-end"
      >
        <Stack spacing={10}>
          <Button
            className="btn p-2 h-auto !bg-primary-50"
            onClick={exportModal.handleOpen}
          >
            <Icon
              size={24}
              name={"external-link"}
              className={"!bg-primary-500"}
            />
          </Button>
          <Button
            className="btn p-2 h-auto !bg-primary-50"
            onClick={importModal.handleOpen}
          >
            <Icon
              size={24}
              name="download"
              className="!bg-primary-500"
            />
          </Button>
          <Button
            className="btn px-4 py-2.5 h-auto hover:text-white focus:text-white active:text-white !bg-primary-500 text-sm"
            onClick={addModal.handleOpen}
          >
            <Icon
              size={18}
              name="plus"
              className="!bg-white"
            />
            Kullanıcı Ekle
          </Button>
        </Stack>
      </Stack>
      <UserAddModal
        open={addModal.open}
        onClose={addModal.handleClose}
        onSuccess={(data) => {
          dispatch(clientUserRegisterRequested(data))
        }}
      />
      <SuccessModal
        open={successModal.open && !!successModal.data}
        onClose={successModal.handleClose}
        data={successModal.data}
        onOk={addModal.handleOpen}
      />
      <ExportModal
        {...exportModal}
        dataHeader={["Ad-Soyad", "E-posta", "Departman", "Statü", "Rol"]}
        data={getExportData(data)}
        selectedRowData={exportmodalData}
      />
      <ImportModal {...importModal} />
    </>
  )
}

export default ButtonActions
