/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useRef, useState} from "react"
import {useDispatch, useSelector} from "react-redux";

//layouts
import PaginationAndNavigation from "./PaginationAndNavigation"
/*import Arrow from "./SliderArrows"
import AutoSlide from "./AutoSlide"*/

//components
import {SelectPicker} from "rsuite";
import {ColorBox, SideMenuListItem} from "components"
import {UpdateStrategy} from "../../../../helpers/UpdateStrategy";

//localization
import localization from "localization";

//helpers
import {setSettings} from "helpers/setSettings";

// saga-requests
import {getStrategySucceed} from "redux/actions/strategy";

const Settings = (props) => {
  let { ThisComponent, setThisComponent} = props

  const dispatch = useDispatch()
  const widthUnitTypeWrapperRef= useRef()
  const heighthUnitTypeWrapperRef= useRef()
  const widthInputRef = useRef(null);
  const heightInputRef = useRef(null);

  //selectors
  let components = useSelector(state => state.strategy.getStrategy.list?.result)

  // const
  const units = ["auto", "px", "%"]
  const unitRegexp = /[^%pxauto]+/gi
  const unitSelectpickerData = units.map((unit) => {
    return {
      label: unit,
      value: unit,
    }
  })

  //states
  const [ShowSubMenu, setShowSubMenu] = useState(null)
  const [VisualBlockSettings, setVisualBlockSettings] = useState(ThisComponent?.visual)
  const [DefaultUnit] = useState(units[0]) // pattern1+'|'+pattern2, 'gi'
  const [WidthActiveUnit, setWidthActiveUnit] = useState((ThisComponent?.visual?.width && ThisComponent?.visual?.width?.replace(unitRegexp,"")) || DefaultUnit)
  const [HeightActiveUnit, setHeightActiveUnit] = useState((ThisComponent?.visual?.height && ThisComponent?.visual?.height?.replace(unitRegexp,"")) || DefaultUnit)

  //funcs
  const getUnit = (whichProperty, whichUnit) => {
    if (ThisComponent?.visual?.[whichProperty] === DefaultUnit) return DefaultUnit
    return ThisComponent?.visual?.[whichProperty] && ThisComponent?.visual?.[whichProperty].slice(Number(whichUnit ? whichUnit.length : DefaultUnit) * -1);
  }
  const resetFields = (value, ref, key, activeUnit) => {
    if (activeUnit !== value){
      setVisualBlockSettings({
        ...ThisComponent?.visual,
        ...VisualBlockSettings,
        [key]: "auto"
      })
      setTimeout(() => {
        if (ref?.current){
          ref.current.value = undefined
        }
      }, 0)
    }
  }

  //effects
  useEffect(() => {
    if (VisualBlockSettings){
      setThisComponent({
        ...ThisComponent,
        visual: {
          ...ThisComponent?.visual,
          ...VisualBlockSettings,
          style: {
            ...ThisComponent?.visual?.style,
            ...VisualBlockSettings?.style,
          },
        }
      })
    }
  }, [VisualBlockSettings])
  useEffect(() => {
    if (ThisComponent && VisualBlockSettings){
      const strategy = UpdateStrategy(components, ThisComponent)
      dispatch(getStrategySucceed(strategy))
    }

  }, [ThisComponent])
  useEffect(() => {
    if (ThisComponent?.visual?.width){
      const activeUnit = getUnit("width", WidthActiveUnit)
      setWidthActiveUnit(activeUnit)
    }
    if (ThisComponent?.visual?.height){
      const activeUnit = getUnit("height", HeightActiveUnit)
      setHeightActiveUnit(activeUnit)
    }

  }, [ThisComponent?.visual?.width || ThisComponent?.visual?.height])

  return (
    <>
      <h6 className="text-gray-500 font-semibold">SETTINGS</h6>
      <div className="flex flex-col gap-4 mt-4">
        <div className="border-case flex flex-col gap-3 border border-gray-200 rounded-lg p-3">

          <div
            className="relative input-wrapper flex items-center border-t pt-3 first:p-0 first:border-t-0">
            <label
              htmlFor="widthInput"
              className="grow"
            >
              Genişlik (px, %)
            </label>

            {units.map(unit => {
              if (unit === DefaultUnit){
                return false
              }
              return <input
                hidden={WidthActiveUnit !== unit}
                key={unit}
                name="width"
                ref={widthInputRef}
                placeholder={WidthActiveUnit === "px" ? "1040" : "100"}
                style={{width: "90px"}}
                className={`!h-[26px] !py-0 !pl-0 !text-blue-500 !text-sm !border-0 bg-gray-100 text-right !pr-[52px]`}
                type="number"
                value={ThisComponent?.visual?.width && ThisComponent?.visual?.width?.replace(WidthActiveUnit, "")}
                onChange={(e) => setSettings(e, true, VisualBlockSettings, undefined, WidthActiveUnit).then((res) => setVisualBlockSettings(res))}
              />
            })}
            <div
              ref={widthUnitTypeWrapperRef}
              className="inline-selectpicker-wrapper absolute w-[50px] right-0 top-1">
              <SelectPicker
                block
                className="!border-none"
                placement="bottomStart"
                value={WidthActiveUnit || DefaultUnit}
                menuMaxHeight={164}
                cleanable={false}
                searchable={false}
                container={widthUnitTypeWrapperRef.current}
                onChange={(value) => {
                  if (value === DefaultUnit){
                    resetFields(value, widthInputRef, "width", WidthActiveUnit)
                  }
                  setWidthActiveUnit(value)
                }}
                size="xs"
                data={unitSelectpickerData}
                locale={localization.TR.PickerLocaleType.Picker}
                //onChange={(value) => console.log(value)}
              />
            </div>
          </div>
          <div className="relative input-wrapper flex items-center border-t pt-3 first:p-0 first:border-t-0">
            <label
              htmlFor="heightInput"
              className="grow"
            >
              Yükseklik (px)
            </label>

            {units.map(unit => {
              if (unit === DefaultUnit){
                return false
              }
              return <input
                hidden={HeightActiveUnit !== unit}
                key={unit}
                name="height"
                ref={heightInputRef}
                style={{width: "90px"}}
                className={`!h-[26px] !py-0 !pl-0 !text-blue-500 !text-sm !border-0 bg-gray-100 text-right !pr-[52px]`}
                type="number"
                value={ThisComponent?.visual?.height && ThisComponent?.visual?.height?.replace(HeightActiveUnit, "")}
                onChange={(e) => setSettings(e, true, VisualBlockSettings, undefined, HeightActiveUnit).then((res) => setVisualBlockSettings(res))}
              />
            })}
            <div
              ref={widthUnitTypeWrapperRef}
              className="inline-selectpicker-wrapper absolute w-[50px] right-0 top-4">
              <SelectPicker
                block
                className="!border-none"
                placement="bottomStart"
                value={HeightActiveUnit || DefaultUnit}
                menuMaxHeight={164}
                cleanable={false}
                searchable={false}
                container={heighthUnitTypeWrapperRef.current}
                onChange={(value) => {
                  if (value === DefaultUnit){
                    resetFields(value, heightInputRef, "height", HeightActiveUnit)
                  }
                  setHeightActiveUnit(value)
                }}
                size="xs"
                data={unitSelectpickerData.slice(0, 2)}
                locale={localization.TR.PickerLocaleType.Picker}
              />
            </div>
          </div>
        </div>

        <ColorBox
          title="Arka Plan Rengi"
          value={ThisComponent?.visual?.style?.backgroundColor}
          name="backgroundColor" // optional -  style property
          onChange={(e) => setSettings(e, false, VisualBlockSettings).then((res) => setVisualBlockSettings(res))}
        />
        <div className="border-case flex flex-col border border-gray-200 rounded-lg">
          <SideMenuListItem
            className="border-t first:border-t-0"
            onClick={() =>
              setShowSubMenu({
                paginationAndNavigation: true,
              })
            }
            title="Sayfalandırma ve Gezinme"
          />
          {/*<SideMenuListItem
            className="border-t first:border-t-0"
            onClick={() =>
              setShowSubMenu({
                arrow: true,
              })
            }
            title="Arrow"
          />
          <SideMenuListItem
            className="border-t first:border-t-0"
            onClick={() =>
              setShowSubMenu({
                autoSlide: true,
              })
            }
            title="Auto Slide"
          />*/}
        </div>
      </div>

      <PaginationAndNavigation
        show={ShowSubMenu?.paginationAndNavigation}
        setShow={(e) => setShowSubMenu(e)}
        ThisComponent={ThisComponent}
        setThisComponent={setThisComponent}
      />
      {/*<Arrow
        show={ShowSubMenu?.arrow}
        setShow={(e) => setShowSubMenu(e)}
      />
      <AutoSlide
        show={ShowSubMenu?.autoSlide}
        setShow={(e) => setShowSubMenu(e)}
      />*/}
    </>
  )
}

export default Settings
