import React from "react"
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"
import store from "redux/store"
import App from "./App"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"

import { Auth0Provider } from "./auth0/auth0-react"
import { getConfig } from "./config"

const config = getConfig()
const queryClient = new QueryClient()

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  ...(config.audience ? { audience: config.audience } : null),
  redirectUri: window.location.origin,

  /*advancedOptions: {
    defaultScope: '' // change the scopes that are applied to every authz request. **Note**: `openid` is always specified regardless of this setting
  },*/
}

const rootElement = document.getElementById("root")
const root = createRoot(rootElement)

root.render(
  <Auth0Provider {...providerConfig}>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <App />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Provider>
  </Auth0Provider>,
)
