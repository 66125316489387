/**
 * @description This object is used to create the form object. I took this constant object from the Backend Team and it is used in the backend.
 */
const OperatorObjects = {
  and: {
    Type: "AndOperatorStatement",
    Title: "VE",
  },
  or: {
    Type: "OrOperatorStatement",
    Title: "VEYA",
  },
}

const treeItemsGenerator = (formObject, reversed = true) =>
  (formObject?.children || []).reduce((prev, current, currentIndex) => {
    const a = {
      Type: "RuleTreeStatement",
      Name: `${currentIndex + 1}. Kural`,
      Children: current.children
        .reduce((subPrev, subCurrent, currentIndex) => {
          const conditionObject = {
            Type: "NamedRuleStatement",
            Name: subCurrent.conditionSelect,
            Parameters: reversed ? SerializeChildrens(subCurrent).reverse() : SerializeChildrens(subCurrent),
            ParameterLabels: {
              0: ["Olan"],
            },
            Title: "",
          }
          subPrev.push(conditionObject)

          const operatorObject =
            current?.children?.length <= 1 || current?.children?.length === currentIndex + 1
              ? null
              : OperatorObjects[current?.multipleCondition]

          operatorObject && subPrev.push(operatorObject)

          return subPrev
        }, [])
        .filter((item) => item?.Parameters?.length !== 0),
    }
    prev.push(a)

    if (!!formObject.children[currentIndex + 1]) {
      const operatorObject = OperatorObjects[formObject.children[currentIndex + 1].relatedCondition]
      operatorObject && prev.push(operatorObject)
    }

    return prev
  }, [])

/**
 * @description This function serializes the form data to the format that the api expects.
 * @description You can check the format from the Postman(Collections/WhiteLabel.Display/Rule/AddRule).
 *
 * @param {*} ruleTypeId
 * @param {*} formObject
 * @param {*} metadata
 * @returns
 */
export function FormToDataSerializer({ ruleTypeId, formObject }) {
  let returnObject = {
    Rule: {
      Id: Number(formObject?.rule?.Id) || 0,
      Name: formObject?.rule.Name || "",
      Priority: formObject?.rule.Priority || 0,
      Predicate: null,
      Result: null,
      CreateDate: new Date().toISOString(),
      UpdateDate: new Date().toISOString(),
      Status: formObject?.rule.Status === "active" ? 1 : 0,
      Type: ruleTypeId,
      ScopePath: null,
      Value: null,
      Parameters: JSON.stringify({
        Rule: formObject?.rule,
        Result: formObject?.result,
      }),
    },
    MetaData: {
      ResultItem: {
        TreeItems: treeItemsGenerator(formObject?.result, false),
        Metadatas: null,
      },
      RuleItem: {
        TreeItems: treeItemsGenerator(formObject?.rule),
        Metadatas: null,
      },
    },
  }

  return returnObject
}

/**
 * @description This function works to serialize the children of the form object according to expected data.
 *
 * @param {*} child
 * @returns
 */
function SerializeChildrens(child) {
  let childrenArray = Object.entries(child)

  let childrens = childrenArray.reduce((prev, [key, value]) => {
    if (key === "ArrayParameter" && !!value) {
      Array.isArray(value) ? prev.push(value?.join(",")) : prev.push(value)
      return prev;
    } else if (key === "DestinationParameter") {
      prev.push(value)
      return prev
    } else if (key === "EqualityListParameter") {
      prev.push(value)
      return prev
    } else if (key.includes("NumericParameter") && !key.includes("_Text") && !!value) {
      let newVal = value.replace(/\./g, "").replace(/,/g, ".")
      prev.push(newVal.toString())
      return prev
    } else if (key === "conditionSelect" || key.includes("_Text")) {
      return prev
    } else if (!!key && key.includes("ReadonlyParameter")) {
      prev.push(value)
      return prev
    }
    prev.push(value)
    return prev
  }, [])
  return childrens.filter((item) => item !== undefined || item !== null)
}
