import { call, fork, put, takeLatest } from "redux-saga/effects"
import * as actions from "redux/actions/clientConfigurationGet"

import { CLIENT_CONFIG_GET_REQUESTED } from "redux/types"
import { API_GET } from "helpers/api"

function* watchGetClientConfigGet() {
  yield takeLatest(CLIENT_CONFIG_GET_REQUESTED, getClientConfigGet)
}

function* getClientConfigGet() {
  try {
    const res = yield call(apiGetClientConfigGet)
    yield put(actions.clientConfigGetSucceed(res?.data?.result))
  } catch (err) {
    yield put(actions.clientConfigGetFailed())
  }
}

const apiGetClientConfigGet = () => {
  return API_GET(`${process.env.REACT_APP_DISPLAY_DOMAIN}/api/clientconfigurationpaneluser/get`)
}

export default function* clientConfigGet() {
  yield fork(watchGetClientConfigGet)
}
