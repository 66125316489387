import React from "react"
import { Modal, Button } from "rsuite"
import { ReactComponent as InfoError } from "assets/svg/info-error.svg"
import { useDefineCrud } from "utils/useDefines"

/**
 * @typedef {"makePassive" | "default"} ModalType
 *
 * @param {*} data Define object
 * @param {boolean} open Visibility of the modal
 * @param {ModalType} type default or makePassive
 * @param {() => void} onSuccess on success callback
 * @param {() => void} onFailed on Failed callback
 * @param {() => void} onClose on close callback
 * @returns
 */

const DefineDeleteModal = (props) => {
  const { defineCrud } = useDefineCrud()

  const handleOnSetStatus = (status) => {
    defineCrud({ ...props.data, Status: status })
      .then((response) => {
        if (response.status === 200) {
          props.onSuccess()
        } else {
          console.error("Tanım güncellenirken bir hata oluştu. Lütfen tekrar deneyiniz.")
        }
      })
      .catch((error) => {
        console.error("Tanım güncellenirken bir hata oluştu. Lütfen tekrar deneyiniz. : " + error.message)
      })
  }

  return (
    <>
      <Modal
        open={props.open}
        onClose={props.onClose}
        className="w-[382px] !mt-0 absolute top-1/2 left-1/2 transform !-translate-x-1/2 !-translate-y-1/2"
      >
        <Modal.Body className="flex justify-center items-center flex-col text-center gap-3">
          <span>
            <InfoError className="w-12 h-12 text-gray-500" />
          </span>
          <p className="font-semibold">{props?.type === "makePassive" ? "Tanımı Pasife Al" : "Tanımı Sil"}</p>
          {props?.type === "makePassive" ? (
            <p className="text-gray-600">
              "{props?.data?.PropertyValue}" tanımını pasife aldığınızda bu tanıma bağlı olan kurallar artık uygulanamayacaktır.
            </p>
          ) : (
            <p className="text-gray-600">
              "{props?.data?.PropertyValue}" tanımını sildiğinizde bu tanıma bağlı olan kurallar artık uygulanamayacaktır.
            </p>
          )}
        </Modal.Body>
        <Modal.Footer className="flex">
          <Button
            onClick={props.handleClose}
            className="btn w-full bg-white text-gray-600 border border-solid border-gray-300 mr-2 hover:!text-white hover:!bg-gray-600 hover:!border-gray-600"
          >
            Vazgeç
          </Button>
          <Button
            onClick={() => handleOnSetStatus(props?.type === "makePassive" ? 0 : -1)}
            className="btn w-full bg-red-600 text-white border border-solid border-red-600 hover:!text-white hover:!bg-red-700 hover:!border-red-700"
          >
            {props?.type === "makePassive" ? "Pasife Al" : "Sil"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

const useDefineDeleteModal = ({
  onSuccess,
} = {
  onSuccess: () => {},
}) => {
  const [open, setOpen] = React.useState(false)
  const [type, setType] = React.useState(null)
  const [data, setData] = React.useState(null)

  /**
   * @param {*} data
   * @param {ModalType} type
   */
  const handleOpen = (data, type) => {
    setData({...data})
    setOpen(true)
    setType(type || null)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleOnSuccess = () => {
    setOpen(false)
    onSuccess()
  }

  return {
    open,
    data,
    type,
    handleOpen,
    handleClose,
    onSuccess: handleOnSuccess,
  }
}

export { useDefineDeleteModal }
export default DefineDeleteModal
