/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useRef, useState} from "react"
import {useDispatch, useSelector} from "react-redux"

// constants
import {HYPERLINK, IMAGE, newHyperLink, newImage, newMedia, newMediaList, newText, TEXT,} from "../../../Enums";
//layouts
import PartialWrapper from "../../PartialWrapper"
// components
import {ColorBox, Icon, LoadBar, Subtitle, TabList, TextInput, ToggleBox} from "components"
// utils
import getSelectedIndex from "utils/getSelectedIndex"
//helpers
import {UpdateStrategy} from "../../../helpers/UpdateStrategy";
import {deleteMediaItem} from "../../../helpers/DeleteMediaItem";
import {setSettings} from "helpers/setSettings";

// saga-requests
import {getStrategySucceed} from "redux/actions/strategy";

const AddOrEditMedia = (props) => {
  let { activeLayout, editBlock, setEditBlock, ThisComponent, setThisComponent, SelectedCategory, subComponentIdList } = props

  const dispatch = useDispatch()
  let categoryInfo = editBlock?.categoryInfo

  const NewMediaList = newMediaList(ThisComponent?.code, activeLayout?.order)


  let [ThisMedia, setThisMedia] = useState(editBlock?.mediaList || newMedia(subComponentIdList?.id || editBlock?.mediaList?.code))
  const [ThisImage, setThisImage] = useState(null)
  const [ThisText, setThisText] = useState(null)
  const [ThisSubText, setThisSubText] = useState(null)
  const [ThisHyperLink, setThisHyperLink] = useState(null)
  const [ClientChanged, setClientChanged] = useState(false)

  const formRef = useRef()
  const UploadFileRef = useRef()

  // selectors
  let components = useSelector(state => state.strategy.getStrategy.list?.result)
  // states
  const [SelectedTab, setSelectedTab] = useState(0) // default
  const [InfoCodeForStrategy, setInfoCodeForStrategy] = useState(activeLayout?.info?.code)
  const [IsAddNewMedia, setIsAddNewMedia] = useState(editBlock?.categoryMedia === "addNewMedia")

  // Func
  const editMediaTabChanged = (e) => {
    let selectedIndex = getSelectedIndex(e)
    setSelectedTab(selectedIndex)
  }
  const clearImageState = () => {
    setThisImage(null)
    setSelectedTab(0)
  }
  const updateImage = () => {
    UploadFileRef.current.click()
  }
  const readImage = (file) => {
    const reader = new FileReader()
    reader.addEventListener("load", (event) => {
      setThisImage({
        ...ThisImage,
        data: {
          src: event.target.result,
          size: file.size,
          name: file.name,
          fileForBinary: file,
        }
      })
      setClientChanged(true)
    })
    reader.readAsDataURL(file)
    UploadFileRef.current.value = ""
  }
  const goBack = async () => {
    clearImageState()
    setThisText(null)
    setThisSubText(null)
    setThisHyperLink(null)
    setThisMedia(null)
    setClientChanged(false)
    setEditBlock(null)
  }
  const checkHasComponentVisual = () => {
    return ThisComponent?.visual
  }
  const getComponent = () => {
    let componentCode = ThisComponent?.code
    if (!componentCode && !checkHasComponentVisual()) {
      componentCode = InfoCodeForStrategy
    } else if (!componentCode && !InfoCodeForStrategy){
      componentCode = NewMediaList?.code
    }
    let component = components?.children?.find(child => child?.code === componentCode)
    if (!component){
      setInfoCodeForStrategy(NewMediaList.code)
      component = NewMediaList
    }
    return component
  }

  const generateChildrenModels = (component) => {
    component.children = []
    return component
  }
  const addCategoryToMedia = (ThisMedia) => {
    ThisMedia.data = {
      ...ThisMedia?.data,
      category: {
        ...ThisMedia?.data?.category,
        categoryId: categoryInfo?.Id,
        categoryName: categoryInfo?.Name,
        order: categoryInfo?.Order,
        categoryVisible: categoryInfo?.Visible,
        isActive: true
      }
    }

    setThisMedia({
      ...ThisMedia
    })
  }

  const getChildIndex = (component) => {
    return component?.children?.findIndex(child => child?.code === ThisMedia?.code)
  }

  //effects
  useEffect(() => {
    if (editBlock){
      let thisImage, thisText, thisSubText, thisHyperLink
      if (editBlock?.categoryMedia === "addNewMedia"){
        setIsAddNewMedia(true)
        setThisMedia(newMedia(subComponentIdList?.id || editBlock?.mediaList?.code))
        thisImage = newImage(editBlock?.itemInfo?.imageId)
        thisText = newText(editBlock?.itemInfo?.textId, undefined, "textComponent")
        thisSubText = newText(editBlock?.itemInfo?.subTextId, undefined, "subTextComponent")
        thisHyperLink = newHyperLink(editBlock?.itemInfo?.hyperLinkId)
      }
      if (editBlock?.mediaList) {
        setThisMedia({
          ...ThisMedia,
          ...editBlock?.mediaList
        })
        thisImage = editBlock?.mediaList?.children?.find(child => child?.name === IMAGE)
        thisText = editBlock?.mediaList?.children?.find(child => child?.name === TEXT && child?.visual?.settings?.className === "textComponent")
        thisSubText = editBlock?.mediaList?.children?.find(child => child?.name === TEXT && child?.visual?.settings?.className === "subTextComponent")
        thisHyperLink = editBlock?.mediaList?.children?.find(child => child?.name === HYPERLINK)
        const checkItemIncludesImage = !!thisImage?.data?.src || !!!thisImage?.visual?.style?.backgroundColor
        setSelectedTab(checkItemIncludesImage ? 0 : 1)
      }

      setThisImage({...thisImage})
      setThisText({...thisText})
      setThisSubText({...thisSubText})
      setThisHyperLink({...thisHyperLink})
    }
  }, [editBlock])

  useEffect(() => {
    if ((ThisImage || ThisText || ThisSubText || ThisHyperLink) && ClientChanged){

      let component = getComponent()
      if (!component?.children){
        generateChildrenModels(component)
      }
      if (IsAddNewMedia){
        ThisMedia = newMedia()
        setIsAddNewMedia(false)
      }
      ThisMedia = {
        ...ThisMedia,
        children: [
          ThisImage,
          ThisText,
          ThisSubText,
          ThisHyperLink,
        ]
      }
      if (!component?.children?.length){
        component = {
          ...component,
          children: [ThisMedia],
        }
      } else {
        const childIndex = getChildIndex(component)
        if (childIndex !== -1){
          component.children[childIndex] = ThisMedia
        } else {
          component.children[component.children.length] = ThisMedia
        }
      }
      setThisMedia({
        ...ThisMedia,
      })
      if (!ThisMedia?.data?.category) addCategoryToMedia(ThisMedia)
      setThisComponent({
        ...component,
      })
    }


  }, [ThisImage, ThisText, ThisSubText, ThisHyperLink])

  useEffect(() => {
    if (ClientChanged){
      const strategy = UpdateStrategy(components, ThisComponent)
      dispatch(getStrategySucceed(strategy))
    }
  }, [ThisComponent])

  return (
    <PartialWrapper
      goBackOnClick={goBack}
      className={`edit-media${editBlock?.categoryMedia ? " active" : ""}`}
    >
      {!ThisMedia && (
        <LoadBar pathFill={"#2E90FA"} />
      )}
      {ThisMedia && (
        <>
          <Subtitle className="mb-4">{!IsAddNewMedia ? "Medya Düzenle" : "Medya Ekle"}</Subtitle>

          <div className="input-wrapper mt-4">
            <TextInput
              title="Başlık"
              placeholder="Başlık girin"
              name="text"
              value={ThisText?.data?.text}
              onChange={e => {
                setSettings(e, true, ThisText, "data").then((res) => {
                  setThisText(res)
                  setClientChanged(true)
                })
              }}
            />
          </div>

          <div className="input-wrapper mt-4">
            <ToggleBox
              title="Alt Başlığı Göster"
              checked={ThisSubText?.visual?.style?.display !== "none"}
              onToggle={e => setSettings({ target: {
                  name: "display",
                  value: e ? "block" : "none"
                }}, true, ThisSubText?.visual, "style").then((res) => {
                let stylized = [res].reduce((acc, value) => {
                  acc = {visual: {...value, style: value?.style}}
                  delete acc?.style
                  return acc
                }, null)
                setThisSubText({
                  ...ThisSubText,
                  ...stylized,
                })
                setClientChanged(true)
              })}
            />
          </div>

          {(ThisSubText?.visual?.style?.display === undefined ? true : ThisSubText?.visual?.style?.display === "block") && (
            <div className="input-wrapper mt-4">
              <TextInput
                title="Alt Metin"
                placeholder="Alt metin girin"
                name="text"
                value={ThisSubText?.data?.text}
                onChange={e => setSettings(e, true, ThisSubText, "data").then((res) => {
                  setThisSubText(res)
                  setClientChanged(true)
                })}
              />
            </div>
          )}


          <div className="input-wrapper mt-7">
            <TextInput
              title="Bağlantı adresi"
              name="href"
              placeholder={ThisHyperLink?.data?.href || "https://..."}
              value={ThisHyperLink?.data?.href}
              onChange={e => setSettings(e, true, ThisHyperLink, "data").then((res) => {
                setThisHyperLink(res)
                setClientChanged(true)
              })}
            />
          </div>

          <div className="input-wrapper mt-4">
            <ToggleBox
              title={"Yeni Sekmede Aç"}
              checked={ThisHyperLink?.data?.target !== "_self"}
              onToggle={e => setSettings({ target: {
                  name: "target",
                  value: e ? "_blank" : "_self"
                }}, true, ThisHyperLink, "data").then((res) => {
                setThisHyperLink(res)
                setClientChanged(true)
              })}
            />
          </div>

          <div className="sub-caption-box flex items-center my-4">
            <h4 className="text-sm font-medium text-gray-800">Görüntü</h4>
          </div>
          <TabList
            onChange={editMediaTabChanged}
            tabs={[{ label: "Görüntü" }, { label: "Renk" }, { label: "Video", disabled: true }]}
            tabClassName="w-full flex items-center justify-center text-center text-gray-600 text-xs p-2 border-r last:border-r-0 hover:bg-gray-50 transition duration-400"
            wrapperClassName="flex shrink-0 border rounded-lg border-gray-200 overflow-hidden mb-7"
            //itemActiveClass="!text-gray-800 !bg-blue-50"
            activeTab={SelectedTab}
          />

          <div className="w-full overflow-hidden">
            <div
              className="flex transition duration-500 -m-10"
              style={{
                transform: `translate3d(${SelectedTab === 1 ? "-100%" : "0%"} , 0px, 0px)`,
              }}
            >
              <div className="background-image-area text-sm w-full shrink-0 p-10">
                <div className="flex items-center">
                  <p className="text-gray-800 font-medium">Arka plan görüntüsü</p>
                  <div className="progress-area ml-auto">
                    {!ThisImage?.data?.src && (
                      <p
                        className="text-blue-500 cursor-pointer font-medium hover:text-blue-300 transition"
                        onClick={() => UploadFileRef.current.click()}
                      >
                        Ekle
                      </p>
                    )}
                    {ThisImage?.data?.src && (
                      <div className="icon-box flex items-center gap-4">
                        <Icon
                          className="cursor-pointer hover:opacity-50"
                          name="refresh-cw"
                          size={20}
                          color={"#1D2939"}
                          onClick={updateImage}
                        />
                        <Icon
                          className="cursor-pointer hover:opacity-50"
                          name="trash-2"
                          size={20}
                          color={"#1D2939"}
                          onClick={() => {
                            deleteMediaItem(ThisComponent, ThisMedia, true).then(response => {
                              if (response){
                                const strategy = UpdateStrategy(components, response)
                                dispatch(getStrategySucceed(strategy))
                                setEditBlock({...editBlock})
                                clearImageState()
                              }
                            })
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <form
                    ref={formRef}
                    className="hidden"
                  >
                    <input
                      hidden
                      type="file"
                      id="fileUploadInputForMedia"
                      ref={UploadFileRef}
                      onChange={(e) => readImage(UploadFileRef.current.files[0])}
                      accept=".jpg, .jpeg, .png, .svg, .gif"
                    />
                  </form>
                </div>

                {ThisImage?.data?.src && (
                  <div className="uploaded-image-area flex items-center justify-center rounded-lg py-overflow-hidden h-[220px] mt-3">
                    <img
                      className="max-w-full object-cover object-center bg-white/[.5] max-h-full"
                      src={ThisImage?.data?.src}
                      alt={ThisImage?.data?.name}
                    />
                  </div>
                )}

                <p className="mt-3">
                  Yüksek çözünürlüklü fotoğraflar, kompanent’in yüklenme hızını etkileyebilir. Fotoğrafları yüklemeden önce optimize
                  etmenizi tavsiye ederiz.
                </p>
              </div>

              <div className="w-full shrink-0 p-10">
                <div className="input-wrapper mb-8">
                  <ColorBox
                    title={"Arka Plan Rengi"}
                    name="backgroundColor"
                    value={ThisImage?.visual?.style?.backgroundColor || "#98A2B3"}
                    onChange={e => setSettings(e, false, ThisImage?.visual).then((res) => {
                      let stylized = [res].reduce((acc, value) => {
                        acc = {visual: {style: value?.style}}
                        delete acc?.style
                        return acc
                      }, null)
                      setThisImage({
                        ...ThisImage,
                        ...stylized,
                      })
                      setClientChanged(true)
                    })}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </PartialWrapper>
  )
}

export default AddOrEditMedia
