import {BUTTON, IMAGE, TEXT} from "../Enums";

const AddMediaListPattern = (model, thisComponent, mediaIdList, mediaType) => {
  console.log({
    model,
    thisComponent,
    mediaIdList,
  })

  if (!model || !Object.values(model)?.length) {
    return false
  }

  const isRelationData = model?.find((item) => item?.relation === "Data")
  const isRelationVisual = model?.find((item) => item?.relation === "Visual")
  const isRelationComponent = model?.find((item) => item?.relation === "Component")
  const translateItems = isRelationData?.translateItems
  const dataInput = isRelationData?.input
  let visualInput = isRelationVisual?.input
  let childrenInput = isRelationComponent?.Childs

  const getMediaIdByMediaIndex = index => {
    const subComponents = childrenInput[index]?.ChildStrategies?.find(child => child?.relation === "Component")
    const image = subComponents?.Childs.find(child => child?.name === IMAGE)
    const imageData = image?.ChildStrategies?.find(child => child?.relation === "Data")
    return imageData?.input?.mediaFileId
  }
  let entiredImage = 0
  const generateChildComponent = (childModel, childData) => {
    const childRelationData = childModel?.ChildStrategies?.find(strategy => strategy?.relation === "Data")
    const childRelationVisual = childModel?.ChildStrategies?.find(strategy => strategy?.relation === "Visual")
    let childDataInput = childRelationData.input
    const translateItems = childRelationData.translateItems
    const childVisualInput = childRelationVisual.input

    if (childData?.name === IMAGE){
      const mediaIdResult = mediaIdList.find(media => media?.sIndex === entiredImage)?.mediaId
      const isDeletedImage = mediaIdResult === undefined
      const isSameImage = mediaIdResult === null
      const mediaFileId = isDeletedImage ? 0 : (isSameImage ? getMediaIdByMediaIndex(entiredImage) : mediaIdResult)
      entiredImage++
      childDataInput = {
        ...childDataInput,
        mediaFileId
      }
    } else if (childData?.name === TEXT || childData?.name === BUTTON){
      childDataInput = {
        ...childDataInput,
      }
    } else {
      childDataInput = {
        ...childDataInput,
        ...childData?.data
      }
    }


    translateItems?.map(item => {
      if (item?.langKey === "TextLangKey"){
        item.name = (childData?.data?.text && childData?.data?.text?.length > 0) ? `${childData?.name} text` : null
        item.value = childData?.data?.text || ""
      }
    })


    const style = {
      ...childVisualInput.style,
      ...childData?.visual?.style,
    }

    const settings = {
      ...childVisualInput.settings,
      ...childData?.visual?.settings,
    }

    childVisualInput.style = style
    childVisualInput.settings = settings
    childRelationData.input = childDataInput


  }

  const medias = isRelationComponent?.Childs

  if (medias){
    medias?.map((media, sIndex) => {
      const thisMedia = thisComponent?.children[sIndex]
      const mediaRelationData = media?.ChildStrategies?.find(strategy => strategy?.relation === "Data")
      const mediaRelationVisual = media?.ChildStrategies?.find(strategy => strategy?.relation === "Visual")
      const mediaRelationComponent = media?.ChildStrategies?.find(strategy => strategy?.relation === "Component")
      const mediaDataInput = mediaRelationData.input
      const mediaVisualInput = mediaRelationVisual.input
      const mediaComponentInput = mediaRelationComponent.Childs

      mediaDataInput.categoryId = thisMedia?.data?.category?.categoryId

      const style = {
        ...mediaVisualInput.style,
        ...thisMedia?.visual?.style,
      }

      mediaVisualInput.style = style

      mediaComponentInput?.map((child, index) => {
        generateChildComponent(child, thisMedia?.children?.find((c, i) => child?.name === c?.name && index === i))
      })
    })
  }


  let title = thisComponent?.data?.title
  dataInput.titleStyle = thisComponent?.data?.titleStyle

  translateItems.map(item => {
    if (item?.langKey === "TitleLangKey"){
      item.value = title || ""
      item.name = "media list title" || ""
    }
  })


  visualInput = {
    ...visualInput,
    height: thisComponent?.visual?.height,
    width: thisComponent?.visual?.width,
    style: {
      ...visualInput?.style,
      ...thisComponent?.visual?.style
    },
    settings: {
      ...visualInput?.settings,
      ...thisComponent?.visual?.settings
    },
    paginationStyle: {
      ...visualInput?.paginationStyle,
      ...thisComponent?.visual?.paginationStyle,
    }
  }
  isRelationVisual.input = visualInput

  return model
}


export default AddMediaListPattern
