import { call, fork, put, takeLatest } from "redux-saga/effects"
import * as actions from "redux/actions/myReservationsList"
import { MY_RESERVATIONS_LIST_REQUESTED } from "redux/types"
import { API_POST } from "helpers/api"

function* watchMyReservations() {
  yield takeLatest(MY_RESERVATIONS_LIST_REQUESTED, getMyReservationslist)
}

function* getMyReservationslist(action) {
  try {
    const response = yield call(apiGetMyReservation, action.payload)
    const {
      data: {
        result: { data },
      },
    } = response

    const myReservationList = data.reduce((acc, value, index) => {
      acc.push({
        Id: index,
        ReservationNo: value.bookingCode,
        confirmationStatus: value.confirmationStatus,
        ConfirmStatus: value.confirmationStatusString,
        GuestName: value.customerName,
        ProductName: value.productName,
        RoomInfo: value.rooms[0]?.name,
        ReservationDate: value.bookingDate,
        CheckInDate: value.checkInDate,
        CheckOutDate: value.checkOutDate,
        CancellationDate: value.cancelDate,
        TotalPrice: Number(value.totalSale.substring(3)),
        Currency: value.totalSale.slice(0, 3),
        TotalNight: value.rooms[0]?.night,
        isNotificationSent: value.isNotificationSent,
      })
      return acc
    }, [])
    yield put(actions.myReservationsListSucceed(myReservationList))
  } catch (err) {
    yield put(actions.myReservationsListFailed())
  }
}

const apiGetMyReservation = (payload) => {
  return API_POST(`${process.env.REACT_APP_PANEL_DOMAIN}/api/reservation/list`, payload)
}

export default function* myReservationsList() {
  yield fork(watchMyReservations)
}
