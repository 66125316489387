import React, { useState } from "react"
import { Modal, Button, Grid, Row, Col, Uploader } from "rsuite"
import { Icon } from "components"
import { ReactComponent as UploadCloud } from "assets/svg/cloud-upload-inside-circle.svg"
import ExcelFile from "assets/files/tanimlar_sablon.xlsx"
import { useForm, Controller } from "react-hook-form"
import { SelectPicker } from "rsuite"
import { useDefinePropertyType } from "utils/useDefinePropertyType"
import { useImportDefine } from "utils/useDefines"
import { useDispatch, useSelector } from "react-redux"
/**
 * @param {*} data data of the modal
 * @param {boolean} open Visibility of the modal
 * @param {() => void} onClose on close callback
 * @returns
 */
const ImportModal = (props) => {
  const {
    reset,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      file: null,
    },
  })
  const { mutateAsync, isPending } = useImportDefine()
  const [uploadError, setUploadError] = useState("")
  const dispatch = useDispatch()
  const { filter } = useSelector((state) => state.define)
  const handleOnChangeStatus = (value) => {
    dispatch({
      type: "SET_FILTER",
      payload: {
        ...filter,
        status: null,
      },
    })
  }
  const onSubmit = (data) => {
    const formData = new FormData()
    formData.append("propertyId", data.type)
    formData.append("file", data.file[0].blobFile)

    mutateAsync(formData)
      .then((e) => {
        if (!e?.data?.Success) {
          setUploadError("Dosya yüklenirken bir hata oluştu, daha sonra tekrar deneyin.")
          return
        }
        handleOnChangeStatus()
        setUploadError("")
        handleClose()
        window.location.replace("/defines")
      })
      .catch(() => {
        setUploadError("Dosya yüklenirken bir hata oluştu, daha sonra tekrar deneyin.")
      })
  }

  const handleClose = () => {
    setUploadError("")
    props.onClose()
    reset()
  }
  const { data: propertyTypeList } = useDefinePropertyType()

  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        className="w-[392px] !mt-0 absolute top-1/2 left-1/2 transform !-translate-x-1/2 !-translate-y-1/2"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header
            closeButton={false}
            className="!px-1"
          >
            <div className="flex justify-between items-center">
              <p className="text-3xl font-semibold">İçe Aktar</p>
              <span
                className="p-1 !w-8 !h-8 !border !border-solid !border-gray-400 flex items-center rounded-md hover:bg-gray-50 cursor-pointer"
                onClick={handleClose}
              >
                <Icon
                  name="close"
                  className="!w-5 !h-5 mx-auto"
                />
              </span>
            </div>
          </Modal.Header>
          <Modal.Body className="text-left">
            <Grid fluid>
              <Row>
                <Col
                  xs={24}
                  className="mb-4"
                >
                  <p className="text-gray-800">Tanım Türü *</p>
                  <Controller
                    name="type"
                    control={control}
                    rules={{ required: "Tanım Türü seçiniz" }}
                    render={({ field: { onChange, value } }) => (
                      <SelectPicker
                        data={[
                          ...(propertyTypeList?.map((item) => ({
                            label: item.Name,
                            value: item.Id,
                          })) || []),
                        ]}
                        className="w-full "
                        searchable={false}
                        cleanable={true}
                        placeholder="Tanım Türü Seçiniz"
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                  {errors.type && <p className="text-red-500 text-xs mt-1">{errors.type.message}</p>}
                </Col>
                <Col xs={24}>
                  <p className="text-gray-600">İçe aktarmak için bir dosya yükleyin</p>
                </Col>
                <Col
                  xs={24}
                  className="my-5 text-sm"
                >
                  <a
                    className="text-blue-500 flex items-center cursor-pointer"
                    href={ExcelFile}
                    download="Tanımlar Yükleme Şablonu"
                  >
                    <Icon
                      name="download"
                      className="!w-4 !h-4 mr-1 !bg-blue-500"
                    />
                    Örnek dosyayı indir
                  </a>
                </Col>
                <Col xs={24}>
                  <Controller
                    name="file"
                    control={control}
                    rules={{
                      required: "Dosya Yükleyiniz",
                      validate: {
                        lessThan2MB: (value) => {
                          if (value[0].blobFile.size > 2097152) {
                            return "Dosya boyutu 2 MB'dan büyük olamaz."
                          }
                          return true
                        },
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <Uploader
                        autoUpload={false}
                        draggable
                        className="cursor-pointer"
                        name="file"
                        onChange={onChange}
                      >
                        <div
                          style={{
                            height: 200,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            textAlign: "center",
                            gap: 30,
                          }}
                        >
                          <UploadCloud />
                          <span className="text-blue-500 text-sm font-medium">
                            Yüklemek için tıklayın <span className="font-normal">ya da sürükleyip bırakın</span>
                          </span>
                          <span className="text-blue-500 text-xs">(maks. 2 MB) .xlsx formatında yükleyin.</span>
                        </div>
                      </Uploader>
                    )}
                  />
                  {errors.file && <p className="text-red-500 text-xs mt-1">{errors.file.message}</p>}
                </Col>
              </Row>
            </Grid>
          </Modal.Body>
          <Modal.Footer>
            <Grid fluid>
              <Row>
                {uploadError && (
                  <Col
                    xs={24}
                    className="text-left my-4"
                  >
                    <p className="text-red-500 text-sm">{uploadError}</p>
                  </Col>
                )}
                <Col xs={24}>
                  <Button
                    className="btn w-full hover:!text-white"
                    type="submit"
                    loading={isPending}
                  >
                    İçe Aktar
                  </Button>
                </Col>
                <Col
                  xs={24}
                  className="mt-4"
                >
                  <Button
                    className="btn w-full bg-blue-50 text-blue-500 border border-solid border-blue-50 hover:!text-white hover:!bg-blue-500 hover:!border-blue-500"
                    onClick={handleClose}
                    disabled={isPending}
                  >
                    Vazgeç
                  </Button>
                </Col>
              </Row>
            </Grid>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

const useImportModal = () => {
  const [open, setOpen] = useState(false)
  const [data, setData] = useState(null)
  /**
   * @param {*} data
   */
  const handleOpen = (data) => {
    setData(data)
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  return {
    open,
    data,
    handleOpen,
    onClose,
  }
}

export { useImportModal }
export default ImportModal
