import * as type from "redux/types"

// Get Category List
export const categoryListRequested = (data) => ({ type: type.CATEGORY_LIST_REQUESTED, payload: data })
export const categoryListSucceed = (data) => ({ type: type.CATEGORY_LIST_SUCCEEDED, payload: data })
export const categoryListFailed = () => ({ type: type.CATEGORY_LIST_FAILED })

// Get Category
export const categoryGetRequested = (data) => ({ type: type.CATEGORY_GET_REQUESTED, payload: data })
export const categoryGetSucceed = (data) => ({ type: type.CATEGORY_GET_SUCCEEDED, payload: data })
export const categoryGetFailed = () => ({ type: type.CATEGORY_GET_FAILED })

// Save Category
export const categorySaveRequested = (data) => ({ type: type.CATEGORY_SAVE_REQUESTED, payload: data })
export const categorySaveSucceed = (data) => ({ type: type.CATEGORY_SAVE_SUCCEEDED, payload: data })
export const categorySaveFailed = () => ({ type: type.CATEGORY_SAVE_FAILED })

// Update Category
export const categoryUpdateRequested = (data) => ({ type: type.CATEGORY_UPDATE_REQUESTED, payload: data })
export const categoryUpdateSucceed = (data) => ({ type: type.CATEGORY_UPDATE_SUCCEEDED, payload: data })
export const categoryUpdateFailed = () => ({ type: type.CATEGORY_UPDATE_FAILED })

// Delete Category
export const categoryDeleteRequested = (data) => ({ type: type.CATEGORY_DELETE_REQUESTED, payload: data })
export const categoryDeleteSucceed = (data) => ({ type: type.CATEGORY_DELETE_SUCCEEDED, payload: data })
export const categoryDeleteFailed = () => ({ type: type.CATEGORY_DELETE_FAILED })

// Get Components List
export const componentsListRequested = (data) => ({ type: type.COMPONENTS_LIST_REQUESTED, payload: data })
export const componentsListSucceed = (data) => ({ type: type.COMPONENTS_LIST_SUCCEEDED, payload: data })
export const componentsListFailed = () => ({ type: type.COMPONENTS_LIST_FAILED })

// Get Components List
export const addAtomPageRequested = (data) => ({ type: type.ADD_ATOM_PAGE_REQUESTED, payload: data })
export const addAtomPageSucceed = (data) => ({ type: type.ADD_ATOM_PAGE_SUCCEEDED, payload: data })
export const addAtomPageFailed = () => ({ type: type.ADD_ATOM_PAGE_FAILED })
