/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import GoogleMap from "google-map-react"

import { SelectPicker } from "rsuite"
import localization from "localization"

import { citiesRequested, countriesRequested, districtsRequested } from "redux/actions/destinations"
import { saveUserSettingsRequested } from "redux/actions/saveUserSettings"
import { getSettingsRequested } from "redux/actions/getSettings"

import { getPropertyValueByName, getPropertyById } from "utils/propertyUtils"

import Logo from "assets/img/logo.png"

const Contact = ({ getSettingsData, setSelectedContent, setLoading }) => {
  const dispatch = useDispatch()

  // selectors
  const countries = useSelector((state) => state.destinations.countries)
  const countriesLoading = useSelector((state) => state.destinations.countriesLoading)
  const cities = useSelector((state) => state.destinations.cities)
  const citiesLoading = useSelector((state) => state.destinations.citiesLoading)
  const districts = useSelector((state) => state.destinations.districts)
  const districtsLoading = useSelector((state) => state.destinations.districtsLoading)

  //refs
  const countryWrapper = useRef()
  const cityWrapper = useRef()
  const districtWrapper = useRef()

  // states
  const [ContactValues, setContactValues] = useState([])
  const [InputValues, setInputValues] = useState({})
  const [Countries, setCountries] = useState([])
  const [Cities, setCities] = useState([])
  const [Districts, setDistricts] = useState([])
  const [ActiveCountry, setActiveCountry] = useState()
  const [ActiveCity, setActiveCity] = useState()
  const [ActiveDistricts, setActiveDistricts] = useState()
  const [MapsCoords] = useState({
    lng: 27.267141,
    lat: 37.818547,
  })
  const [SelectedValues, setSelectedValues] = useState({
    country: "",
    city: "",
    district: "",
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setInputValues((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const saveAndPublish = () => {
    const { phone, fax, email, call_center_phone, call_center_information, address } = InputValues
    const { country, city, district } = SelectedValues

    const phoneValue = phone
    const faxValue = fax
    const emailValue = email
    const callCenterPhoneValue = call_center_phone
    const callCenterInfoValue = call_center_information

    const properties = [
      {
        PropertyId: getPropertyById(ContactValues, "Telefon"),
        PropertyValue: phoneValue || "",
        Status: phoneValue ? 1 : 0 ,
      },

      {
        PropertyId: getPropertyById(ContactValues, "Fax"),
        PropertyValue: faxValue || "",
        Status: faxValue ? 1 : 0 ,
      },

      {
        PropertyId: getPropertyById(ContactValues, "Email"),
        PropertyValue: emailValue || "",
        Status: emailValue ? 1 : 0 ,
      },

      {
        PropertyId: getPropertyById(ContactValues, "Çağrı Merkezi"),
        PropertyValue: callCenterPhoneValue || "",
        Status: callCenterPhoneValue ? 1 : 0 ,
      },

      {
        PropertyId: getPropertyById(ContactValues, "Çağrı Merkezi Bilgilendirme"),
        PropertyValue: callCenterInfoValue || "",
        Status: callCenterInfoValue ? 1 : 0 ,
      },

      {
        PropertyId: getPropertyById(ContactValues, "Ülke"),
        PropertyValue: country || "",
        Status: country ? 1 : 0 ,
      },

      {
        PropertyId: getPropertyById(ContactValues, "Şehir"),
        PropertyValue: city || "",
        Status: city ? 1 : 0 ,
      },

      {
        PropertyId: getPropertyById(ContactValues, "İlçe"),
        PropertyValue: district || "",
        Status: district ? 1 : 0 ,
      },

      {
        PropertyId: getPropertyById(ContactValues, "Adres"),
        PropertyValue: address || "",
        Status: address ? 1 : 0 ,
      },

    ]

    dispatch(saveUserSettingsRequested({ properties }))

    setSelectedContent(false)
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
      dispatch(getSettingsRequested())
    }, 2000)
  }

  useEffect(() => {
    if (getSettingsData) {
      const filteredData = getSettingsData?.Result?.filter((i) => i.SettingName === "İletişim Bilgileri")
      setContactValues(filteredData)
    }
  }, [getSettingsData])

  useEffect(() => {
    setInputValues({
      phone: getPropertyValueByName(ContactValues, "Telefon"),
      fax: getPropertyValueByName(ContactValues, "Fax"),
      email: getPropertyValueByName(ContactValues, "Email"),
      call_center_phone: getPropertyValueByName(ContactValues, "Çağrı Merkezi"),
      call_center_information: getPropertyValueByName(ContactValues, "Çağrı Merkezi Bilgilendirme"),
      address: getPropertyValueByName(ContactValues, "Adres"),
    })
    setSelectedValues({
      country: getPropertyValueByName(ContactValues, "Ülke"),
      city: getPropertyValueByName(ContactValues, "Şehir"),
      district: getPropertyValueByName(ContactValues, "İlçe"),
    })
  }, [ContactValues])

  useEffect(() => {
    dispatch(countriesRequested())
  }, [dispatch])

  useEffect(() => {
    if (countries.list.length && !ActiveCountry) {
      const countryValue = JSON.parse(countries.list).find(
        (item) => item.label === getPropertyValueByName(ContactValues, "Ülke"),
      )?.value
      setActiveCountry(countryValue)
      setCountries(JSON.parse(countries.list))
    }
    if (cities.list.length && !ActiveCity) {
      const cityValue = JSON.parse(cities.list).find(
        (item) => item.label === getPropertyValueByName(ContactValues, "Şehir"),
      )?.value
      setActiveCity(cityValue)
      setCities(JSON.parse(cities.list))
    }
    if (districts.list.length && !ActiveDistricts) {
      const districtValue = JSON.parse(districts.list).find(
        (item) => item.label === getPropertyValueByName(ContactValues, "İlçe"),
      )?.value
      setActiveDistricts(districtValue)
      setDistricts(JSON.parse(districts.list))
    }
  }, [countries, cities, districts])

  useEffect(() => {
    if (ActiveCountry) {
      dispatch(citiesRequested(ActiveCountry))
    }
  }, [ActiveCountry])

  useEffect(() => {
    if (ActiveCity) {
      dispatch(districtsRequested(ActiveCity))
    }
  }, [ActiveCity])

  return (
    <div className="contact-area grid sm:grid-cols-2 gap-4">
      <div className="col mb-6 last:mb-0">
        <p className="text-sm font-semibold pb-0.5">Telefon</p>

        <div className="text-gray-500">
          <div className="mt-1.5">
            <input
              type="tel"
              name="phone"
              className="w-full"
              value={InputValues?.phone}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div className="col mb-6 last:mb-0">
        <p className="text-sm font-semibold pb-0.5">Faks</p>

        <div className="mt-1.5">
          <input
            type="tel"
            name="fax"
            className="w-full"
            value={InputValues?.fax}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="col mb-6 last:mb-0">
        <p className="text-sm font-semibold pb-0.5">Çağrı Merkezi</p>

        <div className="mt-1.5">
          <input
            type="tel"
            name="call_center_phone"
            className="w-full"
            value={InputValues?.call_center_phone}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="col mb-6 last:mb-0">
        <p className="text-sm font-semibold pb-0.5">E-posta</p>

        <div className="mt-1.5">
          <input
            type="email"
            name="email"
            className="w-full"
            value={InputValues?.email}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="col mb-6 col-span-2 last:mb-0">
        <p className="text-sm font-semibold pb-0.5">Çağrı Merkezi Bilgilendirme</p>

        <div className="mt-1.5">
          <textarea
            name="call_center_information"
            className="w-full h-auto"
            value={InputValues?.call_center_information}
            rows="6"
            onChange={handleChange}
          />
        </div>

        <p className="text-gray-500 text-sm mt-1.5">
          Çağrı merkezinizin çalışma saatleri ve günleri ile ilgili bilgi verebilirsiniz.
        </p>
      </div>

      <div className="col mb-6 last:mb-0">
        <p className="text-sm font-semibold pb-0.5">Ülke</p>
        <div className="mt-1.5">
          <div
            ref={countryWrapper}
            className="country-wrapper relative"
          >
            <SelectPicker
              block
              placement="bottomStart"
              placeholder={""}
              value={ActiveCountry}
              loading={!!countriesLoading}
              locale={localization?.TR?.PickerLocaleType}
              menuMaxHeight={164}
              cleanable={false}
              data={Countries}
              searchable={Countries.length > 7}
              container={countryWrapper.current}
              onSelect={(value, item) => {
                setSelectedValues((prev) => ({
                  ...prev,
                  country: item.label,
                  city: "",
                  district: "",
                }))
                setActiveDistricts(null)
                setActiveCity(null)
                setActiveCountry(value)
              }}
            />
          </div>
        </div>
      </div>

      <div className="col mb-6 last:mb-0">
        <p className="text-sm font-semibold pb-0.5">Şehir</p>
        <div className="mt-1.5">
          <div
            ref={cityWrapper}
            className="city-wrapper relative"
          >
            <SelectPicker
              block
              placement="bottomStart"
              placeholder={ActiveCountry ? "" : "Önce ülke seçiniz"}
              loading={!!citiesLoading}
              value={ActiveCity}
              className={ActiveCountry ? "" : "pointer-events-none opacity-30"}
              locale={localization?.TR?.PickerLocaleType}
              menuMaxHeight={164}
              cleanable={false}
              data={Cities}
              searchable={Cities.length > 7}
              container={cityWrapper.current}
              onSelect={(value, item) => {
                setSelectedValues((prev) => ({
                  ...prev,
                  city: item.label,
                  district: "",
                }))
                setActiveDistricts(null)
                setActiveCity(value)
              }}
            />
          </div>
        </div>
      </div>

      <div className="col mb-6 last:mb-0">
        <p className="text-sm font-semibold pb-0.5">İlçe</p>
        <div className="mt-1.5">
          <div
            ref={districtWrapper}
            className="district-wrapper relative"
          >
            <SelectPicker
              block
              placement="bottomStart"
              placeholder={ActiveCity ? "" : "Önce şehir seçiniz"}
              loading={!!districtsLoading}
              className={ActiveCity ? "" : "pointer-events-none opacity-30"}
              value={ActiveDistricts}
              locale={localization?.TR?.PickerLocaleType}
              menuMaxHeight={164}
              cleanable={false}
              data={Districts}
              searchable={Districts.length > 7}
              container={districtWrapper.current}
              onSelect={(value, item) => {
                setSelectedValues((prev) => ({
                  ...prev,
                  district: item.label,
                }))
                setActiveDistricts(value)
              }}
            />
          </div>
        </div>
      </div>

      <div className="col mb-6 col-span-2 last:mb-0">
        <p className="text-sm font-semibold pb-0.5">Adres</p>

        <div className="mt-1.5">
          <textarea
            name="address"
            className="w-full h-auto"
            value={InputValues?.address}
            rows="6"
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="col mb-6 col-span-2 last:mb-0">
        <div className="map-box">
          {MapsCoords.lat && (
            <div style={{ height: "300px", width: "100%" }}>
              <GoogleMap
                bootstrapURLKeys={{ key: "AIzaSyCYRTHPzopuYqCowEbOGQquCwZxfhrzhpc" }}
                defaultCenter={MapsCoords}
                yesIWantToUseGoogleMapApiInternals
                defaultZoom={11}
              >
                <Marker
                  lat={MapsCoords.lat}
                  lng={MapsCoords.lng}
                >
                  <img
                    src={Logo}
                    className="w-full h-full object-cover"
                    alt="my-hotel-logo"
                  />
                </Marker>
              </GoogleMap>
            </div>
          )}
        </div>
      </div>

      <div className="col mb-6 col-span-2 last:mb-0">
        <div className="button-area flex justify-end gap-4">
          <button
            onClick={() => setSelectedContent(false)}
            className={`btn outline`}
          >
            Vazgeç
          </button>
          <button
            onClick={saveAndPublish}
            className="btn"
          >
            Kaydet ve Yayınla
          </button>
        </div>
      </div>
    </div>
  )
}

const Marker = ({ children }) => (
  <div
    style={{
      backgroundColor: "white",
      width: "30px",
      height: "30px",
      padding: "5px",
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "100%",
      transform: "translate(-50%, -50%)",
    }}
  >
    {children}
  </div>
)

export default Contact
