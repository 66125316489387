import React from "react"
import Cookies from "universal-cookie"

import { TitleH1 } from "components"
import Icon from "components/Icon"
import evaPreview from "assets/svg/eva-preview.svg"

const cookies = new Cookies()

const HomePage = (props) => {
  let { StepsGoTo } = props

  const userName = cookies.get("userInfo")?.name

  return (
    <div className="setup-area flex flex-col lg:flex-row h-full overflow-auto lg:overflow-hidden">
      <div className="lg:w-1/2 flex items-center justify-center border-t lg:border-r lg:border-t-0 border-b lg:border-b-0 border-gray-200 py-20 lg:bg-white lg:overflow-auto">
        <div className="lg:w-[504px] max-w-[90%]">
          <TitleH1 className="mb-4">Hoş geldin {userName}!</TitleH1>

          <div className="text-area">
            <p className="text-gray-500 text-sm lg:text-lg">
              Rezervasyon almaya başlamak için kurulum adımlarını tamamlamalısın.
              <br />
              <br />
              Bu aşamada yaptığın tüm ayarları istediğin zaman yeniden düzenleyebillirsin.
            </p>
          </div>

          <button
            onClick={() => {
              window.history.pushState("", "", "/setup/displaySettings")
              StepsGoTo(1)
            }}
            className="btn sm mt-4 lg:mt-8"
          >
            <span>Kurulumu Başlat</span>
            <Icon
              name="arrow-right"
              size="20"
              color="white"
            />
          </button>
        </div>
      </div>

      <figure className="flex lg:w-1/2 items-center justify-center xl:p-28 p-20">
        <img
          src={evaPreview}
          alt="HomePage Preview"
        />
      </figure>
    </div>
  )
}

export default HomePage
