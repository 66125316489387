import React, { useState } from "react"
import { Modal, Button, Grid, Row, Col, Uploader } from "rsuite"
import { Icon } from "components"
import { ReactComponent as UploadCloud } from "assets/svg/cloud-upload-inside-circle.svg"
import ExcelFile from "assets/files/user-import-template.xlsx"
import { useUserImport } from "utils/useUserImport"
/**
 * @param {*} data data of the modal
 * @param {boolean} open Visibility of the modal
 * @param {() => void} onClose on close callback
 * @returns
 */
const ImportModal = (props) => {
  const [file, setFile] = useState([])
  const [error, setError] = useState("")
  const { mutateAsync, isPending } = useUserImport()

  const handleImport = async () => {
    if (file.length === 0) {
      setError("Lütfen bir dosya yükleyin.")
      return
    }
    const formData = new FormData()
    formData.append("file", file[0].blobFile)
    await mutateAsync(formData)
      .then((e) => {
        if (e?.data?.errors?.[0]?.message) {
          setError(e?.data?.errors?.[0]?.message)
          return
        }
        props.onClose()
      })
      .catch(() => {
        setError("Dosya yüklenirken bir hata oluştu, lütfen tekrar deneyin.")
      })
  }

  return (
    <>
      <Modal
        open={props.open}
        onClose={props.onClose}
        className="w-[392px] !mt-0 absolute top-1/2 left-1/2 transform !-translate-x-1/2 !-translate-y-1/2"
      >
        <Modal.Header
          closeButton={false}
          className="!px-1"
        >
          <div className="flex justify-between items-center">
            <p className="text-3xl font-semibold">İçe Aktar</p>
            <span
              className="p-1 !w-8 !h-8 !border !border-solid !border-gray-400 flex items-center rounded-md hover:bg-gray-50 cursor-pointer"
              onClick={props.onClose}
            >
              <Icon
                name="close"
                className="!w-5 !h-5 mx-auto"
              />
            </span>
          </div>
        </Modal.Header>
        <Modal.Body className="text-left">
          <Grid fluid>
            <Row>
              <Col xs={24}>
                <p className="text-gray-600">İçe aktarmak için bir dosya yükleyin</p>
              </Col>
              <Col
                xs={24}
                className="my-5 text-sm"
              >
                <a
                  className="text-blue-500 flex items-center cursor-pointer"
                  href={ExcelFile}
                  download="Yeni Kullanıcı Yükleme Şablonu"
                >
                  <Icon
                    name="download"
                    className="!w-4 !h-4 mr-1 !bg-blue-500"
                  />
                  Örnek dosyayı indir
                </a>
              </Col>

              <Col xs={24}>
                <Uploader
                  autoUpload={false}
                  draggable
                  className="cursor-pointer"
                  onChange={(e) => {
                    const file = e?.[e.length - 1]
                    if (!file) setFile([])
                    else setFile([e?.[e.length - 1]])
                  }}
                  fileList={file}
                >
                  <div>
                    <div
                      style={{
                        height: 200,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        textAlign: "center",
                        gap: 30,
                      }}
                    >
                      <UploadCloud />
                      <span className="text-blue-500 text-sm font-medium">
                        Yüklemek için tıklayın <span className="font-normal">ya da sürükleyip bırakın</span>
                      </span>
                      <span className="text-blue-500 text-xs">(maks. 2 MB) .xlsx formatında yükleyin.</span>
                    </div>
                  </div>
                </Uploader>
              </Col>
            </Row>
          </Grid>
        </Modal.Body>
        <Modal.Footer>
          <Grid fluid>
            <Row>
              {error && (
                <Col
                  xs={24}
                  className="text-left py-4"
                >
                  <p className="text-red-500 text-sm">{error}</p>
                </Col>
              )}
              <Col xs={24}>
                <Button
                  className="btn w-full hover:!text-white"
                  onClick={handleImport}
                  loading={isPending}
                >
                  İçe Aktar
                </Button>
              </Col>
              <Col
                xs={24}
                className="mt-4"
              >
                <Button
                  className="btn w-full bg-blue-50 text-blue-500 border border-solid border-blue-50 hover:!text-white hover:!bg-blue-500 hover:!border-blue-500"
                  onClick={() => {
                    props.onClose()
                  }}
                  disabled={isPending}
                >
                  Vazgeç
                </Button>
              </Col>
            </Row>
          </Grid>
        </Modal.Footer>
      </Modal>
    </>
  )
}

const useImportModal = () => {
  const [open, setOpen] = useState(false)
  const [data] = useState(null)
  /**
   * @param {*} data
   */
  const handleOpen = (data) => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  return {
    open,
    data,
    handleOpen,
    onClose,
  }
}

export { useImportModal }
export default ImportModal
