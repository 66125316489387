export default function lcfc(obj) {
  const newObj = {}
  if (!obj) {
    return obj
  }
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
      newObj[key.charAt(0).toLowerCase() + key.slice(1)] = lcfc(obj[key])
      return
    }
    if (typeof obj[key] === "object" && Array.isArray(obj[key])) {
      newObj[key.charAt(0).toLowerCase() + key.slice(1)] = obj[key].map((item) => {
        return lcfc(item)
      })
      return
    }
    newObj[key.charAt(0).toLowerCase() + key.slice(1)] = obj[key]
  })
  return newObj
}
