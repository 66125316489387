import React, { useCallback, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import Header from "components/Headers/MainHeader"
import { Grid, Row, Col, Stack, Divider, Badge } from "rsuite"
import { Icon } from "components"
import DefineDeleteModal, { useDefineDeleteModal } from "components/Defines/DefineDeleteModal"
import { useDefineById } from "utils/useDefines"

const DefineDetail = (props) => {
  let { isLogged } = props
  const navigate = useNavigate()
  const { id } = useParams()
  const { open, handleOpen, data: modalData, ...modalProps } = useDefineDeleteModal({
    onSuccess: () => navigate("/defines"),
  })
  const { data, isLoading } = useDefineById(id)

  const handleOnNavigate = useCallback((path = "") => navigate("/defines/" + path), [navigate])

  const handleOnDelete = useCallback(() => {
    handleOpen(data)
  }, [data, handleOpen])

  useEffect(() => {
    if (!isLoading && !data) {
      handleOnNavigate()
    }
  }, [data, handleOnNavigate, isLoading])

  return (
    <>
      <Header isLogged={isLogged} />
      <main>
        <section className="p-10 my-0 mx-auto max-w-[778px]">
          <span
            className="!text-gray-600 flex justify-start items-center gap-2 mt-2 mb-8 cursor-pointer w-fit transition-all hover:drop-shadow"
            onClick={() => handleOnNavigate()}
          >
            <Icon
              name="arrow-left"
              size="20"
              className="!bg-gray-500"
            />
            <h4>Tanımlar</h4>
          </span>
          <Grid fluid>
            <Row gutter={10}>
              <Col
                xs={24}
                xl={16}
                className="my-2"
              >
                <h1 className="text-3xl text-gray-800 font-semibold mb-1">Tanım Detayı</h1>
              </Col>
              <Col
                xs={24}
                xl={8}
                className="my-2 flex justify-end items-center"
              >
                <Stack spacing={10}>
                  <div
                    className="btn border border-red-300 bg-white text-red-500 hover:bg-red-500 hover:text-white hover:border-red-500"
                    onClick={handleOnDelete}
                  >
                    Sil
                  </div>
                  <div
                    className="btn"
                    onClick={() => handleOnNavigate(data.PropertyValueId + "/edit")}
                  >
                    Düzenle
                  </div>
                </Stack>
              </Col>
              <Col xs={24}>
                <div className="w-full my-3 p-7 rounded-lg border border-gray-200 bg-white">
                  <Grid fluid>
                    <Row gutter={10}>
                      <Col
                        xs={24}
                        className="mb-10"
                      >
                        <p className="font-medium text-sm">Tanım Türü</p>
                        <p className="text-lg text-gray-500">{data?.PropertyName}</p>
                      </Col>
                      <Col xs={12}>
                        <p className="font-medium text-sm">Tanım Adı</p>
                        <p className="text-lg text-gray-500">{data?.PropertyValue}</p>
                      </Col>
                      <Col xs={12}>
                        <p className="font-medium text-sm">Statü</p>
                        <span className="text-lg text-gray-500">
                          {data?.PropertyValueStatus === 1 ? (
                            <Badge
                              className="p-1 px-2 rounded-xl bg-green-100 text-green-500 font-semibold"
                              content="Aktif"
                            />
                          ) : (
                            <Badge
                              className="p-1 px-2 rounded-xl bg-red-100 text-red-500 font-semibold"
                              content="Pasif"
                            />
                          )}
                        </span>
                      </Col>
                      <Col
                        xs={24}
                        className="mb-2 mt-5"
                      >
                        <Divider />
                      </Col>
                      <Col xs={24}>
                        <p>Açıklama</p>
                        <p className="text-lg text-gray-500">{data?.PropertyValueDescription || "-"}</p>
                      </Col>
                    </Row>
                  </Grid>
                </div>
              </Col>
            </Row>
          </Grid>
        </section>
      </main>
      <DefineDeleteModal
        {...modalProps}
        open={open}
        data={modalData}
      />
    </>
  )
}

export default DefineDetail
