import * as type from "../types"

export const listConfigurationRequested = (data) => ({
  type: type.LIST_CONFIGURATON_REQUESTED,
  payload: data,
})

export const listConfigurationSucceed = (data) => ({
  type: type.LIST_CONFIGURATON_SUCCEEDED,
  payload: data,
})

export const listConfigurationFailed = () => ({
  type: type.LIST_CONFIGURATON_SUCCEEDED,
})
