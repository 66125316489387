import React from "react"

import Icon from "components/Icon"
import banner404 from "assets/img/404-banner.png"

const Mobile404 = (props) => {
  let { data, logo, fontFamily } = props
  let { mail = data?.domainProducts[0]?.email, phone = data?.domainProducts[0]?.phone } = data

  return (
    <div
      className="w-[200px] h-[330px] text-[6px] border-4 rounded-2xl bg-white"
      style={{ fontFamily: fontFamily }}
    >
      <div className="flex flex-col h-full gap-4 rounded-2xl">
        <div className="py-3 p-5 my-auto">
          <div className="w-full bg-white pb-4 flex items-center justify-center">
            <img
              className="h-6"
              alt="logo"
              src={logo}
            />
          </div>
          <div className="flex flex-col text-center">
            <h3 className="text-gray-900 text-sm font-semibold">Hemen dönüyoruz!</h3>
            <p className="text-[8px]">şu anda sitemiz bakım aşamasında</p>
            <div className="mt-7">
              <img
                className="h-[80px] w-full object-cover"
                alt="logo"
                src={banner404}
              />
            </div>
            {/* <div className="mt-2 flex flex-col">
              <p className="text-[8px]">Döndüğümüzde ilk senin haberin olsun!</p>
              <div className="flex flex-col w-full gap-2 mt-2">
                <p className="border border-gray-200 text-[8px] py-1 pl-2 pr-12 rounded">Email adresi girin</p>
                <button
                  className="text-white text-[8px] py-1 px-2 rounded"
                  style={{ backgroundColor: color }}
                >
                  Bana Bildir
                </button>
              </div>
            </div> */}
          </div>
          <div className="flex flex-wrap items-end gap-2 pt-5">
            <div className="flex items-center gap-1">
              <Icon
                className=""
                name="headphones"
                size="8"
                color="#667085"
              />
              <p className="text-[6px]">{phone}</p>
            </div>
            <div className="flex items-center gap-1">
              <Icon
                className=""
                name="mail"
                size="8"
                color="#667085"
              />
              <p className="text-[6px]">{mail}</p>
            </div>
            {/* <div className="flex items-center gap-1">
              <Icon
                className=""
                name="instagram"
                size="8"
                color="#667085"
              />
              <p className="text-[6px]">instagram</p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Mobile404
