import React from "react"

import EvaPicture from "assets/svg/eva.svg"

const backgroundIcons = () => {
  return (
    <>
      <div className="absolute w-full min-h-[1024px] bg-icons opacity-10 blur-2xl z-[-1]">
        <img
          className="absolute left-[-351px] top-[-438px] w-[704px] h-[704px]"
          src={EvaPicture}
          alt={""}
        />
        <img
          className="absolute right-[9%] top-[80px] w-[278px] h-[278px]"
          src={EvaPicture}
          alt={""}
        />
        <img
          className="absolute left-[20%] bottom-[-52px] w-[456px] h-[456px]"
          src={EvaPicture}
          alt={""}
        />
      </div>
    </>
  )
}

export default backgroundIcons
