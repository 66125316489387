/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect } from "react"
import { useFormContext, Controller } from "react-hook-form"
import { Toggle } from "rsuite"
import LogoUpload from "components/LogoUpload"
import { Alert, Icon } from "components"
import { useMediaLibraryList, useSaveMediaLibraryItem } from "services/wl-display/mediaLibrary/mediaLibrary"

const SocialMediaFormItem = ({ title, name, active, link, logo }) => {
  const { control, setValue } = useFormContext()
  const [Ids, setIds] = useState(null)
  const [showAlert, setShowAlert] = useState(false)
  const [ThemeConfig, setThemeConfig] = useState({})
  const [currentLogo, setCurrentLogo] = useState(logo)
  const [changes, setChanges] = useState({
    isActiveChanged: false,
    isLinkChanged: false,
    isLogoChanged: false,
  })

  // const hostNames = ["localhost:5000", "my-eva.gordios.dev", "my-eva.gordios.ws"].includes(window.location.host)

  // const logoSourcePath = `https://${
  //   hostNames ? "gordiosdatatest.blob.core.windows.net/files/" : "cdng.jollytur.com/files/"
  // }${currentLogo}`

  const saveMediaMutation = useSaveMediaLibraryItem()
  const { data: mediaList } = useMediaLibraryList(Ids)

  const setConfig = useCallback(
    (config) => {
      if (config) {
        setThemeConfig((prev) => ({
          ...prev,
          displaySettings: {
            ...prev.displaySettings,
            ...config,
          },
        }))
        const formData = new FormData()
        const logoFile = config?.logoValues?.fileForBinary
        formData.append("File", logoFile)
        saveMediaMutation.mutate(formData, {
          onSuccess: (data) => {
            const Ids = [data?.data?.result?.id]
            setIds(Ids)
            const newLogoPath = mediaList?.data?.[0]?.path
            setValue(`${name}.logo`, newLogoPath)
            setCurrentLogo(newLogoPath)
            setChanges((prev) => ({ ...prev, isLogoChanged: true }))
          },
        })
      }
    },
    [saveMediaMutation, mediaList, setValue, name],
  )

  useEffect(() => {
    setCurrentLogo(logo)
    if (!changes.isActiveChanged) {
      setValue(`${name}.active`, active === "True")
    }
    if (!changes.isLinkChanged) {
      setValue(`${name}.link`, link)
    }
    if (!changes.isLogoChanged) {
      setValue(`${name}.logo`, logo)
    }
  }, [])

  return (
    <div className="flex flex-col gap-4 border border-gray-200 rounded-lg p-4 h-[209px]">
      <div className="flex justify-between items-center">
        <span className="text-lg text-gray-800 font-medium">{title}</span>
        <Controller
          name={`${name}.active`}
          control={control}
          defaultValue={active === "True"}
          render={({ field }) => (
            <Toggle
              {...field}
              checked={field.value}
              onChange={(value) => {
                field.onChange(value)
                setChanges((prev) => ({ ...prev, isActiveChanged: true }))
              }}
            />
          )}
        />
      </div>
      <div className="flex flex-col gap-2">
        <span className="text-gray-800 font-medium">Bağlantı linki</span>
        <Controller
          name={`${name}.link`}
          control={control}
          defaultValue={link}
          render={({ field }) => (
            <input
              type="text"
              defaultValue={link}
              onChange={(e) => {
                field.onChange(e.target.value)
                setChanges((prev) => ({ ...prev, isLinkChanged: true }))
              }}
              placeholder="Bağlantı linkini ekleyin"
            />
          )}
        />
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex items-center gap-[6px]">
          {currentLogo ? (
            <div className="flex justify-between items-center w-full border rounded-lg border-gray-200 p-3">
              <div className="flex items-center gap-1">
                <Icon
                  size={"16"}
                  name={name}
                  color={"#2E90FA"}
                />
                <span className="font-medium text-sm">{name}.png</span>
              </div>
              <span
                onClick={() => setShowAlert(!showAlert)}
                className="text-[#F04438] font-medium text-sm cursor-pointer"
              >
                Kaldır
              </span>
            </div>
          ) : (
            <>
              <Icon
                size={"16"}
                name="upload-cloud"
                color={"#2E90FA"}
              />
              <span
                onClick={() => setShowAlert(!showAlert)}
                className="text-[#2E90FA] font-medium cursor-pointer"
              >
                Özel ikon yükle
              </span>
            </>
          )}
        </div>
        {showAlert && (
          <Alert
            title="Sosyal Medya İkonu Yükle"
            rejectButtonText="Vazgeç"
            approveButtonText="Kaydet"
            rejectButtonOnClick={() => setShowAlert(!showAlert)}
            approveButtonOnClick={() => {
              setShowAlert(!showAlert)
            }}
            iconName=""
            className="w-[450px]"
          >
            <Controller
              name={`${name}.logo`}
              control={control}
              defaultValue={logo}
              render={({ field }) => (
                <LogoUpload
                  accept={".jpg, .png"}
                  title={false}
                  showText="false"
                  favicon={false}
                  config={{ ThemeConfig }}
                  hasTitle={false}
                  setConfig={(config) => setConfig(config)}
                />
              )}
            />
          </Alert>
        )}
      </div>
    </div>
  )
}

export default SocialMediaFormItem
