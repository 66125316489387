import CommonAddLayout from "pages/Rules/components/FormLayouts/CommonAddLayout"
import React from "react"
import RuleForm from "pages/Rules/components/FormLayouts/RuleForm"

const AddReservationRule = (props) => {
  return (
    <CommonAddLayout
      {...props}
      title="Rezervasyon Kuralı Ekle"
      description="Ürününüzde çalıştırmak üzere sisteme rezervasyon kuralı tanımlayın."
    >
      <RuleForm {...RuleForm.PageMeta.reservationRule} />
    </CommonAddLayout>
  )
}

export default AddReservationRule
