import FormDestinationPicker from "../FormItems/FormDestinationPicker"
import { FormNumericInput, FormSelectPicker, FormAutoSearchCheckPicker, FormStaticSearchCheckPicker } from "../FormItems/index"

/**
 * @description You can check the serialize format from FormToDataSerializer.js file when you add new item. !IMPORTANT
 */
const DynamicFormItems = {
  NumericParameter: (props) => <FormNumericInput {...props} />,
  ListParameter: (props) => <FormSelectPicker {...props} />,
  EqualityListParameter: (props) => <FormSelectPicker {...props} />,
  BooleanListParameter: (props) => <FormSelectPicker {...props} />,
  ArrayParameter: (props) => ArrayParameterDetection(props),
  DestinationParameter: (props) => <FormDestinationPicker {...props} />,
}

const ArrayParameterDetection = (props) => {
  if (props?.item?.Data?.isMultiple === "false") {
    return <FormSelectPicker {...props} />
  } else if (props?.item?.Data?.isAutocomplete) {
    return <FormAutoSearchCheckPicker {...props} />
  } else {
    return <FormStaticSearchCheckPicker {...props} />
  }
}

export default DynamicFormItems
