import React from "react"
import MainHeader from "components/Headers/MainHeader"
import RuleDataList from "./components/RuleDataList"

const Rules = (props) => {
  let { isLogged } = props
  return (
    <>
      <MainHeader isLogged={isLogged} />
      <main>
        <section className="p-10 my-0 mx-auto max-w-[1920px] overflow-auto">
          <RuleDataList />
        </section>
      </main>
    </>
  )
}

Rules.propTypes = {}

Rules.defaultProps = {}

export default Rules
