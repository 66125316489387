import * as type from "redux/types"

const initialState = {
  response: null,
  loading: false,
  error: false,
}

const pageSaveMain = (state = initialState, action) => {
  switch (action.type) {
    case type.PAGE_SAVE_MAIN_REQUESTED:
      return {
        ...state,
        loading: true,
        error: false,
      }
    case type.PAGE_SAVE_MAIN_SUCCEEDED:
      return {
        ...state,
        response: action.payload,
        loading: false,
        error: false,
      }
    case type.PAGE_SAVE_MAIN_FAILED:
      return {
        ...state,
        response: null,
        loading: false,
        error: true,
      }
    default:
      return state
  }
}

export default pageSaveMain
