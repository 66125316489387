import React from "react"
import PropTypes from "prop-types"

const TextAreaInput = (props) => {
  let {
    title,
    placeholder,
    defaultValue,
    value,
    name,
    onChange,
    textareaClassName,
    textClassName,
    wrapperClassName,
    resize,
    disabled,
    ...attrs
  } = props
  return (
    <label className={wrapperClassName}>
      <span className={textClassName}>{title}</span>
      <textarea
        className={`w-full h-auto ${textareaClassName}`}
        disabled={disabled}
        name={name}
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        style={{ resize }}
        {...attrs}
      />
    </label>
  )
}

export default TextAreaInput

TextAreaInput.prototypes = {
  title: PropTypes.string.isRequired,
  textareaClassName: PropTypes.string,
  textClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
}

TextAreaInput.defaultProps = {
  title: "Label",
  textareaClassName: "mt-2",
  textClassName: "text-gray-800 font-medium",
  wrapperClassName: "",
  name: "text",
  placeholder: "",
  defaultValue: undefined,
  value: undefined,
  disabled: false,
  //onChange: (e) => console.log(e),
}
