import * as type from "redux/types"

export const saveAgreementRequested = (data) => ({
  type: type.SAVE_AGREEMENT_REQUESTED,
  payload: data,
})

export const saveAgreementSucceed = (data) => ({
  type: type.SAVE_AGREEMENT_SUCCEEDED,
  payload: data,
})

export const saveAgreementFailed = () => ({
  type: type.SAVE_AGREEMENT_FAILED,
})
