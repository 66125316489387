import React, { useState } from "react"
import { Col, Dropdown, Badge } from "rsuite"
import { Icon } from "components"
import { useNavigate } from "react-router-dom"
import UserDeleteModal, { useUserDeleteModal } from "./UserDeleteModal"
import UserActivateModal, { useUserActivateModal } from "./UserActivateModal"
import DataGrid, {
  Column,
  Pager,
  Paging,
  SearchPanel,
  Selection,
  Sorting,
  Toolbar,
  Item,
  HeaderFilter,
  FilterRow,
} from "devextreme-react/data-grid"

import trMessages from "localization/TR/devextreme.json"
import { loadMessages, locale } from "devextreme/localization"

const DataList = ({ data, setSelectedRowsData }) => {
  loadMessages(trMessages)
  locale("tr" || navigator.language || navigator.browserLanguage)
  const navigate = useNavigate()
  const [TableDraggableLines] = useState(false)
  const { open, handleOpen, data: modalData, ...modalProps } = useUserDeleteModal()
  const { activateModalOpen, activateModalHandleOpen, data: activateModalData, ...activateModalProps } = useUserActivateModal()
  const tableStates = {
    cellAlignments: "center",
    rowWidth: "199",
    dataGrid: {
      id: "gridContainer",
      allowColumnReordering: true,
      allowColumnResizing: true,
      columnAutoWidth: true,
      showBorders: true,
      showScrollbar: "always",
      keyExpr: "userId",
      dataSource: data,
      noDataText: "Veri Yok",
      onSelectionChanged: function (e) {
        let selectedRowsData = e.selectedRowsData
        setSelectedRowsData(selectedRowsData)
      },
    },
    paging: {
      defaultPageSize: 10,
    },
    filterRow: {
      visible: true,
    },
    headerFilter: {
      visible: true,
    },
    pager: {
      visible: true,
      allowedPageSizes: [10, 20, 30],
      displayMode: "full",
      showPageSizeSelector: true,
      showInfo: true,
      showNavigationButtons: true,
    },
    selection: {
      mode: "multiple",
      selectAllMode: "allMode",
    },
  }

  return (
    <Col xs={24}>
      <DataGrid
        className="filters-active !w-full !max-w-none"
        {...tableStates.dataGrid}
      >
        {/*TABLE OPTIONS*/}
        {!TableDraggableLines && false && <Selection {...tableStates.selection} />}
        {!TableDraggableLines && (
          <SearchPanel
            placeholder={"Kullanıcı adı veya email adresi arayın"}
            visible={true}
            width="320"
            highlightCaseSensitive={false}
          />
        )}
        {/*TABLE OPTIONS*/}
        <Selection
          {...tableStates.selection}
          showCheckBoxesMode={"always"}
        />
        <Sorting mode="multiple" />
        <HeaderFilter {...tableStates.headerFilter} />
        <FilterRow {...tableStates.filterRow} />
        <Paging {...tableStates.paging} />
        <Pager {...tableStates.pager} />
        {/*TABLE OPTIONS*/}
        <Toolbar>
          <Item
            location="before"
            name="searchPanel"
          />
        </Toolbar>
        {/*TABLE BODY*/}
        <Column
          caption="Ad-Soyad"
          dataField="name"
          allowSearch={true}
          allowFiltering={false}
          allowHeaderFiltering={true}
          alignment={"left"}
          cellRender={(row) => {
            return `${row.data?.name} ${row.data?.surname}`
          }}
        />
        <Column
          caption="Email"
          dataField="email"
          allowSearch={true}
          allowFiltering={false}
          allowHeaderFiltering={true}
          alignment={"left"}
        />
        <Column
          caption="Departman"
          dataField="departments[0].departmentName"
          allowSearch={true}
          allowFiltering={false}
          allowHeaderFiltering={true}
          width={"139"}
          alignment={"left"}
          cellRender={(row) => {
            return row.data.departments.map((d) => d.departmentName).join(", ")
          }}
        />
        <Column
          caption="Rol"
          dataField="role[0].roleName"
          allowSearch={true}
          allowFiltering={false}
          allowHeaderFiltering={true}
          width={"139"}
          alignment={"left"}
          cellRender={(row) => {
            return row.data.role.map((r) => r.roleName).join(", ")
          }}
        />
        <Column
          caption="Statü"
          dataField="status"
          allowFiltering={false}
          allowHeaderFiltering={true}
          cellRender={(row) => {
            return row.data.status ? (
              <Badge
                className="p-1 px-2 rounded-xl bg-transparent text-green-500"
                content="Aktif"
              />
            ) : (
              <Badge
                className="p-1 px-2 rounded-xl bg-transparent text-red-500"
                content="Pasif"
              />
            )
          }}
          width={"139"}
          alignment={tableStates.cellAlignments}
        >
          <HeaderFilter
            dataSource={[
              {
                text: "Pasif",
                value: ["status", "=", false],
              },
              {
                text: "Aktif",
                value: ["status", "=", true],
              },
            ]}
          />
        </Column>
        <Column
          caption="İşlemler"
          width={"105"}
          fixed={true}
          fixedPosition={"right"}
          cellRender={(row) => {
            const ButtonContent = (props, ref) => (
              <div
                ref={ref}
                {...props}
              >
                <Icon
                  size={24}
                  name="more-vertical"
                  className={"!bg-blue-500"}
                />
              </div>
            )

            const DropItem = (props) => (
              <Dropdown.Item
                icon={
                  <Icon
                    name={props.iconName ? props.iconName : ""}
                    size={14}
                    style={{ backgroundColor: props.color ? props.color : "#000000" }}
                  />
                }
                onClick={props.onClick}
                className="flex items-center justify-start gap-3 px-4"
                style={{ color: props.color ? props.color : "#000000" }}
              >
                {props.text}
              </Dropdown.Item>
            )

            return (
              <Dropdown
                renderToggle={ButtonContent}
                trigger={"hover"}
                placement="bottomEnd"
              >
                <DropItem
                  text="İncele"
                  iconName="eye"
                  onClick={() => navigate(`/settings/user-management/${row.data.userId}`)}
                />
                <DropItem
                  text="Düzenle"
                  iconName="edit"
                  onClick={() => navigate(`/settings/user-management/${row.data.userId}/edit`)}
                />
                <DropItem
                  text={!row?.data?.status ? "Aktife Al" : "Pasife Al"}
                  color={!row?.data?.status ? "#00C853" : "#F44336"}
                  iconName={!row?.data?.status ? "check-square" : "slash"}
                  onClick={() => {
                    if (row?.data?.status) {
                      handleOpen({ ...row?.data, changeStatusVal: false }, "makePassive")
                    } else {
                      activateModalHandleOpen({ ...row?.data, changeStatusVal: true })
                    }
                  }}
                />
              </Dropdown>
            )
          }}
          alignment={tableStates.cellAlignments}
        />
        {/*TABLE BODY*/}
      </DataGrid>

      <UserDeleteModal
        {...modalProps}
        open={open}
        data={modalData}
      />

      <UserActivateModal
        {...activateModalProps}
        open={activateModalOpen}
        data={activateModalData}
      />
    </Col>
  )
}

export default DataList
