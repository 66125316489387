import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

// components
import { TabList, FontSizeBox, FontSizeRangeBox } from "components"

const FontSizeTabBox = (props) => {
  const { onChange, onTabChange, title, value, tabs, sizes, rangeLabel, styles, activeTab } = props

  const fontSize = (value && value?.replace("px","") * 1) || 16


  return (
    <>
      <TabList
        name={`MediaTab`}
        onChange={onTabChange}
        tabs={tabs}
        tabClassName="w-full flex items-center justify-center gap-2.5 text-center text-gray-600 text-xs p-2 border-r last:border-r-0 hover:bg-gray-50 transition duration-400"
        wrapperClassName="flex shrink-0 border rounded-t-lg border-gray-200 overflow-hidden mt-7"
        //itemActiveClass="!text-gray-800 !bg-blue-50"
        activeTab={activeTab}
      />

      <div className="border-b border-x p-3 border-gray-200">
        {activeTab === 0 && (
          <FontSizeBox
            title={title}
            activeFontSize={fontSize}
            onChange={onChange}
            sizes={sizes}
            styles={styles}
          />
        )}
        {activeTab === 1 && (
          <div className="input-wrapper">
            <FontSizeRangeBox
              className={""}
              min={12}
              max={72}
              onChange={onChange}
              rangeLabel={rangeLabel}
              defaultRangeValue={fontSize}
              rangeValue={fontSize}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default FontSizeTabBox

FontSizeTabBox.prototypes = {
  tabs: PropTypes.array,
  activeTab: PropTypes.number,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onTabChange: PropTypes.func,
}

FontSizeTabBox.defaultProps = {
  tabs: [{ label: "Önerilen" }, { label: "Özelleştir" }],
  activeTab: 0,
}
