import { call, fork, put, takeLatest } from "redux-saga/effects"
import * as actions from "redux/actions/saveAgreement"

import { SAVE_AGREEMENT_REQUESTED } from "redux/types"
import { API_POST } from "helpers/api"

function* watchSaveAgreement() {
  yield takeLatest(SAVE_AGREEMENT_REQUESTED, saveAgreement)
}

function* saveAgreement(action) {
  try {
    const res = yield call(apiGetAgreement, action.payload)
    yield put(actions.saveAgreementSucceed(res?.data?.result))
  } catch (err) {
    yield put(actions.saveAgreementFailed())
  }
}

const apiGetAgreement = (payload) => {
  return API_POST(`${process.env.REACT_APP_PANEL_DOMAIN}/api/panelagreement`, payload)
}

export default function* agreementSave() {
  yield fork(watchSaveAgreement)
}
