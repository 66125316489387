import { call, fork, put, takeLatest } from "redux-saga/effects"
import * as actions from "redux/actions/domainStateGet"
import Cookies from "universal-cookie"

import { DOMAIN_GET_STATE_REQUESTED } from "redux/types"
import { API_POST } from "helpers/api"

function* watchGetDomainState() {
  yield takeLatest(DOMAIN_GET_STATE_REQUESTED, uptadeDomainState)
}

const cookies = new Cookies()

function* uptadeDomainState(action) {
  try {
    const data = action.payload
    const res = yield call(apiGetDomainState, data)
    yield put(actions.domainStateGetSucceed(res?.data))
    cookies.set("domainValue", res?.data?.value)
  } catch (err) {
    yield put(actions.domainStateGetFailed())
  }
}

const apiGetDomainState = (data) => {
  if (Object.keys(data)?.length !== 0) {
    var formBody = []
    for (var property in data) {
      var encodedKey = encodeURIComponent(property)
      var encodedValue = encodeURIComponent(data[property])
      formBody.push(encodedKey + "=" + encodedValue)
    }
    formBody = formBody.join("&")

    return API_POST(
      `${process.env.REACT_APP_DISPLAY_DOMAIN}/api/domain/getdomainstate`,
      formBody,
      "application/x-www-form-urlencoded",
    )
  }

  return API_POST(`${process.env.REACT_APP_DISPLAY_DOMAIN}/api/domain/getdomainstate`, data)
}

export default function* domainStateGet() {
  yield fork(watchGetDomainState)
}
