import { call, fork, put, takeLatest } from "redux-saga/effects"
import * as actions from "redux/actions/getCustomDomainsNearExpiration"

import { GET_CUSTOM_DOMAINS_NEAR_EXPIRATION_REQUESTED } from "redux/types"
import { API_POST } from "helpers/api"

function* watchGetCustomDomainsNearExpiration() {
  yield takeLatest(GET_CUSTOM_DOMAINS_NEAR_EXPIRATION_REQUESTED, getCustomDomainsNearExpiration)
}

function* getCustomDomainsNearExpiration(action) {
  try {
    const res = yield call(apiGetCustomDomainsNearExpiration, action.payload)
    let newData = Object.keys(res?.data?.result).reduce((acc, value, index) => {
      acc.push({
        keyExpr: index,
        domainUrl: value,
        expirationDate: Object.values(res?.data?.result)[index],
      })
      return acc
    }, [])
    yield put(actions.getCustomDomainsNearExpirationSucceed(newData))
  } catch (err) {
    yield put(actions.getCustomDomainsNearExpirationFailed())
  }
}

const apiGetCustomDomainsNearExpiration = () => {
  return API_POST(`${process.env.REACT_APP_DISPLAY_DOMAIN}/api/domain/getCustomDomainsNearExpiration?returnData=true`, null, "application/x-www-form-urlencoded; charset=UTF-8")
}

export default function* customDomainsNearExpirationGet() {
  yield fork(watchGetCustomDomainsNearExpiration)
}
