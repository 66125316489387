import {IMAGE} from "../Enums";

export const deleteMediaItem = function (ThisComponent, media, softDelete) {
	return new Promise(function (resolve, reject) {
		let children = ThisComponent?.children
		if (!children){
			reject("error")
		}

		if (!softDelete){
			if(children){
				const s = children?.find((child) => child?.code === media?.code)
				const index = children.indexOf(s);
				if (index > -1) {
					children.splice(index, 1);
				}
			}
		} else {
			media?.children && media.children?.map((child) => {
				let hasImage = child?.name === IMAGE
				if (hasImage){
					delete child.data
					const index = media.children?.indexOf(child);
					if (index > -1) {
						media.children[index] = child
					}
				}
			})
		}
		resolve(ThisComponent);
	});
};