/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

// utils
import useQuery from "utils/useQuery"

// enums
import {DefaultPageSettings, DefaultVisualStyles} from "../Enums";

// components
import PageManagerHeader from "components/Headers/PageManagerHeader"
import PageManagerAside from "layouts/Aside/PageManager"
import PreviewPage from "../layouts/PreviewPage"
import { deleteStrategySucceed, getTemplatesRequested } from "redux/actions/strategyDesign"
import { getStrategyRequested } from "redux/actions/strategy"
import {getConfigurationRequested} from "../../B2cDemoPage/redux/actions/GetConfiguration";
import {listConfigurationRequested} from "../../B2cDemoPage/redux/actions/ListConfiguration";
import {tr2en} from "utils/tr2en";

const EditPage = (props) => {
  let { AssociatedDomains } = props

  const dispatch = useDispatch()
  let query = useQuery()
  const DomainId = AssociatedDomains?.domainId
  const DomainType = AssociatedDomains?.domainType
  let path = query.get("path")
  let name = query.get("name")

  //selectors
  const ListConfiguration = useSelector((state) => state.ListConfiguration.result)
  const ListConfigurationLoading = useSelector((state) => state.ListConfiguration.loading)
  const GetConfiguration = useSelector((state) => state.GetConfiguration.result)
  const GetConfigurationLoading = useSelector((state) => state.GetConfiguration.loading)
  const DomainInfoState = useSelector((state) => state.associatedDomains.data)
  const templates = useSelector((state) => state.strategyDesign.getTemplates)
  const strategies = useSelector((state) => state.strategy.getStrategy)
  const deleteStrategy = useSelector((state) => state.strategyDesign.deleteStrategy?.response)
  // states

  const [PageLock, setPageLock] = useState(false)
  //blocks
  const [EditLayouts, setEditLayouts] = useState(null)
  const [EditBlock, setEditBlock] = useState(null)
  const [BlockListTab, setBlockListTab] = useState(0)
  const [ActiveCategory, setActiveCategory] = useState(null)
  const [AddNewBlockHandleClick, setAddNewBlockHandleClick] = useState(null)

  // design
  const [VisualValues, setVisualValues] = useState(DefaultVisualStyles)
  const [ThemeConfig, setThemeConfig] = useState(null)
  const [StrategyUpdatedOneTime, setStrategyUpdatedOneTime] = useState(false)
  const [ActiveMenuTab, setActiveMenuTab] = useState(!path ? 3 : 0)
  const [DefaultThemeLinkColor] = useState(DefaultVisualStyles.button.backgroundColor)
  const [AllFonts, setAllFonts] = useState(null)
  const [AllLinks, setAllLinks] = useState(null)
  const [GetThemeConfig, setGetThemeConfig] = useState(null)
  const [LayoutPreview, setLayoutPreview] = useState({ id: 1, text: "Masaüstü" })
  const [DefaultPageName] = useState(name || DefaultPageSettings.pageTitle )

  const [SeoSettings, setSeoSettings] = React.useState({
    title: DefaultPageName,
    pageTitle: DefaultPageName,
    path: path || `${tr2en(DefaultPageName).toLowerCase().replaceAll(" ", "-")}`,
    description: "",
    keywords: [],
    canonicalUrl: "",
    index: true,
  });



  //funcs
  const getPageRequestsSync = () => {
    dispatch(
      listConfigurationRequested({
        query: `domainType=${DomainType}`,
      }),
    )
    dispatch(
      getConfigurationRequested({
        domainType: DomainId,
      }),
    )
  }

  useEffect(() => {
    dispatch(getTemplatesRequested())
    dispatch(
      getStrategyRequested({
        path,
        LanguageCode: "tr",
      }),
    )
  }, [])

  useEffect(() => {
    if (deleteStrategy) {
      dispatch(deleteStrategySucceed(null))
      dispatch(
        getStrategyRequested({
          path,
          LanguageCode: "tr",
        }),
      )
    }
  }, [deleteStrategy])


  //effects


  // data manipulation
  useEffect(() => {
    if (ListConfiguration !== null) {
      // add background-color to response list
      let displays = ListConfiguration?.displays.reduce((acc, value) => {
        acc.push({
          ...value,
          backgroundColor: "#EFF8FF",
        })
        return acc
      }, [])

      // compare if GetThemeConfig is not empty
      let activeDisplay = displays ? displays[0] : {}

      let activeFontFamily = ListConfiguration?.fonts[0]?.value
      let activeThemeLinkType = ListConfiguration?.links[0]?.value
      let activeThemeButtonColor = DefaultVisualStyles.button.backgroundColor
      let activeThemeLinkColor = DefaultThemeLinkColor
      if (GetThemeConfig?.configurations.length) {
        activeDisplay = [
          GetThemeConfig?.configurations.find(({ configurationMetadataCode }) => configurationMetadataCode === "theme"),
        ].reduce((acc, value) => {
          acc = {
            label: value.name,
            styles: value.properties,
          }
          return acc
        }, [])

        activeFontFamily = GetThemeConfig?.configurations.find(
          ({ configurationMetadataCode }) => configurationMetadataCode === "font",
        )?.name
        activeThemeLinkType = GetThemeConfig?.configurations.find(
          ({ configurationMetadataCode }) => configurationMetadataCode === "linktype",
        )
        activeThemeButtonColor = GetThemeConfig?.configurations.find(
          ({ configurationMetadataCode }) => configurationMetadataCode === "color",
        )?.properties?.color
        activeThemeLinkColor = GetThemeConfig?.configurations.find(
          ({ configurationMetadataCode }) => configurationMetadataCode === "linkcolor",
        )?.properties?.linkcolor
      }
      setAllFonts(ListConfiguration?.fonts)
      setAllLinks(ListConfiguration?.links)

      const displayValues = {
        borderRadius: activeDisplay?.styles?.borderRadius,
        fontFamily: activeFontFamily,
        button: {
          backgroundColor: activeThemeButtonColor,
          borderColor: activeThemeButtonColor,
          //color: "black",
        },
        link: {
          name: activeThemeLinkType?.name,
          style: activeThemeLinkType?.properties?.textDecorationLine,
          color: activeThemeLinkColor,
        },

      }
      setVisualValues({
        ...VisualValues,
        ...displayValues,
      })

      setThemeConfig({
        visualValues: {
          ...displayValues
        },
      })
    }

    // eslint-disable-next-line
  }, [GetThemeConfig, ListConfiguration])

  // getConfiguration
  useEffect(() => {
    if (GetThemeConfig && ListConfiguration && ThemeConfig && strategies?.list?.result && !StrategyUpdatedOneTime) {
      const visual = strategies?.list?.result?.visual
      const style = visual?.style
      const themeVisual = ThemeConfig?.visualValues
      setVisualValues({
        ...VisualValues,
        ...themeVisual,
        enableHeaderFooter: visual?.enableHeaderFooter || false,
        link: {
          name: visual?.link?.name || themeVisual?.link?.name,
          style: visual?.link?.style || themeVisual?.link?.style,
          color: visual?.link?.color || themeVisual?.link?.color,
        },
        ...style,
        fontSize: style?.fontSize || DefaultVisualStyles.fontSize,
        fontFamily: style?.fontFamily || themeVisual.fontFamily,
        backgroundColor: style?.backgroundColor || DefaultVisualStyles.backgroundColor,
        componentMargin: style?.componentMargin || DefaultVisualStyles.componentMargin,
      })
      setStrategyUpdatedOneTime(true)
    }
    if (strategies?.list?.result){
      const { data } = strategies?.list?.result
      setSeoSettings({
        ...SeoSettings,
        title: data?.seoTitle || DefaultPageName,
        description: data?.seoDescription,
        keywords: (data?.seoKeyword && JSON.parse(data.seoKeyword)) || [],
        index: data?.index,
        canonicalUrl: data?.canonicalUrl
      })
    }
  }, [ThemeConfig, strategies?.list?.result])

  // getConfiguration
  useEffect(() => {
    if (GetConfiguration) {
      setGetThemeConfig(GetConfiguration)
    }
  }, [GetConfiguration])

  // request
  useEffect(() => {
    if (!ListConfigurationLoading && !GetConfigurationLoading) {
      if (DomainId && DomainType) {
        getPageRequestsSync()
      } else {
        if (DomainInfoState && DomainInfoState.length) {
          getPageRequestsSync()
        }
      }
    }
    // eslint-disable-next-line
  }, [dispatch, DomainInfoState, DomainId, DomainType])


  return (
    <div className={`${PageLock ? "opacity-60 pointer-events-none select-none cursor-wait": ""}`}>
      <PageManagerHeader
        visualValues={VisualValues}
        setVisualValues={setVisualValues}
        layoutPreview={LayoutPreview}
        setLayoutPreview={setLayoutPreview}
        seoSettings={SeoSettings}
      />
      <main>
        <PageManagerAside
          templates={templates}
          strategies={strategies}
          EditLayouts={EditLayouts}
          setEditLayouts={setEditLayouts}
          EditBlock={EditBlock}
          setEditBlock={setEditBlock}
          BlockListTab={BlockListTab}
          ActiveCategory={ActiveCategory}
          setActiveCategory={setActiveCategory}
          setBlockListTab={setBlockListTab}
          AddNewBlockHandleClick={AddNewBlockHandleClick}
          setAddNewBlockHandleClick={setAddNewBlockHandleClick}
          AssociatedDomains={AssociatedDomains}
          visualValues={VisualValues}
          setVisualValues={setVisualValues}
          activeMenuTab={ActiveMenuTab}
          setActiveMenuTab={setActiveMenuTab}
          allFonts={AllFonts}
          allLinks={AllLinks}
          setPageLock={setPageLock}
          seoSettings={SeoSettings}
          setSeoSettings={setSeoSettings}
        />
        <PreviewPage
          strategies={strategies}
          EditLayouts={EditLayouts}
          setEditLayouts={setEditLayouts}
          EditBlock={EditBlock}
          setEditBlock={setEditBlock}
          setBlockListTab={setBlockListTab}
          ActiveCategory={ActiveCategory}
          setActiveCategory={setActiveCategory}
          AddNewBlockHandleClick={AddNewBlockHandleClick}
          setAddNewBlockHandleClick={setAddNewBlockHandleClick}
          visualValues={VisualValues}
          activeMenuTab={ActiveMenuTab}
          setActiveMenuTab={setActiveMenuTab}
          layoutPreview={LayoutPreview}
        />
      </main>
    </div>
  )
}

export default EditPage
