import React, { useRef } from "react"
import { SelectPicker } from "rsuite"
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Label, ResponsiveContainer } from "recharts"

import Icon from "components/Icon"
import { data, options } from "./data"

const ReservationInformation = () => {
  const selectPickerRef = useRef()

  return (
    <div className="border border-gray-200 rounded-lg p-6 flex flex-col gap-6 bg-white">
      <h2 className="text-xl text-gray-700 font-semibold">Rezervasyon Bilgileri</h2>

      <div className="flex justify-between">
        <div className="flex gap-3 items-center">
          <div className="flex gap-2 items-center border rounded-lg border-[#53B1FD] p-3">
            <Icon
              name="dot"
              size="10"
              color="#2E90FA"
            />
            <p className="text-gray-500 text-sm">Rez. Sayısı</p>
          </div>
          <div className="flex gap-2 items-center border rounded-lg border-[#53B1FD] p-3">
            <Icon
              name="dot"
              size="10"
              color="#84CAFF"
            />
            <p className="text-gray-500 text-sm">Kazanç</p>
          </div>
          <div className="flex gap-2 items-center border rounded-lg border-[#53B1FD] p-3">
            <Icon
              name="dot"
              size="10"
              color="#175CD3"
            />
            <p className="text-gray-500 text-sm">Sayfa Görüntülenme</p>
          </div>
        </div>
        <div
          ref={selectPickerRef}
          className="flex justify-end relative"
        >
          <SelectPicker
            block
            size="lg"
            placement="bottomStart"
            placeholder={"Aylık"}
            defaultValue={"Aylık"}
            menuMaxHeight={164}
            cleanable={false}
            className="w-[154px]"
            searchable={false}
            data={options}
            container={() => selectPickerRef.current}
          />
        </div>
      </div>

      <div>
        <ResponsiveContainer height={350}>
          <LineChart
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 20,
            }}
          >
            <CartesianGrid
              stroke="#F2F4F7"
              strokeDasharray="0"
              vertical={false}
            />
            <XAxis
              dataKey="name"
              axisLine={false}
              tickLine={false}
              padding={{ left: 30, right: 30 }}
              style={{
                fontSize: "12px",
                color: "#667085",
              }}
            >
              <Label
                className="text-sm text-gray-500 font-medium"
                value="Ay"
                position="bottom"
              />
            </XAxis>
            <YAxis
              axisLine={false}
              tickLine={false}
              padding={{ left: 30, right: 30 }}
              style={{
                fontSize: "12px",
                color: "#667085",
              }}
            />
            <Tooltip />
            <Line
              type="monotone"
              dataKey="pv"
              stroke="#2E90FA"
              strokeWidth={2}
              activeDot={{ r: 8 }}
              dot={false}
            />
            <Line
              type="monotone"
              dataKey="uv"
              stroke="#84CAFF"
              strokeWidth={2}
              dot={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

export default ReservationInformation
