import * as type from "redux/types"

export const getPageListRequested = (data) => ({
  type: type.GET_PAGE_LIST_REQUESTED,
  payload: data,
})

export const getPageListSucceed = (data) => ({
  type: type.GET_PAGE_LIST_SUCCEEDED,
  payload: data,
})

export const getPageListFailed = () => ({
  type: type.GET_PAGE_LIST_FAILED,
})
