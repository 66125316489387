import React, { useState } from "react"
import PropTypes from "prop-types"

const ButtonStyleBox = (props) => {
  let { title, onChange, ActiveButtonStyle } = props

  let [ButtonStyles] = useState(["Dolu", "Dış Çizgili"])

  return (
    <div className="flex flex-col border border-gray-200 px-3 pt-3.5 pb-5 rounded-lg">
      <label className="text-sm">{title}</label>
      <div className="flex justify-center text-center border border-gray-200 rounded-lg mt-4">
        {ButtonStyles.map((style, key) => {
          return (
            <span
              className={`flex items-center justify-center w-full py-2 text-xs font-medium ${
                ActiveButtonStyle === style ? "bg-blue-50" : ""
              } border-l border-gray-200 cursor-pointer first:border-l-0`}
              key={key}
              onClick={() => onChange(style)}
            >
              {style}
            </span>
          )
        })}
      </div>
    </div>
  )
}

export default ButtonStyleBox

ButtonStyleBox.prototypes = {
  title: PropTypes.string,
  ActiveButtonStyle: PropTypes.string,
  onChange: PropTypes.func,
}

ButtonStyleBox.defaultProps = {
  title: "Style",
  ActiveButtonStyle: "Dolu",
  //onChange: (e) => console.log(e),
}
