import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams } from "react-router-dom"

// Components
import MainHeader from "components/Headers/MainHeader"
import BackgroundIcons from "components/BackgroundIcons"
import { TitleH1, Description, Icon, LoadBar, StatusNotificationBox } from "components"
import MultiCascader from "rsuite/MultiCascader"
import { SelectPicker } from "rsuite"
import { DomainTypes } from "../../constants"
import localization from "localization"

// moment JS
import moment from "moment"

// dxTable
import { LoadPanel } from "devextreme-react/load-panel"
import DataGrid, {
  Column,
  FilterRow,
  Item,
  Pager,
  Paging,
  SearchPanel,
  Selection,
  Sorting,
  Toolbar,
} from "devextreme-react/data-grid"
import Button from "devextreme-react/button"
import OutsideAlerter from "pages/Reservations/OutsideAlerter"

// img
import evaPreview from "../../assets/svg/eva-preview.svg"

// Requests
import {
  getUserListRequested,
  getTenantRequested,
  setUserListRequested,
  getDomainRequested,
} from "../../redux/actions/accountManagement"

export const MyDomains = (props) => {
  let { isLogged } = props

  const dispatch = useDispatch()
  const params = useParams()

  let { id } = params

  // selectors
  //const activeDomainLoading = useSelector((state) => state.accountManagement.activeDomain.loading)
  const activeDomainList = useSelector((state) => state.accountManagement.activeDomain.list)
  //const activeDomainError = useSelector((state) => state.accountManagement.activeDomain.error)

  const activeUserLoading = useSelector((state) => state.accountManagement.userList.loading)
  const activeUserList = useSelector((state) => state.accountManagement.userList.list)
  const activeUserError = useSelector((state) => state.accountManagement.userList.error)

  const setUserLoading = useSelector((state) => state.accountManagement.setUserList.loading)
  const setUserList = useSelector((state) => state.accountManagement.setUserList.list)
  const setUserError = useSelector((state) => state.accountManagement.setUserList.error)

  //const activeTenantLoading = useSelector((state) => state.accountManagement.activeTenant.loading)
  const activeTenantList = useSelector((state) => state.accountManagement.activeTenant.list)
  //const activeTenantError = useSelector((state) => state.accountManagement.activeTenant.error)

  // states
  const [TabList] = useState([
    { text: "Domainler", isActive: false, url: "account-management/domains" },
    { text: "Kullanıcı Listesi", isActive: true, url: "account-management/userlist" },
  ])
  const [IsOpenTableDdown, setIsOpenTableDdown] = useState(null)
  const [AddUserForm, setAddUserForm] = useState({})
  const [ShowAddUser, setShowAddUser] = useState(false)
  const [UserAddButtonDisabled, setUserAddButtonDisabled] = useState(true)
  const [MultiCascaderSelectedList, setMultiCascaderSelectedList] = useState([])
  const [MultiCascaderDomainList, setMultiCascaderDomainList] = useState([])
  const [ShowStatusNotificationBox, setShowStatusNotificationBox] = useState(false)
  const [ErrorSetUserMessage, setErrorSetUserMessage] = useState({})
  const [TableStates, setTableStates] = useState({
    cellAlignments: "left",
    rowWidth: 162,
    dataGrid: {
      id: "gridContainer",
      allowColumnReordering: true,
      allowColumnResizing: true,
      columnAutoWidth: true,
      showBorders: true,
      showScrollbar: "always",
      keyExpr: "userId",
      dataSource: [],
      noDataText: "",
    },
    paging: {
      defaultPageSize: 5,
    },
    pager: {
      visible: true,
      allowedPageSizes: [5, 10, 20],
      displayMode: "full",
      showPageSizeSelector: true,
      showInfo: true,
      showNavigationButtons: true,
    },
  })

  // Func

  const setForm = (e) => {
    setAddUserForm({
      ...AddUserForm,
      [e.target.name]: e.target.value,
    })
  }

  const addUserRequest = () => {
    setUserAddButtonDisabled(true)
    let saveData = {
      Name:
        AddUserForm.userName.split(" ").length > 1
          ? AddUserForm.userName.split(" ").slice(0, -1).join(" ")
          : AddUserForm.userName,
      Surname: AddUserForm.userName.split(" ").length > 1 ? AddUserForm.userName.split(" ").pop() : "",
      Email: AddUserForm.email,
      TenantId: id,
      DomainItemList: [],
    }

    MultiCascaderSelectedList.map((product) => {
      let activeDomain = activeDomainList?.find((domain) => domain?.Id === product)
      let DomainType = DomainTypes.find((type) => type?.label === activeDomain?.DomainType)?.value
      saveData.DomainItemList.push({
        DomainType,
        DomainId: product,
      })
      return true
    })

    dispatch(setUserListRequested(saveData))
  }
  const showAddUser = () => {
    setShowAddUser(!ShowAddUser)
    setShowStatusNotificationBox(false)
    setUserAddButtonDisabled(true)
    dispatch(getDomainRequested(id))
  }

  const showStatusNotificationBox = () => {
    setShowStatusNotificationBox(!ShowStatusNotificationBox)
    setUserAddButtonDisabled(true)
  }

  // effects
  useEffect(() => {
    if (activeUserError) {
      alert("Müşteriye ait kullanıcı bilgilerine ulaşılamadı. Lütfen daha sonra tekrar deneyiniz.")
      // todo buraya bakmalısın - static code (route değişebilir)
      window.location.pathname = "/account-management"
    }
    if (!activeUserError && !activeUserLoading) {
      dispatch(getTenantRequested(id))
      dispatch(getUserListRequested(id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id, activeUserError])

  useEffect(() => {
    if (activeUserList) {
      setTableStates({
        ...TableStates,
        dataGrid: {
          ...TableStates.dataGrid,
          dataSource: activeUserList,
        },
      })

      if (activeDomainList) {
        let multiCascaderDomainListOptions = activeDomainList.reduce((acc, value) => {
          acc.push({
            label: value.Name,
            value: value.Id,
          })
          return acc
        }, [])
        setMultiCascaderDomainList(multiCascaderDomainListOptions)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDomainList, activeUserList])

  useEffect(() => {
    let message
    if (setUserList) {
      !setUserList.success ? (message = setUserList?.errors[0]?.message) : (message = "")
      dispatch(getUserListRequested(id))
    } else if (setUserError) {
      message = `işlem sırasında bir hata oluştu, bu genellikle internet bağlantısı sorunlarında veya sunucu ile haberleşme kaynaklı sorunlardan gerçekleşmektedir. Lütfen internet bağlantını kontrol et, herşeyden eminsen sayfayı yenile.`
    }
    if (setUserList || setUserError) {
      setErrorSetUserMessage({ message })
      showStatusNotificationBox()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setUserList, setUserError])

  useEffect(() => {
    if (AddUserForm) {
      if (
        AddUserForm?.email?.length &&
        AddUserForm?.userName?.length &&
        AddUserForm?.roleSelectPicker?.length &&
        MultiCascaderSelectedList.length
      ) {
        setUserAddButtonDisabled(false)
      } else {
        setUserAddButtonDisabled(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AddUserForm, MultiCascaderSelectedList])

  return (
    <>
      <MainHeader isLogged={isLogged} />
      <main className="bg-gray-25 z-10">
        <section className="wrapper relative flex flex-col bg-white lg:bg-transparent overflow-auto">
          {activeUserLoading && (
            <div className="account-management-area flex items-center justify-center h-full overflow-auto lg:overflow-hidden">
              <div className="flex items-center justify-center border border-gray-200 shadow-xl rounded-xl bg-white w-[200px] h-[200px] p-10">
                <LoadBar
                  pathFill={"#2E90FA"}
                  className="w-[100px] h-[100px]"
                />
              </div>
            </div>
          )}

          {/*showAddUser*/}
          {ShowAddUser && !activeUserLoading && (
            <>
              {!ShowStatusNotificationBox && (
                <div className="account-management-area relative flex items-center justify-center h-full max-h-full overflow-auto lg:overflow-hidden">
                  {/*background blur icons*/}
                  <BackgroundIcons />
                  <div className="flex flex-col border border-gray-200 shadow-xl rounded-xl bg-white w-[392px] p-6">
                    <form className="form-area w-full">
                      <div className="form-header">
                        <p className="text-lg text-gray-900 font-semibold">[{activeTenantList?.Name}] için yeni kullanıcı ekle</p>
                        <p className="text-sm text-gray-500 mt-2">
                          Seçmiş olduğunuz müşteri domainlerine yeni kullanıcılar ekleyin.
                        </p>
                      </div>
                      <div className="form-body mt-8">
                        <div className="form-wrapper flex flex-col mt-6 first:mt-0">
                          <label className="text-gray-800 text-sm font-medium mb-1">Ad Soyad</label>
                          <input
                            placeholder="Ad soyad girin"
                            type="text"
                            name="userName"
                            onChange={setForm}
                          />
                        </div>
                        <div className="form-wrapper flex flex-col mt-6 first:mt-0">
                          <label className="text-gray-800 text-sm font-medium mb-1">E-posta Adresi</label>
                          <input
                            placeholder="E-posta girin"
                            type="email"
                            name="email"
                            onChange={setForm}
                          />
                        </div>
                        <div className="form-wrapper flex flex-col mt-6 first:mt-0">
                          <label className="text-gray-800 text-sm font-medium mb-1">Yetki</label>
                          <SelectPicker
                            block
                            placeholder={"Yetki seçin"}
                            cleanable={false}
                            data={[
                              {
                                label: "Admin",
                                value: "1",
                              },
                              {
                                label: "Editör",
                                value: "2",
                              },
                              {
                                label: "Görüntüleyen",
                                value: "3",
                              },
                              {
                                label: "Süper Admin",
                                value: "4",
                              },
                            ]}
                            searchable={false}
                            //container={textTypeWrapper.current}
                            onSelect={(value, item) => {
                              setForm({
                                target: {
                                  name: "roleSelectPicker",
                                  value,
                                },
                              })
                            }}
                          />
                        </div>
                        {MultiCascaderDomainList && (
                          <div className="form-wrapper flex flex-col mt-6 first:mt-0">
                            <label className="text-gray-800 text-sm font-medium mb-1">Domain Adı</label>
                            <MultiCascader
                              block
                              appearance="default"
                              menuHeight="auto"
                              menuWidth={300}
                              placeholder={"Lütfen Seçiniz"}
                              cleanable={true}
                              data={MultiCascaderDomainList}
                              searchable={false}
                              locale={{
                                ...localization?.TR?.PickerLocaleType,
                                searchPlaceholder: "Ara",
                              }}
                              onChange={(e) => {
                                setMultiCascaderSelectedList(e)
                              }}
                            />
                          </div>
                        )}
                      </div>

                      <div className="form-footer mt-8">
                        <div className="btn-group flex gap-6">
                          <button
                            type="button"
                            className="btn outline w-full"
                            onClick={showAddUser}
                          >
                            Vazgeç
                          </button>
                          <button
                            type="button"
                            className="btn w-full"
                            onClick={addUserRequest}
                            disabled={UserAddButtonDisabled}
                          >
                            {UserAddButtonDisabled && setUserLoading ? <LoadBar pathFill={"#2E90FA"} /> : "Kullanıcı Ekle"}
                          </button>
                        </div>
                        <div className="flex flex-start mt-6">
                          <Icon
                            name="info"
                            size="16"
                          />
                          <p className="text-gray-500 text-xs">
                            Aradığınız domaini veya ürünü bulamadınız mı? Sistemde gözükmüyorsa{" "}
                            <Link
                              className="text-blue-500"
                              to={`/account-management/domains/${id}`}
                            >
                              Yeni Domain Oluştur
                            </Link>{" "}
                            alanından domain oluşturabilirsiniz veya destek kısmından bizimle iletişime geçebilirsiniz.
                          </p>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              )}
              <StatusNotificationBox
                show={!!!ErrorSetUserMessage?.message && ShowStatusNotificationBox}
                title="Kullanıcı başarıyla oluşturuldu"
                desc={`Kullanıcınız başarıyla oluşturuldu ve <span class="text-blue-500">${AddUserForm?.email}</span> hesabına aktivasyon linkini gönderdik. Kullanıcı hesabını aktifleştirdiğinde bizden bildirim maili alacaksın.`}
              >
                <div className="form-footer mt-8">
                  <div className="btn-group flex gap-6">
                    <Link
                      className="block w-full"
                      to={"/account-management"}
                    >
                      <button
                        type="button"
                        className="btn outline w-full px-2"
                        // onClick={showAddUser}
                      >
                        Müşteri Yönetimi
                      </button>
                    </Link>
                    <button
                      type="button"
                      className="btn w-full px-2"
                      onClick={showAddUser}
                    >
                      Kullanıcı Listesi
                    </button>
                  </div>
                </div>
              </StatusNotificationBox>

              <StatusNotificationBox
                show={!!ErrorSetUserMessage?.message && ShowStatusNotificationBox}
                error={true}
                title=""
                desc={ErrorSetUserMessage?.message}
              >
                <div className="form-footer mt-8">
                  <div className="btn-group flex gap-6">
                    <Link
                      className="block w-full"
                      to={"/account-management"}
                    >
                      <button
                        type="button"
                        className="btn outline w-full px-2"
                        // onClick={showAddUser}
                      >
                        Müşteri Yönetimi
                      </button>
                    </Link>
                    <button
                      type="button"
                      className="btn w-full px-2"
                      onClick={showAddUser}
                    >
                      Kullanıcı Listesi
                    </button>
                  </div>
                </div>
              </StatusNotificationBox>
            </>
          )}

          {!activeUserLoading && !ShowAddUser && activeUserList && (
            <>
              <div className="account-management-container max-w-[1032px] px-4 py-8 w-full mx-auto">
                <Link
                  to="/account-management"
                  className="inline-flex items-center"
                >
                  <Icon
                    name="arrow-left"
                    size="20"
                    color="#667085"
                  />
                  <p className="font-medium text-gray-500 ml-2">Müşteri yönetimine dön</p>
                </Link>
                <div className="page-info flex items-center flex-wrap mt-6">
                  <div className="page-info-text mr-4">
                    <TitleH1>{activeTenantList?.Name}</TitleH1>
                    <Description className="mt-2">
                      Oluşturulma tarihi: {moment(activeTenantList?.CreateDate).format("DD.MM.YYYY")}
                    </Description>
                  </div>
                </div>
                <div className="filter-wrapper border-b border-gray-200">
                  <ul className="relative flex items-start text-gray-500 font-medium text-sm pt-6 select-none">
                    {TabList.map((v, k) => {
                      return (
                        <li
                          key={k}
                          className={`transition delay-200 ${v.isActive ? "text-blue-500 border-b border-blue-500" : ""}`}
                        >
                          <Link
                            to={`../${v.url}/${id}`}
                            className="block px-3 pb-4 xl:py-4"
                          >
                            {v.text}
                          </Link>
                        </li>
                      )
                    })}
                  </ul>
                </div>

                {!activeUserList.length && (
                  <div className="empty-box flex flex-col lg:items-center gap-10 lg:flex-row lg:overflow-hidden py-5">
                    <div className="lg:w-7/12 flex flex-col items-start py-5">
                      <TitleH1 className="mb-4">Henüz hiç kullanıcı yok!</TitleH1>
                      <p className="text-gray-500 text-sm lg:text-lg">
                        Bu sayfadan kullanıcı ekleyebilir, yönetebilir ve kullanıcıları görüntüleyebilirsin.
                      </p>

                      <button
                        onClick={showAddUser}
                        className="btn sm lg:md mt-4 lg:mt-8"
                      >
                        <span>Kullanıcı Ekle</span>
                      </button>
                    </div>

                    <figure className="hidden lg:flex lg:w-5/12 items-center justify-center">
                      <img
                        src={evaPreview}
                        alt="HomePage Preview"
                      />
                    </figure>
                  </div>
                )}

                {/*table list*/}
                {!!activeUserList.length && (
                  <>
                    <LoadPanel
                      position={{ of: `#${TableStates.dataGrid.id}` }}
                      shadingColor="rgba(0,0,0,0.4)"
                      loadPanelVisible={false}
                      showIndicator={true}
                      shading={true}
                      showPane={true}
                      hideOnOutsideClick={true}
                      visible={false}
                      text={"Yükleniyor..."}
                      //onHidden={() => false}
                    />
                    <DataGrid
                      className={`mt-6`}
                      {...TableStates.dataGrid}
                    >
                      {/*TABLE OPTIONS*/}
                      <Selection {...TableStates.selection} />
                      <Sorting mode="multiple" />
                      <SearchPanel
                        placeholder={"Domain arayın"}
                        visible={true}
                        width="288"
                      />
                      <FilterRow {...TableStates.filterRow} />
                      <Paging {...TableStates.paging} />
                      <Pager {...TableStates.pager} />
                      {/*TABLE OPTIONS*/}

                      <Toolbar>
                        <Item
                          location="before"
                          name="searchPanel"
                        />
                        {/*<Item location="before">
                          <SelectBox
                            width="184"
                            items={[
                              {
                                value: "0",
                                text: "B2C",
                              },
                              {
                                value: "1",
                                text: "Otel Widget",
                              },
                            ]}
                            displayExpr="text"
                            valueExpr="value"
                            placeholder="Domain Tipi"
                            value={1}
                            //onValueChanged={domainTypeChanged}
                          />
                        </Item>*/}
                        <Item location="after">
                          <Button
                            text="Kullanıcı Ekle"
                            width="auto"
                            className="!bg-blue-500 !text-white"
                            onClick={showAddUser}
                          />
                        </Item>
                      </Toolbar>

                      {/*TABLE BODY*/}
                      <Column
                        caption="Kullanıcı Adı"
                        dataField="userName"
                        allowFiltering={true}
                        allowSearch={true}
                        allowHeaderFiltering={false}
                        alignment={"center"}
                        width={TableStates.rowWidth}
                      />
                      <Column
                        caption="Yetki"
                        dataField="roles.length"
                        alignment={TableStates.cellAlignments}
                        allowFiltering={false}
                        allowHeaderFiltering={true}
                        width={TableStates.rowWidth}
                      />
                      <Column
                        caption="Profil Durumu"
                        dataField="status"
                        cellRender={(row) => {
                          return row.data?.status > 0 ? "Aktif" : "Pasif"
                        }}
                        allowFiltering={true}
                        allowHeaderFiltering={false}
                        width={TableStates.rowWidth}
                      />
                      <Column
                        caption="Domain"
                        dataField="scope"
                        allowFiltering={true}
                        cellRender={(row) => row.value.substring(2).split("/").join(",")}
                        allowHeaderFiltering={true}
                        width={TableStates.rowWidth}
                      />
                      <Column
                        caption="Son İşlem Tarihi"
                        dataType="date"
                        dataField="updateDate"
                        allowFiltering={true}
                        allowHeaderFiltering={false}
                        width={TableStates.rowWidth}
                      />
                      <Column
                        caption="İşlemler"
                        width={TableStates.rowWidth}
                        fixed={true}
                        fixedPosition={"right"}
                        cellRender={(row) => {
                          return (
                            <div className="dropdown relative">
                              <OutsideAlerter
                                as="button"
                                className="w-full"
                                index={row.rowIndex}
                                isOutsideClick={() => setIsOpenTableDdown(null)}
                                handleClick={() =>
                                  IsOpenTableDdown !== row.rowIndex
                                    ? setIsOpenTableDdown(row.rowIndex)
                                    : setIsOpenTableDdown(null)
                                }
                              >
                                <Icon
                                  className="pointer-events-none"
                                  name="more-vertical"
                                  size="20"
                                  color="#1D2939"
                                />
                              </OutsideAlerter>
                              {IsOpenTableDdown === row.rowIndex && (
                                <ul
                                  className={`dropdown-body absolute right-[12px] bg-white text-base border border-solid border-gray-100 shadow-lg mt-1 rounded-lg overflow-hidden text-sm text-gray-800 z-10`}
                                >
                                  {/*
                                    todo BE tamamlandıktan sonra bunlar da eklenecek (MARKETPLACE ADDONS)
                                    <li className="dropdown-item">
                                    <Link
                                      to={`products/id=${row.data.Id}&add=true`}
                                      className="block"
                                    >
                                      <button className="w-full py-2.5 px-4 hover:bg-gray-50 hover:font-medium text-left">
                                        Ürün ekle
                                      </button>
                                    </Link>
                                  </li>
                                  <li className="dropdown-item">
                                    <Link
                                      to={`products/id=${row.data.Id}`}
                                      className="block"
                                    >
                                      <button className="w-full py-2.5 px-4 hover:bg-gray-50 hover:font-medium text-left">
                                        Ürünleri göster
                                      </button>
                                    </Link>
                                  </li>*/}
                                  <li className="dropdown-item">
                                    <button
                                      onClick={() => alert(JSON.stringify(row.data, null, 4))}
                                      className="w-full py-2.5 px-4 hover:bg-gray-50 hover:font-medium text-left"
                                    >
                                      kullanıcı bilgileri
                                    </button>
                                  </li>
                                </ul>
                              )}
                            </div>
                          )
                        }}
                        alignment={"center"}
                      />
                      {/*TABLE BODY*/}
                    </DataGrid>
                  </>
                )}
              </div>
            </>
          )}
        </section>
      </main>
    </>
  )
}

export default MyDomains
