/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useRef, useState} from "react"
import {useDispatch, useSelector} from "react-redux";

// components
import {Icon, TabList, Subtitle, ColorBox} from "components"

// wrappers
import PartialWrapper from "../../PartialWrapper"

// utils
import getSelectedIndex from "utils/getSelectedIndex"

// saga-requests
import {getStrategySucceed} from "redux/actions/strategy";
import {UpdateStrategy} from "../../../helpers/UpdateStrategy";

const Background = (props) => {
  let { editBlock, setEditBlock, UploadedImageInfo, setUploadedImageInfo, ThisComponent, setThisComponent, DefaultImageInfo, setDefaultImageInfo } = props

  const dispatch = useDispatch()

  const formRef = useRef()
  const UploadFileRef = useRef()

  //selectors
  const mediaListSave = useSelector((state) => state.mediaLibrary.mediaLibrarySave?.response)
  const mediaListSaveLoading = useSelector((state) => state.mediaLibrary.mediaLibrarySave?.loading)
  let components = useSelector(state => state.strategy.getStrategy.list?.result)

  // states
  const [SelectedTab, setSelectedTab] = useState(0) // default
  const [DefaultBgColor] = useState("#98A2B3")
  const [BgColor, setBgColor] = useState(undefined)
  const [DeleteBackgroundImage, setDeleteBackgroundImage] = useState(false)

  const TabListData = [
    { label: "Görüntü", disabled: false },
    { label: "Renk", disabled: false },
    { label: "Video", disabled: true },
  ]

  // Func
  const editTPTabChanged = (e) => {
    let selectedIndex = getSelectedIndex(e)
    setSelectedTab(selectedIndex)
  }
  const clearImageState = () => {
    setDefaultImageInfo(null)
    setUploadedImageInfo(null)
  }
  const readImage = (file) => {
    const reader = new FileReader()
    reader.addEventListener("load", (event) => {
      clearImageState()
      setUploadedImageInfo({
        src: event.target.result,
        size: file.size,
        name: file.name,
        fileForBinary: file,
      })
    })
    reader.readAsDataURL(file)
    UploadFileRef.current.value = ""
  }
  const goBack = () => {
    setEditBlock(null)
  }
  const deleteBackgroundImage = () => {
    setDeleteBackgroundImage(true)
    clearImageState()
  }

  //effects
  useEffect(() => {
    if (editBlock?.showSettingsBackgroundLayout) {
      const checkItemIncludesImage = !!ThisComponent?.data?.backgroundImagePath || !ThisComponent?.visual?.style?.backgroundColor
      setSelectedTab(checkItemIncludesImage ? 0 : 1)
      setBgColor(editBlock?.media?.backgroundColor)
    }
  }, [editBlock?.showSettingsBackgroundLayout])
  useEffect(() => {
    if (UploadedImageInfo || DeleteBackgroundImage || BgColor){

      let backgroundImagePath = DefaultImageInfo
      let visual = ThisComponent?.visual
      let style = {}

      if (DeleteBackgroundImage){
        backgroundImagePath = 0
      }
      if (UploadedImageInfo){
        backgroundImagePath = UploadedImageInfo?.src
      }
      if (BgColor){
        style = {
          style: {
            ...visual?.style,
            backgroundColor: BgColor
          }
        }
        visual = {
          ...visual,
          ...style
        }
      }

      setThisComponent({
        ...ThisComponent,
        visual,
        data: {
          ...ThisComponent?.data,
          backgroundImagePath,
        }
      })
    }
  }, [UploadedImageInfo, DeleteBackgroundImage, BgColor])
  useEffect(() => {
    if (ThisComponent && (UploadedImageInfo || DeleteBackgroundImage || BgColor)){
      const strategy = UpdateStrategy(components, ThisComponent)
      dispatch(getStrategySucceed(strategy))
    }

  }, [ThisComponent])


  return (
    <PartialWrapper
      className={`edit-bg${editBlock?.showSettingsBackgroundLayout ? " active" : ""}${
        mediaListSaveLoading ? " cursor-progress [&>*]:opacity-50 [&>*]:pointer-events-none [&>*]:select-none" : ""
      }`}
      goBackOnClick={goBack}
    >
      <Subtitle>Arka Plan</Subtitle>

      <TabList
        name={`MediaTab`}
        onChange={editTPTabChanged}
        tabs={TabListData}
        tabClassName="w-full flex items-center justify-center gap-2.5 text-center text-gray-600 text-xs p-2 border-r last:border-r-0 hover:bg-gray-50 transition duration-400"
        wrapperClassName="flex shrink-0 border rounded-lg border-gray-200 overflow-hidden mt-7"
        //itemActiveClass="!text-gray-800 !bg-blue-50"
        activeTab={SelectedTab}
      />

      <div className="w-full overflow-hidden mt-6">
        <div
          className="flex transition duration-500 -m-10"
          style={{
            transform: `translate3d(${SelectedTab === 1 ? "-100%" : "0%"} , 0px, 0px)`,
          }}
        >
          <div className="background-image-area text-sm w-full shrink-0 p-10">
            <div className="flex items-center">
              <p className="text-gray-800 font-medium">Arka plan görüntüsü</p>
              <div className="progress-area ml-auto">
                {!UploadedImageInfo && !DefaultImageInfo && (
                  <p
                    className="text-blue-500 cursor-pointer font-medium hover:text-blue-300 transition"
                    onClick={() => UploadFileRef.current.click()}
                  >
                    Ekle
                  </p>
                )}
                {(UploadedImageInfo || DefaultImageInfo) && (
                  <div className="icon-box flex items-center gap-4">
                    <Icon
                      className="cursor-pointer hover:opacity-50"
                      name="refresh-cw"
                      size={20}
                      color={"#1D2939"}
                      onClick={() => UploadFileRef.current.click()}
                    />
                    <Icon
                      className="cursor-pointer hover:opacity-50"
                      name="trash-2"
                      size={20}
                      color={"#1D2939"}
                      onClick={deleteBackgroundImage}
                    />
                  </div>
                )}
              </div>
              <form
                ref={formRef}
                className="hidden"
              >
                <input
                  hidden
                  type="file"
                  id="fileUploadInputForSlider"
                  ref={UploadFileRef}
                  onChange={(e) => readImage(UploadFileRef.current.files[0])}
                  accept=".jpg, .jpeg, .png, .svg, .gif"
                />
              </form>
            </div>

            {(DefaultImageInfo && !UploadedImageInfo) && (
              <div className="uploaded-image-area flex items-center justify-center rounded-lg py-overflow-hidden h-[220px] mt-3">
                <img
                  className="max-w-full object-cover object-center bg-white/[.5] max-h-full"
                  src={`${DefaultImageInfo}`}
                  alt={DefaultImageInfo}
                />
              </div>
            )}

            {UploadedImageInfo && (
              <div className="uploaded-image-area flex items-center justify-center rounded-lg py-overflow-hidden h-[220px] mt-3">
                <img
                  className="max-w-full object-cover object-center bg-white/[.5] max-h-full"
                  src={UploadedImageInfo?.src || mediaListSave?.result?.url}
                  alt={UploadedImageInfo?.name}
                />
              </div>
            )}

            <p className="mt-3">
              Yüksek çözünürlüklü fotoğraflar, kompanent’in yüklenme hızını etkileyebilir. Fotoğrafları yüklemeden önce optimize
              etmenizi tavsiye ederiz.
            </p>
          </div>

          <div className="w-full shrink-0 p-10">
            <div className="input-wrapper mb-8">
              <ColorBox
                title={"Arka Plan Rengi"}
                value={ThisComponent?.visual?.style?.backgroundColor || DefaultBgColor}
                onChange={(event) => setBgColor(event.target.value)}
              />
            </div>
          </div>
        </div>
      </div>

    </PartialWrapper>
  )
}

export default Background
