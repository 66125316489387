import { call, fork, put, takeLatest } from "redux-saga/effects"
import * as actions from "../actions/SaveConfiguration"
import { SAVE_CONFIGURATON_REQUESTED } from "../types"
import { API_POST } from "helpers/api"

import Cookies from "universal-cookie"
const cookies = new Cookies()

function* watchSaveConfiguration() {
  yield takeLatest(SAVE_CONFIGURATON_REQUESTED, getSaveConfiguration)
}

function* getSaveConfiguration(action) {
  try {
    const res = yield call(apiPostSaveConfiguration, action.payload)
    yield put(actions.saveConfigurationSucceed(res.data))
    cookies.set("redirectSetup", "false")
  } catch (err) {
    yield put(actions.saveConfigurationFailed())
  }
}

const apiPostSaveConfiguration = (payload) => {
  return API_POST(
    `${process.env.REACT_APP_DISPLAY_DOMAIN}/api/clientconfigurationpaneluser/save`,
    payload,
    "application/x-www-form-urlencoded",
  )
}

export default function* saveConfiguration() {
  yield fork(watchSaveConfiguration)
}
