import React from "react"
import { Icon, Card } from "components"
import { Grid, Row, Col } from "rsuite"
const RuleList = [
  {
    key: "incomeRule",
    name: "Bütçe Kuralı",
    description: "Ürününüzde çalıştırmak için bütçe limitleri ve koşulları tanımlayın.",
    to: "/rules/addNewRule/incomeRule",
  },
  {
    key: "reservationRule",
    name: "Rezervasyon Kuralı",
    description: "Rezervasyon oluşturma koşulları tanımlayın.",
    to: "/rules/addNewRule/reservationRule",
  },
  {
    key: "searchRule",
    name: "Arama Kuralı",
    description: "Rezervasyon arama koşulları oluşturun.",
    to: "/rules/addNewRule/searchRule",
  },
  {
    key: "confirmationRule",
    name: "Onay Kuralı",
    description: "Talep onaylama kuralları oluşturun.",
    to: "/rules/addNewRule/confirmationRule",
  },
]

/**
 *
 * @description This component is used to show the no data screen.
 */
const NoDataScreen = () => {
  return (
    <>
      {/* Title */}
      <p className="text-gray-800 font-semibold text-3xl">Kurallar</p>
      <p className="text-gray-500 text-sm font-thin">Ürününüzde çalıştırmak üzere sisteme yönlendiren kurallar ekleyin.</p>

      {/* Content */}
      <Card>
        <p className="text-gray-800 font-semibold text-xl">Henüz hiç kural yok.</p>
        <p className="text-gray-800 text-lg">Kural tipinizi seçerek ilk kuralınızı oluşturun.</p>
        <RuleListButtons />
      </Card>
    </>
  )
}

/**
 * @typedef {object} RuleListItemProps
 * @property {"stretch" | "wide"} size
 *
 * @param {RuleListItemProps} param0
 * @returns
 */
export const RuleListButtons = ({ size }) => {
  return (
    <Grid
      fluid
      className="py-10"
    >
      <Row>
        {RuleList.map((item) => (
          <Col
            xs={12}
            lg={size === "stretch" ? 12 : 6}
            key={item?.key}
            className="p-1"
          >
            <div className="flex flex-col items-start gap-3 justify-between p-4 border border-gray-200 rounded-md transition-all hover:shadow-md min-h-[160px]">
              {item?.name}
              <p className="text-gray-500 text-sm font-thin">{item?.description}</p>
              <p
                className="flex items-center gap-1 text-blue-500 text-sm cursor-pointer hover:text-blue-600 transition-all"
                onClick={() => (window.location.href = item?.to)}
              >
                Oluştur&nbsp;
                <Icon
                  size={20}
                  name={"chevron-right"}
                  className="!bg-blue-500"
                />
              </p>
            </div>
          </Col>
        ))}
      </Row>
    </Grid>
  )
}

export default NoDataScreen
