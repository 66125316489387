import React, { useState } from "react"
import { Modal, Button } from "rsuite"
import { useNavigate } from "react-router-dom"
import { ReactComponent as InfoSuccess } from "assets/svg/info-success.svg"
import { useDefinePropertyType } from "utils/useDefinePropertyType"

/**
 * @param {*} data data of the modal
 * @param {boolean} open Visibility of the modal
 * @param {() => void} onClose on close callback
 * @returns
 */

const SuccessModal = (props) => {
  const navigate = useNavigate()
  const { data: propertyTypeList } = useDefinePropertyType()
  return (
    <>
      <Modal
        open={props.open}
        onClose={props.onClose}
        className="w-[382px] !mt-0 absolute top-1/2 left-1/2 transform !-translate-x-1/2 !-translate-y-1/2"
      >
        <Modal.Header
          closeButton={false}
          className="text-center flex justify-center"
        >
          <span>
            <InfoSuccess className="w-14 h-14 mx-auto" />
          </span>
        </Modal.Header>
        <Modal.Body className="text-center">
          <p className="font-medium text-[#101828] text-lg">
            “{props?.data?.PropertyValue}” tanımı {propertyTypeList?.find((item) => item.Id === props?.data?.PropertyId)?.Name} kategorisine
            eklenmiştir.
          </p>
        </Modal.Body>
        <Modal.Footer className="flex">
          <Button
            onClick={() => navigate("/")}
            className="btn w-full bg-white text-gray-600 border border-solid border-gray-300 mr-2 hover:!text-white hover:!bg-gray-600 hover:!border-gray-600"
          >
            Anasayfaya git
          </Button>
          <Button
            onClick={() => {navigate(0)}}
            className="btn w-full hover:!text-white"
          >
            Listeye Dön
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

const useSuccessModal = () => {
  const [open, setOpen] = useState(false)
  const [data, setData] = useState(null)
  /**
   * @param {*} data
   */
  const handleOpen = (data) => {
    setData(data)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return {
    open,
    data,
    handleOpen,
    handleClose,
  }
}

export { useSuccessModal }
export default SuccessModal
