import { Col } from "rsuite"
import { Icon } from "components"

const NewAreaButton = ({ onClick, text }) => {
  return (
    <Col
      xs={24}
      onClick={onClick}
      className="flex justify-center items-center w-full border-2 border-dashed rounded-lg py-3 text-sm text-blue-500 border-gray-200 bg-gray-25 hover:bg-gray-50 hover:border-gray-300 transition-all cursor-pointer"
    >
      <Icon
        name="plus"
        size={20}
        className="!bg-blue-500"
      />
      {text}
    </Col>
  )
}

export default NewAreaButton
