import React from "react"

const DesktopPreviewSkeleton = () => {
  return (
    <main>
      <section>
        <div className="my-0 mx-auto animate-pulse flex flex-col page-container w-full transition-[width] duration-500">
          <div className="bg-slate-200 rounded h-screen" />
        </div>
      </section>
    </main>
  )
}

export default DesktopPreviewSkeleton
