import { call, fork, put, take, takeLatest } from "redux-saga/effects"
import * as actions from "redux/actions/pageManager"
import * as strategyActions from "redux/actions/strategyDesign"

import {
  CATEGORY_LIST_REQUESTED,
  CATEGORY_GET_REQUESTED,
  CATEGORY_SAVE_REQUESTED,
  CATEGORY_UPDATE_REQUESTED,
  CATEGORY_DELETE_REQUESTED,
  COMPONENTS_LIST_REQUESTED,
  ADD_ATOM_PAGE_REQUESTED,
  DESIGN_FIELDS_VALUE_SAVE_SUCCEEDED,
} from "redux/types"
import { API_GET, API_POST } from "helpers/api"

function* watchPageManager() {
  yield takeLatest(CATEGORY_LIST_REQUESTED, getCategoryList)
  yield takeLatest(CATEGORY_GET_REQUESTED, getCategory)
  yield takeLatest(CATEGORY_SAVE_REQUESTED, getCategorySave)
  yield takeLatest(CATEGORY_UPDATE_REQUESTED, getCategoryUpdate)
  yield takeLatest(CATEGORY_DELETE_REQUESTED, getCategoryDelete)
  yield takeLatest(COMPONENTS_LIST_REQUESTED, getComponentsList)
  yield takeLatest(ADD_ATOM_PAGE_REQUESTED, getAddAtomPage)
}

function* getCategoryList(action) {
  try {
    const res = yield call(apiGetCategoryList, action.payload)
    yield put(actions.categoryListSucceed(res?.data))
  } catch (err) {
    yield put(actions.categoryListFailed())
  }
}
function* getCategory(action) {
  try {
    const res = yield call(apiGetCategory, action.payload)
    yield put(actions.categoryGetSucceed(res?.data))
  } catch (err) {
    yield put(actions.categoryGetFailed())
  }
}
function* getCategorySave(action) {
  try {
    const res = yield call(apiGetCategorySave, action.payload)
    yield put(actions.categorySaveSucceed(res?.data))
  } catch (err) {
    yield put(actions.categorySaveFailed())
  }
}
function* getCategoryUpdate(action) {
  try {
    const res = yield call(apiGetCategoryUpdate, action.payload)
    yield put(actions.categoryUpdateSucceed(res?.data))
  } catch (err) {
    yield put(actions.categoryUpdateFailed())
  }
}
function* getCategoryDelete(action) {
  try {
    const res = yield call(apiGetCategoryDelete, action.payload)
    yield put(actions.categoryDeleteSucceed(res?.data))
  } catch (err) {
    yield put(actions.categoryDeleteFailed())
  }
}
function* getComponentsList(action) {
  try {
    const res = yield call(apiGetComponentsList, action.payload)
    yield put(actions.componentsListSucceed(res?.data))
  } catch (err) {
    yield put(actions.componentsListFailed())
  }
}
function* getAddAtomPage(action) {
  try {
    const getAtomPageRes = yield call(apiGetAddAtomPage, action.payload)
    if (getAtomPageRes.data.success) {
      const {RequestId, RequestType, LanguageCode} = action.payload
      yield put(strategyActions.saveDesignFieldsValueRequested({
        LanguageCode,
        RequestId,
        RequestType,
        strategies: getAtomPageRes.data.result.strategies,
      }))
      yield take(DESIGN_FIELDS_VALUE_SAVE_SUCCEEDED)
      yield put(actions.addAtomPageSucceed(getAtomPageRes?.data))
    } else {
      yield put(actions.addAtomPageFailed())
    }
  } catch (err) {
    yield put(actions.addAtomPageFailed())
  }
}

const apiGetCategoryList = (payload) => {
  return API_GET(`${process.env.REACT_APP_DISPLAY_DOMAIN}/api/contentcategory/list/${payload}`)
}
const apiGetCategory = (payload) => {
  return API_GET(`${process.env.REACT_APP_DISPLAY_DOMAIN}/api/contentcategory/get/${payload}`)
}
const apiGetCategorySave = (payload) => {
  return API_POST(`${process.env.REACT_APP_DISPLAY_DOMAIN}/api/contentcategory/save`, payload)
}
const apiGetCategoryUpdate = (payload) => {
  return API_POST(`${process.env.REACT_APP_DISPLAY_DOMAIN}/api/contentcategory/update`, payload)
}
const apiGetCategoryDelete = (payload) => {
  return API_POST(`${process.env.REACT_APP_DISPLAY_DOMAIN}/api/contentcategory/delete`, payload)
}
const apiGetComponentsList = (payload) => {
  return API_GET(`${process.env.REACT_APP_DISPLAY_DOMAIN}/api/v1/strategydesign/get-components?SourceId=${payload}`)
}
const apiGetAddAtomPage = (payload) => {
  const {DefinitionId, LanguageCode} = payload
  return API_GET(`${process.env.REACT_APP_DISPLAY_DOMAIN}/api/v1/strategydesign/get-design-fields-value`, {DefinitionId, LanguageCode})
}

export default function* pageManager() {
  yield fork(watchPageManager)
}
