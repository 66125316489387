import { useQuery } from "@tanstack/react-query"
import { API_POST } from "helpers/api"

export const useReservationDetail = (id) => {
  const query = useQuery({
    queryKey: ["corporatedetail", id],
    queryFn: () =>
      API_POST(`${process.env.REACT_APP_PANEL_DOMAIN}/api/reservation/corporatedetail`, {
        RequestId: id,
      }),
  })

  return query
}
