import * as type from "redux/types"

export const countryCodesRequested = (data) => ({
  type: type.GET_COUNTRY_CODES_REQUESTED,
  payload: data,
})

export const countryCodesSucceed = (data) => ({
  type: type.GET_COUNTRY_CODES_SUCCEEDED,
  payload: data,
})

export const countryCodesFailed = () => ({
  type: type.GET_COUNTRY_CODES_FAILED,
})
