import { call, fork, put, takeLatest } from "redux-saga/effects"
import * as actions from "redux/actions/pageSave"

import { PAGE_SAVE_REQUESTED } from "redux/types"
import { API_POST } from "helpers/api"

function* watchSavePage() {
  yield takeLatest(PAGE_SAVE_REQUESTED, getPageSave)
}

function* getPageSave(action) {
  try {
    const res = yield call(apiGetPageSave, action.payload)
    yield put(actions.pageSaveSucceed(res?.data))
  } catch (err) {
    yield put(actions.pageSaveFailed())
  }
}

const apiGetPageSave = (payload) => {
  return API_POST(`${process.env.REACT_APP_DISPLAY_DOMAIN}/api/page/save`, payload)
}

export default function* pageSave() {
  yield fork(watchSavePage)
}
