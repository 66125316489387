import { useState, useEffect } from "react"
import Icon from "./Icon"

const StepBar = (props) => {
  let { page, length, labels } = props

  const [percent, setPercent] = useState(100 / length + 1)

  useEffect(() => {
    let width = 100 / length + 1
    for (let i = 0; i < length + 1; i++) if (i === page) setPercent(width * i)
  }, [page, length])

  let links = []

  labels.map((label, index) => {
    links.push({
      text: label,
      active: page >= index + 1,
      done: page > index + 1,
    })
    return true
  })

  return (
    <>
      <div className="step-bar relative bg-white border-b-4 border-blue-50 px-10 py-4">
        <div className="w-full flex flex-col items-center h-full">
          <nav className="flex w-full gap-8 lg:gap-0 font-medium overflow-auto">
            {links.map(({ text, active, done }, index) => (
              <div
                className={`flex-1 flex gap-2 justify-center ${active ? "text-blue-500" : "text-gray-400"}`}
                key={index}
              >
                <span
                  className={`w-6 h-6 rounded-lg  inline-flex items-center justify-center ${
                    active ? "bg-primary-25" : "bg-gray-100"
                  }`}
                >
                  {done ? (
                    <Icon
                      name="check"
                      size="16"
                      color="#2E90FA"
                    />
                  ) : (
                    index + 1
                  )}
                </span>
                <span className="whitespace-nowrap">{text}</span>
              </div>
            ))}
          </nav>

          <div
            className="absolute left-0 -bottom-1 h-1 bg-blue-500 rounded-tr rounded-br transition-all duration-500 ease-[cubic-bezier(0.65, 0.05, 0.36, 1)]"
            style={{ width: `${percent}%` }}
          />
        </div>
      </div>
    </>
  )
}

export default StepBar
