import React from "react"
import { Modal, Button } from "rsuite"
import { useSettingsLinks } from "pages/Settings"
import {clientUserUpdateRequested} from "../../../redux/actions/clientUserUpdate";
import {useDispatch, useSelector} from "react-redux";

/**
 * @typedef {"makeActive" | "default"} ModalType
 *
 * @param {*} data User object
 * @param {boolean} open Visibility of the modal
 * @param {ModalType} type default or makePassive
 * @param {() => void} onSuccess on success callback
 * @param {() => void} onFailed on Failed callback
 * @param {() => void} onClose on close callback
 * @returns
 */

const UserActivateModal = (props) => {
  const dispatch = useDispatch()
  const clientAccountUpdateStatus = useSelector(state => state.clientUserUpdate.list)
  const handleOnSetStatus = () => {

    console.log(props);
    let formData = [props.activateModalData].reduce((acc, value) => {
      const departments = []
      departments.push(...value.departments.map(dep => dep.departmentId *1))
      acc.push(
          {
            userid: value.userId,
            name: value.name,
            surname: value.surname,
            DepartmentIds: departments,
            RoleId: value.role[0].roleId* 1,
            status: value.changeStatusVal,
            Email :value.email,
            phoneNumber :value.phoneNumber,
          })
      return acc
    }, [])
    dispatch(clientUserUpdateRequested(...formData))

  }

  if (clientAccountUpdateStatus?.success){
    props.onSuccess()
  }

  return (
    <>
      <Modal
        open={props.open}
        onClose={props.onClose}
        className="w-[382px] !mt-0 absolute top-1/2 left-1/2 transform !-translate-x-1/2 !-translate-y-1/2"
      >
        <Modal.Body className="flex justify-center items-center flex-col text-center gap-3 mb-3">
          <p className="font-medium text-lg">Aktife Al</p>
          <p className="text-gray-500 text-sm">
            Seçtiğiniz kullanıcı artık sistemi kullanabilecektir.
          </p>
        </Modal.Body>
        <Modal.Footer className="flex">
          <Button
            onClick={props.activateModalHandleClose}
            className="btn w-full bg-white text-gray-600 border border-solid border-gray-300 mr-2 hover:!text-white hover:!bg-gray-600 hover:!border-gray-600"
          >
            Vazgeç
          </Button>
          <Button
            onClick={handleOnSetStatus}
            className="btn w-full bg-primary-500 text-white border border-solid border-primary-500 hover:!text-white hover:!bg-primary-700 hover:!border-primary-700"
          >
            Aktife Al
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

const useUserActivateModal = () => {
  const [
    activateModalOpen, setActivateModalOpen] = React.useState(false)
  const [type, setType] = React.useState(null)
  const [activateModalData, setActivateModalData] = React.useState(null)
  const { links } = useSettingsLinks("userManagement")
  /**
   * @param {*} data
   * @param {ModalType} type
   */
  const activateModalHandleOpen = (data, type) => {
    console.log(data);
    setActivateModalData(data)
    setActivateModalOpen(true)
    setType(type || null)
  }

  const activateModalHandleClose = () => {
    setActivateModalOpen(false)
  }

  const activateModalHandleOnSuccess = () => {
    setActivateModalOpen(false)
    window.location.replace(links.link)
  }

  return {
    activateModalOpen,
    activateModalData,
    type,
    activateModalHandleOpen,
    activateModalHandleClose,
    onSuccess: activateModalHandleOnSuccess,
  }
}

export { useUserActivateModal }
export default UserActivateModal
