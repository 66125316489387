/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"

import MainHeader from "components/Headers/MainHeader"
import Icon from "components/Icon"
import OutsideAlerter from "../OutsideAlerter"
import Modal from "components/Modal"
import MyReservationsApproveModal from "components/MyReservationsApproveModal"
import TextEditor from "components/TextEditor"
import MyReservationSkeleton from "skeletons/MyReservation"

import pdf from "assets/img/pdf.png"
import excel from "assets/img/excel.png"

// tooltip
import tippy, { createSingleton } from "tippy.js"

// table
import DataGrid, {
  Column,
  ColumnChooser,
  ColumnFixing,
  SearchPanel,
  Pager,
  Paging,
  Selection,
  FilterRow,
  HeaderFilter,
  Sorting,
} from "devextreme-react/data-grid"
import { locale, loadMessages } from "devextreme/localization"
import trMessages from "localization/TR/devextreme.json"

// Requests
import { milesAndSmilesRequested } from "redux/actions/milesAndSmiles"
import { myReservationsApproveRequested } from "redux/actions/myReservationsApprove"
import { clientConfigGetRequested } from "redux/actions/clientConfigurationGet"

import { exportToPDF } from "utils/exportToPdf"
import { exportToExcel } from "utils/exportToExcel"
import { printData } from "utils/printData"

// table localization
loadMessages(trMessages)
locale(navigator.language || navigator.browserLanguage)

const MyReservations = (props) => {
  let { isLogged } = props

  const dispatch = useDispatch()

  const filterListEl = useRef(null)
  const filterRightAreaRef = useRef(null)

  const fileTypes = ["excel"] // , "pdf"
  const headers = [
    "Miles & Smiles Membership ID",
    "Sadakat Değeri",
    "Rez. No",
    "Rez. Yapan Adı Soyadı",
    "Rez. Tarihi",
    "Check-In",
    "Check-Out",
    "Toplam İşlem Değeri",
    "Para Birimi",
    "İndirim Yapılmış Fiyat",
    "Para Birimi",
  ]

  const milesAndSmiles = useSelector((state) => state.milesAndSmilesList.list)
  const milesAndSmilesLoading = useSelector((state) => state.milesAndSmilesList.loading)
  const clientConfigurationGet = useSelector((state) => state.clientConfigurationGet?.data)

  const [SelectedFileToImg, setSelectedFileToImg] = useState(excel)
  const [SelectedFile, setSelectedFile] = useState("excel")
  const [ActiveFilter] = useState({
    filterStatus: [],
  })
  const [ActiveDomainProduct, setActiveDomainProduct] = useState()
  const [DomainProducts, setDomainProducts] = useState([])
  const [TableLoaded, setTableLoaded] = useState(false)
  const [IsOpenTableDdown, setIsOpenTableDdown] = useState(null)
  const [FilterButtonClicked, setFilterButtonClicked] = useState(false)
  const [TableDraggableLines, setTableDraggableLines] = useState(false)
  const [FilterButtonPosition, setFilterButtonPosition] = useState({ display: "none" })
  const [FilteredHeader] = useState(null)
  const [SelectedRowsData, setSelectedRowsData] = useState([])
  const [SelectedReservationNo] = useState()
  const [PrintModal, setPrintModal] = useState(false)
  const [ExportModal, setExportModal] = useState(false)
  const [EditorData, setEditorData] = useState()

  const [ConfirmReservationModal, setConfirmReservationModal] = useState({
    show: false,
    type: "single", // multiple , table
  })

  let tableButton = null

  const [TableStates, setTableStates] = useState({
    cellAlignments: "left",
    dataGrid: {
      id: "gridContainer",
      allowColumnReordering: true,
      allowColumnResizing: true,
      columnAutoWidth: true,
      showBorders: true,
      showScrollbar: "always",
      dataSource: [],
      noDataText: "Aranılan kriterde rezervasyon bulunamadı.",
      onContentReady: (e) => {
        let columnChooserView = e.component.getView("columnChooserView")
        if (!columnChooserView["_popupContainer"]) {
          columnChooserView._initializePopupContainer()
          columnChooserView.render()
          columnChooserView["_popupContainer"].option("position", {
            of: e.element,
            my: "center",
            at: "center",
            offset: "0 0",
          })
        }

        setTableLoaded(true)

        setTimeout(() => {
          createSingleton(tippy(".dx-widget .dx-datagrid .dx-row > td span.tooltip"), {
            delay: 300,
            placement: "top", // default
            // The props in the current `tippyInstance` will override the ones above
            moveTransition: "transform 0.2s ease-out",
          })

          tippy(".table-column-chooser")
        }, 100)
      },

      onEditorPreparing: (e) => {
        let placeholderText = "Ara"
        if (e.parentType === "filterRow") {
          /*if(e.dataType ===  'date'){
            placeholderText = 'Seç'
          }*/
          e.editorOptions.placeholder = placeholderText
        }
      },
      onToolbarPreparing: (e) => {
        for (let i = e.toolbarOptions.items.length - 1; i >= 0; i--) {
          let item = e.toolbarOptions.items[i]
          if (item.name === "columnChooserButton" || item.name === "exportButton") {
            e.toolbarOptions.items.splice(i, 1)
          }
        }
        for (let item of e.toolbarOptions.items) {
          if (item["name"] === "searchPanel") {
            item.location = "before"
          }
        }

        e.toolbarOptions.items.unshift({
          name: "columnChooser",
          widget: "dxButton",
          showText: "inMenu",
          options: {
            icon: "icon icon-edit",
            text: "Kolon Seçici",
            elementAttr: {
              class: "table-column-chooser",
              "data-tippy-content": "Düzenle",
            },
            onClick: function () {
              if (!e.component._isHidden) {
                e.component._isHidden = true
                e.component.showColumnChooser()
                setTableDraggableLines(true)
              } else {
                e.component._isHidden = false
                e.component.hideColumnChooser()
                setTableDraggableLines(false)
              }

              setTimeout(() => {
                resize()
              }, 0)
            },
          },
          location: "after",
        })

        /*e.toolbarOptions.items.push({
          widget: 'dxButton',
          showText : 'none',
          options: {
            icon: 'user',
            text: 'Export',
            onClick: function () {
              e.component.exportToExcel(false);
            }
          },
          location: 'after'
        });

        */
      },

      onSelectionChanged: function (e) {
        //let grid = e.component;
        let selectedRowsData = e.selectedRowsData
        setSelectedRowsData(selectedRowsData)
      },
    },
    selection: {
      mode: "multiple",
      selectAllMode: "allPages",
      showCheckBoxesMode: "always",
    },
    filterRow: {
      visible: FilterButtonClicked,
    },
    headerFilter: {
      visible: FilterButtonClicked,
    },
    paging: {
      defaultPageSize: 10,
    },
    pager: {
      visible: true,
      allowedPageSizes: [10, 20, 30],
      displayMode: "full",
      showPageSizeSelector: true,
      showInfo: true,
      showNavigationButtons: true,
    },
    columnChooser: {
      enabled: true,
      mode: "select",
    },
  })

  const handleFileChange = (e) => {
    const fileType = e.target.value
    switch (fileType) {
      case "pdf":
        setSelectedFile("pdf")
        setSelectedFileToImg(pdf)
        break
      case "excel":
        setSelectedFile("excel")
        setSelectedFileToImg(excel)
        break
      default:
        setSelectedFile("excel")
        setSelectedFileToImg(excel)
    }
  }

  const filterButtonClicked = () => {
    setFilterButtonClicked(!FilterButtonClicked)
    setTableStates({
      ...TableStates,
      dataGrid: {
        ...TableStates.dataGrid,
        dataSource: milesAndSmiles,
      },
      // rowWidth: TableStates.filterRow.visible ? 122 : 136,
      filterRow: {
        ...TableStates.filterRow,
        visible: !TableStates.filterRow.visible,
      },
      headerFilter: {
        ...TableStates.headerFilter,
        visible: !TableStates.filterRow.visible,
      },
    })
  }

  const resize = () => {
    let paddingFromContent = 40 // "p-10" from section content
    let table = document.getElementById(TableStates.dataGrid.id)
    let tableRect = table?.getBoundingClientRect()
    let rightPosition = tableRect?.right
    let right = (window.innerWidth - rightPosition + paddingFromContent).toFixed(0) + "px"
    setFilterButtonPosition({
      ...FilterButtonPosition,
      right,
      display: "block",
    })
  }

  const ToggleConfirmationModal = () => {
    setConfirmReservationModal({
      show: !ConfirmReservationModal.show,
      type: SelectedRowsData.length === 1 ? "single" : SelectedRowsData.length > 1 ? "multiple" : undefined,
    })
  }

  const approveReservation = () => {
    dispatch(
      myReservationsApproveRequested({
        ProductType: 1,
        BookingCode: SelectedReservationNo,
        IsReject: false,
        RejectReason: 0,
        ApproverDescription: EditorData,
      }),
    )
    setConfirmReservationModal({
      ...ConfirmReservationModal,
      show: false,
    })
  }

  /*const setShowReservationModal = (reservationNo) => {
    setSelectedReservationNo(reservationNo)
    setConfirmReservationModal({
      ...ConfirmReservationModal,
      show: true,
    })
  }*/

  useEffect(() => {
    if (ActiveFilter !== undefined) {
      let filteredDataSource =
        ActiveFilter?.filterStatus?.length === 0
          ? milesAndSmiles
          : milesAndSmiles.filter((row) => ActiveFilter.filterStatus.includes(row.confirmationStatus))

      setTableStates({
        ...TableStates,
        dataGrid: {
          ...TableStates.dataGrid,
          dataSource: filteredDataSource,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterListEl, ActiveFilter, milesAndSmiles])

  useEffect(() => {
    resize()
    window.addEventListener("resize", resize)
    return () => window.removeEventListener("resize", resize)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterRightAreaRef, TableLoaded, FilterButtonClicked])

  useEffect(() => {
    if (!tableButton) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      tableButton = createSingleton(tippy(".table-button", {}), {
        delay: 100,
        placement: "top", // default
        // The props in the current `tippyInstance` will override the ones above
        moveTransition: "transform 0.2s ease-out",
      })
    }
  }, [])

  useEffect(() => {
    dispatch(clientConfigGetRequested())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (clientConfigurationGet) {
      const arr = []
      setActiveDomainProduct(clientConfigurationGet?.domainProducts[0]?.id)
      clientConfigurationGet?.domainProducts.map((e) => arr.push({ label: e?.name, value: e?.id }))
      setDomainProducts(arr)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientConfigurationGet])

  useEffect(() => {
    dispatch(
      milesAndSmilesRequested({
        SortColumn: "BookingDate",
        Page: 1,
        PageSize: 1000,
        CheckInDateFrom: null,
        CheckInDateTo: null,
      }),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (milesAndSmiles.length) {
      setTableStates({
        ...TableStates,
        dataGrid: {
          ...TableStates.dataGrid,
          dataSource: milesAndSmiles,
        },
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [milesAndSmiles])

  return (
    <>
      <MainHeader isLogged={isLogged} />
      <main>
        {milesAndSmilesLoading && <MyReservationSkeleton />}
        {!milesAndSmilesLoading && (
          <section className="p-10 my-0 mx-auto max-w-[1440px]">
            <div className="page-info flex items-center flex-wrap">
              <div className="page-info-text mr-4">
                <p className="text-gray-800 font-semibold text-3xl">Sadakat Programı</p>
                <p className="mt-1 font-base text-gray-500">Miles & Smiles programını görüntüleyin ve yönetin.</p>
              </div>

              {milesAndSmiles && (
                <div className="relative button-area mt-3 xl:mt-0 ml-auto">
                  <button
                    onClick={ToggleConfirmationModal}
                    data-tippy-content={"Onayla"}
                    className={`${
                      SelectedRowsData.length > 0 ? "" : "hidden"
                    } table-button relative bg-blue-50 border border-blue-50 drop-shadow-[0_1px_2px_rgba(16,24,40,.05)] w-11 h-11 rounded-lg mr-2 last:mr-0`}
                  >
                    <Icon
                      className="absolute left-1/2 top-1/2 -translate-y-2/4 -translate-x-2/4"
                      name={"check-square"}
                      size={"20"}
                      color={"#2E90FA"}
                    />
                  </button>
                  <button
                    onClick={() => setExportModal(true)}
                    data-tippy-content={"Dışa Aktar"}
                    className="table-button relative bg-blue-50 border border-blue-50 drop-shadow-[0_1px_2px_rgba(16,24,40,.05)] w-11 h-11 rounded-lg mr-2 last:mr-0"
                  >
                    <Icon
                      className="absolute left-1/2 top-1/2 -translate-y-2/4 -translate-x-2/4"
                      name={"external-link"}
                      size={"20"}
                      color={"#2E90FA"}
                    />
                  </button>
                  <button
                    onClick={() => setPrintModal(true)}
                    data-tippy-content={"Yazdır"}
                    className="table-button relative bg-blue-50 border border-blue-50 drop-shadow-[0_1px_2px_rgba(16,24,40,.05)] w-11 h-11 rounded-lg mr-2 last:mr-0"
                  >
                    <Icon
                      className="absolute left-1/2 top-1/2 -translate-y-2/4 -translate-x-2/4"
                      name={"printer"}
                      size={"20"}
                      color={"#2E90FA"}
                    />
                  </button>
                </div>
              )}
            </div>
            {milesAndSmiles && (
              <>
                {!TableDraggableLines && (
                  <div className="filter-area relative z-10">
                    <div
                      ref={filterRightAreaRef}
                      className="filter-box absolute top-10 mt-[2px] right-20"
                      // style={{ ...FilterButtonPosition }}
                    >
                      <button
                        onClick={() => filterButtonClicked()}
                        className={`btn white ${FilterButtonClicked ? "active hover:!bg-white" : ""} px-8`}
                      >
                        <span>Filtrele {FilteredHeader ? `(${FilteredHeader.length})` : ""}</span>
                        <Icon
                          name="filters-lines"
                          size="20"
                          color={FilterButtonClicked ? "#2E90FA " : "#667085"}
                        />
                      </button>
                    </div>
                  </div>
                )}
                <DataGrid
                  className={`${TableDraggableLines ? "draggable-active" : ""} ${
                    FilterButtonClicked ? "filters-active" : ""
                  } mt-6`}
                  {...TableStates.dataGrid}
                >
                  {/*TABLE OPTIONS*/}
                  {!TableDraggableLines && false && <Selection {...TableStates.selection} />}
                  {!TableDraggableLines && <Sorting mode="multiple" />}
                  {!TableDraggableLines && (
                    <SearchPanel
                      placeholder={"Bir rezervasyon numarası veya misafir adı arayın"}
                      visible={true}
                      width="496px"
                    />
                  )}
                  {!TableDraggableLines && <HeaderFilter {...TableStates.headerFilter} />}
                  {!TableDraggableLines && <FilterRow {...TableStates.filterRow} />}
                  {!TableDraggableLines && <Paging {...TableStates.paging} />}
                  {!TableDraggableLines && <Pager {...TableStates.pager} />}
                  <ColumnFixing enabled={!TableDraggableLines} />
                  <ColumnChooser {...TableStates.columnChooser} />
                  {/*<Export enabled={true} />*/}
                  {/*TABLE OPTIONS*/}
                  {/*TABLE BODY*/}
                  <Column
                    caption="Rez. No"
                    dataField="bookingCode"
                    cellRender={(row) => <Link to={`${window.location.pathname}/${row.displayValue}`}>{row.displayValue}</Link>}
                    allowFiltering={true}
                    allowSearch={true}
                    allowHeaderFiltering={false}
                    alignment={"center"}
                    width={TableStates.rowWidth}
                  />
                  <Column
                    caption="Check-Out"
                    dataType="date"
                    cellRender={(row) => (row.text ? row.text : "-")}
                    dataField="checkOutDate"
                    allowFiltering={true}
                    allowHeaderFiltering={false}
                    width={TableStates.rowWidth}
                  />
                  <Column
                    caption="Oluşturulma Tarihi"
                    dataType="date"
                    cellRender={(row) => (row.text ? row.text : "-")}
                    dataField="bookingDate"
                    allowFiltering={true}
                    allowHeaderFiltering={false}
                    width={TableStates.rowWidth}
                  />
                  <Column
                    caption="Toplam İşlem Değeri"
                    dataField="totalSalePrice"
                    cellRender={(row) =>
                      Intl.NumberFormat("tr-TR", {
                        style: "currency",
                        currency: row.data.totalSalePriceCurrency,
                      }).format(row.value)
                    }
                    allowFiltering={true}
                    allowSearch={true}
                    allowHeaderFiltering={false}
                    alignment={TableStates.cellAlignments}
                    width={TableStates.rowWidth}
                  />
                  <Column
                    caption={"Para Birimi"}
                    dataField={"totalSalePriceCurrency"}
                    allowFiltering={false}
                    allowHeaderFiltering={true}
                    width={TableStates.rowWidth}
                  />
                  <Column
                    caption="Rez. Yapan Adı Soyadı"
                    dataField="customerName"
                    cellRender={(row) => (
                      <span
                        className="inline truncate tooltip"
                        data-tippy-content={row.displayValue}
                      >
                        {row.displayValue}
                      </span>
                    )}
                    allowFiltering={true}
                    allowHeaderFiltering={false}
                    width={TableStates.rowWidth}
                  />
                  <Column
                    caption={"Miles & Smiles Membership ID"}
                    dataField={"loyaltyCode"}
                    allowFiltering={false}
                    allowHeaderFiltering={true}
                    width={TableStates.rowWidth}
                  />
                  <Column
                    caption={"Mil Değeri"}
                    dataField={"loyaltyValue"}
                    allowFiltering={false}
                    allowHeaderFiltering={true}
                    alignment={TableStates.cellAlignments}
                    width={TableStates.rowWidth}
                  />
                  <Column
                    caption="İşlemler"
                    width={80}
                    visible={SelectedRowsData.length <= 1}
                    cellRender={(row) => {
                      let { bookingCode } = row.row.data
                      return (
                        <div className="dropdown relative">
                          <OutsideAlerter
                            as="button"
                            className="w-full"
                            index={row.rowIndex}
                            isOutsideClick={() => setIsOpenTableDdown(null)}
                            handleClick={() =>
                              IsOpenTableDdown !== row.rowIndex ? setIsOpenTableDdown(row.rowIndex) : setIsOpenTableDdown(null)
                            }
                          >
                            <Icon
                              className="pointer-events-none"
                              name="more-vertical"
                              size="20"
                              color="#2E90FA"
                            />
                          </OutsideAlerter>
                          {IsOpenTableDdown === row.rowIndex && (
                            <ul
                              className={`dropdown-body absolute right-[12px] bg-white text-base border border-solid border-gray-100 shadow-lg mt-1 rounded-lg overflow-hidden z-10`}
                            >
                              <li className="dropdown-item">
                                <Link to={`${window.location.pathname}/${bookingCode}`}>
                                  <button className="flex items-center justify-start py-2.5 px-4 hover:bg-gray-200 w-full">
                                    <Icon
                                      className="mr-3"
                                      name="eye"
                                      size="16"
                                      color={"#667085"}
                                    />
                                    <span className="text-gray-500">Detaylı incele</span>
                                  </button>
                                </Link>
                              </li>
                              {/* {isNotificationSent && (
                                <li className="dropdown-item">
                                  <button
                                    onClick={() => setShowReservationModal(bookingCode)}
                                    className="flex items-center justify-start py-2.5 px-4 hover:bg-gray-200 w-full"
                                  >
                                    <Icon
                                      className="mr-3"
                                      name="check-square"
                                      size="16"
                                      color={"#12B76A"}
                                    />
                                    <span className="text-success-500">Onayla</span>
                                  </button>
                                </li>
                              )} */}
                            </ul>
                          )}
                        </div>
                      )
                    }}
                    fixed={!TableDraggableLines}
                    fixedPosition={"right"}
                    alignment={"center"}
                  />
                  {/*TABLE BODY*/}
                </DataGrid>
              </>
            )}
            {!milesAndSmiles && (
              <div className="welcome-wrapper flex flex-col lg:flex-row lg:items-center justify-between p-10 bg-white border border-gray-200 rounded-lg mt-6">
                <div className="text-box lg:w-5/12">
                  <p className="text-lg text-gray-800 font-semibold">Henüz hiç rezervasyon yok!</p>
                  <p className="text-lg mt-2 text-gray-500 font-normal">
                    Rezervasyon almaya başlamak için önce entegrasyonu tamamlamalısın!
                    <br />
                    <br />
                    İlk rezervasyonun oluştuğunda bu alandan görüntüleyebilirsin.
                  </p>
                </div>
                <img
                  className="mt-7 lg:mt-0 lg:w-7/12 max-w-[400px]"
                  src="assets/svg/my-reservations-welcome.svg"
                  alt=""
                />
              </div>
            )}
          </section>
        )}
      </main>

      {SelectedReservationNo && (
        <MyReservationsApproveModal
          onChange={() => setConfirmReservationModal({ ...ConfirmReservationModal, show: false })}
          onEditorChange={(data) => setEditorData(data)}
          approveButton={() => approveReservation()}
          open={ConfirmReservationModal.show}
          width={600}
          title={"Onayla"}
          headerClass={"mb-2"}
          closeButton={true}
          reservationNo={SelectedReservationNo}
        />
      )}

      <Modal
        onChange={() => {
          setPrintModal(false)
        }}
        open={PrintModal}
        title={"Yazdır"}
        width={600}
        closeButton={true}
        headerClass={"mb-1.5"}
      >
        <p className="text-base text-gray-500 mb-8">Listedeki rezervasyonlardan hangilerini yazdırmak istiyorsun?</p>
        <div className="flex flex-row w-full justify-end mt-8">
          <button
            onClick={() => {
              printData(milesAndSmiles, headers)
              setPrintModal(false)
            }}
            className="btn lg"
          >
            Tümünü Yazdır
          </button>
        </div>
      </Modal>

      <Modal
        onChange={() => {
          setExportModal(false)
        }}
        open={ExportModal}
        title={"Dışa aktar"}
        width={392}
        closeButton={true}
        headerClass={"mb-1.5"}
      >
        <p className="text-base text-gray-500 mb-8">Dışa aktarma yöntemini belirleyin.</p>
        <div className="flex flex-col gap-8">
          <div className="flex gap-7 items-center justify-between">
            <div className="flex flex-col gap-4">
              <h4 className="text-base font-semibold">Dosya türü</h4>
              {fileTypes.map((fileType) => (
                <div
                  key={fileType}
                  className="flex items-center gap-3"
                >
                  <input
                    type="radio"
                    id={fileType}
                    name="fileType"
                    value={fileType}
                    checked={SelectedFile === fileType}
                    onChange={handleFileChange}
                  />
                  <label htmlFor={fileType}>{fileType.toUpperCase()}</label>
                </div>
              ))}
            </div>
            <img
              src={SelectedFileToImg}
              alt={SelectedFile}
            />
          </div>
          <button
            onClick={() => {
              SelectedFile === "excel" ? exportToExcel(milesAndSmiles, headers) : exportToPDF(milesAndSmiles)
            }}
            className="btn lg"
          >
            Tümünü Dışa Aktar
          </button>
        </div>
      </Modal>

      {SelectedRowsData.length > 0 && (
        <Modal
          onChange={() => {
            setConfirmReservationModal({ ...ConfirmReservationModal, show: false })
          }}
          open={ConfirmReservationModal.show}
          title={"Onayla"}
          width={600}
          closeButton={true}
          headerClass={"mb-2"}
        >
          {ConfirmReservationModal.type === "multiple" ? (
            <p className="text-base text-gray-500 mb-8">Konfirme onaylaması bekleyen tüm rezervasyonları onaylamak üzeresin.</p>
          ) : (
            <>
              <p className="text-base text-gray-500 mb-8">
                {SelectedRowsData[0].ReservationNo} numaralı rezervasyonu direkt onaylayabilir, istersen beraberinde bir açıklama
                ekleyebilirsin.
              </p>
            </>
          )}
          <div className="editor-area">
            <p className="text-gray-800 font-medium text-sm mb-4">Açıklama</p>
            <TextEditor />
          </div>
          <div className="flex flex-row w-full justify-end mt-8">
            <button
              onClick={() => setConfirmReservationModal({ ...ConfirmReservationModal, show: false })}
              className={`btn lg ${ConfirmReservationModal.type === "single" ? "outline" : "alternate"} mr-3`}
            >
              {ConfirmReservationModal.type === "single" ? "Vazgeç" : "Seçilenleri Onayla"}
            </button>
            <button
              onClick={() => setConfirmReservationModal({ ...ConfirmReservationModal, show: false })}
              className="btn lg"
            >
              {ConfirmReservationModal.type === "single" ? "Rezervasyonu Onayla" : "Tümünü Onayla"}
            </button>
          </div>
        </Modal>
      )}
    </>
  )
}

export default MyReservations
