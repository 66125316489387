import { useEffect, useState } from "react"
import { Link } from "react-router-dom"

import Icon from "components/Icon"

const Addon = (props) => {
  let { title, status, description, price, addonId } = props

  const [stat, setStat] = useState({})

  useEffect(() => {
    if (status) {
      setStat({ text: "Kurulum bekliyor", style: "text-warning-500 bg-warning-50", iconName: "check-circle" })
    } else {
      setStat({ text: "Etkinleştirildi", style: "text-success-500 bg-success-50", iconName: "check" })
    }
  }, [status])

  return (
    <div className="p-4 flex flex-col bg-white border rounded-lg min-h-[226px]">
      <div className="flex items-center mb-[10px] gap-2">
        <div>
          <Icon
            color="currentColor"
            name="marketplace"
          />
        </div>
        <span className="text-xl font-semibold text-gray-800">{title}</span>
      </div>
      <span className={`mb-2 text-x flex items-center w-fit px-2 py-[2px] rounded-2xl text-xs gap-[6px] ${stat.style}`}>
        <Icon
          color="currentColor"
          name={stat.iconName}
          size={"9"}
        />
        {stat.text}
      </span>
      <p className="font-normal text-gray-500 text-sm leading-5 line-clamp-3">{description}</p>
      <div className="mt-auto">
        <div className="flex justify-between items-center mt-2">
          <span className="text-sm text-gray-800 font-normal">
            {price?.Fee === undefined || price?.Fee === 0
              ? "Ücretsiz"
              : `${price?.FeeCurrency}${price?.Fee}'dan başlayan fiyatlarla`}
          </span>
          <Link to={`${window.location.pathname}/${addonId}`}>
            <button className="text-sm text-blue-500 font-normal">Detaylı İncele</button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Addon
