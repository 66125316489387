import React from "react"
import { useNavigate } from "react-router-dom"

import {Alert, Icon} from "components"

const EditorBtnGroup = ({
  Visibility,
  Flag,
  setOpenModal,
  OpenModal,
  rejectButtonClick,
  saveAndPublish,
  saveAgreementLoading,
  handleDefaultButtonClick,
}) => {
  const navigate = useNavigate()

  return (
    <>
      <div className="flex items-center gap-2">
        {!Visibility.default && Flag ? (
          <div
            className="flex items-center gap-2 cursor-pointer"
            onClick={handleDefaultButtonClick}
          >
            <Icon
              name="rotate-ccw"
              size="16"
              color="#2E90FA"
            />
            <p className="text-blue-500 text-sm">Varsayılan metine geri dön</p>
          </div>
        ) : (
          <div />
        )}
      </div>
      <div className="flex gap-4">
        <button
          className={`btn outline`}
          onClick={() => {
            navigate(`/contracts`)
          }}
        >
          Vazgeç
        </button>
        <button
          disabled={!Flag}
          onClick={() => {
            setOpenModal(true)
          }}
          className="btn"
        >
          Kaydet ve Yayınla
        </button>
      </div>
      {OpenModal && (
        <Alert
          iconName={""}
          approveButtonText="Değiştir"
          title="Sözleşmeyi değiştirmek istediğinize emin misiniz?"
          rejectButtonOnClick={rejectButtonClick}
          approveButtonOnClick={saveAndPublish}
          loading={saveAgreementLoading}
        >
          <p className="text-sm text-gray-500">Yaptığınız değişiklikler ürününüze doğrudan yansıyacaktır.</p>
        </Alert>
      )}
    </>
  )
}

export default EditorBtnGroup
