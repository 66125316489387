import React, { useRef, useState } from "react"

// wrappers
import PartialWrapper from "../../../../PartialWrapper"

// constants
import { evaGlobalImageUrl } from "constants/index"

// components
import { Icon, Subtitle, TextInput, ToggleBox } from "components"
import { SelectPicker } from "rsuite"

// utils

const NavigationListEdit = (props) => {
  let { editNavList, setEditNavList } = props

  // refs
  const formRef = useRef()
  const UploadFileRef = useRef()

  const [UploadedImageInfo, setUploadedImageInfo] = useState(null)
  const [DefaultImageInfo, setDefaultImageInfo] = useState(null)

  //funcs
  const clearImageState = () => {
    setUploadedImageInfo(null)
    setDefaultImageInfo(null)
  }

  const readImage = (file) => {
    const reader = new FileReader()
    reader.addEventListener("load", (event) => {
      setUploadedImageInfo({
        src: event.target.result,
        size: file.size,
        name: file.name,
        fileForBinary: file,
      })
    })
    reader.readAsDataURL(file)
    UploadFileRef.current.value = ""
  }

  return (
    <PartialWrapper
      className={`title-layout${editNavList ? " active" : ""}`}
      goBackOnClick={() => {
        setEditNavList(null)
        clearImageState()
      }}
    >
      <Subtitle>Edit item</Subtitle>

      <div className="input-wrapper mt-4">
        <TextInput
          title={"Metin"}
          disabled={true}
          value={editNavList?.item ? editNavList?.item : undefined}
          onChange={() => false}
        />
      </div>

      <div className="input-wrapper mt-4">
        <ToggleBox
          title={"Show Icon"}
          disabled={true}
          defaultChecked={true}
          onToggle={(e) => console.log(e)}
        />
      </div>

      <div className="background-image-area text-sm mt-4">
        <div className="flex items-center">
          <p className="text-gray-800 font-medium">Icon</p>
          <div className="progress-area ml-auto">
            {!UploadedImageInfo && !DefaultImageInfo && (
              <p
                className="text-blue-500 cursor-pointer font-medium hover:text-blue-300 transition"
                onClick={() => UploadFileRef.current.click()}
              >
                Ekle
              </p>
            )}
            {(UploadedImageInfo || DefaultImageInfo) && (
              <div className="icon-box flex items-center gap-4">
                <Icon
                  className="cursor-pointer hover:opacity-50"
                  name="refresh-cw"
                  size={20}
                  color={"#1D2939"}
                  onClick={() => UploadFileRef.current.click()}
                />
                <Icon
                  className="cursor-pointer hover:opacity-50"
                  name="trash-2"
                  size={20}
                  color={"#1D2939"}
                  onClick={clearImageState}
                />
              </div>
            )}
          </div>
          <form
            ref={formRef}
            className="hidden"
          >
            <input
              hidden
              type="file"
              id="fileUploadInputForSlider"
              ref={UploadFileRef}
              onChange={(e) => readImage(UploadFileRef.current.files[0])}
              accept=".jpg, .jpeg, .png, .svg, .gif"
            />
          </form>
        </div>

        {DefaultImageInfo && (
          <div className="uploaded-image-area flex items-center justify-center rounded-lg py-overflow-hidden h-[220px] mt-3">
            <img
              className="max-w-full object-cover min-w-[50%] object-center bg-white/[.8] max-h-full"
              src={`${evaGlobalImageUrl}${DefaultImageInfo?.path}`}
              alt={DefaultImageInfo?.name}
            />
          </div>
        )}

        {UploadedImageInfo && (
          <div className="uploaded-image-area flex items-center justify-center rounded-lg py-overflow-hidden h-[220px] mt-3">
            <img
              className="max-w-full object-cover min-w-[50%] object-center bg-white/[.8] max-h-full"
              src={UploadedImageInfo?.src}
              alt={UploadedImageInfo?.name}
            />
          </div>
        )}

        {!UploadedImageInfo && (
          <p className="mt-3">
            Yüksek çözünürlüklü fotoğraflar, kompanent’in yüklenme hızını etkileyebilir. Fotoğrafları yüklemeden önce optimize
            etmenizi tavsiye ederiz.
          </p>
        )}
      </div>

      <div className="input-wrapper mt-7">
        <span className="text-gray-800 font-medium">Link type</span>
        <SelectPicker
          block
          className={"mt-2"}
          placement="bottomStart"
          disabled
          menuMaxHeight={164}
          //defaultValue={"1"}
          placeholder={"Link tipi seçin"}
          cleanable={false}
          data={[
            {
              label: "Home Page",
              value: "1",
            },
            {
              label: "Landing Page",
              value: "2",
            },
          ]}
          searchable={false}
          //container={() => selectPickerRef.current}
        />
      </div>
      <div className="input-wrapper mt-4">
        <TextInput
          disabled
          title="Bağlantı adresi"
          placeholder="URL..."
        />
      </div>
    </PartialWrapper>
  )
}

export default NavigationListEdit
