import {getEntiredImages} from "./GetEntiredImages";
import {dataUrlToFile} from "helpers/DataUrlToFile";
import {apiPostMediaLibrarySave} from "redux/sagas/mediaLibrary";
import AddTabSliderPattern from "../Patterns/TabSlider/AddTabSliderPattern";
import {MediaType} from "constants/index";
import {IMAGE} from "../Enums";

export const modifyTabSliderByNewData = async (thisComponent, model, imageKey) => {
	const mediaIdList = await thisComponent?.children && Promise.all(thisComponent?.children.map(async (slider, sliderIndex) => {
		const indexData = {
			sIndex: sliderIndex,
		}
		const imageComponent = slider?.children?.find(child => child?.name === IMAGE)
		if (imageComponent?.data){
			const hasImage = getEntiredImages(imageComponent?.data, imageKey)
			const alreadyUploadedImage = !!hasImage && hasImage?.startsWith("https://")
			if (hasImage && !alreadyUploadedImage) {
				return await dataUrlToFile(hasImage, imageComponent?.data?.name, {type: "image/png"}).then(file => {
					let formData = new FormData()
					formData.append("File", file)
					return apiPostMediaLibrarySave(formData).then(async response => {
						return {
							...indexData,
							mediaId: response?.data?.result?.id
						}
					})
				})
			} else {
				return  {
					...indexData,
					mediaId: null
				}
			}
		}
	}))

	return AddTabSliderPattern(model, thisComponent, await mediaIdList, MediaType.Image)

}