import React from "react"

import { Icon } from "components"

function StatusText({ status, className, text, cancelReason, ...props }) {
  const StatusContent = {
    1: {
      text: "Onaylandı",
      icon: "check",
    },
    2: {
      text: "Reddedildi",
      icon: "check",
    },
    3: {
      text: "İptal Edildi",
      icon: "clock",
    },
    4: {
      text: "Onay Bekliyor",
      icon: "check",
    },
  }
  const content = StatusContent[status]
  return (
    <div className="flex flex-col">
      <div
        className={`text-success-500
        } flex items-center gap-1.5`}
        {...props}
      >
        <Icon
          name={content.icon}
          size={16}
          color={"#4CAF50"}
        />
        <p className={`text-success-500 ml-1.5`}>{content?.text}</p>
      </div>
    </div>
  )
}

export { StatusText }
