import * as type from "redux/types"

export const addonPriceRequested = (data) => ({
  type: type.ADDON_PRICE_REQUESTED,
  payload: data,
})

export const addonPriceSucceed = (data) => ({
  type: type.ADDON_PRICE_SUCCEEDED,
  payload: data,
})

export const addonPriceFailed = () => ({
  type: type.ADDON_PRICE_FAILED,
})
