import React from "react"
import PropTypes from "prop-types"

const FontWeightBox = (props) => {
  let { title, onChange, activeFontWeight, fontWeights, ...args } = props

  return (
    <div className="font-weight-box">
      <span className="text-sm">{title}</span>
      <div className="flex justify-center text-center border border-gray-200 rounded-lg mt-2">
        {fontWeights.map((weight, key) => {
          return (
            <span
              className={`flex items-center justify-center w-full py-2 text-xs font-${weight} ${
                activeFontWeight === weight ? "bg-blue-50" : ""
              } border-l border-gray-200 cursor-pointer capitalize first:border-l-0`}
              key={key}
              onClick={() => onChange(weight)}
              {...args}
            >
              {weight}
            </span>
          )
        })}
      </div>
    </div>
  )
}

export default FontWeightBox

FontWeightBox.prototypes = {
  title: PropTypes.string,
  activeFontWeight: PropTypes.string,
  fontWeights: PropTypes.array,
  onChange: PropTypes.func,
}

FontWeightBox.defaultProps = {
  title: "Yazı Tipi Kalınlığı",
  activeFontWeight: "bold",
  fontWeights: ["normal", "bold"],
  //onChange: (e) => console.log(e),
}
