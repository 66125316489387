import React, { useState } from "react"
import { Col, Dropdown, Badge } from "rsuite"
import DataGrid, {
  Column,
  FilterRow,
  Pager,
  Paging,
  SearchPanel,
  Selection,
  Sorting,
  Toolbar,
  Item,
} from "devextreme-react/data-grid"
import { SelectPicker } from "rsuite"
import { Icon } from "components"
import { useNavigate } from "react-router-dom"
import DefineDeleteModal, { useDefineDeleteModal } from "./DefineDeleteModal"
import { useDefinePropertyType } from "utils/useDefinePropertyType"
import { useDispatch, useSelector } from "react-redux"
import { useDefineCrud } from "utils/useDefines"

const DataList = ({ data, refetch }) => {
  const navigate = useNavigate()
  const { filter } = useSelector((state) => state.define)
  const dispatch = useDispatch()
  const [TableDraggableLines] = useState(false)
  const {
    open,
    handleOpen,
    data: modalData,
    ...modalProps
  } = useDefineDeleteModal({
    onSuccess: () => {
      if (!!refetch) refetch()
    },
  })
  const { data: propertyTypeList } = useDefinePropertyType()
  const { defineCrud } = useDefineCrud()
  const tableStates = {
    cellAlignments: "center",
    rowWidth: "20%",
    dataGrid: {
      id: "gridContainer",
      allowColumnReordering: true,
      allowColumnResizing: true,
      columnAutoWidth: true,
      showBorders: true,
      showScrollbar: "always",
      keyExpr: "PropertyValueId",
      dataSource: data,
      noDataText: "Veri Yok",
    },
    paging: {
      defaultPageSize: 10,
    },
    pager: {
      visible: true,
      allowedPageSizes: [10, 20, 50],
      displayMode: "full",
      showPageSizeSelector: true,
      showInfo: true,
      showNavigationButtons: true,
    },
    selection: {
      mode: "multiple",
      selectAllMode: "allMode",
    },
  }

  const handleOnChangeType = (value) => {
    dispatch({
      type: "SET_FILTER",
      payload: {
        ...filter,
        type: value,
      },
    })
  }

  const handleSelectData = (selectedItems) => {
    dispatch({
      type: "SET_SELECTED_ITEMS",
      payload: selectedItems?.selectedRowsData.length > 0 ? selectedItems?.selectedRowsData : [],
    })
  }

  const handleOnChangeStatus = (value) => {
    dispatch({
      type: "SET_FILTER",
      payload: {
        ...filter,
        status: !value ? null : value === "active" ? true : false,
      },
    })
  }

  return (
    <Col xs={24}>
      <DataGrid
        className={`${TableDraggableLines ? "draggable-active" : ""} "filters-active" `}
        {...tableStates.dataGrid}
        style={{ borderRadius: "!10px" }}
        onSelectionChanged={handleSelectData}
      >
        {/*TABLE OPTIONS*/}
        {!TableDraggableLines && false && <Selection {...tableStates.selection} />}
        {!TableDraggableLines && (
          <SearchPanel
            placeholder={"Tanımlarda arama yapın"}
            visible={true}
            width="288"
            highlightCaseSensitive={false}
          />
        )}
        {/*TABLE OPTIONS*/}
        <Selection
          {...tableStates.selection}
          showCheckBoxesMode={"always"}
        />
        {!TableDraggableLines && <Sorting mode="multiple" />}
        {!TableDraggableLines && <FilterRow {...tableStates.filterRow} />}
        {!TableDraggableLines && <Paging {...tableStates.paging} />}
        {!TableDraggableLines && <Pager {...tableStates.pager} />}
        {/*TABLE OPTIONS*/}
        <Toolbar>
          <Item
            location="before"
            name="searchPanel"
          />
          <Item location="after">
            <div className="flex justify-end gap-2 w-full">
              <SelectPicker
                data={[
                  ...(propertyTypeList?.map((item) => ({
                    label: item.Name,
                    value: item.Name,
                  })) || []),
                ]}
                style={{ width: 224 }}
                searchable={false}
                cleanable={true}
                placeholder="Tür"
                onChange={handleOnChangeType}
                value={filter.type}
              />
              <SelectPicker
                data={[
                  {
                    label: "Aktif",
                    value: "active",
                  },
                  {
                    label: "Pasif",
                    value: "passive",
                  },
                ]}
                style={{ width: 224 }}
                searchable={false}
                cleanable={true}
                placeholder="Statü"
                onChange={handleOnChangeStatus}
                value={filter.status === undefined || filter.status === null ? null : filter.status ? "active" : "passive"}
              />
            </div>
          </Item>
        </Toolbar>

        {/*TABLE BODY*/}
        <Column
          caption="Tanım Adı"
          dataField="PropertyValue"
          allowFiltering={true}
          allowSearch={true}
          allowHeaderFiltering={false}
          width={tableStates.rowWidth}
          alignment={"left"}
        />
        <Column
          caption="Tür"
          dataField="PropertyName"
          allowFiltering={false}
          allowHeaderFiltering={true}
          width={tableStates.rowWidth}
          alignment={"left"}
        />
        <Column
          caption="Statü"
          dataField="PropertyValueStatus"
          cellRender={(row) => {
            return row.data.PropertyValueStatus === 1 ? (
              <Badge
                className="p-1 px-2 rounded-xl bg-transparent text-green-500"
                content="Aktif"
              />
            ) : (
              <Badge
                className="p-1 px-2 rounded-xl bg-transparent text-red-500"
                content="Pasif"
              />
            )
          }}
          allowFiltering={true}
          allowHeaderFiltering={false}
          width={tableStates.rowWidth / 2}
          alignment={tableStates.cellAlignments}
        />
        <Column
          caption="Oluşturulma Tarihi"
          dataField="CreateDate"
          dataType="date"
          cellRender={(row) => (row.text ? row.text : "-")}
          allowFiltering={false}
          allowHeaderFiltering={true}
          width={tableStates.rowWidth}
          alignment={"left"}
        />
        <Column
          caption="Düzenleme Tarihi"
          dataField="UpdateDate"
          dataType="date"
          cellRender={(row) => (row.text ? row.text : "-")}
          allowFiltering={true}
          allowHeaderFiltering={false}
          width={tableStates.rowWidth}
          alignment={"left"}
        />
        <Column
          caption="İşlemler"
          width={"15%"}
          fixed={true}
          fixedPosition={"right"}
          cellRender={(row) => {
            const ButtonContent = (props, ref) => (
              <div
                ref={ref}
                {...props}
              >
                <Icon
                  size={24}
                  name="more-vertical"
                  className={"!bg-blue-500"}
                />
              </div>
            )

            const DropItem = (props) => (
              <Dropdown.Item
                icon={
                  <Icon
                    name={props.iconName ? props.iconName : ""}
                    size={14}
                    style={{ backgroundColor: props.color ? props.color : "#000000" }}
                  />
                }
                onClick={props.onClick}
                className="flex items-center justify-start gap-3 px-4"
                style={{ color: props.color ? props.color : "#000000" }}
              >
                {props.text}
              </Dropdown.Item>
            )

            return (
              <Dropdown
                renderToggle={ButtonContent}
                trigger={"hover"}
                placement="bottomEnd"
              >
                <DropItem
                  text="İncele"
                  iconName="eye"
                  onClick={() => navigate(`/defines/${row.data.PropertyValueId}`)}
                />
                <DropItem
                  text="Düzenle"
                  iconName="edit"
                  onClick={() => navigate(`/defines/${row.data.PropertyValueId}/edit`)}
                />
                <DropItem
                  text={row?.data?.PropertyValueStatus !== 1 ? "Aktife Al" : "Pasife Al"}
                  color={row?.data?.PropertyValueStatus !== 1 ? "#00C853" : "#F44336"}
                  iconName={row?.data?.PropertyValueStatus !== 1 ? "check-square" : "slash"}
                  onClick={() => {
                    if (row?.data?.PropertyValueStatus === 1) {
                      handleOpen({ ...row?.data, Status: "passive", PropertyValueStatus: 0 }, "makePassive")
                    } else {
                      defineCrud({ ...row?.data, Status: "active", PropertyValueStatus: 1 }).finally(() => refetch())
                    }
                  }}
                />
                <DropItem
                  text="Sil"
                  iconName="trash"
                  color={"#F44336"}
                  onClick={() => {
                    handleOpen({ ...row.data, Status: -1 })
                  }}
                />
              </Dropdown>
            )
          }}
          alignment={tableStates.cellAlignments}
        />
        {/*TABLE BODY*/}
      </DataGrid>

      <DefineDeleteModal
        {...modalProps}
        open={open}
        data={modalData}
      />
    </Col>
  )
}

export default DataList
