import React from "react"
import { Grid, Row, Col } from "rsuite"
import MainHeader from "components/Headers/MainHeader"
import Card from "components/Settings/UserManagement/Card"
import { Icon } from "components"

const generalSettings = [
  {
    key: "reservationReports",
    title: "Rezervasyon Raporu",
    description: "Rezervasyonlarınızla ilgili bilgileri  görüntüleyin ve yönetin.",
    icon: "book-open",
    link: "/reports/reservation-reports",
  },
  // {
  //   key: "reservationReports",
  //   title: "Bütçe Raporu",
  //   description: "Çalışanlarınıza gösterilecek arama sonuçlarını kişiselleştirin.",
  //   icon: "trending-up",
  //   link: "#",
  //   disable: true,
  // },
  // {
  //   key: "reservationReports",
  //   title: "Talep Raporu",
  //   description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  //   icon: "info",
  //   link: "#",
  //   disable: true,
  // },
  // {
  //   key: "reservationReports",
  //   title: "Y Raporu",
  //   description: "Müşterilerinize gönderilen otomatik yanıt e-postalarını, hatırlatmaları yönetin ve düzenleyin.",
  //   icon: "info",
  //   link: "#",
  //   disable: true,
  // },
]

const Reports = (props) => {
  let { isLogged } = props

  const ReportsListItem = ({ title, description, icon, link, disable }) => {
    return (
      <Col
        xs={24}
        sm={24}
        md={24}
        lg={8}
        xl={8}
        className="py-[16px] px-[20px]  transition-all"
      >
        <a
          href={link}
          className={`${disable ? "cursor-default text-transparent" : "cursor-pointer"}`}
        >
          <div className="flex justify-start items-start gap-[16px]">
            <div>
              <Icon
                name={icon}
                className="!bg-gray-500"
              />
            </div>
            <div>
              <p className="text-gray-800 font-medium pb-1 hover:text-blue-500">{title}</p>
              <p className="text-gray-500 text-sm">{description}</p>
            </div>
          </div>
        </a>
      </Col>
    )
  }

  function sliceGroups(arr, n) {
    let groups = []
    for (let i = 0; i < arr.length; i += n) {
      groups.push(arr.slice(i, i + n))
    }
    return groups
  }

  return (
    <>
      <MainHeader isLogged={isLogged} />
      <main>
        <section className="p-10 my-0 mx-auto max-w-[1920px] overflow-auto">
          <Grid className="w-full">
            <Row gutter={10}>
              <Col
                xs={24}
                className="my-3"
              >
                <h1 className="text-3xl text-gray-800 font-semibold mb-1">Raporlar</h1>
                <p className="text-gray-500">İncelemek istediğiniz kategoriyi seçin ve ilerleyin.</p>
              </Col>
              <Col
                xs={24}
                xl={24}
              >
                <Card>
                  {sliceGroups(generalSettings, 3).map((group, index) => (
                    <Row
                      gutter={40}
                      key={index}
                    >
                      {group.map((item, index) => (
                        <ReportsListItem
                          key={index}
                          {...item}
                        />
                      ))}
                    </Row>
                  ))}
                </Card>
              </Col>
            </Row>
          </Grid>
        </section>
      </main>
    </>
  )
}

export default Reports
