import React from "react"
import Modal from "../Modal"
import PropTypes from "prop-types"

import TextEditor from "components/TextEditor"

export default function MyReservationsApproveModal({
  title,
  width,
  closeButton,
  headerClass,
  onChange,
  onEditorChange,
  open,
  reservationNo,
  approveButton,
}) {
  return (
    <Modal
      onChange={onChange}
      open={open}
      title={title}
      width={width}
      closeButton={closeButton}
      headerClass={headerClass}
    >
      <p className="text-base text-gray-500 mb-8">
        {reservationNo} numaralı rezervasyonu direkt onaylayabilir, istersen beraberinde bir açıklama ekleyebilirsin.
      </p>
      <div className="editor-area">
        <p className="text-gray-800 font-medium text-sm mb-4">Açıklama</p>
        <TextEditor
          data={""}
          setData={onEditorChange}
        />
      </div>
      <div className="flex flex-row w-full justify-end mt-8">
        <button
          onClick={onChange}
          className={`btn outline lg single mr-3`}
        >
          Vazgeç
        </button>
        <button
          onClick={approveButton}
          className="btn lg"
        >
          Rezervasyonu Onayla
        </button>
      </div>
    </Modal>
  )
}

MyReservationsApproveModal.propTypes = {
  title: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  closeButton: PropTypes.bool,
  headerClass: PropTypes.string,
  reservationNo: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  approveButton: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onEditorChange: PropTypes.func.isRequired,
}
MyReservationsApproveModal.defaultProps = {
  title: "Modal Başlık",
  isOpen: false,
  width: 600,
  closeButton: true,
  headerClass: "",
}
