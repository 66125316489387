import React from "react"
import PropTypes from "prop-types"

// components
import { Icon } from "components"

const GoBackBtn = (props) => {
  let { onClick, disabled } = props
  return (
    <>
      <div className="goback-btn">
        <button
          className={"btn text sm"}
          onClick={onClick}
          disabled={disabled}
        >
          <Icon
            name="arrow-left"
            size={20}
            color={disabled ? "#d0d5dd" : "#2E90FA"}
          />
          <span>Geri dön</span>
        </button>
      </div>
    </>
  )
}
export default GoBackBtn

GoBackBtn.prototypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
}

GoBackBtn.defaultProps = {
  //onClick: (e) => console.log(e),
  disabled: false,
}
