import React from "react"
import PropTypes from "prop-types"

const Icon = ({ name, size, color, className, ...props }) => {
  let style = ""
  if (props.style) {
    style = props.style
    delete props.style
  }
  function getComputedStyle(size, color) {
    return {
      width: `${size}px`,
      height: `${size}px`,
      backgroundColor: color,
      transition: "all ease 150ms",
      ...style,
    }
  }
  return (
    <i
      className={`icon icon-${name} ${className}`}
      style={getComputedStyle(size, color)}
      {...props}
    />
  )
}
Icon.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
}
Icon.defaultProps = {
  size: "24",
  color: "#000",
  className: "",
}

export default Icon
