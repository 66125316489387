import React from "react"
import { Stack, Button } from "rsuite"
import { Icon } from "components"
import DefineAddModal, { useDefineAddModal } from "./DefineAddModal"
import SuccessModal, { useSuccessModal } from "./SuccessModal"
import ExportModal, { useExportModal } from "./ExportModal"
import ImportModal, { useImportModal } from "./ImportModal"

const ButtonActions = () => {
  const addModal = useDefineAddModal()
  const successModal = useSuccessModal()
  const exportModal = useExportModal()
  const importModal = useImportModal()

  return (
    <>
      <Stack
        direction="column"
        spacing={20}
        alignItems="flex-end"
      >
        <Stack spacing={10}>
          <Button
            className="btn !bg-blue-100"
            onClick={exportModal.handleOpen}
          >
            <Icon
              size={20}
              name={"arrow-up-right-from-square"}
              className={"!bg-blue-600"}
            />
          </Button>
          <Button
            className="btn !bg-blue-100"
            onClick={importModal.handleOpen}
          >
            <Icon
              size={22}
              name={"download"}
              className={"!bg-blue-600"}
            />
          </Button>
          <Button
            className="btn hover:text-white focus:text-white active:text-white !bg-blue-600"
            onClick={addModal.handleOpen}
          >
            <Icon
              size={18}
              name={"plus"}
              className={"!bg-white"}
            />
            Tanım Ekle
          </Button>
        </Stack>
      </Stack>
      <DefineAddModal
        open={addModal.open}
        onClose={addModal.handleClose}
        onSuccess={(data) => {
          addModal.handleClose()
          successModal.handleOpen(data)
        }}
      />
      <SuccessModal
        open={successModal.open && !!successModal.data}
        onClose={successModal.handleClose}
        data={successModal.data}
      />
      <ExportModal {...exportModal} />
      <ImportModal {...importModal} />
    </>
  )
}

export default ButtonActions
