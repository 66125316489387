import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import tursabImg from "../../pages/B2cDemoPage/LivePreview/img/tursab_belge.png"
import maestro from "../../pages/B2cDemoPage/LivePreview/img/credit_cards/maestro.png"
import master from "../../pages/B2cDemoPage/LivePreview/img/credit_cards/master.png"
import stripe from "../../pages/B2cDemoPage/LivePreview/img/credit_cards/stripe.png"
import visa from "../../pages/B2cDemoPage/LivePreview/img/credit_cards/visa.png"
import banner1 from "../../pages/B2cDemoPage/LivePreview/img/banner-img-1.jpg"
import holiday1 from "../../pages/B2cDemoPage/LivePreview/img/holiday_themes/1.jpg"
import holiday2 from "../../pages/B2cDemoPage/LivePreview/img/holiday_themes/2.jpg"
import holiday3 from "../../pages/B2cDemoPage/LivePreview/img/holiday_themes/3.jpg"
import holiday4 from "../../pages/B2cDemoPage/LivePreview/img/holiday_themes/4.jpg"
import popular_area1 from "../../pages/B2cDemoPage/LivePreview/img/popular_areas/1.jpg"
import popular_area2 from "../../pages/B2cDemoPage/LivePreview/img/popular_areas/2.jpg"
import popular_area3 from "../../pages/B2cDemoPage/LivePreview/img/popular_areas/3.jpg"

import Icon from "components/Icon"

const B2CMobile = (props) => {
  let { logo, color, borderRadius, fontFamily } = props

  return (
    <div style={{ fontFamily: fontFamily }}>
      <div className="lp-header relative z-20 bg-white">
        <div className="main-header border-b border-gray-200 py-5 px-4">
          <div className="lp-container flex items-center justify-between">
            <div className="logo-wrapper">
              <img
                alt="logo"
                className="object-cover max-w-[120px] max-h-[33px]"
                src={logo}
              />
            </div>
            <div className="navbar">
              <ul className="flex items-center">
                <li className="flex ml-3 first:ml-0"></li>
                <li className="flex ml-3 p-1 first:ml-0">
                  <Icon
                    name="user"
                    size="24"
                    color="#1D2939"
                  />
                </li>
                <li className="flex ml-3 p-1 first:ml-0">
                  <Icon
                    name="menu"
                    size="24"
                    color="#1D2939"
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="sub-header bg-white">
        <div className="lp-container">
          <nav>
            <ul className="flex items-center snap-x text-gray-500 font-medium whitespace-nowrap overflow-hidden">
              {[
                { text: "Otel", icon: "building" },
                { text: "Uçak", icon: "plane" },
                { text: "Tur", icon: "suitcase" },
                { text: "Transfer", icon: "car" },
                { text: "Paketle Kazan", icon: "special_days" },
              ].map((item, key) => {
                return (
                  <li
                    key={key}
                    className={`flex items-center justify-center text-center snap-start scroll-ml-4 py-4 px-2 ml-3 first:ml-0 ${
                      key === 0 ? " font-semibold border-b-2 min-w-[120px] cursor-pointer" : "cursor-not-allowed"
                    }`}
                    style={key === 0 ? { borderBottomColor: color } : {}}
                  >
                    <Icon
                      className="mr-2"
                      name={item.icon}
                      size="20"
                      color="#667085"
                    />
                    <span className="text-sm">{item.text}</span>
                  </li>
                )
              })}
            </ul>
          </nav>
        </div>
      </div>
      <div className="lp-main bg-white">
        <div className="travel-planner">
          <div
            className="search-area relative overflow-hidden z-10 bg-cover bg-[center_70%]
            bg-gray-50"
          >
            <div className="lp-container relative z-20 py-6 px-4">
              <div className="text-wrapper text-white w-11/12">
                <div className="heading text-2xl font-bold drop-shadow-sm text-gray-800">
                  Sıradaki <br /> konaklamanızı bulun.
                </div>
                <div className="text text-base font-medium drop-shadow-sm mt-4 text-gray-500">
                  Oteller, evler ve çok daha fazlası için fırsatları kaçırmayın...
                </div>
              </div>
              <div className="searchbar flex flex-col overflow-hidden !transition-all mt-6">
                {[
                  { placeholder: "Nereye gidiyorsun?", icon: "search" },
                  { placeholder: "Giriş - Çıkış tarihi seçin", icon: "calendar" },
                  { placeholder: "2 misafir, 1 oda", icon: "person" },
                ].map((v, k) => {
                  return (
                    <div
                      key={k}
                      className="input-wrapper w-full relative shadow-buttonSm border border-gray-200 overflow-hidden !transition-all mt-3"
                      style={{ borderRadius: borderRadius }}
                    >
                      <Icon
                        className="absolute top-1/2 left-2 transform -translate-y-1/2 pointer-events-none"
                        name={v.icon}
                        size="20"
                        color={`${k === 2 ? "#000" : "#667085"}`}
                      />
                      <input
                        type="text"
                        className={`w-full border-none !pl-9 disabled:cursor-not-allowed disabled:!bg-white disabled:!pointer-events-auto ${
                          k === 2 ? "placeholder:text-gray-800" : "placeholder:text-gray-500"
                        }`}
                        placeholder={v.placeholder}
                        disabled
                      />
                    </div>
                  )
                })}
                <button
                  className="btn whitespace-nowrap px-7 border-0 cursor-not-allowed !transition-all mt-3"
                  style={{ backgroundColor: color, borderRadius: borderRadius }}
                >
                  Otel ara
                </button>
              </div>
            </div>
          </div>
          <div className="features py-6 px-4">
            <div
              className="lp-container flex flex-col border border-gray-200 rounded-lg items-center"
              style={{ borderRadius: borderRadius }}
            >
              {[
                { text: "Fiyat farkı iade garantisi", icon: "rotate-ccw" },
                { text: "Güvenli online alışveriş", icon: "shield" },
                { text: "18 aya varan taksitler", icon: "credit-card" },
              ].map((v, k) => {
                return (
                  <div
                    key={k}
                    className="features-box w-full flex items-center justify-center text-center py-4"
                  >
                    <Icon
                      className="mr-2"
                      name={v.icon}
                      size="16"
                      color="#475467"
                    />
                    <span className="font-medium text-gray-500">{v.text}</span>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        {/*Banner Area*/}
        <div className="banner-area ml-3">
          {/*Banner Slider*/}
          <div className="mobile-banner-content">
            <div className="lp-container swiper-area relative flex flex-col z-10 overflow-hidden !transition-all pb-3">
              <p className="text-gray-800 font-semibold text-xl mb-2 pr-4">Kampanyalar</p>
              <Swiper
                className={"mx-0 mb-5"}
                spaceBetween={12}
                slidesPerView={"auto"}
                speed={1200}
                draggable={true}
                freeMode={true}
              >
                {[1, 2, 1, 2, 1, 2].map((v, k) => {
                  return (
                    <SwiperSlide
                      className="overflow-hidden w-10/12 transition-all rounded-lg"
                      key={k}
                    >
                      <img
                        className="absolute t-0 l-0 -z-10 h-full w-full object-cover transition-all"
                        src={banner1}
                        alt=""
                      />
                      <div className="text-area flex flex-col items-start w-9/12 h-[196px] p-4 rounded-lg">
                        <p className="text-xl font-semibold text-white drop-shadow-sm">
                          Yaz fırsatları devam ediyor! Kaçırmadan yakala
                        </p>
                        <button
                          className="btn xs border-none mt-auto text-sm cursor-not-allowed"
                          style={{ backgroundColor: color }}
                        >
                          Fırsatları gör
                        </button>
                      </div>
                    </SwiperSlide>
                  )
                })}
              </Swiper>
              <div className="swiper-pagination bottom-0 left" />
            </div>
          </div>
        </div>

        {/*Konaklama Seçenekleri*/}
        <div className="accommodation_options mt-3 overflow-auto">
          <div className="lp-container">
            <p className="text-gray-800 font-semibold text-xl px-4 mb-2">Konaklama seçenekleri</p>

            <Swiper
              className={"px-4"}
              spaceBetween={12}
              slidesPerView={"auto"}
              speed={1200}
              draggable={true}
              freeMode={true}
            >
              {[
                { text: "Bungalovlar", icon: "bungalovs" },
                { text: "Villalar", icon: "villages" },
                { text: "Çiftlik evleri", icon: "farmhouses" },
                { text: "Kayak otelleri", icon: "ski-hotels" },
                { text: "Lüks oteller", icon: "lux-hotels" },
                { text: "Butik oteller", icon: "botique-hotels" },
              ].map((v, k) => {
                return (
                  <SwiperSlide
                    className="overflow-hidden w-auto"
                    key={k}
                  >
                    <div
                      key={k}
                      className={`icon-box flex flex-col items-center border border-gray-200 text-sm font-medium text-color-800 shrink-0 w-[106px] whitespace-nowrap
                      cursor-pointer !transition-all ${k === 0 ? "shadow-xl cursor-pointer" : "cursor-not-allowed"} px-2 py-4`}
                      style={{ borderRadius: borderRadius }}
                    >
                      <Icon
                        name={v.icon}
                        size="32"
                      />
                      <span className="mt-1">{v.text}</span>
                    </div>
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </div>
        </div>

        {/*Tatil Temaları*/}
        <div className="holiday-themes mt-6">
          <div className="lp-container">
            <p className="text-gray-800 font-semibold text-xl mb-2 px-4">Tatil Temaları</p>

            <div className="relative flex items-center">
              <Swiper
                className={"px-4"}
                spaceBetween={12}
                slidesPerView={"auto"}
                speed={1200}
                draggable={true}
                freeMode={true}
              >
                {[
                  { text: "Balayı otelleri", src: holiday1 },
                  { text: "Her şey dahil oteller", src: holiday2 },
                  { text: "Yakın bölge otelleri", src: holiday3 },
                  { text: "Denize sıfır oteller", src: holiday4 },
                  { text: "Balayı otelleri", src: holiday1 },
                  { text: "Her şey dahil oteller", src: holiday2 },
                  { text: "Yakın bölge otelleri", src: holiday3 },
                  { text: "Denize sıfır oteller", src: holiday4 },
                ].map((v, k) => {
                  return (
                    <SwiperSlide
                      className={"w-[164px]"}
                      key={k}
                    >
                      <div
                        key={k}
                        className="image-box flex flex-col items-start border border-gray-200 bg-white overflow-hidden text-sm 
                          font-medium text-color-800 transition-all duration-500 cursor-not-allowed"
                        style={{ borderRadius: borderRadius }}
                      >
                        <div className="image-wrapper overflow-hidden">
                          <img
                            className="w-full h-full object-cover transition-all duration-500 hover:scale-125"
                            src={v.src}
                            alt=""
                          />
                        </div>
                        <div className="desc-area flex overflow-hidden p-4">
                          <span className="truncate">{v.text}</span>
                        </div>
                      </div>
                    </SwiperSlide>
                  )
                })}
              </Swiper>
            </div>
          </div>
        </div>

        {/*Popüler bölgeler*/}
        <div className="popular-places mt-6 px-4">
          <div className="lp-container">
            <p className="text-gray-800 font-semibold text-xl mb-2">Popüler tatil seçenekleri</p>

            <div className="relative flex flex-col">
              {[
                {
                  text: "Antalya otelleri",
                  desc: "Akdeniz’in incisi Antalya sizi bekliyor.",
                  src: popular_area1,
                },
                {
                  text: "Belek otelleri",
                  desc: "Antalya’nın gözdesinde yerinizi ayırın.",
                  src: popular_area2,
                },
                {
                  text: "Bodrum otelleri",
                  desc: "En güzel tatil Bodrum’da yapılır.",
                  src: popular_area3,
                },
                {
                  text: "Antalya otelleri",
                  desc: "Akdeniz’in incisi Antalya sizi bekliyor.",
                  src: popular_area1,
                },
                {
                  text: "Belek otelleri",
                  desc: "Antalya’nın gözdesinde yerinizi ayırın.",
                  src: popular_area2,
                },
                {
                  text: "Bodrum otelleri",
                  desc: "En güzel tatil Bodrum’da yapılır.",
                  src: popular_area3,
                },
              ].map((v, k) => {
                return (
                  <div
                    key={k}
                    className="image-box flex flex-col border border-gray-200 bg-white overflow-hidden text-base 
                          font-semibold text-color-800 transition-all duration-500 cursor-not-allowed mt-4 first:mt-0"
                    style={{ borderRadius: borderRadius }}
                  >
                    <div className="imaga-wrapper overflow-hidden">
                      <img
                        className="w-full h-full object-cover transition-all duration-500 hover:scale-125"
                        src={v.src}
                        alt=""
                      />
                    </div>
                    <div className="p-4">
                      <p>{v.text}</p>
                      <p className="text-gray-500 text-sm font-medium mt-0.5">{v.desc}</p>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>

        {/*Popüler bölgeler*/}
        <div className="seo-text text-sm text-gray-500 mt-6 px-4">
          <div className="lp-container">
            <p>
              Jolly ile unutulmaz bir tatil deneyimi sizleri bekliyor. Yurt içi ve yurt dışı yüzlerce farklı konaklama seçeneği ve
              geniş kapsamlı turlar ile uygun fiyatlı tatil paketlerini en ince ayrıntısına kadar sizi düşünerek hazırlayan Jolly,
              35 yıllık deneyimi ve birikimi ile hayalinizdeki tatili gerçeğe dönüştürüyor.
              <br />
              <br />
              Tatilcilerin yol arkadaşı ve gezginlerin daimi başlangıç noktası olan Jolly ile harika bir yaz geçirebilir, muhteşem
              bir balayı yapabilir; maviliklere yelken açtıran gemi turları,keşiflerle dolu yurt dışı turları, Anadolu'yu gezdiren
              kültür turları ile her bütçeye ve zevke uygun tur seçenekleri arasından seçim yaparak içinizdeki keşfetme tutkusunun
              peşine düşebilirsiniz.
              <br />
              <br />
              Tercih sizden, popüler tatil yerleri Jolly'den... Binlerce farklı destinasyonda dilediğiniz tatile çıkabilir, size
              uygun otel seçenekleri arasından tercih yapabilir ve seyahat planınızı sorunsuz bir şekilde gerçekleştirebilirsiniz.
              <br />
              <br />
              Yurt içi tatiliniz için yazın vazgeçilmez rotaları arasında yer alan Ege ve Akdeniz sahillerine uzanabilir; Çeşme,
              Bodrum, Fethiye ve Antalya’nın enfes kumsallarından berrak sularına doğru bir yolculuğa çıkabilirsiniz. Temiz havaya
              doymak ve yeşilin binbir tonuna şahit olmak için Karadeniz turları ile doğa tatili yapabilir, günübirlik veya hafta
              sonu turları ile hayata kısa bir mola verebilir, birbirinden lezzetli yöresel yemeklerin tadına GAP turları ile
              bakabilirsiniz.
              <br />
              <br />
              Gözde destinasyonlardan biri olan Akdeniz'in cennet adası Kıbrıs'ta ise eşsiz bir denizi, benzersiz koyları, tarihi
              ve kültürel öneme sahip durakları ziyaret edebilir, dört mevsim eğlenceyi bir arada bulabilir; muhteşem Kıbrıs
              otellerinde ihtiyacınıza uygun oda seçeneklerinde erken rezervasyon fırsatlarından yararlanarak unutulmaz bir tatil
              deneyimi yaşayabilirsiniz.
              <br />
              <br />
              Tatilinizi yurt dışında geçirmek isterseniz Jolly'nin zengin içerikli gezi programları, onlarca farklı yurt dışı
              tatil rotaları,uçaklı ya da otobüslü ulaşım imkanları, ekonomik ve vizesiz yurt dışı tur seçenekleri, deneyimli
              rehberleri, vize işlemleri, rezervasyon kolaylığı, transfer hizmetleri ve her bütçeye uygun yurt dışı otel
              seçenekleri ile kusursuz bir tatil yapabilirsiniz. Dünyayı keşfetmek için Avrupa'nın birbirinden güzel kentlerinden
              Asya'nın egzotik adalarına uzanabilir, Uzak Doğu'nun gizemli tapınaklarından Afrika'nın vahşi hayatına uzanan
              benzersiz bir maceraya atılabilirsiniz.
            </p>
          </div>
        </div>
      </div>
      <footer className="lp-footer text-xs text-gray-500 border-t border-gray-200 px-4 mt-6 bg-white">
        <div className="lp-container flex flex-col py-8">
          <div className="row mt-4 first:mt-0">
            <div className="footer-logo-wrapper max-w-[140px]">
              <img
                alt="logo"
                className="object-cover max-h-[90px]"
                src={logo}
              />
            </div>
          </div>
          <div className="row mt-4 first:mt-0">
            <ul>
              <li className="font-medium text-sm text-gray-800 pb-2">Kurumsal</li>
              {["Hakkımızda", "İletişim", "TÜRSAB Belge No: 286428"].map((v, k) => (
                <li
                  key={k}
                  className="mt-2"
                >
                  {v}
                </li>
              ))}
              <li className="mt-2">
                <img
                  src={tursabImg}
                  alt=""
                />
              </li>
            </ul>
          </div>
          <div className="row mt-4 first:mt-0">
            <ul>
              <li className="font-medium text-sm text-gray-800 pb-2">Sözleşmeler</li>
              {[
                "Kullanım sözleşmesi",
                "Gizlilik sözleşmesi",
                "Bilgi güvenliği politikası",
                "KVKK aydınlatma metni",
                "KVKK işlenmesi politikası",
                "Çerez politikası",
              ].map((v, k) => (
                <li
                  key={k}
                  className="mt-2"
                >
                  {v}
                </li>
              ))}
            </ul>
          </div>
          <div className="row mt-4 first:mt-0">
            <ul>
              <li className="font-medium text-sm text-gray-800 pb-2">Ödeme seçenekleri</li>
              <li className="flex items-center mt-2">
                {[visa, maestro, master, stripe].map((v, k) => {
                  return (
                    <span
                      key={k}
                      className="w-12 h-8 flex items-center justify-center bg-gray-100 rounded-lg ml-1 first:ml-0"
                    >
                      <img
                        src={v}
                        alt="alt"
                      />
                    </span>
                  )
                })}
              </li>
              <li className="mt-4">Sitemizde görmüş olduğunuz fiyatlara KDV dahildir.</li>
              <li className="mt-4">
                <button className="btn bg-gray-100 text-success-500 border-none text-xs py-2 px-3 h-auto font-normal hover:bg-gray-200">
                  <Icon
                    name="shield"
                    size="16"
                    color="#12B76A"
                  />
                  <span>Güvenli alışveriş</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div className="lp-container py-8 text-xs text-gray-400 text-center border-t border-gray-200">
          <p>Bu sitenin tüm seyahat hizmetleri Gordion Teknoloji A.Ş. tarafından verilmektedir. Her hakkı saklıdır. ©2022</p>
        </div>
      </footer>
    </div>
  )
}

export default B2CMobile
