import React, { useEffect, useState } from "react"
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useAuth0 } from "auth0/auth0-react"
import withAppInsights from "AppInsights"
import routes from "./routes"

// components
import Aside from "./layouts/Aside/MainAside"
import HomePage from "./pages/HomePage"
import { LoadBar } from "./components"

// global styles
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/parallax"
import "swiper/css/pagination"
import "react-circular-progressbar/dist/styles.css"
import "assets/scss/rsuite.scss"
import "assets/scss/_icon.scss"
import "react-tippy/dist/tippy.css"
import "tippy.js/dist/tippy.css"
import "assets/scss/tippy-custom.scss"
import "devextreme/dist/css/dx.light.css"
import "assets/scss/table-custom.scss"
import "assets/scss/demoStyles.scss"
import "assets/scss/lp-theme.scss"
import "assets/scss/_b2c_icon.scss"
import "assets/scss/ck-custom.scss"
import "assets/scss/fonts.scss"
import "assets/scss/theme.scss"
import "index.css"
// global localization
import "@ckeditor/ckeditor5-build-classic/build/translations/tr"
import "moment/locale/tr"

// utils
import { logout } from "./utils/logout"

// requests
import { associatedDomainsRequested } from "./redux/actions/associatedDomains"
import { userInfoRequested } from "redux/actions/userInfo"

// cookies
import Cookies from "universal-cookie"
const cookies = new Cookies()

function App() {
  const dispatch = useDispatch()

  const { loginWithRedirect } = useAuth0()

  //const AssociatedDomainsLoading = useSelector((state) => state.associatedDomains.loading)
  const AssociatedDomainsError = useSelector((state) => state.associatedDomains.error)
  const AssociatedDomains = useSelector((state) => state.associatedDomains.data)

  const UserInfo = useSelector((state) => state.UserInfo.data)
  //const UserInfoLoading = useSelector((state) => state.UserInfo.loading)

  const [Config, setConfig] = useState({
    TOKEN: localStorage.getItem("user_token") || cookies.get("user_token"),
    isLogged: !!localStorage.getItem("user_token") || !!cookies.get("user_token"),
    AssociatedDomains: cookies.get("associatedDomain"),
    UserInfo: cookies.get("userInfo"),
  })

  const [RoleMatch, setRoleMatch] = useState(undefined)
  const [RoutesState, setRoutesState] = useState([])
  const [Loading, setLoading] = useState(true)
  const [IsAdmin, setIsAdmin] = useState(cookies.get("userInfo")?.role.includes("paneladmin"))

  function HandleLoginRequest() {
    const startsWidthCode = window.location.search.startsWith("?code=")
    if (!Config.TOKEN && !startsWidthCode) {
      loginWithRedirect()
    }
  }

  useEffect(() => {
    if (Config.TOKEN) {
      if (AssociatedDomainsError) {
        alert("Domain bilgilerine ulaşılamadı. Lütfen daha sonra tekrar deneyiniz.")
        logout()
      }
      if (!!!Config.AssociatedDomains && !AssociatedDomainsError) {
        if (IsAdmin === false) {
          dispatch(associatedDomainsRequested())
          window.history.pushState("", "", "/")
        }
        if (!UserInfo) dispatch(userInfoRequested())
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [IsAdmin, AssociatedDomainsError, UserInfo])

  useEffect(() => {
    if (AssociatedDomains) {
      cookies.set("associatedDomain", AssociatedDomains)
      setConfig({
        ...Config,
        AssociatedDomains,
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AssociatedDomains, Config.AssociatedDomains])

  useEffect(() => {
    if (UserInfo) {
      cookies.set("userInfo", UserInfo)
      setIsAdmin(UserInfo?.role.includes("paneladmin"))
      setConfig({
        ...Config,
        UserInfo,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UserInfo, Config.UserInfo])

  useEffect(() => {
    if (Loading === false) {
      const location = window.location
      if (!!IsAdmin && !location.pathname.includes("/account-management") && !location.pathname.includes("/domain-dns")) {
        setLoading(true)
        location.replace("/account-management")
      }

      /**
       * @todo AssociatedDomains?.redirectSetup müdahale komutu kaldırılacak.
       */
      if (
        !!!IsAdmin &&
        (AssociatedDomains?.redirectSetup || Config.AssociatedDomains?.redirectSetup) &&
        !location.pathname.includes("/setup")
      ) {
        /**
         * @todo: redirect to setup page
         */
        // setLoading(true)
        // location.replace("/setup")
      }

      let roleMatch = routes.filter((route) => {
        let rolesIntersection = route.roles?.filter((value) => !!Config.UserInfo?.role?.includes(value))
        return !!rolesIntersection?.length
      })
      setRoleMatch(!!roleMatch)
      setRoutesState(roleMatch)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Loading])

  useEffect(() => {
    if (IsAdmin || (Config && (AssociatedDomains || Config.AssociatedDomains) && (UserInfo || Config.UserInfo))) {
      setTimeout(() => {
        setLoading(false)
      }, 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Config])

  return (
    <BrowserRouter>
      {Config.isLogged ? (
        <>
          {Loading && (
            <main className={"justify-center"}>
              <LoadBar pathFill={"#2E90FA"} />
            </main>
          )}

          {!Loading && (
            <>
              <Aside {...Config} />
              <Routes>
                <Route
                  path="/"
                  element={<HomePage {...Config} />}
                />
                {RoleMatch &&
                  RoutesState.map((route, k) => {
                    return (
                      <Route
                        key={k}
                        path={route.path}
                        exact={route.exact}
                        element={route.component(Config)}
                      />
                    )
                  })}

                {RoleMatch && RoutesState.length && (
                  <Route
                    path="*"
                    element={
                      <Navigate
                        to={"/"}
                        replace
                      />
                    }
                  />
                )}
              </Routes>
            </>
          )}
        </>
      ) : (
        <>
          <Routes>
            <Route
              path="*"
              element={<HandleLoginRequest />}
            />
          </Routes>
        </>
      )}
    </BrowserRouter>
  )
}

export default withAppInsights(App)
