import * as type from "redux/types"

const initialState = {
  countries: {
    list: [],
    loading: false,
    error: false,
  },
  cities: {
    list: [],
    loading: false,
    error: false,
  },
  districts: {
    list: [],
    loading: false,
    error: false,
  },
}

const destinations = (state = initialState, action) => {
  switch (action.type) {
    case type.COUNTRIES_REQUESTED:
      return {
        ...state,
        countries: {
          list: [],
          loading: true,
          error: false,
        },
      }
    case type.COUNTRIES_SUCCEEDED:
      return {
        ...state,
        countries: {
          list: action.payload,
          loading: false,
          error: false,
        },
      }
    case type.COUNTRIES_FAILED:
      return {
        ...state,
        countries: {
          list: [],
          loading: false,
          error: true,
        },
      }
    case type.CITIES_REQUESTED:
      return {
        ...state,
        cities: {
          list: [],
          loading: true,
          error: false,
        },
      }
    case type.CITIES_SUCCEEDED:
      return {
        ...state,
        cities: {
          list: action.payload,
          loading: false,
          error: false,
        },
      }
    case type.CITIES_FAILED:
      return {
        ...state,
        cities: {
          list: [],
          loading: false,
          error: true,
        },
      }
    case type.DISTRICTS_REQUESTED:
      return {
        ...state,
        districts: {
          list: [],
          loading: true,
          error: false,
        },
      }
    case type.DISTRICTS_SUCCEEDED:
      return {
        ...state,
        districts: {
          list: action.payload,
          loading: false,
          error: false,
        },
      }
    case type.DISTRICTS_FAILED:
      return {
        ...state,
        districts: {
          list: [],
          loading: false,
          error: true,
        },
      }
    default:
      return state
  }
}

export default destinations
