import { useQuery } from "@tanstack/react-query"
import { API_GET } from "helpers/api"

/**
 *
 * @returns
 */
export const useDynamicData = (url, isAutocomplete = false) => {
  const query = useQuery({
    queryKey: ["dynamic-data", url],
    queryFn: () => API_GET(url),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: false,
    enabled: isAutocomplete ? !!url && url.includes("term") : !!url,
  })

  return query
}
