import {v4 as uuidv4} from "uuid";
import {BUTTON, HYPERLINK, IMAGE, TEXT} from "../../Enums";

const AddTabSliderPattern = (model, thisComponent, mediaIdList, mediaType) => {
  console.log({
    model,
    thisComponent,
    mediaIdList,
  })

  if (!model || !Object.values(model)?.length) {
    return false
  }

  const copyModel = JSON.parse(JSON.stringify(model))
  const isRelationData = model?.find((item) => item?.relation === "Data")
  const isRelationVisual = model?.find((item) => item?.relation === "Visual")
  const isRelationComponent = model?.find((item) => item?.relation === "Component")
  const dataInput = isRelationData?.input
  let dataItems = dataInput?.items
  let visualInput = isRelationVisual?.input
  let childrenInput = isRelationComponent?.Childs

  const getMediaIdBySliderIndex = index => {
    const subComponents = childrenInput[index]?.ChildStrategies?.find(child => child?.relation === "Component")
    const image = subComponents?.Childs.find(child => child?.name === IMAGE)
    const imageData = image?.ChildStrategies?.find(child => child?.relation === "Data")
    return imageData?.input?.mediaFileId
  }
  let entiredImage = 0
  const generateChildComponent = (childModel, childData) => {
    const childRelationData = childModel?.ChildStrategies?.find(strategy => strategy?.relation === "Data")
    const childRelationVisual = childModel?.ChildStrategies?.find(strategy => strategy?.relation === "Visual")
    let childDataInput = childRelationData.input
    const translateItems = childRelationData.translateItems
    const childVisualInput = childRelationVisual.input

    if (childData?.name === IMAGE){
      const mediaFileId = mediaIdList.find(media => media?.sIndex === entiredImage)?.mediaId || getMediaIdBySliderIndex(entiredImage)
      entiredImage++
      childDataInput = {
        ...childDataInput,
        mediaFileId
      }
    } else if (childData?.name === TEXT || childData?.name === BUTTON){
      childDataInput = {
        ...childDataInput,
      }
    } else {
      childDataInput = {
        ...childDataInput,
        ...childData?.data
      }
    }


    translateItems?.map(item => {
      if (item?.langKey === "TextLangKey"){
        item.value = childData?.data?.text
        item.name = `${childData?.name} text`
      }
    })


    const style = {
      ...childVisualInput.style,
      ...childData?.visual?.style,
    }

    childVisualInput.style = style
    childRelationData.input = childDataInput

  }

  const sliders = isRelationComponent?.Childs

  if (sliders){
    sliders?.map((slider, sIndex) => {
      const thisSlider = thisComponent?.children[sIndex]
      const sliderRelationData = slider?.ChildStrategies?.find(strategy => strategy?.relation === "Data")
      const sliderRelationVisual = slider?.ChildStrategies?.find(strategy => strategy?.relation === "Visual")
      const sliderRelationComponent = slider?.ChildStrategies?.find(strategy => strategy?.relation === "Component")
      const sliderDataInput = sliderRelationData.input
      const sliderVisualInput = sliderRelationVisual.input
      const sliderComponentInput = sliderRelationComponent.Childs

      sliderDataInput.categoryId = thisSlider?.data?.category?.categoryId

      const style = {
        ...sliderVisualInput.style,
        ...thisSlider?.visual?.style,
      }

      sliderVisualInput.style = style

      sliderComponentInput?.map((child) => {
          generateChildComponent(child, thisSlider?.children?.find(c => child?.name === c?.name))
      })
    })
  }

  visualInput = {
    ...visualInput,
    height: thisComponent?.visual?.height,
    width: thisComponent?.visual?.width,
    style: {
      ...visualInput?.style,
      ...thisComponent?.visual?.style
    },
    settings: {
      ...visualInput?.settings,
      ...thisComponent?.visual?.settings
    },
    paginationStyle: {
      ...visualInput?.paginationStyle,
      ...thisComponent?.visual?.paginationStyle,
    }
  }
  isRelationVisual.input = visualInput

  return model
}


export default AddTabSliderPattern
