export const data = [
  {
    name: "Oca",
    uv: 3000,
    pv: 2400,
  },
  {
    name: "Şub",
    uv: 3100,
    pv: 1398,
  },
  {
    name: "Mar",
    uv: 3200,
    pv: 4800,
  },
  {
    name: "Nis",
    uv: 3300,
    pv: 3908,
  },
  {
    name: "May",
    uv: 3400,
    pv: 4800,
  },
  {
    name: "Haz",
    uv: 3500,
    pv: 3800,
  },
  {
    name: "Tem",
    uv: 3000,
    pv: 4300,
  },
  {
    name: "Ağu",
    uv: 3000,
    pv: 4300,
  },
  {
    name: "Eyl",
    uv: 3000,
    pv: 4300,
  },
  {
    name: "Eki",
    uv: 3000,
    pv: 4300,
  },
  {
    name: "Kas",
    uv: 3000,
    pv: 4300,
  },
  {
    name: "Ara",
    uv: 3000,
    pv: 4300,
  },
]

export const options = ["Aylık", "Yıllık"].map((item) => ({
  label: item,
  value: item,
}))
