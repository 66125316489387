import * as type from "redux/types"

const initialState = {
  mediaLibraryList: {
    list: null,
    loading: false,
    error: false,
  },
  mediaLibrarySave: {
    response: null,
    loading: false,
    error: false,
  },
  mediaLibraryDelete: {
    response: null,
    loading: false,
    error: false,
  },
}

const mediaLibrary = (state = initialState, action) => {
  switch (action.type) {
    case type.MEDIA_LIBRARY_LIST_REQUESTED:
      return {
        ...state,
        mediaLibraryList: {
          list: null,
          loading: true,
          error: false,
        },
      }
    case type.MEDIA_LIBRARY_LIST_SUCCEEDED:
      return {
        ...state,
        mediaLibraryList: {
          list: action.payload,
          loading: false,
          error: true,
        },
      }
    case type.MEDIA_LIBRARY_LIST_FAILED:
      return {
        ...state,
        mediaLibraryList: {
          list: null,
          loading: true,
          error: false,
        },
      }
    case type.MEDIA_LIBRARY_SAVE_REQUESTED:
      return {
        ...state,
        mediaLibrarySave: {
          response: null,
          loading: true,
          error: false,
        },
      }
    case type.MEDIA_LIBRARY_SAVE_SUCCEEDED:
      return {
        ...state,
        mediaLibrarySave: {
          response: action.payload,
          loading: false,
          error: true,
        },
      }
    case type.MEDIA_LIBRARY_SAVE_FAILED:
      return {
        ...state,
        mediaLibrarySave: {
          response: null,
          loading: true,
          error: false,
        },
      }
    case type.MEDIA_LIBRARY_DELETE_REQUESTED:
      return {
        ...state,
        mediaLibraryDelete: {
          response: null,
          loading: true,
          error: false,
        },
      }
    case type.MEDIA_LIBRARY_DELETE_SUCCEEDED:
      return {
        ...state,
        mediaLibraryDelete: {
          response: action.payload,
          loading: false,
          error: true,
        },
      }
    case type.MEDIA_LIBRARY_DELETE_FAILED:
      return {
        ...state,
        mediaLibraryDelete: {
          response: null,
          loading: true,
          error: false,
        },
      }
    default:
      return state
  }
}

export default mediaLibrary
