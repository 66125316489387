import React, {useRef, useState} from "react"
import { Link } from "react-router-dom"

// components
import {BorderBox, ColorBox, FontSizeBox, Icon, RadiusBox, SideMenuListItem, TitleH3, ToggleBox} from "components"
import { SelectPicker } from "rsuite"

//layouts
import Margin from "./Margin";
import AnchorLinks from "./AnchorLinks";

// enums

// saga-requests

const PageDesign = ({
    visualValues,
    setVisualValues,
    allFonts,
    allLinks,
  }) => {
  // refs
  const textTypeWrapper = useRef(null)

  //states
  const FontSizes = [16, 32, 48]
  const FontSizeStyles = ["sm", "sm", "sm"]
  const [ShowSubLayout, setShowSubLayout] = useState(null)

  //funcs


  const borderRadiusOnChange = (radius) => {
    setVisualValues({
      ...visualValues,
      borderRadius: `${radius}px`,
    })
  }

  const fontSizeOnChange = (size) => {
    setVisualValues({
      ...visualValues,
      fontFamily: size
    })

  }


  return (
    <div className="h-full overflow-auto flex flex-col pb-6">
      <div className="flex flex-col gap-4">
        <p className="font-semibold">SAYFA TASARIMI</p>

        <div className="flex flex-col gap-4">
          <div className="input-wrapper">
            <ToggleBox
              disabled={true}
              title={"Header & Footer"}
              checked={!visualValues?.enableHeaderFooter}
              onToggle={e => console.log(e)}
            />
          </div>


          {visualValues && (
            <div className="input-wrapper">
              <RadiusBox
                min={0}
                max={40}
                showRangeArea={true}
                ActiveButtonRadius={visualValues?.borderRadius ? Number(visualValues?.borderRadius.replace("px", "")) : 0}
                onChange={(radius) => borderRadiusOnChange(radius)}
                rangeValue={visualValues?.borderRadius ? Number(visualValues?.borderRadius.replace("px", "")) : 0}
                onRangeChange={(radius) => borderRadiusOnChange(radius)}
              />
            </div>
          )}


          {/*Text Type*/}
          <BorderBox
            ref={textTypeWrapper}
            className="ds-box !flex-row items-center justify-between py-2 px-3 relative"
          >
            <TitleH3 className="font-normal shrink-0">Yazı Tipi Ailesi</TitleH3>

            <div className="relative">
              <SelectPicker
                placeholder={""}
                value={visualValues?.fontFamily}
                menuMaxHeight={164}
                cleanable={false}
                data={allFonts ? allFonts : []}
                searchable={false}
                container={textTypeWrapper.current}
                onSelect={(value, item) => fontSizeOnChange(value)}
              />
            </div>
          </BorderBox>


          <BorderBox
            className="ds-box !flex-row items-center justify-between py-2 px-3 relative"
          >
            <TitleH3 className="font-normal shrink-0">Yazı Tipi Boyutu</TitleH3>

            <FontSizeBox
              title=""
              className=""
              activeFontSize={visualValues?.fontSize}
              sizes={FontSizes}
              styles={FontSizeStyles}
              onChange={size => setVisualValues({
                ...visualValues,
                fontSize: size
              })}
              letters={["S", "M", "L"]}
              />
          </BorderBox>

          <ColorBox
            value={visualValues?.backgroundColor}
            onChange={(event) => setVisualValues({...visualValues, backgroundColor: event.target.value})}
          />


        </div>
      </div>
      <div className="flex flex-col gap-4 mt-8">
        <p className="font-semibold">ELEMENTLERİ ÖZELLEŞTİR</p>

        <BorderBox className="p-0">
          <SideMenuListItem
            className="border-t first:border-t-0"
            title="Kenar Boşluğu"
            onClick={() => setShowSubLayout({
              Margin: true
            })}
          />

          <SideMenuListItem
            className="border-t first:border-t-0"
            title="Linkler"
            onClick={() => setShowSubLayout({
              AnchorLinks: true
            })}
          />
        </BorderBox>
      </div>

      <Link
        to="/theme-settings/configuration"
        className="mt-auto"
      >
        <BorderBox
          className="p-3 !flex-row items-center gap-2 justify-center text-center cursor-pointer select-none">
          <Icon name="layout" color="#475467" size={20} />
          <p className="text-gray-600 font-medium">Tema ayarları</p>
        </BorderBox>
      </Link>


      <Margin
        showSubLayout={ShowSubLayout}
        visualValues={visualValues}
        setVisualValues={setVisualValues}
        setShowSubLayout={setShowSubLayout}
      />

      <AnchorLinks
        visualValues={visualValues}
        setVisualValues={setVisualValues}
        showSubLayout={ShowSubLayout}
        links={allLinks}
        setShowSubLayout={setShowSubLayout}
      />
    </div>
  )
}

export default PageDesign
