import { useMutation } from "@tanstack/react-query"
import { API_POST } from "helpers/api"

export const useUserImport = () => {
  const mutation = useMutation({
    mutationFn: (form) =>
      API_POST(`${process.env.REACT_APP_AUTH_DOMAIN}/api/clientaccount/upload`, form, "application/json", false),
  })

  return mutation
}
