import * as type from "redux/types"

// DETAIL
export const saveUserSettingsRequested = (data) => ({
  type: type.SAVE_USER_SETTINGS_REQUESTED,
  payload: data,
})

export const saveUserSettingsSucceed = (data) => ({
  type: type.SAVE_USER_SETTINGS_SUCCEEDED,
  payload: data,
})

export const saveUserSettingsFailed = () => ({
  type: type.SAVE_USER_SETTINGS_FAILED,
})
