import { call, fork, put, takeLatest } from "redux-saga/effects"
import * as actions from "redux/actions/clientUserRegister"

import { CLIENT_USER_REGISTER_REQUESTED } from "redux/types"
import { API_POST } from "helpers/api"

function* watchGetClientUserRegister() {
  yield takeLatest(CLIENT_USER_REGISTER_REQUESTED, getClientUserRegister)
}

function* getClientUserRegister(action) {
  try {
    const res = yield call(apiGetClientUserRegister, action.payload)
    if (res.data.success) {
      yield put(actions.clientUserRegisterSucceed(res?.data))
    } else {
      yield put(actions.clientUserRegisterFailedWithErrorMessage(res.data.errors[0]?.message))
    }


  } catch (err) {
    yield put(actions.clientUserRegisterFailed())
  }
}

const apiGetClientUserRegister = (payload) => {
  return API_POST(`${process.env.REACT_APP_AUTH_DOMAIN}/api/clientaccount/register`, payload, "application/json", false)
}

export default function* clientUserRegisterGet() {
  yield fork(watchGetClientUserRegister)
}
