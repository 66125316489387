import React from "react"
import PropTypes from "prop-types"

/**
 * @description This component is used to wrap the content with a card.
 *
 * @param { children } - The content of the card.
 * @param { title } - The title of the card.
 * @param { description } - The description of the card.
 * @param { size } - The size of the card inner padding. sm -> 5, md -> 10, lg -> 15
 * @param { footer } - The footer of the card.
 * @param { marginSize } - The margin size of the card. sm -> 5, md -> 10, lg -> 15
 * @param { className } - The className of the card.
 * @param { wrapperClassName } - The className of the cards wrapper.
 * @returns
 */
const Card = ({ children, title, description, size, footer, marginSize, ref, Header, className, wrapperClassName }) => {
  return (
    <div
      ref={ref}
      className={`my-${marginSize === "sm" ? "5" : marginSize === "lg" ? "15" : "10"} ${className}`}
    >
      {(!!title || !!description) && (
        <div className="my-2">
          {!!title && <p className="font-semibold">{title}</p>}
          {!!description && <p className="font-thin text-sm">{description}</p>}
        </div>
      )}
      {!!Header && <Header />}
      <div
        className={`p-${
          size === "sm" ? "5" : size === "lg" ? "15" : "10"
        } bg-white border border-gray-200 rounded-md shadow-md ${wrapperClassName}`}
      >
        {children}
      </div>
      {!!footer && <div className="my-2">{footer}</div>}
    </div>
  )
}

Card.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  size: PropTypes.oneOf(["sm", "lg"]),
  marginSize: PropTypes.oneOf(["sm", "lg", "md"]),
  Header: PropTypes.node,
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
}

Card.defaultProps = {
  children: null,
  title: undefined,
  description: undefined,
  size: undefined,
  marginSize: "md",
  Header: undefined,
  className: "",
  wrapperClassName: "",
}

export default Card
