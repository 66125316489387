import React from "react"
import moment from "moment"
import { BorderBox } from "components"

const ReservationReportsDetailFlightInfo = ({ data, reservationData, displayInformations }) => {
  const pricingCombinations = reservationData?.DetailResponse?.PricingCombinations
  const flightSegments =
    reservationData &&
    pricingCombinations?.length > 0 &&
    pricingCombinations[0] &&
    pricingCombinations[0].CombinationGroups &&
    pricingCombinations[0].CombinationGroups[0] &&
    pricingCombinations[0].CombinationGroups[0].FlightSegments[0] &&
    pricingCombinations[0].CombinationGroups[0].FlightSegments.sort(
      (a, b) => moment(a.DepartureTime).valueOf() - moment(b.DepartureTime).valueOf(),
    )
  if (!flightSegments) {
    return null
  }
  let arrivalSegment = flightSegments[flightSegments.length - 1]
  return (
    <BorderBox className="p-8">
      <div className="flex flex-col">
        <div className="flex gap-4 justify-between">
          <div className="flex flex-col gap-1">
            <span className="text-gray-500">Seyahat Yeri</span>
            <span className="text-gray-800">{arrivalSegment?.Destination}</span>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-gray-500">Giriş-Çıkış Tarihleri</span>
            <span className="text-gray-800">
              {`${moment(displayInformations?.CheckInDate).format("DD MMM")}
                -
                ${moment(displayInformations?.CheckOutDate).format("DD MMM")}`}
            </span>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-gray-500">Seyahat Nedeni</span>
            <span className="text-gray-800">{data?.travelReasonName ? data?.travelReasonName : "-"}</span>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-gray-500">Toplam Tutar</span>
            <span className="text-gray-800">
              {Intl.NumberFormat("tr-TR", {
                style: "currency",
                currency: "TRY",
              }).format(data.totalPrice)}
            </span>
          </div>
        </div>
      </div>
    </BorderBox>
  )
}

export default ReservationReportsDetailFlightInfo
