import axios from "axios"
import Cookies from "universal-cookie"
import { getUrl } from "helpers/remoteHost"

const cookies = new Cookies()

let TOKEN = ""

export const getToken = () => {
  return JSON.parse(localStorage.getItem("user_token"))?.access_token || cookies.get("user_token")?.access_token
}

const API_POST = async (url = "", data = {}, contentType = "application/json", withCredentials = true) => {
  TOKEN = getToken()

  return await axios({
    method: "POST",
    withCredentials,
    headers: {
      "content-type": contentType,
      authorization: "Bearer " + TOKEN,
    },
    url: getUrl(url),
    data: data,
  }).catch((err) => {
    if (err.response.status === 405) {
      throw Object.assign({
        response: {
          data: {
            message: "405.",
          },
        },
      })
    }
    if (err.response.status === 403) {
      throw Object.assign({
        response: {
          data: {
            message: "403.",
          },
        },
      })
    }
    throw err
  })
}

const API_PATCH = async (url = "", data = {}, contentType = "application/json", withCredentials = true) => {
  TOKEN = getToken()

  return await axios({
    method: "PATCH",
    withCredentials,
    headers: {
      "content-type": contentType,
      authorization: "Bearer " + TOKEN,
    },
    url: getUrl(url),
    data: data,
  }).catch((err) => {
    if (err.response.status === 405) {
      throw Object.assign({
        response: {
          data: {
            message: "405.",
          },
        },
      })
    }
    if (err.response.status === 403) {
      throw Object.assign({
        response: {
          data: {
            message: "403.",
          },
        },
      })
    }
    throw err
  })
}

const API_GET = async (url = "", params = {}, withCredentials = true, headers = {}) => {
  TOKEN = getToken()

  return await axios({
    method: "GET",
    withCredentials,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      authorization: "Bearer " + TOKEN,
      ...headers,
    },
    url: getUrl(url),
    params: params,
    paramsSerializer: (params) => {
      let keys = Object.keys(params)
      let queryString = keys.reduce((acc, key) => {
        // if params is not null or empty string
        if (params[key] !== null && params[key] !== "") {
          if (typeof params[key] === "object") {
            // if object write in same key
            acc += params[key].reduce((objectAcc, value) => {
              objectAcc += key + "=" + value + "&"
              return objectAcc
            }, "")
          } else {
            acc += key + "=" + params[key] + "&"
          }
        }
        return acc
      }, "")
      // remove last & and returrn
      return queryString.slice(0, -1)
    },
  }).catch((err) => {
    if (err.response.status === 405) {
      throw Object.assign({
        response: {
          data: {
            message: "405.",
          },
        },
      })
    }
    if (err.response.status === 403) {
      throw Object.assign({
        response: {
          data: {
            message: "403",
          },
        },
      })
    }
    throw err
  })
}

const API_DELETE = async (url = "", params = {}, withCredentials = true) => {
  TOKEN = getToken()

  return await axios({
    method: "DELETE",
    withCredentials,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      authorization: "Bearer " + TOKEN,
    },
    url: getUrl(url),
    params: params,
    paramsSerializer: (params) => {
      let keys = Object.keys(params)
      let queryString = keys.reduce((acc, key) => {
        // if params is not null or empty string
        if (params[key] !== null && params[key] !== "") {
          if (typeof params[key] === "object") {
            // if object write in same key
            acc += params[key].reduce((objectAcc, value) => {
              objectAcc += key + "=" + value + "&"
              return objectAcc
            }, "")
          } else {
            acc += key + "=" + params[key] + "&"
          }
        }
        return acc
      }, "")
      // remove last & and returrn
      return queryString.slice(0, -1)
    },
  }).catch((err) => {
    if (err.response.status === 405) {
      throw Object.assign({
        response: {
          data: {
            message: "405.",
          },
        },
      })
    }
    if (err.response.status === 403) {
      throw Object.assign({
        response: {
          data: {
            message: "403",
          },
        },
      })
    }
    throw err
  })
}

export { API_POST, API_PATCH, API_GET, API_DELETE }
