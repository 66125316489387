import Addon from "../Addon"

const AddonList = ({ data }) => {
  // TODO: group by ihtiyacı olduğunda
  // const addonsData = data?.reduce(function (r, a) {
  //   r[a.category] = r[a.category] || []
  //   r[a.category].push(a)
  //   return r
  // }, Object.create(null))

  return (
    <>
      <div className="grid grid-cols-3 gap-4 mb-4">
        {data?.map((v, i) => (
          <Addon
            key={i}
            addonId={v.Id}
            title={v.Name}
            status={v.Status === "0"}
            description={v.Description}
            price={v.AddonRelations[0]?.AddonPrices[0]}
          />
        ))}
      </div>
    </>
  )
}

export default AddonList
