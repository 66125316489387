/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Alert} from "components";
import {pageSaveMainRequested, pageSaveMainSucceed} from "redux/actions/pageSaveMain";
import getPageList from "../../helpers/GetPageList";

const MainPageRowAlert = (props) => {
	const {state, setState, TableSearchQuery, ActivePageTypeDropdown, ActivePageStatusDropdown, onSuccess, onError, noListRequest} = props
	const dispatch = useDispatch()

	const saveMainPage = useSelector((state) => state.pageSaveMain.response)
	const saveMainPageLoading = useSelector((state) => state.pageSaveMain.loading)
	const saveMainPageError = useSelector((state) => state.pageSaveMain.error)

	const getSaveMainPage = () => {
		const {Id, isCustom} = state
		dispatch(pageSaveMainRequested({
			Id,
			Type: (isCustom === "true" || isCustom) ? 2 : 1 // custom page olanlar için 2 olmayanlara 1.
		}))
	}

	useEffect(() => {
		if (saveMainPage || saveMainPageError){
			const staticErrorMessage = "geçiş işlemi sırasında bir sorun oluştu, lütfen tekrar deneyiniz."
			if (saveMainPage?.success) {
				dispatch(pageSaveMainSucceed(null))
				setState(null)
				if (!noListRequest) getPageList(dispatch, TableSearchQuery, ActivePageTypeDropdown, ActivePageStatusDropdown)
				if(onSuccess){
					onSuccess(true)
				}
			} else if(!saveMainPage?.success){
				setState({
					...state,
					errorMessage: (saveMainPage?.errors && saveMainPage?.errors[0]?.Message) || staticErrorMessage
				})
				if(onError){
					onError(true)
				}
			} else if (saveMainPageError){
				setState({
					...state,
					errorMessage: staticErrorMessage
				})
				if(onError){
					onError(true)
				}
			}
		}
	}, [saveMainPage, saveMainPageError])

	return (
		<>
			{state && (
				<Alert
					iconName={"home"}
					approveButtonText={`${!state?.errorMessage ? "Evet" : "Tekrar dene"}`}
					title={`Açılış Sayfası`}
					rejectButtonOnClick={() => {
						dispatch(pageSaveMainSucceed(null))
						setState(null)
					}}
					approveButtonOnClick={getSaveMainPage}
					approveButtonClass={"primary"}
					loading={saveMainPageLoading}
				>
					<p className="text-sm text-gray-500">
						<code className="text-red-500"><b>{state?.Name}</b></code>
						&nbsp;isimli ve
						&nbsp;<code className="text-red-500"><b>{state?.Path}</b></code>
						&nbsp;url adresli {state?.errorMessage ? state?.errorMessage.toLowerCase() || `kayıt işlem sırasında bir hata oluştu` : `kaydı açılış sayfası olarak ayarlamak istediğinize emin misiniz?`}</p>
				</Alert>
			)}
		</>
	)
}


export default MainPageRowAlert