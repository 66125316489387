import * as type from "redux/types"

// Get All Tenants
export const getAllTenantsRequested = (data) => ({ type: type.GET_ALL_TENANTS_REQUESTED, payload: data })
export const getAllTenantsSucceed = (data) => ({ type: type.GET_ALL_TENANTS_SUCCEEDED, payload: data })
export const getAllTenantsFailed = () => ({ type: type.GET_ALL_TENANTS_FAILED })

// Get Tenant
export const getTenantRequested = (data) => ({ type: type.GET_TENANT_REQUESTED, payload: data })
export const getTenantSucceed = (data) => ({ type: type.GET_TENANT_SUCCEEDED, payload: data })
export const getTenantFailed = () => ({ type: type.GET_TENANT_FAILED })

// Set Tenants
export const setTenantRequested = (data) => ({ type: type.SET_TENANT_REQUESTED, payload: data })
export const setTenantSucceed = (data) => ({ type: type.SET_TENANT_SUCCEEDED, payload: data })
export const setTenantFailed = () => ({ type: type.SET_TENANT_FAILED })

// Delete Tenants
export const deleteTenantRequested = (data) => ({ type: type.DELETE_TENANT_REQUESTED, payload: data })
export const deleteTenantSucceed = (data) => ({ type: type.DELETE_TENANT_SUCCEEDED, payload: data })
export const deleteTenantFailed = () => ({ type: type.DELETE_TENANT_FAILED })

// Update Tenants
export const updateTenantRequested = (data) => ({ type: type.UPDATE_TENANT_REQUESTED, payload: data })
export const updateTenantSucceed = (data) => ({ type: type.UPDATE_TENANT_SUCCEEDED, payload: data })
export const updateTenantFailed = () => ({ type: type.UPDATE_TENANT_FAILED })

// Get All Domains
export const getAllDomainsRequested = (data) => ({ type: type.GET_ALL_DOMAINS_REQUESTED, payload: data })
export const getAllDomainsSucceed = (data) => ({ type: type.GET_ALL_DOMAINS_SUCCEEDED, payload: data })
export const getAllDomainsFailed = () => ({ type: type.GET_ALL_DOMAINS_FAILED })

// Get Domain
export const getDomainRequested = (data) => ({ type: type.GET_DOMAIN_REQUESTED, payload: data })
export const getDomainSucceed = (data) => ({ type: type.GET_DOMAIN_SUCCEEDED, payload: data })
export const getDomainFailed = () => ({ type: type.GET_DOMAIN_FAILED })

// Set Domain
export const setDomainRequested = (data) => ({ type: type.SET_DOMAIN_REQUESTED, payload: data })
export const setDomainSucceed = (data) => ({ type: type.SET_DOMAIN_SUCCEEDED, payload: data })
export const setDomainFailed = () => ({ type: type.SET_DOMAIN_FAILED })

// Delete Domain
export const deleteDomainRequested = (data) => ({ type: type.DELETE_DOMAIN_REQUESTED, payload: data })
export const deleteDomainSucceed = (data) => ({ type: type.DELETE_DOMAIN_SUCCEEDED, payload: data })
export const deleteDomainFailed = () => ({ type: type.DELETE_DOMAIN_FAILED })

// Get UserList
export const getUserListRequested = (data) => ({ type: type.GET_USERLIST_REQUESTED, payload: data })
export const getUserListSucceed = (data) => ({ type: type.GET_USERLIST_SUCCEEDED, payload: data })
export const getUserListFailed = () => ({ type: type.GET_USERLIST_FAILED })

// Set UserList
export const setUserListRequested = (data) => ({ type: type.SET_USERLIST_REQUESTED, payload: data })
export const setUserListSucceed = (data) => ({ type: type.SET_USERLIST_SUCCEEDED, payload: data })
export const setUserListFailed = () => ({ type: type.SET_USERLIST_FAILED })
