import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import GoogleMap from "google-map-react"

// components
import MainHeader from "components/Headers/MainHeader"
import { SelectPicker } from "rsuite"

// localization
import localization from "../../localization"

import MyHotelSkeleton from "skeletons/MyHotel"

// saga requests
import { MyWorkAreaRequested } from "redux/actions/myWorkAreaInfo"
import { clientConfigGetRequested } from "redux/actions/clientConfigurationGet"
import { citiesRequested, countriesRequested, districtsRequested } from "redux/actions/destinations"
import { footerUptadeRequested } from "redux/actions/footerUptade"

const Marker = ({ children }) => (
  <div
    style={{
      backgroundColor: "white",
      width: "30px",
      height: "30px",
      padding: "5px",
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "100%",
      transform: "translate(-50%, -50%)",
    }}
  >
    {children}
  </div>
)

const MyHotel = (props) => {
  let { isLogged, AssociatedDomains } = props

  const dispatch = useDispatch()

  const DomainType = AssociatedDomains?.domainType

  //refs
  const inputPhoneRef = useRef()
  const inputEmailRef = useRef()
  const countryWrapper = useRef()
  const cityWrapper = useRef()
  const districtWrapper = useRef()
  const domainProductWrapper = useRef()

  // selectors
  const myWorkAreaInfoList = useSelector((state) => state.myWorkAreaInfo?.list)
  const myWorkAreaInfoLoading = useSelector((state) => state.myWorkAreaInfo?.loading)
  const myWorkAreaInfoError = useSelector((state) => state.myWorkAreaInfo?.error)
  const clientConfigurationGet = useSelector((state) => state.clientConfigurationGet?.data)
  const clientConfigLoading = useSelector((state) => state.clientConfigurationGet?.loading)
  const clientConfigError = useSelector((state) => state.clientConfigurationGet?.error)
  const countries = useSelector((state) => state.destinations?.countries)
  const countriesLoading = useSelector((state) => state.destinations?.countries?.loading)
  const cities = useSelector((state) => state.destinations?.cities)
  const citiesLoading = useSelector((state) => state.destinations?.cities?.loading)
  const districts = useSelector((state) => state.destinations?.districts)
  const districtsLoading = useSelector((state) => state.destinations?.districts?.loading)

  // states
  const [IsEditableFields, setIsEditableFields] = useState(false)
  const [MyWorkAreaLoaction, setMyWorkAreaLoaction] = useState({})
  const [Countries, setCountries] = useState([])
  const [Cities, setCities] = useState([])
  const [Districts, setDistricts] = useState([])
  const [ActiveCountry, setActiveCountry] = useState()
  const [ActiveCity, setActiveCity] = useState()
  const [ActiveDistrict, setActiveDistrict] = useState()
  const [AdressValue, setAdressValue] = useState()
  const [FormInfo, setFormInfo] = useState({})
  const [Errors, setErrors] = useState({})
  const [BtnDisabled, setBtnDisabled] = useState(false)
  const [Skeleton, setSkeleton] = useState(false)
  const [DomainProducts, setDomainProducts] = useState([])
  const [ActiveDomainProduct, setActiveDomainProduct] = useState()

  const setForm = (e) => {
    switch (e.target.name) {
      case "email":
        if (!/\S+@\S+\.\S+/.test(e.target.value) && inputEmailRef?.current?.value.length > 0) {
          setErrors({ ...Errors, email: "Hatalı mail adresi!" })
        } else {
          setErrors({ ...Errors, email: "" })
        }
        break
      case "phone":
        if (e.target.value.length <= 6) {
          setErrors({ ...Errors, phone: "Telefon numarası en az 7 haneli olmalıdır!" })
          setBtnDisabled(true)
        } else {
          setErrors({ ...Errors, phone: "" })
          setBtnDisabled(false)
        }
        break
      default:
        break
    }
    setFormInfo({
      ...FormInfo,
      [e.target.name]: e.target.value,
    })
  }

  const saveButton = () => {
    let obj = {
      HotelDetailId: myWorkAreaInfoList?.id,
      Address: FormInfo?.address ? FormInfo?.address : AdressValue,
      Email: inputEmailRef.current?.value,
      Phone: FormInfo?.phone ? FormInfo?.phone : myWorkAreaInfoList?.phone,
      Longitude: myWorkAreaInfoList?.longitude ? myWorkAreaInfoList?.longitude : null,
      Latitude: myWorkAreaInfoList?.latitude ? myWorkAreaInfoList?.latitude : null,
      ZoomLevel: myWorkAreaInfoList?.latitude?.zoomLevel ? myWorkAreaInfoList?.latitude?.zoomLevel : 1,
      DestinationCountryId: ActiveCountry ? ActiveCountry : null,
      DestinationCityId: ActiveCity ? ActiveCity : null,
      DestinationDistrictId: ActiveDistrict ? ActiveDistrict : null,
    }
    dispatch(footerUptadeRequested(obj))
    setIsEditableFields(false)
  }

  const domainProductSelect = (value, item) => {
    if (clientConfigurationGet && !clientConfigLoading) {
      setSkeleton(true)
      dispatch(MyWorkAreaRequested(value))
      setActiveDomainProduct(item?.value)
    } else {
      setSkeleton(false)
    }
  }

  useEffect(() => {
    dispatch(clientConfigGetRequested())
    setSkeleton(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (clientConfigurationGet) {
      const arr = []
      setSkeleton(true)
      dispatch(MyWorkAreaRequested(clientConfigurationGet?.domainProducts[0]?.id))
      setActiveDomainProduct(clientConfigurationGet?.domainProducts[0]?.id)
      clientConfigurationGet?.domainProducts.map((e) => arr.push({ label: e?.name, value: e?.id }))
      setDomainProducts(arr)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientConfigurationGet])

  useEffect(() => {
    if (IsEditableFields) {
      dispatch(countriesRequested())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [IsEditableFields])

  useEffect(() => {
    if (countries.list.length && ActiveCountry && countriesRequested && IsEditableFields) {
      dispatch(citiesRequested(ActiveCountry))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries, ActiveCountry, IsEditableFields])

  useEffect(() => {
    if (cities.list.length && ActiveCity && citiesRequested && IsEditableFields) {
      dispatch(districtsRequested(ActiveCity))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cities, ActiveCity, IsEditableFields])

  useEffect(() => {
    // setState when countries setted from saga
    if (countries.list.length) {
      setCountries(JSON.parse(countries.list))
    }
    if (cities.list.length) {
      setCities(JSON.parse(cities.list))
    }
    if (districts.list.length) {
      setDistricts(JSON.parse(districts.list))
    }
  }, [countries, cities, districts])

  useEffect(() => {
    if (clientConfigurationGet && myWorkAreaInfoList) {
      setSkeleton(false)
    }

    if (myWorkAreaInfoList) {
      // setMyHotelLocation
      setMyWorkAreaLoaction({
        lng: myWorkAreaInfoList?.longitude,
        lat: myWorkAreaInfoList?.latitude,
      })

      setAdressValue(myWorkAreaInfoList?.address?.replace(/<[^>]+>/g, ""))
      setActiveCountry(myWorkAreaInfoList?.destinationCountryId)
      setActiveCity(myWorkAreaInfoList?.destinationCityId)
      setActiveDistrict(myWorkAreaInfoList?.destinationDistrictId)
    }
  }, [clientConfigurationGet, myWorkAreaInfoList])

  useEffect(() => {
    if (inputPhoneRef?.current?.value.length > 0 && ActiveCity === null) {
      setBtnDisabled(true)
    } else {
      setBtnDisabled(false)
    }
  }, [inputPhoneRef, ActiveCity])

  return (
    <>
      <MainHeader isLogged={isLogged} />
      {Skeleton && <MyHotelSkeleton />}
      {!myWorkAreaInfoLoading && !myWorkAreaInfoError && !clientConfigLoading && !clientConfigError && (
        <>
          <main>
            <section className="p-10">
              <div className="page-container">
                <div className="flex flex-col items-center">
                  <div className="xl:w-9/12 w-full transition-[width] duration-500">
                    <div className="page-top-area flex justify-between items-center mb-6">
                      <div className="page-title">
                        <h3 className="text-3xl font-semibold">Otelim</h3>
                        <p className="font-normal mt-1">Otelinize ait bilgileri buradan görüntüleyebilirsiniz.</p>
                      </div>
                      {DomainType !== "B2C" && DomainProducts?.length > 1 && (
                        <div
                          ref={domainProductWrapper}
                          className="country-wrapper relative"
                        >
                          <SelectPicker
                            block
                            size="lg"
                            placement="bottomStart"
                            placeholder={""}
                            defaultValue={ActiveDomainProduct}
                            menuMaxHeight={164}
                            cleanable={false}
                            loading={!!clientConfigLoading}
                            data={DomainProducts}
                            searchable={DomainProducts?.length > 7}
                            container={domainProductWrapper.current}
                            onSelect={(value, item) => {
                              domainProductSelect(value, item)
                            }}
                          />
                        </div>
                      )}
                    </div>
                    <div className="bg-white border border-gray-200 rounded-lg text-lg p-8">
                      <div className="grid sm:grid-cols-2">
                        <div className="col">
                          <p className="text-sm font-semibold pb-0.5">Logo</p>
                          <div className="text-gray-500">
                            <img
                              src={myWorkAreaInfoList?.logoUrl}
                              className="max-h-7 max-w-[150px] object-cover"
                              alt="my-hotel-logo"
                            />
                          </div>
                        </div>
                        <div className="col">
                          <p className="text-sm font-semibold pb-0.5">Otel Adı</p>
                          <div className="text-gray-500">
                            <p>{myWorkAreaInfoList?.name}</p>
                          </div>
                        </div>
                      </div>

                      <hr className="my-8 border-gray-200" />

                      <div className="grid sm:grid-cols-2">
                        <div className="col">
                          <p className="text-sm font-semibold pb-0.5">Check In / Check Out</p>
                          <div className="text-gray-500">
                            <p>
                              {myWorkAreaInfoList?.checkIn} / {myWorkAreaInfoList?.checkOut}
                            </p>
                          </div>
                        </div>
                        <div className="col">
                          <p className="text-sm font-semibold pb-0.5">Kapasite</p>
                          <div className="text-gray-500">
                            <p>{myWorkAreaInfoList?.roomCount}</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="bg-white border border-gray-200 rounded-lg text-lg p-8 mt-6">
                      <div className="flex flex-col sm:flex-row sm:items-center gap-3 border-bottom border-gray-200 pb-5">
                        <div className="col w-full">
                          <p className="text-lg font-bold">İletişim Bilgileri</p>
                          <p className="text-sm text-gray-500 mt-2">
                            Footer kısmında son kullanıcılarınız ile iletişim bilgisi olarak paylaşılacaktır.
                          </p>
                        </div>
                        <div className="col">
                          <button
                            onClick={() => setIsEditableFields(!IsEditableFields)}
                            className={`btn ${IsEditableFields ? " invisible" : ""}`}
                          >
                            Düzenle
                          </button>
                        </div>
                      </div>

                      <div className="grid sm:grid-cols-2 gap-4 mt-8">
                        <div className="col mb-8 last:mb-0">
                          <p className="text-sm font-semibold pb-0.5">Telefon Numarası</p>
                          {!IsEditableFields && (
                            <div className="text-gray-500">
                              <p>{FormInfo.phone || myWorkAreaInfoList?.phone}</p>
                            </div>
                          )}
                          {IsEditableFields && (
                            <div className="mt-1.5">
                              <input
                                type="tel"
                                name="phone"
                                ref={inputPhoneRef}
                                className={`w-full ${Errors?.phone?.length ? "!border-error-500" : ""}`}
                                defaultValue={FormInfo.phone || myWorkAreaInfoList?.phone}
                                onChange={setForm}
                              />
                            </div>
                          )}
                        </div>
                        <div className="col mb-8 last:mb-0">
                          <p className="text-sm font-semibold pb-0.5">Mail Adresi</p>
                          {!IsEditableFields && (
                            <div className="text-gray-500">
                              <p className="truncate">{inputEmailRef.current?.value}</p>
                            </div>
                          )}
                          {IsEditableFields && (
                            <div className="mt-1.5">
                              <input
                                type="email"
                                name="email"
                                ref={inputEmailRef}
                                className={`w-full ${Errors?.email?.length ? "!border-error-500" : ""}`}
                                defaultValue={FormInfo.email || myWorkAreaInfoList?.email}
                                onChange={setForm}
                              />
                            </div>
                          )}
                        </div>
                        <div className="col mb-8 col-start-1 col-end-3 last:mb-0">
                          <p className="text-sm font-semibold pb-0.5">Adres</p>
                          {!IsEditableFields && (
                            <div className="text-gray-500">
                              <p>{FormInfo.address || AdressValue}</p>
                            </div>
                          )}
                          {IsEditableFields && (
                            <>
                              <div className="grid sm:grid-cols-3 gap-4 mt-1.5">
                                <div
                                  ref={countryWrapper}
                                  className="country-wrapper relative"
                                >
                                  <SelectPicker
                                    block
                                    placement="bottomStart"
                                    placeholder={""}
                                    loading={!!countriesLoading}
                                    value={ActiveCountry}
                                    locale={localization?.TR?.PickerLocaleType}
                                    menuMaxHeight={164}
                                    cleanable={false}
                                    data={Countries}
                                    searchable={Countries.length > 7}
                                    container={countryWrapper.current}
                                    onSelect={(value, item) => {
                                      setActiveCountry(value)
                                      setActiveCity(null)
                                      setActiveDistrict(null)
                                    }}
                                  />
                                </div>

                                <div
                                  ref={cityWrapper}
                                  className="city-wrapper relative"
                                >
                                  <SelectPicker
                                    block
                                    placement="bottomStart"
                                    placeholder={ActiveCountry ? "" : "Önce ülke seçiniz"}
                                    loading={!!citiesLoading}
                                    value={ActiveCity === null ? "" : ActiveCity}
                                    locale={localization?.TR?.PickerLocaleType}
                                    menuMaxHeight={164}
                                    cleanable={false}
                                    data={Cities}
                                    searchable={Cities.length > 7}
                                    container={cityWrapper.current}
                                    onSelect={(value, item) => {
                                      setActiveCity(value)
                                    }}
                                  />
                                </div>

                                <div
                                  ref={districtWrapper}
                                  className="district-wrapper relative"
                                >
                                  <SelectPicker
                                    block
                                    // disabled={!ActiveCity}
                                    placement="bottomStart"
                                    placeholder={ActiveCity ? "" : "Önce il seçiniz"}
                                    loading={!!districtsLoading}
                                    className={ActiveCity ? "" : "pointer-events-none"}
                                    value={ActiveDistrict === null ? "" : ActiveDistrict}
                                    locale={localization?.TR?.PickerLocaleType}
                                    menuMaxHeight={164}
                                    cleanable={false}
                                    data={Districts}
                                    searchable={Districts.length > 7}
                                    container={districtWrapper.current}
                                    onSelect={(value, item) => {
                                      setActiveDistrict(value)
                                    }}
                                  />
                                </div>

                                <textarea
                                  name="address"
                                  className="col-start-1 col-end-4 text-lg text-gray-500"
                                  defaultValue={FormInfo.address || AdressValue}
                                  onChange={setForm}
                                />
                              </div>
                              <div className="button-area flex justify-end gap-4 mt-8">
                                <button
                                  onClick={() => {
                                    setIsEditableFields(!IsEditableFields)
                                    setFormInfo({})
                                    setErrors({})
                                  }}
                                  className="btn outline"
                                >
                                  Vazgeç
                                </button>
                                <button
                                  onClick={(e) => saveButton(e)}
                                  className={`btn`}
                                  type="submit"
                                  disabled={BtnDisabled}
                                >
                                  Kaydet
                                </button>
                              </div>
                            </>
                          )}
                          <div className={`map-box ${IsEditableFields ? "invisible h-0" : "mt-4"}`}>
                            {MyWorkAreaLoaction.lat && (
                              <div style={{ height: "250px", width: "100%" }}>
                                <GoogleMap
                                  bootstrapURLKeys={{ key: "AIzaSyCYRTHPzopuYqCowEbOGQquCwZxfhrzhpc" }}
                                  defaultCenter={MyWorkAreaLoaction}
                                  yesIWantToUseGoogleMapApiInternals
                                  defaultZoom={11}
                                >
                                  <Marker
                                    lat={MyWorkAreaLoaction.lat}
                                    lng={MyWorkAreaLoaction.lng}
                                  >
                                    <img
                                      src={myWorkAreaInfoList?.logoUrl}
                                      className="w-full h-full object-cover"
                                      alt="my-hotel-logo"
                                    />
                                  </Marker>
                                </GoogleMap>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </>
      )}
    </>
  )
}

export default MyHotel
