/* eslint-disable no-use-before-define */
import React from "react"
import { BorderBox } from "components"
import ReservationReportsDetailFlightModulle from "./ReservationReportsDetailFlight/ReservationReportsDetailFlightModule"
import ReservationReportsDetailTransferModule from "./ReservationReportsDetailTransfer/ReservationReportsDetailTransferModule"
import ReservationReportsDetailCarRentalModule from "./ReservationReportsDetailCarRental/ReservationReportsDetailCarRentalModule"

const ReservationReportsDetailModuleInfo = ({ data, displayInformations, searchRequest, hotelImage, reservationData }) => {
  return (
    <BorderBox className="p-8 mb-10">
      <h3 className="text-gray-800 text-xl font-semibold mb-4">Rezervasyon Bilgileri</h3>
      <div className="flex flex-row gap-4">
        {hotelImage && (
          <div className="basis-[125px] h-[86px] rounded-lg overflow-hidden shrink-0">
            <img
              className="w-full h-full object-cover rounded-lg"
              src={hotelImage}
              alt="cardAltText"
            />
          </div>
        )}
        <div className="flex flex-col items-start gap-2">
          <h2 className="text-lg">{displayInformations?.HotelName}</h2>
          <p className="text-sm text-gray-500">{searchRequest?.DestinationName}</p>
        </div>
      </div>
      {data.moduleName === "FlightModule" && <ReservationReportsDetailFlightModulle reservationData={reservationData} />}
      {data.moduleName === "TransferModule" && <ReservationReportsDetailTransferModule reservationData={reservationData} />}
      {data.moduleName === "CarRentalModule" && <ReservationReportsDetailCarRentalModule reservationData={reservationData} />}
    </BorderBox>
  )
}

export default ReservationReportsDetailModuleInfo
