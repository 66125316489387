import React, { useState } from "react"

// wrappers
import PartialWrapper from "../../../PartialWrapper"

//layouts
import { TitleLayout, TextLayout, ButtonLayout, MediaLayout, ContentOverlayLayout, AdvancedPositionLayout } from "./SubMenus"

// components
import { BorderBox, AlignmentBlock, SideMenuListItem, Subtitle } from "components"

// utils
import { isProdEnv } from "utils/environmentsCheck"

const Content = (props) => {
  let { show, setShow, activeLayout, editBlock, setEditBlock, ThisComponent, setThisComponent, ActiveCategory } = props

  // states
  /*const [SelectedAlignment, setSelectedAlignment] = useState("start")*/
  const [ShowSubLayout, setShowSubLayout] = useState(null)

  return (
    <PartialWrapper
      className={`content-layout${show ? " active" : ""}`}
      goBackOnClick={() => setShow(null)}
    >
      <Subtitle>İçerik</Subtitle>

      <div className="flex flex-col gap-7">
        {/*<div className="input-wrapper mt-4">
          <BorderBox>
            <span className="text-gray-800 font-medium">Hizalama</span>
            <div className="flex items-center gap-6 mt-3">
              {["start", "center", "end"].map((pos, key) => {
                return (
                  <AlignmentBlock
                    onClick={() => setSelectedAlignment(pos)}
                    selected={SelectedAlignment === pos}
                    key={key}
                    position={pos}
                  />
                )
              })}
            </div>
          </BorderBox>
        </div>*/}

        <BorderBox className="p-0 mt-4">
          <SideMenuListItem
            className="border-t first:border-t-0"
            onClick={() =>
              setShowSubLayout({
                titleLayout: true,
              })
            }
            title="Başlık"
          />
          {/*{!isProdEnv && (
            <SideMenuListItem
              className="border-t first:border-t-0"
              onClick={() =>
                setShowSubLayout({
                  textLayout: true,
                })
              }
              title="Text"
            />
          )}*/}
          <SideMenuListItem
            className="border-t first:border-t-0"
            onClick={() =>
              setShowSubLayout({
                buttonLayout: true,
              })
            }
            title="Buton"
          />
          {/*{!isProdEnv && (
            <SideMenuListItem
              className="border-t first:border-t-0"
              onClick={() =>
                setShowSubLayout({
                  mediaLayout: true,
                })
              }
              title="Media"
            />
          )}*/}
        </BorderBox>

        {/*{!isProdEnv && (
          <BorderBox className="p-0">
            <SideMenuListItem
              className="border-t first:border-t-0"
              onClick={() =>
                setShowSubLayout({
                  contentOverlayLayout: true,
                })
              }
              title="Content Overlay"
            />
            <SideMenuListItem
              className="border-t first:border-t-0"
              onClick={() =>
                setShowSubLayout({
                  advancedPositionLayout: true,
                })
              }
              title="Advanced Position"
            />
          </BorderBox>
        )}*/}
      </div>

      {/* region sub menus*/}
      <TitleLayout
        showSubLayout={ShowSubLayout}
        setShowSubLayout={setShowSubLayout}
        editBlock={editBlock}
        ThisComponent={ThisComponent}
        setThisComponent={setThisComponent}
      />

      {!isProdEnv && (
        <>
          <TextLayout
            showSubLayout={ShowSubLayout}
            setShowSubLayout={setShowSubLayout}
          />
          <MediaLayout
            showSubLayout={ShowSubLayout}
            setShowSubLayout={setShowSubLayout}
          />
          <ContentOverlayLayout
            showSubLayout={ShowSubLayout}
            setShowSubLayout={setShowSubLayout}
          />
          <AdvancedPositionLayout
            showSubLayout={ShowSubLayout}
            setShowSubLayout={setShowSubLayout}
          />
        </>
      )}
      <ButtonLayout
        showSubLayout={ShowSubLayout}
        setShowSubLayout={setShowSubLayout}
        editBlock={editBlock}
        ThisComponent={ThisComponent}
        setThisComponent={setThisComponent}
      />
      {/* endregion sub menus*/}
    </PartialWrapper>
  )
}

export default Content
