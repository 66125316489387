const ConditionItemSerializer = (ruleItems) => {
  const conditionDatas = !!ruleItems ? Object.entries(ruleItems) : []

  const conditionSelectItems = conditionDatas?.map((item) => {
    const definations = item || []
    return definations
  })
  return conditionSelectItems
}

export default ConditionItemSerializer
