import { useState, useRef, useEffect } from "react"
import tippy from "tippy.js"
import { useDispatch, useSelector } from "react-redux"

import "tippy.js/dist/tippy.css"
import "assets/scss/tippy-custom.scss"

import Icon from "components/Icon"
import Modal from "components/Modal"
import MasterpassImage from "assets/img/detailpopup.png"
import { addonSaveRequested } from "redux/actions/addonSave"

const AddonSetup = ({ AddonDetail, DomainId, TurnInformationPage }) => {
  const dispatch = useDispatch()

  const inputRef = useRef(null)

  const [loader, setLoader] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [inputValue, setInputValue] = useState("")
  const [error, setError] = useState(false)
  const [visible, setVisible] = useState(true)
  const [completeSetupModal, setCompleteSetupModal] = useState(false)

  const addonSaveData = useSelector((state) => state.AddonSave?.data)
  const addonSaveLoading = useSelector((state) => state.AddonSave?.loading)
  const addonSaveError = useSelector((state) => state.AddonSave?.error)

  tippy(".trackinId", {
    content: "Lorem ipsum dolor sit amet",
    placement: "right",
  })

  const handleChange = (e) => {
    setInputValue(inputRef.current.value)
    if (e.target.value) {
      setError(false)
    } else {
      setError(true)
    }
  }

  const setupCompleteButton = () => {
    if (!error && inputValue.length !== 0) {
      let obj = {
        AddonId: AddonDetail?.Id,
        DomainId: DomainId,
        StartDate: null,
        RenewalDate: null,
        Settings: [
          {
            PropertyId: AddonDetail?.AddonSettings[0]?.PropertyId,
            Value: inputValue,
          },
        ],
      }
      dispatch(addonSaveRequested(obj))
    } else {
      setError(true)
    }
    if (addonSaveData?.success) {
      setCompleteSetupModal(true)
    }
  }

  useEffect(() => {
    if (!addonSaveLoading) {
      setLoader(false)
      if (addonSaveData?.success) {
        setOpenModal(false)
      } else {
        setVisible(true)
        setError(true)
      }
    } else {
      setLoader(true)
    }
  }, [addonSaveData, addonSaveLoading])

  useEffect(() => {
    if (openModal) {
      setError(false)
      setLoader(false)
    }
  }, [openModal])

  return (
    <>
      <div className="w-full border rounded-lg bg-white p-6">
        <div className="mb-6">
          <h2 className="flex items-center w-fit px-2 rounded-2xl text-lg gap-[6px]">
            <Icon
              color="currentColor"
              name="box"
              size={"20"}
            />
            Kurulum Aşamaları
          </h2>
        </div>
        <div className="flex flex-col gap-3">
          <div className="flex gap-3 border rounded-lg border-gray-200 p-3">
            <div className="flex items-center justify-center rounded-full bg-blue-50 p-1 h-5 w-5">
              <Icon
                color="#2E90FA"
                name="check"
                size={"14"}
              />
            </div>
            <div className="flex flex-col gap-2">
              <span className="text-sm text-gray-500 line-through">Google Analytics eklentisini satın alın.</span>
              <span className="text-sm text-gray-500">Mağazadan eklentiyi satın alın ve kuruluma gidin.</span>
            </div>
          </div>
          <div className="flex gap-3 justify-between border rounded-lg border-blue-500 p-3">
            <div className="flex gap-3">
              <div className="flex items-center justify-center rounded-full bg-blue-50 p-1 h-5 w-5">
                {!addonSaveLoading && !addonSaveError && addonSaveData?.success && (
                  <Icon
                    color="#2E90FA"
                    name="check"
                    size={"14"}
                  />
                )}
              </div>
              <div className="flex flex-col gap-2">
                <span className="text-sm text-gray-500">
                  {AddonDetail?.AddonSettings[0]?.Property?.Name} girin ve kurulumu tamamlayın.
                </span>
                <span className="text-sm text-gray-500">
                  Mail adresinize gönderilen {AddonDetail?.AddonSettings[0]?.Property?.Name} girin.
                </span>
              </div>
            </div>
            <div className="py-[6px] pr-[3px]">
              <button
                disabled={addonSaveData?.success}
                className="btn"
                onClick={() => setOpenModal((prev) => !prev)}
              >
                {AddonDetail?.AddonSettings[0]?.Property?.Name}
              </button>
            </div>
          </div>
          <div className="flex gap-3 justify-between border rounded-lg border-gray-200 p-3">
            <div className="flex gap-3">
              <div className="flex items-center justify-center rounded-full bg-blue-50 p-1 h-5 w-5"></div>
              <div className="flex flex-col gap-2">
                <span className="text-sm text-gray-600">Kullanmaya başlayın</span>
                <span className="text-sm text-gray-500">Kurulum tamamlandı ve artık eklentinizi kullanabilirsiniz.</span>
              </div>
            </div>
            <div className="py-[6px] pr-4">
              <button className="btn disabled">Kullan</button>
            </div>
          </div>
        </div>
      </div>

      <Modal
        onChange={() => {
          setOpenModal((prev) => !prev)
        }}
        open={openModal}
        width={480}
        closeButton={false}
      >
        <div className="flex flex-col gap-5">
          <div>
            <img
              src={MasterpassImage}
              alt="MasterpassImage"
            />
          </div>
          <div className="flex flex-col gap-2">
            <h3 className="font-semibold text-gray-800">Google Analytics</h3>
            <p className="text-gray-500 text-sm">Lorem ipsum dolor lorem ipsum dolor sit amet</p>
          </div>
          <div>
            {error && visible && (
              <span className="text-base rounded-lg bg-error-200 flex items-center justify-between gap-3 p-4 mb-6">
                <div className="flex items-center gap-2">
                  <Icon
                    color="#7A271A"
                    name="info"
                    size={"22"}
                  />
                  {addonSaveData?.errors[0]?.message?.length > 0 ? (
                    <span className="text-error-900 ">{addonSaveData?.errors[0]?.message}</span>
                  ) : (
                    <span className="text-error-900">
                      {AddonDetail?.AddonSettings[0]?.Property?.Name} doğrulanamadı. Tekrar deneyin.
                    </span>
                  )}
                </div>
                <Icon
                  className="cursor-pointer"
                  onClick={() => setVisible(false)}
                  color="#7A271A"
                  name="close"
                  size={"22"}
                />
              </span>
            )}
            <div className="flex items-center mb-2 gap-[5px]">
              <span className="text-gray-800 text-sm font-medium">{AddonDetail?.AddonSettings[0]?.Property?.Name}</span>
              <div className="trackinId">
                <Icon
                  color="#667085"
                  name="info"
                  size={"14"}
                />
              </div>
            </div>
            <div className="relative">
              <input
                type="text"
                className={`w-full !pr-10 ${error ? "!border-error-500" : ""}`}
                ref={inputRef}
                onChange={(e) => handleChange(e)}
              />
              {error && (
                <>
                  <div className="absolute right-[15px] top-[19%]">
                    <Icon
                      color="#F04438"
                      name="info"
                      size={"14"}
                    />
                  </div>
                  <span className="text-error-500 text-sm">
                    {AddonDetail?.AddonSettings[0]?.Property?.Name} hatalı veya eksik girdiniz.
                  </span>
                </>
              )}
            </div>
          </div>
          <div className="flex gap-3">
            <div>
              <Icon
                className="cursor-pointer"
                color="#667085"
                name="help-circle"
                size={"14"}
              />
            </div>
            <span className="text-gray-500 text-sm">
              Kurulumla ilgili sorularınız mı var?{" "}
              <a
                href="/#"
                className="underline text-blue-500"
              >
                Kurulum Rehberine
              </a>{" "}
              gidin veya{" "}
              <a
                href="/#"
                className="underline text-blue-500"
              >
                help@gordiontech.com
              </a>{" "}
              adresinden destek talebi oluşturun.
            </span>
          </div>
          <div className="w-full flex gap-3">
            <button
              onClick={() => setOpenModal((prev) => !prev)}
              className="btn lg outline mr-3 w-1/2"
            >
              Vazgeç
            </button>
            <button
              className="btn w-1/2"
              onClick={setupCompleteButton}
            >
              {loader ? (
                <svg
                  aria-hidden="true"
                  class="inline w-5 h-5 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="white"
                  />
                </svg>
              ) : (
                "Kurulumu Tamamla"
              )}
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        onChange={() => {
          setCompleteSetupModal(false)
        }}
        open={completeSetupModal}
        width={392}
        closeButton={false}
      >
        <div className="flex flex-col justify-center items-center gap-5">
          <div className="flex justify-center items-center w-12 h-12 rounded-full bg-success-100">
            <Icon
              className="cursor-pointer"
              color="#12B76A"
              name="check"
              size={"24"}
            />
          </div>
          <div className="flex flex-col justify-center gap-2">
            <span className="font-semibold text-gray-800 text-2xl text-center">Kurulumunuz başarıyla tamamlandı!</span>
            <p className="text-gray-500 text-sm">Artık eklentinizi kullanmaya başlayabilirsiniz.</p>
          </div>
          <div className="w-full flex gap-3 mt-8">
            <button
              onClick={() => {
                setCompleteSetupModal(false)
                TurnInformationPage(0)
              }}
              className="btn w-full"
            >
              Eklentiye Dön
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default AddonSetup
