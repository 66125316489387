import React from "react"
import moment from "moment"
import { BorderBox } from "components"

const ReservationReportsDetailTransferInfo = ({ data, reservationData }) => {
  let transferSegmentGroupItems = reservationData.DetailResponse.TransferSegmentGroupItem.filter((x) =>
    reservationData.ProductKeys.includes(x.Key),
  )

  transferSegmentGroupItems = transferSegmentGroupItems.sort(
    (a, b) => moment(a.Items[0]?.DepartureTime).valueOf() - moment(b.Items[0]?.DepartureTime).valueOf(),
  )

  const originSegmentGroup = transferSegmentGroupItems[0]?.Items[0]
  return (
    <BorderBox className="p-8">
      <div className="grid grid-cols-1 gap-x-2 gap-y-3 md:grid-cols-2 lg:grid-cols-4">
        <div>
          <p className="font-medium text-gray-500">Seyahat Yeri</p>
          <p className="mt-1">{originSegmentGroup?.Name?.split(" - ")[1]}</p>
        </div>
        <div>
          <p className="font-medium text-gray-500">Gidiş Tarihi ve Saati</p>
          <p className="mt-1">{moment(originSegmentGroup?.ArrivalTime).format("DD MMM YYYY HH:mm")}</p>
        </div>
        <div>
          <p className="font-medium text-gray-500">Seyahat Nedeni</p>
          <p className="mt-1">{data?.travelReasonName ? data?.travelReasonName : "-"}</p>
        </div>
        <div>
          <p className="font-medium text-gray-500">Toplam Tutar</p>
          <p className="mt-1">
            {Intl.NumberFormat("tr-TR", {
              style: "currency",
              currency: "TRY",
            }).format(data.totalPrice)}
          </p>
        </div>
      </div>
    </BorderBox>
  )
}

export default ReservationReportsDetailTransferInfo
