import React from "react"
import { Col, Button } from "rsuite"
import { ReactComponent as NoDataIcon } from "assets/svg/no-data.svg"
import { Icon } from "components"
import DefineAddModal, { useDefineAddModal } from "./DefineAddModal"
import SuccessModal, { useSuccessModal } from "./SuccessModal"

const NoData = () => {
  const addModal = useDefineAddModal()
  const successModal = useSuccessModal()

  return (
    <>
      <Col xs={24}>
        <div className="rounded-lg bg-white w-full border border-gray-100 px-3 py-10 flex flex-col justify-center items-center shadow-sm gap-5 my-5">
          <NoDataIcon className="w-1/2" />
          <h1 className="font-semibold text-[18px]">Henüz hiç tanım yok.</h1>
          <Button
            className="btn hover:text-white"
            onClick={addModal.handleOpen}
          >
            <Icon
              size={18}
              name={"plus"}
              className={"!bg-white"}
            />
            Tanım Ekle
          </Button>
        </div>
      </Col>
      <DefineAddModal
        open={addModal.open}
        onClose={addModal.handleClose}
        onSuccess={(data) => {
          addModal.handleClose()
          successModal.handleOpen(data)
        }}
      />
      <SuccessModal
        open={successModal.open && !!successModal.data}
        onClose={successModal.handleClose}
        data={successModal.data}
      />
    </>
  )
}

export default NoData
