import { call, fork, put, takeLatest } from "redux-saga/effects"
import * as actions from "redux/actions/contractsSet"

import { CONTRACTS_SET_REQUESTED } from "redux/types"
import { API_POST } from "helpers/api"

function* watchSetContracts() {
  yield takeLatest(CONTRACTS_SET_REQUESTED, setContracts)
}

function* setContracts(action) {
  try {
    const data = action.payload
    const res = yield call(apiSetContracts, data)
    yield put(actions.contractsSetSucceed(res.data))
  } catch (err) {
    yield put(actions.contractsSetFailed())
  }
}

const apiSetContracts = (data) => {
  return API_POST(`${process.env.REACT_APP_PANEL_DOMAIN}/api/setting/saveusersettings/`, data)
}

export default function* contractsSet() {
  yield fork(watchSetContracts)
}
