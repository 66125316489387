import React, { useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import MainHeader from "components/Headers/MainHeader"
import AddonList from "components/MarketPlace/AddonList"
import MarketPlaceFilter from "layouts/Aside/MarketPlaceFilter"
import MarketPlaceHomePageSkeleton from "skeletons/MarketPlace/HomePage"

import { addonListRequested } from "redux/actions/addon"

const Plugins = (props) => {
  let { isLogged } = props
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()

  const addonListGet = useSelector((state) => state.Addon?.list)
  const addonListLoading = useSelector((state) => state.Addon?.loading)
  const addonListError = useSelector((state) => state.Addon?.error)

  useEffect(() => {
    dispatch(
      addonListRequested({
        IsFree: searchParams.get("isFree") ? searchParams.get("isFree") : null,
        CategoryIds: [searchParams.get("categoryId")],
      }),
    )
  }, [dispatch, searchParams])

  return (
    <>
      <MainHeader
        isLogged={isLogged}
        marketPlace
      />
      <MarketPlaceFilter addonListGetLength={addonListGet?.length} />
      {addonListLoading && <MarketPlaceHomePageSkeleton />}
      {!addonListLoading && !addonListError && (
        <main>
          <section className="p-10 my-0 mx-auto max-w-[1920px] overflow-auto">
            <div className="mb-6">
              <h2 className="font-semibold text-3xl">{searchParams.get("categoryName")}</h2>
              <span className="text-gray-500 mt-2">{addonListGet?.length} sonuç gösteriliyor</span>
            </div>
            <AddonList data={addonListGet} />
          </section>
        </main>
      )}
    </>
  )
}

export default Plugins
