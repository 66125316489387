import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"

// Components
import MainHeader from "components/Headers/MainHeader"
import BackgroundIcons from "components/BackgroundIcons"
import { TitleH1, Description, Icon } from "components"
import useQuery from "utils/useQuery"
import SelectPicker from "rsuite/SelectPicker"
import { Dropdown } from "rsuite"
import MultiCascader from "rsuite/MultiCascader"

// img

// Requests

export const Products = (props) => {
  let { isLogged } = props
  let query = useQuery()

  let isAdd = query.get("add")
  // selectors

  // states
  const [ActivePage, setActivePage] = useState(0)

  // Func

  // effects

  return (
    <>
      <MainHeader isLogged={isLogged} />
      <main className="bg-gray-25 z-10">
        <section className="wrapper relative flex flex-col bg-white lg:bg-transparent overflow-auto">
          {isAdd && (
            <>
              {ActivePage === 0 && <AddProduct setPage={(e) => setActivePage(e)} />}
              {ActivePage === 1 && <AddProductSuccess setPage={(e) => setActivePage(e)} />}
            </>
          )}

          {!isAdd && <ShowProducts />}
        </section>
      </main>
    </>
  )
}

export default Products

function ShowProducts() {
  const [ActiveFilters, setActiveFilters] = useState({})

  const renderButton = (props, ref) => {
    return (
      <button
        {...props}
        ref={ref}
      >
        <Icon
          className="pointer-events-none"
          name="more-vertical"
          size="20"
          color="#98A2B3"
        />
      </button>
    )
  }

  useEffect(() => {
    console.log(ActiveFilters)
  }, [ActiveFilters])

  return (
    <div className="account-management-container max-w-[1032px] px-4 py-8 w-full mx-auto">
      <Link
        to="/account-management/domains"
        className="inline-flex items-center"
      >
        <Icon
          name="arrow-left"
          size="20"
          color="#667085"
        />
        <p className="font-medium text-gray-500 ml-2">Geri Dön</p>
      </Link>
      <div className="page-info flex items-center flex-wrap mt-6">
        <div className="page-info-text mr-4">
          <div className="flex items-center">
            <TitleH1>[Tripy.com] Ürünleri</TitleH1>
            <span className="badge-area inline-flex items-center text-sm font-medium text-blue-500 bg-blue-50 rounded-2xl px-3 py-1 ml-2 select-none">
              B2C
            </span>
          </div>
          <Description className="mt-2">Oluşturulma tarihi: 07.10.2022</Description>
        </div>
      </div>
      <div className="process-area mt-6">
        <div className="flex items-center justify-between">
          <MultiCascader
            block
            appearance="default"
            menuHeight="auto"
            menuWidth={300}
            style={{ width: 200 }}
            placeholder={"Ürün tipi seçin"}
            cleanable={false}
            data={[
              {
                label: "Tur",
                value: "1",
              },
              {
                label: "Otel",
                value: "2",
              },
              {
                label: "Uçak",
                value: "3",
              },
            ]}
            searchable={true}
            onChange={(e) => {
              console.log(e)
              setActiveFilters({ filters: e })
            }}
          />
          <button className="btn">Ürün Ekle</button>
        </div>
      </div>

      <div className="products flex flex-col mt-2">
        {[
          { text: "Otel", icon: "building" },
          { text: "Uçak", icon: "plane" },
          { text: "Tur", icon: "suitcase" },
        ].map((v) => {
          return (
            <div
              key={v.text}
              className="bg-white border border-gray-200 rounded-lg text-lg p-4 mt-4"
            >
              <div className="flex items-center">
                <div className="inline-flex bg-blue-50 rounded-lg p-2 mr-4">
                  <Icon
                    name={v.icon}
                    size="20"
                    color="#2E90FA"
                  />
                </div>
                <div className="info-area grow">
                  <p className="name text-gray-800 font-medium">{v.text}</p>
                  <p className="date text-gray-500 text-sm">Güncellenme Tarihi: 07.10.2022</p>
                </div>
                <div className="process-icon ml-4">
                  <Dropdown
                    placement="bottomEnd"
                    renderToggle={renderButton}
                  >
                    <Dropdown.Item onClick={() => alert()}>Detaylara git</Dropdown.Item>
                    <Dropdown.Item onClick={() => alert()}>Düzenle</Dropdown.Item>
                    <Dropdown.Item onClick={() => alert()}>Ürünü kaldır</Dropdown.Item>
                  </Dropdown>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

function AddProduct(props) {
  const { setPage } = props

  return (
    <div className="account-management-area relative flex items-center justify-center h-full max-h-full overflow-auto lg:overflow-hidden">
      {/*background blur icons*/}
      <BackgroundIcons />
      <div className="flex flex-col border border-gray-200 shadow-xl rounded-xl bg-white w-[392px] p-6">
        <form className="form-area w-full">
          <div className="form-header">
            <p className="text-lg text-gray-900 font-semibold">[Tripy.com] için Ürün Ekle</p>
            <p className="text-sm text-gray-500 mt-2">Seçmiş olduğunuz domaine yeni ürün tanımlayın.</p>
          </div>
          <div className="form-body mt-8">
            <div className="form-wrapper flex flex-col mt-6 first:mt-0">
              <label
                className="text-gray-800 text-sm font-medium mb-1"
                htmlFor="domain_name"
              >
                Domain Adı
              </label>
              <input
                id="domain_name"
                placeholder="Domain adı yaz"
                type="text"
              />
            </div>
            <div className="form-wrapper flex flex-col mt-6 first:mt-0">
              <label
                className="text-gray-800 text-sm font-medium mb-1"
                htmlFor="domain_name"
              >
                Ürün Tipi
              </label>
              <SelectPicker
                block
                placeholder={"Ürün tipi seçin"}
                cleanable={false}
                data={[
                  {
                    label: "Tur",
                    value: "1",
                  },
                  {
                    label: "Otel",
                    value: "2",
                  },
                  {
                    label: "Uçak",
                    value: "3",
                  },
                ]}
                searchable={false}
                /*container={textTypeWrapper.current}
                onSelect={(value, item) => {
                  setConfig({
                    fontFamily: value,
                  })
                }}*/
              />
            </div>
          </div>

          <div className="form-footer mt-8">
            <div className="btn-group flex gap-6">
              <Link
                to="domains"
                className="w-full"
              >
                <button
                  type="button"
                  className="btn outline w-full"
                >
                  Vazgeç
                </button>
              </Link>
              <button
                type="button"
                className="btn w-full"
                onClick={() => setPage(1)}
              >
                Ürün Ekle
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

function AddProductSuccess(props) {
  const { setPage } = props

  return (
    <div className="account-management-area relative flex items-center justify-center h-full max-h-full overflow-auto lg:overflow-hidden">
      {/*background blur icons*/}
      <BackgroundIcons />

      <div className="flex flex-col border border-gray-200 shadow-xl rounded-xl bg-white w-[392px] p-6">
        <form className="form-area w-full">
          <div className="form-header">
            <div className="inline-flex bg-success-100 rounded-full p-3">
              <Icon
                name="check-circle"
                size="20"
                color="#101828"
              />
            </div>
          </div>
          <div className="form-body mt-5">
            <div className="form-wrapper items-start flex flex-col">
              <p className="text-lg text-gray-900 font-semibold">Ürününüzü başarıyla ekledik.</p>
              <p className="text-sm text-gray-500 mt-2">
                Ürününüze kullanıcılar yetkilendirebilir ya da domain sayfasına dönüp ürünlerinizi görüntüleyebilirsiniz.
              </p>
            </div>
          </div>

          <div className="form-footer mt-8">
            <div className="btn-group flex gap-6">
              <Link
                to="domains"
                className="w-full"
              >
                <button
                  type="button"
                  className="btn outline w-full px-2"
                  onClick={() => setPage(0)}
                >
                  Domain Sayfası
                </button>
              </Link>
              <button
                type="button"
                className="btn w-full px-2"
                onClick={() => setPage(0)}
              >
                Yeni Ürün Ekle
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
