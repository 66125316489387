import { call, fork, put, takeLatest } from "redux-saga/effects"
import * as actions from "../actions/ListConfiguration"
import { LIST_CONFIGURATON_REQUESTED } from "../types"
import { API_GET } from "helpers/api"

function* watchGetListConfiguration() {
  yield takeLatest(LIST_CONFIGURATON_REQUESTED, getListConfiguration)
}

function* getListConfiguration(action) {
  try {
    const res = yield call(apiGetListConfiguration, action.payload)

    let result = res.data.result
    let fonts = result.filter((d) => d.configurationMetadataCode === "font")
    let links = result.filter((d) => d.configurationMetadataCode === "linktype")
    let displays = result.filter((d) => d.configurationMetadataCode === "theme")

    fonts = fonts.reduce((acc, value, index) => {
      // change first font name for recommended text
      //let label = index === 0 ? value.name + " (Önerilen)" : value.name
      acc.push({
        label: value.name,
        value: value.name,
        styles: value.properties,
      })
      return acc
    }, [])

    links = links.reduce((acc, value) => {
      acc.push({
        label: value.name,
        value: value.properties.textDecorationLine,
        styles: value.properties,
      })
      return acc
    }, [])

    displays = displays.reduce((acc, value) => {
      acc.push({
        label: value.name,
        styles: value.properties,
      })
      return acc
    }, [])

    yield put(
      actions.listConfigurationSucceed({
        fonts,
        links,
        displays,
      }),
    )
  } catch (err) {
    yield put(actions.listConfigurationFailed())
  }
}

const apiGetListConfiguration = (payload) => {
  return API_GET(
    `${process.env.REACT_APP_DISPLAY_DOMAIN}/api/panelconfiguration/getpanelconfigurations?${payload.query}&i=${Math.round(
      10000 + Math.random() * 1000000,
    )}`,
  )
}

export default function* listConfiguration() {
  yield fork(watchGetListConfiguration)
}
