import { call, fork, put, takeLatest } from "redux-saga/effects"
import * as actions from "redux/actions/getProductList"

import { GET_PRDUCT_LIST_REQUESTED } from "redux/types"
import { API_POST } from "helpers/api"

function* watchGetProductList() {
  yield takeLatest(GET_PRDUCT_LIST_REQUESTED, getProductList)
}

function* getProductList(action) {
  try {
    const res = yield call(apiGetProductList, action.payload)
    yield put(actions.productListSucceed(res?.data?.result?.data))
  } catch (err) {
    yield put(actions.productListFailed())
  }
}

const apiGetProductList = (payload) => {
  return API_POST(`${process.env.REACT_APP_PANEL_DOMAIN}/api/products/list`, payload)
}

export default function* productList() {
  yield fork(watchGetProductList)
}
