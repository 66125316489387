import * as type from "redux/types"

export const roleListRequested = (data) => ({
  type: type.GET_ROLES_REQUESTED,
  payload: data,
})

export const roleListSucceed = (data) => ({
  type: type.GET_ROLES_SUCCEEDED,
  payload: data,
})

export const roleListFailed = () => ({
  type: type.GET_ROLES_FAILED,
})
