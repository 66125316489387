import { call, fork, put, takeLatest } from "redux-saga/effects"
import * as actions from "redux/actions/pageSaveMain"

import { PAGE_SAVE_MAIN_REQUESTED } from "redux/types"
import { API_POST } from "helpers/api"

function* watchSaveMainPage() {
  yield takeLatest(PAGE_SAVE_MAIN_REQUESTED, getPageSaveMain)
}

function* getPageSaveMain(action) {
  try {
    const res = yield call(apiGetPageSaveMain, action.payload)
    yield put(actions.pageSaveMainSucceed(res?.data))
  } catch (err) {
    yield put(actions.pageSaveMainFailed())
  }
}

const apiGetPageSaveMain = (payload) => {
  return API_POST(`${process.env.REACT_APP_DISPLAY_DOMAIN}/api/page/save-mainpage`, payload)
}

export default function* pageSaveMain() {
  yield fork(watchSaveMainPage)
}
