import { useQuery } from "@tanstack/react-query"
import { API_GET } from "helpers/api"

export const useProductList = () => {
  const query = useQuery({
    queryKey: ["rule"],
    queryFn: () => API_GET(`${process.env.REACT_APP_PANEL_DOMAIN}/api/staticdata/productlist`),
  })

  return query
}
