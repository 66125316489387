import * as type from "redux/types"

export const clientUserRegisterRequested = (data) => ({
  type: type.CLIENT_USER_REGISTER_REQUESTED,
  payload: data,
})

export const clientUserRegisterSucceed = (data) => ({
  type: type.CLIENT_USER_REGISTER_SUCCEEDED,
  payload: data,
})

export const clientUserRegisterFailed = () => ({
  type: type.CLIENT_USER_REGISTER_FAILED,
})

export const clientUserRegisterFailedWithErrorMessage = (data) => ({
  type: type.CLIENT_USER_REGISTER_FAILED_WITH_ERROR_MESSAGE,
  payload: data,
})
