import React from "react"
import { Card } from "components"

const ReservationReportsNoData = () => {
  return (
    <Card>
      <div className="flex flex-col gap-1 items-center justify-center p-12">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="152"
          height="118"
          viewBox="0 0 152 118"
          fill="none"
        >
          <circle
            cx="76"
            cy="52"
            r="52"
            fill="#EAECF0"
          />
          <circle
            cx="21"
            cy="19"
            r="5"
            fill="#F2F4F7"
          />
          <circle
            cx="18"
            cy="109"
            r="7"
            fill="#F2F4F7"
          />
          <circle
            cx="145"
            cy="35"
            r="7"
            fill="#F2F4F7"
          />
          <circle
            cx="134"
            cy="8"
            r="4"
            fill="#F2F4F7"
          />
          <path
            d="M76 100C102.51 100 124 78.5097 124 52C124 25.4903 102.51 4 76 4C49.4903 4 28 25.4903 28 52C28 78.5097 49.4903 100 76 100Z"
            fill="#F2F4F7"
          />
          <path
            d="M104.799 100H47.1992V37.9202C49.9141 37.9171 52.5169 36.8373 54.4366 34.9176C56.3563 32.9979 57.4362 30.3951 57.4392 27.6802H94.5592C94.5563 29.0251 94.8202 30.3572 95.3355 31.5994C95.8509 32.8416 96.6074 33.9694 97.5615 34.9173C98.5093 35.8716 99.6371 36.6284 100.88 37.1438C102.122 37.6593 103.454 37.9232 104.799 37.9202V100Z"
            fill="white"
          />
          <path
            d="M76.0006 69.2802C84.4837 69.2802 91.3606 62.4033 91.3606 53.9202C91.3606 45.4371 84.4837 38.5602 76.0006 38.5602C67.5175 38.5602 60.6406 45.4371 60.6406 53.9202C60.6406 62.4033 67.5175 69.2802 76.0006 69.2802Z"
            fill="#D0D5DD"
          />
          <path
            d="M82.3357 60.2559C81.8358 60.7557 81.0254 60.7557 80.5255 60.2559L76 55.7304L71.4745 60.2559C70.9746 60.7557 70.1642 60.7557 69.6643 60.2559C69.1645 59.756 69.1645 58.9455 69.6643 58.4457L74.1898 53.9202L69.6643 49.3947C69.1645 48.8948 69.1645 48.0844 69.6643 47.5845C70.1642 47.0846 70.9746 47.0846 71.4745 47.5845L76 52.11L80.5255 47.5845C81.0254 47.0846 81.8358 47.0846 82.3357 47.5845C82.8355 48.0844 82.8355 48.8948 82.3357 49.3947L77.8102 53.9202L82.3357 58.4457C82.8355 58.9455 82.8355 59.756 82.3357 60.2559Z"
            fill="white"
          />
          <path
            d="M84.3178 73.1201H67.6778C66.6174 73.1201 65.7578 73.9797 65.7578 75.0401C65.7578 76.1005 66.6174 76.9601 67.6778 76.9601H84.3178C85.3782 76.9601 86.2378 76.1005 86.2378 75.0401C86.2378 73.9797 85.3782 73.1201 84.3178 73.1201Z"
            fill="#EAECF0"
          />
          <path
            d="M90.08 80.8H61.92C60.8596 80.8 60 81.6596 60 82.72C60 83.7804 60.8596 84.64 61.92 84.64H90.08C91.1404 84.64 92 83.7804 92 82.72C92 81.6596 91.1404 80.8 90.08 80.8Z"
            fill="#EAECF0"
          />
        </svg>
        <p className="text-gray-900 font-medium text-lg">Hiç rezervasyon yok</p>
        <p className="text-gray-500 text-sm">Rezervasyon almaya başlamak için önce kurulumu tamamlamalısın.</p>
      </div>
    </Card>
  )
}
export default ReservationReportsNoData
