/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux";

//layouts
import Alignment from "./Alignment"
import Background from "./Background"

//helpers
import {UpdateStrategy} from "../../../helpers/UpdateStrategy";

//utils
import {isProdEnv} from "utils/environmentsCheck";

//components
import { SideMenuListItem } from "components"
import {WidthAndHeightVisual} from "components/PageManager/WidthAndHeightVisual";

//saga-requests
import {getStrategySucceed} from "redux/actions/strategy";

const Settings = (props) => {
  let { editBlock, setEditBlock, setEditLayouts, UploadedImageInfo, setUploadedImageInfo, ThisComponent, setThisComponent, DefaultImageInfo, setDefaultImageInfo } = props

  const dispatch = useDispatch()

  //selectors
  let components = useSelector(state => state.strategy.getStrategy.list?.result)

  //states
  const [VisualBlockSettings, setVisualBlockSettings] = useState(null)

  //funcs


  //effects
  useEffect(() => {
    if (VisualBlockSettings){
      setThisComponent({
        ...ThisComponent,
        visual: {
          ...ThisComponent?.visual,
          ...VisualBlockSettings,
          style: {
            ...ThisComponent?.visual?.style,
            ...VisualBlockSettings?.style,
          },
        }
      })
    }
  }, [VisualBlockSettings])
  useEffect(() => {
    if (ThisComponent && VisualBlockSettings){
      const strategy = UpdateStrategy(components, ThisComponent)
      dispatch(getStrategySucceed(strategy))
    }
  }, [ThisComponent])

  return (
    <>
      <h6 className="text-gray-500 font-semibold">AYARLAR</h6>
      <div className="flex flex-col gap-4 mt-4">
        <WidthAndHeightVisual
          ThisComponent={ThisComponent}
          VisualSettings={VisualBlockSettings}
          setVisualSettings={setVisualBlockSettings}
        />

        <SideMenuListItem
          title="Arka plan"
          onClick={(e) =>
            setEditBlock({
              showSettingsBackgroundLayout: true,
            })
          }
        />
        {/*{!isProdEnv && (
          <SideMenuListItem
            title="Hizalama"
            onClick={(e) =>
              setEditBlock({
                showSettingsAlignmentLayout: true,
              })
            }
          />
        )}*/}
      </div>

      {/*<Alignment
        editBlock={editBlock}
        setEditBlock={setEditBlock}
      />*/}

      <Background
        editBlock={editBlock}
        setEditBlock={setEditBlock}
        setEditLayouts={setEditLayouts}
        UploadedImageInfo={UploadedImageInfo}
        setUploadedImageInfo={setUploadedImageInfo}
        ThisComponent={ThisComponent}
        setThisComponent={setThisComponent}
        DefaultImageInfo={DefaultImageInfo}
        setDefaultImageInfo={setDefaultImageInfo}
      />
    </>
  )
}

export default Settings
