import * as type from "../types"

const initialState = {
  id: 1,
  name: "",
}

const DisplayDevice = (state = initialState, action) => {
  switch (action.type) {
    case type.SET_DEVICE:
      return {
        ...state,
        id: action.payload.id,
        name: action.payload.name,
      }
    default:
      return state
  }
}

export default DisplayDevice
