import { call, fork, put, takeLatest } from "redux-saga/effects"
import * as actions from "redux/actions/getCountryCodes"

import { GET_COUNTRY_CODES_REQUESTED } from "redux/types"
import { API_GET } from "helpers/api"

function* watchGetCountryCodes() {
  yield takeLatest(GET_COUNTRY_CODES_REQUESTED, getCountryCodes)
}

function* getCountryCodes(action) {
  try {
    const res = yield call(apiGetCountryCodes, action.payload)
    yield put(actions.countryCodesSucceed(res?.data))
  } catch (err) {
    yield put(actions.countryCodesFailed())
  }
}

const apiGetCountryCodes = (payload) => {
  return API_GET(`${process.env.REACT_APP_BOOK_DOMAIN}/api/paneldestination/countrycodes`, payload)
}

export default function* countryCodes() {
  yield fork(watchGetCountryCodes)
}
