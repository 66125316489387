import {v4 as uuidv4} from "uuid";

const AddIconPattern = (model, thisComponent, mediaIdList) => {

	const isRelationComponent = model?.find((item) => item?.relation === "Component")
	const getIconModelRelationData = (index = 0) => {
		return isRelationComponent?.Childs[index]?.ChildStrategies?.find((item) => item?.relation === "Data")
	}
	const getMediaIdByIconId = (index) => {
		return getIconModelRelationData(index)?.input?.mediaFileId
	}

	const updateList = {
		items: [],
		translateItems: []

	}

	mediaIdList && mediaIdList.map((item) => {
		const thisIcon = thisComponent?.children[item?.cIndex].data
		updateList.items.push({
			...getIconModelRelationData(item?.cIndex)?.input,
			id: thisIcon?.id ? thisIcon.id.replace("new-", "") : uuidv4(),
			order: 0,
			mediaFileId: item?.mediaId || getMediaIdByIconId(item?.cIndex) || 0,
			url: thisIcon?.url || null,
			//text: thisIcon?.text || "",
			target: thisIcon?.target || "_blank",
		})
		getIconModelRelationData(item?.cIndex)?.translateItems.map(item => {
			if (item?.langKey === "TextLangKey"){
				item.value = thisIcon?.text || ""
				item.name = "icon list title"
			}
		})
	})

	return updateList

}

export default AddIconPattern