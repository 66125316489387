import * as type from "../types"

const initialState = {
  show: false,
}

const ViewportFullSize = (state = initialState, action) => {
  switch (action.type) {
    case type.SET_VIEWPORT_FULL_SIZE:
      return {
        ...state,
        show: action.payload,
      }
    default:
      return state
  }
}

export default ViewportFullSize
