import React from "react"
import { Modal } from "rsuite"

const LoadingModal = (props) => {
  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      className="w-[382px] !mt-0 absolute top-1/2 left-1/2 transform !-translate-x-1/2 !-translate-y-1/2"
    >
      <Modal.Header
        closeButton={false}
        className="text-center flex justify-center"
      ></Modal.Header>
      <Modal.Body className="text-center">
        <p className="font-medium text-[#101828] text-lg">Lütfen Bekleyiniz...</p>
      </Modal.Body>
    </Modal>
  )
}

export default LoadingModal
