import { jsPDF } from "jspdf"
import "jspdf-autotable"

export const exportToPDF = (data) => {
  const columns = Object.keys(data[0])
  const rows = data?.map((item) => Object.values(item))

  const doc = new jsPDF()

  doc.autoTable({
    head: [columns],
    body: rows,
    startY: 10,
    styles: { halign: "left" },
    bodyStyles: { cellHeight: 25 },
    columnStyles: { 0: { cellWidth: 25 } },
  })

  doc.save("data.pdf")
}
