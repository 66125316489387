import * as type from "redux/types"

export const getSettingsRequested = (data) => ({
  type: type.GET_SETTINGS_REQUESTED,
  payload: data,
})

export const getSettingsSucceed = (data) => ({
  type: type.GET_SETTINGS_SUCCEEDED,
  payload: data,
})

export const getSettingsFailed = () => ({
  type: type.GET_SETTINGS_FAILED,
})
