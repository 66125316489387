import { call, fork, put, takeLatest } from "redux-saga/effects"
import * as actions from "../actions/GetConfiguration"
import { GET_CONFIGURATON_REQUESTED } from "../types"
import { API_GET } from "helpers/api"

function* watchGetConfiguration() {
  yield takeLatest(GET_CONFIGURATON_REQUESTED, getConfiguration)
}

function* getConfiguration(action) {
  try {
    const res = yield call(apiGetConfiguration, action.payload)
    yield put(actions.getConfigurationSucceed(res.data.result))
  } catch (err) {
    yield put(actions.getConfigurationFailed())
  }
}

const apiGetConfiguration = (payload) => {
  return API_GET(`${process.env.REACT_APP_DISPLAY_DOMAIN}/api/clientconfigurationpaneluser/get`)
}

export default function* GetConfiguration() {
  yield fork(watchGetConfiguration)
}
