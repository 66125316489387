import React from "react"

const MarketPlaceHomePageSkeleton = () => {
  return (
    <main>
      <section className="p-10 my-0 mx-auto flex flex-col max-w-[1440px]">
        <div className="animate-pulse space-y-3 my-12 grid grid-cols-3 gap-4">
          {Array.from(Array(3), (_, index) => (
            <div
              className="!mt-0 mb-10"
              key={index}
            >
              <div className="bg-slate-200 rounded h-[293px]" />
              <div className="h-2 bg-slate-200 rounded w-1/3 mt-3" />
            </div>
          ))}
          {Array.from(Array(6), (_, index) => (
            <div
              className="!mt-0 !mb-10 bg-white border rounded-lg min-h-[226px] p-4 flex flex-col"
              key={index}
            >
              <div className="flex flex-col mb-[10px] gap-4">
                <div className="h-2 bg-slate-200 rounded w-1/3" />
                <div className="h-2 bg-slate-200 rounded w-1/3" />
                <div className="h-24 bg-slate-200 rounded" />
              </div>
              <div className="mt-auto">
                <div className="flex justify-between ">
                  <div className="h-2 bg-slate-200 rounded w-1/3" />
                  <div className="h-2 bg-slate-200 rounded w-1/3" />
                </div>
              </div>
            </div>
          ))}
          {Array.from(Array(3), (_, index) => (
            <div
              className="!mt-0 !mb-10"
              key={index}
            >
              <div className="bg-slate-200 rounded h-[293px]" />
              <div className="h-2 bg-slate-200 rounded w-1/3 mt-3" />
            </div>
          ))}
        </div>
      </section>
    </main>
  )
}

export default MarketPlaceHomePageSkeleton
