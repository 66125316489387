export const UpdateStrategy = (components, ThisComponent) => {
	let componentIndex = components?.children?.findIndex(item => item.code === ThisComponent.code)
	if (componentIndex === -1) componentIndex = components?.children?.length || 0
	if (components && components.children){
		if (ThisComponent?.order !== undefined && typeof ThisComponent?.order === "number"){
			components.children.splice(ThisComponent?.order, 0, ThisComponent);
			delete ThisComponent.order
		} else {
			components.children[componentIndex] = ThisComponent
		}
	} else {
		components = {
			...components,
			children : [{...ThisComponent}]
		}
	}

	return {
		result: {
			...components
		},
	}

}