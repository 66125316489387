import {getPageListRequested} from "redux/actions/getPageList";

const GetPageList = (dispatch, text, type, state) => {

	dispatch(
		getPageListRequested({
			PageName: text,
			PageType: type,
			PageState: state,
			LanguageCode: "tr",
			IsExcel: false,
			DataTableRequest: {
				Page: 1,
				Start: 0,
				PageSize: 1000,
				SortColumn: "",
				Draw: "",
			},
		}),
	)
}

export default GetPageList