import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

// components
import { Icon, DoneBtn, EditBlockName } from "components"
import { Dropdown, Whisper } from "rsuite"

const BlockWrapper = (props) => {
  let { show, className, activeLayout, editBlock, setEditBlock, disabled, doneBtnOnClick, children, ThisComponent, setThisComponent } = props
  let BlockName = ThisComponent?.visual?.settings?.name || activeLayout?.info?.name


  const [BlockNameDdownOpen, setBlockNameDdownOpen] = useState(false)

  const blockNameDropDownToggle = (show) => {
    setBlockNameDdownOpen(show)
  }
  const renderBlockNameDropdown = ({ onClose, left, top, className }, ref) => {
    const handleSelect = (eventKey) => {
      if (eventKey) {
        onClose()
        switch (eventKey) {
          case "rename_block_name":
            setEditBlock({
              rename_block_name: true,
            })
            break
          default:
            break
        }
      }
    }
    return (
      <Dropdown.Menu
        ref={ref}
        className={`${className} min-w-[190px] max-w-[240px]`}
        style={{ left, top }}
        onSelect={handleSelect}
      >
        <Dropdown.Item className="font-medium py-2.5 px-4 cursor-default !bg-white !text-gray-800 hover:!bg-white hover:text-gray-800">
          Seçenekler
        </Dropdown.Item>
        <hr />
        <Dropdown.Item
          className="flex gap-3 items-center py-2.5 px-4"
          eventKey="rename_block_name"
        >
          <Icon
            name="edit-2"
            size={16}
            color="#1D2939"
          />
          <p>Bloğu Yeniden Adlandır</p>
        </Dropdown.Item>
      </Dropdown.Menu>
    )
  }

  // when blockListTab item dropdown clicked
  useEffect(() => {
    setBlockNameDdownOpen(false)
  }, [editBlock])

  return (
    <div className={`inner-sidebar-wrapper flex flex-col ${className}${show ? " active" : ""}`}>
      <DoneBtn
          disabled={disabled}
          onClick={doneBtnOnClick} />
      <div className="inside-container flex flex-col overflow-hidden text-sm mt-4">
        <div className="caption-box flex items-center mb-4">
          <h4
            className="text-2xl font-bold text-gray-800 truncate"
            title={BlockName}
          >
            {BlockName}
          </h4>

          <Whisper
            placement="bottomEnd"
            trigger="click"
            speaker={({ onClose, left, top, className }, ref) => renderBlockNameDropdown({ onClose, left, top, className }, ref)}
            onOpen={() => blockNameDropDownToggle(true)}
            onClose={() => blockNameDropDownToggle(false)}
          >
            <div className="cursor-pointer ml-auto">
              <Icon
                name="more-vertical"
                size={14}
                className="!duration-[100ms]"
                color={BlockNameDdownOpen ? "#2E90FA" : "#1D2939"}
              />
            </div>
          </Whisper>
        </div>

        <div className="inside-container flex flex-col overflow-auto text-sm">{children}</div>
      </div>

      <EditBlockName
        activeLayout={activeLayout}
        editBlock={editBlock}
        setEditBlock={setEditBlock}
        ThisComponent={ThisComponent}
        setThisComponent={setThisComponent}
      />
    </div>
  )
}

export default BlockWrapper

BlockWrapper.prototypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
}

BlockWrapper.defaultProps = {
  className: "",
  disabled: false,
}
