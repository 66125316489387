import React, {useEffect, useRef, useState} from "react"
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router";
import useQuery from "utils/useQuery";

//layouts
import Seo from "./Seo";


// components
import {ErrorMessage, LoadBar, TabList} from "components"
import { SelectPicker } from "rsuite"

// localization
import localization from "localization"

// constants
import {PageTypes} from "constants/index";

// helpers
import {tr2en} from "utils/tr2en";
import {pageNameIsCustomTooltip} from "tooltips/PageManager/AddNewPage";
import getSelectedIndex from "utils/getSelectedIndex";

// saga-requests
import {pageSaveRequested, pageSaveSucceed} from "redux/actions/pageSave";
import {pageUpdateRequested, pageUpdateSucceed} from "redux/actions/pageUpdate";
import {setSettings} from "helpers/setSettings";

const PageSettings = (props) => {

  let {
    menuItems,
    setMenuItems,
    //setActiveMenuTab,
    setPageLock,
    seoSettings,
    setSeoSettings,
  } = props

  const dispatch = useDispatch()
  let query = useQuery()
  let pagePath = query.get("path")
  let pageType = query.get("pageType")
  let pageState = query.get("pageState")
  let isMainPage = query.get("isMainPage")
  const isNewPage = !pagePath

  // refs
  const pageTypeWrapper = useRef()

  const PageTypeSelectData = Object.keys(PageTypes).map((type, index) => {
    return ({
      label: type,
      value: Object.values(PageTypes)[index]
    })
  })

  // selectors
  const pageSave = useSelector(state => state.pageSave.response)
  const pageSaveLoading = useSelector(state => state.pageSave.loading)
  const pageSaveError = useSelector(state => state.pageSave.error)
  const pageUpdate = useSelector(state => state.pageUpdate.response)
  const pageUpdateLoading = useSelector(state => state.pageUpdate.loading)
  const pageUpdateError = useSelector(state => state.pageUpdate.error)

  //states
  const [ActiveTab, setActiveTab] = useState(0)
  const [ActivePageType, setActivePageType] = useState((PageTypeSelectData && PageTypeSelectData[pageType]?.value) || Object.keys(PageTypes)[1])
  const [isUpdate, setIsUpdate] = useState(!!pagePath)
  const [Params, setParams] = useState(useParams())
  const [isCustom, setIsCustom] = useState(query.get("isCustom"))
  const [PagePathGeneration, setPagePathGeneration] = useState(true)

  const tabListOnChange = (e) => {
    let selectedIndex;
    selectedIndex = getSelectedIndex(e)
    setActiveTab(selectedIndex)
  }

  const pagePathChanged = (e, stopPathGenarete) => {
    if (stopPathGenarete) setPagePathGeneration(false)
    let value = e.target.value
    const re = /^([a-z0-9-])*$/;
    if (value === "" || re.test(value)) {
      setSeoSettings({
        ...seoSettings,
        path: `${tr2en(value).toLowerCase().replaceAll(" ", "-")}`
      })
    }
  }

  const checkSettings = async () => {
    dispatch(pageSaveSucceed(null))
    dispatch(pageUpdateSucceed(null))
    if (seoSettings?.pageTitle?.length && seoSettings?.path?.length) {
      if (isUpdate){
        dispatch(pageUpdateRequested({
          Id: Number(Params?.pageId),
          Path: `/${seoSettings?.path}`,
          Name: seoSettings?.pageTitle,
          PageType: Number(ActivePageType),
          State : pageState * 1, // Pasif/Aktif
          LanguageCode : "tr",
          Method :"GET",  //GET, POST
          Action : 200,
          Status : 1,
          TargetPath:"",
        }))
      } else {
        dispatch(pageSaveRequested({
          Path: `/${seoSettings?.path}`,
          Name: seoSettings?.pageTitle,
          PageType: Number(ActivePageType),
        }))
      }
    }

  }

  useEffect(() => {
    if (pageSave?.Success || pageUpdate?.Success) {
      let result
      if (pageSave?.Success){
        const blocksMenu = menuItems.find(item => item?.icon === "layout")
        if (blocksMenu.disabled){
          blocksMenu.disabled = false
          setMenuItems([
            ...menuItems
          ])
        }
        setParams({
          ...Params,
          pageId: pageSave?.Result?.Id
        })
        setIsUpdate(true)
        setIsCustom("true")
        result = pageSave?.Result
      } else if (pageUpdate?.Success){
        result = pageUpdate?.Result
      }
      window.history.pushState('', '', `${result?.Id}?isCustom=${isCustom !== "false"}&path=${result?.Path}&name=${result?.Name}&pageType=${result?.PageType}&pageState=${result?.State}&isMainPage=${isMainPage || false}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSave, pageUpdate])

  useEffect(() => {
    setPageLock(pageSaveLoading || pageUpdateLoading)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSaveLoading, pageUpdateLoading])

  useEffect(() => {
    pageNameIsCustomTooltip()
  }, [])

  useEffect(() => {
    if (isNewPage && seoSettings?.pageTitle && PagePathGeneration){
      const desired = seoSettings?.pageTitle.replace(/[^\w\s]/gi, '-')
      pagePathChanged({target: {value: tr2en(desired).toLowerCase().replaceAll(" ", "-").replaceAll("_", "-")}})
    }
  }, [seoSettings?.pageTitle])

  useEffect(() => {
    if (seoSettings?.path){
      if (seoSettings?.path.startsWith("/")){
        setSeoSettings({
          ...seoSettings,
          path: seoSettings?.path.slice(1)
        })
      }
    }

  }, [seoSettings?.path])

  return (
    <div className="h-full overflow-auto">
      <div className="flex flex-col gap-4">
        <TabList
          name={`settingsTab`}
          onChange={tabListOnChange}
          tabs={[
            { label: "Sayfa Bilgileri", disabled: false  },
            { label: "Seo", disabled: false  },
          ]}
          tabClassName="w-full flex items-center justify-center gap-2.5 text-center text-gray-600 text-xs p-2 border-r last:border-r-0 hover:bg-gray-50 transition duration-400"
          wrapperClassName="flex shrink-0 border rounded-t-lg border-gray-200 overflow-hidden"
          //itemActiveClass="!text-gray-800 !bg-blue-50"
          activeTab={ActiveTab}
        />


        {ActiveTab === 0 && (
          <>
            <div
              className={`relative input-wrapper ${isCustom === "false" ? "opacity-50 !cursor-not-allowed is-custom-tooltip [&>*]:pointer-events-none" : ""}`}
              ref={pageTypeWrapper}
            >
              <span className="text-gray-800 font-medium">Sayfa tipi</span>
              <SelectPicker
                block
                className="mt-1.5"
                placeholder={""}
                value={ActivePageType}
                disabledItemValues={!isCustom ? ["0"] : []}
                menuMaxHeight={164}
                cleanable={false}
                locale={localization?.TR?.PickerLocaleType}
                data={PageTypeSelectData}
                searchable={false}
                container={pageTypeWrapper.current}
                onSelect={(value) => setActivePageType(value)}
              />
            </div>

            <div className={`input-wrapper ${isCustom === "false" ? "!cursor-not-allowed is-custom-tooltip [&>*]:pointer-events-none" : ""}`}>
              <label>
                <span className="text-gray-800 font-medium">Sayfa adı</span>
                <input
                  className="w-full mt-1.5"
                  type="text"
                  disabled={isCustom === "false"}
                  name={"pageTitle"}
                  value={seoSettings.pageTitle}
                  onChange={(e) =>
                    setSettings(e, true, seoSettings).then((res) => setSeoSettings(res))
                  }
                />
              </label>
            </div>

            <div className={`input-wrapper ${isCustom === "false" ? "!cursor-not-allowed is-custom-tooltip [&>*]:pointer-events-none" : ""}`}>
              <label>
                <span className="text-gray-800 font-medium">Sayfa url`i</span>
                <div className="relative rounded-lg overflow-hidden mt-1.5">

                  <div className="absolute left-0 top-0 h-full pl-3.5 flex items-center justify-center text-base select-none pointer-events-none">
                    /
                  </div>
                  <input
                    className="w-full !pl-5"
                    type="text"
                    disabled={isCustom === "false"}
                    onChange={(e) => pagePathChanged(e, true)}
                    value={seoSettings?.path}
                  />
                </div>
              </label>
            </div>


            {(isCustom === "true" || isCustom === null) && (
              <button
                className="btn sm secondary w-full mt-4"
                onClick={checkSettings}
                disabled={pageSaveLoading || pageUpdateLoading}
              >
                {/*<Icon name={"arrow-left"} size="16" color={"#60a5fa"} />*/}

                {(pageSaveLoading || pageUpdateLoading) && <LoadBar pathFill={"#2E90FA"}  />}
                {(!pageSaveLoading || !pageUpdateLoading) && (isUpdate ? "Güncelle" : "Kaydet")}

              </button>
            )}
          </>

        )}


        {ActiveTab === 1 && (
          <Seo
            seoSettings={seoSettings}
            setSeoSettings={setSeoSettings}
          />
        )}

      </div>

      {((pageSaveError || pageUpdateError || (pageUpdate?.Errors && pageUpdate?.Errors[0]?.Message) || (pageSave?.Errors && pageSave?.Errors[0]?.Message)) || ((pageUpdate && !pageUpdate?.Success) || (pageSave && !pageSave?.Success))) && (
        <ErrorMessage className="mt-4">
          {
            (pageUpdate?.Errors && pageUpdate?.Errors[0]?.Message) ||
            (pageSave?.Errors && pageSave?.Errors[0]?.Message) ||
            "Path kaydolurken bir sorun olustu"}
        </ErrorMessage>
      )}
    </div>
  )
}

export default PageSettings
