import React from "react"

import Icon from "components/Icon"

const PaymentPlans = () => {
  return (
    <div className="flex justify-between gap-4 w-full border rounded-lg bg-white p-6">
      <div className="p-4 flex flex-col border rounded-lg min-h-[242px] w-1/2">
        <div className="flex items-center mb-[10px] gap-2">
          <span className="text-xl font-semibold text-gray-800">Aylık Ödeme</span>
        </div>
        <p className="font-normal text-gray-500 text-sm leading-5 line-clamp-3">
          Open repair of infrarenal aortic aneurysm or dissection, plus repair of associated arterial trauma, following
          unsuccessful endovascular repair; tube prosthesis, Her ay yenilenir.
        </p>
        <div className="mt-auto">
          <div className="flex justify-between ">
            <span className="text-sm text-gray-800 font-normal">$6.00/Ay, $78.00/Yıl</span>
            <span className="flex items-center w-fit px-2 rounded-2xl text-xs gap-[6px] $text-success-500 bg-success-50">
              <Icon
                color="currentColor"
                name="check"
                size={"9"}
              />
              Etkinleştirildi
            </span>
          </div>
        </div>
      </div>
      <div className="p-4 flex flex-col bg-white border rounded-lg min-h-[242px] w-1/2">
        <div className="flex items-center mb-[10px] gap-2">
          <span className="text-xl font-semibold text-gray-800">Yıllık Ödeme</span>
        </div>
        <span className="mb-2 text-warning-500">%26 kazanın</span>
        <p className="font-normal text-gray-500 text-sm leading-5 line-clamp-3">
          Open repair of infrarenal aortic aneurysm or dissection, plus repair of associated arterial trauma, Her yıl yenilenir.
          Dilediğiniz zaman iptal edin.
        </p>
        <div className="mt-auto">
          <div className="flex justify-between">
            <span className="text-sm text-gray-800 font-normal">$4.00/Ay, $48.00/Yıl</span>
            <button className="text-sm text-blue-500 font-normal">Yıllık Plana Geç</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PaymentPlans
