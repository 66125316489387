// Contracts Set
export const CONTRACTS_SET_REQUESTED = "CONTRACTS_SET_REQUESTED"
export const CONTRACTS_SET_SUCCEEDED = "CONTRACTS_SET_SUCCEEDED"
export const CONTRACTS_SET_FAILED = "CONTRACTS_SET_FAILED"

// ProfileImage
export const PROFILE_IMAGE_REQUESTED = "PROFILE_IMAGE_REQUESTED"
export const PROFILE_IMAGE_SUCCEEDED = "PROFILE_IMAGE_SUCCEEDED"
export const PROFILE_IMAGE_FAILED = "PROFILE_IMAGE_FAILED"

// AssociatedDomains
export const ASSOCIATED_DOMAINS_REQUESTED = "ASSOCIATED_DOMAINS_REQUESTED"
export const ASSOCIATED_DOMAINS_SUCCEEDED = "ASSOCIATED_DOMAINS_SUCCEEDED"
export const ASSOCIATED_DOMAINS_FAILED = "ASSOCIATED_DOMAINS_FAILED"

// GetPropertiesWithUser
export const PROPERTIES_REQUESTED = "PROPERTIES_REQUESTED"
export const PROPERTIES_SUCCEEDED = "PROPERTIES_SUCCEEDED"
export const PROPERTIES_FAILED = "PROPERTIES_FAILED"

// ClientConfiguration - SET
export const CLIENT_CONFIG_REQUESTED = "CLIENT_CONFIG_REQUESTED"
export const CLIENT_CONFIG_SUCCEEDED = "CLIENT_CONFIG_SUCCEEDED"
export const CLIENT_CONFIG_FAILED = "CLIENT_CONFIGURATION_FAILED"

// ClientConfiguration - GET
export const CLIENT_CONFIG_GET_REQUESTED = "CLIENT_CONFIG_GET_REQUESTED"
export const CLIENT_CONFIG_GET_SUCCEEDED = "CLIENT_CONFIG_GET_SUCCEEDED"
export const CLIENT_CONFIG_GET_FAILED = "CLIENT_CONFIGUGET_RATION_FAILED"

// MyReservationsList
export const MY_RESERVATIONS_LIST_REQUESTED = "MY_RESERVATIONS_LIST_REQUESTED"
export const MY_RESERVATIONS_LIST_SUCCEEDED = "MY_RESERVATIONS_LIST_SUCCEEDED"
export const MY_RESERVATIONS_LIST_FAILED = "MY_RESERVATIONS_LIST_FAILED"

// MyReservationsDetail
export const MY_RESERVATIONS_DETAIL_REQUESTED = "MY_RESERVATIONS_DETAIL_REQUESTED"
export const MY_RESERVATIONS_DETAIL_SUCCEEDED = "MY_RESERVATIONS_DETAIL_SUCCEEDED"
export const MY_RESERVATIONS_DETAIL_FAILED = "MY_RESERVATIONS_DETAIL_FAILED"

// MyReservationsApprove
export const MY_RESERVATIONS_APPROVE_REQUESTED = "MY_RESERVATIONS_APPROVE_REQUESTED"
export const MY_RESERVATIONS_APPROVE_SUCCEEDED = "MY_RESERVATIONS_APPROVE_SUCCEEDED"
export const MY_RESERVATIONS_APPROVE_FAILED = "MY_RESERVATIONS_APPROVE_FAILED"

// MyWorkArea Info
export const MY_WORKAREA_INFO_REQUESTED = "MY_WORKAREA_INFO_REQUESTED"
export const MY_WORKAREA_INFO_SUCCEEDED = "MY_WORKAREA_INFO_SUCCEEDED"
export const MY_WORKAREA_INFO_FAILED = "MY_WORKAREA_INFO_FAILED"

// AddonList
export const ADDON_LIST_REQUESTED = "ADDON_LIST_REQUESTED"
export const ADDON_LIST_SUCCEEDED = "ADDON_LIST_SUCCEEDED"
export const ADDON_LIST_FAILED = "ADDON_LIST_FAILED"

// AddonDetail
export const ADDON_DETAIL_REQUESTED = "ADDON_DETAIL_REQUESTED"
export const ADDON_DETAIL_SUCCEEDED = "ADDON_DETAIL_SUCCEEDED"
export const ADDON_DETAIL_FAILED = "ADDON_DETAIL_FAILED"

//AddonPrice
export const ADDON_PRICE_REQUESTED = "ADDON_PRICE_REQUESTED"
export const ADDON_PRICE_SUCCEEDED = "ADDON_PRICE_SUCCEEDED"
export const ADDON_PRICE_FAILED = "ADDON_PRICE_FAILED"

//AddonCategory
export const ADDON_CATEGORY_REQUESTED = "ADDON_CATEGORY_REQUESTED"
export const ADDON_CATEGORY_SUCCEEDED = "ADDON_CATEGORY_SUCCEEDED"
export const ADDON_CATEGORY_FAILED = "ADDON_CATEGORY_FAILED"

//AddonSave
export const ADDON_SAVE_REQUESTED = "ADDON_SAVE_REQUESTED"
export const ADDON_SAVE_SUCCEEDED = "ADDON_SAVE_SUCCEEDED"
export const ADDON_SAVE_FAILED = "ADDON_SAVE_FAILED"

// Destinations
export const COUNTRIES_REQUESTED = "COUNTRIES_REQUESTED"
export const COUNTRIES_SUCCEEDED = "COUNTRIES_SUCCEEDED"
export const COUNTRIES_FAILED = "COUNTRIES_FAILED"
export const CITIES_REQUESTED = "CITIES_REQUESTED"
export const CITIES_SUCCEEDED = "CITIES_SUCCEEDED"
export const CITIES_FAILED = "CITIES_FAILED"
export const DISTRICTS_REQUESTED = "DISTRICTS_REQUESTED"
export const DISTRICTS_SUCCEEDED = "DISTRICTS_SUCCEEDED"
export const DISTRICTS_FAILED = "DISTRICTS_FAILED"

// Account Management
export const GET_ALL_TENANTS_REQUESTED = "GET_ALL_TENANTS_REQUESTED"
export const GET_ALL_TENANTS_SUCCEEDED = "GET_ALL_TENANTS_SUCCEEDED"
export const GET_ALL_TENANTS_FAILED = "GET_ALL_TENANTS_FAILED"
export const GET_TENANT_REQUESTED = "GET_TENANT_REQUESTED"
export const GET_TENANT_SUCCEEDED = "GET_TENANT_SUCCEEDED"
export const GET_TENANT_FAILED = "GET_TENANT_FAILED"
export const SET_TENANT_REQUESTED = "SET_TENANT_REQUESTED"
export const SET_TENANT_SUCCEEDED = "SET_TENANT_SUCCEEDED"
export const SET_TENANT_FAILED = "SET_TENANT_FAILED"
export const DELETE_TENANT_REQUESTED = "DELETE_TENANT_REQUESTED"
export const DELETE_TENANT_SUCCEEDED = "DELETE_TENANT_SUCCEEDED"
export const DELETE_TENANT_FAILED = "DELETE_TENANT_FAILED"
export const UPDATE_TENANT_REQUESTED = "UPDATE_TENANT_REQUESTED"
export const UPDATE_TENANT_SUCCEEDED = "UPDATE_TENANT_SUCCEEDED"
export const UPDATE_TENANT_FAILED = "UPDATE_TENANT_FAILED"
export const GET_ALL_DOMAINS_REQUESTED = "GET_ALL_DOMAINS_REQUESTED"
export const GET_ALL_DOMAINS_SUCCEEDED = "GET_ALL_DOMAINS_SUCCEEDED"
export const GET_ALL_DOMAINS_FAILED = "GET_ALL_DOMAINS_FAILED"
export const GET_DOMAIN_REQUESTED = "GET_DOMAIN_REQUESTED"
export const GET_DOMAIN_SUCCEEDED = "GET_DOMAIN_SUCCEEDED"
export const GET_DOMAIN_FAILED = "GET_DOMAIN_FAILED"
export const SET_DOMAIN_REQUESTED = "SET_DOMAIN_REQUESTED"
export const SET_DOMAIN_SUCCEEDED = "SET_DOMAIN_SUCCEEDED"
export const SET_DOMAIN_FAILED = "SET_DOMAIN_FAILED"
export const UPDATE_DOMAIN_REQUESTED = "UPDATE_DOMAIN_REQUESTED"
export const UPDATE_DOMAIN_SUCCEEDED = "UPDATE_DOMAIN_SUCCEEDED"
export const UPDATE_DOMAIN_FAILED = "UPDATE_DOMAIN_FAILED"
export const DELETE_DOMAIN_REQUESTED = "DELETE_DOMAIN_REQUESTED"
export const DELETE_DOMAIN_SUCCEEDED = "DELETE_DOMAIN_SUCCEEDED"
export const DELETE_DOMAIN_FAILED = "DELETE_DOMAIN_FAILED"
export const GET_USERLIST_REQUESTED = "GET_USERLIST_REQUESTED"
export const GET_USERLIST_SUCCEEDED = "GET_USERLIST_SUCCEEDED"
export const GET_USERLIST_FAILED = "GET_USERLIST_FAILED"
export const SET_USERLIST_REQUESTED = "SET_USERLIST_REQUESTED"
export const SET_USERLIST_SUCCEEDED = "SET_USERLIST_SUCCEEDED"
export const SET_USERLIST_FAILED = "SET_USERLIST_FAILED"
export const GET_CUSTOM_DOMAINS_NEAR_EXPIRATION_REQUESTED = "GET_CUSTOM_DOMAINS_NEAR_EXPIRATION_REQUESTED"
export const GET_CUSTOM_DOMAINS_NEAR_EXPIRATION_SUCCEEDED = "GET_CUSTOM_DOMAINS_NEAR_EXPIRATION_SUCCEEDED"
export const GET_CUSTOM_DOMAINS_NEAR_EXPIRATION_FAILED = "GET_CUSTOM_DOMAINS_NEAR_EXPIRATION_FAILED"
export const REFRESH_CUSTOM_DOMAIN_VALIDATION_TOKEN_REQUESTED = "REFRESH_CUSTOM_DOMAIN_VALIDATION_TOKEN_REQUESTED"
export const REFRESH_CUSTOM_DOMAIN_VALIDATION_TOKEN_SUCCEEDED = "REFRESH_CUSTOM_DOMAIN_VALIDATION_TOKEN_SUCCEEDED"
export const REFRESH_CUSTOM_DOMAIN_VALIDATION_TOKEN_FAILED = "REFRESH_CUSTOM_DOMAIN_VALIDATION_TOKEN_FAILED"

// ProductList
export const GET_PRDUCT_LIST_REQUESTED = "GET_PRDUCT_LIST_REQUESTED"
export const GET_PRDUCT_LIST_SUCCEEDED = "GET_PRDUCT_LIST_SUCCEEDED"
export const GET_PRDUCT_LIST_FAILED = "GET_PRDUCT_LIST_FAILED"

// ProductList
export const GET_PAGE_LIST_REQUESTED = "GET_PAGE_LIST_REQUESTED"
export const GET_PAGE_LIST_SUCCEEDED = "GET_PAGE_LIST_SUCCEEDED"
export const GET_PAGE_LIST_FAILED = "GET_PAGE_LIST_FAILED"

// AgencyList
export const GET_AGENCY_LIST_REQUESTED = "GET_AGENCY_LIST_REQUESTED"
export const GET_AGENCY_LIST_SUCCEEDED = "GET_AGENCY_LIST_SUCCEEDED"
export const GET_AGENCY_LIST_FAILED = "GET_AGENCY_LIST_FAILED"

// AgencyListForCorporate
export const GET_AGENCY_LIST_FOR_CORPORATE_REQUESTED = "GET_AGENCY_LIST_FOR_CORPORATE_REQUESTED"
export const GET_AGENCY_LIST_FOR_CORPORATE_SUCCEEDED = "GET_AGENCY_LIST_FOR_CORPORATE_SUCCEEDED"
export const GET_AGENCY_LIST_FOR_CORPORATE_FAILED = "GET_AGENCY_LIST_FOR_CORPORATE_FAILED"

// DomainStateGet
export const DOMAIN_GET_STATE_REQUESTED = "DOMAIN_GET_STATE_REQUESTED"
export const DOMAIN_GET_STATE_SUCCEEDED = "DOMAIN_GET_STATE_SUCCEEDED"
export const DOMAIN_GET_STATE_FAILED = "DOMAIN_GET_STATE_FAILED"

// DomainStateUptade
export const DOMAIN_UPTADE_STATE_REQUESTED = "DOMAIN_UPTADE_STATE_REQUESTED"
export const DOMAIN_UPTADE_STATE_SUCCEEDED = "DOMAIN_UPTADE_STATE_SUCCEEDED"
export const DOMAIN_UPTADE_STATE_FAILED = "DOMAIN_UPTADE_STATE_FAILED"

// UserInfo
export const USER_INFO_STATE_REQUESTED = "USER_INFO_STATE_REQUESTED"
export const USER_INFO_STATE_SUCCEEDED = "USER_INFO_STATE_SUCCEEDED"
export const USER_INFO_STATE_FAILED = "USER_INFO_STATE_FAILED"

// PageManager
export const CATEGORY_LIST_REQUESTED = "CATEGORY_LIST_REQUESTED"
export const CATEGORY_LIST_SUCCEEDED = "CATEGORY_LIST_SUCCEEDED"
export const CATEGORY_LIST_FAILED = "CATEGORY_LIST_FAILED"
export const CATEGORY_GET_REQUESTED = "CATEGORY_GET_REQUESTED"
export const CATEGORY_GET_SUCCEEDED = "CATEGORY_GET_SUCCEEDED"
export const CATEGORY_GET_FAILED = "CATEGORY_GET_FAILED"
export const CATEGORY_SAVE_REQUESTED = "CATEGORY_SAVE_REQUESTED"
export const CATEGORY_SAVE_SUCCEEDED = "CATEGORY_SAVE_SUCCEEDED"
export const CATEGORY_SAVE_FAILED = "CATEGORY_SAVE_FAILED"
export const CATEGORY_UPDATE_REQUESTED = "CATEGORY_UPDATE_REQUESTED"
export const CATEGORY_UPDATE_SUCCEEDED = "CATEGORY_UPDATE_SUCCEEDED"
export const CATEGORY_UPDATE_FAILED = "CATEGORY_UPDATE_FAILED"
export const CATEGORY_DELETE_REQUESTED = "CATEGORY_DELETE_REQUESTED"
export const CATEGORY_DELETE_SUCCEEDED = "CATEGORY_DELETE_SUCCEEDED"
export const CATEGORY_DELETE_FAILED = "CATEGORY_DELETE_FAILED"
export const COMPONENTS_LIST_REQUESTED = "COMPONENTS_LIST_REQUESTED"
export const COMPONENTS_LIST_SUCCEEDED = "COMPONENTS_LIST_SUCCEEDED"
export const COMPONENTS_LIST_FAILED = "COMPONENTS_LIST_FAILED"
export const ADD_ATOM_PAGE_REQUESTED = "ADD_ATOM_PAGE_REQUESTED"
export const ADD_ATOM_PAGE_SUCCEEDED = "ADD_ATOM_PAGE_SUCCEEDED"
export const ADD_ATOM_PAGE_FAILED = "ADD_ATOM_PAGE_FAILED"

// Media Library
export const MEDIA_LIBRARY_LIST_REQUESTED = "MEDIA_LIBRARY_LIST_REQUESTED"
export const MEDIA_LIBRARY_LIST_SUCCEEDED = "MEDIA_LIBRARY_LIST_SUCCEEDED"
export const MEDIA_LIBRARY_LIST_FAILED = "MEDIA_LIBRARY_LIST_FAILED"
export const MEDIA_LIBRARY_SAVE_REQUESTED = "MEDIA_LIBRARY_SAVE_REQUESTED"
export const MEDIA_LIBRARY_SAVE_SUCCEEDED = "MEDIA_LIBRARY_SAVE_SUCCEEDED"
export const MEDIA_LIBRARY_SAVE_FAILED = "MEDIA_LIBRARY_SAVE_FAILED"
export const MEDIA_LIBRARY_DELETE_REQUESTED = "MEDIA_LIBRARY_DELETE_REQUESTED"
export const MEDIA_LIBRARY_DELETE_SUCCEEDED = "MEDIA_LIBRARY_DELETE_SUCCEEDED"
export const MEDIA_LIBRARY_DELETE_FAILED = "MEDIA_LIBRARY_DELETE_FAILED"

// Strategy
export const STRATEGY_GET_REQUESTED = "STRATEGY_GET_REQUESTED"
export const STRATEGY_GET_SUCCEEDED = "STRATEGY_GET_SUCCEEDED"
export const STRATEGY_GET_FAILED = "STRATEGY_GET_FAILED"
export const STRATEGY_POST_REQUESTED = "STRATEGY_POST_REQUESTED"
export const STRATEGY_POST_SUCCEEDED = "STRATEGY_POST_SUCCEEDED"
export const STRATEGY_POST_FAILED = "STRATEGY_POST_FAILED"

// Strategy Design
export const DESIGN_FIELDS_VALUE_REQUESTED = "DESIGN_FIELDS_VALUE_REQUESTED"
export const DESIGN_FIELDS_VALUE_SUCCEEDED = "DESIGN_FIELDS_VALUE_SUCCEEDED"
export const DESIGN_FIELDS_VALUE_FAILED = "DESIGN_FIELDS_VALUE_FAILED"
export const DESIGN_FIELDS_VALUE_SAVE_REQUESTED = "DESIGN_FIELDS_VALUE_SAVE_REQUESTED"
export const DESIGN_FIELDS_VALUE_SAVE_SUCCEEDED = "DESIGN_FIELDS_VALUE_SAVE_SUCCEEDED"
export const DESIGN_FIELDS_VALUE_SAVE_FAILED = "DESIGN_FIELDS_VALUE_SAVE_FAILED"
export const GET_TEMPLATES_REQUESTED = "GET_TEMPLATES_REQUESTED"
export const GET_TEMPLATES_SUCCEEDED = "GET_TEMPLATES_SUCCEEDED"
export const GET_TEMPLATES_FAILED = "GET_TEMPLATES_FAILED"
export const DELETE_STRATEGY_REQUESTED = "DELETE_STRATEGY_REQUESTED"
export const DELETE_STRATEGY_SUCCEEDED = "DELETE_STRATEGY_SUCCEEDED"
export const DELETE_STRATEGY_FAILED = "DELETE_STRATEGY_FAILED"

// FooterUptade
export const FOOTER_UPTADE_REQUESTED = "FOOTER_UPTADE_REQUESTED"
export const FOOTER_UPTADE_SUCCEEDED = "FOOTER_UPTADE_SUCCEEDED"
export const FOOTER_UPTADE_FAILED = "FOOTER_UPTADE_FAILED"

// MilesAndSmilesList
export const MILES_AND_SMILES_REQUESTED = "MILES_AND_SMILES_REQUESTED"
export const MILES_AND_SMILES_SUCCEEDED = "MILES_AND_SMILES_SUCCEEDED"
export const MILES_AND_SMILES_FAILED = "MILES_AND_SMILES_FAILED"

// SaveUserSettings
export const SAVE_USER_SETTINGS_REQUESTED = "SAVE_USER_SETTINGS_REQUESTED"
export const SAVE_USER_SETTINGS_SUCCEEDED = "SAVE_USER_SETTINGS_SUCCEEDED"
export const SAVE_USER_SETTINGS_FAILED = "SAVE_USER_SETTINGS_FAILED"

// GetSettings
export const GET_SETTINGS_REQUESTED = "GET_SETTINGS_REQUESTED"
export const GET_SETTINGS_SUCCEEDED = "GET_SETTINGS_SUCCEEDED"
export const GET_SETTINGS_FAILED = "GET_SETTINGS_FAILED"

// GetProperties
export const GET_PROPERTIES_REQUESTED = "GET_PROPERTIES_REQUESTED"
export const GET_PROPERTIES_SUCCEEDED = "GET_PROPERTIES_SUCCEEDED"
export const GET_PROPERTIES_FAILED = "GET_PROPERTIES_FAILED"

// GetDefinePropertyType
export const GET_DEFINE_PROPERTY_TYPE_REQUESTED = "GET_DEFINE_PROPERTY_TYPE_REQUESTED"
export const GET_DEFINE_PROPERTY_TYPE_SUCCEEDED = "GET_DEFINE_PROPERTY_TYPE_SUCCEEDED"
export const GET_DEFINE_PROPERTY_TYPE_FAILED = "GET_PROPERTIES_FAILED"
// GetAgreement
export const GET_AGREEMENT_REQUESTED = "GET_AGREEMENT_REQUESTED"
export const GET_AGREEMENT_SUCCEEDED = "GET_AGREEMENT_SUCCEEDED"
export const GET_AGREEMENT_FAILED = "GET_AGREEMENT_FAILED"

// SaveAgreement
export const SAVE_AGREEMENT_REQUESTED = "SAVE_AGREEMENT_REQUESTED"
export const SAVE_AGREEMENT_SUCCEEDED = "SAVE_AGREEMENT_SUCCEEDED"
export const SAVE_AGREEMENT_FAILED = "SAVE_AGREEMENT_FAILED"

// AccountList
export const GET_ALL_ACCOUNT_LIST_REQUESTED = "GET_ALL_ACCOUNT_LIST_REQUESTED"
export const GET_ALL_ACCOUNT_LIST_SUCCEEDED = "GET_ALL_ACCOUNT_LIST_SUCCEEDED"
export const GET_ALL_ACCOUNT_LIST_FAILED = "GET_ALL_ACCOUNT_LIST_FAILED"

// Departmant List
export const GET_DEPARTMANTS_REQUESTED = "GET_DEPARTMANTS_REQUESTED"
export const GET_DEPARTMANTS_SUCCEEDED = "GET_DEPARTMANTS_SUCCEEDED"
export const GET_DEPARTMANTS_FAILED = "GET_DEPARTMANTS_FAILED"

// Role List
export const GET_ROLES_REQUESTED = "GET_ROLES_REQUESTED"
export const GET_ROLES_SUCCEEDED = "GET_ROLES_SUCCEEDED"
export const GET_ROLES_FAILED = "GET_ROLES_FAILED"

// User Register
export const CLIENT_USER_REGISTER_REQUESTED = "CLIENT_USER_REGISTER_REQUESTED"
export const CLIENT_USER_REGISTER_SUCCEEDED = "CLIENT_USER_REGISTER_SUCCEEDED"
export const CLIENT_USER_REGISTER_FAILED_WITH_ERROR_MESSAGE = "CLIENT_USER_REGISTER_FAILED_WITH_ERROR_MESSAGE"
export const CLIENT_USER_REGISTER_FAILED = "CLIENT_USER_REGISTER_FAILED"

// User Update
export const CLIENT_USER_UPDATE_REQUESTED = "CLIENT_USER_UPDATE_REQUESTED"
export const CLIENT_USER_UPDATE_SUCCEEDED = "CLIENT_USER_UPDATE_SUCCEEDED"
export const CLIENT_USER_UPDATE_FAILED = "CLIENT_USER_UPDATE_FAILED"

// User Update
export const CLIENT_USER_GET_REQUESTED = "CLIENT_USER_GET_REQUESTED"
export const CLIENT_USER_GET_SUCCEEDED = "CLIENT_USER_GET_SUCCEEDED"
export const CLIENT_USER_GET_FAILED = "CLIENT_USER_GET_FAILED"

// Country Codes
export const GET_COUNTRY_CODES_REQUESTED = "GET_COUNTRY_CODES_REQUESTED"
export const GET_COUNTRY_CODES_SUCCEEDED = "GET_COUNTRY_CODES_SUCCEEDED"
export const GET_COUNTRY_CODES_FAILED = "GET_COUNTRY_CODES_FAILED"

// Page
export const PAGE_SAVE_REQUESTED = "PAGE_SAVE_REQUESTED"
export const PAGE_SAVE_SUCCEEDED = "PAGE_SAVE_SUCCEEDED"
export const PAGE_SAVE_FAILED = "PAGE_SAVE_FAILED"
export const PAGE_UPDATE_REQUESTED = "PAGE_UPDATE_REQUESTED"
export const PAGE_UPDATE_SUCCEEDED = "PAGE_UPDATE_SUCCEEDED"
export const PAGE_UPDATE_FAILED = "PAGE_UPDATE_FAILED"
export const PAGE_DELETE_REQUESTED = "PAGE_DELETE_REQUESTED"
export const PAGE_DELETE_SUCCEEDED = "PAGE_DELETE_SUCCEEDED"
export const PAGE_DELETE_FAILED = "PAGE_DELETE_FAILED"
export const PAGE_STATE_REQUESTED = "PAGE_STATE_REQUESTED"
export const PAGE_STATE_SUCCEEDED = "PAGE_STATE_SUCCEEDED"
export const PAGE_STATE_FAILED = "PAGE_STATE_FAILED"
export const PAGE_CLONE_REQUESTED = "PAGE_CLONE_REQUESTED"
export const PAGE_CLONE_SUCCEEDED = "PAGE_CLONE_SUCCEEDED"
export const PAGE_CLONE_FAILED = "PAGE_CLONE_FAILED"
export const PAGE_SAVE_MAIN_REQUESTED = "PAGE_SAVE_MAIN_REQUESTED"
export const PAGE_SAVE_MAIN_SUCCEEDED = "PAGE_SAVE_MAIN_SUCCEEDED"
export const PAGE_SAVE_MAIN_FAILED = "PAGE_SAVE_MAIN_FAILED"


// #endregion
