import { useQuery } from "@tanstack/react-query"
import { API_GET } from "helpers/api"

export const useDomainAddonSettingList = (addonId, enabled) => {
  return useQuery({
    queryKey: ["clientDomainAddonSettingList", addonId],
    queryFn: () => API_GET(`${process.env.REACT_APP_DISPLAY_DOMAIN}/api/domainaddonsetting/list/addon/${addonId}`),
    enabled: enabled,
  })
}
