import SocialMediaBox from "./components/SocialMediaBox"
import React from "react"

const SocialMediaContent = ({ setSelectedContent, getSettingsData }) => {
  const activeItems = getSettingsData?.Result?.filter(
    (item) => item.PropertyName === "Active" && item.PropertyValue === "True",
  ).map((item) => item.SettingName)

  const socialMediaList = getSettingsData?.Result?.filter(
    (item) => item.PropertyName === "Address" && activeItems.includes(item.SettingName),
  ).map((item) => ({
    icon: item.SettingName.toLowerCase(),
    title: item.SettingName,
    link: item.PropertyValue,
  }))

  return (
    <div className="bg-white border border-gray-200 rounded-lg p-8 mt-6 flex flex-col gap-4">
      <div className="flex items-center justify-between">
        <h3 className="text-gray-800 text-lg font-semibold">Sosyal Medya Bilgileri</h3>
        <button
          className="text-blue-500 font-medium"
          onClick={() => setSelectedContent("Sosyal Medya")}
        >
          {socialMediaList?.length > 0 ? "Düzenle" : "Sosyal Medya Bilgilerini Ekle"}
        </button>
      </div>
      {socialMediaList?.length > 0 ? (
        <div className="grid grid-cols-2 gap-4">
          {socialMediaList?.map((item, index) => (
            <SocialMediaBox
              key={index}
              icon={item.icon}
              title={item.title}
              link={item.link}
            />
          ))}
        </div>
      ) : (
        <p className="text-gray-500 mt-2">Satış kanalınızın footer alanında görüntülenecek sosyal medya ikonlarını düzenleyin.</p>
      )}
    </div>
  )
}

export default SocialMediaContent
