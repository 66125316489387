import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import MainHeader from "components/Headers/MainHeader"
import MyReservationSkeleton from "skeletons/MyReservation"


// table
import DataGrid, {
	Column,
	SearchPanel,
	Pager,
	Paging,
	Sorting,
	Item,
	Toolbar,
} from "devextreme-react/data-grid"
import "devextreme/dist/css/dx.light.css"
import { locale, loadMessages } from "devextreme/localization"
import trMessages from "localization/TR/devextreme.json"

// Requests
import {getCustomDomainsNearExpirationRequested} from "redux/actions/getCustomDomainsNearExpiration";
import {LoadPanel} from "devextreme-react/load-panel";
import {intervalToDuration} from "date-fns";
import tippy, {createSingleton} from "tippy.js";
import {Icon, LoadBar, Modal} from "components";
import {refreshCustomDomainValidationTokenRequested} from "redux/actions/refreshCustomDomainValidationToken";

// table localization
loadMessages(trMessages)
locale(navigator.language || navigator.browserLanguage)

const DomainDNS = (props) => {
	let { isLogged } = props

	const dispatch = useDispatch()

	const filterListEl = useRef(null)
	const filterRightAreaRef = useRef(null)
	const dataGridRef = useRef(null)

	const customDomainsExpirationList = useSelector((state) => state.getCustomDomainsNearExpiration?.list)
	const customDomainsExpirationLoading = useSelector((state) => state.getCustomDomainsNearExpiration.loading)
	const refreshCustomDomainValidationToken = useSelector((state) => state.refreshCustomDomainValidationToken.response)
	const refreshCustomDomainValidationTokenLoading = useSelector((state) => state.refreshCustomDomainValidationToken.loading)
	const refreshCustomDomainValidationTokenError = useSelector((state) => state.refreshCustomDomainValidationToken.error)

	const [ActiveFilter, setActiveFilter] = useState({
		filterStatus: [],
	})
	const [TableLoaded, setTableLoaded] = useState(false)
	const [FilterButtonPosition, setFilterButtonPosition] = useState({ display: "none" })
	/*const [FiltersMoverStyle, setFiltersMoverStyle] = useState({
		left: 0,
		width: 0,
	})*/
	/*const [HeaderFilterList] = useState([
		{ text: "Tümü", filterStatus: [] },
		{ text: "Aktif", filterStatus: [1, 4, 7, 10, 15], textColor: "text-warning-400", bgColor: "bg-warning-50"},
		{ text: "Pasif", filterStatus: [3, 6, 8, 9, 11, 18], textColor: "text-error-500", bgColor: "bg-error-50"},
	])*/

	const [TableStates, setTableStates] = useState({
		cellAlignments: "center",
		dataGrid: {
			id: "gridContainer",
			allowColumnReordering: true,
			allowColumnResizing: true,
			columnAutoWidth: true,
			showBorders: true,
			showScrollbar: "always",
			keyExpr: "keyExpr",
			//columnHidingEnabled: true,
			dataSource: [],
			noDataText: "Domain Listesi bulunamadı.",
			onContentReady: (e) => {
				setTableLoaded(true)
				getTippyForInfo()
			},
		},
		paging: {
			defaultPageSize: 10,
		},
		pager: {
			visible: true,
			allowedPageSizes: [10, 20, 50],
			displayMode: "full",
			showPageSizeSelector: true,
			showInfo: true,
			showNavigationButtons: true,
		},
	})
	const [CantRefreshCustomDomainList] = useState(["jollytur"])
	const [ChoosedDomain, setChoosedDomain] = useState("")

	const getTippyForInfo = () => {
		setTimeout(() => {
			createSingleton(tippy("[data-tippy-content]"), {
				delay: [300, 100],
				allowHTML: true,
				placement: "top", // default
				// The props in the current `tippyInstance` will override the ones above
				moveTransition: "transform 0.2s ease-out",
			})
		}, 100)
	}

	const resize = () => {
		let paddingFromContent = 40 // "p-10" from section content
		let table = document.getElementById(TableStates.dataGrid.id)
		let tableRect = table?.getBoundingClientRect()
		let rightPosition = tableRect?.right
		let right = (window.innerWidth - rightPosition + paddingFromContent).toFixed(0) + "px"
		setFilterButtonPosition({
			...FilterButtonPosition,
			right,
			display: "block",
		})
	}

	const getCustomDomainExpiration = () => {
		dispatch(getCustomDomainsNearExpirationRequested())
	}

	const refreshCustomDomainToken = (tenantDomain) => {
		if (tenantDomain){
			setChoosedDomain(tenantDomain)
			dispatch(refreshCustomDomainValidationTokenRequested(tenantDomain))
		}
	}

	/*function arrayEquals(a, b) {
		return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => val === b[index])
	}*/


	useEffect(() => {
		getCustomDomainExpiration()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (ActiveFilter !== undefined) {
			let filteredDataSource =
				ActiveFilter?.filterStatus?.length === 0
					? customDomainsExpirationList
					: customDomainsExpirationList.filter((row) => ActiveFilter.filterStatus.includes(row.confirmationStatus))

			setTableStates({
				...TableStates,
				dataGrid: {
					...TableStates.dataGrid,
					dataSource: filteredDataSource,
				},
			})

			/*if (filterListEl.current) {
				let childs = Object.values(filterListEl.current?.children)
				childs.map((el) => {
					let getBoundingClientRect = el.getBoundingClientRect()
					if (el.classList.value.includes("text-blue-500")) {
						setFiltersMoverStyle({
							width: getBoundingClientRect.width,
							height: getBoundingClientRect.height,
							left: el.offsetLeft,
							top: el.offsetTop,
						})
					}
					return true
				})
			}*/
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterListEl, ActiveFilter, customDomainsExpirationList])

	useEffect(() => {
		resize()
		window.addEventListener("resize", resize)
		return () => window.removeEventListener("resize", resize)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterRightAreaRef, TableLoaded])

	useEffect(() => {
		if ((refreshCustomDomainValidationToken && !refreshCustomDomainValidationToken?.success) || refreshCustomDomainValidationToken?.success || refreshCustomDomainValidationToken?.error){
			setChoosedDomain("")
		}
		if (refreshCustomDomainValidationToken?.success){
			console.log("basarili oldu tekrar gidiyorum")
			getCustomDomainExpiration("gittim")
		}
	}, [refreshCustomDomainValidationToken, refreshCustomDomainValidationTokenError])

	useEffect(() => {
		if (customDomainsExpirationList?.length) {
			setTableStates({
				...TableStates,
				dataGrid: {
					...TableStates.dataGrid,
					dataSource: customDomainsExpirationList,
				},
			})
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [customDomainsExpirationList])


	return (
		<>
			<MainHeader isLogged={isLogged} />
			<main>
				{customDomainsExpirationLoading && <MyReservationSkeleton />}
				{!customDomainsExpirationLoading && (
					<section className="p-10 my-0 mx-auto max-w-[1440px]">
						<div className="page-info flex items-center flex-wrap">
							<div className="page-info-text mr-4">
								<p className="text-gray-800 font-semibold text-3xl">Domain ve DNS Ayarları</p>
								<p className="mt-1 font-base text-gray-500">
									Domain listesinden DNS ayarlarını kontrol edin ve güncelleyin.
								</p>
							</div>
						</div>

						{refreshCustomDomainValidationToken?.success && !refreshCustomDomainValidationTokenLoading && (
							<div className="bg-success-200 p-4 flex flex-col gap-0.5 rounded-lg text-sm text-success-900 my-6">
								<div className="flex flex-wrap items-center gap-1.5">
									<Icon name="info" size={18} color={`#054f31`} />
									<p className="font-semibold">Seçilen domain için Txt değerleri e-posta adresinize gönderildi.</p>
								</div>
								<p>DNS adresini güncellemek için mail adresinize gönderilen yeni Txt değerlerini kullanın.</p>
							</div>
						)}

						{((refreshCustomDomainValidationToken && !refreshCustomDomainValidationToken?.success) || refreshCustomDomainValidationTokenError) && (
							<div className="bg-error-200 p-4 flex flex-col gap-0.5 rounded-lg text-sm text-error-900 my-6">
								<div className="flex flex-wrap items-center gap-1.5">
									<Icon name="info" size={18} color={`#7a271a`} />
									<p className="font-semibold">E-posta gönderilirken bir sorun oluştu.</p>
								</div>
								<p>{refreshCustomDomainValidationToken?.message || "Lütfen Txt oluşturma işlemini tekrar deneyin."}</p>
							</div>
						)}

						{customDomainsExpirationList && (
							<>
								{/*<div className="filter-wrapper border-b border-gray-200">
									<ul
										ref={filterListEl}
										className="relative flex flex-wrap flex-col items-start xl:flex-row text-gray-500 font-medium text-sm xl:pt-6 pt-4 select-none"
									>
										{!customDomainsExpirationLoading &&
											HeaderFilterList.map((v, k) => {
												return (
													<li
														key={k}
														className={`transition delay-200 px-3 py-1 xl:py-4 cursor-pointer ${
															arrayEquals(v.filterStatus, ActiveFilter.filterStatus) ? "text-blue-500" : ""
														}`}
														onClick={() =>
															setActiveFilter({
																filterStatus: v.filterStatus,
																textColor: v.textColor,
															})
														}
													>
														{v.text}
													</li>
												)
											})}
										<span
											style={{ ...FiltersMoverStyle }}
											className="mover pointer-events-none transition-all duration-300 absolute bottom-0 left-0 w-[24px] border-b border-blue-500"
										/>
									</ul>
								</div>*/}
								<LoadPanel
									position={{ of: `#${TableStates.dataGrid.id}` }}
									shadingColor="rgba(0,0,0,0.4)"
									loadPanelVisible={true}
									showIndicator={true}
									shading={true}
									showPane={true}
									hideOnOutsideClick={true}
									visible={!customDomainsExpirationList && !customDomainsExpirationList.length}
									text={"Yükleniyor..."}
									//onHidden={() => false}
								/>
								<DataGrid
									ref={dataGridRef}
									className={"!max-w-full mt-6"}
									{...TableStates.dataGrid}
								>
									{/*TABLE OPTIONS*/}
									<Sorting mode="multiple" />
									<SearchPanel
										placeholder={"Domain adı arayın"}
										visible={true}
										width="496px"
									/>
									<Paging {...TableStates.paging} />
									<Pager {...TableStates.pager} />
									<Toolbar>
										<Item
											location="before"
											name="searchPanel"
										/>
									</Toolbar>

									{/*TABLE OPTIONS*/}
									{/*TABLE BODY*/}

									<Column
										caption="Domain Adı"
										cellRender={(row) => (row.text ? row.text : "-")}
										dataField="domainUrl"
										//width={TableStates.rowWidth}
									/>
									{/*<Column
										caption="Statü"
										dataField="confirmationStatus"
										//sortOrder={"asc"}
										cellRender={(row) => {
											return (
												<span
													className={`inline-flex gap-1 items-center ${
														row.value === 4
															? "bg-success-50 text-success-500"
															: row.value === 13
																? "bg-gray-100 text-gray-500"
																: "bg-blue-50 text-blue-500"
													} rounded-xl py-0.5 px-2`}
												>
                            <span>{row.value === 4 ? "Aktif" : row.value === 13 ? "Pasif" : "İşlemde"}</span>
                          </span>
											)
										}}
										allowFiltering={true}
										allowHeaderFiltering={false}
										alignment={"left"}
									/>*/}
									<Column
										caption="Txt Son Kullanma Tarihi"
										dataType="date"
										sortOrder="asc"
										cellRender={(row) => {
											if (row.data?.expirationDate){
												const remainingTime = intervalToDuration({
													start: new Date(),
													end: new Date(row.data.expirationDate)
												})
												if (!remainingTime?.months && (remainingTime?.days <= 6 || !remainingTime?.days || !remainingTime?.hours || !remainingTime?.minutes || !remainingTime?.seconds)){
													const isPastTime = Math.sign(remainingTime?.seconds) === -1
													return <div className="flex flex-col gap-0.5">
														<span className={`${!isPastTime ? "text-warning-600" : "text-red-600"}`}>{row.text}</span>
														<span className={`${!isPastTime ? "text-warning-500" : "text-red-500"}`}>Son kullanma tarihi {!isPastTime ? "yaklaşıyor" : "geçti"}!</span>
													</div>
												}
											}
											return row.text ? row.text : "-"

										}}
										dataField="expirationDate"
									/>
									<Column
										caption="İşlemler"
										cellRender={(row) => {
											const mainDomainUrl = row.data?.domainUrl.split(".")[0]
											const subDomainUrl = row.data?.domainUrl.split(".")[1]
											if (CantRefreshCustomDomainList.includes(subDomainUrl) || CantRefreshCustomDomainList.includes(mainDomainUrl)) return (
												<div className="flex items-center justify-center" data-tippy-content={`<div><code class="p-0.5 bg-warning-500 font-bold">${mainDomainUrl}</code> adresli DNS adresinizi güncellemek için lütfen DNS sağlayıcınıza başvurun</div>`}>
													<span className="mr-1">Uygun Değil</span>
													<Icon name="info" color={"#d78c2a"} size={16} />
												</div>
											)
											return (<button
												disabled={refreshCustomDomainValidationTokenLoading}
												onClick={() => refreshCustomDomainToken(row.data?.domainUrl)}
												className="btn xs outline">
												{row.data?.domainUrl === ChoosedDomain && <LoadBar />}
												{row.data?.domainUrl !== ChoosedDomain && <span>Txt oluştur</span>}

											</button> )

										}}
										dataField=""
										alignment={TableStates.cellAlignments}
									/>
								</DataGrid>
							</>
						)}
						{!customDomainsExpirationList && (
							<div className="welcome-wrapper flex flex-col lg:flex-row lg:items-center justify-between p-10 bg-white border border-gray-200 rounded-lg mt-6">
								<div className="text-box lg:w-5/12">
									<p className="text-lg text-gray-800 font-semibold">Henüz hiç domain yok!</p>
									<p className="text-lg mt-2 text-gray-500 font-normal">
										Domain listesini görmek için önce entegrasyonu tamamlamalısın!
										<br />
										<br />
										İlk domain oluştuğunda bu alandan görüntüleyebilirsin.
									</p>
								</div>
							</div>
						)}


						<Modal
							open={refreshCustomDomainValidationTokenLoading}
							width={600}
							closeButton={false}
						>
							<div className="login-box bg-white">
								<div className="flex flex-col justify-center items-center">
									<div className="w-24">
										<LoadBar className="w-24" pathFill={"#2E90FA"} />
									</div>
									<p className="text-lg font-medium my-6">E-posta gönderiliyor...</p>
								</div>
							</div>
						</Modal>

					</section>
				)}
			</main>
		</>
	)
}

export default DomainDNS
