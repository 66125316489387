import React from "react"
import { Modal, Button } from "rsuite"
import { ReactComponent as InfoError } from "assets/svg/info-error.svg"
import { useSettingsLinks } from "pages/Settings"
import {useDispatch} from "react-redux";
import {clientUserUpdateRequested} from "../../../redux/actions/clientUserUpdate";

/**
 * @typedef {"makePassive" | "default"} ModalType
 *
 * @param {*} data User object
 * @param {boolean} open Visibility of the modal
 * @param {ModalType} type default or makePassive
 * @param {() => void} onSuccess on success callback
 * @param {() => void} onFailed on Failed callback
 * @param {() => void} onClose on close callback
 * @returns
 */

const UserDeleteModal = (props) => {
  const dispatch = useDispatch()
  const handleOnSetStatus = () => {

    let formData = [props.data].reduce((acc, value) => {
      const departments = []
      departments.push(...value.departments.map(dep => dep.departmentId *1))
      acc.push(
          {
            userid: value.userId,
            name: value.name,
            surname: value.surname,
            DepartmentIds: departments,
            RoleId: value.role[0].roleId* 1,
            status: value.changeStatusVal,
            Email :value.email,
            phoneNumber :value.phoneNumber,
          })
      return acc
    }, [])
    dispatch(clientUserUpdateRequested(...formData))

    //props.onSuccess()
  }

  return (
    <>
      <Modal
        open={props.open}
        onClose={props.onClose}
        className="w-[382px] !mt-0 absolute top-1/2 left-1/2 transform !-translate-x-1/2 !-translate-y-1/2"
      >
        <Modal.Body className="flex justify-center items-center flex-col text-center gap-3 mb-3">
          <span>
            <InfoError className="w-12 h-12 text-gray-500" />
          </span>
          <p className="font-medium text-lg">{props?.type === "makePassive" ? "Pasife Al" : "Kullanıcıyı Sil"}</p>
          {props?.type === "makePassive" ? (
            <p className="text-gray-500 text-sm">Seçtiğiniz kullanıcı artık sisteme giriş yapamayacak.</p>
          ) : (
            <p className="text-gray-500 text-sm">
              Bu işlemi geri alamazsınız.
            </p>
          )}
        </Modal.Body>
        <Modal.Footer className="flex">
          <Button
            onClick={props.handleClose}
            className="btn w-full bg-white text-gray-600 border border-solid border-gray-300 mr-2 hover:!text-white hover:!bg-gray-600 hover:!border-gray-600"
          >
            Vazgeç
          </Button>
          <Button
            onClick={() => handleOnSetStatus(props?.type === "makePassive" ? 0 : 1)}
            className="btn w-full bg-red-600 text-white border border-solid border-red-600 hover:!text-white hover:!bg-red-700 hover:!border-red-700"
          >
            {props?.type === "makePassive" ? "Pasife Al" : "Sil"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

const useUserDeleteModal = () => {
  const [open, setOpen] = React.useState(false)
  const [type, setType] = React.useState(null)
  const [data, setData] = React.useState(null)
  const { links } = useSettingsLinks("userManagement")
  /**
   * @param {*} data
   * @param {ModalType} type
   */
  const handleOpen = (data, type) => {
    setData({ ...data })
    setOpen(true)
    setType(type || null)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleOnSuccess = () => {
    setOpen(false)
    window.location.replace(links.link)
  }

  return {
    open,
    data,
    type,
    handleOpen,
    handleClose,
    onSuccess: handleOnSuccess,
  }
}

export { useUserDeleteModal }
export default UserDeleteModal
