import React, {useEffect, useRef, useState} from "react"
import {useDispatch, useSelector} from "react-redux";
import moment from "moment/moment";

//images
import stack from "pages/B2cDemoPage/LivePreview/img/stack.png";

// components
import {ComponentNotFound, Icon} from "components"
import AddBlock from "components/PageManager/AddBlock";
import {array_move} from "helpers/ArrayMove";
import getInfoFromChildren from "utils/getInfoFromChildren"
import {
  AgencyCarRentalTravelPlanner,
  Carousel,
  CorporateCarRentalTravelPlanner,
  FlightTravelPlanner,
  Header,
  HotelTravelPlanner,
  IconList as GordyIconList,
  LayoutContainer,
  MediaList as GordyMediaList,
  TourTravelPlanner,
  TransferTravelPlanner,
  /*TextHtml as GordyTextHtml,*/
} from "gordy-ui-lib"
import {
  ATOM_PAGE,
  ICON_LIST,
  MEDIA_LIST,
  TAB_SLIDER,
  TEXT_HTML,
  TRAVEL_PLANNER_CAR_RENTAL_AGENCY,
  TRAVEL_PLANNER_CAR_RENTAL_CORPORATE,
  TRAVEL_PLANNER_FLIGHT,
  TRAVEL_PLANNER_HOTEL,
  TRAVEL_PLANNER_TOUR,
  TRAVEL_PLANNER_TRANSFER_AGENCY,
  TRAVEL_PLANNER_TRANSFER_CORPORATE,
} from "../../Enums";

//dummies
import {CarouselDummy} from "../../dummyData/carousel";
import {IconListDummy} from "../../dummyData/iconList";
import {MediaListDummy} from "../../dummyData/mediaList";
import {travelPlannerData} from "../../dummyData/hoteltravelplanner";
import {tourTravelPlannerData, tourTravelPlannerTabButtons} from "../../dummyData/tourtravelplanner";
import {transferTravelPlannerData, transferTravelPlannerTabButtons} from "../../dummyData/transfertravelplanner";
import {flightTravelPlannerData, flightTravelPlannerTabButtons} from "../../dummyData/flighttravelplanner";
import {CarRentalTravelPlannerData} from "../../dummyData/carrentaltravelplanner";
import {TextHtmlDummy} from "../../dummyData/texthtml";

// saga-requests
import {clientConfigGetRequested} from "redux/actions/clientConfigurationGet";
import {getStrategySucceed} from "redux/actions/strategy";
import {StrategyLoadingSkeleton} from "skeletons/PageManager";

const PreviewPage = (props) => {
  let {
    strategies,
    EditLayouts,
    setEditLayouts,
    //EditBlock,
    setEditBlock,
    setBlockListTab,
    ActiveCategory,
    setActiveCategory,
    AddNewBlockHandleClick,
    setAddNewBlockHandleClick,
    visualValues,
    activeMenuTab,
    setActiveMenuTab,
    layoutPreview,
  } = props


  const dispatch = useDispatch()

  //refs
  const lpBody = useRef()

  //selectors
  const clientConfigurationGet = useSelector((state) => state.clientConfigurationGet?.data)
  const components = useSelector(state => state.strategy.getStrategy.list?.result)
  const allCategories = useSelector(state => state.pageManager.categoryList.list)
  const getConfigurationLoading = useSelector((state) => state.GetConfiguration.loading)

  //states
  const [PageComponentList, setPageComponentList] = useState([])
  const [ShowOverlay, setShowOverlay] = useState(false)
  const [ActiveSlide, setActiveSlide] = useState(null)
  const [EditComponent, setEditComponent] = useState(false)


  const gordyComponents = {
    [TAB_SLIDER]:(strategy) => {
      CarouselDummy.children[0].data.category.categoryName = strategy?.mock?.category?.name
      /*console.log(strategy);*/
      return <Carousel
        onSlideChange={(e) => setActiveSlide({...e})}
        strategy={strategy?.children || strategy?.data || strategy?.visual ? strategy : CarouselDummy}
        design={visualValues} />
    },
    [ICON_LIST]:(strategy) => {
      /*console.log(strategy);*/
      return <GordyIconList strategy={strategy?.children || strategy?.visual ? strategy : IconListDummy} design={visualValues} />
    },
    [MEDIA_LIST]:(strategy) => {
      return <GordyMediaList strategy={strategy?.children || strategy?.data || strategy?.visual ? strategy : MediaListDummy} design={visualValues} />
    },
    [TRAVEL_PLANNER_HOTEL]:(strategy) => {
      return <HotelTravelPlanner strategy={strategy?.data || strategy?.visual ? strategy : travelPlannerData} design={visualValues}
          //disabled={true}
      />
    },
    [TRAVEL_PLANNER_TOUR]:(strategy) => {
      if (!strategy?.data?.button){
        strategy = {
          ...strategy,
          data: {
            ...strategy?.data,
            button: "Ara"
          }
        }
      }

      return <TourTravelPlanner
          //disabled={true}
          strategy={strategy?.data || strategy?.visual ? strategy : tourTravelPlannerData}
          tabButtons={tourTravelPlannerTabButtons}
          design={visualValues}
      />
    },
    [TRAVEL_PLANNER_TRANSFER_AGENCY]:(strategy) => {
      if (!strategy?.data?.button){
        strategy = {
          ...strategy,
          data: {
            ...strategy?.data,
            button: "Ara"
          }
        }
      }

      return <TransferTravelPlanner
          //disabled={true}
          strategy={strategy?.data || strategy?.visual ? strategy : transferTravelPlannerData}
          tabButtons={transferTravelPlannerTabButtons}
          noReason={false}
          tourType="return"
          tpType="Agency"
          design={visualValues}
      />
    },
    [TRAVEL_PLANNER_TRANSFER_CORPORATE]:(strategy) => {
      if (!strategy?.data?.button){
        strategy = {
          ...strategy,
          data: {
            ...strategy?.data,
            button: "Ara"
          }
        }
      }

      return <TransferTravelPlanner
          //disabled={true}
          strategy={strategy?.data || strategy?.visual ? strategy : transferTravelPlannerData}
          tabButtons={transferTravelPlannerTabButtons}
          noReason={false}
          tourType="return"
          tpType="Corporate"
          design={visualValues}
      />
    },
    [TRAVEL_PLANNER_FLIGHT]:(strategy) => {
      if (!strategy?.data?.button){
        strategy = {
          ...strategy,
          data: {
            ...strategy?.data,
            button: "Ara"
          }
        }
      }

      return <FlightTravelPlanner
          //disabled={true}
          strategy={strategy?.data || strategy?.visual ? strategy : flightTravelPlannerData}
          tabButtons={flightTravelPlannerTabButtons}
          noReason={false}
          tourType="return"
          tpType="Agency"
          design={visualValues}
      />
    },
    [TRAVEL_PLANNER_CAR_RENTAL_AGENCY]:(strategy) => {
      if (strategy?.data &&!strategy?.data?.button){
        strategy = {
          ...strategy,
          data: {
            ...strategy?.data,
            button: "Ara"
          }
        }
      }

      return <AgencyCarRentalTravelPlanner
          //disabled={true}
          strategy={strategy?.data || strategy?.visual ? strategy : CarRentalTravelPlannerData}
          tourType="return"
          design={visualValues}
      />
    },
    [TRAVEL_PLANNER_CAR_RENTAL_CORPORATE]:(strategy) => {
      if (strategy?.data && !strategy?.data?.button){
        strategy = {
          ...strategy,
          data: {
            ...strategy?.data,
            button: "Ara"
          }
        }
      }

      return <CorporateCarRentalTravelPlanner
          //disabled={true}
          strategy={strategy?.data || strategy?.visual ? strategy : CarRentalTravelPlannerData}
          tourType="return"
          design={visualValues}
      />
    },
    [TEXT_HTML]:(strategy) => {
      /*console.log(strategy);*/
      return (
        <div className="wrapper flex flex-col items-center justify-center mx-auto" style={{
          ...strategy?.visual?.style,
          ...{
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundImage: strategy.data?.backgroundImagePath ? `url("${strategy.data.backgroundImagePath}")` : undefined,
          },
          height: strategy?.visual?.style?.height === 0 ? undefined : strategy?.visual?.style?.height
        }}>
          <div className="container flex flex-col p-5">
            <h1
              className={`grd-text-slate-800 grd-font-bold grd-drop-shadow-lg dark:grd-text-white ${!strategy.data?.titleStyle?.fontSize ? "grd-text-5xl " : ""}`}
              style={strategy.data?.titleStyle}>{strategy.data?.title}</h1>
            <div className="min-h-12 mt-6" dangerouslySetInnerHTML={{__html: strategy?.data?.html ? strategy.data.html : TextHtmlDummy?.data?.html}}/>
          </div>
        </div>
      )
    },
  }

  const getEditLayoutCode = () => EditLayouts?.info?.code || EditLayouts?.code
  const componentMoveUp = (component, index) => {
    const isVirtualMove = EditLayouts
    const list = array_move(PageComponentList, index, index-1)
    const thisComponent = components?.children?.find(cmp => cmp?.code === component?.code)
    // component eklenmediyse
    if (!thisComponent && isVirtualMove) {
      setEditLayouts({
        ...EditLayouts,
        order: typeof EditLayouts?.order === "number" ? EditLayouts?.order - 1 : index - 1
      })
    } else {
      dispatch(getStrategySucceed({
        result: {
          ...components,
          children: list
        }
      }))
    }
  }
  const componentMoveDown = (component, index) => {
    const isVirtualMove = EditLayouts
    const list = array_move(PageComponentList, index, index+1)
    const thisComponent = components?.children?.find(cmp => cmp?.code === component?.code)
    // component eklenmediyse
    if (!thisComponent && isVirtualMove) {
      setEditLayouts({
        ...EditLayouts,
        order: typeof EditLayouts?.order === "number" ? EditLayouts?.order + 1 : index + 1
      })
    } else {
      dispatch(getStrategySucceed({
        result: {
          ...components,
          children: list
        }
      }))
    }
  }
  const componentRemove = (index) => {
    setEditLayouts(null)
    components?.children?.splice(index, 1);
    const list = components?.children
    dispatch(getStrategySucceed({
      result: {
        ...components,
        children: list
      }
    }))

  }

  const showTabSliderActiveCategory = (category) => {
    const thisSlider = EditLayouts?.info?.children?.find(slider => slider?.code === ActiveSlide?.data?.code)
    if (thisSlider){
      if (category?.categoryId !== ActiveCategory?.Id){
        setActiveCategory({
          Id: category?.categoryId,
          Name: category?.categoryName,
          Order: category?.order,
          Visible: category?.categoryVisible,
        })
      }
    }
  }

  const SettingsWrapper = ({ component, index, className, children }) => {
    const isEqualId = component?.code === getEditLayoutCode()

    return (
      <div
        id={`component-${index}`}
        className={`${className ? className : ""} group relative${isEqualId ? " z-20" : ""}`} /*${isOnHover && !selectedComponent ? " rounded-lg overflow-hidden" : ""}*/
      >
        <div
          className={`absolute opacity-0 -right-1 top-2 bg-blue-200 rounded-lg flex shadow-lg px-3 py-2 gap-2.5 items-center transition-all duration-300 z-30
          ${isEqualId ? "!opacity-100 !right-2" : ""} group-hover:opacity-100 group-hover:right-2`}
        >
          {!isEqualId && (
            <div
              onClick={(e) => {
                setActiveMenuTab(0)
                setEditLayouts(null)
                setEditBlock(null)
                setTimeout(() => {
                  setEditLayouts({
                    [component?.name]: true,
                    info: component,
                    rejectPreviewRender: true,
                  })
                  if(component?.name === TAB_SLIDER){
                    const activeCategory = document.querySelector(`#component-${index} .categories .grd-text-blue-500`)
                    const category = allCategories && allCategories?.find((cat) => cat?.Name === activeCategory?.innerHTML)
                    if (category) showTabSliderActiveCategory({categoryId: category?.Id, categoryName: category?.Name, order: category?.Order, categoryVisible: category?.Visible})
                    setEditComponent(true)
                  } else {
                    setEditComponent(false)
                  }
                }, 0)
              }}
              className="bg-white px-1.5 border border-gray-100 rounded-2xl cursor-pointer">
              <span className="block py-0.5 text-sm font-medium text-blue-500">Düzenle</span>
            </div>
          )}
          {isEqualId && (
            <div onClick={() => {
              setEditLayouts(null)
              setEditBlock(null)
              setEditComponent(false)
            }} className="bg-white px-3 border border-gray-100 rounded-2xl cursor-pointer">
              <span className="block py-0.5 text-sm font-medium text-blue-500">Kapat</span>
            </div>
          )}
          <div
            onClick={() => componentMoveUp(component, index)}
            className={`bg-white px-1.5 border border-gray-100 rounded-2xl cursor-pointer ${index <= 0 ? "opacity-50 pointer-events-none" : ""}`}>
            <Icon
              name={"arrow-up"}
              size={12}
              color={"#2E90FA"}
            />
          </div>
          <div
            onClick={() => componentMoveDown(component, index)}
            className={`bg-white px-1.5 border border-gray-100 rounded-2xl cursor-pointer ${PageComponentList.length === index + 1 ? "opacity-50 pointer-events-none" : ""}`}>
            <Icon
              name={"arrow-down"}
              size={12}
              color={"#2E90FA"}
            />
          </div>
          {/*<div className="bg-white px-1.5 border border-gray-100 rounded-2xl cursor-pointer">
            <Icon
              name={"copy"}
              size={12}
              color={"#2E90FA"}
            />
          </div>*/}
          {!component?.virtualBlock && (
            <div
              onClick={() => componentRemove(index)}
              className="bg-white px-1.5 border border-gray-100 rounded-2xl cursor-pointer">
              <Icon
                name={"trash"}
                size={12}
                color={"#2E90FA"}
              />
            </div>
          )}
        </div>
        <div className={`relative z-0 border-2 rounded-lg border-transparent transition-all duration-300 overflow-hidden
        ${isEqualId ? "!border-blue-400" : ""}
        group-hover:border-blue-400`}>{children}</div> {/*${selectedComponent ? " bg-white z-20" : ""}*/}
      </div>
    )
  }

  // effects

  useEffect(() => {
    if (strategies?.list?.result) {
      setPageComponentList(getInfoFromChildren(strategies.list.result))
    } else if (!strategies?.list?.result) {
      setPageComponentList([])
    }
  }, [strategies?.list?.result])

  useEffect(()=> {
    let result = []
    if (strategies?.list?.result){
      result = getInfoFromChildren(strategies.list.result)
    }
    if (EditLayouts === null){
      setPageComponentList(result)
    }
    setShowOverlay(!!EditLayouts)
    if (EditLayouts && !EditLayouts?.rejectPreviewRender){
      const hasIncludesMock = PageComponentList.find(list => list.code === EditLayouts.code)
      const name = Object.keys(EditLayouts)[0]
      if (!hasIncludesMock){
        if (EditLayouts?.order !== undefined && typeof EditLayouts?.order === "number"){
          PageComponentList.splice(EditLayouts?.order, 0, {
            name,
            ...EditLayouts,
          });

          setPageComponentList([
            ...PageComponentList
          ])
        } else {
          setPageComponentList([
            ...PageComponentList,
            {
              name,
              ...EditLayouts,
            }
          ])
        }
      }



      if (hasIncludesMock){
        const elementIndex = PageComponentList.findIndex(list => list.code === hasIncludesMock.code)
        PageComponentList[elementIndex] = {
          ...PageComponentList[elementIndex],
          ...EditLayouts
        }

        setPageComponentList([
          ...PageComponentList
        ])
      }

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [EditLayouts])

  useEffect(() => {
    dispatch(clientConfigGetRequested())
  }, [dispatch])

  useEffect(() => {
    if (ActiveSlide && EditComponent && EditLayouts){
      const category = ActiveSlide?.data?.data?.category
      showTabSliderActiveCategory(category)
      setEditComponent(false)
    }

  }, [ActiveSlide, EditComponent, EditLayouts])

  return (
    <section className="p-3 z-0">
      <div className="page-container relative flex flex-col bg-white border borer-gray-300 rounded-[20px] overflow-hidden max-h-full "> {/*min-w-[1040px]*/}
        <div className="browser-address-bar p-[5px] mx-5 bg-gray-100 rounded-xl my-2.5 text-sm text-gray-500 font-medium text-center relative z-30">
          <span className="pointer-events-none select-none">Domaininiz</span>
        </div>

        {strategies?.loading && getConfigurationLoading &&  <StrategyLoadingSkeleton />}

        <div
          className="lpBody flex flex-col overflow-auto min-h-[84vh]"
          ref={lpBody}
        >

          {/*<pre>{JSON.stringify(PageComponentList, null, 2)}</pre>*/}

          <div style={{
            overflow: "auto",
            height: layoutPreview?.id === 0 ? "calc(100vh - (56px + 84px))" : "",
            margin: "0 auto",
            width: layoutPreview?.id === 0 ? "375px" : "100%",
            minWidth: layoutPreview?.id === 0 ? "inherit" : "800px",
            backgroundColor: visualValues?.backgroundColor,
            fontFamily: visualValues?.fontFamily ? `"${visualValues?.fontFamily}"` : undefined,
          }}>
            <div style={{width: "auto"}}>

              {layoutPreview?.id === 0 && (
                <div className="flex items-center pt-6 pb-4 pl-9 pr-3">
                  <span className="font-bold">{moment(new Date()).format("HH:mm")}</span>
                  <img
                    className="ml-auto"
                    src={stack}
                    alt=""
                  />
                </div>
              )}
              <LayoutContainer>
                <div className={`w-full h-full absolute left-0 top-0 pointer-events-none bg-gray-500/[0.5] transition-all duration-400 z-20 opacity-0 ${ShowOverlay ? "opacity-100" : ""}`}></div>
                <Header
                  tabItemList={[
                    {label: "Otel", icon: "Home", isActive: true},
                    /*{label: "Uçak", icon: "Plane"},*/
                    {label: "Tur", icon: "CarFront"},
                  ]}
                  showAccountDropdownItem={false}
                  showHelpMenuItem={false}
                  showLanguageMenuItem={true}
                  showLogo={true}
                  showMyRequestsMenuItem={false}
                  showTabBar={true}
                  logoUrl={clientConfigurationGet?.logoUrl}
                  className="bg-white"
                  design={visualValues}
                />

                {(!PageComponentList?.length || (PageComponentList && PageComponentList[0]?.name === ATOM_PAGE)) && !EditLayouts?.virtualBlock && (
                  <AddBlock
                    AddNewBlockHandleClick={AddNewBlockHandleClick}
                    setAddNewBlockHandleClick={setAddNewBlockHandleClick}
                    index={0}
                    setBlockListTab={setBlockListTab}
                    setEditLayouts={setEditLayouts}
                    style={{height: 434}}
                  />
                )}


                {PageComponentList?.length > 0 &&
                  PageComponentList.map((component, index, list) => {
                    const Name = component?.name
                    let Component = gordyComponents[Name] && gordyComponents[Name](component)
                    if (Name === ATOM_PAGE) return <div className="hidden" key={component?.code} />
                    return (
                      <span
                        key={component?.code}
                      >
                        {!EditLayouts?.virtualBlock && activeMenuTab === 0 && (
                          <AddBlock
                            AddNewBlockHandleClick={AddNewBlockHandleClick}
                            setAddNewBlockHandleClick={setAddNewBlockHandleClick}
                            index={index}
                            setBlockListTab={setBlockListTab}
                            setEditLayouts={setEditLayouts}
                          />
                        )}


                        {SettingsWrapper({component, index, className:"page-component", children: Component ? <div style={{
                            fontSize: `${visualValues?.fontSize}px`,
                            margin: `${visualValues?.componentMargin / 2}px`,
                          }} className="relative z-20 bg-white">{Component}</div> : <ComponentNotFound />})}


                        {list.length === index + 1 && !EditLayouts?.virtualBlock && activeMenuTab === 0 && (
                          <AddBlock
                            AddNewBlockHandleClick={AddNewBlockHandleClick}
                            setAddNewBlockHandleClick={setAddNewBlockHandleClick}
                            index={index + 1}
                            setBlockListTab={setBlockListTab}
                            setEditLayouts={setEditLayouts}
                          />
                        )}
                      </span>
                    )})}
              </LayoutContainer>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PreviewPage
