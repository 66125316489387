const AddHtmlPattern = (model, thisComponent, mediaId) => {
  console.log({
    model,
    thisComponent,
    mediaId,
  })
  if (!model || !Object.values(model)?.length) {
    return false
  }

  //translateItems
  // designField listesi icinde bulunan itemlar`in relation key`i "Data" olani getirir
  const isRelationData = model?.find((item) => item?.relation === "Data")
  const isRelationVisual = model?.find((item) => item?.relation === "Visual")
  const translateItems = isRelationData?.translateItems
  const dataInput = isRelationData?.input
  const visualInput = isRelationVisual?.input


  if (isRelationVisual && thisComponent?.visual){
    visualInput.style = {
      ...visualInput.style,
      ...thisComponent?.visual?.style
    }
    visualInput.settings = {
      ...visualInput.settings,
      ...thisComponent?.visual?.settings
    }
  }


  if (isRelationData) {
    let title = thisComponent?.data?.title
		let html = thisComponent?.data?.html

    translateItems.map(item => {
      if (item?.langKey === "TitleLangKey"){
        item.value = title || ""
        item.name = "title text" || ""
      } else if (item?.langKey === "HtmlLangKey"){
        item.value = html || ""
        item.name = "html text" || ""
      }
    })


    if (mediaId === 0){
      delete dataInput.backgroundMediaId
    } else {
      dataInput.backgroundMediaId = mediaId || dataInput.backgroundMediaId
    }

    dataInput.title = title
    dataInput.html = html
    dataInput.titleStyle = {
      ...dataInput.titleStyle,
      ...thisComponent?.data?.titleStyle
    }

    return model
  }

}

export default AddHtmlPattern
