import React, { useRef, useState } from "react"

// layouts
import PartialWrapper from "../../../../PartialWrapper"

// constants
import { evaGlobalImageUrl } from "constants/index"

// components
import { Icon, RangeInput, Subtitle, TextInput, ToggleBox, BorderBox, TabList } from "components"

// utils

const MediaLayout = (props) => {
  const { showSubLayout, setShowSubLayout } = props

  // refs
  const formRef = useRef()
  const UploadFileRef = useRef()

  const [UploadedImageInfo, setUploadedImageInfo] = useState(null)
  const [DefaultImageInfo, setDefaultImageInfo] = useState(null)
  const [MediaSizeRange, setMediaSizeRange] = useState(24)

  //funcs
  const clearImageState = () => {
    setUploadedImageInfo(null)
    setDefaultImageInfo(null)
  }

  const readImage = (file) => {
    const reader = new FileReader()
    reader.addEventListener("load", (event) => {
      setUploadedImageInfo({
        src: event.target.result,
        size: file.size,
        name: file.name,
        fileForBinary: file,
      })
    })
    reader.readAsDataURL(file)
    UploadFileRef.current.value = ""
  }

  return (
    <PartialWrapper
      className={`media-layout${showSubLayout?.mediaLayout ? " active" : ""}`}
      goBackOnClick={() => {
        setShowSubLayout(null)
        clearImageState()
      }}
    >
      <Subtitle>Media</Subtitle>

      <div className="flex flex-col gap-4 mt-4">
        <div className="input-wrapper">
          <ToggleBox
            title={"Display Media"}
            defaultChecked={true}
            onToggle={(e) => console.log(e)}
          />
        </div>

        <TabList
          onChange={(e) => console.log(e)}
          tabs={[
            { label: "Görüntü", disabled: false },
            { label: "Video", disabled: true },
          ]}
          tabClassName="w-full flex justify-center gap-2.5 text-center text-gray-600 text-xs p-2 border-r last:border-r-0 hover:bg-gray-50 transition duration-400"
          wrapperClassName="flex shrink-0 border rounded-lg border-gray-200 overflow-hidden"
          //itemActiveClass="!text-gray-800 !bg-blue-50"
          activeTab={0}
        />

        <BorderBox className="background-image-area text-sm p-3">
          <div className="flex items-center">
            <p className="text-gray-800 font-medium">Görüntü</p>
            <div className="progress-area ml-auto">
              {!UploadedImageInfo && !DefaultImageInfo && (
                <p
                  className="text-blue-500 cursor-pointer font-medium hover:text-blue-300 transition"
                  onClick={() => UploadFileRef.current.click()}
                >
                  Ekle
                </p>
              )}
              {(UploadedImageInfo || DefaultImageInfo) && (
                <div className="icon-box flex items-center gap-4">
                  <Icon
                    className="cursor-pointer hover:opacity-50"
                    name="refresh-cw"
                    size={20}
                    color={"#1D2939"}
                    onClick={() => UploadFileRef.current.click()}
                  />
                  <Icon
                    className="cursor-pointer hover:opacity-50"
                    name="trash-2"
                    size={20}
                    color={"#1D2939"}
                    onClick={clearImageState}
                  />
                </div>
              )}
            </div>
            <form
              ref={formRef}
              className="hidden"
            >
              <input
                hidden
                type="file"
                id="fileUploadInputForSlider"
                ref={UploadFileRef}
                onChange={(e) => readImage(UploadFileRef.current.files[0])}
                accept=".jpg, .jpeg, .png, .svg, .gif"
              />
            </form>
          </div>

          {DefaultImageInfo && (
            <div className="uploaded-image-area flex items-center justify-center rounded-lg py-overflow-hidden h-[220px] mt-3">
              <img
                className="max-w-full object-cover min-w-[50%] object-center bg-white/[.8] max-h-full"
                src={`${evaGlobalImageUrl}${DefaultImageInfo?.path}`}
                alt={DefaultImageInfo?.name}
              />
            </div>
          )}

          {UploadedImageInfo && (
            <div className="uploaded-image-area flex items-center justify-center rounded-lg py-overflow-hidden h-[220px] mt-3">
              <img
                className="max-w-full object-cover min-w-[50%] object-center bg-white/[.8] max-h-full"
                src={UploadedImageInfo?.src}
                alt={UploadedImageInfo?.name}
              />
            </div>
          )}

          {!UploadedImageInfo && (
            <p className="mt-3">
              Yüksek çözünürlüklü fotoğraflar, kompanent’in yüklenme hızını etkileyebilir. Fotoğrafları yüklemeden önce optimize
              etmenizi tavsiye ederiz.
            </p>
          )}
        </BorderBox>

        <div className="input-wrapper">
          <RangeInput
            rangeLabel={"Media Size (px)"}
            rangeValue={MediaSizeRange}
            min={0}
            max={100}
            onChange={(value) => {
              console.log(value)
              setMediaSizeRange(value)
            }}
          />
        </div>

        <div className="input-wrapper">
          <TextInput title="Görüntü Alt Başlık" />
        </div>
      </div>
    </PartialWrapper>
  )
}

export default MediaLayout
