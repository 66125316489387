import { useState, useEffect } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import MainHeader from "components/Headers/MainHeader"
import Icon from "components/Icon"
import Information from "components/MarketPlace/AddonDetail/Information"
import PaymentPlans from "components/MarketPlace/AddonDetail/PaymentPlans"
import AddonSetup from "components/MarketPlace/AddonDetail/AddonSetup"
import Modal from "components/Modal"
import MasterpassImage from "assets/img/detailpopup.png"
import MarketPlaceFilter from "layouts/Aside/MarketPlaceFilter"
import AddonDetailSkeleton from "skeletons/MarketPlace/AddonDetail"

import { addonDetailRequested } from "redux/actions/addonDetail"

const AddonDetail = (props) => {
  let { isLogged, AssociatedDomains } = props

  let navigate = useNavigate()
  const dispatch = useDispatch()
  const { id } = useParams()
  const DomainId = AssociatedDomains?.domainId

  const [priceList, setPriceList] = useState({})
  const [activeMenu, setActiveMenu] = useState(0)
  const [openModal, setOpenModal] = useState(false)
  const [setupStepModal, setSetupStepModal] = useState(false)
  const [accessLinkModalRadio, setAccessLinkModalRadio] = useState("monthly")

  const AddonDetail = useSelector((state) => state.AddonDetail?.list)
  const AddonDetailLoading = useSelector((state) => state.AddonDetail?.loading)
  const AddonDetailError = useSelector((state) => state.AddonDetail?.error)

  const tabs = ["Detaylı Bilgi", "Ödeme Planları", "Kurulum"]

  const goBack = () => {
    navigate(-1)
  }

  const handleRadio = (event) => {
    setAccessLinkModalRadio(event.target.value)
  }

  useEffect(() => {
    dispatch(addonDetailRequested(id))
  }, [dispatch, id])

  useEffect(() => {
    AddonDetail?.AddonRelations?.map((value) =>
      setPriceList({
        montlyFee: value.AddonPrices[1]?.Fee ? value.AddonPrices[1]?.Fee : "Ücretsiz",
        montlyFeeCurrency: value.AddonPrices[1]?.FeeCurrency ? value.AddonPrices[1]?.FeeCurrency : null,
        yearlyFee: value.AddonPrices[0]?.Fee ? value.AddonPrices[0]?.Fee : "Ücretsiz",
        yearlyFeeCurrency: value.AddonPrices[0]?.FeeCurrency ? value.AddonPrices[0]?.FeeCurrency : null,
      }),
    )
  }, [AddonDetail])

  return (
    <>
      <MainHeader
        isLogged={isLogged}
        marketPlace
      />
      <MarketPlaceFilter />
      {AddonDetailLoading && <AddonDetailSkeleton />}
      {AddonDetailError && (
        <main>
          <section className="p-10 my-0 mx-auto max-w-[1920px]">Addon bulunamadı </section>
        </main>
      )}
      {!AddonDetailLoading && !AddonDetailError && (
        <main>
          <section className="p-10 my-0 mx-auto max-w-[1920px]">
            <div
              onClick={goBack}
              className="go-back inline-flex items-center cursor-pointer"
            >
              <Icon
                className="mr-2"
                name="arrow-left"
                size="20"
                color="#667085"
              />
              <span className="text-base text-gray-500">Geri Dön</span>
            </div>
            <div className="flex justify-between my-6">
              <div className="w-3/4">
                <h2 className="font-semibold text-3xl">{AddonDetail?.Name}</h2>
                {/* <span className="text-gray-500 mt-2">{AddonDetail?.Description}</span> */}
              </div>
              <button
                className="btn"
                onClick={() => setOpenModal(true)}
              >
                Kurulumu Başlat
              </button>
            </div>
            <div className="flex flex-row mb-6 border-b border-solid border-gray-200">
              {tabs?.map((value, index) => (
                <p
                  onClick={() => setActiveMenu(index)}
                  className={
                    activeMenu === index
                      ? "text-sm font-medium pb-4 border-b border-solid border-blue-500 text-blue-500 mr-6 cursor-pointer"
                      : "text-sm font-medium text-gray-500 mr-6 cursor-pointer"
                  }
                >
                  {value}
                </p>
              ))}
            </div>

            {activeMenu === 0 && <Information description={AddonDetail?.Description} />}
            {activeMenu === 1 && <PaymentPlans />}
            {activeMenu === 2 && (
              <AddonSetup
                AddonDetail={AddonDetail}
                DomainId={DomainId}
                TurnInformationPage={setActiveMenu}
              />
            )}

            <Modal
              onChange={() => {
                setOpenModal(false)
              }}
              open={openModal}
              width={480}
              closeButton={false}
            >
              <div className="flex flex-col gap-5">
                <div>
                  <img
                    src={MasterpassImage}
                    alt=""
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <h3 className="font-semibold text-gray-800">{AddonDetail?.Name}</h3>
                  {/* <p className="text-gray-500 text-sm">{AddonDetail?.Description}</p> */}
                  <p className="text-gray-500 text-sm">Ürününüzdeki satışların ödemelerini Masterpass ile gerçekleştirin. </p>
                </div>
                <div className="bg-gray-50 border border-gray-200 rounded-lg p-4 flex">
                  <Icon
                    color="currentColor"
                    name="info"
                    size={"20"}
                  />
                  <div className="ml-[10px] flex flex-col gap-1">
                    <p className="font-semibold text-gray-800">Hatırlatmak İstedik</p>
                    <span className="text-xs text-gray-500">Masterpass kurulumunu yapabilmek için neler gerekli?</span>
                    <li className="text-xs text-gray-500 list-disc">Ürününüzün satış belgesi olduğuna emin olun.</li>
                  </div>
                </div>
                <p className="text-sm text-gray-500">Masterpass ile güvenilir ödeme alabilmek için ödeme planınızı seçin.</p>
                <div className="flex flex-col">
                  <label
                    className={`flex flex-row items-center mb-2 cursor-pointer border rounded-lg p-4 ${
                      accessLinkModalRadio === "monthly" ? "border-blue-500" : "border-gray-200"
                    }`}
                  >
                    <input
                      className="mr-2"
                      defaultChecked={accessLinkModalRadio === "monthly"}
                      onChange={(e) => handleRadio(e)}
                      type="radio"
                      value="monthly"
                      name="Payment"
                    />
                    <div className="flex flex-col">
                      <p className={accessLinkModalRadio === "monthly" ? "text-sm text-blue-500" : "text-sm text-gray-400"}>
                        Aylık
                      </p>
                      <span className="text-gray-500 text-sm">
                        {priceList?.montlyFeeCurrency}
                        {priceList?.montlyFee}
                        {priceList?.montlyFee !== "Ücretsiz" && "/Ay, Her ay yenilenir."}
                      </span>
                    </div>
                  </label>
                  <label
                    className={`flex flex-row items-center mb-2 cursor-pointer border rounded-lg p-4 mt-5 ${
                      accessLinkModalRadio === "yearly" ? "border-blue-500" : "border-gray-200"
                    }`}
                  >
                    <input
                      className="mr-2"
                      defaultChecked={accessLinkModalRadio === "yearly"}
                      onChange={(e) => handleRadio(e)}
                      type="radio"
                      value="yearly"
                      name="Payment"
                    />
                    <div className="flex flex-col">
                      <p className={accessLinkModalRadio === "yearly" ? "text-sm text-blue-500" : "text-sm text-gray-400"}>
                        Yıllık <span className="text-warning-500">Yıllık %26 kazanın</span>
                      </p>
                      <span className="text-gray-500 text-sm">
                        {priceList?.yearlyFeeCurrency}
                        {priceList?.yearlyFee}
                        {priceList?.yearlyFee !== "Ücretsiz" && "/Ay, Her yıl yenilenir. Dilediğiniz zaman iptal edin."}
                      </span>
                    </div>
                  </label>
                </div>
                <div className="w-full flex gap-3">
                  <button
                    onClick={() => setOpenModal((prev) => !prev)}
                    className="btn lg outline mr-3 w-1/2"
                  >
                    Vazgeç
                  </button>
                  <button
                    className="btn w-1/2"
                    onClick={() => {
                      setOpenModal((prev) => !prev)
                      setSetupStepModal((prev) => !prev)
                    }}
                  >
                    Etkinleştir
                  </button>
                </div>
              </div>
              <div className="flex flex-row w-full justify-end mt-8"></div>
            </Modal>

            <Modal
              onChange={() => {
                setSetupStepModal((prev) => !prev)
              }}
              open={setupStepModal}
              width={480}
              closeButton={false}
            >
              <div className="flex flex-col gap-5">
                <div>
                  <img
                    src={MasterpassImage}
                    alt=""
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <h3 className="font-semibold text-gray-800">Masterpass</h3>
                  <p className="text-gray-500 text-sm">Ürününüzdeki satışların ödemelerini Masterpass ile gerçekleştirin.</p>
                </div>
                <div className="w-full flex gap-3">
                  <Link
                    to="/marketplace/plugins"
                    onClick={() => setSetupStepModal((prev) => !prev)}
                    className="btn lg outline mr-3 w-1/2"
                  >
                    Eklentilere Dön
                  </Link>
                  <button
                    className="btn w-1/2"
                    onClick={() => {
                      setActiveMenu(2)
                      setSetupStepModal((prev) => !prev)
                    }}
                  >
                    Kurulum Yap
                  </button>
                </div>
              </div>
            </Modal>
          </section>
        </main>
      )}
    </>
  )
}

export default AddonDetail
