import * as type from "redux/types"

// COUNTRIES
export const countriesRequested = (data) => ({ type: type.COUNTRIES_REQUESTED, payload: data })
export const countriesSucceed = (data) => ({ type: type.COUNTRIES_SUCCEEDED, payload: data })
export const countriesFailed = () => ({ type: type.COUNTRIES_FAILED })

// CITIES
export const citiesRequested = (data) => ({ type: type.CITIES_REQUESTED, payload: data })
export const citiesSucceed = (data) => ({ type: type.CITIES_SUCCEEDED, payload: data })
export const citiesFailed = () => ({ type: type.CITIES_FAILED })

// DISTRICTS
export const districtsRequested = (data) => ({ type: type.DISTRICTS_REQUESTED, payload: data })
export const districtsSucceed = (data) => ({ type: type.DISTRICTS_SUCCEEDED, payload: data })
export const districtsFailed = () => ({ type: type.DISTRICTS_FAILED })
