import * as type from "redux/types"

export const MyWorkAreaRequested = (data) => ({
  type: type.MY_WORKAREA_INFO_REQUESTED,
  payload: data,
})

export const MyWorkAreaSucceed = (data) => ({
  type: type.MY_WORKAREA_INFO_SUCCEEDED,
  payload: data,
})

export const MyWorkAreaFailed = () => ({
  type: type.MY_WORKAREA_INFO_FAILED,
})
