export const MediaListDummy = {
  "visual": {
    "width": null,
    "height": null,
    "style": {
      "borderRadius": null,
      "backgroundColor": null,
      "color": null,
      "position": null,
      "fontWeight": null,
      "fontFamily": null,
      "fontSize": null,
      "lineHeight": null,
      "zIndex": null,
      "opacity": null,
      "paddingRight": null,
      "paddingTop": null,
      "paddingBottom": null,
      "paddingLeft": null,
      "marginRight": null,
      "marginTop": null,
      "marginBottom": null,
      "marginLeft": null,
      "left": null,
      "top": null,
      "right": null,
      "bottom": null,
      "cursor": null,
      "borderBottom": null,
      "borderTop": null,
      "borderRight": null,
      "borderLeft": null,
      "gap": null,
      "display": null,
      "alignItems": null,
      "padding": null,
      "border": null,
      "justifyContent": null,
      "margin": null,
      "flexDirection": null,
      "width": null,
      "textAlign": null,
      "whiteSpace": null
    },
    "paginationStyle": {
      "layout": null,
      "align": null,
      "position": null,
      "infiniteLoop": false,
      "swipeNavigation": false,
      "backgroundColor": null,
      "color": null,
      "fontWeight": null,
      "fontFamily": null,
      "borderRadius": null,
      "fontSize": null,
      "lineHeight": null,
      "zIndex": null,
      "opacity": null,
      "paddingRight": null,
      "paddingTop": null,
      "paddingBottom": null,
      "paddingLeft": null,
      "marginRight": null,
      "marginTop": null,
      "marginBottom": null,
      "marginLeft": null,
      "left": null,
      "top": null,
      "right": null,
      "bottom": null,
      "cursor": null,
      "borderBottom": null,
      "borderTop": null,
      "borderRight": null,
      "borderLeft": null,
      "gap": null,
      "display": null,
      "alignItems": null,
      "padding": null,
      "border": null,
      "justifyContent": null,
      "margin": null,
      "flexDirection": null,
      "width": null,
      "textAlign": null,
      "whiteSpace": null
    },
    "arrowVisualStyle": {
      "displayArrows": false,
      "position": null,
      "color": null,
      "backgroundColor": null,
      "fontWeight": null,
      "fontFamily": null,
      "borderRadius": null,
      "fontSize": null,
      "lineHeight": null,
      "zIndex": null,
      "opacity": null,
      "paddingRight": null,
      "paddingTop": null,
      "paddingBottom": null,
      "paddingLeft": null,
      "marginRight": null,
      "marginTop": null,
      "marginBottom": null,
      "marginLeft": null,
      "left": null,
      "top": null,
      "right": null,
      "bottom": null,
      "cursor": null,
      "borderBottom": null,
      "borderTop": null,
      "borderRight": null,
      "borderLeft": null,
      "gap": null,
      "display": null,
      "alignItems": null,
      "padding": null,
      "border": null,
      "justifyContent": null,
      "margin": null,
      "flexDirection": null,
      "width": null,
      "textAlign": null,
      "whiteSpace": null
    },
    "autoSlide": {
      "autoSlide": false,
      "pauseOnHover": false,
      "delay": 0
    },
    "settings": {
      "className": null
    },
    "code": "0708b491-87c0-44dc-a137-b847286083cb",
    "outputData": null,
    "type": 1,
    "name": "Visual.Molecule.MediaListVisual",
    "status": 1
  },
  "data": {
    "title": "",
    "titleStyle": {
      "textAlign": null,
      "fontSize": null,
      "fontFamily": null,
      "color": null,
      "fontWeight": null,
      "display": null,
      "visible": null,
      "backgroundColor": null,
      "position": null,
      "borderRadius": null,
      "lineHeight": null,
      "zIndex": null,
      "opacity": null,
      "paddingRight": null,
      "paddingTop": null,
      "paddingBottom": null,
      "paddingLeft": null,
      "marginRight": null,
      "marginTop": null,
      "marginBottom": null,
      "marginLeft": null,
      "left": null,
      "top": null,
      "right": null,
      "bottom": null,
      "cursor": null,
      "borderBottom": null,
      "borderTop": null,
      "borderRight": null,
      "borderLeft": null,
      "gap": null,
      "alignItems": null,
      "padding": null,
      "border": null,
      "justifyContent": null,
      "margin": null,
      "flexDirection": null,
      "width": null,
      "whiteSpace": null
    },
    "code": "a04cec53-1671-42c9-b61d-11a6f13b74ad",
    "outputData": null,
    "type": 2,
    "name": "Gordios.Strategy.Web.Concrete.Data.Molecule.MediaListData",
    "status": 1
  },
  "event": null,
  "code": "a640974f-0034-4ffd-ac76-d86ba0b04b8e",
  "children": [
    {
      "data": {
        "category": {
          "categoryId": 123,
          "order": 1,
          "categoryName": "ensar media 1",
          "categoryVisible": false
        },
        "isActive": false,
        "code": "c7d55374-acda-476e-baa9-df35b991fe56",
        "outputData": null,
        "type": 2,
        "name": "Data.Atom.MediaData",
        "status": 1
      },
      "event": null,
      "code": "611d97a2-8c25-4a81-a714-785a4aed7829",
      "children": [
        {
          "visual": {
            "height": null,
            "width": null,
            "loading": null,
            "style": {
              "backgroundColor": null,
              "color": null,
              "position": null,
              "fontWeight": null,
              "fontFamily": null,
              "borderRadius": null,
              "fontSize": null,
              "lineHeight": null,
              "zIndex": null,
              "opacity": null,
              "paddingRight": null,
              "paddingTop": null,
              "paddingBottom": null,
              "paddingLeft": null,
              "marginRight": null,
              "marginTop": null,
              "marginBottom": null,
              "marginLeft": null,
              "left": null,
              "top": null,
              "right": null,
              "bottom": null,
              "cursor": null,
              "borderBottom": null,
              "borderTop": null,
              "borderRight": null,
              "borderLeft": null,
              "gap": null,
              "display": null,
              "alignItems": null,
              "padding": null,
              "border": null,
              "justifyContent": null,
              "margin": null,
              "flexDirection": null,
              "width": null,
              "textAlign": null,
              "whiteSpace": null
            },
            "settings": {
              "className": null
            },
            "code": "379ffce2-f787-455c-a9f8-58f04ef80ee5",
            "outputData": null,
            "type": 1,
            "name": "Visual.Atom.ImageVisual",
            "status": 1
          },
          "data": {
            "name": "",
            "src": "https://assets-v2.lottiefiles.com/a/490bc92a-1170-11ee-9e35-0f6eacef4099/DBwCBrM6eQ.gif",
            "code": "2f0d352e-77d5-497f-8cd6-093b693d31b2",
            "outputData": null,
            "type": 2,
            "status": 1
          },
          "event": null,
          "code": "97ba0cc2-cbf2-43d5-bf50-23ec884dc8e5",
          "outputData": null,
          "type": 5,
          "name": "Image",
          "status": 1
        },
        {
          "visual": {
            "style": {
              "backgroundColor": null,
              "color": null,
              "position": null,
              "fontWeight": null,
              "fontFamily": null,
              "borderRadius": null,
              "fontSize": null,
              "lineHeight": null,
              "zIndex": null,
              "opacity": null,
              "paddingRight": null,
              "paddingTop": null,
              "paddingBottom": null,
              "paddingLeft": null,
              "marginRight": null,
              "marginTop": null,
              "marginBottom": null,
              "marginLeft": null,
              "left": null,
              "top": null,
              "right": null,
              "bottom": null,
              "cursor": null,
              "borderBottom": null,
              "borderTop": null,
              "borderRight": null,
              "borderLeft": null,
              "gap": null,
              "display": null,
              "alignItems": null,
              "padding": null,
              "border": null,
              "justifyContent": null,
              "margin": null,
              "flexDirection": null,
              "width": null,
              "textAlign": null,
              "whiteSpace": null
            },
            "settings": {
              "className": "textComponent"
            },
            "code": "cc27f3c5-d778-4385-b31e-a9829f3d4aaf",
            "outputData": null,
            "type": 1,
            "name": "Visual.Atom.TextVisual",
            "status": 1
          },
          "data": {
            "text": "null",
            "code": "4c3522d6-ad54-4313-82c7-90f1757d287f",
            "outputData": null,
            "type": 2,
            "name": "Data.Atom.TextData",
            "status": 1
          },
          "event": null,
          "code": "cbad17cb-fa53-4a15-b675-11965af0dcd6",
          "outputData": null,
          "type": 5,
          "name": "Text",
          "status": 1
        },
        {
          "visual": {
            "style": {
              "backgroundColor": null,
              "color": null,
              "position": null,
              "fontWeight": null,
              "fontFamily": null,
              "borderRadius": null,
              "fontSize": null,
              "lineHeight": null,
              "zIndex": null,
              "opacity": null,
              "paddingRight": null,
              "paddingTop": null,
              "paddingBottom": null,
              "paddingLeft": null,
              "marginRight": null,
              "marginTop": null,
              "marginBottom": null,
              "marginLeft": null,
              "left": null,
              "top": null,
              "right": null,
              "bottom": null,
              "cursor": null,
              "borderBottom": null,
              "borderTop": null,
              "borderRight": null,
              "borderLeft": null,
              "gap": null,
              "display": "block",
              "alignItems": null,
              "padding": null,
              "border": null,
              "justifyContent": null,
              "margin": null,
              "flexDirection": null,
              "width": null,
              "textAlign": null,
              "whiteSpace": null
            },
            "settings": {
              "className": "subTextComponent"
            },
            "code": "febdfa8e-6603-4803-94ce-a304000c63bc",
            "outputData": null,
            "type": 1,
            "name": "Visual.Atom.TextVisual",
            "status": 1
          },
          "data": {
            "text": "null",
            "code": "2a7b65d2-9cd4-43f6-b403-ca4d535d2e07",
            "outputData": null,
            "type": 2,
            "name": "Data.Atom.TextData",
            "status": 1
          },
          "event": null,
          "code": "f18c2059-e5d7-4a38-8db6-768a0f931549",
          "outputData": null,
          "type": 5,
          "name": "Text",
          "status": 1
        },
        {
          "visual": {
            "style": {
              "backgroundColor": null,
              "color": null,
              "position": null,
              "fontWeight": null,
              "fontFamily": null,
              "borderRadius": null,
              "fontSize": null,
              "lineHeight": null,
              "zIndex": null,
              "opacity": null,
              "paddingRight": null,
              "paddingTop": null,
              "paddingBottom": null,
              "paddingLeft": null,
              "marginRight": null,
              "marginTop": null,
              "marginBottom": null,
              "marginLeft": null,
              "left": null,
              "top": null,
              "right": null,
              "bottom": null,
              "cursor": null,
              "borderBottom": null,
              "borderTop": null,
              "borderRight": null,
              "borderLeft": null,
              "gap": null,
              "display": null,
              "alignItems": null,
              "padding": null,
              "border": null,
              "justifyContent": null,
              "margin": null,
              "flexDirection": null,
              "width": null,
              "textAlign": null,
              "whiteSpace": null
            },
            "settings": {
              "className": null
            },
            "code": "03fcd7d6-8646-40ee-b1bb-59364bf1f1dd",
            "outputData": null,
            "type": 1,
            "name": "Components.Visual.Atom.HyperlinkVisual",
            "status": 1
          },
          "data": {
            "target": null,
            "href": null,
            "code": "7efabc2b-cac0-419a-9cbc-29087583aec6",
            "outputData": null,
            "type": 2,
            "name": "Data.Atom.HyperlinkData",
            "status": 1
          },
          "event": null,
          "code": "63cc0872-6eac-42d9-9576-92b0327d3d2a",
          "outputData": null,
          "type": 5,
          "name": "Components.Atom.Hyperlink",
          "status": 1
        }
      ],
      "outputData": null,
      "type": 5,
      "name": "Media",
      "status": 1
    },
  ],
  "outputData": null,
  "type": 5,
  "name": "Media List",
  "status": 1
}