import React from "react"
import { useDispatch } from "react-redux"
import { useForm, FormProvider } from "react-hook-form"
import SocialMediaFormItem from "../components/SocialMediaFormItem"
import SocialMediaGeneralSettings, { generalSettingsList } from "../components/SocialMediaGeneralSettings"
import { getSettingById, getPropertyValueByName } from "utils/propertyUtils"
import { saveUserSettingsRequested } from "redux/actions/saveUserSettings"
import { getSettingsRequested } from "redux/actions/getSettings"

export const socialMediaList = [
  { name: "facebook", title: "Facebook" },
  { name: "twitter", title: "Twitter" },
  { name: "instagram", title: "Instagram" },
  { name: "linkedin", title: "LinkedIn" },
  { name: "youtube", title: "YouTube" },
  { name: "pinterest", title: "Pinterest" },
  { name: "tiktok", title: "Tiktok" },
]

const groupedSettings = generalSettingsList.reduce((acc, { group }) => {
  acc[group] = ""
  return acc
}, {})

const SocialMedia = ({ setSelectedContent, getSettingsData, rejectButtonClick }) => {
  const dispatch = useDispatch()

  const propertyNames = ["Active", "DisplayPreference", "IconPreference", "CustomIcon", "Address"]

  const propertyData = getSettingsData?.Result?.reduce((acc, item) => {
    if (propertyNames.includes(item.PropertyName)) {
      const key = `${item.PropertyName}`
      acc[key] = acc[key] || []
      acc[key].push(item)
    }
    return acc
  }, {})

  const { Active = [], DisplayPreference = [], IconPreference = [], CustomIcon = [], Address = [] } = propertyData || {}

  const createProperty = (data, key, title) => ({
    PropertyId: getSettingById(data, title),
    PropertyValue: key || "",
  })

  const handleFormSubmit = (data) => {
    const generalSettings = data.generalSettings

    const iconPreferenceMap = {
      "siyah-beyaz": "BlackWhite",
      renkli: "Colored",
    }

    const displayPreferenceMap = {
      ikon: "Icon",
      "ikon-sosyal-medya-adi": "IconWithName",
    }

    const iconPreference = iconPreferenceMap[generalSettings["İkon Rengi"]]
    const displayPreference = displayPreferenceMap[generalSettings["Görünüm"]]

    const properties = socialMediaList.flatMap((media) => {
      const mediaData = data[media.name] || {}

      return [
        createProperty(Active, `${mediaData.active ? "True" : "False"}`, media.title),
        createProperty(DisplayPreference, displayPreference, media.title),
        createProperty(IconPreference, iconPreference, media.title),
        createProperty(CustomIcon, mediaData.logo, media.title),
        createProperty(Address, mediaData.link, media.title),
      ]
    })

    dispatch(saveUserSettingsRequested({ properties }))
  }

  const methods = useForm({
    defaultValues: {
      generalSettings: groupedSettings,
      ...socialMediaList.reduce((acc, { name }) => {
        acc[name] = { active: false, link: "", logo: "" }
        return acc
      }, {}),
    },
  })

  const getPropertyValue = (settingName, propertyName) => {
    const setting = getSettingsData?.Result?.find(
      (item) => item.SettingName === settingName && item.PropertyName === propertyName,
    )
    return setting ? setting.PropertyValue : ""
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleFormSubmit)}>
        <SocialMediaGeneralSettings
          displayPreference={getPropertyValueByName(getSettingsData.Result, "DisplayPreference")}
          iconPreference={getPropertyValueByName(getSettingsData.Result, "IconPreference")}
        />
        <div className="flex flex-col gap-6 bg-white border border-gray-200 rounded-lg p-8 mt-6">
          <div className="flex items-center justify-between">
            <h3 className="text-gray-800 text-lg font-semibold">Sosyal Medya Hesapları</h3>
          </div>
          <div className="grid grid-cols-2 gap-4 ">
            {socialMediaList.map(({ name, title }) => (
              <SocialMediaFormItem
                key={name}
                title={title}
                name={name}
                active={getPropertyValue(title, "Active")}
                link={getPropertyValue(title, "Address")}
                logo={getPropertyValue(title, "CustomIcon")}
              />
            ))}
          </div>
        </div>
        <div className="my-6">
          <div className="button-area flex justify-end gap-4">
            <button
              onClick={() => {
                setSelectedContent(false)
                rejectButtonClick()
              }}
              className={`btn outline`}
            >
              Vazgeç
            </button>
            <button
              onClick={() => {
                setTimeout(() => {
                  setSelectedContent(false)
                  dispatch(getSettingsRequested())
                }, 1000)
              }}
              type="submit"
              className="btn"
            >
              Kaydet ve Yayınla
            </button>
          </div>
        </div>
      </form>
    </FormProvider>
  )
}

export default SocialMedia
