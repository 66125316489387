/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux";

// layouts
import PartialWrapper from "../../../PartialWrapper"

// components
import {Subtitle, ToggleBox} from "components"
import {UpdateStrategy} from "../../../../helpers/UpdateStrategy";

//helpers
import {setSettings} from "helpers/setSettings";

//saga-requests
import {getStrategySucceed} from "redux/actions/strategy";

const PaginationAndNavigation = (props) => {
  let { show, setShow, ThisComponent, setThisComponent } = props

  const dispatch = useDispatch()

  //selectors
  let components = useSelector(state => state.strategy.getStrategy.list?.result)

  // states
  const [PaginationSettings, setPaginationSettings] = useState(null)


  // Func

  //effects
  useEffect(() => {
    if (PaginationSettings){
      setThisComponent({
        ...ThisComponent,
        visual: {
          ...ThisComponent?.visual,
          paginationStyle: {
            ...ThisComponent.visual?.paginationStyle,
            ...PaginationSettings
          }
        }
      })
    }
  }, [PaginationSettings])


  useEffect(() => {
    if (ThisComponent && PaginationSettings){
      const strategy = UpdateStrategy(components, ThisComponent)
      dispatch(getStrategySucceed(strategy))
    }

  }, [ThisComponent])


  return (
    <PartialWrapper
      goBackOnClick={() => setShow(null)}
      className={`pagination-and-navigation${show ? ` active` : ``}`}
    >
      <Subtitle className={"mt-5"}>Sayfalandırma</Subtitle>

      <div className="input-wrapper mt-4">
          <ToggleBox
            title={"Sayfalandırmayı Göster"}
            defaultChecked={(ThisComponent?.visual?.paginationStyle?.swipeNavigation) || false}
            onToggle={(e) => {
              setSettings({ target: {
                  name: "swipeNavigation",
                  value: e
                }}, true).then((res) => setPaginationSettings(res))
            }}
          />
      </div>
    </PartialWrapper>
  )
}

export default PaginationAndNavigation
