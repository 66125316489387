import * as type from "redux/types"

export const myReservationsApproveRequested = (data) => ({
  type: type.MY_RESERVATIONS_APPROVE_REQUESTED,
  payload: data,
})

export const myReservationsApproveSucceed = (data) => ({
  type: type.MY_RESERVATIONS_APPROVE_SUCCEEDED,
  payload: data,
})

export const myReservationsApproveFailed = () => ({
  type: type.MY_RESERVATIONS_APPROVE_FAILED,
})
