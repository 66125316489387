import React from "react"
import PropTypes from "prop-types"
import Icon from "./Icon"
import LoadBar from "./LoadBar"

const Alert = ({
  title,
  description,
  iconName,
  iconColor,
  iconBgColor,
  iconSize,
  className,
  hideRejectButton,
  hideApproveButton,
  rejectButtonText,
  rejectButtonClass,
  approveButtonText,
  approveButtonClass,
  rejectButtonOnClick,
  approveButtonOnClick,
  disableApproveButton,
  loading,
  children,
  ...props
}) => {
  return (
    <div className="alert-box fixed top-0 left-0 w-full h-full flex items-center justify-center bg-[#344054b3] p-10 z-[100]">
      <div className={`${className} max-w-full wrapper bg-white p-6 shadow-xl rounded-xl overflow-hidden`}>
        <div className="header">
          {iconName && (
            <div
              className="icon-box inline-flex p-[14px] rounded-full mb-5"
              style={{ backgroundColor: iconBgColor }}
            >
              <Icon
                name={iconName}
                size={iconSize}
                color={iconColor}
              />
            </div>
          )}
          {title && <p className="text-gray-900 font-medium text-lg">{title}</p>}
        </div>
        <div className="body max-h-[calc(100vh_-_250px)] mt-2 -mr-2.5 pr-2.5 overflow-auto">
          {description && <p className="text-gray-500 text-sm">{description}</p>}
          {children}
        </div>

        <div className="footer mt-8">
          <div className="btn-group flex w-full gap-3">
            {!hideRejectButton && (
              <button
                onClick={rejectButtonOnClick}
                className={`btn w-full outline ${rejectButtonClass}`}
                disabled={loading}
              >
                {rejectButtonText}
              </button>
            )}
            {!hideApproveButton && (
              <button
                onClick={approveButtonOnClick}
                className={`btn w-full ml-auto ${approveButtonClass}`}
                disabled={loading || disableApproveButton}
              >
                {loading && <LoadBar />}
                {!loading && approveButtonText}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
Alert.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  iconName: PropTypes.string,
  iconSize: PropTypes.string,
  iconColor: PropTypes.string,
  iconBgColor: PropTypes.string,
  hideRejectButton: PropTypes.bool,
  hideApproveButton: PropTypes.bool,
  rejectButtonText: PropTypes.string,
  approveButtonText: PropTypes.string,
  rejectButtonOnClick: PropTypes.func,
  approveButtonOnClick: PropTypes.func,
  disableApproveButton: PropTypes.bool,
}
Alert.defaultProps = {
  className: "w-[392px]",
  title: "",
  description: "",
  iconName: "info",
  iconSize: "20",
  iconColor: "#101828",
  iconBgColor: "#F2F4F7",
  hideRejectButton: false,
  hideApproveButton: false,
  rejectButtonText: "Vazgeç",
  approveButtonText: "Onayla",
  disableApproveButton: false,
}

export default Alert
