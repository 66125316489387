import { NIL as ZERO } from "uuid";
import {getItemRelationObjectIndex} from "./getItemRelationObject";
const findPatternName = (obj, target) =>
	target in obj
		? obj[target]
		: Object.values(obj).reduce((acc, val) => {
			if (acc !== undefined) return acc;
			if (typeof val === 'object') return findPatternName(val, target);
		}, undefined);


const createModel = (allStrategiesModel, pattern) => {
	let copyStrategies = JSON.parse(JSON.stringify(allStrategiesModel))
	if (pattern && !Array.isArray(pattern)){
		const name = findPatternName(pattern, "name")
		let model = copyStrategies.find(model => model?.code === pattern?.code)
		if (pattern?.code.startsWith("new-")){
			copyStrategies = JSON.parse(JSON.stringify(allStrategiesModel))
			model = copyStrategies.find((model) => {
				return model?.name === name && model?.code === ZERO
			})
		}
		if (pattern?.ChildStrategies) {
			const cIndex = getItemRelationObjectIndex(model?.ChildStrategies)
			if (!model?.ChildStrategies[cIndex]?.Childs) model.ChildStrategies[cIndex].Childs = []
			model.ChildStrategies[cIndex].Childs.push(...pattern.ChildStrategies.map((child, index) => createModel(allStrategiesModel, child, index)))
		}
		return model
	} else {
		return pattern?.map((childs) => {
			let model = copyStrategies.find(model => model?.code === childs?.code)
			if (childs?.code.startsWith("new-")){
				copyStrategies = JSON.parse(JSON.stringify(allStrategiesModel))
				model = copyStrategies.find(model => {
					return model?.name === childs?.name && model?.code === ZERO
				})
			}
			if (childs?.ChildStrategies) {
				const cIndex = getItemRelationObjectIndex(model?.ChildStrategies)
				if (!model?.ChildStrategies[cIndex]?.Childs) model.ChildStrategies[cIndex].Childs = []
				model.ChildStrategies[cIndex].Childs.push(...childs.ChildStrategies.map((child, index) => createModel(allStrategiesModel, child, index)))
			}
			return model
		})
	}

}

export const structureJson =  (allStrategiesModel, pattern) => {
	return createModel(allStrategiesModel, pattern?.ChildStrategies)
}
