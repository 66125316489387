// This is initial state for define reducer
const initialState = {
  filter: {
    type: null,
    status: null,
  },
  list: {
    selectedItems: [],
  },
}

const define = (state = initialState, action) => {
  switch (action.type) {
    case "SET_FILTER":
      return {
        ...state,
        filter: action.payload,
      }
    case "SET_SELECTED_ITEMS":
      return {
        ...state,
        list: {
          ...state.list,
          selectedItems: action.payload,
        },
      }
    default:
      return state
  }
}

export default define
