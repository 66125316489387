/* eslint-disable no-sequences */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react"
import { Stack, Button } from "rsuite"
import { ExportData, Icon } from "components"
import { JsonParserHelper } from "utils/jsonParserHelper"
import { useExportReservationList } from "pages/Reports/libs/useReservationList"
import moment from "moment"
import LoadingModal from "./LoadingModal"

const { useExportModal, ExportModal } = ExportData

const useLoadingModal = () => {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return { open, handleOpen, handleClose }
}

const initialFilterState = (tableFilter) => ({
  currentPage: 1,
  requestStatus: tableFilter.requestStatus,
  moduleName: tableFilter.moduleName,
  startDate: moment().utcOffset("+03:00").subtract(1, "years").startOf("day").format("YYYY-MM-DD"),
  endDate: moment().utcOffset("+03:00").startOf("day").format("YYYY-MM-DD"),
})

const ButtonActions = ({ data, selectedRowsData, moduleName, statusContent, tableFilter, isLoading }) => {
  const [filter, setFilter] = useState(initialFilterState(tableFilter))
  const [isLoadingState, setIsLoadingState] = useState(isLoading)
  const [detailData, setDetailData] = useState([])
  const { data: listData, isLoading: isExportLoading } = useExportReservationList(filter)

  const [currentModuleName] = useState(moduleName)
  const [currentStatusContent] = useState(statusContent)

  const loadingModal = useLoadingModal()

  useEffect(() => {
    if (listData?.data?.data) {
      setDetailData(listData?.data?.data)
    }
  }, [listData?.data])

  useEffect(() => {
    if (isExportLoading && isLoadingState) {
      loadingModal.handleOpen()
    } else {
      loadingModal.handleClose()
    }
  }, [isExportLoading, isLoadingState, loadingModal])

  const handleExportClick = () => {
    setIsLoadingState(true)
    setFilter(initialFilterState(tableFilter))
    exportModal.handleOpen()
  }

  const getExportData = (detailData) => {
    return detailData?.map((item) => {
      const content = JsonParserHelper.jsonParseOrUndefined(item.passengerInformations)
      const searchRequest = JsonParserHelper.jsonParseOrUndefined(item.searchRequest)
      const combinedNames = content.map((person) => `${person.Name} ${person.Surname}`).join(", ")
      const bookingKey =
        item.corpBookingRecords && item.corpBookingRecords.length > 0 ? item.corpBookingRecords[0].bookingKey : ""
      return {
        RezNo: bookingKey,
        Ürün: currentModuleName[item?.moduleName],
        OnayDurumu: currentStatusContent[item?.requestStatus]?.text || "", 
        Kişiler: combinedNames,
        SeyahatNedeni: item?.travelReasonName,
        OlusturulmaTarihi: item.createDate,
        Detay: searchRequest?.DestinationName ?? "-",
        Tutar: `₺${item?.totalPrice}`,
      }
    })
  }

  const exportModal = useExportModal(selectedRowsData)
  let exportmodalData = selectedRowsData && getExportData(selectedRowsData)
  if (!selectedRowsData && !isExportLoading) {
    exportmodalData = getExportData(detailData)
  }

  return (
    <>
      <Stack
        direction="column"
        spacing={20}
        alignItems="flex-end"
      >
        <Stack spacing={10}>
          <Button
            className="btn !bg-blue-100"
            onClick={handleExportClick}
          >
            <Icon
              size={20}
              name={"arrow-up-right-from-square"}
              className={"!bg-blue-600"}
            />
          </Button>
        </Stack>
      </Stack>
      {isExportLoading && (
        <LoadingModal
          open={loadingModal.open}
          onClose={loadingModal.handleClose}
        />
      )}
      {!isExportLoading && (
        <ExportModal
          {...exportModal}
          dataHeader={["Rez. No", "Ürün", "Onay Durumu", "Kişiler", "Seyahat Nedeni", "Oluşturulma Tarihi", "Detay", "Tutar"]}
          data={getExportData(detailData)}
          selectedRowData={exportmodalData}
        />
      )}
    </>
  )
}

export default ButtonActions
