import { call, fork, put, takeLatest } from "redux-saga/effects"
import * as actions from "redux/actions/refreshCustomDomainValidationToken"

import { REFRESH_CUSTOM_DOMAIN_VALIDATION_TOKEN_REQUESTED } from "redux/types"
import { API_POST } from "helpers/api"

function* watchRefreshCustomDomainValidationToken() {
  yield takeLatest(REFRESH_CUSTOM_DOMAIN_VALIDATION_TOKEN_REQUESTED, refreshCustomDomainValidationToken)
}

function* refreshCustomDomainValidationToken(action) {
  try {
    const res = yield call(apiRefreshCustomDomainValidationToken, action.payload)
    yield put(actions.refreshCustomDomainValidationTokenSucceed(res?.data))
  } catch (err) {
    yield put(actions.refreshCustomDomainValidationTokenFailed())
  }
}

const apiRefreshCustomDomainValidationToken = (payload) => {
  return API_POST(`${process.env.REACT_APP_DISPLAY_DOMAIN}/api/domain/refreshCustomDomainValidationToken?tenantDomain=${payload}`, null, "application/x-www-form-urlencoded; charset=UTF-8")
}

export default function* refreshCustomDomainValidationTokenGet() {
  yield fork(watchRefreshCustomDomainValidationToken)
}
