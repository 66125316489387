import React from "react"

// components

import MainHeader from "components/Headers/MainHeader"
import { TitleH1, Description } from "components"

// Layouts
import ReservationPolicy from "layouts/ReservationPolicy"

const ReservationSettingsPage = (props) => {
  let { isLogged, AssociatedDomains } = props

  return (
    <>
      <MainHeader isLogged={isLogged} />
      <main>
        <section className="p-10 my-0 mx-auto max-w-[1440px]">
          <div className="page-container">
            <div className="flex flex-col items-center">
              <div className="xl:w-10/12 w-full transition-[width] duration-500">
                <div className="page-top-area flex justify-between items-center mb-6">
                  <div className="page-title">
                    <TitleH1>Rezervasyon Ayarları</TitleH1>
                    <Description>Rezervasyon deneyimini doğrudan etkileyecek ayarları buradan güncelleyebilirsin.</Description>
                  </div>
                </div>

                <div className="bg-white border border-gray-200 rounded-lg text-lg p-8">
                  <div className="flex flex-row bg-white mb-8 border-b border-gray-200">
                    <p className="text-sm font-medium pb-4 border-b border-blue-500 text-blue-500">Rezervasyon Politikası</p>
                  </div>

                  <ReservationPolicy AssociatedDomains={AssociatedDomains} />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}

export default ReservationSettingsPage
