import * as type from "../types"

export const getConfigurationRequested = (data) => ({
  type: type.GET_CONFIGURATON_REQUESTED,
  payload: data,
})

export const getConfigurationSucceed = (data) => ({
  type: type.GET_CONFIGURATON_SUCCEEDED,
  payload: data,
})

export const getConfigurationFailed = () => ({
  type: type.GET_CONFIGURATON_FAILED,
})
