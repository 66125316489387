import moment from "moment"

export const printData = (data, headerArr, isJpeg = false, minWidth) => {
  const headers = headerArr
  const newData = data?.map((item) => {
    const row = Object.values(item).map((value, index) => {
      const header = headers[index]
      if (header?.includes("Check-In") || header?.includes("Check-Out") || header?.includes("Tarihi")) {
        if (value !== null && value !== undefined) {
          return moment(value).format("DD.MM.YYYY")
        } else {
          return "-"
        }
      }
      return value !== null && value !== undefined ? value : "-"
    })
    return row
  })

  const tableHTML = `
    <html>
      <head>
      ${isJpeg ? `<script src="https://cdnjs.cloudflare.com/ajax/libs/html2canvas/1.4.1/html2canvas.min.js"></script>` : ``}
        <style>
          @page {
            size: A4;
            margin: 0;
          }
          body {
            font-family: Arial, sans-serif;
            margin: 3mm;
            ${minWidth ? `min-width: ${minWidth}px;` : ``
          }
          table {
            width: 100%;
            border-collapse: collapse;
            margin-bottom: 10mm;
            font-size: 8pt;
          }
          th, td {
            border: 1px solid black;
            padding: 5px;
            word-break: break-all;
          }
          th {
            background-color: #f2f2f2;
          }
          tr:first-child,
          td:first-child {
            width: 10px;
          }
          td:nth-child(n+2) {
            width: 30px;
          }
        </style>
      </head>
      <body>
        <table id='mainTable'>
          <thead>
            <tr>
              ${headerArr
                .map((column, index) => {
                  return `<th>${column}</th>`
                })
                .join("")}
            </tr>
          </thead>
          <tbody>
            ${newData
              .map(
                (row) =>
                  `<tr>${row
                    .map((value) => {
                      return `<td>${value}</td>`
                    })
                    .join("")}</tr>`,
              )
              .join("")}
          </tbody>
        </table>
        ${
          isJpeg
            ? `<script>
            const fileName = "jpegExport-"+(new Date().getTime())+".jpeg"
  
            function saveAs(blob, fileName="pic") {
              const link = document.createElement('a');
              link.download = fileName
              link.href = URL.createObjectURL(blob);
              link.click();
              URL.revokeObjectURL(link.href);
              window.close();
            }
  
            html2canvas(document.querySelector("#mainTable")).then(canvas => {
                canvas.toBlob((blob)=> {
                  console.log(blob)
                  saveAs(blob, fileName)
                })
            })
          </script>`
            : ``
        }
      </body>
    </html>
  `

  const printWindow = window.open("", "_blank")
  printWindow.document.open()
  printWindow.document.write(tableHTML)
  printWindow.document.close()
  if (!isJpeg) printWindow.print()
}
