import * as type from "redux/types"

export const refreshCustomDomainValidationTokenRequested = (data) => ({
  type: type.REFRESH_CUSTOM_DOMAIN_VALIDATION_TOKEN_REQUESTED,
  payload: data,
})

export const refreshCustomDomainValidationTokenSucceed = (data) => ({
  type: type.REFRESH_CUSTOM_DOMAIN_VALIDATION_TOKEN_SUCCEEDED,
  payload: data,
})

export const refreshCustomDomainValidationTokenFailed = () => ({
  type: type.REFRESH_CUSTOM_DOMAIN_VALIDATION_TOKEN_FAILED,
})
