import * as type from "redux/types"

export const clientUserGetRequested = (data) => ({
  type: type.CLIENT_USER_GET_REQUESTED,
  payload: data,
})

export const clientUserGetSucceed = (data) => ({
  type: type.CLIENT_USER_GET_SUCCEEDED,
  payload: data,
})

export const clientUserGetFailed = () => ({
  type: type.CLIENT_USER_GET_FAILED,
})
