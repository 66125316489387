import * as type from "../types"

const initialState = {
  result: null,
  loading: false,
  error: false,
}

const listConfiguration = (state = initialState, action) => {
  switch (action.type) {
    case type.LIST_CONFIGURATON_REQUESTED:
      return {
        ...state,
        loading: true,
        error: false,
      }
    case type.LIST_CONFIGURATON_SUCCEEDED:
      return {
        ...state,
        result: action.payload,
        loading: false,
        error: false,
      }
    case type.LIST_CONFIGURATON_FAILED:
      return {
        ...state,
        result: null,
        loading: false,
        error: true,
      }
    default:
      return state
  }
}

export default listConfiguration
