import * as type from "redux/types"

export const userInfoRequested = (data) => ({
  type: type.USER_INFO_STATE_REQUESTED,
  payload: data,
})

export const userInfoSucceed = (data) => ({
  type: type.USER_INFO_STATE_SUCCEEDED,
  payload: data,
})

export const userInfoFailed = () => ({
  type: type.USER_INFO_STATE_FAILED,
})
