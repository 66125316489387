import * as type from "redux/types"

export const agreementRequested = (data) => ({
  type: type.GET_AGREEMENT_REQUESTED,
  payload: data,
})

export const agreementSucceed = (data) => ({
  type: type.GET_AGREEMENT_SUCCEEDED,
  payload: data,
})

export const agreementFailed = () => ({
  type: type.GET_AGREEMENT_FAILED,
})
