import React from "react"

const IntegrationSettinsSkeleton = () => {
  return (
    <main>
      <section className="p-10 my-0 mx-auto flex flex-col max-w-[1440px]">
        <div className="animate-pulse space-y-3 h-2 bg-slate-200 rounded col-span-1 w-1/3 my-10" />
        <div className="flex bg-white border border-solid border-gray-200 justify-between p-12 mb-10 gap-24 w-full h-[430px]">
          <div className="flex flex-col w-1/2 justify-center">
            <div className="animate-pulse space-y-3 my-7">
              <div className="h-2 bg-slate-200 rounded w-1/3" />
              <div className="h-2 bg-slate-200 rounded" />
              <div className="h-2 bg-slate-200 rounded" />
            </div>
            <div className="animate-pulse space-y-3 my-7">
              <div className="h-2 bg-slate-200 rounded w-1/3" />
              <div className="h-2 bg-slate-200 rounded" />
              <div className="h-2 bg-slate-200 rounded" />
            </div>
            <div className="animate-pulse space-y-3 my-7">
              <div className="h-2 bg-slate-200 rounded w-1/3" />
              <div className="h-2 bg-slate-200 rounded" />
              <div className="h-2 bg-slate-200 rounded" />
            </div>
          </div>
          <div className="flex flex-col justify-center w-1/2">
            <div className="animate-pulse space-y-3 my-12">
              <div className="h-2 bg-slate-200 rounded w-1/3" />
              <div className="bg-slate-200 rounded h-[293px]" />
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default IntegrationSettinsSkeleton
