import React, { useState, useEffect } from "react"
import { Tooltip } from "react-tippy"
import { Icon } from "components"
import { Col, Grid, Row, Placeholder, SelectPicker, Input, InputGroup } from "rsuite"
import MainHeader from "components/Headers/MainHeader"
import { useNavigate } from "react-router"
import { useReservationList } from "pages/Reports/libs/useReservationList"
import DataGrid, { Column, FilterRow, Selection, Sorting, Toolbar, Item } from "devextreme-react/data-grid"
import { Link } from "react-router-dom"
import { JsonParserHelper } from "utils/jsonParserHelper"
import Pagination from "components/Pagination"
import { useProductList } from "pages/Reports/libs/useProductList"
import ReservationReportsNoData from "./components/ReservationReportsNoData"
import ButtonActions from "components/Reservation/ButtonActions"
import { useDebounceCallback } from "usehooks-ts"

const ReservationReports = (props) => {
  const { isLogged } = props
  const navigate = useNavigate()
  const [TableDraggableLines] = useState(false)
  const [recordsTotal, setRecordsTotal] = useState()
  const [detailData, setDetailData] = useState([])
  const [productListData, setProductListData] = useState()
  const [moduleNameChange, setModuleNameChange] = useState()
  const [selectedRowsData, setSelectedRowsData] = useState(null)
  const [users, setUsers] = useState([{}])
  const [tableFilter, setTableFilter] = useState({
    currentPage: 1,
    requestStatus: null,
    moduleName: null,
    searchTerm: "",
  })
  const [isFilterActive, setIsFilterActive] = useState(false)
  const [searchTerm, setSearchTerm] = useState(tableFilter.searchTerm)
  const { data, isLoading, isSuccess } = useReservationList(tableFilter)
  const { data: useProductListData } = useProductList()

  const handleOnNavigate = (path = "") => navigate("/reports/" + path)

  const StatusContent = {
    0: {
      text: "Onay Bekliyor",
      color: "bg-warning-100 text-warning-500",
    },
    1: {
      text: "Onaylandı",
      color: "bg-green-100 text-green-500",
    },
    2: {
      text: "Reddedildi",
      color: "bg-error-100 text-error-500",
    },
    3: {
      text: "İptal Edildi",
      color: "bg-error-100 text-error-500",
    },
    4: {
      text: "Tamamlanmadı",
      color: "bg-warning-100 text-warning-500",
    },
    5: {
      text: "Bilinmeyen Durum",
      color: "bg-error-100 text-error-500",
    },
    6: {
      text: "İptal Edildi",
      color: "bg-error-100 text-error-500",
    },
  }

  const Status = [
    {
      text: "Onay Bekliyor",
      status: 0,
    },
    {
      text: "Onaylandı",
      status: 1,
    },
    {
      text: "Reddedildi",
      status: 2,
    },
    {
      text: "İptal Edildi",
      status: 3,
    },
    {
      text: "Tamamlanmadı",
      status: 4,
    },
    {
      text: "Bilinmeyen Durum",
      status: 5,
    },
    {
      text: "İptal Edildi",
      status: 6,
    },
  ]

  const ModuleName = {
    FlightModule: "Uçak",
    HotelModule: "Otel",
    TransferModule: "Transfer",
    CarRentalModule: "Araç Kiralama",
  }

  const tableStates = {
    cellAlignments: "center",
    dataGrid: {
      id: "gridContainer",
      allowColumnReordering: true,
      allowColumnResizing: true,
      columnAutoWidth: true,
      showBorders: true,
      showScrollbar: "always",
      keyExpr: "id",
      noDataText: "Aranılan kriterde rezervasyon bulunamadı.",
      onSelectionChanged: function (e) {
        let selectedRowsData = e.selectedRowsData
        setSelectedRowsData(selectedRowsData)
      },
    },
    paging: {
      defaultPageSize: 10,
    },
    selection: {
      mode: "multiple",
      selectAllMode: "allMode",
      showCheckBoxedMode: "always",
    },
  }

  useEffect(() => {
    if (tableFilter.requestStatus || tableFilter.moduleName || tableFilter.searchTerm) {
      setIsFilterActive(true)
    } else {
      setIsFilterActive(false)
    }
    if (data?.data?.data) {
      const enrichedData = data.data.data.map((item) => {
        const bookingKey =
          item.corpBookingRecords && item.corpBookingRecords.length > 0 ? item.corpBookingRecords[0].bookingKey : ""

        return { ...item, bookingKey }
      })
      setRecordsTotal(data.data.recordsTotal)
      setDetailData(enrichedData)
    }
  }, [data?.data, tableFilter])

  const handlePreviewChange = () => {
    tableFilter.currentPage > 1 && setTableFilter((prev) => ({ ...prev, currentPage: prev.currentPage - 1 }))
  }

  const handleMoreChange = () => {
    setTableFilter((prev) => ({ ...prev, currentPage: prev.currentPage + 1 }))
  }

  const handleFilterChange = (key, value) => {
    setTableFilter((prev) => ({
      ...prev,
      currentPage: 1,
      [key]: value,
    }))
  }

  const debounce = useDebounceCallback((value) => handleFilterChange("searchTerm", value), 1000)

  useEffect(() => {
    if (useProductListData?.data) {
      setProductListData(useProductListData.data.result)
    }
  }, [useProductListData?.data])

  return (
    <>
      <MainHeader isLogged={isLogged} />
      <main>
        <section className="p-10 my-0 mx-auto max-w-[1920px] overflow-auto">
          <span
            className="!text-gray-600 flex justify-start items-center gap-2 mt-2 mb-8 cursor-pointer w-fit transition-all hover:drop-shadow"
            onClick={() => handleOnNavigate()}
          >
            <Icon
              name="arrow-left"
              size="20"
              className="!bg-gray-500"
            />
            <h4>Raporlara Dön</h4>
          </span>
          {isLoading && (
            <div className="flex flex-col gap-10 items-start justify-start  w-full h-full">
              <Placeholder.Paragraph
                className="w-full"
                active
              />
              <Placeholder.Graph
                active
                className="w-full h-full"
              />
            </div>
          )}

          {!isLoading && ((data?.data?.data || []).length !== 0 || isFilterActive) && isSuccess && (
            <Grid fluid>
              <Row>
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}
                >
                  <p className="text-gray-800 font-semibold text-3xl">Rezervasyon Raporu</p>
                  <p className="text-gray-500 text-sm font-thin">
                    Rezervasyonlarınızla ilgili bilgileri bu alandan görüntüleyin.
                  </p>
                </Col>

                <Col
                  xs={24}
                  xl={12}
                  className="my-3"
                >
                  <ButtonActions
                    isLoading={isLoading}
                    data={detailData}
                    users={users}
                    moduleName={ModuleName}
                    statusContent={StatusContent}
                    selectedRowsData={selectedRowsData}
                    tableFilter={tableFilter}
                  />
                </Col>
              </Row>
              <Row className="mt-5">
                <Col xs={24}>
                  <DataGrid
                    key={JSON.stringify(detailData)}
                    className={`${TableDraggableLines ? "draggable-active" : ""} filters-active rule-grid`}
                    {...tableStates.dataGrid}
                    dataSource={detailData}
                    style={{ borderRadius: "!10px" }}
                  >
                    {!TableDraggableLines && false && <Selection {...tableStates.selection} />}
                    <Selection
                      {...tableStates.selection}
                      showCheckBoxesMode={"always"}
                    />
                    {!TableDraggableLines && <Sorting mode="multiple" />}
                    {!TableDraggableLines && <FilterRow {...tableStates.filterRow} />}

                    <Toolbar>
                      <Item location="before">
                        <InputGroup
                          inside
                          className="!w-[310px] flex items-baseline justify-start gap-2 bg-white"
                        >
                          <InputGroup.Addon className="size-full">
                            <Icon
                              name={"search"}
                              size={"14"}
                              className="text-gray-200"
                            />
                          </InputGroup.Addon>
                          <Input
                            className="text-sm placeholder:text-sm flex items-center"
                            placeholder={"Kişi adı veya rezervasyon numarası arayın"}
                            defaultValue={searchTerm}
                            value={searchTerm}
                            onChange={(value) => {
                              setSearchTerm(value)
                              debounce(value)
                            }}
                          />
                        </InputGroup>
                      </Item>
                      <Item location="after">
                        <div className="flex justify-end gap-2 w-full">
                          <SelectPicker
                            data={
                              Status.map((item) => ({
                                label: item.text,
                                value: item.text,
                              })) || []
                            }
                            style={{ width: 224 }}
                            searchable={false}
                            cleanable={true}
                            value={StatusContent[tableFilter.requestStatus]?.text || ""}
                            placeholder="Onay Durumu"
                            onChange={(value) => {
                              handleFilterChange("requestStatus", Status.find((item) => item.text === value)?.status)
                            }}
                          />
                          <SelectPicker
                            data={
                              productListData?.map((item) => ({
                                label: item.text,
                                value: item.text,
                              })) || []
                            }
                            style={{ width: 224 }}
                            searchable={false}
                            cleanable={true}
                            value={moduleNameChange || ""}
                            placeholder="Ürün"
                            onChange={(value) => {
                              setModuleNameChange(value)
                              handleFilterChange("moduleName", value ? `${value}Module` : null)
                            }}
                          />
                        </div>
                      </Item>
                    </Toolbar>

                    <Column
                      caption="Rez. No"
                      dataField="bookingKey"
                      allowFiltering={true}
                      allowHeaderFiltering={false}
                      width={"12%"}
                      cellRender={(row) => (
                        <Link
                          to={row.data?.bookingKey ? `/reports/reservation-reports/${row.data.id}` : "#"}
                          className="px-3 text-[#2E90FA]"
                        >
                          {row.data?.bookingKey || "-"}
                        </Link>
                      )}
                    />
                    <Column
                      caption="Ürün"
                      dataField=""
                      width={"12%"}
                      allowFiltering={true}
                      allowHeaderFiltering={false}
                      cellRender={(row) => {
                        const content = ModuleName[row.data?.moduleName]
                        return <div className="px-3">{content ?? "-"}</div>
                      }}
                      alignment={"left"}
                    />
                    <Column
                      caption="Onay Durumu"
                      dataField="requestStatus"
                      width={"12%"}
                      cellRender={(row) => {
                        const content = StatusContent[row.data?.requestStatus]
                        return (
                          <div className="h-[56px] flex items-center ">
                            <div className={`${content?.color} p-1 h-[22px] max-h-[45px] px-2 rounded-xl leading-[14px]`}>
                              {content?.text}
                            </div>
                          </div>
                        )
                      }}
                      allowFiltering={true}
                      allowHeaderFiltering={false}
                      alignment={"left"}
                    />
                    <Column
                      caption="Kişiler"
                      width={"12%"}
                      dataField="passengerNames"
                      cellRender={(row) => {
                        const content = JsonParserHelper.jsonParseOrUndefined(row.data.passengerInformations)
                        const numberOfPeople = content.length
                        const combinedNames = content.map((person) => `${person.Name} ${person.Surname}`).join(", ")
                        setUsers(combinedNames)

                        return (
                          <div className="px-3 flex flex-col !mt-[-7px]">
                            <span className="!mb-[-35px] text-gray-500">{numberOfPeople} kişi</span>
                            <span className="line-clamp-3">
                              <Tooltip
                                title={combinedNames}
                                position="top-start"
                                arrow="true"
                                className="cursor-pointer"
                              >
                                {combinedNames}
                              </Tooltip>
                            </span>
                          </div>
                        )
                      }}
                      allowFiltering={true}
                      allowSearch={true}
                      allowHeaderFiltering={false}
                      alignment={"left"}
                    />
                    <Column
                      caption="Seyahat Nedeni"
                      dataField=""
                      allowFiltering={true}
                      allowHeaderFiltering={false}
                      width={"12%"}
                      cellRender={(row) => <div className="px-3">{row.data.travelReasonName ?? "-"}</div>}
                      alignment={"left"}
                    />
                    <Column
                      caption="Oluşturulma Tarihi"
                      dataField="createDate"
                      dataType="date"
                      width={"12%"}
                      allowFiltering={true}
                      allowHeaderFiltering={false}
                      cellRender={(row) => <div className="px-3">{row.text ?? "-"}</div>}
                      alignment={"left"}
                    />
                    <Column
                      caption="Detay"
                      dataField=""
                      allowFiltering={false}
                      width={"12%"}
                      allowHeaderFiltering={true}
                      cellRender={(row) => {
                        const content = JsonParserHelper.jsonParseOrUndefined(row.data.searchRequest)
                        return (
                          <Tooltip
                            title={content.DestinationName}
                            position="top-start"
                            arrow="true"
                            className="cursor-pointer"
                          >
                            <div className="line-clamp-3 mx-3">{content.DestinationName ?? "-"}</div>
                          </Tooltip>
                        )
                      }}
                    />
                    <Column
                      caption="Tutar"
                      width={"12%"}
                      dataField="totalPrice"
                      allowFiltering={true}
                      allowHeaderFiltering={false}
                      cellRender={(row) =>
                        Intl.NumberFormat("tr-TR", {
                          style: "currency",
                          currency: "TRY",
                        }).format(row.text)
                      }
                      alignment={"left"}
                    />
                  </DataGrid>
                  <Pagination
                    currentPage={tableFilter.currentPage}
                    recordsTotal={recordsTotal}
                    handleMoreChange={handleMoreChange}
                    handlePreviewChange={handlePreviewChange}
                  />
                </Col>
              </Row>
            </Grid>
          )}

          {!isLoading && (data?.data?.data || []).length === 0 && !isFilterActive && <ReservationReportsNoData />}

          <style jsx>{`
            #gridContainer .dx-row.dx-data-row.dx-column-lines {
              height: 56px;
            }
            .dx-widget .dx-datagrid .dx-row.dx-data-row.dx-column-lines > td {
              line-height: 56px;
              padding: 0px;
            }
            .dx-datagrid-content .dx-datagrid-table .dx-row .dx-editor-cell:not([aria-label="Filter cell"]):before {
              background: none !important;
            }
            .dx-datagrid-content .dx-datagrid-table .dx-row .dx-editor-cell:not([aria-label="Filter cell"]) {
              border-right: none !portant;
            }
          `}</style>
        </section>
      </main>
    </>
  )
}

export default ReservationReports
