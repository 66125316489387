import { TitleH1, Description, Icon } from "components"
import MainHeader from "components/Headers/MainHeader"

export const Docs = (props) => {
  let { isLogged } = props
  return (
    <>
      <MainHeader isLogged={isLogged} />
      <main>
        <section className="p-10 my-0 mx-auto max-w-[1920px] overflow-auto">
          <div className="page-container">
            <div className="flex flex-col items-center">
              <div className="w-full duration-500">
                <div className="page-top-area flex items-center mb-6">
                  <div className="page-title">
                    <TitleH1>Dokümantasyon</TitleH1>
                    <Description>Kod yazma kuralları, düzeni ve bileşenler hakkında tüm bilgiler.</Description>
                  </div>
                </div>

                <div className="bg-white border border-gray-200 rounded-lg text-lg p-8">
                  <div className="p-5">
                    <Icon
                      name="home"
                      color="#F97C1C"
                      size="24"
                    />
                  </div>

                  <p className="text-xs">text-xs (12px - 18px)</p>
                  <p className="text-sm">text-sm (14px - 20px)</p>
                  <p className="text-base">text-base (16px - 24px)</p>
                  <p className="text-lg">text-lg (18px - 28px)</p>
                  <p className="text-xl">text-xl (20px - 30px)</p>
                  <p className="text-2xl">text-2xl (24px - 32px)</p>
                  <p className="text-3xl">text-3xl (30px - 38px)</p>
                  <p className="text-4xl">text-4xl (36px - 64px)</p>
                  <p className="text-5xl">text-5xl (48px - 20px)</p>
                  <p className="text-6xl">text-6xl (60px - 72px)</p>
                  <p className="text-7xl">text-7xl (72px - 90px)</p>

                  <p className="font-base">font-base/regular (400)</p>
                  <p className="font-medium">font-medium (500)</p>
                  <p className="font-semibold">font-semibold (600)</p>
                  <p className="font-bold">font-bold (700)</p>

                  <div>
                    <div className="flex flex-wrap gap-2 mb-6">
                      <input type="text" />

                      <div className="input-group error">
                        <input type="text" />
                        <small>This is a error message.</small>
                      </div>

                      <input
                        type="text"
                        value="Jhon Doe"
                        disabled
                      />

                      <div className="input-group icon error">
                        <span>🌹</span>
                        <input type="text" />
                      </div>
                    </div>

                    <div className="flex flex-wrap gap-2 mb-6">
                      <button className="btn xs">Primary Button</button>
                      <button className="btn sm">Primary Button</button>
                      <button className="btn">Primary Button</button>
                      <button className="btn lg">Primary Button</button>
                      <button className="btn xl">Primary Button</button>
                    </div>

                    <div className="flex flex-wrap gap-2 mb-6">
                      <button className="btn xs disabled">Primary Button</button>
                      <button className="btn sm disabled">Primary Button</button>
                      <button className="btn disabled">Primary Button</button>
                      <button className="btn lg disabled">Primary Button</button>
                      <button className="btn xl disabled">Primary Button</button>
                    </div>

                    <div className="flex flex-wrap gap-2 mb-6">
                      <button className="btn xs secondary">Secondary Button</button>
                      <button className="btn sm secondary">Secondary Button</button>
                      <button className="btn secondary">Secondary Button</button>
                      <button className="btn lg secondary">Secondary Button</button>
                      <button className="btn xl secondary">Secondary Button</button>
                    </div>

                    <div className="flex flex-wrap gap-2 mb-6">
                      <button className="btn xs secondary disabled">Secondary Button</button>
                      <button className="btn sm secondary disabled">Secondary Button</button>
                      <button className="btn secondary disabled">Secondary Button</button>
                      <button className="btn lg secondary disabled">Secondary Button</button>
                      <button className="btn xl secondary disabled">Secondary Button</button>
                    </div>

                    <div className="flex flex-wrap gap-2 mb-6">
                      <button className="btn xs outline">Outline Button</button>
                      <button className="btn sm outline">Outline Button</button>
                      <button className="btn outline">Outline Button</button>
                      <button className="btn lg outline">Outline Button</button>
                      <button className="btn xl outline">Outline Button</button>
                    </div>

                    <div className="flex flex-wrap gap-2 mb-6">
                      <button className="btn xs outline disabled">Outline Button</button>
                      <button className="btn sm outline disabled">Outline Button</button>
                      <button className="btn outline disabled">Outline Button</button>
                      <button className="btn lg outline disabled">Outline Button</button>
                      <button className="btn xl outline disabled">Outline Button</button>
                    </div>

                    <div className="flex flex-wrap gap-2 mb-6">
                      <button className="btn xs ghost">Ghost Button</button>
                      <button className="btn sm ghost">Ghost Button</button>
                      <button className="btn ghost">Ghost Button</button>
                      <button className="btn lg ghost">Ghost Button</button>
                      <button className="btn xl ghost">Ghost Button</button>
                    </div>

                    <div className="flex flex-wrap gap-2 mb-6">
                      <button className="btn xs ghost disabled">Ghost Button</button>
                      <button className="btn sm ghost disabled">Ghost Button</button>
                      <button className="btn ghost disabled">Ghost Button</button>
                      <button className="btn lg ghost disabled">Ghost Button</button>
                      <button className="btn xl ghost disabled">Ghost Button</button>
                    </div>

                    <div className="flex flex-wrap gap-2 mb-6">
                      <button className="btn xs text">Text Button</button>
                      <button className="btn sm text">Text Button</button>
                      <button className="btn text">Text Button</button>
                      <button className="btn lg text">Text Button</button>
                      <button className="btn xl text">Text Button</button>
                    </div>

                    <div className="flex flex-wrap gap-2 mb-6">
                      <button className="btn xs text disabled">Text Button</button>
                      <button className="btn sm text disabled">Text Button</button>
                      <button className="btn text disabled">Text Button</button>
                      <button className="btn lg text disabled">Text Button</button>
                      <button className="btn xl text disabled">Text Button</button>
                    </div>

                    <div className="flex flex-wrap gap-2 mb-6">
                      <button className="btn xs square">🌹</button>
                      <button className="btn sm square">🌹</button>
                      <button className="btn square">🌹</button>
                      <button className="btn lg square">🌹</button>
                      <button className="btn xl square">🌹</button>
                    </div>
                  </div>

                  <h2>Kod Düzeni</h2>

                  <section>
                    <p>
                      <strong>import</strong>'lar belli bir hiyerarşi ile eklenecek.
                    </p>

                    <pre className="mt-5 bg-gray-200">
                      <code>
                        <p className="bg-red-500">Önce package'ler</p>
                        {`import React from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter, Routes, Route } from "react-router-dom"

`}

                        <p className="bg-red-500">Sonra proje içindeki componentler</p>
                        {`import {Button, Modal} from "components"

`}

                        <p className="bg-red-500">En son stil yada görsel vb</p>
                        {`import image from "img/placeholder.png" import "index.css"`}
                      </code>
                    </pre>

                    <p className="mt-10">
                      Kod içerisinde useState, useEffect, fonksiyon vb her şey <strong>#region - #endregion</strong> ile
                      gruplandırılacak.
                    </p>

                    <pre>
                      <code>
                        {`#region useState
const [a, setA] from useState("")
const [b, setB] from useState("")
const [c, setC] from useState("")
#endregion`}
                      </code>
                    </pre>
                  </section>

                  <section>
                    Projede <strong>../../../components/A</strong> gibi importlar kullanılmayacak. import yolu direkt olarak{" "}
                    <strong>src</strong> klasöründen başlıyor. Yani <strong>import A from "components/A"</strong> yapmak kafi.
                  </section>

                  <section>
                    <h2>Kodu yollamadan önce</h2>
                    <ul>
                      <li>1. "./" stringi arat ve olmadığından emin ol</li>
                      <li>2. Yorum satırında kod, kullanılmayan değişken vb ve console.log bırakma</li>
                      <li>3. Kod hiyerarşisini kontrol et</li>
                    </ul>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}

export default Docs
