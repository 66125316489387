import * as type from "redux/types"

export const allAccountListRequested = (data) => ({
  type: type.GET_ALL_ACCOUNT_LIST_REQUESTED,
  payload: data,
})

export const allAccountListSucceed = (data) => ({
  type: type.GET_ALL_ACCOUNT_LIST_SUCCEEDED,
  payload: data,
})

export const allAccountListFailed = () => ({
  type: type.GET_ALL_ACCOUNT_LIST_FAILED,
})
