import { useEffect } from "react"

export const useDebouncedEffect = (effect, deps, delay) => {
  useEffect(() => {
    if (deps && deps[0].length) {
      const handler = setTimeout(() => effect(), delay)
      return () => clearTimeout(handler)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...(deps || []), delay])
}
