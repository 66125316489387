import * as type from "redux/types"

export const addonSaveRequested = (data) => ({
  type: type.ADDON_SAVE_REQUESTED,
  payload: data,
})

export const addonSaveSucceed = (data) => ({
  type: type.ADDON_SAVE_SUCCEEDED,
  payload: data,
})

export const addonSaveFailed = () => ({
  type: type.ADDON_SAVE_FAILED,
})
