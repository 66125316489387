import React, {useEffect} from "react"
import Header from "components/Headers/MainHeader"
import { Grid, Row, Col } from "rsuite"
import DataList from "components/Settings/UserManagement/DataList"
import NoData from "components/Settings/UserManagement/NoData"
import ButtonActions from "components/Settings/UserManagement/ButtonActions"
import Icon from "components/Icon"
import { useNavigate } from "react-router"
import {useDispatch, useSelector} from "react-redux";
import {allAccountListRequested} from "../../../redux/actions/getAllAccountList";
import MyReservationSkeleton from "../../../skeletons/MyReservation";
import {ErrorMessage} from "../../../components";

const UserList = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  let { isLogged } = props

  const allAccountList = useSelector(state => state.getAllAccountList.list)
  const allAccountListLoading = useSelector(state => state.getAllAccountList.loading)
  const allAccountListError = useSelector(state => state.getAllAccountList.error)

  const [SelectedRowsData, setSelectedRowsData] = React.useState(null)

  const handleOnNavigate = (path = "") => navigate("/settings/" + path)

  useEffect(() => {
    dispatch(allAccountListRequested())
  }, [dispatch])

  return (
    <>
      <Header isLogged={isLogged} />
      <main>
        <section className="p-10 my-0 mx-auto max-w-[1440px]">
          <span
            className="!text-gray-600 flex justify-start items-center gap-2 mt-2 mb-8 cursor-pointer w-fit transition-all hover:drop-shadow"
            onClick={() => handleOnNavigate()}
          >
            <Icon
              name="arrow-left"
              size="20"
              className="!bg-gray-500"
            />
            <h4>Ayarlara Dön</h4>
          </span>
          <Grid fluid>
            <Row gutter={10}>
              <Col
                xs={24}
                xl={16}
                className="mb-3"
              >
                <h1 className="text-3xl text-gray-800 font-semibold mb-1">Kullanıcı Yönetimi</h1>
                <p className="text-gray-500">Sisteme giriş yapabilecek kullanıcıları görüntüleyin ve yönetin.</p>
              </Col>
              <Col
                xs={24}
                xl={8}
                className="my-3"
              >
                {allAccountList && !allAccountListError && !allAccountListLoading && <ButtonActions data={allAccountList} selectedRowsData={SelectedRowsData} />}
              </Col>
              {allAccountListLoading && <MyReservationSkeleton className="!p-0" />}
              {!allAccountList.length === 0 && <NoData /> }
              {allAccountListError && !allAccountListLoading && allAccountList.length === 0 && <Col
                  xs={24}
                  className="my-3"
              >
                <ErrorMessage className="mt-1">kullanıcı listesi yuklenirken bir hata ile karşılaşıldı.</ErrorMessage>
              </Col> }

              {allAccountList.length > 0 && !allAccountListError && !allAccountListLoading && <DataList data={allAccountList} setSelectedRowsData={setSelectedRowsData} /> }
            </Row>
          </Grid>
        </section>
      </main>
    </>
  )
}

export default UserList
