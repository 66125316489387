import * as type from "redux/types"

export const addonListRequested = (data) => ({
  type: type.ADDON_LIST_REQUESTED,
  payload: data,
})

export const addonListSucceed = (data) => ({
  type: type.ADDON_LIST_SUCCEEDED,
  payload: data,
})

export const addonListFailed = () => ({
  type: type.ADDON_LIST_FAILED,
})
