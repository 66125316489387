import {getEntiredImages} from "./GetEntiredImages";
import {dataUrlToFile} from "helpers/DataUrlToFile";
import {apiPostMediaLibrarySave} from "redux/sagas/mediaLibrary";
import {MediaType} from "constants/index";
import AddIconListPattern from "../Patterns/IconList/AddIconListPattern";

export const modifyIconListByNewData = async (thisComponent, model, imageKey) => {

	const mediaIdList = await thisComponent?.children && Promise.all(thisComponent?.children.map(async (child, itemIndex) => {
		const indexData = {
			cIndex: itemIndex,
		}
		const hasImage = getEntiredImages(child?.data, imageKey)
		const alreadyUploadedImage = !!hasImage && hasImage?.startsWith("https://")
		if (hasImage && !alreadyUploadedImage) {
			return await dataUrlToFile(hasImage, `no_name_${(new Date().toJSON().slice(0, 10))}.png`, {type: "image/png"}).then(file => {
				let formData = new FormData()
				formData.append("File", file)
				return apiPostMediaLibrarySave(formData).then(async response => {
					return {
						...indexData,
						mediaId: response?.data?.result?.id
					}
				})
			})
		} else {
			return  {
				...indexData,
				mediaId: null
			}
		}

	}))


	return AddIconListPattern(model, thisComponent, await mediaIdList, MediaType.Image)

}