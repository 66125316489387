/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Alert} from "components";
import {pageDeleteRequested, pageDeleteSucceed} from "redux/actions/pageDelete";
import getPageList from "../../helpers/GetPageList";

const DeletePathAlert = (props) => {
	const {state, setState, TableSearchQuery, ActivePageTypeDropdown, ActivePageStatusDropdown, onSuccess, onError, noListRequest} = props
	const dispatch = useDispatch()

	const deletePage = useSelector((state) => state.pageDelete.response)
	const deletePageLoading = useSelector((state) => state.pageDelete.loading)
	const deletePageError = useSelector((state) => state.pageDelete.error)

	const getPageDelete = () => {
		const {Id} = state
		dispatch(pageDeleteRequested(Id))
	}

	useEffect(() => {
		if (deletePage || deletePageError){
			const staticErrorMessage = "silme işlemi sırasında bir sorun oluştu, lütfen tekrar deneyiniz."
			if (deletePage?.Success) {
				dispatch(pageDeleteSucceed(null))
				setState(null)
				if (!noListRequest) getPageList(dispatch, TableSearchQuery, ActivePageTypeDropdown, ActivePageStatusDropdown)
				if(onSuccess){
					onSuccess(true)
				}
			} else if(!deletePage?.Success){
				setState({
					...state,
					errorMessage: (deletePage?.Errors && deletePage?.Errors[0]?.Message) || staticErrorMessage
				})
				if(onError){
					onError(true)
				}
			} else if (deletePageError){
				setState({
					...state,
					errorMessage: staticErrorMessage
				})
				if(onError){
					onError(true)
				}
			}
		}
	}, [deletePage, deletePageError])

	return (
		<>
			{state && (
				<Alert
					iconName={"trash"}
					approveButtonText={`${!state?.errorMessage ? "Sil" : "Tekrar dene"}`}
					title={`Sayfa Silme`}
					rejectButtonOnClick={() => {
						dispatch(pageDeleteSucceed(null))
						setState(null)
					}}
					approveButtonOnClick={getPageDelete}
					approveButtonClass={"danger"}
					loading={deletePageLoading}
				>
					<p className="text-sm text-gray-500">
						<code className="text-red-500"><b>{state?.Name}</b></code>
						&nbsp;isimli ve
						&nbsp;<code className="text-red-500"><b>{state?.Path}</b></code>
						&nbsp;url adresli {state?.errorMessage ? state?.errorMessage.toLowerCase() || `kayıt silinirken bir hata oluştu` : `kaydı silmek istediğinize emin misiniz?`}</p>
				</Alert>
			)}
		</>
	)
}


export default DeletePathAlert