import * as type from "redux/types"

export const pageUpdateRequested = (data) => ({
  type: type.PAGE_UPDATE_REQUESTED,
  payload: data,
})

export const pageUpdateSucceed = (data) => ({
  type: type.PAGE_UPDATE_SUCCEEDED,
  payload: data,
})

export const pageUpdateFailed = () => ({
  type: type.PAGE_UPDATE_FAILED,
})
