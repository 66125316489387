import React, { useEffect, useState } from "react"
import Tab from "../Tab"
import PropTypes from "prop-types"

// utils
import getSelectedIndex from "utils/getSelectedIndex"

const TabList = (props) => {
  let { wrapperClassName, tabs, tabClassName, itemActiveClass, activeTab, onChange, iconSize, ...args } = props

  const [ActiveTab, setActiveTab] = useState(0)
  const tabChanged = (e) => {
    let selectedIndex = getSelectedIndex(e)
    onChange(e)
    setActiveTab(selectedIndex)
  }

  useEffect(() => {
    setActiveTab(activeTab)
  }, [activeTab])

  return (
    <ul
      className={`tab-list ${wrapperClassName}`}
      onChange={onChange}
      {...args}
    >
      {tabs.length &&
        tabs.map((item, index) => {
          return (
            <Tab
              label={item}
              value={index}
              key={index}
              active={ActiveTab}
              itemActiveClass={itemActiveClass}
              className={tabClassName}
              iconSize={iconSize}
              disabled={item?.disabled}
              onClick={tabChanged}
            />
          )
        })}
    </ul>
  )
}

export default TabList

TabList.propTypes = {
  onChange: PropTypes.func,
  wrapperClassName: PropTypes.string,
  tabClassName: PropTypes.string,
  itemActiveClass: PropTypes.string,
  tabs: PropTypes.array.isRequired,
  activeTab: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  iconSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

TabList.defaultProps = {
  wrapperClassName: "bg-white",
  tabClassName: "",
  itemActiveClass: "!text-gray-800 !bg-blue-50 font-medium",
  tabs: [{ label: "tab 1 example" }],
  activeTab: 0,
  iconSize: 18,
  onChange: () => alert("Lütfen bir onchange methodu ekleyiniz"),
}
