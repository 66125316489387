import ReservationReportsDetailInfo from "./components/ReservationReportsDetailInfo"
import ReservationReportsDetailFlightInfo from "./components/ReservationReportsDetailFlight/ReservationReportsDetailFlightInfo"
import ReservationReportsDetailTransferInfo from "./components/ReservationReportsDetailTransfer/ReservationReportsDetailTransferInfo"
import ReservationReportsDetailCarRentalInfo from "./components/ReservationReportsDetailCarRental/ReservationReportsDetailCarRentalInfo"

const ReservationReportDetailInfos = ({ detailData, displayInformations, reservationData, searchRequest }) => {
  switch (detailData?.moduleName) {
    case "HotelModule":
      return (
        <ReservationReportsDetailInfo
          data={detailData}
          displayInformations={displayInformations}
          reservationData={reservationData}
          searchRequest={searchRequest}
        />
      )
    case "FlightModule":
      return (
        <ReservationReportsDetailFlightInfo
          data={detailData}
          displayInformations={displayInformations}
          reservationData={reservationData}
          searchRequest={searchRequest}
        />
      )
    case "TransferModule":
      return (
        <ReservationReportsDetailTransferInfo
          data={detailData}
          displayInformations={displayInformations}
          reservationData={reservationData}
          searchRequest={searchRequest}
        />
      )
    case "CarRentalModule":
      return (
        <ReservationReportsDetailCarRentalInfo
          data={detailData}
          displayInformations={displayInformations}
          reservationData={reservationData}
          searchRequest={searchRequest}
        />
      )
    default:
      return null
  }
}

export default ReservationReportDetailInfos
