import * as type from "redux/types"

export const associatedDomainsRequested = (data) => ({
  type: type.ASSOCIATED_DOMAINS_REQUESTED,
  payload: data,
})

export const associatedDomainsSucceed = (data) => ({
  type: type.ASSOCIATED_DOMAINS_SUCCEEDED,
  payload: data,
})

export const associatedDomainsFailed = () => ({
  type: type.ASSOCIATED_DOMAINS_FAILED,
})
