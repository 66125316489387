import { call, fork, put, takeLatest } from "redux-saga/effects"
import * as actions from "redux/actions/getAgencyList"

import { GET_AGENCY_LIST_REQUESTED } from "redux/types"
import { API_POST } from "helpers/api"

function* watchGetAgencyList() {
  yield takeLatest(GET_AGENCY_LIST_REQUESTED, getAgencyList)
}

function* getAgencyList(action) {
  try {
    const res = yield call(apiGetAgencyList, action.payload)
    yield put(actions.agencyListSucceed(res?.data?.result?.data))
  } catch (err) {
    yield put(actions.agencyListFailed())
  }
}

const apiGetAgencyList = (payload) => {
  return API_POST(`${process.env.REACT_APP_PANEL_DOMAIN}/api/products/agencylist`, payload)
}

export default function* AgencyList() {
  yield fork(watchGetAgencyList)
}
