import React from "react"
import { Link } from "react-router-dom"

import { Icon, BorderBox } from "components"
import moment from "moment/moment";

const Content = ({ title, typeValue, updateDate, isOpen, toggleDropdown }) => {
  const handleSetActiveDropDownValue = () => () => {
    sessionStorage.setItem("saveAgreementSuccess", false)
  }

  return (
    <BorderBox className="p-8">
      <div className="flex items-center justify-between">
        <h3 className="text-gray-800 text-lg font-semibold">{title}</h3>
        <div className="relative">
          <button
            className="text-blue-500 font-medium"
            onClick={() => {
              toggleDropdown()
            }}
          >
            <Icon
              name="more-vertical"
              size="16"
              color="#101828"
            />
          </button>
          {isOpen && (
            <ul className="absolute right-0 bg-white text-sm border border-solid border-gray-100 shadow-lg mt-1 rounded-lg overflow-hidden text-gray-800 z-10 w-[143px]">
              <li
                className="flex items-center w-full py-4 px-2.5 hover:bg-gray-50 text-left cursor-pointer whitespace-nowrap"
                onClick={handleSetActiveDropDownValue()}
              >
                <Link to={`${window.location.pathname}/view/${typeValue}`}>Görüntüle</Link>
              </li>
              <li
                className="flex items-center w-full py-4 px-2.5 hover:bg-gray-50 text-left cursor-pointer whitespace-nowrap"
                onClick={handleSetActiveDropDownValue()}
              >
                <Link to={`${window.location.pathname}/edit/${typeValue}`}>Düzenle</Link>
              </li>
            </ul>
          )}
        </div>
      </div>
      <div className="line-clamp-5 mt-4 text-gray-500">{updateDate && `${moment(updateDate).format("DD.MM.YYYY")} tarihinde düzenlendi.`}</div>
    </BorderBox>
  )
}

export default Content
