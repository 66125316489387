import { Controller, useFormContext } from "react-hook-form"
import DynamicFormItems from "./DynamicFormItems"
const DynamicFormItemsManager = ({ item, errorMessage, name, formPath, conditionName, hiddenName, required }) => {
  const { control } = useFormContext()

  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={{
          required: !!required ? "Bu alan boş bırakılamaz" : false,
        }}
        render={({ field: { name, onChange } }) => {
          const props = {
            item,
            name,
            placeholder: item?.Title || "Bir değer giriniz",
            onChange,
            formPath,
            conditionName,
            hiddenName,
          }
          return DynamicFormItems[item?.Type || "NumericParameter"](props) || DynamicFormItems["NumericParameter"](props)
        }}
      />
      {errorMessage && required && <p className="text-red-500 text-xs py-1">{errorMessage}</p>}
    </>
  )
}

export default DynamicFormItemsManager
