import { call, fork, put, takeLatest } from "redux-saga/effects"
import * as actions from "redux/actions/clientUserGet"

import { CLIENT_USER_GET_REQUESTED } from "redux/types"
import { API_GET } from "helpers/api"

function* watchGetClientUserGet() {
  yield takeLatest(CLIENT_USER_GET_REQUESTED, getClientUserGet)
}

function* getClientUserGet(action) {
  try {
    const res = yield call(apiGetClientUserGet, action.payload)
    yield put(actions.clientUserGetSucceed(res?.data))
  } catch (err) {
    yield put(actions.clientUserGetFailed())
  }
}

const apiGetClientUserGet = (payload) => {
  return API_GET(`${process.env.REACT_APP_AUTH_DOMAIN}/api/clientaccount/getuser/${payload}`, "", false)
}

export default function* clientUserGet() {
  yield fork(watchGetClientUserGet)
}
