import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux";

// utils
import getSelectedIndex from "utils/getSelectedIndex"

// layouts
import PartialWrapper from "../PartialWrapper"

// components
import {
  TabList,
  AlignmentBlock,
  ColorBox,
  FontSizeBox,
  FontSizeRangeBox,
  FontWeightBox,
  Subtitle,
  TextInput,
  ToggleBox,
  BorderBox,
} from "components"

// helpers
import {UpdateStrategy} from "../../helpers/UpdateStrategy";
import {setSettings} from "helpers/setSettings";

//saga-requests
import {getStrategySucceed} from "redux/actions/strategy";

const TitleLayout = (props) => {
  let { editBlock, setEditBlock, ThisComponent, setThisComponent } = props

  const dispatch = useDispatch()

  //selectors
  let components = useSelector(state => state.strategy.getStrategy.list?.result)

  //states
  const [FontSizeTab, setFontSizeTab] = useState(0)
  const [TitleBlockSettings, setTitleBlockSettings] = useState(ThisComponent?.data?.titleStyle)
  const FontSizeTabData = [{ label: "Gelişmiş" }, { label: "Özel" }]
  const FontSizes = [16, 32, 48]
  const FontSizeStyles = ["base", "lg", "xl"]

  const goBackHandleClick = () => {
    setTitleBlockSettings(null)
    setEditBlock(null)
  }

  const fontSizeTabChanged = (e) => {
    let selectedIndex = getSelectedIndex(e)
    setFontSizeTab(selectedIndex)
  }

  useEffect(() => {
    if (TitleBlockSettings && editBlock?.showTitleLayout){
      setThisComponent({
        ...ThisComponent,
        data: {
          ...ThisComponent?.data,
          ...TitleBlockSettings,
          titleStyle: {
            ...ThisComponent?.data?.titleStyle,
            ...TitleBlockSettings?.titleStyle,
          }
        }
      })
    }
  }, [TitleBlockSettings])

  useEffect(() => {
    if (ThisComponent?.data && editBlock?.showTitleLayout){
      const strategy = UpdateStrategy(components, ThisComponent)
      dispatch(getStrategySucceed(strategy))
    }

  }, [ThisComponent])

  if (!editBlock?.showTitleLayout){
    return false
  }

  return (
    <PartialWrapper
      className={`title-layout${editBlock?.showTitleLayout ? " active" : ""}`}
      goBackOnClick={goBackHandleClick}
    >
      <Subtitle>Başlık</Subtitle>
      <div className="flex flex-col gap-4 mt-4">
        <div className="input-wrapper">
          <ToggleBox
            title={"Başlık Görünürlüğü"}
            checked={ThisComponent?.data?.titleStyle?.display !== "none"}
            onToggle={(e) =>
              setSettings({ target: {
                  name: "display",
                  value: e ? "block" : "none"
                }}, false, TitleBlockSettings, "titleStyle").then((res) => setTitleBlockSettings(res))
            }
          />
        </div>
        <div className="input-wrapper">
          <TextInput
            title={"Başlık"}
            name="title"
            defaultValue={ThisComponent?.data?.title}
            //value={ThisComponent?.data?.title}
            placeholder={"Buraya çarpıcı bir başlık ekleyin!"}
            onChange={e => setSettings(e, true).then((res) => setTitleBlockSettings(res))}
          />
        </div>
        <div className="input-wrapper">
          <ColorBox
            title={"Renk"}
            defaultValue={ThisComponent?.data?.titleStyle?.color}
            name="color"
            onChange={e => setSettings(e, false, TitleBlockSettings, "titleStyle").then((res) => setTitleBlockSettings(res))}
          />
        </div>
        <div className="input-wrapper">
          <TabList
            onChange={fontSizeTabChanged}
            tabs={FontSizeTabData}
            tabClassName="w-full flex items-center justify-center gap-2.5 text-center text-gray-600 text-xs p-2 border-r last:border-r-0 hover:bg-gray-50 transition duration-400"
            wrapperClassName="flex shrink-0 border rounded-t-lg border-gray-200 overflow-hidden mt-7"
            //itemActiveClass="!text-gray-800 !bg-blue-50"
            activeTab={FontSizeTab}
          />

          <div className="border-b border-x p-3 border-gray-200">
            {FontSizeTab === 0 && (
              <FontSizeBox
                title="Yazı Boyutu"
                activeFontSize={ThisComponent?.data?.titleStyle?.fontSize ? ThisComponent?.data?.titleStyle?.fontSize.replace("px", "") : FontSizes[0]}
                sizes={FontSizes}
                styles={FontSizeStyles}
                onChange={size => setSettings({
                  target: {
                    name: "fontSize",
                    value: `${size}px`
                  }
                }, false, TitleBlockSettings, "titleStyle").then((res) => setTitleBlockSettings(res))}
              />
            )}
            {FontSizeTab === 1 && (
              <div className="input-wrapper">
                <FontSizeRangeBox
                  className={""}
                  min={12}
                  max={72}
                  onChange={size => setSettings({
                    target: {
                      name: "fontSize",
                      value: `${size}px`
                    }
                  }, false, TitleBlockSettings, "titleStyle").then((res) => setTitleBlockSettings(res))}
                  rangeLabel="Yazı Boyutu (px)"
                  rangeValue={ThisComponent?.data?.titleStyle?.fontSize ? ThisComponent?.data?.titleStyle?.fontSize.replace("px", "") : 24}
                />
              </div>
            )}
          </div>
        </div>
        <div className="input-wrapper">
          <BorderBox>
            <FontWeightBox
              title="Yazı Kalınlığı"
              activeFontWeight={ThisComponent?.data?.titleStyle?.fontWeight}
              onChange={weight => setSettings({ target: {
                  name: "fontWeight",
                  value: weight
                }}, false, TitleBlockSettings, "titleStyle").then((res) => setTitleBlockSettings(res))}
            />
          </BorderBox>
        </div>
        <BorderBox>
          <span className="text-gray-800 font-medium">Hizalama</span>
          <div className="flex items-center gap-6 mt-3">
            {["start", "center", "end"].map((pos, key) => {
              const selected = ThisComponent?.data?.titleStyle?.["textAlign"] ? (ThisComponent?.data?.titleStyle?.["textAlign"] && ThisComponent?.data?.titleStyle?.["textAlign"].replace("text-", "")) === pos : key === 0
              return (
                <AlignmentBlock
                  onClick={() => setSettings({ target: {
                      name: "textAlign",
                      value: pos
                    }}, false, TitleBlockSettings, "titleStyle").then((res) => setTitleBlockSettings(res))}
                  selected={selected}
                  key={key}
                  position={pos}
                />
              )
            })}
          </div>
        </BorderBox>
      </div>
    </PartialWrapper>
  )
}

export default TitleLayout
