import * as type from "redux/types"

const initialState = {
  list: null,
  loading: false,
  error: false,
}

const getAgencyListForCorporate = (state = initialState, action) => {
  switch (action.type) {
    case type.GET_AGENCY_LIST_FOR_CORPORATE_REQUESTED:
      return {
        ...state,
        loading: true,
        error: false,
      }
    case type.GET_AGENCY_LIST_FOR_CORPORATE_SUCCEEDED:
      return {
        ...state,
        list: action.payload,
        loading: false,
        error: false,
      }
    case type.GET_AGENCY_LIST_FOR_CORPORATE_FAILED:
      return {
        ...state,
        list: null,
        loading: false,
        error: true,
      }
    default:
      return state
  }
}

export default getAgencyListForCorporate
