import Icon from "components/Icon"
import React from "react"

const ReservationSummaryRoutes = ({ origin, destination }) => {
  return (
    <div className="mt-4 flex shrink-0 flex-col md:max-w-[200px]">
      <div className="flex items-start gap-2">
        <div className="flex flex-col items-center justify-center pt-[6px]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="9"
            height="8"
            viewBox="0 0 9 8"
            fill="none"
          >
            <circle
              cx="4.5"
              cy="4"
              r="4"
              fill="#98A2B3"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1"
            height="19"
            viewBox="0 0 1 19"
            fill="none"
          >
            <path
              d="M0.5 18.5L0.499999 0.5"
              stroke="#98A2B3"
              stroke-dasharray="2 2"
            />
          </svg>
          <Icon
            name="chevron-down"
            size={12}
            color="#98A2B3"
          />
        </div>

        <span className="text-sm text-gray-500">{origin}</span>
      </div>
      <div className="flex items-end gap-2">
        <div className="flex flex-col items-center justify-center pb-[5px] pl-px">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1"
            height="19"
            viewBox="0 0 1 19"
            fill="none"
          >
            <path
              d="M0.5 18.5L0.499999 0.5"
              stroke="#98A2B3"
              stroke-dasharray="2 2"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="9"
            height="8"
            viewBox="0 0 9 8"
            fill="none"
          >
            <circle
              cx="4.5"
              cy="4"
              r="4"
              fill="#98A2B3"
            />
          </svg>
        </div>
        <span className="line-clamp-2 text-ellipsis text-sm text-gray-500">{destination}</span>
      </div>
    </div>
  )
}

export default ReservationSummaryRoutes
