import * as type from "redux/types"

export const domainStateUptadeRequested = (data) => ({
  type: type.DOMAIN_UPTADE_STATE_REQUESTED,
  payload: data,
})

export const domainStateUptadeSucceed = (data) => ({
  type: type.DOMAIN_UPTADE_STATE_SUCCEEDED,
  payload: data,
})

export const domainStateUptadeFailed = () => ({
  type: type.DOMAIN_UPTADE_STATE_FAILED,
})
