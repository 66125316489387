import React, { useState } from "react"
import MainHeader from "components/Headers/MainHeader"
import { Link } from "react-router-dom"
import { Icon } from "components"
import BackgroundIcons from "components/BackgroundIcons"

function ForgotPassword(props) {
  let { isLogged } = props
  const [submit, setSubmit] = useState(false)
  const [email, setEmail] = useState("")

  const setEmailValue = (e) => {
    setEmail(e.target.value)
  }

  return (
    <>
      <MainHeader
        isLogged={isLogged}
        className={"!absolute"}
      />

      <section className="login-wrapper flex items-center justify-center relative overflow-hidden min-h-screen">
        {/*background blur icons*/}
        <BackgroundIcons />
        {!submit ? (
          <div className="flex flex-col border border-gray-200 rounded-lg bg-white p-8 w-2/12">
            <div className="flex flex-row mb-8">
              <Link
                to="/"
                className="flex items-center text-base text-gray-500"
              >
                <Icon
                  className="mr-2"
                  name="arrow-left"
                  color="#667085"
                  size="18"
                ></Icon>
                Giriş Yap
              </Link>
            </div>

            <div className="title-area">
              <h2 className="text-3xl text-gray-800 font-bold">Şifremi unuttum</h2>
              <p className="text-base text-gray-500 mt-2">Doğrulama kodu göndereceğimiz e-posta adresini yazın.</p>
            </div>

            <form className="form-area mt-8">
              <div className="form-wrapper mb-5">
                <input
                  onChange={(e) => setEmailValue(e)}
                  className="w-full placeholder:text-slate-500"
                  type="email"
                  placeholder="E-Posta"
                  required
                  autoComplete="none"
                />
              </div>
              <div className="form-wrapper">
                <button
                  onClick={() => setSubmit(true)}
                  type="submit"
                  className="btn w-full"
                >
                  Gönder
                </button>
              </div>
            </form>
          </div>
        ) : (
          <div className="flex flex-col border border-gray-200 rounded-lg bg-white p-8 w-2/12">
            {/*login box title*/}
            <div className="title-area">
              <h2 className="text-3xl text-gray-800 font-bold mb-8">Şifremi unuttum</h2>
              <p className="text-base text-gray-500">Şifre sıfırlama işlemi için {email} adresine bir e-posta gönderdik.</p>
              <p className="text-base text-gray-500 mt-4">
                E-postana gelen bağlantıya tıklayarak, sıfırlama işlemine başlayabilirsin.
              </p>
            </div>
          </div>
        )}
      </section>
    </>
  )
}

export default ForgotPassword
