import React from "react"
import MainHeader from "components/Headers/MainHeader"
import { Placeholder } from "rsuite"
import { Icon } from "components"
import { useNavigate } from "react-router"
import PropTypes from "prop-types"

/**
 *
 * @param title this is title of page
 * @param description this is description of page
 * @param buttonContent this is button content of page at head of this page
 * @param backButtonText this is button text of back button
 * @param isLoading this is loading status of page
 * @param children this is children of page
 * @returns
 */
const CommonAddLayout = (props) => {
  let { isLogged, isLoading } = props
  const navigate = useNavigate()

  return (
    <>
      <MainHeader isLogged={isLogged} />
      <main
        className="bg-gray-50  overflow-auto"
        ref={props?.ref}
      >
        <section className="p-[1.1876rem] my-0 mx-auto max-w-[900px]">
          <span
            className="!text-gray-600 flex justify-start items-center gap-2 mt-2 mb-8 cursor-pointer w-fit transition-all hover:drop-shadow"
            onClick={() => navigate(-1)}
          >
            <Icon
              name="arrow-left"
              size="20"
              className="!bg-gray-500"
            />
            <h4>{props?.backButtonText || "Kurallara Dön"}</h4>
          </span>
          <div className="flex items-center justify-between">
            <span>
              <h1 className="text-3xl font-semibold text-gray-800">{props.title}</h1>
              <p className="text-sm text-gray-600 font-thin my-1">{props.description}</p>
            </span>
            {!isLoading && <>{!!props.buttonContent && <span>{props.buttonContent}</span>}</>}
          </div>
          {isLoading && <Placeholder.Graph active />}
          {!isLoading && props.children}
        </section>
      </main>
    </>
  )
}

CommonAddLayout.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  buttonContent: PropTypes.element,
  isLogged: PropTypes.bool,
  isLoading: PropTypes.bool,
}

CommonAddLayout.defaultProps = {
  title: "",
  description: "",
  buttonContent: null,
  isLogged: false,
  isLoading: false,
}

export default CommonAddLayout
