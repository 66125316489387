import React from "react"

import Icon from "components/Icon"

const TursabContent = ({ tippyContent, addDocument, tursabLogo, tursabValue }) => {
  const hostNames = ["localhost:5000", "my-eva.gordios.dev", "my-eva.gordios.ws"].includes(window.location.host)

  const tursabLogoSourcePath = `https://${
    hostNames ? "gordiosdatatest.blob.core.windows.net/files/" : "cdng.jollytur.com/files/"
  }${tursabLogo?.value}`

  return (
    <div className="bg-white border border-gray-200 rounded-lg p-8 mt-6">
      <div className="flex items-center">
        <h3 className="text-gray-800 text-lg font-semibold">
          <span className="text-blue-500">*</span>
          TÜRSAB Belge
        </h3>
        <Icon
          name="help-circle"
          size="16"
          color="#101828"
          className="ml-1 tippy-content"
        />
        <div
          ref={tippyContent}
          className="hidden"
        >
          <div className="w-40 md:w-72">
            <h6 className="font-medium">TÜRSAB Belge</h6>
            <p className="mt-1">
              Turizm ve seyahat amaçlı kurulan tüm kurumlar için Kültür ve Turizm Bakanlığı’nın zorunlu kıldığı belgedir.
            </p>
          </div>
        </div>

        <button
          className="text-blue-500 font-medium ml-auto"
          onClick={addDocument}
        >
          {tursabLogo?.value !== null && tursabValue?.value ? "Düzenle" : "TÜRSAB Belge Ekle"}
        </button>
      </div>
      {tursabLogo?.value !== null && tursabValue?.value ? (
        <div className="mt-4 flex items-start gap-6">
          <img
            width="232px"
            height="114px"
            src={tursabLogoSourcePath}
            alt="tursab-logo"
          />
          <div className="flex flex-col gap-3 w-full">
            <div className="flex w-full gap-4">
              <div className="flex flex-col w-1/2">
                <span className="text-sm font-medium">Belge Numarası</span>
                <span className="text-gray-500 line-clamp-2">
                  {tursabValue?.value ? tursabValue?.value : "Belge No alanı doldurulmadı"}
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <p className={"text-gray-500 mt-4"}>Onaylı TÜRSAB Belgenizi sisteme yükleyin.</p>
      )}
    </div>
  )
}

export default TursabContent
