import { all, fork } from "redux-saga/effects"
import contractsSet from "redux/sagas/contractsSet"
import getAllAccountList from "redux/sagas/getAllAccountList"
import getDepartmants from "redux/sagas/getDepartmants"
import getRoles from "redux/sagas/getRoles"
import associatedDomains from "redux/sagas/associatedDomains"
import getPropertiesWithUser from "redux/sagas/getPropertiesWithUser"
import clientConfiguration from "redux/sagas/clientConfiguration"
import clientConfigurationGet from "redux/sagas/clientConfigurationGet"
import myReservationsList from "redux/sagas/myReservationsList"
import myReservationsDetail from "redux/sagas/myReservationsDetail"
import myReservationsApprove from "redux/sagas/myReservationsApprove"
import myWorkAreaInfo from "redux/sagas/myWorkAreaInfo"
import destinations from "redux/sagas/destinations"
import accountManagement from "redux/sagas/accountManagement"
import getCustomDomainsNearExpiration from "redux/sagas/getCustomDomainsNearExpiration"
import refreshCustomDomainValidationToken from "redux/sagas/refreshCustomDomainValidationToken"
import getProductList from "redux/sagas/getProductList"
import getPageList from "redux/sagas/getPageList"
import pageManager from "redux/sagas/pageManager"
import getAgencyList from "redux/sagas/getAgencyList"
import getAgencyListForCorporate from "redux/sagas/getAgencyListForCorporate"
import mediaLibrary from "redux/sagas/mediaLibrary"
import clientUserRegister from "redux/sagas/clientUserRegister"
import clientUserUpdate from "redux/sagas/clientUserUpdate"
import clientUserGet from "redux/sagas/clientUserGet"
import strategy from "redux/sagas/strategy"
import strategyDesign from "redux/sagas/strategyDesign"
import domainStateGet from "redux/sagas/domainStateGet"
import domainStateUptade from "redux/sagas/domainStateUptade"
import footerUptade from "redux/sagas/footerUptade"
import getCountryCodes from "redux/sagas/getCountryCodes"
import pageSave from "redux/sagas/pageSave"
import pageUpdate from "redux/sagas/pageUpdate"
import pageDelete from "redux/sagas/pageDelete"
import pageState from "redux/sagas/pageState"
import pageClone from "redux/sagas/pageClone"
import pageSaveMain from "redux/sagas/pageSaveMain"
// B2C
import ListConfiguration from "pages/B2cDemoPage/redux/sagas/ListConfiguration"
import SetDevice from "pages/B2cDemoPage/redux/sagas/DisplayDevice"
import SaveConfiguration from "pages/B2cDemoPage/redux/sagas/SaveConfiguration"
import GetConfiguration from "pages/B2cDemoPage/redux/sagas/GetConfiguration"
import setThemeConfig from "pages/B2cDemoPage/redux/sagas/setThemeConfig"
import ViewportFullSize from "pages/B2cDemoPage/redux/sagas/ViewportFullSize"
import Addon from "redux/sagas/addon"
import AddonDetail from "redux/sagas/addonDetail"
import AddonPrice from "redux/sagas/addonPrice"
import AddonCategoryList from "redux/sagas/addonCategoryList"
import UserInfo from "redux/sagas/userInfo"
import AddonSave from "redux/sagas/addonSave"
import milesAndSmilesList from "redux/sagas/milesAndSmiles"
import saveUserSettings from "redux/sagas/saveUserSettings"
import getSettingsList from "redux/sagas/getSettings"
import definePropertyTypeList from "redux/sagas/definePropertyType"
import propertiesList from "redux/sagas/properties"
import getAgreement from "redux/sagas/getAgreement"
import saveAgreement from "redux/sagas/saveAgreement"

export default function* sagas() {
  yield all([
    fork(contractsSet),
    fork(getAllAccountList),
    fork(getDepartmants),
    fork(getRoles),
    fork(associatedDomains),
    fork(getPropertiesWithUser),
    fork(clientConfiguration),
    fork(clientConfigurationGet),
    fork(myReservationsList),
    fork(myReservationsDetail),
    fork(myReservationsApprove),
    fork(myWorkAreaInfo),
    fork(destinations),
    fork(accountManagement),
    fork(getCustomDomainsNearExpiration),
    fork(refreshCustomDomainValidationToken),
    fork(getProductList),
    fork(getPageList),
    fork(pageManager),
    fork(getAgencyList),
    fork(getAgencyListForCorporate),
    fork(mediaLibrary),
    fork(clientUserRegister),
    fork(clientUserUpdate),
    fork(clientUserGet),
    fork(strategy),
    fork(strategyDesign),
    fork(domainStateGet),
    fork(domainStateUptade),
    fork(footerUptade),
    fork(getCountryCodes),
    fork(pageSave),
    fork(pageUpdate),
    fork(pageDelete),
    fork(pageState),
    fork(pageClone),
    fork(pageSaveMain),
    // b2C
    fork(ListConfiguration),
    fork(SetDevice),
    fork(SaveConfiguration),
    fork(GetConfiguration),
    fork(setThemeConfig),
    fork(ViewportFullSize),
    fork(Addon),
    fork(AddonDetail),
    fork(AddonPrice),
    fork(AddonCategoryList),
    fork(UserInfo),
    fork(AddonSave),
    fork(milesAndSmilesList),
    fork(saveUserSettings),
    fork(getSettingsList),
    fork(propertiesList),
    fork(definePropertyTypeList),
    fork(getAgreement),
    fork(saveAgreement)
  ])
}
