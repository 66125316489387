import { call, fork, put, takeLatest } from "redux-saga/effects"
import * as actions from "redux/actions/pageClone"

import { PAGE_CLONE_REQUESTED } from "redux/types"
import {API_POST} from "helpers/api"

function* watchClonePage() {
  yield takeLatest(PAGE_CLONE_REQUESTED, getPageClone)
}

function* getPageClone(action) {
  try {
    const res = yield call(apiGetPageClone, action.payload)
    yield put(actions.pageCloneSucceed(res?.data))
  } catch (err) {
    yield put(actions.pageCloneFailed())
  }
}

const apiGetPageClone = (payload) => {
  return API_POST(`${process.env.REACT_APP_DISPLAY_DOMAIN}/api/page/clone-page`, payload)
}

export default function* pageClone() {
  yield fork(watchClonePage)
}
