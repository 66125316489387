import {newAtomPage} from "../Enums";
let atomPageSetted = false

export const extractNameAndCode = (obj, index) => {
	// Name ve code alanlarını içeren yeni bir nesne oluştur

	let result = {
		name: obj?.name,
		code: obj?.code
	};

	if (!atomPageSetted && !obj?.name){
		atomPageSetted = true
		result = newAtomPage()
	}

	// Eğer children varsa, her bir child için recursive olarak extractNameAndCode fonksiyonunu çağır
	if (obj.children && obj.children.length > 0) {
		result.ChildStrategies = obj.children.map((child, index) => extractNameAndCode(child, index));
	}

	return result;
}