import React, { useRef } from "react"

// Components
import TitleH3 from "components/Typography/TitleH3"

// RSuite.JS
import { SelectPicker } from "rsuite"

const DisplaySettings = (props) => {
  let {
    config: { ThemeConfig, AllDisplays, AllFonts },
    setConfig,
  } = props

  // refs
  const textTypeWrapper = useRef()

  return (
    <>
      <div className="relative w-5/12">
        {/*Display*/}
        <div className="ds-box mb-8">
          <TitleH3 className="mb-2">Görünüm</TitleH3>

          <div className="box-content relative">
            <div className="data-box min-h-[150px]">
              {!AllDisplays && (
                <div className="animate-pulse left-0 w-full h-full bottom-0 space-y-3">
                  <div className="grid grid-cols-4 gap-2">
                    <div className="h-[50px] bg-slate-200 rounded col-span-3" />
                    <div className="h-[50px] bg-slate-200 rounded col-span-1" />
                  </div>
                  <div className="grid grid-cols-4 gap-2">
                    <div className="h-[50px] bg-slate-200 rounded col-span-3" />
                    <div className="h-[50px] bg-slate-200 rounded col-span-1" />
                  </div>
                  <div className="grid grid-cols-4 gap-2">
                    <div className="h-[50px] bg-slate-200 rounded col-span-3" />
                    <div className="h-[50px] bg-slate-200 rounded col-span-1" />
                  </div>
                </div>
              )}
              {AllDisplays &&
                ThemeConfig.displaySettings?.borderRadius &&
                AllDisplays.map((display, index) => {
                  return (
                    <label
                      onClick={() => {
                        setConfig({
                          borderRadius: display.styles.borderRadius,
                          activeDisplay: display.label,
                        })
                      }}
                      htmlFor={`display-${index}`}
                      key={index}
                      className={`${
                        display.styles.borderRadius === ThemeConfig.displaySettings.borderRadius ? "border-blue-200 " : ""
                      }radio-group transition flex items-center border border-gray-200 shadow-buttonSm rounded-lg py-[5px] px-3 cursor-pointer mb-2 last:mb-0`}
                    >
                      <div className="radio-area inline-flex items-center mr-2">
                        <input
                          id={`display-${index}`}
                          type="radio"
                          name={"radios"}
                          onChange={() => false}
                          checked={display.styles.borderRadius === ThemeConfig.displaySettings.borderRadius}
                        />
                      </div>
                      <p className="text-black font-medium text-sm mr-4">{display.label}</p>
                      <div
                        className="font-preview-wrapper ml-auto w-[50px] h-10 flex items-center justify-center"
                        style={{ ...display.styles, backgroundColor: display.backgroundColor }}
                      >
                        <p className="text-gray-900 font-bold">Aa</p>
                      </div>
                    </label>
                  )
                })}
            </div>
          </div>
        </div>

        <div className="ds-box mt-8">
          <TitleH3 className="mb-2">Yazı Tipi</TitleH3>

          <div
            ref={textTypeWrapper}
            className="box-content relative"
          >
            {!AllFonts && (
              <div className="animate-pulse absolute left-0 w-full h-full bottom-0 space-y-3">
                <div className="grid grid-cols-4 gap-2">
                  <div className="h-11 bg-slate-200 rounded col-span-3" />
                  <div className="h-11 bg-slate-200 rounded col-span-1" />
                </div>
              </div>
            )}

            {AllFonts && AllFonts.length > 0 && (
              <SelectPicker
                block
                placement="topStart"
                placeholder={""}
                value={ThemeConfig.displaySettings.fontFamily}
                menuMaxHeight={164}
                cleanable={false}
                data={AllFonts ? AllFonts : []}
                searchable={false}
                container={textTypeWrapper.current}
                onSelect={(value, item) => {
                  setConfig({
                    fontFamily: value,
                  })
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default DisplaySettings
