import React from "react"

const DoneBtn = (props) => {
  let { onClick, disabled } = props
  return (
    <>
      <div className="goback-btn text-right">
        <button
          className={"btn text sm"}
          disabled={disabled}
          onClick={onClick}
        >
          Tamamla
        </button>
      </div>
    </>
  )
}
export default DoneBtn
