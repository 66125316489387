import * as type from "redux/types"

export const footerUptadeRequested = (data) => ({
  type: type.FOOTER_UPTADE_REQUESTED,
  payload: data,
})

export const footerUptadeSucceed = (data) => ({
  type: type.FOOTER_UPTADE_SUCCEEDED,
  payload: data,
})

export const footerUptadeFailed = () => ({
  type: type.FOOTER_UPTADE_FAILED,
})
