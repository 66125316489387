import React from "react"
import moment from "moment"
import { BorderBox, Icon } from "components"
import { getCityName, dateToTime, getAirportCode, getFormatedFlightTime } from "pages/Reports/libs/flightHelpers"

const ReservationReportsDetailFlightModule = ({ reservationData }) => {
  const pricingCombinations = reservationData?.DetailResponse?.PricingCombinations?.length
    ? reservationData.DetailResponse?.PricingCombinations
    : [reservationData.DetailResponse?.PricingCombinations?.[0]]

  if (pricingCombinations.length === 0) {
    return null
  }
  const combinationGroups = pricingCombinations?.flatMap((item) => item?.CombinationGroups?.sort((a, b) => a.GroupId - b.GroupId))

  const tripLength = combinationGroups && combinationGroups?.length

  const mainCurrency = reservationData.DetailResponse.PricingCombinations[0].TotalPrice.slice(0, 3)
  const totalledPrice =
    reservationData.DetailResponse.PricingCombinations.length > 1
      ? reservationData.DetailResponse.PricingCombinations.reduce((acc, curr) => acc + +curr.TotalPrice.slice(3), 0)
      : +reservationData.DetailResponse.PricingCombinations[0].TotalPrice.slice(3)

  return (
    <div>
      {combinationGroups &&
        combinationGroups.map((combination) => {
          if (!combination?.FlightSegments || combination?.FlightSegments?.length === 0) {
            console.log("combination", combination)
            return null
          }

          const flightSegments = combination.FlightSegments.sort(
            (a, b) => moment(a.DepartureTime).valueOf() - moment(b.DepartureTime).valueOf(),
          )

          if (!flightSegments || !Array.isArray(flightSegments) || flightSegments.length === 0 || !flightSegments[0]) return null

          const departureSegment = flightSegments[0]
          const arrivalSegment = flightSegments[flightSegments.length - 1]
          const direct = combination.GroupId === 0 ? "Gidiş" : "Dönüş"

          return (
            <div key={departureSegment.Key}>
              <div className="my-2 flex items-center justify-between text-sm">
                <div className="flex gap-2">
                  <span className="font-medium">
                    {`${getCityName(departureSegment?.Origin || "")} - ${getCityName(departureSegment?.Destination || "")}`}
                  </span>
                  <span>{moment(departureSegment.DepartureTime).format("DD MMMM YYYY dddd")}</span>
                </div>
              </div>
              <BorderBox
                key={departureSegment.Key}
                className="flex w-full gap-4 bg-gray-50 p-4"
              >
                <div className="flex w-full flex-col items-center justify-between gap-2 md:flex-row">
                  <div className="flex w-full flex-col md:w-auto">
                    {tripLength === 2 && <p className="mb-1 text-sm font-medium">{direct}</p>}
                    <div className="flex items-center justify-center gap-2 whitespace-nowrap md:justify-normal">
                      <img
                        className="size-6"
                        src={`https://concorecdn.jollytur.com/concore/media/site/assets/img/theme/${departureSegment?.OperatingAirlineCode}-42x42.gif?v1040`}
                        alt={departureSegment?.OperatingAirlineCode}
                      />
                      <div>
                        <p className="text-sm font-semibold">{departureSegment?.MarketingAirline}</p>
                        <p className="text-xs text-gray-500">{departureSegment?.FlightNumber}</p>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col items-center gap-1 overflow-hidden">
                    <p className="text-xs text-gray-500">{getFormatedFlightTime(combination?.TravelTime || "")}</p>
                    <div className="flex w-full items-center gap-3 overflow-hidden">
                      <div className="flex w-full items-center gap-1">
                        <p className="text-base font-semibold">{dateToTime(departureSegment.DepartureTime)}</p>
                        <p
                          className="text-sm"
                          title={departureSegment?.Origin}
                        >
                          {getAirportCode(departureSegment?.Origin || "")}
                        </p>
                      </div>

                      <Icon
                        color={"#667085"}
                        className="shrink-0 text-gray-300"
                        name="minus"
                        size={20}
                      />
                      <Icon
                        color={"#667085"}
                        className="shrink-0 rotate-45 text-gray-500"
                        size="20"
                        name="plane"
                      />
                      <Icon
                        color={"#667085"}
                        className="shrink-0 text-gray-300"
                        name="move-right"
                        size={20}
                      />

                      <div className="flex w-full items-center gap-1">
                        <p className="text-base font-semibold">{dateToTime(arrivalSegment.ArrivalTime)}</p>
                        <p
                          className="text-sm"
                          title={arrivalSegment?.Destination}
                        >
                          {getAirportCode(arrivalSegment?.Destination || "")}
                        </p>
                      </div>
                    </div>
                    <p className="text-sm">
                      {`${
                        combination.FlightSegments.length >= 2
                          ? `${combination.FlightSegments.length - 1} Aktarmalı ( ${[
                              ...new Set(flightSegments.filter((_, i) => i !== 0).map((x) => getAirportCode(x.Origin))),
                            ].join(", ")} )`
                          : "Aktarmasız"
                      }`}
                      <span className="capitalize">{`, ${combination.CabinClassTitle?.toLowerCase() ?? ""}`}</span>
                    </p>
                  </div>
                  <div className="flex w-full items-center justify-between gap-2 border-t pt-2 text-right md:w-auto md:flex-col md:border-none md:pt-0">
                    {tripLength === 1 && (
                      <div>
                        <p className="text-sm text-gray-500">Toplam Tutar</p>
                        <p className="font-semibold">
                          {Intl.NumberFormat("tr-TR", {
                            style: "currency",
                            currency: mainCurrency,
                          }).format(totalledPrice)}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </BorderBox>
            </div>
          )
        })}
    </div>
  )
}

export default ReservationReportsDetailFlightModule
