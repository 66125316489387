import { useQuery } from "@tanstack/react-query"
import { API_POST } from "helpers/api"

export const useReservationList = (
  tableFilter,
  props = {
    SortColumn: "Id",
    SortIsAscending: false,
    TravelReason: null,
    CancelReason: null,
    PageSize: 10,
    StartDate: null,
    EndDate: null,
  },
) => {
  const { currentPage, requestStatus, moduleName, searchTerm, startDate, endDate } = tableFilter

  const query = useQuery({
    queryKey: ["corporateList", tableFilter],
    queryFn: () =>
      API_POST(`${process.env.REACT_APP_PANEL_DOMAIN}/api/reservation/corporatelist`, {
        ...props,
        Page: currentPage,
        RequestStatus: requestStatus,
        ModuleName: moduleName,
        SearchTerm: searchTerm,
        StartDate: startDate,
        EndDate: endDate,
      }),
  })

  return query
}

export const useExportReservationList = (
  tableFilter,
  props = {
    SortColumn: "Id",
    SortIsAscending: false,
    TravelReason: null,
    CancelReason: null,
    StartDate: null,
    PageSize: 2000,
    EndDate: null,
  },
) => {
  const { currentPage, requestStatus, moduleName, startDate, endDate } = tableFilter

  const query = useQuery({
    queryKey: ["corporateExportList", tableFilter],
    queryFn: () =>
      API_POST(`${process.env.REACT_APP_PANEL_DOMAIN}/api/reservation/corporatelist`, {
        ...props,
        Page: currentPage,
        RequestStatus: requestStatus,
        ModuleName: moduleName,
        StartDate: startDate,
        EndDate: endDate,
      }),
  })

  return query
}
